import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../proceedweb.css';
import { useRef, useState } from "react";
//
import { MdOutlineCreate, MdSettingsBackupRestore, MdOutlineArrowForwardIos, MdOutlineArrowBackIos, MdOutlineAttachEmail } from 'react-icons/md';
import { AiOutlineWhatsApp } from 'react-icons/ai';
//
import { AlertModal, getCurrentDate, PopupModal } from "../../helperfunctions/Helper";
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
import { 
    SpanGlobalSpan,
    GlobalStrongTitle,
    LongFormScrollerWidgetSm,
    TextProceedService, 
    TextLoginService,
    GlobalLargeInput,
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
    TextArea,
} from "../../global/Styles";

import Axios from 'axios';

function ProceedWeb() {
    const {t} = useTranslation();

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleWebChange = event => {
        if (!isValidEmail(event.target.value)) {
          setError(t('invalidemail'));
        } else {
          setError(null);
        }
    
        setEmail(event.target.value);
    };

    const navigateProceedWeb = useNavigate();

    //alert
    const elementOutId = "modaloutproceedweb";
    const elementDropId = "backdropoutproceedweb";
    const buttonHandler = "ouiHandlerProceedWebBtn";
    //
    const elementOutAlertId = "modaloutalertproceedweb";
    const elementDropAlertId = "backdropoutalertproceedweb";
    const buttonAlertHandler = "ouiHandleralertProceedWebBtn";

    //webstepone
    const webSiteQuestion1Ref = useRef(null);
    const webSiteQuestion2Ref = useRef(null);
    const webSiteQuestion3Ref = useRef(null);
    //websteptwo
    const webSiteQuestion4Ref = useRef(null);
    const webSiteQuestion5Ref = useRef(null);
    const webSiteQuestion6Ref = useRef(null);
    //webstepthree
    const webSiteQuestion7Ref = useRef(null);
    const webSiteQuestion8Ref = useRef(null);
    const webSiteQuestion9Ref = useRef(null);
    //webstepfour
    const webSiteQuestion10Ref = useRef(null);
    const webSiteQuestion11Ref = useRef(null);
    const webSiteQuestion12Ref = useRef(null);
    //modeproceedweb
    const webSiteQuestion13Ref = useRef(null);
    const webSiteQuestion14Ref = useRef(null);
    //
    

    const forwardProceedWeb1Handler = async (e) => {
        e.preventDefault();

        const websteponeDiv = document.getElementById('webstepone');
        const websteptwoDiv = document.getElementById('websteptwo');
        const webstepthreeDiv = document.getElementById('webstepthree');
        const webstepfourDiv = document.getElementById('webstepfour');
        const modeproceedwebDiv = document.getElementById('modeproceedweb');
        const submitproceedwebDiv = document.getElementById('submitproceedweb');
        //
        websteponeDiv.style.display = 'none';
        websteptwoDiv.style.display = 'block';
        webstepthreeDiv.style.display = 'none';
        webstepfourDiv.style.display = 'none';
        modeproceedwebDiv.style.display = 'none';
        submitproceedwebDiv.style.display = 'none';
    }

    const forwardProceedWeb2Handler = async (e) => {
        e.preventDefault();

        const websteponeDiv = document.getElementById('webstepone');
        const websteptwoDiv = document.getElementById('websteptwo');
        const webstepthreeDiv = document.getElementById('webstepthree');
        const webstepfourDiv = document.getElementById('webstepfour');
        const modeproceedwebDiv = document.getElementById('modeproceedweb');
        const submitproceedwebDiv = document.getElementById('submitproceedweb');
        //
        websteponeDiv.style.display = 'none';
        websteptwoDiv.style.display = 'none';
        webstepthreeDiv.style.display = 'block';
        webstepfourDiv.style.display = 'none';
        modeproceedwebDiv.style.display = 'none';
        submitproceedwebDiv.style.display = 'none';
    }
    //
    const goBackProceedWeb2Handler = async (e) => {
        e.preventDefault();

        const websteponeDiv = document.getElementById('webstepone');
        const websteptwoDiv = document.getElementById('websteptwo');
        const webstepthreeDiv = document.getElementById('webstepthree');
        const webstepfourDiv = document.getElementById('webstepfour');
        const modeproceedwebDiv = document.getElementById('modeproceedweb');
        const submitproceedwebDiv = document.getElementById('submitproceedweb');
        //
        websteponeDiv.style.display = 'block';
        websteptwoDiv.style.display = 'none';
        webstepthreeDiv.style.display = 'none';
        webstepfourDiv.style.display = 'none';
        modeproceedwebDiv.style.display = 'none';
        submitproceedwebDiv.style.display = 'none';
    }

    const forwardProceedWeb3Handler = async (e) => {
        e.preventDefault();

        const websteponeDiv = document.getElementById('webstepone');
        const websteptwoDiv = document.getElementById('websteptwo');
        const webstepthreeDiv = document.getElementById('webstepthree');
        const webstepfourDiv = document.getElementById('webstepfour');
        const modeproceedwebDiv = document.getElementById('modeproceedweb');
        const submitproceedwebDiv = document.getElementById('submitproceedweb');
        //
        websteponeDiv.style.display = 'none';
        websteptwoDiv.style.display = 'none';
        webstepthreeDiv.style.display = 'none';
        webstepfourDiv.style.display = 'block';
        modeproceedwebDiv.style.display = 'none';
        submitproceedwebDiv.style.display = 'none';
    }
    //
    const goBackProceedWeb3Handler = async (e) => {
        e.preventDefault();

        const websteponeDiv = document.getElementById('webstepone');
        const websteptwoDiv = document.getElementById('websteptwo');
        const webstepthreeDiv = document.getElementById('webstepthree');
        const webstepfourDiv = document.getElementById('webstepfour');
        const modeproceedwebDiv = document.getElementById('modeproceedweb');
        const submitproceedwebDiv = document.getElementById('submitproceedweb');
        //
        websteponeDiv.style.display = 'none';
        websteptwoDiv.style.display = 'block';
        webstepthreeDiv.style.display = 'none';
        webstepfourDiv.style.display = 'none';
        modeproceedwebDiv.style.display = 'none';
        submitproceedwebDiv.style.display = 'none';
    }

    const forwardProceedWeb4Handler = async (e) => {
        e.preventDefault();

        const websteponeDiv = document.getElementById('webstepone');
        const websteptwoDiv = document.getElementById('websteptwo');
        const webstepthreeDiv = document.getElementById('webstepthree');
        const webstepfourDiv = document.getElementById('webstepfour');
        const modeproceedwebDiv = document.getElementById('modeproceedweb');
        const submitproceedwebDiv = document.getElementById('submitproceedweb');
        //
        websteponeDiv.style.display = 'none';
        websteptwoDiv.style.display = 'none';
        webstepthreeDiv.style.display = 'none';
        webstepfourDiv.style.display = 'none';
        modeproceedwebDiv.style.display = 'block';
        submitproceedwebDiv.style.display = 'none';
    }
    //
    const goBackProceedWeb4Handler = async (e) => {
        e.preventDefault();

        const websteponeDiv = document.getElementById('webstepone');
        const websteptwoDiv = document.getElementById('websteptwo');
        const webstepthreeDiv = document.getElementById('webstepthree');
        const webstepfourDiv = document.getElementById('webstepfour');
        const modeproceedwebDiv = document.getElementById('modeproceedweb');
        const submitproceedwebDiv = document.getElementById('submitproceedweb');
        //
        websteponeDiv.style.display = 'none';
        websteptwoDiv.style.display = 'none';
        webstepthreeDiv.style.display = 'block';
        webstepfourDiv.style.display = 'none';
        modeproceedwebDiv.style.display = 'none';
        submitproceedwebDiv.style.display = 'none';
    }

    const forwardProceedWeb5Handler = async (e) => {
        e.preventDefault();

        const webSiteQuestion13 = webSiteQuestion13Ref.current.value;
        const webSiteQuestion14 = webSiteQuestion14Ref.current.value;
        //
        const websteponeDiv = document.getElementById('webstepone');
        const websteptwoDiv = document.getElementById('websteptwo');
        const webstepthreeDiv = document.getElementById('webstepthree');
        const webstepfourDiv = document.getElementById('webstepfour');
        const modeproceedwebDiv = document.getElementById('modeproceedweb');
        const submitproceedwebDiv = document.getElementById('submitproceedweb');
        //
        if(!webSiteQuestion13){
            const pTexteSaisir = t("enteremail");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else if(!webSiteQuestion14){
            const pTexteSaisir = t("enterphone");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else{
            //
            websteponeDiv.style.display = 'none';
            websteptwoDiv.style.display = 'none';
            webstepthreeDiv.style.display = 'none';
            webstepfourDiv.style.display = 'none';
            modeproceedwebDiv.style.display = 'none';
            submitproceedwebDiv.style.display = 'block';
        }
    }
    //
    const goBackProceedWeb5Handler = async (e) => {
        e.preventDefault();
        
        const websteponeDiv = document.getElementById('webstepone');
        const websteptwoDiv = document.getElementById('websteptwo');
        const webstepthreeDiv = document.getElementById('webstepthree');
        const webstepfourDiv = document.getElementById('webstepfour');
        const modeproceedwebDiv = document.getElementById('modeproceedweb');
        const submitproceedwebDiv = document.getElementById('submitproceedweb');
        //
        websteponeDiv.style.display = 'none';
        websteptwoDiv.style.display = 'none';
        webstepthreeDiv.style.display = 'none';
        webstepfourDiv.style.display = 'block';
        modeproceedwebDiv.style.display = 'none';
        submitproceedwebDiv.style.display = 'none';
    }
    //
    const goBackProceedWeb6Handler = async (e) => {
        e.preventDefault();

        const websteponeDiv = document.getElementById('webstepone');
        const websteptwoDiv = document.getElementById('websteptwo');
        const webstepthreeDiv = document.getElementById('webstepthree');
        const webstepfourDiv = document.getElementById('webstepfour');
        const modeproceedwebDiv = document.getElementById('modeproceedweb');
        const submitproceedwebDiv = document.getElementById('submitproceedweb');
        //
        websteponeDiv.style.display = 'none';
        websteptwoDiv.style.display = 'none';
        webstepthreeDiv.style.display = 'none';
        webstepfourDiv.style.display = 'none';
        modeproceedwebDiv.style.display = 'block';
        submitproceedwebDiv.style.display = 'none';
    }

    //popup info
    const popweb1Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_1");
        const pTexteNotAvailable = t("hintwebquestion_1");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popweb2Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_2");
        const pTexteNotAvailable = t("hintwebquestion_2");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popweb3Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_3");
        const pTexteNotAvailable = t("hintwebquestion_3");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popweb4Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_4");
        const pTexteNotAvailable = t("hintwebquestion_4");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popweb5Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_5");
        const pTexteNotAvailable = t("hintwebquestion_5");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popweb6Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_6");
        const pTexteNotAvailable = t("hintwebquestion_6");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popweb7Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_7");
        const pTexteNotAvailable = t("hintwebquestion_7");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popweb8Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_8");
        const pTexteNotAvailable = t("hintwebquestion_8");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popweb9Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_9");
        const pTexteNotAvailable = t("hintwebquestion_9");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popweb10Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_10");
        const pTexteNotAvailable = t("hintwebquestion_10");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popweb11Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_11");
        const pTexteNotAvailable = t("hintwebquestion_11");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popweb12Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_12");
        const pTexteNotAvailable = t("hintwebquestion_12");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    //http://localhost:5000/check/webservice

    const submitProceedWebHandler = async (e) => {
        e.preventDefault();

        const webSiteQuestion1 = webSiteQuestion1Ref.current.value;
        const webSiteQuestion2 = webSiteQuestion2Ref.current.value;
        const webSiteQuestion3 = webSiteQuestion3Ref.current.value;
        //websteptwo
        const webSiteQuestion4 = webSiteQuestion4Ref.current.value;
        const webSiteQuestion5 = webSiteQuestion5Ref.current.value;
        const webSiteQuestion6 = webSiteQuestion6Ref.current.value;
        //webstepthree
        const webSiteQuestion7 = webSiteQuestion7Ref.current.value;
        const webSiteQuestion8 = webSiteQuestion8Ref.current.value;
        const webSiteQuestion9 = webSiteQuestion9Ref.current.value;
        //webstepfour
        const webSiteQuestion10 = webSiteQuestion10Ref.current.value;
        const webSiteQuestion11 = webSiteQuestion11Ref.current.value;
        const webSiteQuestion12 = webSiteQuestion12Ref.current.value;
        //modeproceedweb
        const webSiteQuestion13 = webSiteQuestion13Ref.current.value;
        const webSiteQuestion14 = webSiteQuestion14Ref.current.value;
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const theDate = getCurrentDate();
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = "Website";
        const servicePlan = document.getElementById('typeplan').innerText;
        var createTicketCode = "";
        //
        var resultat1 = "";
        //
        //generate random ticket code
        var randomNumber = Math.floor((Math.random() * 100000) + 1);
        createTicketCode = randomNumber+''+codeutilisateurDiv;
        
        //check if this plan has already been submited
        await Axios.post("https://micarionsapi.carions.net/check/webservice", {
            codeutilisateurDiv:codeutilisateurDiv,
            typeService:typeService,
            servicePlan:servicePlan,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                //insert
                Axios.post("https://micarionsapi.carions.net/quest/webservice", {
                    choixlangueDiv:choixlangueDiv,
                    usernamedetailDiv:usernamedetailDiv,
                    codeutilisateurDiv:codeutilisateurDiv,
                    //
                    typeService:typeService,
                    servicePlan:servicePlan,
                    //
                    createTicketCode:createTicketCode,
                    //
                    webSiteQuestion1:webSiteQuestion1,
                    webSiteQuestion2:webSiteQuestion2,
                    webSiteQuestion3:webSiteQuestion3,
                    //
                    webSiteQuestion4:webSiteQuestion4,
                    webSiteQuestion5:webSiteQuestion5,
                    webSiteQuestion6:webSiteQuestion6,
                    //
                    webSiteQuestion7:webSiteQuestion7,
                    webSiteQuestion8:webSiteQuestion8,
                    webSiteQuestion9:webSiteQuestion9,
                    //
                    webSiteQuestion10:webSiteQuestion10,
                    webSiteQuestion11:webSiteQuestion11,
                    webSiteQuestion12:webSiteQuestion12,
                    //
                    webSiteQuestion13:webSiteQuestion13,
                    webSiteQuestion14:webSiteQuestion14,
                    //
                    theDate:theDate,
                }).then(() => {
                    var answer1 = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                    const hTitleTexte = t("proceedwebtitle");
                    const pTexteNotAvailable = answer1;
                    PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
                });
            }else{
                //set up modal to update
                var answer = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                console.log(resultat1);
                const hTitleTexte = t("proceedwebtitle");
                const pTexteNotAvailable = answer;
                PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
            }
        });
    }

    const goBackProceedWebHandler = async (e) => {
        e.preventDefault();

        navigateProceedWeb('/websitetab')
    }

    return ( 
        <div id="proceedweb">
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t('proceedwebtitle')}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <LongFormScrollerWidgetSm>
                <div id="modaloutproceedweb"></div>
                <div id="backdropoutproceedweb"></div>
                <p className="pservicestyles"><br/>{t("proceedwebdescription")}</p>
                <div id="webstepone">
                    <TextProceedService onClick={popweb1Handler}>{t("webquestion_1")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webSiteQuestion1Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popweb2Handler}>{t("webquestion_2")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webSiteQuestion2Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popweb3Handler}>{t("webquestion_3")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webSiteQuestion3Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <ButtonStart onClick={forwardProceedWeb1Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="websteptwo">
                    <TextProceedService onClick={popweb4Handler}>{t("webquestion_4")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webSiteQuestion4Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popweb5Handler}>{t("webquestion_5")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webSiteQuestion5Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popweb6Handler}>{t("webquestion_6")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webSiteQuestion6Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedWeb2Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedWeb2Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="webstepthree">
                    <TextProceedService onClick={popweb7Handler}>{t("webquestion_7")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webSiteQuestion7Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popweb8Handler}>{t("webquestion_8")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webSiteQuestion8Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popweb9Handler}>{t("webquestion_9")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webSiteQuestion9Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedWeb3Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedWeb3Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="webstepfour">
                    <TextProceedService onClick={popweb10Handler}>{t("webquestion_10")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webSiteQuestion10Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popweb11Handler}>{t("webquestion_11")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webSiteQuestion11Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popweb12Handler}>{t("webquestion_12")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webSiteQuestion12Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedWeb4Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedWeb4Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="modeproceedweb">
                    <TextProceedService>{t("webquestion_13")}</TextProceedService>
                    <TextLoginService><MdOutlineAttachEmail style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput id="emailweb" name="emailweb" value={email} onChange={handleWebChange} ref={webSiteQuestion13Ref}  placeholder={t("placemail")}/>
                        {error && <h5 style={{color: 'white'}}>{error}</h5>}
                    </div>
                    <br/>
                    <TextLoginService><AiOutlineWhatsApp style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                            <GlobalLargeInput type='text' ref={webSiteQuestion14Ref}  placeholder={t("placewhatsapp")}/>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedWeb5Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedWeb5Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="submitproceedweb">
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedWeb6Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={submitProceedWebHandler}>
                                    <MdOutlineCreate style={{fontSize:'30px'}} />
                                   {t("creer")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackProceedWebHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertproceedweb"></div>
                <div id="backdropoutalertproceedweb"></div>
            </LongFormScrollerWidgetSm>
        </div>
    );
}

export default ProceedWeb;