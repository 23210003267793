import React, { useEffect } from 'react';
import './accueil.css';
import image from '../carions.png';
//import i18n from 'i18next';
import { handleLanguageChange } from '../helperfunctions/Helper';

function ImageCentrale() {
    // Function to clear localStorage
    const clearLocalStorage = () => {
        localStorage.clear();
    };

    useEffect(() => {
        // Clear localStorage on mount
        clearLocalStorage();
    
        // Get the user's device language
        const userLanguage = navigator.language.substring(0, 2);
    
        // Call the handleLanguageChange function to set the language in localStorage and i18n
        handleLanguageChange(userLanguage);
    }, []);

    return (
        <div style={{marginTop:'-5rem'}} id='imagecentrale'>
            <div className='image_centrale'>
                <img className="image_center" src={image} alt='logocenter' />
            </div>
        </div>
    )
}

export default ImageCentrale