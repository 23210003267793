import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../../reactquilleditor/myeditor.css';
//
import {MdOutlineEditNote, MdOutlineConfirmationNumber, MdSettingsBackupRestore,} from 'react-icons/md';

import { useRef, useState } from "react";
import Axios from 'axios';
import '../../../translations/Translation';

import { AlertModal } from "../../../helperfunctions/Helper";
import { 
    FormScrollerWidgetSm,
    TextLoginService, 
    LargeSelect,
    GlobalLargeInput,
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
    TextAreaView,
} from "../../../global/Styles";
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';

function EditServicesEditor() {
    const {t} = useTranslation();

    const navigateEditServicesEditor = useNavigate();

    //alert
    const elementOutId = "modaloutalertserviceseditoredit";
    const elementDropId = "backdropoutalertserviceseditoredit";
    const buttonHandler = "ouiHandlerAlertServicesEditorEditBtn";

    const [valueEdit, setValueEdit] = useState([0]);

    const editSelectTypeServicesContenuRef = useRef(null);
    const editSelectPlanServicesContenuRef = useRef(null);
    const editSelectStatusServicesContenuRef = useRef(null);
    //
    const idEditServiceContenuRef = useRef(null);
    const editParagraphServiceContenu1Ref = useRef(null);
    const editParagraphServiceContenu2Ref = useRef(null);
    const editParagraphServiceContenu3Ref = useRef(null);
    const editParagraphServiceContenu4Ref = useRef(null);
    const editTitreServicesContenuRef = useRef(null);
    const editPricesServicesContenuRef = useRef(null);
    const editDescriptionServicesContenuRef = useRef(null);
    const editDeliveryServicesContenuRef = useRef(null);
    const editRevisionServicesContenuRef = useRef(null);
    const editPageServicesContenuRef = useRef(null);
    const editDesignServicesContenuRef = useRef(null);
    const editContentServicesContenuRef = useRef(null);
    const editResponsiveServicesContenuRef = useRef(null);
    const editSourceServicesContenuRef = useRef(null);

    const editTitleHandler = async (e) => {
        e.preventDefault();

        editTitreServicesContenuRef.current.value = t("titre_service");
    }

    const editPriceHandler = async (e) => {
        e.preventDefault();

        editPricesServicesContenuRef.current.value = t("price");
    }

    const editDeliveryHandler = async (e) => {
        e.preventDefault();

        editDeliveryServicesContenuRef.current.value = t("delivery");
    }

    const editRevisionHandler = async (e) => {
        e.preventDefault();

        editRevisionServicesContenuRef.current.value = t("revision");
    }

    const editPageHandler = async (e) => {
        e.preventDefault();

        editPageServicesContenuRef.current.value = t("page");
    }

    const editDesignHandler = async (e) => {
        e.preventDefault();

        editDesignServicesContenuRef.current.value = t("customization");
    }

    const editContentHandler = async (e) => {
        e.preventDefault();

        editContentServicesContenuRef.current.value = t("content");
    }

    const editResponsiveHandler = async (e) => {
        e.preventDefault();

        editResponsiveServicesContenuRef.current.value = t("responsive");
    }

    const editSourceHandler = async (e) => {
        e.preventDefault();

        editSourceServicesContenuRef.current.value = t("source");
    }

    //http://localhost:5000/update/service/confirm

    const confirmEditServicesEditorHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const fonctionuserDiv = document.getElementById('fonctionuser').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        //
        const vieweditservicecontenuDiv = document.getElementById('vieweditservicecontenu');

        const editSelectTypeServicesContenu = editSelectTypeServicesContenuRef.current.value;
        const editSelectPlanServicesContenu = editSelectPlanServicesContenuRef.current.value;
        const editSelectStatusServicesContenu = editSelectStatusServicesContenuRef.current.value;
        var resultat1 = "";

        if(!editSelectTypeServicesContenu){
            const pTexteSaisir = t("type_contenu");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
            //
            vieweditservicecontenuDiv.style.display = 'none';
        }else if(!editSelectPlanServicesContenu){
            const pTexteSaisir = t("titre_plan");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
            //
            vieweditservicecontenuDiv.style.display = 'none';
        }else if(!editSelectStatusServicesContenu){
            const pTexteSaisir = t("select_status");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
            //
            vieweditservicecontenuDiv.style.display = 'none';
        }else{
            await Axios.post("https://micarionsapi.carions.net/update/service/confirm", {
                choixlangueDiv:choixlangueDiv,
                fonctionuserDiv:fonctionuserDiv,
                codeutilisateurDiv:codeutilisateurDiv,
                editSelectTypeServicesContenu:editSelectTypeServicesContenu,
                editSelectPlanServicesContenu:editSelectPlanServicesContenu,
                editSelectStatusServicesContenu:editSelectStatusServicesContenu,
            }).then((response1) => {
                resultat1 = response1.data.length;
                if(resultat1 === 0){
                    const pTexteNotAvailable = t("noteindisponible");
                    AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                    //
                    vieweditservicecontenuDiv.style.display = 'none';
                }else{
                    setValueEdit(response1.data);
                    //
                    vieweditservicecontenuDiv.style.display = 'block';
                }
            });
        }

    }

    const submitEditServiceEditorHandler = async (e) => {
        e.preventDefault();

        //
        const vieweditservicecontenuDiv = document.getElementById('vieweditservicecontenu');

        const idEditServiceContenu = idEditServiceContenuRef.current.value;
        const editSelectTypeServicesContenu = editSelectTypeServicesContenuRef.current.value;
        const editSelectPlanServicesContenu = editSelectPlanServicesContenuRef.current.value;
        const editParagraphServiceContenu1 = editParagraphServiceContenu1Ref.current.value;
        const editParagraphServiceContenu2 = editParagraphServiceContenu2Ref.current.value;
        const editParagraphServiceContenu3 = editParagraphServiceContenu3Ref.current.value;
        const editParagraphServiceContenu4 = editParagraphServiceContenu4Ref.current.value;
        const editTitreServicesContenu = editTitreServicesContenuRef.current.value;
        const editPricesServicesContenu = editPricesServicesContenuRef.current.value;
        const editDescriptionServicesContenu = editDescriptionServicesContenuRef.current.value;
        const editDeliveryServicesContenu = editDeliveryServicesContenuRef.current.value;
        const editRevisionServicesContenu = editRevisionServicesContenuRef.current.value;
        const editPageServicesContenu = editPageServicesContenuRef.current.value;
        const editDesignServicesContenu = editDesignServicesContenuRef.current.value;
        const editContentServicesContenu = editContentServicesContenuRef.current.value;
        const editResponsiveServicesContenu = editResponsiveServicesContenuRef.current.value;
        const editSourceServicesContenu = editSourceServicesContenuRef.current.value;

        if(!editSelectTypeServicesContenu){
            const pTexteSaisir = t("type_contenu");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editSelectPlanServicesContenu){
            const pTexteSaisir = t("titre_plan");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editParagraphServiceContenu1){
            const pTexteSaisir = t("type_paragraphe");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editParagraphServiceContenu2){
            const pTexteSaisir = t("type_paragraphe");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editParagraphServiceContenu3){
            const pTexteSaisir = t("type_paragraphe");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editParagraphServiceContenu4){
            const pTexteSaisir = t("type_paragraphe");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editTitreServicesContenu){
            const pTexteSaisir = t("titre_contenu");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editPricesServicesContenu){
            const pTexteSaisir = t("titre_price");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editDescriptionServicesContenu){
            const pTexteSaisir = t("titre_description");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editDeliveryServicesContenu){
            const pTexteSaisir = t("titre_delivery");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editRevisionServicesContenu){
            const pTexteSaisir = t("titre_revision");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editPageServicesContenu){
            const pTexteSaisir = t("titre_page");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editDesignServicesContenu){
            const pTexteSaisir = t("titre_design");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler); 
        }else if(!editContentServicesContenu){
            const pTexteSaisir = t("titre_upload");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editResponsiveServicesContenu){
            const pTexteSaisir = t("titre_responsive");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editSourceServicesContenu){
            const pTexteSaisir = t("titre_source");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler); 
        }else{
            await Axios.post("https://micarionsapi.carions.net/service/tab/edit", {
                idEditServiceContenu:idEditServiceContenu,
                editSelectTypeServicesContenu:editSelectTypeServicesContenu,
                editSelectPlanServicesContenu:editSelectPlanServicesContenu,
                editParagraphServiceContenu1:editParagraphServiceContenu1,
                editParagraphServiceContenu2:editParagraphServiceContenu2,
                editParagraphServiceContenu3:editParagraphServiceContenu3,
                editParagraphServiceContenu4:editParagraphServiceContenu4,
                editTitreServicesContenu:editTitreServicesContenu,
                editPricesServicesContenu:editPricesServicesContenu,
                editDescriptionServicesContenu:editDescriptionServicesContenu,
                editDeliveryServicesContenu:editDeliveryServicesContenu,
                editRevisionServicesContenu:editRevisionServicesContenu,
                editPageServicesContenu:editPageServicesContenu,
                editDesignServicesContenu:editDesignServicesContenu,
                editContentServicesContenu:editContentServicesContenu,
                editResponsiveServicesContenu:editResponsiveServicesContenu,
                editSourceServicesContenu:editSourceServicesContenu,
            }).then(() => {
                vieweditservicecontenuDiv.style.display = 'none';
            });
        }
    }

    const goBackEditServicesEditorHandler = async (e) => {
        e.preventDefault();

        navigateEditServicesEditor('/crudservicescompo')
    }

    return ( 
        <div id="editserviceseditor">
            <FormScrollerWidgetSm>
                <div style={{overflowY:'scroll'}} id="editservicestitreeditor">
                    <br/>
                    <TextLoginService>{t("typecontenu")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <LargeSelect ref={editSelectTypeServicesContenuRef}>
                            <option value=''>{t("typecontenu")}</option>
                            <option value='web'>Web Development</option>
                            <option value='app'>App Development</option>
                            <option value='ecom'>E-Commerce Development</option>
                            <option value='keywordseo'>Keyword Research SEO</option>
                            <option value='onpageseo'>On-Page Optimization SEO</option>
                            <option value='technicalseo'>Technical SEO Audit</option>
                            <option value='monthlyseo'>Monthly Reporting SEO</option>
                            <option value='codingsoft'>Software Coding Services</option>
                            <option value='designsoft'>Software Design Services</option>
                            <option value='devsoft'>Software Development Services</option>
                            <option value='maintsoft'>Software Maintenance Services</option>
                            <option value='managesoft'>Software Management Services</option>
                            <option value='miscsoft'>Software Miscellanious Services</option>
                            <option value='hosting'>Hosting</option>
                            <option value='rental'>Rental Apps</option>
                            <option value='purchase'>Purchase Apps</option>
                            <option value='training'>Training</option>
                        </LargeSelect>
                    </div>
                    <TextLoginService>{t("type_plan")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <LargeSelect ref={editSelectPlanServicesContenuRef}>
                            <option value=''>{t("select_plan")}</option>
                            <option value='basic'>{t("basic_plan")}</option>
                            <option value='standard'>{t("standard_plan")}</option>
                            <option value='Premium'>{t("premium_plan")}</option>
                        </LargeSelect>
                    </div>
                    <TextLoginService>{t("status_contenu")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <LargeSelect ref={editSelectStatusServicesContenuRef}>
                            <option value=''>{t("select_status")}</option>
                            <option value='online'>Online</option>
                            <option value='pause'>Pause</option>
                        </LargeSelect>
                    </div>
                    <br/>
                    <HandlerContainerDiv>
                        <div>
                            <ButtonStart onClick={confirmEditServicesEditorHandler}>
                                <MdOutlineConfirmationNumber style={{fontSize:'30px'}} />
                                {t("confirmer")}
                            </ButtonStart>
                        </div>
                        <div>
                            <BackStart onClick={goBackEditServicesEditorHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>

                    <div id="vieweditservicecontenu">
                        {valueEdit.map((valview, i) => (
                            <div key={i}>
                                <input style={{display:'block'}} ref={idEditServiceContenuRef} defaultValue={valview.id_services} />
                                <TextLoginService>{t("paragraphe_1")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <TextAreaView ref={editParagraphServiceContenu1Ref} defaultValue={valview.services_paragraphone}></TextAreaView>
                                </div>

                                <TextLoginService>{t("paragraphe_2")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <TextAreaView ref={editParagraphServiceContenu2Ref} defaultValue={valview.services_paragraphtwo}></TextAreaView>
                                </div>

                                <TextLoginService>{t("paragraphe_3")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <TextAreaView ref={editParagraphServiceContenu3Ref} defaultValue={valview.services_paragraphthree}></TextAreaView>
                                </div>

                                <TextLoginService>{t("paragraphe_4")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <TextAreaView ref={editParagraphServiceContenu4Ref} defaultValue={valview.services_paragraphfour}></TextAreaView>
                                </div>

                                <TextLoginService>{t("service_title")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <GlobalLargeInput onClick={editTitleHandler} type='text' ref={editTitreServicesContenuRef} defaultValue={valview.services_titre} />
                                </div>

                                <TextLoginService>{t("service_price")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <GlobalLargeInput onClick={editPriceHandler} type='text' ref={editPricesServicesContenuRef} defaultValue={valview.services_price} />
                                </div>

                                <TextLoginService>{t("plan_description")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <TextAreaView ref={editDescriptionServicesContenuRef} defaultValue={valview.services_description}></TextAreaView>
                                </div>

                                <TextLoginService>{t("days_delivery")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <GlobalLargeInput onClick={editDeliveryHandler} type='text' ref={editDeliveryServicesContenuRef} defaultValue={valview.services_delivery} />
                                </div>

                                <TextLoginService>{t("revision_number")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <GlobalLargeInput onClick={editRevisionHandler} type='text' ref={editRevisionServicesContenuRef} defaultValue={valview.services_revision} />
                                </div>

                                <TextLoginService>{t("page_number")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <GlobalLargeInput onClick={editPageHandler} type='text' ref={editPageServicesContenuRef} defaultValue={valview.services_page} />
                                </div>

                                <TextLoginService>{t("design_customization")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <GlobalLargeInput onClick={editDesignHandler} type='text' ref={editDesignServicesContenuRef} defaultValue={valview.services_design} />
                                </div>

                                <TextLoginService>{t("content_upload")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <GlobalLargeInput onClick={editContentHandler} type='text' ref={editContentServicesContenuRef} defaultValue={valview.services_content} />
                                </div>

                                <TextLoginService>{t("responsive_design")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <GlobalLargeInput onClick={editResponsiveHandler} type='text' ref={editResponsiveServicesContenuRef} defaultValue={valview.services_responsive} />
                                </div>

                                <TextLoginService>{t("source_code")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <GlobalLargeInput onClick={editSourceHandler} type='text' ref={editSourceServicesContenuRef}  defaultValue={valview.services_source}/>
                                </div>

                                <br/><br/>
                                <HandlerContainerDiv>
                                    <div>
                                        <ButtonStart onClick={submitEditServiceEditorHandler}>
                                            <MdOutlineEditNote style={{fontSize:'30px'}} />
                                            {t("modifier")}
                                        </ButtonStart>
                                    </div>
                                    <div>
                                        <BackStart onClick={goBackEditServicesEditorHandler}>
                                            <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                            {t("retour")}
                                        </BackStart>
                                    </div>
                                </HandlerContainerDiv>
                            </div>
                        ))}
                    </div>
                </div>
                <br/><br/>
                <div id="modaloutalertserviceseditoredit"></div>
                <div id="backdropoutalertserviceseditoredit"></div>
            </FormScrollerWidgetSm>
        </div>
    );
}

export default EditServicesEditor;