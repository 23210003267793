import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../proceedweb.css';
import { useRef, useState } from "react";
//
import { MdOutlineCreate, MdSettingsBackupRestore, MdOutlineArrowForwardIos, MdOutlineArrowBackIos, MdOutlineAttachEmail } from 'react-icons/md';
import { AiOutlineWhatsApp } from 'react-icons/ai';
//
import { AlertModal, getCurrentDate, PopupModal } from "../../helperfunctions/Helper";
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
import {  
    SpanGlobalSpan,
    GlobalStrongTitle,
    LongFormScrollerWidgetSm,
    TextProceedService, 
    TextLoginService,
    GlobalLargeInput, 
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
    TextArea,
} from "../../global/Styles";

import Axios from 'axios';

function ProceedOptimization() {
    const {t} = useTranslation();

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleSeoOptimChange = event => {
        if (!isValidEmail(event.target.value)) {
          setError(t('invalidemail'));
        } else {
          setError(null);
        }
    
        setEmail(event.target.value);
    };

    const navigateProceedSeoOptim = useNavigate();

    //alert
    const elementOutId = "modaloutproceedseooptim";
    const elementDropId = "backdropoutproceedseooptim";
    const buttonHandler = "ouiHandlerProceedSeoOptimBtn";
    //
    const elementOutAlertId = "modaloutalertproceedseooptim";
    const elementDropAlertId = "backdropoutalertproceedseooptim";
    const buttonAlertHandler = "ouiHandleralertProceedSeoOptimBtn";

    const optimSeoQuestion1Ref = useRef(null);
    const optimSeoQuestion2Ref = useRef(null);
    const optimSeoQuestion3Ref = useRef(null);
    //
    const optimSeoQuestion4Ref = useRef(null);
    const optimSeoQuestion5Ref = useRef(null);
    const optimSeoQuestion6Ref = useRef(null);
    //
    const optimSeoQuestion7Ref = useRef(null);
    const optimSeoQuestion8Ref = useRef(null);
    const optimSeoQuestion9Ref = useRef(null);
    //
    const optimSeoQuestion10Ref = useRef(null);
    const optimSeoQuestion11Ref = useRef(null);
    const optimSeoQuestion12Ref = useRef(null);
    //
    const optimSeoQuestion13Ref = useRef(null);
    const optimSeoQuestion14Ref = useRef(null);

    const popseooptim1Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_1");
        const pTexteNotAvailable = t("hintseoquestion_1");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseooptim2Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_2");
        const pTexteNotAvailable = t("hintseoquestion_2");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseooptim3Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_3");
        const pTexteNotAvailable = t("hintseoquestion_3");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const popseooptim4Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_4");
        const pTexteNotAvailable = t("hintseoquestion_4");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseooptim5Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_5");
        const pTexteNotAvailable = t("hintseoquestion_5");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseooptim6Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_6");
        const pTexteNotAvailable = t("hintseoquestion_6");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const popseooptim7Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_7");
        const pTexteNotAvailable = t("hintseoquestion_7");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseooptim8Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_8");
        const pTexteNotAvailable = t("hintseoquestion_8");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseooptim9Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_9");
        const pTexteNotAvailable = t("hintseoquestion_9");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const popseooptim10Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_10");
        const pTexteNotAvailable = t("hintseoquestion_10");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseooptim11Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_11");
        const pTexteNotAvailable = t("hintseoquestion_11");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseooptim12Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_12");
        const pTexteNotAvailable = t("hintseoquestion_12");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const forwardProceedSeoOptim1Handler = async (e) => {
        e.preventDefault();

        const seooptimsteponeDiv = document.getElementById('seooptimstepone');
        const seooptimsteptwoDiv = document.getElementById('seooptimsteptwo');
        const seooptimstepthreeDiv = document.getElementById('seooptimstepthree');
        const seooptimstepfourDiv = document.getElementById('seooptimstepfour');
        const modeproceedseooptimDiv = document.getElementById('modeproceedseooptim');
        const submitproceedseooptimDiv = document.getElementById('submitproceedseooptim');
        //
        seooptimsteponeDiv.style.display = 'none';
        seooptimsteptwoDiv.style.display = 'block';
        seooptimstepthreeDiv.style.display = 'none';
        seooptimstepfourDiv.style.display = 'none';
        modeproceedseooptimDiv.style.display = 'none';
        submitproceedseooptimDiv.style.display = 'none';
    }

    const goBackProceedSeoOptim2Handler = async (e) => {
        e.preventDefault();

        const seooptimsteponeDiv = document.getElementById('seooptimstepone');
        const seooptimsteptwoDiv = document.getElementById('seooptimsteptwo');
        const seooptimstepthreeDiv = document.getElementById('seooptimstepthree');
        const seooptimstepfourDiv = document.getElementById('seooptimstepfour');
        const modeproceedseooptimDiv = document.getElementById('modeproceedseooptim');
        const submitproceedseooptimDiv = document.getElementById('submitproceedseooptim');
        //
        seooptimsteponeDiv.style.display = 'block';
        seooptimsteptwoDiv.style.display = 'none';
        seooptimstepthreeDiv.style.display = 'none';
        seooptimstepfourDiv.style.display = 'none';
        modeproceedseooptimDiv.style.display = 'none';
        submitproceedseooptimDiv.style.display = 'none';
    }
    //
    const forwardProceedSeoOptim2Handler = async (e) => {
        e.preventDefault();

        const seooptimsteponeDiv = document.getElementById('seooptimstepone');
        const seooptimsteptwoDiv = document.getElementById('seooptimsteptwo');
        const seooptimstepthreeDiv = document.getElementById('seooptimstepthree');
        const seooptimstepfourDiv = document.getElementById('seooptimstepfour');
        const modeproceedseooptimDiv = document.getElementById('modeproceedseooptim');
        const submitproceedseooptimDiv = document.getElementById('submitproceedseooptim');
        //
        seooptimsteponeDiv.style.display = 'none';
        seooptimsteptwoDiv.style.display = 'none';
        seooptimstepthreeDiv.style.display = 'block';
        seooptimstepfourDiv.style.display = 'none';
        modeproceedseooptimDiv.style.display = 'none';
        submitproceedseooptimDiv.style.display = 'none';
    }

    const goBackProceedSeoOptim3Handler = async (e) => {
        e.preventDefault();

        const seooptimsteponeDiv = document.getElementById('seooptimstepone');
        const seooptimsteptwoDiv = document.getElementById('seooptimsteptwo');
        const seooptimstepthreeDiv = document.getElementById('seooptimstepthree');
        const seooptimstepfourDiv = document.getElementById('seooptimstepfour');
        const modeproceedseooptimDiv = document.getElementById('modeproceedseooptim');
        const submitproceedseooptimDiv = document.getElementById('submitproceedseooptim');
        //
        seooptimsteponeDiv.style.display = 'none';
        seooptimsteptwoDiv.style.display = 'block';
        seooptimstepthreeDiv.style.display = 'none';
        seooptimstepfourDiv.style.display = 'none';
        modeproceedseooptimDiv.style.display = 'none';
        submitproceedseooptimDiv.style.display = 'none';
    }
    //
    const forwardProceedSeoOptim3Handler = async (e) => {
        e.preventDefault();

        const seooptimsteponeDiv = document.getElementById('seooptimstepone');
        const seooptimsteptwoDiv = document.getElementById('seooptimsteptwo');
        const seooptimstepthreeDiv = document.getElementById('seooptimstepthree');
        const seooptimstepfourDiv = document.getElementById('seooptimstepfour');
        const modeproceedseooptimDiv = document.getElementById('modeproceedseooptim');
        const submitproceedseooptimDiv = document.getElementById('submitproceedseooptim');
        //
        seooptimsteponeDiv.style.display = 'none';
        seooptimsteptwoDiv.style.display = 'none';
        seooptimstepthreeDiv.style.display = 'none';
        seooptimstepfourDiv.style.display = 'block';
        modeproceedseooptimDiv.style.display = 'none';
        submitproceedseooptimDiv.style.display = 'none';
    }

    const goBackProceedSeoOptim4Handler = async (e) => {
        e.preventDefault();

        const seooptimsteponeDiv = document.getElementById('seooptimstepone');
        const seooptimsteptwoDiv = document.getElementById('seooptimsteptwo');
        const seooptimstepthreeDiv = document.getElementById('seooptimstepthree');
        const seooptimstepfourDiv = document.getElementById('seooptimstepfour');
        const modeproceedseooptimDiv = document.getElementById('modeproceedseooptim');
        const submitproceedseooptimDiv = document.getElementById('submitproceedseooptim');
        //
        seooptimsteponeDiv.style.display = 'none';
        seooptimsteptwoDiv.style.display = 'none';
        seooptimstepthreeDiv.style.display = 'block';
        seooptimstepfourDiv.style.display = 'none';
        modeproceedseooptimDiv.style.display = 'none';
        submitproceedseooptimDiv.style.display = 'none';
    }
    //
    const forwardProceedSeoOptim4Handler = async (e) => {
        e.preventDefault();

        const seooptimsteponeDiv = document.getElementById('seooptimstepone');
        const seooptimsteptwoDiv = document.getElementById('seooptimsteptwo');
        const seooptimstepthreeDiv = document.getElementById('seooptimstepthree');
        const seooptimstepfourDiv = document.getElementById('seooptimstepfour');
        const modeproceedseooptimDiv = document.getElementById('modeproceedseooptim');
        const submitproceedseooptimDiv = document.getElementById('submitproceedseooptim');
        //
        seooptimsteponeDiv.style.display = 'none';
        seooptimsteptwoDiv.style.display = 'none';
        seooptimstepthreeDiv.style.display = 'none';
        seooptimstepfourDiv.style.display = 'none';
        modeproceedseooptimDiv.style.display = 'block';
        submitproceedseooptimDiv.style.display = 'none';
    }

    const goBackProceedSeoOptim5Handler = async (e) => {
        e.preventDefault();

        const seooptimsteponeDiv = document.getElementById('seooptimstepone');
        const seooptimsteptwoDiv = document.getElementById('seooptimsteptwo');
        const seooptimstepthreeDiv = document.getElementById('seooptimstepthree');
        const seooptimstepfourDiv = document.getElementById('seooptimstepfour');
        const modeproceedseooptimDiv = document.getElementById('modeproceedseooptim');
        const submitproceedseooptimDiv = document.getElementById('submitproceedseooptim');
        //
        seooptimsteponeDiv.style.display = 'none';
        seooptimsteptwoDiv.style.display = 'none';
        seooptimstepthreeDiv.style.display = 'none';
        seooptimstepfourDiv.style.display = 'block';
        modeproceedseooptimDiv.style.display = 'none';
        submitproceedseooptimDiv.style.display = 'none';
    }
    //
    const forwardProceedSeoOptim5Handler = async (e) => {
        e.preventDefault();

        const optimSeoQuestion13 = optimSeoQuestion13Ref.current.value;
        const optimSeoQuestion14 = optimSeoQuestion14Ref.current.value;
        //
        const seooptimsteponeDiv = document.getElementById('seooptimstepone');
        const seooptimsteptwoDiv = document.getElementById('seooptimsteptwo');
        const seooptimstepthreeDiv = document.getElementById('seooptimstepthree');
        const seooptimstepfourDiv = document.getElementById('seooptimstepfour');
        const modeproceedseooptimDiv = document.getElementById('modeproceedseooptim');
        const submitproceedseooptimDiv = document.getElementById('submitproceedseooptim');
        //
        if(!optimSeoQuestion13){
            const pTexteSaisir = t("enteremail");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else if(!optimSeoQuestion14){
            const pTexteSaisir = t("enterphone");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else{
            //
            seooptimsteponeDiv.style.display = 'none';
            seooptimsteptwoDiv.style.display = 'none';
            seooptimstepthreeDiv.style.display = 'none';
            seooptimstepfourDiv.style.display = 'none';
            modeproceedseooptimDiv.style.display = 'none';
            submitproceedseooptimDiv.style.display = 'block';
        }
    }

    const goBackProceedSeoOptim6Handler = async (e) => {
        e.preventDefault();

        const seooptimsteponeDiv = document.getElementById('seooptimstepone');
        const seooptimsteptwoDiv = document.getElementById('seooptimsteptwo');
        const seooptimstepthreeDiv = document.getElementById('seooptimstepthree');
        const seooptimstepfourDiv = document.getElementById('seooptimstepfour');
        const modeproceedseooptimDiv = document.getElementById('modeproceedseooptim');
        const submitproceedseooptimDiv = document.getElementById('submitproceedseooptim');
        //
        seooptimsteponeDiv.style.display = 'none';
        seooptimsteptwoDiv.style.display = 'none';
        seooptimstepthreeDiv.style.display = 'none';
        seooptimstepfourDiv.style.display = 'none';
        modeproceedseooptimDiv.style.display = 'block';
        submitproceedseooptimDiv.style.display = 'none';
    }

    //http://localhost:5000/check/seooptimservice

    const submitProceedSeoOptimHandler = async (e) => {
        e.preventDefault();

        const optimSeoQuestion1 = optimSeoQuestion1Ref.current.value;
        const optimSeoQuestion2 = optimSeoQuestion2Ref.current.value;
        const optimSeoQuestion3 = optimSeoQuestion3Ref.current.value;
        //websteptwo
        const optimSeoQuestion4 = optimSeoQuestion4Ref.current.value;
        const optimSeoQuestion5 = optimSeoQuestion5Ref.current.value;
        const optimSeoQuestion6 = optimSeoQuestion6Ref.current.value;
        //webstepthree
        const optimSeoQuestion7 = optimSeoQuestion7Ref.current.value;
        const optimSeoQuestion8 = optimSeoQuestion8Ref.current.value;
        const optimSeoQuestion9 = optimSeoQuestion9Ref.current.value;
        //webstepfour
        const optimSeoQuestion10 = optimSeoQuestion10Ref.current.value;
        const optimSeoQuestion11 = optimSeoQuestion11Ref.current.value;
        const optimSeoQuestion12 = optimSeoQuestion12Ref.current.value;
        //modeproceedweb
        const optimSeoQuestion13 = optimSeoQuestion13Ref.current.value;
        const optimSeoQuestion14 = optimSeoQuestion14Ref.current.value;
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const theDate = getCurrentDate();
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = "seooptimization";
        const servicePlan = document.getElementById('typeplan').innerText;
        var createTicketCode = "";
        //
        var resultat1 = "";
        //
        //generate random ticket code
        var randomNumber = Math.floor((Math.random() * 100000) + 1);
        createTicketCode = randomNumber+''+codeutilisateurDiv;
        //
        await Axios.post("https://micarionsapi.carions.net/check/seooptimservice", {
            codeutilisateurDiv:codeutilisateurDiv,
            typeService:typeService,
            servicePlan:servicePlan,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                //insert
                Axios.post("https://micarionsapi.carions.net/quest/seooptimservice", {
                    choixlangueDiv:choixlangueDiv,
                    usernamedetailDiv:usernamedetailDiv,
                    codeutilisateurDiv:codeutilisateurDiv,
                    //
                    typeService:typeService,
                    servicePlan:servicePlan,
                    //
                    createTicketCode:createTicketCode,
                    //
                    optimSeoQuestion1:optimSeoQuestion1,
                    optimSeoQuestion2:optimSeoQuestion2,
                    optimSeoQuestion3:optimSeoQuestion3,
                    //
                    optimSeoQuestion4:optimSeoQuestion4,
                    optimSeoQuestion5:optimSeoQuestion5,
                    optimSeoQuestion6:optimSeoQuestion6,
                    //
                    optimSeoQuestion7:optimSeoQuestion7,
                    optimSeoQuestion8:optimSeoQuestion8,
                    optimSeoQuestion9:optimSeoQuestion9,
                    //
                    optimSeoQuestion10:optimSeoQuestion10,
                    optimSeoQuestion11:optimSeoQuestion11,
                    optimSeoQuestion12:optimSeoQuestion12,
                    //
                    optimSeoQuestion13:optimSeoQuestion13,
                    optimSeoQuestion14:optimSeoQuestion14,
                    //
                    theDate:theDate,
                }).then(() => {
                    var answer1 = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                    const hTitleTexte = t("proceedwebapptitle");
                    const pTexteNotAvailable = answer1;
                    PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
                });
            }else{
                //set up modal to update
                var answer = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                const hTitleTexte = t("proceedwebapptitle");
                const pTexteNotAvailable = answer;
                PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
            }
        });
    }

    const goBackProceedSeoOptimHandler = async (e) => {
        e.preventDefault();

        navigateProceedSeoOptim('/onpagetab');
    }

    return ( 
        <div id="proceedoptimization">
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t('proceedseooptimtitle')}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <LongFormScrollerWidgetSm>
                <div id="modaloutproceedseooptim"></div>
                <div id="backdropoutproceedseooptim"></div>
                <p className="pservicestyles"><br/>{t("proceedseodescription")}</p>
                <div id="seooptimstepone">
                    <TextProceedService onClick={popseooptim1Handler}>{t("seoquestion_1")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={optimSeoQuestion1Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseooptim2Handler}>{t("seoquestion_2")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={optimSeoQuestion2Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseooptim3Handler}>{t("seoquestion_3")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={optimSeoQuestion3Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <ButtonStart onClick={forwardProceedSeoOptim1Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="seooptimsteptwo">
                    <TextProceedService onClick={popseooptim4Handler}>{t("seoquestion_4")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={optimSeoQuestion4Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseooptim5Handler}>{t("seoquestion_5")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={optimSeoQuestion5Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseooptim6Handler}>{t("seoquestion_6")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={optimSeoQuestion6Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedSeoOptim2Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedSeoOptim2Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="seooptimstepthree">
                    <TextProceedService onClick={popseooptim7Handler}>{t("seoquestion_7")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={optimSeoQuestion7Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseooptim8Handler}>{t("seoquestion_8")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={optimSeoQuestion8Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseooptim9Handler}>{t("seoquestion_9")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={optimSeoQuestion9Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedSeoOptim3Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedSeoOptim3Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="seooptimstepfour">
                    <TextProceedService onClick={popseooptim10Handler}>{t("seoquestion_10")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={optimSeoQuestion10Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseooptim11Handler}>{t("seoquestion_11")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={optimSeoQuestion11Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseooptim12Handler}>{t("seoquestion_12")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={optimSeoQuestion12Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedSeoOptim4Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedSeoOptim4Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="modeproceedseooptim">
                    <TextProceedService>{t("webquestion_13")}</TextProceedService>
                    <TextLoginService><MdOutlineAttachEmail style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput id="emailseooptim" name="emailseooptim" value={email} onChange={handleSeoOptimChange} ref={optimSeoQuestion13Ref} placeholder={t("placemail")}/>
                        {error && <h5 style={{color: 'white'}}>{error}</h5>}
                    </div>
                    <br/>
                    <TextLoginService><AiOutlineWhatsApp style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                            <GlobalLargeInput type='number' ref={optimSeoQuestion14Ref}  placeholder={t("placewhatsapp")}/>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedSeoOptim5Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedSeoOptim5Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="submitproceedseooptim">
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedSeoOptim6Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={submitProceedSeoOptimHandler}>
                                    <MdOutlineCreate style={{fontSize:'30px'}} />
                                   {t("creer")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackProceedSeoOptimHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertproceedseooptim"></div>
                <div id="backdropoutalertproceedseooptim"></div>
            </LongFormScrollerWidgetSm>
        </div>
    );
}

export default ProceedOptimization;