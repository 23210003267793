import React from 'react';
import { useNavigate } from "react-router-dom";
import '../../../accueil/accueil.css';
//
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';

//import { AlertModal } from "../../../helperfunctions/Helper";

import { 
    DivContainerSlide, 
    GlobalStrongTitle,
    SpanGlobalSpan,
    FormScrollerWidgetSm,
    //TextLogin, 
    GlobalLargeInput,
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
} from "../../../global/Styles";

import { MdOutlineDelete, MdSettingsBackupRestore,} from 'react-icons/md';

import { useRef, useState, useEffect } from "react";
import Axios from 'axios';

function DeleteAccount() {
    const {t} = useTranslation();

    const navigateBackDeleteAccount = useNavigate();

    //set to retriev all comments
    const [valueDeleteAccount, setValueDeleteAccount] = useState([0]);

    //http://localhost:5000
    //https://micarionsapi.carions.net

    useEffect(() => {
        var resultat = '';
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
  
        const fetchAccount = async () => {
            await Axios.post("https://micarionsapi.carions.net/retrieve/account", {
              codeutilisateurDiv:codeutilisateurDiv,
            }).then((responseaccount) => {
                resultat = responseaccount.data.length;
                if(resultat === 0){
                    //
                }else{
                  setValueDeleteAccount(responseaccount.data);
                }
            });
        }
        fetchAccount();
    }, []);

    const deleteIdRef = useRef(null);

    const submitDeleteAccountHandler = async (e) => {
        e.preventDefault();

        const usernamedetailDiv = document.getElementById('usernamedetail');
        const codeutilisateurDiv = document.getElementById('codeutilisateur');
        const deleteId = deleteIdRef.current.value;
        //
        await Axios.post("https://micarionsapi.carions.net/delete/account", {
            deleteId:deleteId,
        }).then(() => {
            usernamedetailDiv.innerText = '';
            codeutilisateurDiv.innerText = '';
            navigateBackDeleteAccount('/');
        });
    }

    const goBackDeleteAccountHandler = async (e) => {
        e.preventDefault();

        navigateBackDeleteAccount('/cruduseraccount');
    }

    return (
        <DivContainerSlide id='deleteaccount'>
            <br/><br/>
            <div style={{marginTop:'-4rem'}}>
                <SpanGlobalSpan style={{textAlign:'center'}}>
                    <GlobalStrongTitle>{t("crudusersupprimer")}</GlobalStrongTitle>
                </SpanGlobalSpan>
            </div>
            <FormScrollerWidgetSm>
                <div className="connectaccountstyle">
                    <br/>
                    {valueDeleteAccount.map((valdelete, indexdelete) => (
                        <div key={indexdelete}>
                            <div style={{display:'none',textAlign:'center'}}>
                                <GlobalLargeInput type='text' defaultValue={valdelete.id_users} ref={deleteIdRef}  placeholder={t("placenom")}/>
                            </div>
                            <div className='sharecontent'>
                                <p className="pshareservicestyles">{t("delete_titre")}</p>
                                <p className="pshareservicestyles">{t("delete_texte")}</p>
                                <p className="pshareservicestyles">{t("delete_conclu_1")}</p>
                                <p className="pshareservicestyles">{t("delete_conclu_2")}</p>
                            </div>
                        </div>
                    ))}
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <ButtonStart onClick={submitDeleteAccountHandler}>
                                    <MdOutlineDelete style={{fontSize:'30px'}} />
                                    {t("delete")}
                                </ButtonStart>
                            </div>
                            <div>
                                <BackStart onClick={goBackDeleteAccountHandler}>
                                    <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                    {t("retour")}
                                </BackStart>
                            </div>
                        </HandlerContainerDiv>
                        <br/><br/>
                    </div>
                    <br/><br/>
                </div>
            </FormScrollerWidgetSm>
        </DivContainerSlide>
    )
}

export default DeleteAccount