import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../../components.css';
//
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';

import { AlertModal, getCurrentDate, addCurrentDate } from "../../../helperfunctions/Helper";

import { 
    DivContainerSlide, 
    GlobalStrongTitle,
    SpanGlobalSpan,
    FormScrollerWidgetSm,
    TextLogin, 
    GlobalLargeInput,
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
} from "../../../global/Styles";

import { MdOutlineCreate, MdSettingsBackupRestore,} from 'react-icons/md';

import { useRef, } from "react";
import Axios from 'axios';

function CreateAccount() {
    const {t} = useTranslation();

    const navigateBackCreateAccount = useNavigate();

    //alert
    const elementOutId = "modaloutalertinscriptionuser";
    const elementDropId = "backdropoutalertinscriptionuser";
    const buttonHandler = "ouiHandlerAlertInscriptionUserBtn";

    const createNameRef = useRef(null);
    const createLastNameRef = useRef(null);
    const createPrefixeRef = useRef(null);
    const createPhoneRef = useRef(null);
    const createUsernameRef = useRef(null);
    const createPasswordRef = useRef(null);
    const createConfirmPasswordRef = useRef(null);
    //

    //http://localhost:5000
    //https://micarionsapi.carions.net

    const submitCreateAccountHandler = async (e) => {
        e.preventDefault();

        //show username and user code
        const usernamedetailDiv = document.getElementById('usernamedetail');
        const codeutilisateurDiv = document.getElementById('codeutilisateur');

        const createName = createNameRef.current.value;
        const createLastName = createLastNameRef.current.value;
        const createPrefixe = createPrefixeRef.current.value;
        const createPhone = createPhoneRef.current.value;
        const createUsername = createUsernameRef.current.value;
        const createPassword = createPasswordRef.current.value;
        const createConfirmPassword = createConfirmPasswordRef.current.value;
        //
        var createUserCode = "";
        var createUserRole = 'utilisateur';
        var userStatus = "inscrit";
        const createDateStart = getCurrentDate();
        var numberOfDays = 90;
        const createDateEnd = addCurrentDate(numberOfDays);
        const createDateInscription = getCurrentDate();
        const statusPayment = "";
        const createCodePayment = "";
        var resultat = '';
        //
        if(!createName){
            const pTexteSaisir = t("placenom");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createLastName){
            const pTexteSaisir = t("placeprenoms");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createPrefixe){
            const pTexteSaisir = t("placeprefixe");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createPhone){
            const pTexteSaisir = t("placemobile");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createUsername){
            const pTexteSaisir = t("placeusername");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createPassword){
            const pTexteSaisir = t("placepassword");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createConfirmPassword){
            const pTexteSaisir = t("placeconfirmpassword");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(createPassword !== createConfirmPassword){
            const pTexteSaisir = t("nomatchpassword");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else{
            //generate random code number
            var randomNumber = Math.floor((Math.random() * 100000) + 1);
            createUserCode = randomNumber;
            //check if this password already exist
            await Axios.post("https://micarionsapi.carions.net/user/check/password", {
                createPassword:createPassword,
            }).then((response1) => {
                resultat = response1.data.length;
                if(resultat === 0){
                    Axios.post("https://micarionsapi.carions.net/user/inscrit/create", {
                        createName:createName,
                        createLastName:createLastName,
                        createPrefixe:createPrefixe,
                        createPhone:createPhone,
                        createUsername:createUsername,
                        createPassword:createPassword,
                        createUserCode:createUserCode,
                        createUserRole:createUserRole,
                        userStatus:userStatus,
                        createDateStart:createDateStart,
                        createDateEnd:createDateEnd,
                        createDateInscription:createDateInscription,
                        statusPayment:statusPayment,
                        createCodePayment:createCodePayment,
                    }).then(() => {
                        //display username and user code
                        usernamedetailDiv.innerText = createUsername;
                        codeutilisateurDiv.innerText = createUserCode;
                        navigateBackCreateAccount('/');
                    });
                }else{
                    navigateBackCreateAccount('/');
                }
            });
        }
    }

    const goBackCreateAccountHandler = async (e) => {
        e.preventDefault();

        navigateBackCreateAccount('/connectaccount');
    }

    return ( 
        <DivContainerSlide id="createaccount">
            <br/>
            <div style={{marginTop:'-2rem'}}>
                <SpanGlobalSpan style={{textAlign:'center'}}>
                    <GlobalStrongTitle>{t("crudusercreer")}</GlobalStrongTitle>
                </SpanGlobalSpan>
            </div>
            <FormScrollerWidgetSm>
                <div className="connectaccountstyle">
                    <br/><br/>
                    <TextLogin>{t("nom")}<strong style={{color:'red'}}>*</strong></TextLogin>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput type='text' ref={createNameRef}  placeholder={t("placenom")}/>
                    </div>
                    <br/>
                    <TextLogin>{t("prenoms")}<strong style={{color:'red'}}>*</strong></TextLogin>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput type='text' ref={createLastNameRef}  placeholder={t("placeprenoms")}/>
                    </div>
                    <br/>
                    <TextLogin>{t("prefixe")}<strong style={{color:'red'}}>*</strong></TextLogin>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput type='number' ref={createPrefixeRef} placeholder={t("placeprefixe")}/>
                    </div>
                    <br/>
                    <TextLogin>{t("mobile")}<strong style={{color:'red'}}>*</strong></TextLogin>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput type='number' ref={createPhoneRef} placeholder={t("placemobile")}/>
                    </div>
                    <br/>
                    <TextLogin>{t("username")}<strong style={{color:'red'}}>*</strong></TextLogin>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput type='text' ref={createUsernameRef}  placeholder={t("placeusername")}/>
                    </div>
                    <br/>
                    <TextLogin>{t("password")}<strong style={{color:'red'}}>*</strong></TextLogin>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput type='text' ref={createPasswordRef} placeholder={t("placepassword")}/>
                    </div>
                    <br/>
                    <TextLogin>{t("confirmpassword")}<strong style={{color:'red'}}>*</strong></TextLogin>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput type='text' ref={createConfirmPasswordRef} placeholder={t("placeconfirmpassword")}/>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <ButtonStart onClick={submitCreateAccountHandler}>
                                    <MdOutlineCreate style={{fontSize:'30px'}} />
                                    {t("creer")}
                                </ButtonStart>
                            </div>
                            <div>
                                <BackStart onClick={goBackCreateAccountHandler}>
                                    <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                    {t("retour")}
                                </BackStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                    <div id="modaloutalertinscriptionuser"></div>
                    <div id="backdropoutalertinscriptionuser"></div>
                </div>
                <br/><br/>
            </FormScrollerWidgetSm>
        </DivContainerSlide>
    );
}

export default CreateAccount;