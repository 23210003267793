import React from "react-dom";
import { useNavigate } from "react-router-dom";
//build tabs tools
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
//
import { AlertModal } from '../../../helperfunctions/Helper';
//
import '../services.css';
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import { HiOutlineClock } from 'react-icons/hi';
import { TbRefresh } from 'react-icons/tb';
//
import {
    SpanGlobalSpan,
    GlobalStrongTitle,
    TabsForm, 
    DivTabRowAround,
    DivTabRowBetween,
    PTab10,
    DivTabColumnAround,
    PTab10Bt0,
    BackStart,  
    HandlerContainerDiv,
} from '../../../global/Styles';

import { useState } from "react";
import Axios from 'axios';

function SoftMaintenanceServicesTab() {
    const {t} = useTranslation();

    const navigateSoftMaintTab = useNavigate();

    //alert
    const elementOutId = "modaloutalertsoftmaintenancetab";
    const elementDropId = "backdropoutalertsoftmaintenancetab";
    const buttonHandler = "ouiHandlerAlertSoftMaintenanceTabBtn";

    const [softMaintenanceTabBasic, getSoftMaintenanceTabBasic] = useState([0]);

    var resultatappbasic = "";

    //http://localhost:5000
    //https://micarionsapi.carions.net

    const softMaintenanceBasicHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'basic';
        //show the tab form paragraphs and panel
        const softmainparagraphDiv = document.getElementById('softmainparagraph');
        const softmainpanelDiv = document.getElementById('softmainpanel');
        //
        await Axios.post("https://micarionsapi.carions.net/seo/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getSoftMaintenanceTabBasic(responseappbasic.data);
                //
                softmainparagraphDiv.style.display = 'block';
                softmainpanelDiv.style.display = 'block';
            }
        });
    }

    const softMaintenanceStandardHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'standard';
        //show the tab form paragraphs and panel
        const softmainparagraphDiv = document.getElementById('softmainparagraph');
        const softmainpanelDiv = document.getElementById('softmainpanel');
        //
        await Axios.post("https://micarionsapi.carions.net/seo/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getSoftMaintenanceTabBasic(responseappbasic.data);
                //
                softmainparagraphDiv.style.display = 'block';
                softmainpanelDiv.style.display = 'block';
            }
        });
    }

    const softMaintenancePremiumHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'premium';
        //show the tab form paragraphs and panel
        const softmainparagraphDiv = document.getElementById('softmainparagraph');
        const softmainpanelDiv = document.getElementById('softmainpanel');
        //
        await Axios.post("https://micarionsapi.carions.net/seo/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getSoftMaintenanceTabBasic(responseappbasic.data);
                //
                softmainparagraphDiv.style.display = 'block';
                softmainpanelDiv.style.display = 'block';
            }
        }); 
    }

    //softmaintenanceservicestab proceed processes
    const maintbasicHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'basic';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateSoftMaintTab('/connectaccount');
            }else{
                navigateSoftMaintTab('/proceedmaint');
            }
        }
    }
    //
    const maintstandardHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'standard';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateSoftMaintTab('/connectaccount');
            }else{
                navigateSoftMaintTab('/proceedmaint');
            }
        }
    }
    //
    const maintpremiumHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'premium';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateSoftMaintTab('/connectaccount');
            }else{
                navigateSoftMaintTab('/proceedmaint');
            }
        }
    }

    //softmaintenanceservicestab contact
    const maintcontactHandler = async (e) => {
        e.preventDefault();

        navigateSoftMaintTab('/contactme');
    }

    const goBackSoftMaintenanceTabHandler = async (e) => {
        e.preventDefault();

        navigateSoftMaintTab('/mainsoftwareservices');
    }

    return ( 
        <div id="softmaintenanceservicestab">
            <br/>
            <div style={{marginTop:'-2rem'}}>
                <SpanGlobalSpan style={{textAlign:'center'}}>
                    <GlobalStrongTitle>{t("software_maintenance")}</GlobalStrongTitle>
                </SpanGlobalSpan>
            </div>
            <br/>
            <TabsForm>
                {softMaintenanceTabBasic.map((valmainbasic, indexmainbasic) => (
                    <div key={indexmainbasic} className="tabstyles">
                        <div id="softmainparagraph">
                            <PTab10>
                                {valmainbasic.services_paragraphone}<br/>
                                {valmainbasic.services_paragraphtwo}<br/>
                                {valmainbasic.services_paragraphthree}<br/>
                                {valmainbasic.services_paragraphfour}<br/>
                            </PTab10>
                        </div>
                        <Tabs defaultIndex={0}>
                            <TabList>
                                <Tab onClick={softMaintenanceBasicHandler}>{t("basic_plan")}</Tab>
                                <Tab onClick={softMaintenanceStandardHandler}>Standard</Tab>
                                <Tab onClick={softMaintenancePremiumHandler}>Premium</Tab>
                            </TabList>
                            <div id="softmainpanel">
                                <TabPanel >
                                    <DivTabRowBetween>
                                        <h3>{valmainbasic.services_titre}</h3><h3>{valmainbasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valmainbasic.services_description} 
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valmainbasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valmainbasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmainbasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmainbasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox"></input>{valmainbasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox"></input>{valmainbasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmainbasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={maintbasicHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={maintcontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                                <TabPanel>
                                    <DivTabRowBetween>
                                        <h3>{valmainbasic.services_titre}</h3><h3>{valmainbasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valmainbasic.services_description}
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valmainbasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valmainbasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmainbasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmainbasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmainbasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox"></input>{valmainbasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmainbasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={maintstandardHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={maintcontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                                <TabPanel>
                                    <DivTabRowBetween>
                                        <h3>{valmainbasic.services_titre}</h3><h3>{valmainbasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valmainbasic.services_description}
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valmainbasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valmainbasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmainbasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmainbasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmainbasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmainbasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmainbasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={maintpremiumHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={maintcontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                ))}
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackSoftMaintenanceTabHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertsoftmaintenancetab"></div>
                <div id="backdropoutalertsoftmaintenancetab"></div>
            </TabsForm>
        </div>
    );
}

export default SoftMaintenanceServicesTab;