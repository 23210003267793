import React from 'react';
import { useNavigate } from "react-router-dom";
import '../../../accueil/accueil.css';
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,
    BackStart,  
    HandlerContainerDiv, 
} from '../../../global/Styles';

function InfoAccount() {
    const {t} = useTranslation();

    const navigateInfoAccount = useNavigate();

    const goBackInfoAccountHandler = async (e) => {
        e.preventDefault();

        navigateInfoAccount('/cruduseraccount');
    }

    return (
        <DivContainerSlide id="infoaccount">
            <br/><br/>
            <SpanGlobalSpan style={{textAlign:'center', marginTop:'-3rem'}}>
                <GlobalStrongTitle>{t("crudusergerer")}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <div>
                <div className='sharecontent'>
                    <p className="pshareservicestyles">{t("info_titre")}</p>
                    <p className="pshareservicestyles">{t("info_dear")}</p>
                    <p className="pshareservicestyles">{t("info_intro")}</p>
                    <p className="pshareservicestyles">{t("info_create")}</p>
                    <p className="pshareservicestyles">{t("info_create_detail")}</p>
                    <p className="pshareservicestyles">{t("info_edit")}</p>
                    <p className="pshareservicestyles">{t("info_edit_detail")}</p>
                    <p className="pshareservicestyles">{t("info_delete")}</p>
                    <p className="pshareservicestyles">{t("info_delete_detail")}</p>
                    <p className="pshareservicestyles">{t("info_conclu_1")}</p>
                    <p className="pshareservicestyles">{t("info_conclu_2")}</p>
                    <p className="pshareservicestyles">{t("info_conclu_3")}</p>
                    <p className="pshareservicestyles">{t("info_conclu_4")}</p>
                    <p className="pshareservicestyles">{t("info_conclu_5")}</p>
                </div>
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackInfoAccountHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
            </div>
        </DivContainerSlide>
    )
}

export default InfoAccount