import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../components.css';
import imageaccount from '../../carions-icon-192.png';
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,
    ReducedFormScrollerWidgetSm, 
    BigButton,
    BackStart,  
} from '../../global/Styles';

function Account() {
    const {t} = useTranslation();

    const navigateBackAccount = useNavigate();
    const navigateCrudUserAccount = useNavigate();

    const compteUtilisateurHandler = async (e) => {
        e.preventDefault();

        navigateCrudUserAccount('/createaccount');
    }

    const goBackCompteHandler = async (e) => {
        e.preventDefault();

        navigateBackAccount(-1);
    }

    return ( 
        <DivContainerSlide id="account">
            <br/>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <img src={imageaccount} alt="" />
            </div>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t("accounttitre")}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <ReducedFormScrollerWidgetSm>
                <div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={compteUtilisateurHandler}>{t("utilisateur")}</BigButton>
                    </div>
                </div>
                <br/>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                    <div>
                        <BackStart onClick={goBackCompteHandler}>
                            <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                            {t("retour")}
                        </BackStart>
                    </div>
                    <br/><br/><br/>
                </div>
                <br/><br/>
            </ReducedFormScrollerWidgetSm>
        </DivContainerSlide>
    );
}

export default Account;