import React from "react-dom";
import { useNavigate } from "react-router-dom";
//build tabs tools
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
//
import { AlertModal } from '../../../helperfunctions/Helper';
//
import '../services.css';
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import { HiOutlineClock } from 'react-icons/hi';
import { TbRefresh } from 'react-icons/tb';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,
    TabsForm, 
    DivTabRowAround,
    DivTabRowBetween,
    PTab10,
    DivTabColumnAround,
    PTab10Bt0,
    BackStart,  
    HandlerContainerDiv,
} from '../../../global/Styles';

import { useState } from "react";
import Axios from 'axios';

function EcommerceTab() {
    const {t} = useTranslation();

    const navigateEcommerceTab = useNavigate();

    //alert
    const elementOutId = "modaloutalertappcontenu";
    const elementDropId = "backdropoutalertappcontenu";
    const buttonHandler = "ouiHandlerAlertAppContenuBtn";

    const [ecomTabBasic, getEcomTabBasic] = useState([0]);

    var resultatecombasic = "";

    //http://localhost:5000
    //https://micarionsapi.carions.net

    const ecomBasicHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'basic';
        //show the tab form paragraphs and panel
        const webecomparagraphDiv = document.getElementById('webecomparagraph');
        const webecompanelDiv = document.getElementById('webecompanel');
        //
        await Axios.post("https://micarionsapi.carions.net/website/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseecombasic) => {
            if(resultatecombasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getEcomTabBasic(responseecombasic.data);
                //
                webecomparagraphDiv.style.display = 'block';
                webecompanelDiv.style.display = 'block';
            }
        });  
    }

    const ecomStandardHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'standard';
        //
        await Axios.post("https://micarionsapi.carions.net/website/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseecombasic) => {
            if(resultatecombasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getEcomTabBasic(responseecombasic.data);
                //
            }
        });  
    }

    const ecomPremiumHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'premium';
        //
        await Axios.post("https://micarionsapi.carions.net/website/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseecombasic) => {
            if(resultatecombasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getEcomTabBasic(responseecombasic.data);
                //
            }
        });
    }

    //ecommerce proceed processes
    const ecommercebasicHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'basic';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateEcommerceTab('/connectaccount');
            }else{
                navigateEcommerceTab('/proceedecom');
            }
        }
    }
    //
    const ecommercestandardHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'standard';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateEcommerceTab('/connectaccount');
            }else{
                navigateEcommerceTab('/proceedecom');
            }
        }
    }
    const ecommercepremiumHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'premium';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateEcommerceTab('/connectaccount');
            }else{
                navigateEcommerceTab('/proceedecom');
            }
        }
    }

    //webapp contact
    const ecommercecontactHandler = async (e) => {
        e.preventDefault();

        navigateEcommerceTab('/contactme');
    }

    const goBackEcomSiteTabHandler = async (e) => {
        e.preventDefault();

        navigateEcommerceTab('/mainwebservices');
    }

    return ( 
        <DivContainerSlide id="ecommercetab">
            <br/>
            <div style={{marginTop:'-2rem'}}>
                <SpanGlobalSpan style={{textAlign:'center'}}>
                    <GlobalStrongTitle>{t("ecomapptitre")}</GlobalStrongTitle>
                </SpanGlobalSpan>
            </div>
            <br/>
            <TabsForm>
                {ecomTabBasic.map((valecombasic, indexecombasic) => (
                    <div key={indexecombasic} className="tabstyles">
                        <div id="webecomparagraph">
                            <PTab10>
                                {valecombasic.services_paragraphone}<br/>
                                {valecombasic.services_paragraphtwo}<br/>
                                {valecombasic.services_paragraphthree}<br/>
                                {valecombasic.services_paragraphfour}<br/>
                            </PTab10>
                        </div>
                        <Tabs defaultIndex={0}>
                            <TabList>
                                <Tab onClick={ecomBasicHandler}>{t("basic_plan")}</Tab>
                                <Tab onClick={ecomStandardHandler}>Standard</Tab>
                                <Tab onClick={ecomPremiumHandler}>Premium</Tab>
                            </TabList>
                            <div id="webecompanel">
                                <TabPanel >
                                    <DivTabRowBetween>
                                        <h3>{valecombasic.services_titre}</h3><h3>{valecombasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valecombasic.services_description} 
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valecombasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valecombasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valecombasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valecombasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valecombasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valecombasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valecombasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={ecommercebasicHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={ecommercecontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                                <TabPanel>
                                    <DivTabRowBetween>
                                        <h3>{valecombasic.services_titre}</h3><h3>{valecombasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valecombasic.services_description}
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valecombasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valecombasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valecombasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valecombasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valecombasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valecombasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valecombasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={ecommercestandardHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={ecommercecontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                                <TabPanel>
                                    <DivTabRowBetween>
                                        <h3>{valecombasic.services_titre}</h3><h3>{valecombasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valecombasic.services_description}
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valecombasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valecombasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valecombasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valecombasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valecombasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valecombasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valecombasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={ecommercepremiumHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={ecommercecontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                ))}
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackEcomSiteTabHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertecomcontenu"></div>
                <div id="backdropoutalertecomcontenu"></div>
            </TabsForm>
        </DivContainerSlide>
    );
}

export default EcommerceTab;