import React from "react-dom";
import { useNavigate } from "react-router-dom";
//build tabs tools
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
//
import { AlertModal } from '../../../helperfunctions/Helper';
//
import '../services.css';
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import { HiOutlineClock } from 'react-icons/hi';
//
import {
    SpanGlobalSpan,
    GlobalStrongTitle,
    TabsForm, 
    DivTabRowAround,
    DivTabRowBetween,
    PTab10,
    DivTabColumnAround,
    PTab10Bt0,
    BackStart,  
    HandlerContainerDiv,
} from '../../../global/Styles';

import { useState } from "react";
import Axios from 'axios';

function MonthlyTab() {
    const {t} = useTranslation();

    const navigateMonthlyTab = useNavigate();

    //alert
    const elementOutId = "modaloutalertmonthlytab";
    const elementDropId = "backdropoutalertmonthlytab";
    const buttonHandler = "ouiHandlerAlertMonthlyTabBtn";

    const [monthlyTabBasic, getMonthlyTabBasic] = useState([0]);

    var resultatappbasic = "";

    //http://localhost:5000
    //https://micarionsapi.carions.net

    const monthlyBasicHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'basic';
        //show the tab form paragraphs and panel
        const monthlyparagraphDiv = document.getElementById('monthlyparagraph');
        const monthlypanelDiv = document.getElementById('monthlypanel');
        //
        await Axios.post("https://micarionsapi.carions.net/seo/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getMonthlyTabBasic(responseappbasic.data);
                //
                monthlyparagraphDiv.style.display = 'block';
                monthlypanelDiv.style.display = 'block';
            }
        }); 
    }

    const monthlyStandardHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'standard';
        //show the tab form paragraphs and panel
        const monthlyparagraphDiv = document.getElementById('monthlyparagraph');
        const monthlypanelDiv = document.getElementById('monthlypanel');
        //
        await Axios.post("https://micarionsapi.carions.net/seo/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getMonthlyTabBasic(responseappbasic.data);
                //
                monthlyparagraphDiv.style.display = 'block';
                monthlypanelDiv.style.display = 'block';
            }
        });
    }

    const monthlyPremiumHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'premium';
        //show the tab form paragraphs and panel
        const monthlyparagraphDiv = document.getElementById('monthlyparagraph');
        const monthlypanelDiv = document.getElementById('monthlypanel');
        //
        await Axios.post("https://micarionsapi.carions.net/seo/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getMonthlyTabBasic(responseappbasic.data);
                //
                monthlyparagraphDiv.style.display = 'block';
                monthlypanelDiv.style.display = 'block';
            }
        });
    }

    //monthly proceed processes
    const monthbasicHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'basic';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateMonthlyTab('/connectaccount');
            }else{
                navigateMonthlyTab('/proceedmonthly');
            }
        }
    }
    //
    const monthstandardHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'standard';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateMonthlyTab('/connectaccount');
            }else{
                navigateMonthlyTab('/proceedmonthly');
            }
        }
    }
    //
    const monthpremiumHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'premium';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateMonthlyTab('/connectaccount');
            }else{
                navigateMonthlyTab('/proceedmonthly');
            }
        }
    }

    //technical contact
    const monthcontactHandler = async (e) => {
        e.preventDefault();

        navigateMonthlyTab('/contactme');
    }

    const goBackMonthlyTabHandler = async (e) => {
        e.preventDefault();

        navigateMonthlyTab('/mainseoservices');
    }

    return ( 
        <div id="monthlytab">
            <br/>
            <div style={{marginTop:'-2rem'}}>
                <SpanGlobalSpan style={{textAlign:'center'}}>
                    <GlobalStrongTitle>{t("monthly_report")}</GlobalStrongTitle>
                </SpanGlobalSpan>
            </div>
            <br/>
            <TabsForm>
                {monthlyTabBasic.map((valmonthlybasic, indexmonthlybasic) => (
                    <div key={indexmonthlybasic} className="tabstyles">
                        <div id="monthlyparagraph">
                            <PTab10>
                                {valmonthlybasic.services_paragraphone}<br/>
                                {valmonthlybasic.services_paragraphtwo}<br/>
                                {valmonthlybasic.services_paragraphthree}<br/>
                                {valmonthlybasic.services_paragraphfour}<br/>
                            </PTab10>
                        </div>
                        <Tabs defaultIndex={0}>
                            <TabList>
                                <Tab onClick={monthlyBasicHandler}>{t("basic_plan")}</Tab>
                                <Tab onClick={monthlyStandardHandler}>Standard</Tab>
                                <Tab onClick={monthlyPremiumHandler}>Premium</Tab>
                            </TabList>
                            <div id="monthlypanel">
                                <TabPanel >
                                    <DivTabRowBetween>
                                        <h3>{valmonthlybasic.services_titre}</h3><h3>{valmonthlybasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valmonthlybasic.services_description} 
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valmonthlybasic.services_delivery}</PTab10>
                                        <p><input type="checkbox" defaultChecked="checked"></input>{valmonthlybasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox"></input>{valmonthlybasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmonthlybasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox"></input>{valmonthlybasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmonthlybasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmonthlybasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={monthbasicHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={monthcontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                                <TabPanel>
                                    <DivTabRowBetween>
                                        <h3>{valmonthlybasic.services_titre}</h3><h3>{valmonthlybasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valmonthlybasic.services_description}
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valmonthlybasic.services_delivery}</PTab10>
                                        <p><input type="checkbox" defaultChecked="checked"></input>{valmonthlybasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmonthlybasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmonthlybasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmonthlybasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmonthlybasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmonthlybasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={monthstandardHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={monthcontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                                <TabPanel>
                                    <DivTabRowBetween>
                                        <h3>{valmonthlybasic.services_titre}</h3><h3>{valmonthlybasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valmonthlybasic.services_description}
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valmonthlybasic.services_delivery}</PTab10>
                                        <p><input type="checkbox" defaultChecked="checked"></input>{valmonthlybasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmonthlybasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmonthlybasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmonthlybasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmonthlybasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmonthlybasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={monthpremiumHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={monthcontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                ))}
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackMonthlyTabHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertmonthlytab"></div>
                <div id="backdropoutalertmonthlytab"></div>
            </TabsForm>
        </div>
    );
}

export default MonthlyTab;