export const TRANSLATIONS_EN = {
    landing: "Launch",
    accueil: "Home",
    apropos: "About",
    portfolio: "Portfolio",
    services: "Services",
    blogs: "Blogs",
    apprentissage: "Learning",
    tutoriels: "Tutorials",
    apps: "Apps",
    contact: "Contact",

    francais:"French",
    anglais:"English",
    portugais:"Portuguese",
    italien:"Italian",

    //global translation
    status_contenu: "Status Type",
    select_status: "Select Status Type",
    retour: "Return",
    nom: "Name",
    placenom: "Enter Name",
    prenoms: "Lastname",
    placeprenoms: "Enter Lastname",
    prefixe: "Inter Prefix",
    placeprefixe: "example:00225",
    mobile: "Mobile Contact",
    placemobile: "Enter Mobile Contact",
    username: "Username",
    placeusername: "Enter Username",
    password: "Password",
    placepassword: "Enter Password",
    confirmpassword: "Confirm Password",
    placeconfirmpassword: "Confirm Password",
    nomatchpassword: "The 2 passwords do not match",
    changepassword: "Change your password if you want to create another account under the same username",
    creer: "Create",
    modifier: "Edit",
    confirmer: "Confirm",
    identifier: "Login",
    newaccount: "No User Account?",
    editaccount: "Manage Your Account",
    acceder: "Sign In",
    erreurdetail: "Incorrect user name or password",
    soucritabonnement: "Trial Period expired. Sign up for a Membership",
    crudcreercontenu: "Create Content",
    crudcontenumodifier: "Edit Content",
    crudaccueilsupprimer: "Delete Content",
    crudaccueilvisualiser: "View Content",

    //slogan section
    slogan: "The Digital Tradition",
    sous_slogan:"Web Development",

    //Account section
    accounttitre: "Select Account Type",
    utilisateur: "User Account",
    abonnement: "Membership account",

    //crud user account
    crudusertitre: "Account Management",
    crudusergerer: "How to manage your Account?",
    crudusercreer: "Create Free Account",
    crudusermodifier: "Edit Your Account",
    crudusersupprimer: "Delete account",

    //crud accueil
    crudaccueiltitre: "Home Page Management",

    //my editor react-quill
    editeur: "Editor",
    apercu: "Preview",
    typecontenu: "Content type",
    titrecontenu: "Content Title",
    placecontenu: "Enter Content Title",
    textcontenu: "Enter Text Content",
    originaltexte: "Previous Text",
    nouveautexte: "Edit Text Here",

    servicetitre: "Carion's Services",
    webdevelopment: "Web Development",
    seoconsulting: "SEO Consulting",
    softdevservice: "Software Dev Services",
    apprentpurchase: "App Rental/Purchase",
    training: "App Dev Training",
    hosting: "Hosting Servcies",
    //
    website: "Websites",
    webapp: "Web Apps",
    ecommerce: "E-Commerce Sites",
    webdescription: "Custom websites tailored to your business goals and brand, using the latest web development technologies, User-friendly, responsive website, and easy to navigate. Secure, scalable, and conversion-optimized e-commerce website to sell your products or services online.  Solution tailored to your business needs, whether you need a customer management system, a project management tool, or any other type of web application.",
    //
    accueil_titre: "Let Build your project. Unlock Your Web Solutions",
    accueil_content: "Whoever wants to travel far takes care of his mount, not only because the mount is exhausted, but also because there will be nowhere else to travel. But be careful not to commit a misinterpretation leading to a dead end !!!",
    accueil_project: "Projects",
    accueil_skills: "Solutions",
    //
    connect_titre: "Create a free Account. Being Connected.",
    connect_content: "Enter Your Username and Password to access newsletters and more or create a new Account for free if not done yet.",
    //
    //
    crudcontenusupprimer: "Delete Content",
    //
    crudcontenuVisualiser: "View Content",

    //notification
    noteindisponible: "No notification list available",
    //gestion tabs services
    crudservicestitre: "Services Management",
    //webservices
    webdescription_1: "Whether you need a mobile app, a web app, or a backend server,  I have the expertise to handle them all. With my skills in React Native, Nodejs, and MySQL, I can build a comprehensive mobile app that meets your needs. If you prefer a Progressive Web App, I can also develop one using React js and either MySQL or CouchDB.",
    webdescription_2: "If you're in need of a simple, yet powerful single page web or mobile app, I can help! Using React js or React Native, I can build an app that is both user-friendly and functional. Whether you're looking to create a customer portal, a landing page, or an e-commerce site, I have the skills and experience to deliver a product that meets your needs.",
    webdescription_3: "My development process begins with a deep understanding of your requirements, goals, and target audience. I will work with you to define the scope of the project, create a development plan, and establish clear milestones and deadlines.",
    webdescription_4: "I'm passionate about my work, and I'm confident that you'll be delighted with the results. However, to ensure that we're on the same page, I highly recommend discussing your requirements with me before we begin the development process. This will help me better understand your vision and deliver a final product that exceeds your expectations.",
    //
    webIntroductory: "Introductory",
    webbasicprice: "£87.22",
    webbasicdescription: "When it comes to building web or mobile apps, I have extensive experience in a variety of technologies and frameworks. Depending on your needs, I can create a simple single page app using React js or React Native.",
    webbasicdelivery: "7 Days Delivery",
    webbasicrevision: "4 Revisions",
    webbasicpage: "1 page",
    webbasicdesign: "Design customization",
    webbasiccontent: "Content upload",
    webbasicresponsive: "Responsive design",
    webbasicsource: "Source code on request",
    //
    proceedbtn: "Proceed",
    contactme: "Contact Me",
    //
    websitetitre: "Web Development Service",
    webapptitre: "Web App Development Service",
    ecomapptitre: "E-commerce Development Service",
    //global
    type_plan: "Plan type",
    select_plan: "Select type",
    basic_plan: "Basic",
    standard_plan: "Standard",
    premium_plan: "Premium",
    paragraphe_1: "Paragaph 1",
    paragraphe_2: "Paragaph 2",
    paragraphe_3: "Paragaph 3",
    paragraphe_4: "Paragaph 4",
    service_title: "Service Title",
    service_price: "Service Price",
    plan_description: "Plan Description",
    days_delivery: "Days Delivery",
    revision_number: "Revision Number",
    page_number: "Page Number",
    design_customization: "Design Customization",
    content_upload: "Content Upload",
    responsive_design: "Responsive Design",
    source_code: "Source Code",
    titre_service: "Introductory/Intermediate/Advanced/",
    delivery: ".. Days Delivery",
    price: "£...",
    revision: ".. Revisions",
    page: ".. Pages",
    customization: "Design customization",
    content: "Content upload",
    responsive: "Responsive design",
    source: "Source code on request",
    //
    type_contenu: "Select a Content Type",
    titre_plan: "Select a type of plan",
    type_paragraphe: "Write at least the first paragraph",
    titre_contenu: "Enter only one service title",
    titre_price: "Enter the price of the service",
    titre_description: "Enter the service description",
    titre_delivery: "Enter the number of days required before delivery",
    titre_revision: "Enter the number of revisions needed",
    titre_page: "Enter the possible number of pages",
    titre_design: "Grab the Customization design",
    titre_upload: "Enter Content upload",
    titre_responsive: "Type Responsive design",
    titre_source: "Enter Source code on demand",
    //
    tab_success: "Registered tab service. Next",
    //seo services
    keyword_research: "Keyword Research",
    on_page: "On-page Optimization",
    technical_audit: "Technical SEO Audit",
    monthly_report: "Monthly Reporting",
    //software development services
    softservices_description: "I provide React website, web app, and mobile app development services to help businesses and individuals build customized software solutions that meet their unique needs. As an experienced React developer, I specialize in implementing custom components, functions, procedures, and algorithms based on customer requests. My React development services include:",
    softwareservices: "Individual Development Services",
    software_coding: "Programming & Coding",
    software_design: "Web and App Design",
    software_development: "Web and App Development",
    software_maintenance: "Web and App Maintenance",
    software_management: "Consulting Project Management",
    software_miscellanious: "Miscellanious services",
    //contact me
    contact_me:"Contact me",
    //no page found
    nofound:"Page not found",
    //select language
    selectlanguage: "To change language, click on home and select your preferred language.",
    //contact me
    contactintro: "Do you have any questions, comments, or suggestions? I'd love to hear from you! Please feel free to reach out to me using any of the following methods:",
    contact_1: "Direct Call: You can give me a call directly on my phone number.",
    contact_2: "Send Email: If you prefer to communicate via email, please send me a message.",
    contact_3: "WhatsApp Call: If you use WhatsApp, you can also give me a call on my WhatsApp number.",
    contactconclued_1: "I'm always happy to hear from new people and I'll do my best to get back to you as soon as possible.",
    contactconclued_2: "Looking forward to hearing from you soon!",
    //proceed website
    proceedwebtitle: "Website development",
    proceedwebdescription: "Here are some essential questions that I may ask you before starting your web project. Please fill free to let me get a better understanding of your needs, preferences, and limitations to tailor the design if needed to meet those requirements.",
    webquestion_1: "1. Describe your project in a few sentences",
    webquestion_2: "2. Do you currently have a website? If so, what is/isn't working for you?",
    webquestion_3: "3. What are your goals for this project?",
    webquestion_4: "4. Who is your target audience?",
    webquestion_5: "5. What specific features do you want on the site?",
    webquestion_6: "6. How can we avoid failure?",
    webquestion_7: "7. Who are your main competitors?",
    webquestion_8: "8. What makes you different from your competitors?",
    webquestion_9: "9. What's the scope of the project?",
    webquestion_10: "10. Who will be responsible for maintaining the contents?",
    webquestion_11: "11. What are the milestones and deadlines?",
    webquestion_12: "12. What is the process for updates and changes to the website after launch?",
    webquestion_13: "I Would Iike to be contacted by:",
    //suivant & precedent
    suivant: "Next",
    precedent: "Before",
    //hint webquestion
    hintwebquestion_1: "In some cases, your business may appear blindingly obvious, but you should never assume anything. Sum up your business in a few short sentences; this is the essence that needs to be immediately obvious when visitors land on your new website or app. Dig deeper, too. Try to find out what your key values are, your level of experience in the industry, and your vision of the future, as well as your size, location, and background.",
    hintwebquestion_2: "Unless you're starting from scratch, your current website is a mine of information. What Content Management System you want to use, how long you would use it, and how easy your prefer to update it. What do you like and what can’t you wait to see the back of? By spending some time reviewing some current websites, you can learn more about their client’s likes and dislikes, as well as learn from any mistakes they made last time around.",
    hintwebquestion_3: "What are your definition of success. Do you want to increase the amount of visitors, up the average order size, or boost the users on their web forum? Perhaps you want to encourage greater engagement via your blog, increase your brand visibility, or encourage people to sign up for your email newsletter/free trial/white paper, etc. By establishing your pain points and determining the issues you’re trying to solve, you'll stand a better chance of producing an efficient solution.",
    hintwebquestion_4: "Try to resonate strongly with your users and target audience, otherwise, they simply won’t use it – online audiences are incredibly fickle today, owing to the hugely competitive market. Delve into your user demographics and psychographics, such as their media consumption habits, slang words, and lifestyle choices. What are their purchasing habits? Do you need to bridge a gap between the current audience and target market? The more you know about the end users, the better you can determine the appearance, layout, and navigation of your website.",
    hintwebquestion_5: "Establish at the outset whether you have any particular functionalities you want included on your new website. Some things may be obvious. If you run a restaurant for example, you’ll need your opening hours and menu included, whereas if you run a hotel, you may want an online booking form. Other possibilities include a blog, website chat, social media integration, photo galleries, a separate mobile or responsive site, video integration, or a contact form. Always clarify at the outset to avoid late additions that could cost time and money to marry into the site. Write or provide any branding materials or style guides that need to be incorporated into the site’s design.",
    hintwebquestion_6: "Knowing your dislikes is equally important. Removing something integrated in a website once you’re halfway through a project is just as annoying, expensive and time-consuming as adding something in. Show your least favorite websites and point out the design elements, features, and styles that make them shudder if needed. This way, can strike out potential failures before getting into the nitty-gritty of the project.",
    hintwebquestion_7: "Discovering your competitors provides you with another useful source of information. Once you’ve indicated your main rivals, you can gather information that will help guide your web design. Look at the elements that work well on their sites, and see if there are things that fall flat. Your intention here isn’t to copy what’s been done before, but to learn from their successes and failures. Point out the things they do and don't like on your competitors' websites is another useful indicator of you like and dislike.",
    hintwebquestion_8: "Knowing your unique selling point will help designing a site that stands apart from your competitors’. In the busy, noisy, competitive online world, businesses need to be truly remarkable to be successful. So how can you grab people’s attention when they land on your site? How can you make that tricky first impression count?This doesn’t have to be anything major; it can be as simple as a free consultation or awesome customer service. Other things that make businesses stand out include speedy or free delivery on products, stocking the cheapest or best quality products in your area, having an amazing guarantee or returns procedure, or offering unique packages. By learning how you differ from your competitors can be delivered a site that does the same.",
    hintwebquestion_9: "Finally, clarify the scope of the project. You need a clear understanding of your final deadline as well as any key milestones you want to meet along the way – this is crucial to keeping your project on track and ensuring you live up to expectations. You also need to know the budget and, more importantly, establish whether there’s any flexibility in the timeline and budget.",
    hintwebquestion_10: "If you are responsible for maintaining the web contents, it's important to ensure that you have the necessary skills and knowledge to make updates and changes to the website. Else, it's important to clarify the scope of these activities and ensure that you understands what is included in the maintenance agreement. This may include activities such as updating content, fixing bugs, and performing regular backups.",
    hintwebquestion_11: "By establishing your milestones and deadlines, you can ensure that the project stays on track and that the expectations are met. It's important to communicate regularly with me throughout the project to ensure that any issues or delays are addressed promptly. That include: Discovery and Planning,  Design Approval, Content Delivery, Development Approval, Testing and Quality Assurance Approval, Launch. Select which you prefer or tell me that you prefer all of them. ",
    hintwebquestion_12: "The process for updates and changes to the website after launch may vary depending on the specific website and the agreement between us. However, here are some general steps that may be involved in the process: Request, Evaluation, Approval, Development, Testing, Deployment, Launch. It's important to have a clear process in place for updates and changes to the website after launch to ensure that they are implemented efficiently and effectively. Please give a clear answer if you agree and ready to follow the whole process.",
    //
    placemail: "Email Address",
    placewhatsapp: "Whatsapp Contact",
    emailphone: "Enter at least your Email or whatsapp or both.",
    responsequestion_1: "Thank you for submitting your questionnaire. We appreciate your interest in working with us. Our team will review your responses and contact you shortly to schedule a live discussion to further understand your project needs and requirements. During this discussion, we will work with you to update your questionnaire as needed to ensure that we fully understand your goals and objectives.",
    responsequestion_2: "Thank you again for your attention, and we look forward to discussing your project further.",
    //proceed website
    proceedwebapptitle: "Web App Development",
    invalidemail: "Enter your valid email address.",
    enteremail: "Enter your valid email address.",
    enterphone: "Enter your valid international prefix followed by Whatsapp number.",
    //proceed ecom
    proceedecomtitle: "E-commerce Development",
    //Seo processing
    proceedseosearchtitle: "Keyword Research",
    proceedseooptimtitle: "On-page Optimization",
    proceedseodescription: "Here are some essential questions that I may ask you before starting your web project. Please fill free to let me get a better understanding of your needs, preferences, and limitations to tailor the design if needed to meet those requirements.",
    seoquestion_1: "1. What are your business goals and objectives?",
    seoquestion_2: "2. Who is your target audience?",
    seoquestion_3: "3. What are your unique selling points (USPs)?",
    seoquestion_4: "4. Who are your main competitors?",
    seoquestion_5: "5. What are your current SEO efforts and results?",
    seoquestion_6: "6. What is your current website traffic and performance?",
    seoquestion_7: "7. What are the main keywords and phrases that you want to rank for?",
    seoquestion_8: "8. What is your content marketing strategy?",
    seoquestion_9: "9. What is your social media strategy?",
    seoquestion_10: "10. What is your budget for SEO?",
    seoquestion_11: "11. What is your timeline for achieving the SEO goals?",
    seoquestion_12: "12. How do you measure success?",
    seoquestion_13: "I Would Iike to be contacted by:",
    //hint seoquestion
    hintseoquestion_1: "1. Understanding your overall business goals and objectives will help to align the SEO strategy with your business needs and ensure that the efforts are focused on achieving the desired results.",
    hintseoquestion_2: "2. Knowing the target audience is crucial in determining the most relevant keywords and optimizing the website content to appeal to the target audience.",
    hintseoquestion_3: "3. Identifying your unique selling points will help to highlight those aspects of your business in the SEO strategy and differentiate them from your competitors.",
    hintseoquestion_4: "4. Understanding your main competitors and online presence will help to develop a more effective SEO strategy that can help to outrank your competitors.",
    hintseoquestion_5: "5. Knowing your current SEO efforts and results will help to identify the areas that need improvement and build on the existing efforts.",
    hintseoquestion_6: "6. Understanding your current website traffic and performance will help to set realistic goals and measure the success of the SEO strategy.",
    hintseoquestion_7: "7. Identifying your target keywords and phrases will help to optimize the website content and improve the rankings for those keywords.",
    hintseoquestion_8: "8. Knowing your content marketing strategy will help to align the SEO efforts with the content strategy and identify opportunities to optimize the content for SEO.",
    hintseoquestion_9: "9. Understanding your social media strategy will help to align the SEO efforts with the social media strategy and identify opportunities for social media optimization.",
    hintseoquestion_10: "10. Knowing your budget for SEO will help to develop a more effective strategy that can deliver the desired results within the available budget.",
    hintseoquestion_11: "11. Understanding your timeline for achieving the SEO goals will help to set realistic expectations and develop a strategy that can deliver the desired results within the specified timeframe.",
    hintseoquestion_12: "12. Knowing your criteria for measuring success will help to set realistic goals and develop a strategy that can deliver the desired results based on your definition of success.",
    //individual development service
    proceedcodingdescription: "Here are some essential questions that I could ask you before starting the service requested. Please fill in for free to allow me to better understand your needs, preferences and limitations in order to adapt the design if necessary to meet these requirements.",
    proceedcodingtitle: "Programming & Coding",
    codingquestion_1: "1. What is the specific coding or functionality that you require for your software development project?",
    codingquestion_2: "2. What programming languages are you currently using in your project?",
    codingquestion_3: "3. Have you tried to solve the coding issue on your own? If so, what have you tried so far?",
    codingquestion_4: "4. What is the timeline for completing this project?",
    codingquestion_5: "5. What is your budget for this project?",
    codingquestion_6: "6. Who are the end-users of your development project?",
    codingquestion_7: "7. What features and functionalities are most important to your end-users?",
    codingquestion_8: "8. How will the development project integrate with your existing technology systems?",
    codingquestion_9: "9. What are the potential risks and challenges that you foresee with this project?",
    codingquestion_10: "10. How will you measure the success of this development project?",
    codingquestion_11: "11. How frequently do you require updates or progress reports on the coding work?",
    codingquestion_12: "12. What level of technical support do you require for this development project?",
    codingquestion_13: "I Would Iike to be contacted by:",
    //hint seoquestion
    hintcodingquestion_1: "1. Provide specific details on the coding or functionality required, including any specific features or functions you want to add or modify.",
    hintcodingquestion_2: "2. Provide information on the programming languages you are currently using in your project. This will help understand the scope of the project and determine if you have the necessary expertise to complete it.",
    hintcodingquestion_3: "3. Have you tried to solve the coding issue on your own. If you have, please provide details on what you have tried so far. This will help understand your level of technical expertise and avoid duplicating any work that has already been done.",
    hintcodingquestion_4: "4. Your specific timeline for completing the project. This will help plan the work schedule and determine the availability to complete the project within the required timeframe.",
    hintcodingquestion_5: "5. Your budget for the project. This will help us understand your financial constraints and determine if services are affordable for you.",
    hintcodingquestion_6: "6. Provide information on the end-users of your software or app development project. This will help understand the target audience and ensure that the coding work meets your needs.",
    hintcodingquestion_7: "7. Provide details on the most important features and functionalities for your end-users. This will help us prioritize your coding work and ensure that the most important functions are completed first.",
    hintcodingquestion_8: "8. Provide information on how the software or app development project will integrate with your existing technology systems. This will help us understand the technical requirements of the project and ensure that the coding work is compatible with your existing technology systems.",
    hintcodingquestion_9: "9. Identify any potential risks or challenges you foresee with the project. This will help us identify potential roadblocks and plan accordingly to mitigate any risks or challenges.",
    hintcodingquestion_10: "9. Identify any potential risks or challenges you foresee with the project. This will help us identify potential roadblocks and plan accordingly to mitigate any risks or challenges.",
    hintcodingquestion_11: "11. How frequently you will require updates or progress reports on the coding work. This will help us plan your work schedule and ensure that your are kept informed throughout the project.",
    hintcodingquestion_12: "12. What level of technical support you will require for the software or app development project. This will help us determine the scope of technical support required and plan your work schedule accordingly.",

    //individual development service
    proceeddesigndescription: "Here are some essential questions that I may ask you before starting your web project. Please fill free to let me get a better understanding of your needs, preferences, and limitations to tailor the design if needed to meet those requirements.",
    proceeddesigntitle: "Web and App Design",
    designquestion_1: "1. What is the purpose of your website or app?",
    designquestion_2: "2. Who is your target audience?",
    designquestion_3: "3. What features and functionalities do you want to include?",
    designquestion_4: "4. What is your budget for the project?",
    designquestion_5: "5. Do you have any existing branding or design elements?",
    designquestion_6: "6. What is your timeline for the project?",
    designquestion_7: "7. What is the main call to action on your website or app?",
    designquestion_8: "8. Do you have any specific design preferences?",
    designquestion_9: "9. What is your content strategy?",
    designquestion_10: "10. What is your SEO strategy?",
    designquestion_11: "11. What is your hosting and maintenance plan?",
    designquestion_12: "12. What is the expected timeline for the completion of the project, and are there any specific deadlines that need to be met?",
    designquestion_13: "I Would Iike to be contacted by:",
    //hint seoquestion
    hintdesignquestion_1: "1. Explain what you want to accomplish with your website or app, such as selling products, providing information, or offering a service. This question will help us understand the main goal of the website or app, and what you want to achieve with it.",
    hintdesignquestion_2: "2. Provide information about your target audience, such as your age range, interests, and demographics. Understanding the target audience will help us create a design that appeals to you and meets your needs.",
    hintdesignquestion_3: "3. Provide a list of features and functionalities you want to include, such as a shopping cart, search bar, or contact form. This question will help us understand what you want the website or app to do, and what features it should have.",
    hintdesignquestion_4: "4. Provide a budget range or specific amount you are willing to spend on the project. Understanding the budget will help us determine what is feasible in terms of design and development.",
    hintdesignquestion_5: "5. Provide any existing branding or design elements, such as logos, color schemes, or fonts. If you have existing branding or design elements, we will incorporate them into the website or app and create a cohesive look and feel.",
    hintdesignquestion_6: "6. Provide a timeline for the project, such as a launch date or specific deadline. Understanding the timeline will help us determine if the project is feasible and plan accordingly.",
    hintdesignquestion_7: "7. Explain what action you want users to take, such as making a purchase, filling out a form, or signing up for a newsletter. Understanding the main call to action will help us design the website or app in a way that encourages users to take the desired action.",
    hintdesignquestion_8: "8. Provide any specific design preferences, such as a minimalist or bold design, or specific colors you want to incorporate. Understanding your design preferences will help us create a design that meets your aesthetic preferences and expectations.",
    hintdesignquestion_9: "9. Explain your content strategy, such as what type of content you want to create and how often you will update it. Understanding your content strategy will help us design the website or app in a way that supports your content goals.",
    hintdesignquestion_10: "10. Explain your SEO strategy, such as what keywords you want to target and how you plan to optimize your content. Understanding your SEO strategy will help us design the website or app in a way that supports your search engine optimization goals.",
    hintdesignquestion_11: "11. Explain your hosting and maintenance plan, such as what hosting provider you plan to use and how often. Understanding your hosting and maintenance plan will help us plan for ongoing support and maintenance of the website or app.",
    hintdesignquestion_12: "12. Answering this question will give us a better idea of your expectations regarding the timeline for the project. It is important to know if there are any specific deadlines that must be met so that we can plan your work accordingly. It will also help us to establish a realistic timeline for the completion of the project and to ensure that we have enough time to deliver a high-quality product.",

    //individual maintenance service
    proceedmaintdescription: "Here are some essential questions that I may ask you before starting your web project. Please fill free to let me get a better understanding of your needs, preferences, and limitations to tailor the design if needed to meet those requirements.",
    maintquestion_1: "1. What specific parts of your website or app do you need me to maintain?",
    maintquestion_2: "2. What kind of updates or changes do you need on a regular basis?",
    maintquestion_3: "3. How frequently do you need updates or changes to be made?",
    maintquestion_4: "4. What are the current issues or problems that you are facing with your website or app?",
    maintquestion_5: "5. What are your long-term goals for your website or app?",
    maintquestion_6: "6. What is your budget for web and app maintenance?",
    maintquestion_7: "7. What is the expected timeline for the maintenance work?",
    maintquestion_8: "8. Are there any security concerns that need to be addressed?",
    maintquestion_9: "9. Have there been any recent changes to the website or app that may require maintenance?",
    maintquestion_10: "10. Do you have a backup system in place for your website or app?",
    maintquestion_11: "11. What kind of reporting or communication do you expect during the maintenance process?",
    maintquestion_12: "12. What is your preferred method of communication?",
    maintquestion_13: "I Would Iike to be contacted by:",
    //hint seoquestion
    hintmaintquestion_1: "1. The answer to this question will help me understand which parts of the website or app you want me to maintain. It could be anything from updating content to fixing bugs or security issues.",
    hintmaintquestion_2: "2. Understanding the types of updates or changes that you require will help us plan your work and estimate the time required for each task.",
    hintmaintquestion_3: "3. Knowing how often you require updates or changes will help us plan your work schedule and ensure that we meet your expectations.",
    hintmaintquestion_4: "4. This question will help us identify any immediate issues that need to be addressed as part of the maintenance work.",
    hintmaintquestion_5: "5. Understanding your long-term goals will help us identify areas that need to be improved or updated to ensure that the website or app continues to meet your needs.",
    hintmaintquestion_6: "6. Knowing your budget will help us determine the level of service we can provide and which tasks we can take on.",
    hintmaintquestion_7: "7. Answering this question will give us a better idea of your expectations regarding the timeline for the maintenance work. It is important to know if there are any specific deadlines that must be met so that we can plan your work accordingly.",
    hintmaintquestion_8: "8. Knowing if there are any security concerns will help us prioritize your work and ensure that the website or app remains secure.",
    hintmaintquestion_9: "9. This question will help us identify any recent changes that may have caused issues or require further maintenance work.",
    hintmaintquestion_10: "10. Knowing if there is a backup system in place will help us plan for any unforeseen issues or problems that may arise.",
    hintmaintquestion_11: "11. Understanding the level of reporting or communication you expect will help us plan how to keep them updated on the progress of the maintenance work.",
    hintmaintquestion_12: "12. Knowing your preferred method of communication will help us ensure that we are able to communicate effectively throughout the maintenance process.",
    //individual maintenance service
    proceedmanagedescription: "Here are some essential questions that I may ask you before starting your web project. Please fill free to let me get a better understanding of your needs, preferences, and limitations to tailor the design if needed to meet those requirements.",
    managequestion_1: "1. What is the main objective of your project and what do you want to achieve?",
    managequestion_2: "2. What is your budget for this project?",
    managequestion_3: "3. What is the timeline for the project and when do you expect it to be completed?",
    managequestion_4: "4. Who are the stakeholders involved in this project?",
    managequestion_5: "5. What are the risks involved in this project?",
    managequestion_6: "6. What are the project deliverables and how do you measure success?",
    managequestion_7: "7. What are the key performance indicators (KPIs) for the project?",
    managequestion_8: "8. What are the communication channels and protocols for the project?",
    managequestion_9: "9. Who will be responsible for decision-making during the project?",
    managequestion_10: "10. What are the project management tools and software that you use?",
    managequestion_11: "11. What is the project team composition and roles?",
    managequestion_12: "12. What are the post-project support and maintenance requirements?",
    managequestion_13: "I Would Iike to be contacted by:",
    //hint question
    hintmanagequestion_1: "1. This question will help us understand the project's purpose and scope. It will also help us determine the resources needed to complete the project.",
    hintmanagequestion_2: "2. Knowing the budget is essential to determine the feasibility of the project and to plan the resources accordingly.",
    hintmanagequestion_3: "3. This question will help us determine the project's urgency and deadline. It will also help us plan the project milestones and delivery schedule.",
    hintmanagequestion_4: "4. Identifying the stakeholders is essential to understand your needs and requirements. It will also help us manage your expectations and ensure your buy-in for the project.",
    hintmanagequestion_5: "5. Identifying potential risks and challenges in advance will help us plan and mitigate them before they impact the project.",
    hintmanagequestion_6: "6. This question will help us understand what you expect to receive from the project and how you measure success.",
    hintmanagequestion_7: "7. This question will help us determine how to measure the project's success and ensure it aligns with your objectives.",
    hintmanagequestion_8: "8. This question will help us determine the communication needs and preferences of you and the stakeholders. It will also help us establish protocols and procedures to ensure effective communication throughout the project.",
    hintmanagequestion_9: "9. Knowing who has decision-making authority will help us manage the project efficiently and avoid delays.",
    hintmanagequestion_10: "10. This question will help us determine your familiarity with project management tools and the level of technology adoption required for the project.",
    hintmanagequestion_11: "11. This question will help us understand the skills and expertise required for the project and how to assemble a team if needed that meets the project's needs.",
    hintmanagequestion_12: "12. This question will help us understand your expectations for support and maintenance after the project is completed. It will also help us plan for future engagements and long-term relationships with you.",
    //individual miscellanious service
    proceedmisceldescription: "Here are some essential questions that I may ask you before starting your web project. Please fill free to let me get a better understanding of your needs, preferences, and limitations to tailor the design if needed to meet those requirements.",
    miscelquestion_1: "1. Select one task a time to be coded and fill free the bellow questions.",
    miscelquestion_2: "2. What are the specific features and functionalities that you want to add to your task?",
    miscelquestion_3: "3. Have you already designed the user interface (UI) for your task?",
    miscelquestion_4: "4. Do you have any existing code or project documentation that can help me better understand your task?",
    miscelquestion_5: "5. Do you have any preferences or requirements for the technologies used in the task?",
    miscelquestion_6: "6. Will your task require integration with a database or APIs?",
    miscelquestion_7: "7. How do you plan on testing and validating the task?",
    miscelquestion_8: "8. How frequently do you want updates and progress reports on the task?",
    miscelquestion_9: "9. What is your budget and timeline for this task?",
    miscelquestion_10: "10. How will the task be deployed and hosted?",
    miscelquestion_11: "11. What are your security requirements for the task?",
    miscelquestion_12: "12. How will you handle maintenance and support after the task is completed?",
    miscelquestion_13: "I Would Iike to be contacted by:",
    //hint question
    hintmiscelquestion_1: "1. You should select the primary function of your task first.",
    hintmiscelquestion_2: "2. You should provide a detailed list of the features and functionalities you want to add and describe how you envision them working.",
    hintmiscelquestion_3: "3. You should describe if you already have a design for the UI or if you need help designing it.",
    hintmiscelquestion_4: "4. You should provide any existing code or project documentation to help us better understand the scope of the task.",
    hintmiscelquestion_5: "5. You should provide any specific technologies you want to be used or have any requirements for technologies to be avoided.",
    hintmiscelquestion_6: "6. You should describe any database or API integrations that will be required for the task.",
    hintmiscelquestion_7: "7. You should describe how you plan on testing and validating the application to ensure it meets your requirements.",
    hintmiscelquestion_8: "8. You should describe how often you want updates on the task and what type of progress reports you would like to receive.",
    hintmiscelquestion_9: "9. You should provide your budget for the task and the desired timeline for completion.",
    hintmiscelquestion_10: "10. You should describe how the task will be deployed and hosted once it's completed.",
    hintmiscelquestion_11: "11. You should describe any specific security requirements for the task.",
    hintmiscelquestion_12: "12. You should describe how you plan on handling maintenance and support for the task after it is completed.",
    //task
    typetask: "Select task",
    react_task: "React Component",
    html_task: "Html task",
    css_task: "CSS task",
    database_task: "Database integration",
    api_task: "API Develpment",
    function_task: "Functions development",
    algorithm_task: "Algorithm coding",
    other_task: "Other tasks",
    //Blog
    react_blog: "React Blog",
    aspirant_blog: "Aspirant",
    introduction_blog: "Introduction",
    fundamental_blog: "Fundamentals",
    advanced_blog: "Advanced",
    tools_blog: "Tools",
    technologies_blog: "Technologies",
    web_blog: "Web",
    html_blog: "HTML",
    css_blog: "CSS",
    javascript_blog: "Javascript",
    level_blog: "Level",
    beginner_blog: "Beginner",
    intermediate_blog: "Intermediate",
    expert_blog: "Expert",
    master_blog: "Master",
    preambule_blog_1: "Welcome to our step-by-step guide for budding web developers, aimed at helping you become an experienced developer. In this guide, we'll cover everything you need to know to become a web development master, from the basics of programming to the latest web trends and technologies. Whether you're a beginner or an expert, our guide will help you improve your skills and stay ahead of the game.",
    preambule_blog_2: "Our guide is divided into three main categories: Aspirant, Beginner, and Level. Each category contains several subcategories consisting of articles and tutorials that cover various topics in depth. This series of blogs will start with the Aspirant category and end at the Level category, with new blogs and tutorials added regularly to each subcategory. We believe in the importance of taking things step by step, so we've designed our guide to help you progress gradually.",
    preambule_blog_3: "Whether you prefer to learn through videos, books, interactive tutorials, or hands-on projects, we've got you covered. Our guide offers a wide range of learning resources that cater to different learning styles and preferences. For beginners, we also offer Learning by Getting Started real online classes where you can get hands-on experience with web development.",
    preambule_blog_4: "Whether you're looking to start a career in web development or you're a seasoned developer looking to improve your skills, our step-by-step guide will provide you with the knowledge and resources you need to succeed in the world of software development.",
    //
    message_to_wait_dear:"Dear developers,",
    message_to_wait_1:"We are excited to announce that we have a new blog category coming soon! However, we want to make sure that you have fully absorbed the information presented in the previous blog category before moving on to the next one.",
    message_to_wait_2:"We kindly ask that you take your time to read and understand each blog thoroughly. We understand that everyone learns at their own pace, but we suggest waiting until most of our readers have achieved deep reading of the previous blog category before moving on to the next one.",
    message_to_wait_3:"We also invite you to keep checking our website for updates and new blog posts. Whenever you feel ready to move on to the next category, simply click on the category tab and check if new blogs are available.",
    message_to_wait_4:"Thank you for your dedication and interest in our blog. We hope you enjoy our upcoming category just as much as the previous one.",
    message_to_wait_best:"Best regards,",
    message_to_wait_carions:"Carion's The Digital Tradition",
    //Aspirant blogs advice
    aspirant_blog_1:"When you're first starting out on your programming journey, it's important to not jump ahead too quickly. It's tempting to want to dive right into complex projects or advanced topics, but taking the time to build a solid foundation is essential for long-term success.",
    aspirant_blog_2:"By following a step-by-step approach, you can gradually build your knowledge and skills, gaining confidence and expertise along the way. Each step builds on the previous one, so it's important to master each one before moving on to the next.",
    aspirant_blog_3:"For example, if you're just starting out with JavaScript, don't try to tackle advanced concepts like closures or recursion right away. Instead, focus on understanding variables, data types, and basic control flow. Once you've got those down, you can move on to more advanced topics.",
    aspirant_blog_4:"Similarly, if you're learning a new framework like React, don't try to build a complex app right away. Start with simple components and work your way up to more complex ones, gradually building your skills and knowledge as you go.",
    aspirant_blog_5:"Remember, the goal isn't to rush through the process and reach your destination as quickly as possible. It's about enjoying the journey and learning as much as you can along the way. So take your time, be patient, and trust the process.",
    aspirant_blog_6:"As you progress through your programming journey, you will undoubtedly encounter complex problems that will require a solid foundation in the basics. Skipping steps may seem like a shortcut, but it will ultimately hinder your progress and limit your ability to tackle more challenging tasks.",
    aspirant_blog_7:"In addition to building a strong foundation, following a structured approach to learning will help you stay motivated and focused. It's easy to get overwhelmed when faced with a seemingly endless amount of information to learn. By breaking down your learning into smaller, more manageable steps, you can avoid burnout and stay on track.",
    aspirant_blog_8:"Remember that programming is a lifelong journey, and it's important to enjoy the process. Don't get too caught up in chasing the latest technology or framework. Instead, focus on building a solid foundation and honing your problem-solving skills. With time, dedication, and patience, you will become a proficient and confident programmer.",
    aspirant_blog_9:"So, my advice to you as an aspirant developer is to take your time, follow each step of the learning process, and enjoy the journey. With perseverance and dedication, you will achieve your goals and become a successful programmer.",
    //poser une question
    question_select:"Question",
    question_comment:"Comment",
    question_blog:"Add a question",
    cancel_question:"Cancel",
    send_question:"Send your question",
    write_question_blog:"Please write your question or comment",
    question_public:"Public",
    question_private:"Private",
    //web blogs advice
    web_blog_1:"As an aspiring web developer, it's important to approach your journey with intention and a plan for progression. Here are some tips to get started and progress in your web development career:",
    web_blog_2:"1. Start with the basics: Just like with programming, it's important to start with the basics of web development. This includes learning HTML, CSS, and JavaScript. Take the time to understand the fundamentals of each language and practice writing code.",
    web_blog_3:"2. Focus on the user experience: As a web developer, you'll be responsible for creating websites that are both visually appealing and easy to use. It's important to keep the user experience in mind when designing and building websites.",
    web_blog_4:"3. Learn a CSS framework: CSS frameworks, such as Bootstrap and Foundation, can save you time and help you create consistent designs across your website. Learn how to use at least one CSS framework to enhance your development skills.",
    web_blog_5:"4. Understand the importance of responsiveness: With so many people accessing the internet from their mobile devices, it's crucial that websites are responsive and can adapt to different screen sizes. Learn how to create responsive designs that will look great on any device.",
    web_blog_6:"5. Expand your skillset: Once you have a solid foundation in web development, it's important to continue learning and expanding your skillset. Consider learning a back-end language, such as PHP or Ruby, or a JavaScript framework, such as React or Angular.",
    web_blog_7:"6. Practice, practice, practice: The more you practice writing code, the better you'll become. Look for opportunities to practice your web development skills, whether it's through building personal projects or contributing to open-source projects.",
    web_blog_8:"7. Stay up-to-date with industry trends: The world of web development is constantly changing, with new technologies and trends emerging all the time. Stay up-to-date with the latest industry news and trends to ensure that your skills remain relevant and in demand.",
    web_blog_9:"Remember, web development is a journey and it takes time and effort to become proficient in it. Don't be discouraged by setbacks and keep pushing yourself to learn and grow as a developer. Good luck on your web development journey!",
    //level blogs advice
    level_blog_1:"If you're an aspirant web developer looking to advance your skills, you're in the right place. The web development tutorials and tools we've provided in the Aspirant and Web categories are great for getting started and familiarizing yourself with programming languages and tools for web development. However, to truly advance your skills and become a competitive developer in the industry, the Level category is the way to go.",
    level_blog_2:"Think of it as an online virtual classroom, where each subcategory corresponds to a year of training. Starting from the beginner class, you'll work your way up to the intermediate, advanced, and finally the master class, where you'll be able to develop real software projects and compete for top jobs in the field.",
    level_blog_3:"The Level category is perfect for those who want to take real programming courses in a self-taught way, deepen their knowledge, or fill gaps in their understanding. It's like having a personal tutor guiding you every step of the way.",
    level_blog_4:"To access the different subcategories, there are a few requirements you need to meet. Firstly, you should be at least in the fourth degree of secondary school. Secondly, you'll need a laptop or desktop computer for practical lessons, along with a reliable internet connection. Additionally, you'll need a touchscreen mobile phone with a WhatsApp account to communicate with your instructors and classmates. Finally, you'll need to create a free account on our website and submit an application to access one of the subcategories.",
    level_blog_5:"Once your application is approved, you'll take a simple test to assess your knowledge and skills. Don't worry, there's no pass or fail. We use your results to guide you and provide you with the best learning experience possible.",
    level_blog_6:"So if you're serious about becoming a competitive web developer, don't skip any steps. The Level category is the way to go.",
    level_apply:"Apply",
    connect_first:"Log in to your account and then return to the page.",
    connect_cours:"Access courses",
    connect_cours_content:"Enter Your student code and Password to access courses and more or create a new Account for free if not done yet.",
    code_inscrit:"Student code",
    placecodeinscrit:"Enter student code",
    newaccount_incrit:"Not Student Code?",
    editaccount_inscrit:"Edit Student details",
    student_creer:"Create your student account",

    //individual hosting service
    proceedhostingdescription: "Here are some essential questions that I may ask you before starting your web project. Please fill free to let me get a better understanding of your needs, preferences, and limitations to tailor the design if needed to meet those requirements.",
    proceedhostingtitle: "Hosting",
    hostingquestion_1:"1. What type of website do you need to host?",
    hostingquestion_2:"2. What is your expected website traffic?",
    hostingquestion_3:"3. What is your budget for hosting services?",
    hostingquestion_4:"4. What is your expected uptime requirement?",
    hostingquestion_5:"5. Do you need a dedicated IP address?",
    hostingquestion_6:"6. What is your expected website loading speed?",
    hostingquestion_7:"7. Do you need any specific software or programming languages?",
    hostingquestion_8:"8. What is your expected data storage requirement?",
    hostingquestion_9:"9. What level of technical support do you require?",
    hostingquestion_10:"10. What is your expected scalability requirement?",
    hostingquestion_11:"11. What is your expected level of security requirement?",
    hostingquestion_12:"12. What is your expected level of control over the hosting environment?",
    hostingquestion_13:"I Would Iike to be contacted by:",
    //hint hostingquestion
    hinthostingquestion_1: "1. Understanding the type of website that you need to host will help us to determine the appropriate hosting solution for your needs.",
    hinthostingquestion_2: "2. Knowing your expected website traffic will help us to determine the appropriate hosting plan and server resources required to handle the traffic.",
    hinthostingquestion_3: "3. Knowing your budget for hosting services will help us to recommend hosting solutions that are affordable and within your budget.",
    hinthostingquestion_4: "4. Understanding your expected uptime requirement will help us to recommend hosting solutions that provide high uptime and availability.",
    hinthostingquestion_5: "5. Knowing whether you need a dedicated IP address will help us to recommend hosting solutions that provide this feature. ",
    hinthostingquestion_6: "6. Understanding your expected website loading speed will help us to recommend hosting solutions that provide fast page loading times.",
    hinthostingquestion_7: "7. Knowing whether you need any specific software or programming languages will help us to recommend hosting solutions that support those requirements.",
    hinthostingquestion_8: "8. Understanding your expected data storage requirement will help us to recommend hosting solutions that provide sufficient storage capacity.",
    hinthostingquestion_9: "9. Knowing your technical support requirements will help us to recommend hosting solutions that provide the appropriate level of support.",
    hinthostingquestion_10: "10. Understanding your expected scalability requirement will help us to recommend hosting solutions that can accommodate future growth and expansion.",
    hinthostingquestion_11: "11. Knowing your expected level of security requirement will help us to recommend hosting solutions that provide appropriate security features and protocols.",
    hinthostingquestion_12: "12. Understanding your expected level of control over the hosting environment will help us to recommend hosting solutions that provide the appropriate level of control and customization options.",
    //admin dashboard
    service_admin_title:"Service Dashboard",
    admin_onboard:"Onboard",
    admin_planning:"Planning",
    admin_schedule:"Schedule",
    admin_communication:"Communication",
    admin_update:"Update",
    admin_contract:"Contract",
    admin_budget:"Budget",
    admin_timeline:"Timeline",
    admin_metrics:"Metrics",
    admin_view:"View",
    admin_management:"Management",
    admin_new:"New",
    admin_delivery:"Delivery",
    admin_deployment:"Deployment",
    //planning
    objective_requirements:"1. Defining project objectives and requirements: This involves identifying the client's needs, goals, and requirements for the project.",
    project_plan:"2. Creating a project plan: This involves developing a project plan that outlines the scope, timeline, budget, and resources required for the project.",
    project_milestones:"3. Identifying project milestones: This involves breaking down the project into smaller, manageable tasks or milestones that can be tracked and measured.",
    project_timelines:"4. Establishing project timelines: This involves creating a timeline that outlines the start and end dates for each task or milestone.",
    task_responsibilities:"5. Assigning tasks and responsibilities: This involves identifying team members or contractors responsible for each task or milestone, and assigning deadlines and priorities.",
    risks_contingencies:"6. Identifying project risks and contingencies: This involves identifying potential risks or challenges that could impact the project, and developing contingency plans to mitigate these risks.",
    review_adjust:"7. Reviewing and adjusting the project plan: This involves regularly reviewing the project plan and timeline to ensure that the project is on track, and making adjustments as necessary.",
    planning_succes:"This planning saved successfully",
    planning_exists:"This planning has already been registered",
    //schedule
    info_schedule:"Establish a connection and discuss with the client when making an appointment and then validate.",
    //share carions contents
    share_title:"📣 Help us spread the word! Share our web app with your friends and colleagues! 🚀",
    share_description_1:"Dear Carion's Visitors/Users,",
    share_description_2:"We're thrilled to have you as a part of Carion's users! We believe that great things are meant to be shared, and we would greatly appreciate your support in spreading the word about what we offer.",
    share_description_3:"If you've been finding value in using Carion's, we kindly invite you to share it with your friends and colleagues by simply clicking the share icon on your mobile phone. By doing so, you'll be helping others discover a powerful tool that can make their lives easier and more productive.",
    share_description_4:"Here's how you can help:",
    share_description_5:"1️⃣ Open our web app on your mobile phone.",
    share_description_6:"2️⃣ Look for the share icon (usually represented by three dots connected by lines or an arrow pointing upwards).",
    share_description_7:"3️⃣ Tap on the share icon and choose your preferred social media platform or messaging app to share the web app link with your contacts.",
    share_description_8:"Your support means the world to us, and together, we can create a vibrant and thriving community around our web app. Let's empower more individuals to achieve their goals and unlock their full potential.",
    share_description_9:"Thank you for being a part of our journey!",
    share_description_10:"Best regards,",
    share_description_11:"Carion's Ltd",
    //comments
    comment_title:"Comments",
    comment_dear:"Dear users",
    comment_description:"Carion's invites you to support it by sharing your suggestions and proposals that could allow us to improve your user experience and improve this application to serve you better.",
    comment_select:"Evaluation",
    select_star:"Select your assessment",
    comment_why:"Enter your comments and suggestions",
    creer_comment:"Submit",
    comment_name:"Add your name please.",
    comment_place:"Your Name",
    view_all_comment:"View all comments",
    //crud account
    info_titre:"🔐 Manage Your Account with Ease! Create, Edit, and Delete Your Account Hassle-Free! 🔄",
    info_dear:"Dear users,",
    info_intro:"We want to make your experience with our services web app as seamless as possible, which is why we've implemented a user-friendly account management feature. With this feature, you have full control over your account, allowing you to create, edit, and delete it whenever needed, without any complications.",
    info_create:"1️⃣ Create an Account:",
    info_create_detail:"When you first access our web app, click on the 'Sign Up' or 'Create an Account' button. Fill in the required information, set your preferences, and voila! Your account will be created in a few simple steps.",
    info_edit:"2️⃣ Edit Your Account:",
    info_edit_detail:"Need to update your personal details, change your password, or modify your account settings? No problem! Simply navigate to the 'Account Management section and click Edit your Account', where you'll find options to edit your information. Make the necessary changes, save them, and your account will be updated instantly.",
    info_delete:"3️⃣ Delete Your Account:",
    info_delete_detail:"Should you ever decide to delete your account, we've made the process straightforward. Go to the 'Account Management' section and click Delete your Account' option, and follow the prompts. Please note that deleting your account will permanently remove all associated data, so proceed with caution.",
    info_conclu_1:"By offering this hassle-free account management feature, we aim to provide you with flexibility and control over your web app experience. We understand that circumstances change, and you should have the freedom to manage your account as you see fit.",
    info_conclu_2:"If you have any questions or need assistance regarding account management, our support team is always here to help. Simply reach out to us via contact form.",
    info_conclu_3:"Thank you for being a valued user of our web app!",
    info_conclu_4:"Best regards,",
    info_conclu_5:"Carion's Ltd",
    //
    login_before:"Log in before managing your account",
    //delete account
    delete_titre:"⚠️ Important Notice: Account Deletion and Recovery ⚠️",
    delete_texte:"Deleting your account permanently removes all data and information associated with it. Once deleted, it cannot be recovered. Consider this carefully as you will lose access to your account and its contents. To return, you must create a new account. We recommend backing up any important data before proceeding. Our support team is here to help if you have any concerns or questions. Follow the account deletion process in the 'Account management' section. Thank you for being a valued member of Carion's Ltd!",
    delete_conclu_1:"Best regards,",
    delete_conclu_2:"Carion's Ltd",
    delete:"Delete",
    //
    share_with:"Share with",
    check_out:"Discover Carion's Web Solutions. Share the link to help others benefit from our services!",
};