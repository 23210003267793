import React from 'react';
import { useNavigate } from "react-router-dom";
import '../../components/reactquilleditor/myeditor.css';
//
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';

import { AlertModal } from "../../helperfunctions/Helper";

import { 
    TextLogin, 
    LargeSelect,
    GlobalLargeInput,
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
} from "../../global/Styles";

import { MdOutlineEditNote, MdOutlineConfirmationNumber, MdSettingsBackupRestore,} from 'react-icons/md';

import { useRef, useState } from "react";
import Axios from 'axios';
//
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import katex from 'katex';
import "katex/dist/katex.min.css";
window.katex = katex;

const modules = {
  toolbar: [[{header: [1, 2, 3, 4, 5, 6,  false] }],
  [{ color: [] }],
  [{ background: [] }],
  [{ script:  "sub" }, { script:  "super" }],
  [{ font: [] }],
  [{ size: [] }],
  ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
  [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
  ],
  ["link", "image", "video", "formula"],
  ["clean"],
],
};

function EditContractEditor() {
  const {t} = useTranslation();

  const navigateEditContractEditor = useNavigate();

  //alert
  const elementOutId = "modaloutalertcontracteditoredit";
  const elementDropId = "backdropoutalertcontracteditoredit";
  const buttonHandler = "ouiHandlerAlertContractEditorEditBtn";

  const [valueContractEditType, setValueContractEditType] = useState([0]);
  const [valueContractEdit, setValueContractEdit] = useState([0]);
  const [valueContract, setValueContract] = useState("");

  const editcontracreditorTextRef = useRef(null);
  const editSelectTypeContractRef = useRef(null);
  const editSelectTitreContractRef = useRef(null);
  const idEditContractRef = useRef(null);
  const editTitreContractRef = useRef(null);

  //http://localhost:5000/edit/select/contract/titre

  const editSelectTypeContractHandler = async (e) => {
    e.preventDefault();

    const choixlangueDiv = document.getElementById('choixlangue').innerText;
    const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
    //
    const editSelectTypeContract = editSelectTypeContractRef.current.value;
    var resultattype = "";
    //
    await Axios.post("https://micarionsapi.carions.net/edit/select/contract/titre", {
      choixlangueDiv:choixlangueDiv,
      codeutilisateurDiv:codeutilisateurDiv,
      editSelectTypeContract:editSelectTypeContract,
    }).then((responsecontract) => {
      resultattype = responsecontract.data.length;
      if(resultattype === 0){
        const pTexteNotAvailable = t("noteindisponible");
        AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
      }else{
        setValueContractEditType(responsecontract.data);
      }
    });
  }

  const confirmEditContractEditorHandler = async (e) => {
    e.preventDefault();

    const vieweditcontractDiv = document.getElementById('vieweditcontract');
    //
    const editSelectTitreContract = editSelectTitreContractRef.current.value;
    var resultat = "";
    //
    if(!editSelectTitreContract){
      const pTexteNotAvailable = "Select Contract Title";
      AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
    }else{
      await Axios.post("https://micarionsapi.carions.net/update/contract/confirm/titre", {
        editSelectTitreContract:editSelectTitreContract,
      }).then((responseconfirm) => {
        resultat = responseconfirm.data.length;
        if(resultat === 0){
          const pTexteNotAvailable = t("noteindisponible");
          AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
          //
          vieweditcontractDiv.style.display = 'none';
        }else{
          setValueContractEdit(responseconfirm.data);
          //
          vieweditcontractDiv.style.display = 'block';
        }
      });
    }
  }

  const goBackEditContractEditorHandler = async (e) => {
    e.preventDefault();

    navigateEditContractEditor('/contractadmin');
  }

  const submitEditContractEditorHandler = async (e) => {
    e.preventDefault();

    const editcontracreditorText = editcontracreditorTextRef.current.value;
    const idEditContract = idEditContractRef.current.value;
    const editStatusContract = 'online';
    if(!editcontracreditorText){
      const pTexteSaisir = t("textcontenu");
      AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
    }else{
      await Axios.post("https://micarionsapi.carions.net/update/contract/modifier", {
        editcontracreditorText:editcontracreditorText,
        editStatusContract:editStatusContract,
        idEditContract:idEditContract,
      }).then(() => {
        navigateEditContractEditor('/contractadmin');
      });
    }
  }

  return (
    <div id='editcontracteditor'>
      <div>
        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
          <div id="edittitreeditor">
            <TextLogin>{t("typecontenu")}<strong style={{color:'red'}}>*</strong></TextLogin>
            <div style={{display:'block',textAlign:'center'}}>
              <LargeSelect onChange={editSelectTypeContractHandler} ref={editSelectTypeContractRef}>
                <optgroup style={{color:'white', backgroundColor:'green'}} label={t("services")}>
                  <option value=''>{t("typecontenu")}</option>
                  <option value='coding'>{t("software_coding")}</option>
                  <option value='design'>{t("software_design")}</option>
                  <option value='webdev'>{t("software_development")}</option>
                  <option value='maintenance'>{t("software_maintenance")}</option>
                  <option value='management'>{t("software_management")}</option>
                  <option value='miscellanious'>{t("software_miscellanious")}</option>
                </optgroup>
                <optgroup style={{color:'white', backgroundColor:'blue'}} label="Web">
                  <option value='website'>{t("website")}</option>
                  <option value='webapp'>{t("webapp")}</option>
                  <option value='ecommerce'>{t("ecommerce")}</option>
                </optgroup>
                <optgroup style={{color:'white', backgroundColor:'gray'}} label="SEO">
                  <option value='seokeyword'>{t("keyword_research")}</option>
                  <option value='seooptimization'>{t("on_page")}</option>
                  <option value='seotechnical'>{t("technical_audit")}</option>
                  <option value='seomonthly'>{t("monthly_report")}</option>
                </optgroup>
                <optgroup style={{color:'white', backgroundColor:'yellow'}} label="Hosting">
                  <option value='hosting'>Hosting Service</option>
                </optgroup>
              </LargeSelect>
            </div>

            <div>
              <TextLogin>{t("titrecontenu")}<strong style={{color:'red'}}>*</strong></TextLogin>
              <LargeSelect ref={editSelectTitreContractRef}>
                <option value="⬇️ Select Titre ⬇️"> -- Select Titre -- </option>
                {valueContractEditType.map((valtype, indextype) => <option key={indextype} value={valtype.id_contract}>{valtype.contract_titre}</option>)}
              </LargeSelect>
            </div>
            <br/>
            <HandlerContainerDiv>
              <div>
                <ButtonStart onClick={confirmEditContractEditorHandler}>
                  <MdOutlineConfirmationNumber style={{fontSize:'30px'}} />
                  {t("confirmer")}
                </ButtonStart>
              </div>
              <div>
                <BackStart onClick={goBackEditContractEditorHandler}>
                  <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                  {t("retour")}
                </BackStart>
              </div>
            </HandlerContainerDiv>
          </div>
        </div>

        <div id="vieweditcontract">
          {valueContractEdit.map((valview, i) => (
            <div key={i}>
              <TextLogin>{t("titrecontenu")}<strong style={{color:'red'}}>*</strong></TextLogin>
              <div>
                <input style={{display:'none'}} ref={idEditContractRef} defaultValue={valview.id_contract} />
                <div style={{display:'block',textAlign:'center'}}>
                  <GlobalLargeInput type='text' ref={editTitreContractRef} defaultValue={valview.contract_titre} />
                </div>
              </div>
              <br/>
              <div>
                <TextLogin><strong>{t("originaltexte")}</strong></TextLogin>
                <div className="editornotestyles" id="launchingnote"
                  dangerouslySetInnerHTML={{__html: valview.contract_contenu}}
                /> 
              </div>
              {/** */}
            </div>
          ))}

          <div className="editrow">
            <div className="editor">
              {t("nouveautexte")}
              <ReactQuill theme="snow" 
                value={valueContract} 
                onChange={setValueContract}
                className="editor-input"
                modules={modules}
              />
            </div>
            <textarea style={{display:'none'}} ref={editcontracreditorTextRef} defaultValue={valueContract}></textarea>
            <div style={{display:'none'}} 
              dangerouslySetInnerHTML={{__html: valueContract}}
            />
          </div>
          <br/><br/>
          <HandlerContainerDiv>
            <div>
              <ButtonStart onClick={submitEditContractEditorHandler}>
                <MdOutlineEditNote style={{fontSize:'30px'}} />
                {t("modifier")}
              </ButtonStart>
            </div>
            <div>
              <BackStart onClick={goBackEditContractEditorHandler}>
                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                {t("retour")}
              </BackStart>
            </div>
          </HandlerContainerDiv>
        </div>

        <div id="modaloutalertcontracteditoredit"></div>
        <div id="backdropoutalertcontracteditoredit"></div>
      </div>
    </div>
  )
}

export default EditContractEditor