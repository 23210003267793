import React from "react-dom";
import '../../components.css';
//import myeditor
import DeleteServiceEditor from "../servicesquilleditor/DeleteServiceEditor";
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,  
} from '../../../global/Styles';

function ServiceDeleteContenu() {
    const {t} = useTranslation();

    return ( 
        <DivContainerSlide id="servicedeletecontenu">
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t("crudcontenusupprimer")} {t("services")}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <div className="lescontenus">
                <DeleteServiceEditor />
                <br/>
            </div>
        </DivContainerSlide>
    );
}

export default ServiceDeleteContenu;