import React from 'react';
import { useNavigate } from "react-router-dom";
import '../components/components.css';
//
import imageaccount from '../carions-icon-192.png';
//
import { useTranslation } from 'react-i18next';
import '../translations/i18n';
import '../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,
    ReducedFormScrollerWidgetSm, 
    AdminButton,
    BackStart,  
} from '../global/Styles';

function ServicesWebDevelopment() {
    const {t} = useTranslation();

    const navigateServiceWebDevTab = useNavigate();

    const serviceWebSiteHandler = async (e) => {
        e.preventDefault();

        navigateServiceWebDevTab('/servicewebsite');
    }

    const serviceWebAppHandler = async (e) => {
        e.preventDefault();

        navigateServiceWebDevTab('/servicesapp');
    }

    const serviceEcommerceHandler = async (e) => {
        e.preventDefault();

        navigateServiceWebDevTab('/servicesecommerce');
    }


    const goBackServiceebDevHandler = async (e) => {
        e.preventDefault();

        navigateServiceWebDevTab(-1);
    }

    return (
        <DivContainerSlide id='servicewebdevelpment'>
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t("webdevelopment")}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <img src={imageaccount} alt="" />
            </div>
            <ReducedFormScrollerWidgetSm>
                <div className="adminservicesstyle">
                    <div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={serviceWebSiteHandler}>{t("website")}</AdminButton>
                        </div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={serviceWebAppHandler}>{t("webapp")}</AdminButton>
                        </div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={serviceEcommerceHandler}>{t("ecommerce")}</AdminButton>
                        </div>
                    </div>
                    <br/>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                        <div>
                            <BackStart onClick={goBackServiceebDevHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </div>
                    <br/>
                </div>
            </ReducedFormScrollerWidgetSm>
        </DivContainerSlide>
    )
}

export default ServicesWebDevelopment