import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../../components.css';
//
import imageaccount from '../../../carions-icon-192.png';
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,
    ReducedFormScrollerWidgetSm, 
    BigButton,
    AdminButton,
    BackStart,  
} from '../../../global/Styles';

function CrudServicesCompo() {
    const {t} = useTranslation();

    const navigateCrudServicesCompo = useNavigate();

    const servicesCreateHandler = async (e) => {
        e.preventDefault();

        navigateCrudServicesCompo('/servicecreatecontenu');
    }

    const serviceslUpdateHandler = async (e) => {
        e.preventDefault();
        
        navigateCrudServicesCompo('/serviceeditcontenu');
    }

    const servicesDeleteHandler = async (e) => {
        e.preventDefault();

        navigateCrudServicesCompo('/servicedeletecontenu');
    }

    const servicesVisualiserHandler = async (e) => {
        e.preventDefault();

        navigateCrudServicesCompo('/viewserviceseditor');
    }

    const servicesAdminHandler = async (e) => {
        e.preventDefault();

        navigateCrudServicesCompo('/mainadmin');
    }

    const goBackcrudservicesHandler = async (e) => {
        e.preventDefault();

        navigateCrudServicesCompo('/');
    }

    return ( 
        <DivContainerSlide id="crudservicescompo">
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t("crudservicestitre")}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <img src={imageaccount} alt="" />
            </div>
            <ReducedFormScrollerWidgetSm>
                <div className="crudservicesstyle">
                    <div>
                        <div className="largebuttonstyle">
                            <BigButton onClick={servicesCreateHandler}>{t("crudcreercontenu")}</BigButton>
                        </div>
                        <div className="largebuttonstyle">
                            <BigButton onClick={serviceslUpdateHandler}>{t("crudcontenumodifier")}</BigButton>
                        </div>
                        <div className="largebuttonstyle">
                            <BigButton onClick={servicesDeleteHandler}>{t("crudaccueilsupprimer")}</BigButton>
                        </div>
                        <div className="largebuttonstyle">
                            <BigButton onClick={servicesVisualiserHandler}>{t("crudaccueilvisualiser")}</BigButton>
                        </div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={servicesAdminHandler}>Admin Dashboard</AdminButton>
                        </div>
                    </div>
                    <br/>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                        <div>
                            <BackStart onClick={goBackcrudservicesHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </div>
                    <br/>
                </div>
            </ReducedFormScrollerWidgetSm>
        </DivContainerSlide>
     );
}

export default CrudServicesCompo;