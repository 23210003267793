import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../../reactquilleditor/myeditor.css';
//
import { MdOutlineCreate, MdSettingsBackupRestore,} from 'react-icons/md';

import { useRef, /*useState*/ } from "react";
import Axios from 'axios';
import '../../../translations/Translation';

import { AlertModal, getCurrentDate } from "../../../helperfunctions/Helper";
import { 
    FormScrollerWidgetSm,
    TextLoginService, 
    LargeSelect,
    GlobalLargeInput,
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
    TextAreaView,
} from "../../../global/Styles";
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';

function CreateServicesEditor() {
    const {t} = useTranslation();

    const navigateCreateServicesEditor = useNavigate();

    //alert
    const elementOutId = "modaloutalertserviceseditorcreate";
    const elementDropId = "backdropoutalertserviceseditorcreate";
    const buttonHandler = "ouiHandlerAlertServicesEditorCreateBtn";

    const createSelectTypeServicesContenuRef = useRef(null);
    const createSelectPlanServicesContenuRef = useRef(null);
    const createParagraphServiceContenu1Ref = useRef(null);
    const createParagraphServiceContenu2Ref = useRef(null);
    const createParagraphServiceContenu3Ref = useRef(null);
    const createParagraphServiceContenu4Ref = useRef(null);
    const createTitreServicesContenuRef = useRef(null);
    const createPricesServicesContenuRef = useRef(null);
    const createDescriptionServicesContenuRef = useRef(null);
    const createDeliveryServicesContenuRef = useRef(null);
    const createRevisionServicesContenuRef = useRef(null);
    const createPageServicesContenuRef = useRef(null);
    const createDesignServicesContenuRef = useRef(null);
    const createContentServicesContenuRef = useRef(null);
    const createResponsiveServicesContenuRef = useRef(null);
    const createSourceServicesContenuRef = useRef(null);
    //
    const createTitleHandler = async (e) => {
        e.preventDefault();

        createTitreServicesContenuRef.current.value = t("titre_service");
    }

    const createDeliveryHandler = async (e) => {
        e.preventDefault();

        createDeliveryServicesContenuRef.current.value = t("delivery");
    }

    const createPriceHandler = async (e) => {
        e.preventDefault();

        createPricesServicesContenuRef.current.value = t("price");
    }

    const createRevisionHandler = async (e) => {
        e.preventDefault();

        createRevisionServicesContenuRef.current.value = t("revision");
    }

    const createPageHandler = async (e) => {
        e.preventDefault();

        createPageServicesContenuRef.current.value = t("page");
    }

    const createDesignHandler = async (e) => {
        e.preventDefault();

        createDesignServicesContenuRef.current.value = t("customization");
    }

    const createContentHandler = async (e) => {
        e.preventDefault();

        createContentServicesContenuRef.current.value = t("content");
    }

    const createResponsiveHandler = async (e) => {
        e.preventDefault();

        createResponsiveServicesContenuRef.current.value = t("responsive");
    }

    const createSourceHandler = async (e) => {
        e.preventDefault();

        createSourceServicesContenuRef.current.value = t("source");
    }

    //http://localhost:5000/website/tab/create

    const submitCreateServicesEditorHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const fonctionuserDiv = document.getElementById('fonctionuser').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        //
        const createSelectTypeServicesContenu = createSelectTypeServicesContenuRef.current.value;
        const createSelectPlanServicesContenu = createSelectPlanServicesContenuRef.current.value;
        const createParagraphServiceContenu1 = createParagraphServiceContenu1Ref.current.value;
        const createParagraphServiceContenu2 = createParagraphServiceContenu2Ref.current.value;
        const createParagraphServiceContenu3 = createParagraphServiceContenu3Ref.current.value;
        const createParagraphServiceContenu4 = createParagraphServiceContenu4Ref.current.value;
        const createTitreServicesContenu = createTitreServicesContenuRef.current.value;
        const createPricesServicesContenu = createPricesServicesContenuRef.current.value;
        const createDescriptionServicesContenu = createDescriptionServicesContenuRef.current.value;
        const createDeliveryServicesContenu = createDeliveryServicesContenuRef.current.value;
        const createRevisionServicesContenu = createRevisionServicesContenuRef.current.value;
        const createPageServicesContenu = createPageServicesContenuRef.current.value;
        const createDesignServicesContenu = createDesignServicesContenuRef.current.value;
        const createContentServicesContenu = createContentServicesContenuRef.current.value;
        const createResponsiveServicesContenu = createResponsiveServicesContenuRef.current.value;
        const createSourceServicesContenu = createSourceServicesContenuRef.current.value;
        //
        const createServicesStatut = 'online';
        const createServicesDate = getCurrentDate();

        if(!createSelectTypeServicesContenu){
            const pTexteSaisir = t("type_contenu");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createSelectPlanServicesContenu){
            const pTexteSaisir = t("titre_plan");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createParagraphServiceContenu1){
            const pTexteSaisir = t("type_paragraphe");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createTitreServicesContenu){
            const pTexteSaisir = t("titre_contenu");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createPricesServicesContenu){
            const pTexteSaisir = t("titre_price");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createDescriptionServicesContenu){
            const pTexteSaisir = t("titre_description");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createDeliveryServicesContenu){
            const pTexteSaisir = t("titre_delivery");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createRevisionServicesContenu){
            const pTexteSaisir = t("titre_revision");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createPageServicesContenu){
            const pTexteSaisir = t("titre_page");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createDesignServicesContenu){
            const pTexteSaisir = t("titre_design");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler); 
        }else if(!createContentServicesContenu){
            const pTexteSaisir = t("titre_upload");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);  
        }else if(!createResponsiveServicesContenu){
            const pTexteSaisir = t("titre_responsive");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler); 
        }else if(!createSourceServicesContenu){
            const pTexteSaisir = t("titre_source");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler); 
        }else{
            await Axios.post("https://micarionsapi.carions.net/website/tab/create", {
                choixlangueDiv:choixlangueDiv,
                fonctionuserDiv:fonctionuserDiv,
                codeutilisateurDiv:codeutilisateurDiv,
                createSelectTypeServicesContenu:createSelectTypeServicesContenu,
                createSelectPlanServicesContenu:createSelectPlanServicesContenu,
                createParagraphServiceContenu1:createParagraphServiceContenu1,
                createParagraphServiceContenu2:createParagraphServiceContenu2,
                createParagraphServiceContenu3:createParagraphServiceContenu3,
                createParagraphServiceContenu4:createParagraphServiceContenu4,
                createTitreServicesContenu:createTitreServicesContenu,
                createPricesServicesContenu:createPricesServicesContenu,
                createDescriptionServicesContenu:createDescriptionServicesContenu,
                createDeliveryServicesContenu:createDeliveryServicesContenu,
                createRevisionServicesContenu:createRevisionServicesContenu,
                createPageServicesContenu:createPageServicesContenu,
                createDesignServicesContenu:createDesignServicesContenu,
                createContentServicesContenu:createContentServicesContenu,
                createResponsiveServicesContenu:createResponsiveServicesContenu,
                createSourceServicesContenu:createSourceServicesContenu,
                createServicesStatut:createServicesStatut,
                createServicesDate:createServicesDate,
            }).then(() => {
                const pTexteSaisir = t("tab_success");
                AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
            });
        }
    }

    const goBackCreateServicesEditorHandler = async (e) => {
        e.preventDefault();

        navigateCreateServicesEditor('/crudservicescompo');
    }

    return ( 
        <div id="createserviceseditor">
            <FormScrollerWidgetSm>
                <div style={{overflowY:'scroll'}} id="createservicestitreeditor">
                    <br/>
                    <TextLoginService>{t("typecontenu")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <LargeSelect ref={createSelectTypeServicesContenuRef}>
                            <option value=''>{t("typecontenu")}</option>
                            <option value='web'>Web Development</option>
                            <option value='app'>App Development</option>
                            <option value='ecom'>E-Commerce Development</option>
                            <option value='keywordseo'>Keyword Research SEO</option>
                            <option value='onpageseo'>On-Page Optimization SEO</option>
                            <option value='technicalseo'>Technical SEO Audit</option>
                            <option value='monthlyseo'>Monthly Reporting SEO</option>
                            <option value='codingsoft'>Software Coding Services</option>
                            <option value='designsoft'>Software Design Services</option>
                            <option value='devsoft'>Software Development Services</option>
                            <option value='maintsoft'>Software Maintenance Services</option>
                            <option value='managesoft'>Software Management Services</option>
                            <option value='miscsoft'>Software Miscellanious Services</option>
                            <option value='hosting'>Hosting</option>
                            <option value='rental'>Rental Apps</option>
                            <option value='purchase'>Purchase Apps</option>
                            <option value='training'>Training</option>
                        </LargeSelect>
                    </div>
                    
                    <TextLoginService>{t("type_plan")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <LargeSelect ref={createSelectPlanServicesContenuRef}>
                            <option value=''>{t("select_plan")}</option>
                            <option value='basic'>{t("basic_plan")}</option>
                            <option value='standard'>{t("standard_plan")}</option>
                            <option value='Premium'>{t("premium_plan")}</option>
                        </LargeSelect>
                    </div>
                    
                    <TextLoginService>{t("paragraphe_1")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextAreaView ref={createParagraphServiceContenu1Ref}></TextAreaView>
                    </div>
                    
                    <TextLoginService>{t("paragraphe_2")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextAreaView ref={createParagraphServiceContenu2Ref}></TextAreaView>
                    </div>
                    
                    <TextLoginService>{t("paragraphe_3")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextAreaView ref={createParagraphServiceContenu3Ref}></TextAreaView>
                    </div>
                    
                    <TextLoginService>{t("paragraphe_4")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextAreaView ref={createParagraphServiceContenu4Ref}></TextAreaView>
                    </div>
                    
                    <TextLoginService>{t("service_title")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput onClick={createTitleHandler} type='text' ref={createTitreServicesContenuRef} placeholder="Enter Service Title"/>
                    </div>
                    
                    <TextLoginService>{t("service_price")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput onClick={createPriceHandler} type='text' ref={createPricesServicesContenuRef} placeholder="Enter Price"/>
                    </div>

                    <TextLoginService>{t("plan_description")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextAreaView ref={createDescriptionServicesContenuRef}></TextAreaView>
                    </div>

                    <TextLoginService>{t("days_delivery")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput onClick={createDeliveryHandler} type='text' ref={createDeliveryServicesContenuRef} placeholder="Enter Days Number"/>
                    </div>

                    <TextLoginService>{t("revision_number")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput onClick={createRevisionHandler} type='text' ref={createRevisionServicesContenuRef} placeholder="Enter Revision Number"/>
                    </div>

                    <TextLoginService>{t("page_number")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput onClick={createPageHandler} type='text' ref={createPageServicesContenuRef} placeholder="Enter Page Number"/>
                    </div>

                    <TextLoginService>{t("design_customization")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput onClick={createDesignHandler} type='text' ref={createDesignServicesContenuRef} placeholder="Enter Design customization"/>
                    </div>

                    <TextLoginService>{t("content_upload")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput onClick={createContentHandler} type='text' ref={createContentServicesContenuRef} placeholder="Enter Content upload"/>
                    </div>

                    <TextLoginService>{t("responsive_design")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput onClick={createResponsiveHandler} type='text' ref={createResponsiveServicesContenuRef} placeholder="Enter Responsive design"/>
                    </div>

                    <TextLoginService>{t("source_code")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput onClick={createSourceHandler} type='text' ref={createSourceServicesContenuRef} placeholder="Enter Source Code"/>
                    </div>
                    <br/>
                    <HandlerContainerDiv>
                        <div>
                            <ButtonStart onClick={submitCreateServicesEditorHandler}>
                                <MdOutlineCreate style={{fontSize:'30px'}} />
                                {t("creer")}
                            </ButtonStart>
                        </div>
                        <div>
                            <BackStart onClick={goBackCreateServicesEditorHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                </div>
                <br/><br/>
                <div id="modaloutalertserviceseditorcreate"></div>
                <div id="backdropoutalertserviceseditorcreate"></div>
                
            </FormScrollerWidgetSm>
        </div>
    );
}

export default CreateServicesEditor;