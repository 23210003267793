import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { TRANSLATIONS_FR } from './francais/translations';
import { TRANSLATIONS_EN } from './anglais/translations';
import { TRANSLATIONS_IT } from './italien/translations';

i18n
.use (LanguageDetector)
.use (initReactI18next)
.init ({
    resources: {
        fr: {
            translation: TRANSLATIONS_FR
        },
        en: {
            translation: TRANSLATIONS_EN
        },
        it: {
            translation: TRANSLATIONS_IT
        }
    }
});

i18n.changeLanguage("fr");
//export default i18n;