import React from 'react';
import { useNavigate } from "react-router-dom";
import '../proceedweb.css';
import { useRef, useState } from "react";
//
import { MdOutlineCreate, MdSettingsBackupRestore, MdOutlineArrowForwardIos, MdOutlineArrowBackIos, MdOutlineAttachEmail } from 'react-icons/md';
import { AiOutlineWhatsApp } from 'react-icons/ai';
//
import { AlertModal, getCurrentDate, PopupModal } from "../../helperfunctions/Helper";
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
import {  
    SpanGlobalSpan,
    GlobalStrongTitle,
    LongFormScrollerWidgetSm,
    TextProceedService, 
    TextLoginService,
    GlobalLargeInput, 
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
    TextArea,
} from "../../global/Styles";

import Axios from 'axios';

function ProceedHosting() {
    const {t} = useTranslation();

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleHostingChange = event => {
        if (!isValidEmail(event.target.value)) {
          setError(t('invalidemail'));
        } else {
          setError(null);
        }
    
        setEmail(event.target.value);
    };

    const navigateProceedHosting = useNavigate();

    //alert
    const elementOutId = "modaloutproceedhosting";
    const elementDropId = "backdropoutproceedhosting";
    const buttonHandler = "ouiHandlerProceedHostingBtn";
    //
    const elementOutAlertId = "modaloutalertproceedhosting";
    const elementDropAlertId = "backdropoutalertproceedhosting";
    const buttonAlertHandler = "ouiHandleralertProceedHostingBtn";

    const hostingQuestion1Ref = useRef(null);
    const hostingQuestion2Ref = useRef(null);
    const hostingQuestion3Ref = useRef(null);
    //
    const hostingQuestion4Ref = useRef(null);
    const hostingQuestion5Ref = useRef(null);
    const hostingQuestion6Ref = useRef(null);
    //
    const hostingQuestion7Ref = useRef(null);
    const hostingQuestion8Ref = useRef(null);
    const hostingQuestion9Ref = useRef(null);
    //
    const hostingQuestion10Ref = useRef(null);
    const hostingQuestion11Ref = useRef(null);
    const hostingQuestion12Ref = useRef(null);
    //
    const hostingQuestion13Ref = useRef(null);
    const hostingQuestion14Ref = useRef(null);

    const pophosting1Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("hostingquestion_1");
        const pTexteNotAvailable = t("hinthostingquestion_1");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const pophosting2Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("hostingquestion_2");
        const pTexteNotAvailable = t("hinthostingquestion_2");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const pophosting3Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("hostingquestion_3");
        const pTexteNotAvailable = t("hinthostingquestion_3");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const pophosting4Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("hostingquestion_4");
        const pTexteNotAvailable = t("hinthostingquestion_4");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const pophosting5Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("hostingquestion_5");
        const pTexteNotAvailable = t("hinthostingquestion_5");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const pophosting6Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("hostingquestion_6");
        const pTexteNotAvailable = t("hinthostingquestion_6");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const pophosting7Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("hostingquestion_7");
        const pTexteNotAvailable = t("hinthostingquestion_7");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const pophosting8Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("hostingquestion_8");
        const pTexteNotAvailable = t("hinthostingquestion_8");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const pophosting9Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("hostingquestion_9");
        const pTexteNotAvailable = t("hinthostingquestion_9");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const pophosting10Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("hostingquestion_10");
        const pTexteNotAvailable = t("hinthostingquestion_10");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const pophosting11Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("hostingquestion_11");
        const pTexteNotAvailable = t("hinthostingquestion_11");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const pophosting12Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("hostingquestion_12");
        const pTexteNotAvailable = t("hinthostingquestion_12");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const forwardProceedHosting1Handler = async (e) => {
        e.preventDefault();

        const hostingsteponeDiv = document.getElementById('hostingstepone');
        const hostingsteptwoDiv = document.getElementById('hostingsteptwo');
        const hostingstepthreeDiv = document.getElementById('hostingstepthree');
        const hostingstepfourDiv = document.getElementById('hostingstepfour');
        const modeproceedhostingDiv = document.getElementById('modeproceedhosting');
        const submitproceedhostingDiv = document.getElementById('submitproceedhosting');
        //
        hostingsteponeDiv.style.display = 'none';
        hostingsteptwoDiv.style.display = 'block';
        hostingstepthreeDiv.style.display = 'none';
        hostingstepfourDiv.style.display = 'none';
        modeproceedhostingDiv.style.display = 'none';
        submitproceedhostingDiv.style.display = 'none';
    }

    const goBackProceedHosting2Handler = async (e) => {
        e.preventDefault();

        const hostingsteponeDiv = document.getElementById('hostingstepone');
        const hostingsteptwoDiv = document.getElementById('hostingsteptwo');
        const hostingstepthreeDiv = document.getElementById('hostingstepthree');
        const hostingstepfourDiv = document.getElementById('hostingstepfour');
        const modeproceedhostingDiv = document.getElementById('modeproceedhosting');
        const submitproceedhostingDiv = document.getElementById('submitproceedhosting');
        //
        hostingsteponeDiv.style.display = 'block';
        hostingsteptwoDiv.style.display = 'none';
        hostingstepthreeDiv.style.display = 'none';
        hostingstepfourDiv.style.display = 'none';
        modeproceedhostingDiv.style.display = 'none';
        submitproceedhostingDiv.style.display = 'none';
    }
    //
    const forwardProceedHosting2Handler = async (e) => {
        e.preventDefault();

        const hostingsteponeDiv = document.getElementById('hostingstepone');
        const hostingsteptwoDiv = document.getElementById('hostingsteptwo');
        const hostingstepthreeDiv = document.getElementById('hostingstepthree');
        const hostingstepfourDiv = document.getElementById('hostingstepfour');
        const modeproceedhostingDiv = document.getElementById('modeproceedhosting');
        const submitproceedhostingDiv = document.getElementById('submitproceedhosting');
        //
        hostingsteponeDiv.style.display = 'none';
        hostingsteptwoDiv.style.display = 'none';
        hostingstepthreeDiv.style.display = 'block';
        hostingstepfourDiv.style.display = 'none';
        modeproceedhostingDiv.style.display = 'none';
        submitproceedhostingDiv.style.display = 'none';
    }

    const goBackProceedHosting3Handler = async (e) => {
        e.preventDefault();

        const hostingsteponeDiv = document.getElementById('hostingstepone');
        const hostingsteptwoDiv = document.getElementById('hostingsteptwo');
        const hostingstepthreeDiv = document.getElementById('hostingstepthree');
        const hostingstepfourDiv = document.getElementById('hostingstepfour');
        const modeproceedhostingDiv = document.getElementById('modeproceedhosting');
        const submitproceedhostingDiv = document.getElementById('submitproceedhosting');
        //
        hostingsteponeDiv.style.display = 'none';
        hostingsteptwoDiv.style.display = 'block';
        hostingstepthreeDiv.style.display = 'none';
        hostingstepfourDiv.style.display = 'none';
        modeproceedhostingDiv.style.display = 'none';
        submitproceedhostingDiv.style.display = 'none';
    }
    //
    const forwardProceedHosting3Handler = async (e) => {
        e.preventDefault();

        const hostingsteponeDiv = document.getElementById('hostingstepone');
        const hostingsteptwoDiv = document.getElementById('hostingsteptwo');
        const hostingstepthreeDiv = document.getElementById('hostingstepthree');
        const hostingstepfourDiv = document.getElementById('hostingstepfour');
        const modeproceedhostingDiv = document.getElementById('modeproceedhosting');
        const submitproceedhostingDiv = document.getElementById('submitproceedhosting');
        //
        hostingsteponeDiv.style.display = 'none';
        hostingsteptwoDiv.style.display = 'none';
        hostingstepthreeDiv.style.display = 'none';
        hostingstepfourDiv.style.display = 'block';
        modeproceedhostingDiv.style.display = 'none';
        submitproceedhostingDiv.style.display = 'none';
    }
    
    const goBackProceedHosting4Handler = async (e) => {
        e.preventDefault();

        const hostingsteponeDiv = document.getElementById('hostingstepone');
        const hostingsteptwoDiv = document.getElementById('hostingsteptwo');
        const hostingstepthreeDiv = document.getElementById('hostingstepthree');
        const hostingstepfourDiv = document.getElementById('hostingstepfour');
        const modeproceedhostingDiv = document.getElementById('modeproceedhosting');
        const submitproceedhostingDiv = document.getElementById('submitproceedhosting');
        //
        hostingsteponeDiv.style.display = 'none';
        hostingsteptwoDiv.style.display = 'none';
        hostingstepthreeDiv.style.display = 'block';
        hostingstepfourDiv.style.display = 'none';
        modeproceedhostingDiv.style.display = 'none';
        submitproceedhostingDiv.style.display = 'none';
    }
    //
    const forwardProceedHosting4Handler = async (e) => {
        e.preventDefault();

        const hostingsteponeDiv = document.getElementById('hostingstepone');
        const hostingsteptwoDiv = document.getElementById('hostingsteptwo');
        const hostingstepthreeDiv = document.getElementById('hostingstepthree');
        const hostingstepfourDiv = document.getElementById('hostingstepfour');
        const modeproceedhostingDiv = document.getElementById('modeproceedhosting');
        const submitproceedhostingDiv = document.getElementById('submitproceedhosting');
        //
        hostingsteponeDiv.style.display = 'none';
        hostingsteptwoDiv.style.display = 'none';
        hostingstepthreeDiv.style.display = 'none';
        hostingstepfourDiv.style.display = 'none';
        modeproceedhostingDiv.style.display = 'block';
        submitproceedhostingDiv.style.display = 'none';
    }

    const goBackProceedHosting5Handler = async (e) => {
        e.preventDefault();

        const hostingsteponeDiv = document.getElementById('hostingstepone');
        const hostingsteptwoDiv = document.getElementById('hostingsteptwo');
        const hostingstepthreeDiv = document.getElementById('hostingstepthree');
        const hostingstepfourDiv = document.getElementById('hostingstepfour');
        const modeproceedhostingDiv = document.getElementById('modeproceedhosting');
        const submitproceedhostingDiv = document.getElementById('submitproceedhosting');
        //
        hostingsteponeDiv.style.display = 'none';
        hostingsteptwoDiv.style.display = 'none';
        hostingstepthreeDiv.style.display = 'none';
        hostingstepfourDiv.style.display = 'block';
        modeproceedhostingDiv.style.display = 'none';
        submitproceedhostingDiv.style.display = 'none';
    }
    //
    const forwardProceedHosting5Handler = async (e) => {
        e.preventDefault();

        const hostingQuestion13 = hostingQuestion13Ref.current.value;
        const hostingQuestion14 = hostingQuestion14Ref.current.value;
        //
        const hostingsteponeDiv = document.getElementById('hostingstepone');
        const hostingsteptwoDiv = document.getElementById('hostingsteptwo');
        const hostingstepthreeDiv = document.getElementById('hostingstepthree');
        const hostingstepfourDiv = document.getElementById('hostingstepfour');
        const modeproceedhostingDiv = document.getElementById('modeproceedhosting');
        const submitproceedhostingDiv = document.getElementById('submitproceedhosting');
        //
        if(!hostingQuestion13){
            const pTexteSaisir = t("enteremail");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else if(!hostingQuestion14){
            const pTexteSaisir = t("enterphone");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else{
            //
            hostingsteponeDiv.style.display = 'none';
            hostingsteptwoDiv.style.display = 'none';
            hostingstepthreeDiv.style.display = 'none';
            hostingstepfourDiv.style.display = 'none';
            modeproceedhostingDiv.style.display = 'none';
            submitproceedhostingDiv.style.display = 'block';
        }
    }

    const goBackProceedHosting6Handler = async (e) => {
        e.preventDefault();

        const hostingsteponeDiv = document.getElementById('hostingstepone');
        const hostingsteptwoDiv = document.getElementById('hostingsteptwo');
        const hostingstepthreeDiv = document.getElementById('hostingstepthree');
        const hostingstepfourDiv = document.getElementById('hostingstepfour');
        const modeproceedhostingDiv = document.getElementById('modeproceedhosting');
        const submitproceedhostingDiv = document.getElementById('submitproceedhosting');
        //
        hostingsteponeDiv.style.display = 'none';
        hostingsteptwoDiv.style.display = 'none';
        hostingstepthreeDiv.style.display = 'none';
        hostingstepfourDiv.style.display = 'none';
        modeproceedhostingDiv.style.display = 'block';
        submitproceedhostingDiv.style.display = 'none';
    }

    //http://localhost:5000/check/hostingservice

    const submitProceedHostingHandler = async (e) => {
        e.preventDefault();

        const hostingQuestion1 = hostingQuestion1Ref.current.value;
        const hostingQuestion2 = hostingQuestion2Ref.current.value;
        const hostingQuestion3 = hostingQuestion3Ref.current.value;
        //websteptwo
        const hostingQuestion4 = hostingQuestion4Ref.current.value;
        const hostingQuestion5 = hostingQuestion5Ref.current.value;
        const hostingQuestion6 = hostingQuestion6Ref.current.value;
        //webstepthree
        const hostingQuestion7 = hostingQuestion7Ref.current.value;
        const hostingQuestion8 = hostingQuestion8Ref.current.value;
        const hostingQuestion9 = hostingQuestion9Ref.current.value;
        //webstepfour
        const hostingQuestion10 = hostingQuestion10Ref.current.value;
        const hostingQuestion11 = hostingQuestion11Ref.current.value;
        const hostingQuestion12 = hostingQuestion12Ref.current.value;
        //modeproceedweb
        const hostingQuestion13 = hostingQuestion13Ref.current.value;
        const hostingQuestion14 = hostingQuestion14Ref.current.value;
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const theDate = getCurrentDate();
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = "hosting";
        const servicePlan = 'basic';
        var createTicketCode = "";
        //
        var resultat1 = "";
        //
        if(!codeutilisateurDiv){
            navigateProceedHosting('/connectaccount'); 
        }else{
            //generate random ticket code
            var randomNumber = Math.floor((Math.random() * 100000) + 1);
            createTicketCode = randomNumber+''+codeutilisateurDiv;
            //
            await Axios.post("https://micarionsapi.carions.net/check/hostingservice", {
                codeutilisateurDiv:codeutilisateurDiv,
                typeService:typeService,
                servicePlan:servicePlan,
            }).then((response1) => {
                resultat1 = response1.data.length;
                if(resultat1 === 0){
                    //insert
                    Axios.post("https://micarionsapi.carions.net/quest/hostingservice", {
                        choixlangueDiv:choixlangueDiv,
                        usernamedetailDiv:usernamedetailDiv,
                        codeutilisateurDiv:codeutilisateurDiv,
                        //
                        typeService:typeService,
                        servicePlan:servicePlan,
                        //
                        createTicketCode:createTicketCode,
                        //
                        hostingQuestion1:hostingQuestion1,
                        hostingQuestion2:hostingQuestion2,
                        hostingQuestion3:hostingQuestion3,
                        //
                        hostingQuestion4:hostingQuestion4,
                        hostingQuestion5:hostingQuestion5,
                        hostingQuestion6:hostingQuestion6,
                        //
                        hostingQuestion7:hostingQuestion7,
                        hostingQuestion8:hostingQuestion8,
                        hostingQuestion9:hostingQuestion9,
                        //
                        hostingQuestion10:hostingQuestion10,
                        hostingQuestion11:hostingQuestion11,
                        hostingQuestion12:hostingQuestion12,
                        //
                        hostingQuestion13:hostingQuestion13,
                        hostingQuestion14:hostingQuestion14,
                        //
                        theDate:theDate,
                    }).then(() => {
                        var answer1 = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                        const hTitleTexte = "Hosting";
                        const pTexteNotAvailable = answer1;
                        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
                    });
                }else{
                    //set up modal to update
                    var answer = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                    const hTitleTexte = "Hosting";
                    const pTexteNotAvailable = answer;
                    PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
                }
            });
        }
    }

    const goBackProceedHostingHandler = async (e) => {
        e.preventDefault();

        navigateProceedHosting('/');
    }

    return (
        <div id='proceedhosting'>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>Hosting</GlobalStrongTitle>
            </SpanGlobalSpan>
            <LongFormScrollerWidgetSm>
                <div id="modaloutproceedhosting"></div>
                <div id="backdropoutproceedhosting"></div>
                <p className="pservicestyles"><br/>{t("proceedhostingdescription")}</p>
                <div id="hostingstepone">
                    <TextProceedService onClick={pophosting1Handler}>{t("hostingquestion_1")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={hostingQuestion1Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={pophosting2Handler}>{t("hostingquestion_2")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={hostingQuestion2Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={pophosting3Handler}>{t("hostingquestion_3")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={hostingQuestion3Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <ButtonStart onClick={forwardProceedHosting1Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="hostingsteptwo">
                    <TextProceedService onClick={pophosting4Handler}>{t("hostingquestion_4")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={hostingQuestion4Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={pophosting5Handler}>{t("hostingquestion_5")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={hostingQuestion5Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={pophosting6Handler}>{t("hostingquestion_6")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={hostingQuestion6Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedHosting2Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedHosting2Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="hostingstepthree">
                    <TextProceedService onClick={pophosting7Handler}>{t("hostingquestion_7")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={hostingQuestion7Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={pophosting8Handler}>{t("hostingquestion_8")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={hostingQuestion8Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={pophosting9Handler}>{t("hostingquestion_9")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={hostingQuestion9Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedHosting3Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedHosting3Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="hostingstepfour">
                    <TextProceedService onClick={pophosting10Handler}>{t("hostingquestion_10")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={hostingQuestion10Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={pophosting11Handler}>{t("hostingquestion_11")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={hostingQuestion11Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={pophosting12Handler}>{t("hostingquestion_12")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={hostingQuestion12Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedHosting4Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedHosting4Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="modeproceedhosting">
                    <TextProceedService>{t("webquestion_13")}</TextProceedService>
                    <TextLoginService><MdOutlineAttachEmail style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput id="emailcodin" name="emailcodin" value={email} onChange={handleHostingChange} ref={hostingQuestion13Ref} placeholder={t("placemail")}/>
                        {error && <h5 style={{color: 'white'}}>{error}</h5>}
                    </div>
                    <br/>
                    <TextLoginService><AiOutlineWhatsApp style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                            <GlobalLargeInput type='number' ref={hostingQuestion14Ref}  placeholder={t("placewhatsapp")}/>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedHosting5Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedHosting5Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="submitproceedhosting">
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedHosting6Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={submitProceedHostingHandler}>
                                    <MdOutlineCreate style={{fontSize:'30px'}} />
                                   {t("creer")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackProceedHostingHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertproceedhosting"></div>
                <div id="backdropoutalertproceedhosting"></div>
            </LongFormScrollerWidgetSm>
        </div>
    )
}

export default ProceedHosting