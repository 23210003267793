import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../proceedweb.css';
import { useRef, useState } from "react";
//
import { MdOutlineCreate, MdSettingsBackupRestore, MdOutlineArrowForwardIos, MdOutlineArrowBackIos, MdOutlineAttachEmail } from 'react-icons/md';
import { AiOutlineWhatsApp } from 'react-icons/ai';
//
import { AlertModal, getCurrentDate, PopupModal } from "../../helperfunctions/Helper";
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
import {  
    SpanGlobalSpan,
    GlobalStrongTitle,
    LongFormScrollerWidgetSm,
    TextProceedService, 
    TextLoginService,
    GlobalLargeInput, 
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
    TextArea,
} from "../../global/Styles";
import Axios from 'axios';

function ProceedEcom() {
    const {t} = useTranslation();

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleEcomChange = event => {
        if (!isValidEmail(event.target.value)) {
          setError(t('invalidemail'));
        } else {
          setError(null);
        }
    
        setEmail(event.target.value);
    };

    const navigateProceedEcom = useNavigate();

    //alert
    const elementOutId = "modaloutproceedecom";
    const elementDropId = "backdropoutproceedecom";
    const buttonHandler = "ouiHandlerProceedEcomBtn";
    //
    const elementOutAlertId = "modaloutalertproceedecom";
    const elementDropAlertId = "backdropoutalertproceedecom";
    const buttonAlertHandler = "ouiHandleralertProceedEcomBtn";

    const ecomSiteQuestion1Ref = useRef(null);
    const ecomSiteQuestion2Ref = useRef(null);
    const ecomSiteQuestion3Ref = useRef(null);
    //
    const ecomSiteQuestion4Ref = useRef(null);
    const ecomSiteQuestion5Ref = useRef(null);
    const ecomSiteQuestion6Ref = useRef(null);
    //
    const ecomSiteQuestion7Ref = useRef(null);
    const ecomSiteQuestion8Ref = useRef(null);
    const ecomSiteQuestion9Ref = useRef(null);
    //
    const ecomSiteQuestion10Ref = useRef(null);
    const ecomSiteQuestion11Ref = useRef(null);
    const ecomSiteQuestion12Ref = useRef(null);
    //
    const ecomSiteQuestion13Ref = useRef(null);
    const ecomSiteQuestion14Ref = useRef(null);

    const popecom1Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_1");
        const pTexteNotAvailable = t("hintwebquestion_1");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popecom2Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_2");
        const pTexteNotAvailable = t("hintwebquestion_2");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popecom3Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_3");
        const pTexteNotAvailable = t("hintwebquestion_3");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const popecom4Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_4");
        const pTexteNotAvailable = t("hintwebquestion_4");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    const popecom5Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_5");
        const pTexteNotAvailable = t("hintwebquestion_5");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popecom6Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_6");
        const pTexteNotAvailable = t("hintwebquestion_6");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const popecom7Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_7");
        const pTexteNotAvailable = t("hintwebquestion_7");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popecom8Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_8");
        const pTexteNotAvailable = t("hintwebquestion_8");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popecom9Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_9");
        const pTexteNotAvailable = t("hintwebquestion_9");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const popecom10Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_10");
        const pTexteNotAvailable = t("hintwebquestion_10");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popecom11Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_11");
        const pTexteNotAvailable = t("hintwebquestion_11");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popecom12Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_12");
        const pTexteNotAvailable = t("hintwebquestion_12");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const forwardProceedEcom1Handler = async (e) => {
        e.preventDefault();

        const ecomsteponeDiv = document.getElementById('ecomstepone');
        const ecomsteptwoDiv = document.getElementById('ecomsteptwo');
        const ecomstepthreeDiv = document.getElementById('ecomstepthree');
        const ecomstepfourDiv = document.getElementById('ecomstepfour');
        const modeproceedecomDiv = document.getElementById('modeproceedecom');
        const submitproceedecomDiv = document.getElementById('submitproceedecom');
        //
        ecomsteponeDiv.style.display = 'none';
        ecomsteptwoDiv.style.display = 'block';
        ecomstepthreeDiv.style.display = 'none';
        ecomstepfourDiv.style.display = 'none';
        modeproceedecomDiv.style.display = 'none';
        submitproceedecomDiv.style.display = 'none';
    }

    const goBackProceedEcom2Handler = async (e) => {
        e.preventDefault();

        const ecomsteponeDiv = document.getElementById('ecomstepone');
        const ecomsteptwoDiv = document.getElementById('ecomsteptwo');
        const ecomstepthreeDiv = document.getElementById('ecomstepthree');
        const ecomstepfourDiv = document.getElementById('ecomstepfour');
        const modeproceedecomDiv = document.getElementById('modeproceedecom');
        const submitproceedecomDiv = document.getElementById('submitproceedecom');
        //
        ecomsteponeDiv.style.display = 'block';
        ecomsteptwoDiv.style.display = 'none';
        ecomstepthreeDiv.style.display = 'none';
        ecomstepfourDiv.style.display = 'none';
        modeproceedecomDiv.style.display = 'none';
        submitproceedecomDiv.style.display = 'none';
    }
    //
    const forwardProceedEcom2Handler = async (e) => {
        e.preventDefault();

        const ecomsteponeDiv = document.getElementById('ecomstepone');
        const ecomsteptwoDiv = document.getElementById('ecomsteptwo');
        const ecomstepthreeDiv = document.getElementById('ecomstepthree');
        const ecomstepfourDiv = document.getElementById('ecomstepfour');
        const modeproceedecomDiv = document.getElementById('modeproceedecom');
        const submitproceedecomDiv = document.getElementById('submitproceedecom');
        //
        ecomsteponeDiv.style.display = 'none';
        ecomsteptwoDiv.style.display = 'none';
        ecomstepthreeDiv.style.display = 'block';
        ecomstepfourDiv.style.display = 'none';
        modeproceedecomDiv.style.display = 'none';
        submitproceedecomDiv.style.display = 'none';
    }

    const goBackProceedEcom3Handler = async (e) => {
        e.preventDefault();

        const ecomsteponeDiv = document.getElementById('ecomstepone');
        const ecomsteptwoDiv = document.getElementById('ecomsteptwo');
        const ecomstepthreeDiv = document.getElementById('ecomstepthree');
        const ecomstepfourDiv = document.getElementById('ecomstepfour');
        const modeproceedecomDiv = document.getElementById('modeproceedecom');
        const submitproceedecomDiv = document.getElementById('submitproceedecom');
        //
        ecomsteponeDiv.style.display = 'none';
        ecomsteptwoDiv.style.display = 'block';
        ecomstepthreeDiv.style.display = 'none';
        ecomstepfourDiv.style.display = 'none';
        modeproceedecomDiv.style.display = 'none';
        submitproceedecomDiv.style.display = 'none';
    }
    //
    const forwardProceedEcom3Handler = async (e) => {
        e.preventDefault();

        const ecomsteponeDiv = document.getElementById('ecomstepone');
        const ecomsteptwoDiv = document.getElementById('ecomsteptwo');
        const ecomstepthreeDiv = document.getElementById('ecomstepthree');
        const ecomstepfourDiv = document.getElementById('ecomstepfour');
        const modeproceedecomDiv = document.getElementById('modeproceedecom');
        const submitproceedecomDiv = document.getElementById('submitproceedecom');
        //
        ecomsteponeDiv.style.display = 'none';
        ecomsteptwoDiv.style.display = 'none';
        ecomstepthreeDiv.style.display = 'none';
        ecomstepfourDiv.style.display = 'block';
        modeproceedecomDiv.style.display = 'none';
        submitproceedecomDiv.style.display = 'none';
    }

    const goBackProceedEcom4Handler = async (e) => {
        e.preventDefault();

        const ecomsteponeDiv = document.getElementById('ecomstepone');
        const ecomsteptwoDiv = document.getElementById('ecomsteptwo');
        const ecomstepthreeDiv = document.getElementById('ecomstepthree');
        const ecomstepfourDiv = document.getElementById('ecomstepfour');
        const modeproceedecomDiv = document.getElementById('modeproceedecom');
        const submitproceedecomDiv = document.getElementById('submitproceedecom');
        //
        ecomsteponeDiv.style.display = 'none';
        ecomsteptwoDiv.style.display = 'none';
        ecomstepthreeDiv.style.display = 'block';
        ecomstepfourDiv.style.display = 'none';
        modeproceedecomDiv.style.display = 'none';
        submitproceedecomDiv.style.display = 'none';
    }
    //
    const forwardProceedEcom4Handler = async (e) => {
        e.preventDefault();

        const ecomsteponeDiv = document.getElementById('ecomstepone');
        const ecomsteptwoDiv = document.getElementById('ecomsteptwo');
        const ecomstepthreeDiv = document.getElementById('ecomstepthree');
        const ecomstepfourDiv = document.getElementById('ecomstepfour');
        const modeproceedecomDiv = document.getElementById('modeproceedecom');
        const submitproceedecomDiv = document.getElementById('submitproceedecom');
        //
        ecomsteponeDiv.style.display = 'none';
        ecomsteptwoDiv.style.display = 'none';
        ecomstepthreeDiv.style.display = 'none';
        ecomstepfourDiv.style.display = 'none';
        modeproceedecomDiv.style.display = 'block';
        submitproceedecomDiv.style.display = 'none';
    }

    const goBackProceedEcom5Handler = async (e) => {
        e.preventDefault();

        const ecomsteponeDiv = document.getElementById('ecomstepone');
        const ecomsteptwoDiv = document.getElementById('ecomsteptwo');
        const ecomstepthreeDiv = document.getElementById('ecomstepthree');
        const ecomstepfourDiv = document.getElementById('ecomstepfour');
        const modeproceedecomDiv = document.getElementById('modeproceedecom');
        const submitproceedecomDiv = document.getElementById('submitproceedecom');
        //
        ecomsteponeDiv.style.display = 'none';
        ecomsteptwoDiv.style.display = 'none';
        ecomstepthreeDiv.style.display = 'none';
        ecomstepfourDiv.style.display = 'block';
        modeproceedecomDiv.style.display = 'none';
        submitproceedecomDiv.style.display = 'none';
    }
    //
    const forwardProceedEcom5Handler = async (e) => {
        e.preventDefault();

        const ecomSiteQuestion13 = ecomSiteQuestion13Ref.current.value;
        const ecomSiteQuestion14 = ecomSiteQuestion14Ref.current.value;
        //
        const ecomsteponeDiv = document.getElementById('ecomstepone');
        const ecomsteptwoDiv = document.getElementById('ecomsteptwo');
        const ecomstepthreeDiv = document.getElementById('ecomstepthree');
        const ecomstepfourDiv = document.getElementById('ecomstepfour');
        const modeproceedecomDiv = document.getElementById('modeproceedecom');
        const submitproceedecomDiv = document.getElementById('submitproceedecom');
        //
        if(!ecomSiteQuestion13){
            const pTexteSaisir = t("enteremail");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else if(!ecomSiteQuestion14){
            const pTexteSaisir = t("enterphone");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else{
            //
            ecomsteponeDiv.style.display = 'none';
            ecomsteptwoDiv.style.display = 'none';
            ecomstepthreeDiv.style.display = 'none';
            ecomstepfourDiv.style.display = 'none';
            modeproceedecomDiv.style.display = 'none';
            submitproceedecomDiv.style.display = 'block';
        }
    }

    const goBackProceedEcom6Handler = async (e) => {
        e.preventDefault();

        const ecomsteponeDiv = document.getElementById('ecomstepone');
        const ecomsteptwoDiv = document.getElementById('ecomsteptwo');
        const ecomstepthreeDiv = document.getElementById('ecomstepthree');
        const ecomstepfourDiv = document.getElementById('ecomstepfour');
        const modeproceedecomDiv = document.getElementById('modeproceedecom');
        const submitproceedecomDiv = document.getElementById('submitproceedecom');
        //
        ecomsteponeDiv.style.display = 'none';
        ecomsteptwoDiv.style.display = 'none';
        ecomstepthreeDiv.style.display = 'none';
        ecomstepfourDiv.style.display = 'none';
        modeproceedecomDiv.style.display = 'block';
        submitproceedecomDiv.style.display = 'none';
    }

    //http://localhost:5000/check/ecomservice

    const submitProceedEcomHandler = async (e) => {
        e.preventDefault();

        const ecomSiteQuestion1 = ecomSiteQuestion1Ref.current.value;
        const ecomSiteQuestion2 = ecomSiteQuestion2Ref.current.value;
        const ecomSiteQuestion3 = ecomSiteQuestion3Ref.current.value;
        //websteptwo
        const ecomSiteQuestion4 = ecomSiteQuestion4Ref.current.value;
        const ecomSiteQuestion5 = ecomSiteQuestion5Ref.current.value;
        const ecomSiteQuestion6 = ecomSiteQuestion6Ref.current.value;
        //webstepthree
        const ecomSiteQuestion7 = ecomSiteQuestion7Ref.current.value;
        const ecomSiteQuestion8 = ecomSiteQuestion8Ref.current.value;
        const ecomSiteQuestion9 = ecomSiteQuestion9Ref.current.value;
        //webstepfour
        const ecomSiteQuestion10 = ecomSiteQuestion10Ref.current.value;
        const ecomSiteQuestion11 = ecomSiteQuestion11Ref.current.value;
        const ecomSiteQuestion12 = ecomSiteQuestion12Ref.current.value;
        //modeproceedweb
        const ecomSiteQuestion13 = ecomSiteQuestion13Ref.current.value;
        const ecomSiteQuestion14 = ecomSiteQuestion14Ref.current.value;
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const theDate = getCurrentDate();
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = "ecommerce";
        const servicePlan = document.getElementById('typeplan').innerText;
        var createTicketCode = "";
        //
        var resultat1 = "";
        
        //generate random ticket code
        var randomNumber = Math.floor((Math.random() * 100000) + 1);
        createTicketCode = randomNumber+''+codeutilisateurDiv;
        //
        await Axios.post("https://micarionsapi.carions.net/check/ecomservice", {
            codeutilisateurDiv:codeutilisateurDiv,
            typeService:typeService,
            servicePlan:servicePlan,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                //insert
                Axios.post("https://micarionsapi.carions.net/quest/ecomservice", {
                    choixlangueDiv:choixlangueDiv,
                    usernamedetailDiv:usernamedetailDiv,
                    codeutilisateurDiv:codeutilisateurDiv,
                    //
                    typeService:typeService,
                    servicePlan:servicePlan,
                    //
                    createTicketCode:createTicketCode,
                    //
                    ecomSiteQuestion1:ecomSiteQuestion1,
                    ecomSiteQuestion2:ecomSiteQuestion2,
                    ecomSiteQuestion3:ecomSiteQuestion3,
                    //
                    ecomSiteQuestion4:ecomSiteQuestion4,
                    ecomSiteQuestion5:ecomSiteQuestion5,
                    ecomSiteQuestion6:ecomSiteQuestion6,
                    //
                    ecomSiteQuestion7:ecomSiteQuestion7,
                    ecomSiteQuestion8:ecomSiteQuestion8,
                    ecomSiteQuestion9:ecomSiteQuestion9,
                    //
                    ecomSiteQuestion10:ecomSiteQuestion10,
                    ecomSiteQuestion11:ecomSiteQuestion11,
                    ecomSiteQuestion12:ecomSiteQuestion12,
                    //
                    ecomSiteQuestion13:ecomSiteQuestion13,
                    ecomSiteQuestion14:ecomSiteQuestion14,
                    //
                    theDate:theDate,
                }).then(() => {
                    var answer1 = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                    const hTitleTexte = t("proceedwebapptitle");
                    const pTexteNotAvailable = answer1;
                    PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
                });
            }else{
                //set up modal to update
                var answer = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                console.log(resultat1);
                const hTitleTexte = t("proceedwebapptitle");
                const pTexteNotAvailable = answer;
                PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
            }
        });
    }

    const goBackProceedEcomHandler = async (e) => {
        e.preventDefault();

        navigateProceedEcom('/ecommercetab')
    }

    return ( 
        <div id="proceedecom">
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t('proceedecomtitle')}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <LongFormScrollerWidgetSm>
                <div id="modaloutproceedecom"></div>
                <div id="backdropoutproceedecom"></div>
                <p className="pservicestyles"><br/>{t("proceedwebdescription")}</p>
                <div id="ecomstepone">
                    <TextProceedService onClick={popecom1Handler}>{t("webquestion_1")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={ecomSiteQuestion1Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popecom2Handler}>{t("webquestion_2")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={ecomSiteQuestion2Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popecom3Handler}>{t("webquestion_3")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={ecomSiteQuestion3Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <ButtonStart onClick={forwardProceedEcom1Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="ecomsteptwo">
                   <TextProceedService onClick={popecom4Handler}>{t("webquestion_4")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={ecomSiteQuestion4Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popecom5Handler}>{t("webquestion_5")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={ecomSiteQuestion5Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popecom6Handler}>{t("webquestion_6")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={ecomSiteQuestion6Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedEcom2Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedEcom2Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="ecomstepthree">
                   <TextProceedService onClick={popecom7Handler}>{t("webquestion_7")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={ecomSiteQuestion7Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popecom8Handler}>{t("webquestion_8")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={ecomSiteQuestion8Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popecom9Handler}>{t("webquestion_9")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={ecomSiteQuestion9Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedEcom3Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedEcom3Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="ecomstepfour">
                    <TextProceedService onClick={popecom10Handler}>{t("webquestion_10")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={ecomSiteQuestion10Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popecom11Handler}>{t("webquestion_11")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={ecomSiteQuestion11Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popecom12Handler}>{t("webquestion_12")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={ecomSiteQuestion12Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedEcom4Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedEcom4Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="modeproceedecom">
                    <TextProceedService>{t("webquestion_13")}</TextProceedService>
                    <TextLoginService><MdOutlineAttachEmail style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput id="emailecom" name="emailecom" value={email} onChange={handleEcomChange} ref={ecomSiteQuestion13Ref} placeholder={t("placemail")}/>
                        {error && <h5 style={{color: 'white'}}>{error}</h5>}
                    </div>
                    <br/>
                    <TextLoginService><AiOutlineWhatsApp style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                            <GlobalLargeInput type='number' ref={ecomSiteQuestion14Ref}  placeholder={t("placewhatsapp")}/>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedEcom5Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedEcom5Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="submitproceedecom">
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedEcom6Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={submitProceedEcomHandler}>
                                    <MdOutlineCreate style={{fontSize:'30px'}} />
                                   {t("creer")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackProceedEcomHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertproceedecom"></div>
                <div id="backdropoutalertproceedecom"></div>
            </LongFormScrollerWidgetSm>
        </div>
    );
}

export default ProceedEcom;