import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../components.css';
//
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';

import {  
    GlobalStrongTitle,
    SpanGlobalSpan,
    DivScrollerWidgetSm,
    BackStart,
    HandlerContainerDiv,
} from "../../global/Styles";

import { MdSettingsBackupRestore, MdSendToMobile, MdOutlineAttachEmail } from 'react-icons/md';
import { AiOutlineWhatsApp } from 'react-icons/ai';

function ContactMe() {
    const {t} = useTranslation();

    const navigateContactMe = useNavigate();

    const goBackContactMeHandler = async (e) => {
        e.preventDefault();

        navigateContactMe(-1);
    }

    return ( 
        <div style={{marginTop:'-2rem'}} id="contactme">
            <br/>
            <div>
                <SpanGlobalSpan style={{textAlign:'center'}}>
                    <GlobalStrongTitle>{t("contact_me")}</GlobalStrongTitle>
                </SpanGlobalSpan>
            </div>
            <br/>
            <div style={{marginTop:'-4rem'}}>
                <DivScrollerWidgetSm>
                    <div className="pservicestyles">
                        <br/>
                        {t("contactintro")}
                        <br/>
                        <ul>
                            <li>{t("contact_1")}</li>
                            <li>{t("contact_2")}</li>
                            <li>{t("contact_3")}</li>
                        </ul>
                        {t("contactconclued_1")}
                        <br/>
                        {t("contactconclued_2")}
                    </div>
                    <br/>
                    <div className="connectaccountstyle">
                        <div className="contactcontainer">
                            <a href='tel:+447438810727'><MdSendToMobile style={{fontSize:'40px', color:'white', cursor:'pointer'}} /></a>
                            <a href='mailto:micarions2023@gmail.com.com'><MdOutlineAttachEmail style={{fontSize:'40px', color:'white', cursor:'pointer'}} /></a>
                            <a href='https://api.whatsapp.com/send?phone=++447438810727'><AiOutlineWhatsApp style={{fontSize:'40px', color:'white', cursor:'pointer'}} /></a>
                        </div>
                        
                        <div>
                            <HandlerContainerDiv>
                                <div>
                                    <BackStart onClick={goBackContactMeHandler}>
                                        <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                        {t("retour")}
                                    </BackStart>
                                </div>
                            </HandlerContainerDiv>
                            <br/>
                        </div>
                    </div>
                </DivScrollerWidgetSm>
                
            </div>
        </div>
    );
}

export default ContactMe;