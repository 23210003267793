import React from "react-dom";
import { useNavigate } from "react-router-dom";
//build tabs tools
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
//
import { AlertModal } from '../../../helperfunctions/Helper';
//
import '../services.css';
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import { HiOutlineClock } from 'react-icons/hi';
import { TbRefresh } from 'react-icons/tb';
//
import {
    SpanGlobalSpan,
    GlobalStrongTitle,
    TabsForm, 
    DivTabRowAround,
    DivTabRowBetween,
    PTab10,
    DivTabColumnAround,
    PTab10Bt0,
    BackStart,  
    HandlerContainerDiv,
} from '../../../global/Styles';

import { useState } from "react";
import Axios from 'axios';

function SoftMiscellaniousServicesTab() {
    const {t} = useTranslation();

    const navigateSoftMiscTab = useNavigate();

    //alert
    const elementOutId = "modaloutalertsoftmiscellanioustab";
    const elementDropId = "backdropoutalertsoftmiscellanioustab";
    const buttonHandler = "ouiHandlerAlertSoftMiscellaniousTabBtn";

    const [softMiscellaniousTabBasic, getSoftMiscellaniousTabBasic] = useState([0]);

    var resultatappbasic = "";

    //http://localhost:5000
    //https://micarionsapi.carions.net

    const softMiscellaniousBasicHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'basic';
        //show the tab form paragraphs and panel
        const softmiscelparagraphDiv = document.getElementById('softmiscelparagraph');
        const softmiscelpanelDiv = document.getElementById('softmiscelpanel');
        //
        await Axios.post("https://micarionsapi.carions.net/seo/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getSoftMiscellaniousTabBasic(responseappbasic.data);
                //
                softmiscelparagraphDiv.style.display = 'block';
                softmiscelpanelDiv.style.display = 'block';
            }
        });
    }

    const softMiscellaniousStandardHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'standard';
        //show the tab form paragraphs and panel
        const softmiscelparagraphDiv = document.getElementById('softmiscelparagraph');
        const softmiscelpanelDiv = document.getElementById('softmiscelpanel');
        //
        await Axios.post("https://micarionsapi.carions.net/seo/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getSoftMiscellaniousTabBasic(responseappbasic.data);
                //
                softmiscelparagraphDiv.style.display = 'block';
                softmiscelpanelDiv.style.display = 'block';
            }
        });
    }

    const softMiscellaniousPremiumHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'premium';
        //show the tab form paragraphs and panel
        const softmiscelparagraphDiv = document.getElementById('softmiscelparagraph');
        const softmiscelpanelDiv = document.getElementById('softmiscelpanel');
        //
        await Axios.post("https://micarionsapi.carions.net/seo/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getSoftMiscellaniousTabBasic(responseappbasic.data);
                //
                softmiscelparagraphDiv.style.display = 'block';
                softmiscelpanelDiv.style.display = 'block';
            }
        }); 
    }

    //softmaintenanceservicestab proceed processes
    const miscbasicHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'basic';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateSoftMiscTab('/connectaccount');
            }else{
                navigateSoftMiscTab('/proceedmiscel');
            }
        }
    }
    //
    const miscstandardHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'standard';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateSoftMiscTab('/connectaccount');
            }else{
                navigateSoftMiscTab('/proceedmiscel');
            }
        }
    }
    //
    const miscpremiumHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'premium';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateSoftMiscTab('/connectaccount');
            }else{
                navigateSoftMiscTab('/proceedmiscel');
            }
        }
    }

    //softmaintenanceservicestab contact
    const misccontactHandler = async (e) => {
        e.preventDefault();

        navigateSoftMiscTab('/contactme');
    }

    const goBackSoftMiscellaniousTabHandler = async (e) => {
        e.preventDefault();

        navigateSoftMiscTab('/mainsoftwareservices');
    }

    return ( 
        <div id="softmiscellaniousservicestab">
            <br/>
            <div style={{marginTop:'-2rem'}}>
                <SpanGlobalSpan style={{textAlign:'center'}}>
                    <GlobalStrongTitle>{t("software_miscellanious")}</GlobalStrongTitle>
                </SpanGlobalSpan>
            </div>
            <br/>
            <TabsForm>
                {softMiscellaniousTabBasic.map((valmiscelbasic, indexmiscelbasic) => (
                    <div key={indexmiscelbasic} className="tabstyles">
                        <div id="softmiscelparagraph">
                            <PTab10>
                                {valmiscelbasic.services_paragraphone}<br/>
                                {valmiscelbasic.services_paragraphtwo}<br/>
                                {valmiscelbasic.services_paragraphthree}<br/>
                                {valmiscelbasic.services_paragraphfour}<br/>
                            </PTab10>
                        </div>
                        <Tabs defaultIndex={0}>
                            <TabList>
                                <Tab onClick={softMiscellaniousBasicHandler}>{t("basic_plan")}</Tab>
                                <Tab onClick={softMiscellaniousStandardHandler}>Standard</Tab>
                                <Tab onClick={softMiscellaniousPremiumHandler}>Premium</Tab>
                            </TabList>
                            <div id="softmiscelpanel">
                                <TabPanel >
                                    <DivTabRowBetween>
                                        <h3>{valmiscelbasic.services_titre}</h3><h3>{valmiscelbasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valmiscelbasic.services_description} 
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valmiscelbasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valmiscelbasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmiscelbasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmiscelbasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmiscelbasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmiscelbasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmiscelbasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={miscbasicHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={misccontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                                <TabPanel>
                                    <DivTabRowBetween>
                                        <h3>{valmiscelbasic.services_titre}</h3><h3>{valmiscelbasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valmiscelbasic.services_description}
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valmiscelbasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valmiscelbasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmiscelbasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmiscelbasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmiscelbasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmiscelbasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmiscelbasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={miscstandardHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={misccontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                                <TabPanel>
                                    <DivTabRowBetween>
                                        <h3>{valmiscelbasic.services_titre}</h3><h3>{valmiscelbasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valmiscelbasic.services_description}
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valmiscelbasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valmiscelbasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmiscelbasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmiscelbasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmiscelbasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmiscelbasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmiscelbasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={miscpremiumHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={misccontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                ))}
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackSoftMiscellaniousTabHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertsoftmiscellanioustab"></div>
                <div id="backdropoutalertsoftmiscellanioustab"></div>
            </TabsForm>
        </div>
    );
}

export default SoftMiscellaniousServicesTab;