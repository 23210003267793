import React from "react-dom";
import '../components.css';
//import myeditor
import MyEditor from "../reactquilleditor/MyEditor";
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,
} from '../../global/Styles';

function AccueilCreateContenu() {
    const {t} = useTranslation();

    return ( 
        <DivContainerSlide id="accueilcreatecontenu">
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t("crudcreercontenu")} {t("accueil")}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <div className="lescontenus">
                <MyEditor />
                <br/>
            </div>
        </DivContainerSlide>
    );
}

export default AccueilCreateContenu;