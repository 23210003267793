import React from "react-dom";
import '../components.css';
//import myeditor
import ViewMyEditor from "../reactquilleditor/ViewMyEditor";
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle, 
} from '../../global/Styles';

function AccueilViewContenu() {
    const {t} = useTranslation();

    return ( 
        <DivContainerSlide id="accueilviewcontenu">
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t("crudcontenuVisualiser")} {t("accueil")}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <div className="lescontenus">
                <ViewMyEditor />
                <br/>
            </div>
        </DivContainerSlide>
    );
}

export default AccueilViewContenu;