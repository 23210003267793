import React from 'react';
import '../../components/components.css';
//
import '../../components/reactquilleditor/myeditor.css';
//import edit contract editor
import DeleteContractEditor from './DeleteContractEditor';
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,  
} from '../../global/Styles';

function DeleteContract() {
    const {t} = useTranslation();

    return (
        <DivContainerSlide>
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t("crudcontenusupprimer")}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <div className="lescontenus">
                <DeleteContractEditor />
                <br/>
            </div>
        </DivContainerSlide>
    )
}

export default DeleteContract