import React from 'react';
import { useNavigate } from "react-router-dom";
import './accueil.css';
//
import { useTranslation } from 'react-i18next';
import '../translations/i18n';
import '../translations/Translation';
//
import { MdOutlineCreate, MdSettingsBackupRestore,} from 'react-icons/md';
import { useRef} from "react";
import Axios from 'axios';
//
import { AlertModal, getCurrentDate } from "../helperfunctions/Helper";
//
import {
    DivContainerSlide,  
    GlobalStrongTitle,
    LargeSelect,
    TextProceedService,
    TextAreaView,
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
    SpanGlobalSpan,
    GlobalLargeInput,
} from '../global/Styles';

function CommentCarions() {
    const {t} = useTranslation();

    const navigateCommentCarions = useNavigate();

    //alert
    const elementOutId = "modaloutalertcomment";
    const elementDropId = "backdropoutalertcomment";
    const buttonHandler = "ouiHandlerAlertEditorContractBtn";

    const createSelectEvaluateRef = useRef(null);
    const createCommentRef = useRef(null);
    const createNomRef = useRef(null);

    //http://localhost:5000/comment/submit
    //https://micarionsapi.carions.net/comment/submit

    const submitCommentHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        //
        const createSelectEvaluate = createSelectEvaluateRef.current.value;
        const createComment = createCommentRef.current.value;
        const createNom = createNomRef.current.value;
        const commentStatus = "online";
        const dateComment = getCurrentDate();
        //
        if(!createSelectEvaluate){
            const pTexteSaisir = t("comment_select");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createComment){
            const pTexteSaisir = t("comment_why");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createNom){
            const pTexteSaisir = t("comment_name");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else{
            await Axios.post("https://micarionsapi.carions.net/comment/submit", {
                choixlangueDiv:choixlangueDiv,
                createSelectEvaluate:createSelectEvaluate,
                createComment:createComment,
                createNom:createNom,
                commentStatus:commentStatus,
                dateComment:dateComment,
            }).then(() => {
                navigateCommentCarions('/');
            });
        }
    }

    const goBackCommentHandler = async (e) => {
        e.preventDefault();

        navigateCommentCarions('/');
    }

    return (
        <DivContainerSlide id='commentcarions'>
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t("comment_title")}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <div>
                <div className='sharecontent'>
                  <p className="pshareservicestyles" >{t("comment_dear")}</p>
                  <p className="pshareservicestyles">{t("comment_description")}</p> 
                </div>
                <TextProceedService>{t("comment_select")}<strong style={{color:'red'}}>*</strong></TextProceedService>
                <div style={{display:'block',textAlign:'center'}}>
                    <LargeSelect ref={createSelectEvaluateRef}>
                        <option value=''>{t("select_star")}</option>
                        <option value='1'>⭐</option>
                        <option value='2'>⭐⭐</option>
                        <option value='3'>⭐⭐⭐</option>
                        <option value='4'>⭐⭐⭐⭐</option>
                        <option value='5'>⭐⭐⭐⭐⭐</option>
                    </LargeSelect>
                </div>
                <br/>
                <TextProceedService>{t("comment_why")}<strong style={{color:'red'}}>*</strong></TextProceedService>
                <div style={{display:'block',textAlign:'center'}}>
                    <TextAreaView ref={createCommentRef}></TextAreaView>
                </div>
                <br/>
                <TextProceedService>{t("comment_name")}<strong style={{color:'red'}}>*</strong></TextProceedService>
                <div style={{display:'block',textAlign:'center'}}>
                    <GlobalLargeInput type='text' ref={createNomRef} placeholder={t("comment_place")}/>
                </div>
                <br/>
                <HandlerContainerDiv>
                    <div>
                        <ButtonStart onClick={submitCommentHandler}>
                            <MdOutlineCreate style={{fontSize:'30px'}} />
                            {t("creer_comment")}
                        </ButtonStart>
                    </div>
                    <div>
                        <BackStart onClick={goBackCommentHandler}>
                            <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                            {t("retour")}
                        </BackStart>
                    </div>
                </HandlerContainerDiv>
                <br/><br/>
                <div id="modaloutalertcomment"></div>
                <div id="backdropoutalertcomment"></div>
            </div>
            <br/><br/>
        </DivContainerSlide>
    )
}

export default CommentCarions