import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../proceedweb.css';
import { useRef, useState } from "react";
//
import { MdOutlineCreate, MdSettingsBackupRestore, MdOutlineArrowForwardIos, MdOutlineArrowBackIos, MdOutlineAttachEmail } from 'react-icons/md';
import { AiOutlineWhatsApp } from 'react-icons/ai';
//
import { AlertModal, getCurrentDate, PopupModal } from "../../helperfunctions/Helper";
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
import {  
    SpanGlobalSpan,
    GlobalStrongTitle,
    LongFormScrollerWidgetSm,
    TextProceedService, 
    TextLoginService,
    GlobalLargeInput, 
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
    TextArea,
} from "../../global/Styles";

import Axios from 'axios';

function ProceedDesign() {
    const {t} = useTranslation();

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleDesignChange = event => {
        if (!isValidEmail(event.target.value)) {
          setError(t('invalidemail'));
        } else {
          setError(null);
        }
    
        setEmail(event.target.value);
    };

    const navigateProceedDesign = useNavigate();

    //alert
    const elementOutId = "modaloutproceeddesign";
    const elementDropId = "backdropoutproceeddesign";
    const buttonHandler = "ouiHandlerProceedDesignBtn";
    //
    const elementOutAlertId = "modaloutalertproceeddesign";
    const elementDropAlertId = "backdropoutalertproceeddesign";
    const buttonAlertHandler = "ouiHandleralertProceedDesignBtn";

    const designQuestion1Ref = useRef(null);
    const designQuestion2Ref = useRef(null);
    const designQuestion3Ref = useRef(null);
    //
    const designQuestion4Ref = useRef(null);
    const designQuestion5Ref = useRef(null);
    const designQuestion6Ref = useRef(null);
    //
    const designQuestion7Ref = useRef(null);
    const designQuestion8Ref = useRef(null);
    const designQuestion9Ref = useRef(null);
    //
    const designQuestion10Ref = useRef(null);
    const designQuestion11Ref = useRef(null);
    const designQuestion12Ref = useRef(null);
    //
    const designQuestion13Ref = useRef(null);
    const designQuestion14Ref = useRef(null);

    const popdesign1Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_1");
        const pTexteNotAvailable = t("hintdesignquestion_1");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popdesign2Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_2");
        const pTexteNotAvailable = t("hintdesignquestion_2");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popdesign3Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_3");
        const pTexteNotAvailable = t("hintdesignquestion_3");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //** */
    const forwardProceedDesign1Handler = async (e) => {
        e.preventDefault();

        const designsteponeDiv = document.getElementById('designstepone');
        const designsteptwoDiv = document.getElementById('designsteptwo');
        const designstepthreeDiv = document.getElementById('designstepthree');
        const designstepfourDiv = document.getElementById('designstepfour');
        const modeproceeddesignDiv = document.getElementById('modeproceeddesign');
        const submitproceeddesignDiv = document.getElementById('submitproceeddesign');
        //
        designsteponeDiv.style.display = 'none';
        designsteptwoDiv.style.display = 'block';
        designstepthreeDiv.style.display = 'none';
        designstepfourDiv.style.display = 'none';
        modeproceeddesignDiv.style.display = 'none';
        submitproceeddesignDiv.style.display = 'none';
    }

    const popdesign4Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_4");
        const pTexteNotAvailable = t("hintdesignquestion_4");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popdesign5Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_5");
        const pTexteNotAvailable = t("hintdesignquestion_5");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popdesign6Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_6");
        const pTexteNotAvailable = t("hintdesignquestion_6");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    /** */
    const goBackProceedDesign2Handler = async (e) => {
        e.preventDefault();

        const designsteponeDiv = document.getElementById('designstepone');
        const designsteptwoDiv = document.getElementById('designsteptwo');
        const designstepthreeDiv = document.getElementById('designstepthree');
        const designstepfourDiv = document.getElementById('designstepfour');
        const modeproceeddesignDiv = document.getElementById('modeproceeddesign');
        const submitproceeddesignDiv = document.getElementById('submitproceeddesign');
        //
        designsteponeDiv.style.display = 'block';
        designsteptwoDiv.style.display = 'none';
        designstepthreeDiv.style.display = '';
        designstepfourDiv.style.display = 'none';
        modeproceeddesignDiv.style.display = 'none';
        submitproceeddesignDiv.style.display = 'none';
    }
    //
    const forwardProceedDesign2Handler = async (e) => {
        e.preventDefault();

        const designsteponeDiv = document.getElementById('designstepone');
        const designsteptwoDiv = document.getElementById('designsteptwo');
        const designstepthreeDiv = document.getElementById('designstepthree');
        const designstepfourDiv = document.getElementById('designstepfour');
        const modeproceeddesignDiv = document.getElementById('modeproceeddesign');
        const submitproceeddesignDiv = document.getElementById('submitproceeddesign');
        //
        designsteponeDiv.style.display = 'none';
        designsteptwoDiv.style.display = 'none';
        designstepthreeDiv.style.display = 'block';
        designstepfourDiv.style.display = 'none';
        modeproceeddesignDiv.style.display = 'none';
        submitproceeddesignDiv.style.display = 'none';
    }

    const popdesign7Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_7");
        const pTexteNotAvailable = t("hintdesignquestion_7");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popdesign8Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_8");
        const pTexteNotAvailable = t("hintdesignquestion_8");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popdesign9Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_9");
        const pTexteNotAvailable = t("hintdesignquestion_9");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    /** */
    const goBackProceedDesign3Handler = async (e) => {
        e.preventDefault();

        const designsteponeDiv = document.getElementById('designstepone');
        const designsteptwoDiv = document.getElementById('designsteptwo');
        const designstepthreeDiv = document.getElementById('designstepthree');
        const designstepfourDiv = document.getElementById('designstepfour');
        const modeproceeddesignDiv = document.getElementById('modeproceeddesign');
        const submitproceeddesignDiv = document.getElementById('submitproceeddesign');
        //
        designsteponeDiv.style.display = 'none';
        designsteptwoDiv.style.display = 'block';
        designstepthreeDiv.style.display = 'none';
        designstepfourDiv.style.display = 'none';
        modeproceeddesignDiv.style.display = 'none';
        submitproceeddesignDiv.style.display = 'none';
    }
    //
    const forwardProceedDesign3Handler = async (e) => {
        e.preventDefault();

        const designsteponeDiv = document.getElementById('designstepone');
        const designsteptwoDiv = document.getElementById('designsteptwo');
        const designstepthreeDiv = document.getElementById('designstepthree');
        const designstepfourDiv = document.getElementById('designstepfour');
        const modeproceeddesignDiv = document.getElementById('modeproceeddesign');
        const submitproceeddesignDiv = document.getElementById('submitproceeddesign');
        //
        designsteponeDiv.style.display = 'none';
        designsteptwoDiv.style.display = 'none';
        designstepthreeDiv.style.display = 'none';
        designstepfourDiv.style.display = 'block';
        modeproceeddesignDiv.style.display = 'none';
        submitproceeddesignDiv.style.display = 'none';
    }

    const popdesign10Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_10");
        const pTexteNotAvailable = t("hintdesignquestion_10");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popdesign11Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_11");
        const pTexteNotAvailable = t("hintdesignquestion_11");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popdesign12Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_12");
        const pTexteNotAvailable = t("hintdesignquestion_12");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    /** */
    const goBackProceedDesign4Handler = async (e) => {
        e.preventDefault();

        const designsteponeDiv = document.getElementById('designstepone');
        const designsteptwoDiv = document.getElementById('designsteptwo');
        const designstepthreeDiv = document.getElementById('designstepthree');
        const designstepfourDiv = document.getElementById('designstepfour');
        const modeproceeddesignDiv = document.getElementById('modeproceeddesign');
        const submitproceeddesignDiv = document.getElementById('submitproceeddesign');
        //
        designsteponeDiv.style.display = 'none';
        designsteptwoDiv.style.display = 'none';
        designstepthreeDiv.style.display = 'block';
        designstepfourDiv.style.display = 'none';
        modeproceeddesignDiv.style.display = 'none';
        submitproceeddesignDiv.style.display = 'none';
    }
    //
    const forwardProceedDesign4Handler = async (e) => {
        e.preventDefault();

        const designsteponeDiv = document.getElementById('designstepone');
        const designsteptwoDiv = document.getElementById('designsteptwo');
        const designstepthreeDiv = document.getElementById('designstepthree');
        const designstepfourDiv = document.getElementById('designstepfour');
        const modeproceeddesignDiv = document.getElementById('modeproceeddesign');
        const submitproceeddesignDiv = document.getElementById('submitproceeddesign');
        //
        designsteponeDiv.style.display = 'none';
        designsteptwoDiv.style.display = 'none';
        designstepthreeDiv.style.display = 'none';
        designstepfourDiv.style.display = 'none';
        modeproceeddesignDiv.style.display = 'block';
        submitproceeddesignDiv.style.display = 'none';
    }

    const goBackProceedDesign5Handler = async (e) => {
        e.preventDefault();

        const designsteponeDiv = document.getElementById('designstepone');
        const designsteptwoDiv = document.getElementById('designsteptwo');
        const designstepthreeDiv = document.getElementById('designstepthree');
        const designstepfourDiv = document.getElementById('designstepfour');
        const modeproceeddesignDiv = document.getElementById('modeproceeddesign');
        const submitproceeddesignDiv = document.getElementById('submitproceeddesign');
        //
        designsteponeDiv.style.display = 'none';
        designsteptwoDiv.style.display = 'none';
        designstepthreeDiv.style.display = 'none';
        designstepfourDiv.style.display = 'block';
        modeproceeddesignDiv.style.display = 'none';
        submitproceeddesignDiv.style.display = 'none';
    }
    //
    const forwardProceedDesign5Handler = async (e) => {
        e.preventDefault();

        const designQuestion13 = designQuestion13Ref.current.value;
        const designQuestion14 = designQuestion14Ref.current.value;
        //
        const designsteponeDiv = document.getElementById('designstepone');
        const designsteptwoDiv = document.getElementById('designsteptwo');
        const designstepthreeDiv = document.getElementById('designstepthree');
        const designstepfourDiv = document.getElementById('designstepfour');
        const modeproceeddesignDiv = document.getElementById('modeproceeddesign');
        const submitproceeddesignDiv = document.getElementById('submitproceeddesign');
        //
        if(!designQuestion13){
            const pTexteSaisir = t("enteremail");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else if(!designQuestion14){
            const pTexteSaisir = t("enterphone");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else{
            //
            designsteponeDiv.style.display = 'none';
            designsteptwoDiv.style.display = 'none';
            designstepthreeDiv.style.display = 'none';
            designstepfourDiv.style.display = 'none';
            modeproceeddesignDiv.style.display = 'none';
            submitproceeddesignDiv.style.display = 'block';
        }
    }

    const goBackProceedDesign6Handler = async (e) => {
        e.preventDefault();

        const designsteponeDiv = document.getElementById('designstepone');
        const designsteptwoDiv = document.getElementById('designsteptwo');
        const designstepthreeDiv = document.getElementById('designstepthree');
        const designstepfourDiv = document.getElementById('designstepfour');
        const modeproceeddesignDiv = document.getElementById('modeproceeddesign');
        const submitproceeddesignDiv = document.getElementById('submitproceeddesign');
        //
        designsteponeDiv.style.display = 'none';
        designsteptwoDiv.style.display = 'none';
        designstepthreeDiv.style.display = 'none';
        designstepfourDiv.style.display = 'none';
        modeproceeddesignDiv.style.display = 'block';
        submitproceeddesignDiv.style.display = 'none';
    }

    //http://localhost:5000/check/designservice

    const submitProceedDesignHandler = async (e) => {
        e.preventDefault();

        const designQuestion1 = designQuestion1Ref.current.value;
        const designQuestion2 = designQuestion2Ref.current.value;
        const designQuestion3 = designQuestion3Ref.current.value;
        //websteptwo
        const designQuestion4 = designQuestion4Ref.current.value;
        const designQuestion5 = designQuestion5Ref.current.value;
        const designQuestion6 = designQuestion6Ref.current.value;
        //webstepthree
        const designQuestion7 = designQuestion7Ref.current.value;
        const designQuestion8 = designQuestion8Ref.current.value;
        const designQuestion9 = designQuestion9Ref.current.value;
        //webstepfour
        const designQuestion10 = designQuestion10Ref.current.value;
        const designQuestion11 = designQuestion11Ref.current.value;
        const designQuestion12 = designQuestion12Ref.current.value;
        //modeproceedweb
        const designQuestion13 = designQuestion13Ref.current.value;
        const designQuestion14 = designQuestion14Ref.current.value;
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const theDate = getCurrentDate();
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = "design";
        const servicePlan = document.getElementById('typeplan').innerText;
        var createTicketCode = "";
        //
        var resultat1 = "";
        //
        //generate random ticket code
        var randomNumber = Math.floor((Math.random() * 100000) + 1);
        createTicketCode = randomNumber+''+codeutilisateurDiv;
        //
        await Axios.post("https://micarionsapi.carions.net/check/designservice", {
            codeutilisateurDiv:codeutilisateurDiv,
            typeService:typeService,
            servicePlan:servicePlan,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                //insert
                Axios.post("https://micarionsapi.carions.net/quest/designservice", {
                    choixlangueDiv:choixlangueDiv,
                    usernamedetailDiv:usernamedetailDiv,
                    codeutilisateurDiv:codeutilisateurDiv,
                    //
                    typeService:typeService,
                    servicePlan:servicePlan,
                    //
                    createTicketCode:createTicketCode,
                    //
                    designQuestion1:designQuestion1,
                    designQuestion2:designQuestion2,
                    designQuestion3:designQuestion3,
                    //
                    designQuestion4:designQuestion4,
                    designQuestion5:designQuestion5,
                    designQuestion6:designQuestion6,
                    //
                    designQuestion7:designQuestion7,
                    designQuestion8:designQuestion8,
                    designQuestion9:designQuestion9,
                    //
                    designQuestion10:designQuestion10,
                    designQuestion11:designQuestion11,
                    designQuestion12:designQuestion12,
                    //
                    designQuestion13:designQuestion13,
                    designQuestion14:designQuestion14,
                    //
                    theDate:theDate,
                }).then(() => {
                    var answer1 = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                    const hTitleTexte = t("proceedcodingtitle");
                    const pTexteNotAvailable = answer1;
                    PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
                });
            }else{
                //set up modal to update
                var answer = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                const hTitleTexte = t("proceedcodingtitle");
                const pTexteNotAvailable = answer;
                PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
            }
        });
    }

    const goBackProceedDesignHandler = async (e) => {
        e.preventDefault();

        navigateProceedDesign('/softdesignservicestab');
    }

    return ( 
        <div id="proceeddesign">
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t('software_design')}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <LongFormScrollerWidgetSm>
                <div id="modaloutproceeddesign"></div>
                <div id="backdropoutproceeddesign"></div>
                <p className="pservicestyles"><br/>{t("proceedcodingdescription")}</p>
                <div id="designstepone">
                    <TextProceedService onClick={popdesign1Handler}>{t("designquestion_1")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={designQuestion1Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popdesign2Handler}>{t("designquestion_2")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={designQuestion2Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popdesign3Handler}>{t("designquestion_3")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={designQuestion3Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <ButtonStart onClick={forwardProceedDesign1Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="designsteptwo">
                    <TextProceedService onClick={popdesign4Handler}>{t("designquestion_4")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={designQuestion4Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popdesign5Handler}>{t("designquestion_5")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={designQuestion5Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popdesign6Handler}>{t("designquestion_6")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={designQuestion6Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedDesign2Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedDesign2Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="designstepthree">
                    <TextProceedService onClick={popdesign7Handler}>{t("designquestion_7")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={designQuestion7Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popdesign8Handler}>{t("designquestion_8")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={designQuestion8Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popdesign9Handler}>{t("designquestion_9")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={designQuestion9Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedDesign3Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedDesign3Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="designstepfour">
                    <TextProceedService onClick={popdesign10Handler}>{t("designquestion_10")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={designQuestion10Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popdesign11Handler}>{t("designquestion_11")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={designQuestion11Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popdesign12Handler}>{t("designquestion_12")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={designQuestion12Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedDesign4Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedDesign4Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="modeproceeddesign">
                    <TextProceedService>{t("webquestion_13")}</TextProceedService>
                    <TextLoginService><MdOutlineAttachEmail style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput id="emaildesign" name="emaildesign" value={email} onChange={handleDesignChange} ref={designQuestion13Ref} placeholder={t("placemail")}/>
                        {error && <h5 style={{color: 'white'}}>{error}</h5>}
                    </div>
                    <br/>
                    <TextLoginService><AiOutlineWhatsApp style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                            <GlobalLargeInput type='number' ref={designQuestion14Ref}  placeholder={t("placewhatsapp")}/>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedDesign5Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedDesign5Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="submitproceeddesign">
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedDesign6Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={submitProceedDesignHandler}>
                                    <MdOutlineCreate style={{fontSize:'30px'}} />
                                   {t("creer")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackProceedDesignHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertproceeddesign"></div>
                <div id="backdropoutalertproceeddesign"></div>
            </LongFormScrollerWidgetSm>
        </div>
    );
}

export default ProceedDesign;