import React from 'react';
import { useNavigate } from "react-router-dom";
import '../components/components.css';
//
import imageaccount from '../carions-icon-192.png';
//
import { useTranslation } from 'react-i18next';
import '../translations/i18n';
import '../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,
    ReducedFormScrollerWidgetSm, 
    AdminButton,
    BackStart,  
} from '../global/Styles';

function MainAdmin() {
    const {t} = useTranslation();

    const navigateMainAdmin = useNavigate();

    const serviceServiceHandler = async (e) => {
        e.preventDefault();

        navigateMainAdmin('/serviceadmin');
    }

    const webServiceHandler = async (e) => {
        e.preventDefault();

        navigateMainAdmin('/servicewebdevelpment');
    }

    const seoServiceHandler = async (e) => {
        e.preventDefault();

        navigateMainAdmin('/servicesseoservice');
    }

    const hostingServiceHandler = async (e) => {
        e.preventDefault();

        navigateMainAdmin('/serviceshosting');
    }

    const contractsServiceHandler = async (e) => {
        e.preventDefault();

        navigateMainAdmin('/contractadmin');
    }

    const goBackMainAdminHandler = async (e) => {
        e.preventDefault();

        navigateMainAdmin('/crudservicescompo');
    }

    return (
        <DivContainerSlide id='mainadmin'>
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t("crudservicestitre")}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <img src={imageaccount} alt="" />
            </div>
            <ReducedFormScrollerWidgetSm>
                <div className="adminservicesstyle">
                    <div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={serviceServiceHandler}>Services</AdminButton>
                        </div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={webServiceHandler}>Web</AdminButton>
                        </div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={seoServiceHandler}>SEO</AdminButton>
                        </div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={hostingServiceHandler}>Hosting</AdminButton>
                        </div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={contractsServiceHandler}>Contract Templates</AdminButton>
                        </div>
                    </div>
                    <br/>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                        <div>
                            <BackStart onClick={goBackMainAdminHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </div>
                    <br/>
                </div>
            </ReducedFormScrollerWidgetSm>
        </DivContainerSlide>
    )
}

export default MainAdmin