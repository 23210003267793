import React from "react-dom";
import { useNavigate } from "react-router-dom";
//build tabs tools
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
//
import { AlertModal } from '../../../helperfunctions/Helper';
//
import '../services.css';
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import { HiOutlineClock } from 'react-icons/hi';
import { TbRefresh } from 'react-icons/tb';
//
import {
    SpanGlobalSpan,
    GlobalStrongTitle,
    TabsForm, 
    DivTabRowAround,
    DivTabRowBetween,
    PTab10,
    DivTabColumnAround,
    PTab10Bt0,
    BackStart,  
    HandlerContainerDiv,
} from '../../../global/Styles';

import { useState } from "react";
import Axios from 'axios';

function SoftManagementServicesTab() {
    const {t} = useTranslation();

    const navigateSoftManageTab = useNavigate();

    //alert
    const elementOutId = "modaloutalertsoftmanagementtab";
    const elementDropId = "backdropoutalertsoftmanagementtab";
    const buttonHandler = "ouiHandlerAlertSoftManagementTabBtn";

    const [softManagementTabBasic, getSoftManagementTabBasic] = useState([0]);

    var resultatappbasic = "";

    //http://localhost:5000
    //https://micarionsapi.carions.net

    const softManagementBasicHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'basic';
        //show the tab form paragraphs and panel
        const softmanageparagraphDiv = document.getElementById('softmanageparagraph');
        const softmanagepanelDiv = document.getElementById('softmanagepanel');
        //
        await Axios.post("https://micarionsapi.carions.net/seo/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getSoftManagementTabBasic(responseappbasic.data);
                //
                softmanageparagraphDiv.style.display = 'block';
                softmanagepanelDiv.style.display = 'block';
            }
        });
    }

    const softManagementStandardHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'standard';
        //show the tab form paragraphs and panel
        const softmanageparagraphDiv = document.getElementById('softmanageparagraph');
        const softmanagepanelDiv = document.getElementById('softmanagepanel');
        //
        await Axios.post("https://micarionsapi.carions.net/seo/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getSoftManagementTabBasic(responseappbasic.data);
                //
                softmanageparagraphDiv.style.display = 'block';
                softmanagepanelDiv.style.display = 'block';
            }
        });
    }

    const softManagementPremiumHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'premium';
        //show the tab form paragraphs and panel
        const softmanageparagraphDiv = document.getElementById('softmanageparagraph');
        const softmanagepanelDiv = document.getElementById('softmanagepanel');
        //
        await Axios.post("https://micarionsapi.carions.net/seo/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getSoftManagementTabBasic(responseappbasic.data);
                //
                softmanageparagraphDiv.style.display = 'block';
                softmanagepanelDiv.style.display = 'block';
            }
        }); 
    }

    //softmaintenanceservicestab proceed processes
    const managebasicHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'basic';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateSoftManageTab('/connectaccount');
            }else{
                navigateSoftManageTab('/proceedmanage');
            }
        }
    }
    //
    const managestandardHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'standard';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateSoftManageTab('/connectaccount');
            }else{
                navigateSoftManageTab('/proceedmanage');
            }
        }
    }
    //
    const managepremiumHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'premium';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateSoftManageTab('/connectaccount');
            }else{
                navigateSoftManageTab('/proceedmanage');
            }
        }
    }

    //softmaintenanceservicestab contact
    const managecontactHandler = async (e) => {
        e.preventDefault();

        navigateSoftManageTab('/contactme');
    }

    const goBackSoftManagementTabHandler = async (e) => {
        e.preventDefault();

        navigateSoftManageTab('/mainsoftwareservices');
    }

    return ( 
        <div id="softmanagementservicestab">
            <br/>
            <div style={{marginTop:'-2rem'}}>
                <SpanGlobalSpan style={{textAlign:'center'}}>
                    <GlobalStrongTitle>{t("software_management")}</GlobalStrongTitle>
                </SpanGlobalSpan>
            </div>
            <br/>
            <TabsForm>
                {softManagementTabBasic.map((valmanagebasic, indexmanagebasic) => (
                    <div key={indexmanagebasic} className="tabstyles">
                        <div id="softmanageparagraph">
                            <PTab10>
                                {valmanagebasic.services_paragraphone}<br/>
                                {valmanagebasic.services_paragraphtwo}<br/>
                                {valmanagebasic.services_paragraphthree}<br/>
                                {valmanagebasic.services_paragraphfour}<br/>
                            </PTab10>
                        </div>
                        <Tabs defaultIndex={0}>
                            <TabList>
                                <Tab onClick={softManagementBasicHandler}>{t("basic_plan")}</Tab>
                                <Tab onClick={softManagementStandardHandler}>Standard</Tab>
                                <Tab onClick={softManagementPremiumHandler}>Premium</Tab>
                            </TabList>
                            <div id="softmanagepanel">
                                <TabPanel >
                                    <DivTabRowBetween>
                                        <h3>{valmanagebasic.services_titre}</h3><h3>{valmanagebasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valmanagebasic.services_description} 
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valmanagebasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valmanagebasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmanagebasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox"></input>{valmanagebasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox"></input>{valmanagebasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox"></input>{valmanagebasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox"></input>{valmanagebasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={managebasicHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={managecontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                                <TabPanel>
                                    <DivTabRowBetween>
                                        <h3>{valmanagebasic.services_titre}</h3><h3>{valmanagebasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valmanagebasic.services_description}
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valmanagebasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valmanagebasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmanagebasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmanagebasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox"></input>{valmanagebasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox"></input>{valmanagebasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmanagebasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={managestandardHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={managecontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                                <TabPanel>
                                    <DivTabRowBetween>
                                        <h3>{valmanagebasic.services_titre}</h3><h3>{valmanagebasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valmanagebasic.services_description}
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valmanagebasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valmanagebasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmanagebasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmanagebasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmanagebasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmanagebasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valmanagebasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={managepremiumHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={managecontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                ))}
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackSoftManagementTabHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertsoftmanagementtab"></div>
                <div id="backdropoutalertsoftmanagementtab"></div>
            </TabsForm>
        </div>
    );
}

export default SoftManagementServicesTab;