import React from "react-dom";
import '../components.css';
//import myeditor
import DeleteMyEditor from "../reactquilleditor/DeleteMyEditor";
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle, 
} from '../../global/Styles';

function AccueilDeleteContenu() {
    const {t} = useTranslation();

    return ( 
        <DivContainerSlide id="accueildeletecontenu">
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t("crudcontenusupprimer")} {t("accueil")}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <div className="lescontenus">
                <DeleteMyEditor />
                <br/>
            </div>
        </DivContainerSlide>
    );
}

export default AccueilDeleteContenu;