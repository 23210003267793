import React from "react-dom";
import '../../components.css';
//import myeditor
import CreateServicesEditor from "../servicesquilleditor/CreateServicesEditor";
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,  
} from '../../../global/Styles';

function ServiceCreateContenu() {
    const {t} = useTranslation();

    return ( 
        <DivContainerSlide id="servicecreatecontenu">
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t("crudcreercontenu")} {t("services")}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <div className="lescontenus">
                <CreateServicesEditor />
                <br/>
            </div>
        </DivContainerSlide>
    );
}

export default ServiceCreateContenu;