import React from 'react';
import './cardcomponents.css';
import { MdSettingsBackupRestore } from 'react-icons/md';
//
import { useState, useEffect } from "react";
import Axios from 'axios';

import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';

import { frenchDateFormat, /*formatDateData,*/ } from '../../helperfunctions/Helper';

import {
    TextProceedService, 
    HandlerContainerDiv,
    BackStart,
} from '../../global/Styles';

const CommentCard = () => {
    const {t} = useTranslation();

    //set to retriev all comments
    const [valueComment, setValueComment] = useState([0]);
    const [valueAllComment, setValueAllComment] = useState([0]);
    //

    //http://localhost:5000
    //https://micarionsapi.carions.net

    useEffect(() => {
        var resultat = '';
        const commentStatus = "online";

        const fetchComment = async () => {
            await Axios.post("https://micarionsapi.carions.net/retrieve/comment", {
                commentStatus:commentStatus,
            }).then((responsecomment) => {
                resultat = responsecomment.data.length;
                if(resultat === 0){
                    //
                }else{
                    setValueComment(responsecomment.data);
                }
            });
        }
        fetchComment();
    }, []);

    const viewAllHandler = async (e) => {
        e.preventDefault();

        const displaycommentDiv = document.getElementById('displaycomment');
        const viewcommentDiv = document.getElementById('viewcomment');
        const displayallcommentDiv = document.getElementById('displayallcomment');
        //
        var resultatall = '';
        const commentStatus = "online";

        await Axios.post("https://micarionsapi.carions.net/retrieve/allcomment", {
            commentStatus:commentStatus,
        }).then((responseall) => {
            resultatall = responseall.data.length;
            if(resultatall === 0){
                displaycommentDiv.style.display = 'block';
                viewcommentDiv.style.display = 'block';
                displayallcommentDiv.style.display = 'none';
            }else{
                setValueAllComment(responseall.data);
                //
                displaycommentDiv.style.display = 'none';
                viewcommentDiv.style.display = 'none';
                displayallcommentDiv.style.display = 'block';
            }
        });
    }

    const goBackViewCommentHandler = async (e) => {
        e.preventDefault();

        const displaycommentDiv = document.getElementById('displaycomment');
        const viewcommentDiv = document.getElementById('viewcomment');
        const displayallcommentDiv = document.getElementById('displayallcomment');
        //
        displaycommentDiv.style.display = 'block';
        viewcommentDiv.style.display = 'block';
        displayallcommentDiv.style.display = 'none';
    }

    //generate stars emoticon based on stars number in database
    const getStarsEmoticon = (stars) => {
        const starsEmoticon = '⭐'.repeat(stars);
        return starsEmoticon;
    };

    //generate language based on language in database
    const getLanguage = (langue) => {
        const languevalue = langue;
        var language = '';
        if(languevalue === 'fr'){
            language = t('francais');
        }else if(languevalue === 'en'){
            language = t('anglais');
        }else{
            language = t('italien');
        }

        return language;
    }

    return(
        <div style={{marginTop:'-7rem'}}>
            <TextProceedService>{t("comment_title")}</TextProceedService>
            <br/>
            <div>
                <div id='displaycomment'>
                    {valueComment.map((valcomment, indexcomment) => (
                        <div key={indexcomment} className="comment-card">
                            <hr/>
                            <h3>{valcomment.comment_nom}</h3>
                            <p>{valcomment.comment_contenu}</p>
                            <div className='starcomment'>Stars: {getStarsEmoticon(valcomment.comment_star)}</div>
                            <div className='datecomment'>Date: {frenchDateFormat(valcomment.comment_date)}</div>
                            <div className='datecomment'>{getLanguage(valcomment.comment_langue)}</div>
                            <hr/>
                        </div>
                    ))}
                </div>
                <div id='viewcomment' className='viewall'>
                    <label style={{color:"#6666ff", fontStyle:"italic", fontSize:"18px"}} onClick={viewAllHandler}>{t("view_all_comment")}</label>
                </div>
                <div id='displayallcomment'>
                    {valueAllComment.map((valallcomment, indexallcomment) => (
                        <div key={indexallcomment} className="comment-card">
                            <hr/>
                            <h3>{valallcomment.comment_nom}</h3>
                            <p>{valallcomment.comment_contenu}</p>
                            <div className='starcomment'>Stars: {getStarsEmoticon(valallcomment.comment_star)}</div>
                            <div className='datecomment'>Date: {frenchDateFormat(valallcomment.comment_date)}</div>
                            <div className='datecomment'>{getLanguage(valallcomment.comment_langue)}</div>
                            <hr/>
                        </div>
                    ))}
                    <br/>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackViewCommentHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
            </div>
            <br/><br/>
        </div>
    )
}

export default CommentCard