import React from 'react';
import './footer.css';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import {FiFacebook, FiLinkedin } from 'react-icons/fi';
import { MdSendToMobile, MdOutlineAttachEmail, MdOutlinePrivacyTip } from 'react-icons/md';

function Footer() {
    const gdprHandler = async (e) => {
        e.preventDefault();
        
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        if(choixlangueDiv === 'fr'){
            window.location.href = 'https://eur-lex.europa.eu/legal-content/FR/TXT/HTML/?uri=CELEX:32016R0679&from=FR';
        }else if(choixlangueDiv === 'en'){
            window.location.href = 'https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679&from=EN';
        }else if(choixlangueDiv === 'it'){
            window.location.href = 'https://eur-lex.europa.eu/legal-content/IT/TXT/HTML/?uri=CELEX:32016R0679&from=IT';
        }else{
            window.location.href = 'https://eur-lex.europa.eu/legal-content/FR/TXT/HTML/?uri=CELEX:32016R0679&from=FR';
        }
    }
    

    return ( 
        <div className='footercontainer'>
            <a href='tel:+447438810727'><MdSendToMobile style={{fontSize:'20px', color:'white', cursor:'pointer'}} /></a>
            <a href='mailto:micarions2023@gmail.com'><MdOutlineAttachEmail style={{fontSize:'20px', color:'white', cursor:'pointer'}} /></a>
            <a href='https://www.facebook.com/grandbico/'><FiFacebook style={{fontSize:'20px', color:'white', cursor:'pointer'}} /></a>
            <a href='https://www.linkedin.com/in/bernard-kouassi-471b07125/'><FiLinkedin style={{fontSize:'20px', color:'white', cursor:'pointer'}} /></a>
            <a href='https://api.whatsapp.com/send?phone=+447438810727'><AiOutlineWhatsApp style={{fontSize:'20px', color:'white', cursor:'pointer'}} /></a>

            <MdOutlinePrivacyTip style={{fontSize:'20px', color:'white', marginTop:'-0.5rem', cursor:'pointer'}} onClick={gdprHandler} />
        </div>
    );
}

export default Footer;
