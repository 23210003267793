import styled from 'styled-components';

export const ViewScroller = styled.div`
    width: 100%;
    height: 400px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

export const DivContainerSlide = styled.div`
    width:100%;
    font:15px;
    font-weight:normal;
    text-align:left;
    margin-top: 2rem;

    @media screen and (max-width: 640px){
        width: 98%;
    }

    @media screen and (max-width: 768px){
        width: 98%;
    }
`;

export const StrongTitle = styled.strong`
    margin-left:600px;
    font-size:large;

    @media screen and (max-width: 640px){
        margin-left:100px;
    }

    @media screen and (max-width: 768px){
        margin-left:100px;
    }
`;

export const SpanGlobalSpan = styled.div`
    display:flex;
    justify-content:center;
`;

export const GlobalStrongTitle = styled.strong`
    font-size:large;
    color:#6666ff;
`;

export const ButtonStart = styled.button`
    border: none;
    color: white;
    display: flex;
    width: 120px;
    height: 40px;
    border-radius: 5px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background-color: #2fb433cd;
    cursor: pointer;
`;

export const BackStart = styled.div`
    border: none;
    color: white;
    display: flex;
    flex-direction:row;
    width: 120px;
    height: 40px;
    border-radius: 5px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background-color: #8c8c8c;
    cursor: pointer;
`;

export const ButtonLarge = styled.button`
    margin-top: 4%;
    display: flex;
    width: 340px;
    height: 80;
    border-radius: 10px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    border: 2px solid  #2fb433cd;
    font-size: 18px;
    color: "#008000";
    cursor: pointer;
`;

export const BigButton = styled.button`
    margin-top: 4%;
    display: flex;
    width: 340px;
    height: 50px;
    border-radius: 10px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    border: 2px solid  #2fb433cd;
    font-size: 18px;
    color: "#008000";
    cursor: pointer;
`;

export const AdminButton = styled.button`
    margin-top: 4%;
    display: flex;
    width: 340px;
    height: 50px;
    border-radius: 10px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    border: 2px solid  #2fb433cd;
    font-size: 18px;
    color: green;
    cursor: pointer;
`;

export const DivWidget = styled.div`
    width:100%;
    display:flex;
    margin:20px;
`;

export const ButtonList = styled.button`
    border: none;
    color: #2d6c85;
    display: flex;
    width: 150px;
    height: 30px;
    border-radius: 10px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background-color: #e5f4fa;
    cursor: pointer;
`;

export const ButtonClear = styled.button`
    border: none;
    color: #ff0000;
    display: flex;
    width: 100px;
    height: 30px;
    border-radius: 10px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background-color: #ffcccc;
    cursor: pointer;
`;

export const LargeInput = styled.input`
    width:300px;
    height:30px;
    border-radius:5px;
    font-size: 17px;

    @media screen and (max-width: 640px){
        width:290px;
    }

    @media screen and (max-width: 768px){
        width:290px;
    }
`;

export const GlobalLargeInput = styled.input`
    width:300px;
    height:30px;
    border-radius:5px;
    font-size: 17px;
    color: black;
    background-color: white;

    @media screen and (max-width: 640px){
        width:200px;
    }

    @media screen and (max-width: 768px){
        width:200px;
    }
`;

export const LargeSelect = styled.select`
    width:350px;
    height:35px;
    border-radius:5px;
    font-size: 17px;

    @media screen and (max-width: 640px){
        width:320px;
    }

    @media screen and (max-width: 768px){
        width:320px;
    }
`;

export const SmallSelect = styled.select`
    width:200px;
    height:30px;
    border-radius:5px;
    font-size: 17px;

    @media screen and (max-width: 640px){
        width:120px;
    }

    @media screen and (max-width: 768px){
        width:120px;
    }
`;

export const TextArea = styled.textarea`
   width: 310px;
   height: 210px;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 15px;
    font-weight: bold;

    @media screen and (max-width: 640px){
        width:320px;
        height: 210px;
    }

    @media screen and (max-width: 768px){
        width:320px;
        height: 210px;
    }
`;

export const TextAreaView = styled.textarea`
   width: 310px;
   height: 100px;
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

    @media screen and (max-width: 640px){
        width:320px;
        height: 100px;
    }

    @media screen and (max-width: 768px){
        width:320px;
        height: 100px;
    }
`;

export const DivWidgetLgContainer = styled.div`
    width:100%;
    flex:1;
    -webkit-box-shadow: 5px 5px 15px 5px #000000; 
    box-shadow: 5px 5px 15px 5px #000000;
    padding:20px;

    @media screen and (max-width: 640px){
        width:320px;
    }

    @media screen and (max-width: 768px){
        width:320px;
    }
`;

export const FormScrollerWidgetLg = styled.form`
    width: 100%;
    height: 960px;
    overflow-y: scroll;
    display: flex;
    justify-content: space-around;
    -ms-overflow-style: none;
    scrollbar-width: none;

    @media screen and (max-width: 640px){
        height: 600px;
        flex-direction: column;
    }

    @media screen and (max-width: 768px){
        height: 600px;
        flex-direction: column;
    }
`;

export const HWidgetLgTitle = styled.h3`
    width: 100%;
    font-size:20px;
    font-weight:600;
`;

export const DivWidgetSmContainer = styled.div`
    width: 100%;
    flex:1;
    -webkit-box-shadow: 5px 5px 15px 5px #000000; 
    box-shadow: 5px 5px 15px 5px #000000;
    padding:20px;
    margin-right:20px;

    @media screen and (max-width: 640px){
        width:320px;
    }

    @media screen and (max-width: 768px){
        width:320px;
    }
`;

export const HWidgetSmTitle = styled.h3`
    font-size:20px;
    font-weight:600;
`;

export const FormScrollerWidgetSm = styled.form`
    width: 100%;
    height: 750px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    @media screen and (max-width: 640px){
        width:100%;
    }

    @media screen and (max-width: 768px){
        width:100%;
    }
`;

export const LongFormScrollerWidgetSm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

export const ReducedFormScrollerWidgetSm = styled.form`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

export const DivScrollerWidgetSm = styled.div`
    margin-top: -8rem;
    width: 100%;
    height: 960px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

export const TabsForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const TabsDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const PTab10 = styled.p`
    margin-left: 10px;
`;

export const DivTabRowBetween = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const DivTabRowAround = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

export const DivTabColumnAround = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

export const PTab10Bt0 = styled.p`
    margin-left: 10px;
    margin-bottom: 0;
`;

export const TableWidgetSmTable = styled.table`
    width:100%;
    border-spacing:20px;
`;

export const ThWidgetSmTh = styled.th`
    text-align:left;
    color:#000;
`;

export const ImgWidgetSmImage = styled.img`
    width:40px;
    height:40px;
    border-radius:50%;
    object-fit:cover;
`;

export const UlWidgetSmList = styled.ul`
    margin:0;
    padding:0;
    list-style:none;
`;

export const LiWidgetSmListItem = styled.li`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin:20px 0px;
`;

export const DivWidgetSmUser = styled.div`
    width: 100%;
    display:flex;
    flex-direction:column;
`;

export const SpanWidgetSmUsername = styled.span`
    font-weight:600;
`;

export const SpanWidgetSmUserTitle = styled.span`
    font-weight:300;
`;

export const WidgetSmButton = styled.button`
    display:flex;
    align-items:center;
    border:none;
    border-radius:10px;
    padding:7px 10px;
    background-color:#eeeef7;
    color:#555;
    cursor:pointer;
`;

export const TextTitle = styled.div`
    width: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
    text-align: center;
`;

export const TextLogin = styled.span`
    width: 100%;
    font-size: 0.9rem;
    color:white;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:600;
`;

export const TextLoginService = styled.span`
    width: 100%;
    font-size: 0.9rem;
    color:black;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:600;
`;

export const TextProceedService = styled.span`
    width: 100%;
    font-size: 1.1rem;
    color:white;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:600;
`;

export const ImageLogo = styled.img`
    max-height:40px;
    max-width:40px;
    cursor:pointer;
`;

export const HandlerContainerDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    @media screen and (max-width: 640px){
        justify-content: space-around;
    }

    @media screen and (max-width: 768px){
        justify-content: space-around;
    }
`;

/** */
export const DivContainer = styled.div`
    flex:4;
`;

//Card view
export const CardViewDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;
`;

export const CardDiv = styled.div`
    width: 30%;
    margin-bottom: 30px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const CardImg = styled.img`
    max-width: 50%;
    height: 30%;
    border-radius: 50%;
    margin-bottom: 20px;
`;

export const CardH3 = styled.h3`
    font-size: 24px;
    margin-bottom: 10px;
`;

export const CardP = styled.p`
    font-size: 16px;
    margin-bottom: 20px;
`;

/**Blog stiling */
export const BlogCategories = styled.button`
    color: white;
    display: flex;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid green;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    background-color: green;
    cursor: pointer;
    :hover{
      background: transparent;
      color: #00a8ff;
      border-color: var(--color-white);
      transition: var(--transition);
    }
    .active{
      color: #00a8ff;
    }
`;

export const BlogButton = styled.button`
    color: black;
    display: flex;
    width: 150px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid green;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    background-color: white;
    cursor: pointer;
    :hover{
      background: transparent;
      color: #00a8ff;
      border-color: var(--color-white);
      transition: var(--transition);
    }
    .active{
      color: #00a8ff;
    }
`;









































