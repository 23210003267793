import React from 'react';
import '../../components/components.css';
import { useNavigate } from "react-router-dom";
//
import imageaccount from '../../carions-icon-192.png';
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,
    ReducedFormScrollerWidgetSm, 
    AdminButton,
    BackStart,  
} from '../../global/Styles';

function ContractAdmin() {
    const {t} = useTranslation();

    const navigateContractAdmin = useNavigate();

    const createContractHandler = async (e) => {
        e.preventDefault();

        navigateContractAdmin("/createcontract");
    }

    const updateContractHandler = async (e) => {
        e.preventDefault();
        
        navigateContractAdmin("/editcontract");
    }

    const deleteContractHandler = async (e) => {
        e.preventDefault();

        navigateContractAdmin("/deletecontract");
    }

    const viewContractHandler = async (e) => {
        e.preventDefault();

        navigateContractAdmin("/viewcontract");
    }

    const goBackContractAdminHandler = async (e) => {
        e.preventDefault();

        navigateContractAdmin("/mainadmin");
    }

    return (
        <DivContainerSlide id='contractadmin'>
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>Contract Management</GlobalStrongTitle>
            </SpanGlobalSpan>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <img src={imageaccount} alt="" />
            </div>
            <ReducedFormScrollerWidgetSm>
                <div className="adminservicesstyle">
                    <div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={createContractHandler}>Create Contract</AdminButton>
                        </div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={updateContractHandler}>Update Contract</AdminButton>
                        </div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={deleteContractHandler}>Delete Contract</AdminButton>
                        </div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={viewContractHandler}>View Contracts</AdminButton>
                        </div>
                    </div>
                    <br/>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                        <div>
                            <BackStart onClick={goBackContractAdminHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </div>
                    <br/>
                </div>
            </ReducedFormScrollerWidgetSm>
        </DivContainerSlide>
    )
}

export default ContractAdmin