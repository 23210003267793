import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../services.css';
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,
    DivScrollerWidgetSm, 
    BackStart,  
    HandlerContainerDiv,
    BigButton,
} from '../../../global/Styles';

function MainSoftwareServices() {
    const {t} = useTranslation();

    const navigateSoftwareServices = useNavigate();

    const softCodingHandler = async (e) => {
        e.preventDefault();

        //type service
        document.getElementById('typeservice').innerText = 'codingsoft';

        navigateSoftwareServices('/softcodingservicestab');
    }

    const softDesingHandler = async (e) => {
        e.preventDefault();

        //type service
        document.getElementById('typeservice').innerText = 'designsoft';
        
        navigateSoftwareServices('/softdesignservicestab');
    }

    const softDevelopmentHandler = async (e) => {
        e.preventDefault();

        //type service
        document.getElementById('typeservice').innerText = 'devsoft';

        navigateSoftwareServices('/softdevelopmentservicestab');
    }

    const softMaintenanceHandler = async (e) => {
        e.preventDefault();

        //type service
        document.getElementById('typeservice').innerText = 'maintsoft';

        navigateSoftwareServices('/softmaintenanceservicestab');  
    }

    const softManagementHandler = async (e) => {
        e.preventDefault();

        //type service
        document.getElementById('typeservice').innerText = 'managesoft';
        
        navigateSoftwareServices('/softmanagementservicestab');
    }

    const softMiscellaniousHandler = async (e) => {
        e.preventDefault();

        //type service
        document.getElementById('typeservice').innerText = 'miscsoft';
        
        navigateSoftwareServices('/softmiscellaniousservicestab');
    }

    const goBackSoftwareServiceHandler = async (e) => {
        e.preventDefault();

        navigateSoftwareServices('/');
    }

    return ( 
        <DivContainerSlide id="mainsoftwareservices">
            <br/>
            <div style={{marginTop:'-2rem'}}>
                <SpanGlobalSpan style={{textAlign:'center'}}>
                    <GlobalStrongTitle>{t("softwareservices")}</GlobalStrongTitle>
                </SpanGlobalSpan>
            </div>
            <br/>
            <DivScrollerWidgetSm>
                <p className="pservicestyles"><br/><br/>{t("softservices_description")}</p>
                <div id="softwaretabaction" className="connectaccountstyle">
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={softCodingHandler}>{t("software_coding")}</BigButton>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={softDesingHandler}>{t("software_design")}</BigButton>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={softDevelopmentHandler}>{t("software_development")}</BigButton>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={softMaintenanceHandler}>{t("software_maintenance")}</BigButton>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={softManagementHandler}>{t("software_management")}</BigButton>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={softMiscellaniousHandler}>{t("software_miscellanious")}</BigButton>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackSoftwareServiceHandler}>
                                    <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                    {t("retour")}
                                </BackStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertpubliersoftservices"></div>
                <div id="backdropoutalertpubliersoftservices"></div>
            </DivScrollerWidgetSm>
        </DivContainerSlide>
    );
}
export default MainSoftwareServices;