import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../proceedweb.css';
import { useRef, useState } from "react";
//
import { MdOutlineCreate, MdSettingsBackupRestore, MdOutlineArrowForwardIos, MdOutlineArrowBackIos, MdOutlineAttachEmail } from 'react-icons/md';
import { AiOutlineWhatsApp } from 'react-icons/ai';
//
import { AlertModal, getCurrentDate, PopupModal } from "../../helperfunctions/Helper";
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
import {  
    SpanGlobalSpan,
    GlobalStrongTitle,
    LongFormScrollerWidgetSm,
    TextProceedService, 
    TextLoginService,
    GlobalLargeInput, 
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
    TextArea,
} from "../../global/Styles";

import Axios from 'axios';

function ProceedWebDev() {
    const {t} = useTranslation();

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleDevChange = event => {
        if (!isValidEmail(event.target.value)) {
          setError(t('invalidemail'));
        } else {
          setError(null);
        }
    
        setEmail(event.target.value);
    };

    const navigateProceedDev = useNavigate();

    //alert
    const elementOutId = "modaloutproceeddev";
    const elementDropId = "backdropoutproceeddev";
    const buttonHandler = "ouiHandlerProceedDevBtn";
    //
    const elementOutAlertId = "modaloutalertproceeddev";
    const elementDropAlertId = "backdropoutalertproceeddev";
    const buttonAlertHandler = "ouiHandleralertProceedDevBtn";

    const devQuestion1Ref = useRef(null);
    const devQuestion2Ref = useRef(null);
    const devQuestion3Ref = useRef(null);
    //
    const devQuestion4Ref = useRef(null);
    const devQuestion5Ref = useRef(null);
    const devQuestion6Ref = useRef(null);
    //
    const devQuestion7Ref = useRef(null);
    const devQuestion8Ref = useRef(null);
    const devQuestion9Ref = useRef(null);
    //
    const devQuestion10Ref = useRef(null);
    const devQuestion11Ref = useRef(null);
    const devQuestion12Ref = useRef(null);
    //
    const devQuestion13Ref = useRef(null);
    const devQuestion14Ref = useRef(null);

    const popdev1Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_1");
        const pTexteNotAvailable = t("hintdesignquestion_1");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popdev2Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_2");
        const pTexteNotAvailable = t("hintdesignquestion_2");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popdev3Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_3");
        const pTexteNotAvailable = t("hintdesignquestion_3");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    /** */
    const forwardProceedDev1Handler = async (e) => {
        e.preventDefault();

        const devsteponeDiv = document.getElementById('devstepone');
        const devsteptwoDiv = document.getElementById('devsteptwo');
        const devstepthreeDiv = document.getElementById('devstepthree');
        const devstepfourDiv = document.getElementById('devstepfour');
        const modeproceeddevDiv = document.getElementById('modeproceeddev');
        const submitproceeddevDiv = document.getElementById('submitproceeddev');
        //
        devsteponeDiv.style.display = 'none';
        devsteptwoDiv.style.display = 'block';
        devstepthreeDiv.style.display = 'none';
        devstepfourDiv.style.display = 'none';
        modeproceeddevDiv.style.display = 'none';
        submitproceeddevDiv.style.display = 'none';
    }

    const popdev4Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_4");
        const pTexteNotAvailable = t("hintdesignquestion_4");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popdev5Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_5");
        const pTexteNotAvailable = t("hintdesignquestion_5");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popdev6Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_6");
        const pTexteNotAvailable = t("hintdesignquestion_6");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    /** */
    const goBackProceedDev2Handler = async (e) => {
        e.preventDefault();

        const devsteponeDiv = document.getElementById('devstepone');
        const devsteptwoDiv = document.getElementById('devsteptwo');
        const devstepthreeDiv = document.getElementById('devstepthree');
        const devstepfourDiv = document.getElementById('devstepfour');
        const modeproceeddevDiv = document.getElementById('modeproceeddev');
        const submitproceeddevDiv = document.getElementById('submitproceeddev');
        //
        devsteponeDiv.style.display = 'block';
        devsteptwoDiv.style.display = 'none';
        devstepthreeDiv.style.display = 'none';
        devstepfourDiv.style.display = 'none';
        modeproceeddevDiv.style.display = 'none';
        submitproceeddevDiv.style.display = 'none';
    }
    //
    const forwardProceedDev2Handler = async (e) => {
        e.preventDefault();

        const devsteponeDiv = document.getElementById('devstepone');
        const devsteptwoDiv = document.getElementById('devsteptwo');
        const devstepthreeDiv = document.getElementById('devstepthree');
        const devstepfourDiv = document.getElementById('devstepfour');
        const modeproceeddevDiv = document.getElementById('modeproceeddev');
        const submitproceeddevDiv = document.getElementById('submitproceeddev');
        //
        devsteponeDiv.style.display = 'none';
        devsteptwoDiv.style.display = 'none';
        devstepthreeDiv.style.display = 'block';
        devstepfourDiv.style.display = 'none';
        modeproceeddevDiv.style.display = 'none';
        submitproceeddevDiv.style.display = 'none';
    }

    const popdev7Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_7");
        const pTexteNotAvailable = t("hintdesignquestion_7");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popdev8Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_8");
        const pTexteNotAvailable = t("hintdesignquestion_8");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popdev9Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_9");
        const pTexteNotAvailable = t("hintdesignquestion_9");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    /** */
    const goBackProceedDev3Handler = async (e) => {
        e.preventDefault();

        const devsteponeDiv = document.getElementById('devstepone');
        const devsteptwoDiv = document.getElementById('devsteptwo');
        const devstepthreeDiv = document.getElementById('devstepthree');
        const devstepfourDiv = document.getElementById('devstepfour');
        const modeproceeddevDiv = document.getElementById('modeproceeddev');
        const submitproceeddevDiv = document.getElementById('submitproceeddev');
        //
        devsteponeDiv.style.display = 'none';
        devsteptwoDiv.style.display = 'block';
        devstepthreeDiv.style.display = 'none';
        devstepfourDiv.style.display = 'none';
        modeproceeddevDiv.style.display = 'none';
        submitproceeddevDiv.style.display = 'none';
    }
    //
    const forwardProceedDev3Handler = async (e) => {
        e.preventDefault();

        const devsteponeDiv = document.getElementById('devstepone');
        const devsteptwoDiv = document.getElementById('devsteptwo');
        const devstepthreeDiv = document.getElementById('devstepthree');
        const devstepfourDiv = document.getElementById('devstepfour');
        const modeproceeddevDiv = document.getElementById('modeproceeddev');
        const submitproceeddevDiv = document.getElementById('submitproceeddev');
        //
        devsteponeDiv.style.display = 'none';
        devsteptwoDiv.style.display = 'none';
        devstepthreeDiv.style.display = 'none';
        devstepfourDiv.style.display = 'block';
        modeproceeddevDiv.style.display = 'none';
        submitproceeddevDiv.style.display = 'none';
    }

    const popdev10Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_10");
        const pTexteNotAvailable = t("hintdesignquestion_10");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popdev11Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_11");
        const pTexteNotAvailable = t("hintdesignquestion_11");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popdev12Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("designquestion_12");
        const pTexteNotAvailable = t("hintdesignquestion_12");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    /** */
    const goBackProceedDev4Handler = async (e) => {
        e.preventDefault();

        const devsteponeDiv = document.getElementById('devstepone');
        const devsteptwoDiv = document.getElementById('devsteptwo');
        const devstepthreeDiv = document.getElementById('devstepthree');
        const devstepfourDiv = document.getElementById('devstepfour');
        const modeproceeddevDiv = document.getElementById('modeproceeddev');
        const submitproceeddevDiv = document.getElementById('submitproceeddev');
        //
        devsteponeDiv.style.display = 'none';
        devsteptwoDiv.style.display = 'none';
        devstepthreeDiv.style.display = 'block';
        devstepfourDiv.style.display = 'none';
        modeproceeddevDiv.style.display = 'none';
        submitproceeddevDiv.style.display = 'none';
    }
    //
    const forwardProceedDev4Handler = async (e) => {
        e.preventDefault();

        const devsteponeDiv = document.getElementById('devstepone');
        const devsteptwoDiv = document.getElementById('devsteptwo');
        const devstepthreeDiv = document.getElementById('devstepthree');
        const devstepfourDiv = document.getElementById('devstepfour');
        const modeproceeddevDiv = document.getElementById('modeproceeddev');
        const submitproceeddevDiv = document.getElementById('submitproceeddev');
        //
        devsteponeDiv.style.display = 'none';
        devsteptwoDiv.style.display = 'none';
        devstepthreeDiv.style.display = 'none';
        devstepfourDiv.style.display = 'none';
        modeproceeddevDiv.style.display = 'block';
        submitproceeddevDiv.style.display = 'none';
    }

    const goBackProceedDev5Handler = async (e) => {
        e.preventDefault();

        const devsteponeDiv = document.getElementById('devstepone');
        const devsteptwoDiv = document.getElementById('devsteptwo');
        const devstepthreeDiv = document.getElementById('devstepthree');
        const devstepfourDiv = document.getElementById('devstepfour');
        const modeproceeddevDiv = document.getElementById('modeproceeddev');
        const submitproceeddevDiv = document.getElementById('submitproceeddev');
        //
        devsteponeDiv.style.display = 'none';
        devsteptwoDiv.style.display = 'none';
        devstepthreeDiv.style.display = 'none';
        devstepfourDiv.style.display = 'block';
        modeproceeddevDiv.style.display = 'none';
        submitproceeddevDiv.style.display = 'none';
    }
    //
    const forwardProceedDev5Handler = async (e) => {
        e.preventDefault();

        const devQuestion13 = devQuestion13Ref.current.value;
        const devQuestion14 = devQuestion14Ref.current.value;
        //
        const devsteponeDiv = document.getElementById('devstepone');
        const devsteptwoDiv = document.getElementById('devsteptwo');
        const devstepthreeDiv = document.getElementById('devstepthree');
        const devstepfourDiv = document.getElementById('devstepfour');
        const modeproceeddevDiv = document.getElementById('modeproceeddev');
        const submitproceeddevDiv = document.getElementById('submitproceeddev');
        //
        if(!devQuestion13){
            const pTexteSaisir = t("enteremail");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else if(!devQuestion14){
            const pTexteSaisir = t("enterphone");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else{
            //
            devsteponeDiv.style.display = 'none';
            devsteptwoDiv.style.display = 'none';
            devstepthreeDiv.style.display = 'none';
            devstepfourDiv.style.display = 'none';
            modeproceeddevDiv.style.display = 'none';
            submitproceeddevDiv.style.display = 'block';
        }
    }

    const goBackProceedDev6Handler = async (e) => {
        e.preventDefault();

        const devsteponeDiv = document.getElementById('devstepone');
        const devsteptwoDiv = document.getElementById('devsteptwo');
        const devstepthreeDiv = document.getElementById('devstepthree');
        const devstepfourDiv = document.getElementById('devstepfour');
        const modeproceeddevDiv = document.getElementById('modeproceeddev');
        const submitproceeddevDiv = document.getElementById('submitproceeddev');
        //
        devsteponeDiv.style.display = 'none';
        devsteptwoDiv.style.display = 'none';
        devstepthreeDiv.style.display = 'none';
        devstepfourDiv.style.display = 'none';
        modeproceeddevDiv.style.display = 'block';
        submitproceeddevDiv.style.display = 'none';
    }

    //http://localhost:5000/check/devservice

    const submitProceedDevHandler = async (e) => {
        e.preventDefault();

        const devQuestion1 = devQuestion1Ref.current.value;
        const devQuestion2 = devQuestion2Ref.current.value;
        const devQuestion3 = devQuestion3Ref.current.value;
        //websteptwo
        const devQuestion4 = devQuestion4Ref.current.value;
        const devQuestion5 = devQuestion5Ref.current.value;
        const devQuestion6 = devQuestion6Ref.current.value;
        //webstepthree
        const devQuestion7 = devQuestion7Ref.current.value;
        const devQuestion8 = devQuestion8Ref.current.value;
        const devQuestion9 = devQuestion9Ref.current.value;
        //webstepfour
        const devQuestion10 = devQuestion10Ref.current.value;
        const devQuestion11 = devQuestion11Ref.current.value;
        const devQuestion12 = devQuestion12Ref.current.value;
        //modeproceedweb
        const devQuestion13 = devQuestion13Ref.current.value;
        const devQuestion14 = devQuestion14Ref.current.value;
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const theDate = getCurrentDate();
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = "webdev";
        const servicePlan = document.getElementById('typeplan').innerText;
        var createTicketCode = "";
        //
        var resultat1 = "";
        //
        //generate random ticket code
        var randomNumber = Math.floor((Math.random() * 100000) + 1);
        createTicketCode = randomNumber+''+codeutilisateurDiv;
        //
        await Axios.post("https://micarionsapi.carions.net/check/devservice", {
            codeutilisateurDiv:codeutilisateurDiv,
            typeService:typeService,
            servicePlan:servicePlan,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                //insert
                Axios.post("https://micarionsapi.carions.net/quest/devservice", {
                    choixlangueDiv:choixlangueDiv,
                    usernamedetailDiv:usernamedetailDiv,
                    codeutilisateurDiv:codeutilisateurDiv,
                    //
                    typeService:typeService,
                    servicePlan:servicePlan,
                    //
                    createTicketCode:createTicketCode,
                    //
                    devQuestion1:devQuestion1,
                    devQuestion2:devQuestion2,
                    devQuestion3:devQuestion3,
                    //
                    devQuestion4:devQuestion4,
                    devQuestion5:devQuestion5,
                    devQuestion6:devQuestion6,
                    //
                    devQuestion7:devQuestion7,
                    devQuestion8:devQuestion8,
                    devQuestion9:devQuestion9,
                    //
                    devQuestion10:devQuestion10,
                    devQuestion11:devQuestion11,
                    devQuestion12:devQuestion12,
                    //
                    devQuestion13:devQuestion13,
                    devQuestion14:devQuestion14,
                    //
                    theDate:theDate,
                }).then(() => {
                    var answer1 = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                    const hTitleTexte = t("software_design");
                    const pTexteNotAvailable = answer1;
                    PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
                });
            }else{
                //set up modal to update
                var answer = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                const hTitleTexte = t("software_design");
                const pTexteNotAvailable = answer;
                PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
            }
        });
    }

    const goBackProceedDevHandler = async (e) => {
        e.preventDefault();

        navigateProceedDev('/softdevelopmentservicestab');
    }

    return ( 
        <div id="proceedwebdev">
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t('software_development')}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <LongFormScrollerWidgetSm>
                <div id="modaloutproceeddev"></div>
                <div id="backdropoutproceeddev"></div>
                <p className="pservicestyles"><br/>{t("proceedwebdescription")}</p>
                <div id="devstepone">
                    <TextProceedService onClick={popdev1Handler}>{t("webquestion_1")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={devQuestion1Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popdev2Handler}>{t("webquestion_2")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={devQuestion2Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popdev3Handler}>{t("webquestion_3")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={devQuestion3Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <ButtonStart onClick={forwardProceedDev1Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="devsteptwo">
                    <TextProceedService onClick={popdev4Handler}>{t("webquestion_4")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={devQuestion4Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popdev5Handler}>{t("webquestion_5")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={devQuestion5Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popdev6Handler}>{t("webquestion_6")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={devQuestion6Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedDev2Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedDev2Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="devstepthree">
                    <TextProceedService onClick={popdev7Handler}>{t("webquestion_7")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={devQuestion7Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popdev8Handler}>{t("webquestion_8")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={devQuestion8Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popdev9Handler}>{t("webquestion_9")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={devQuestion9Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedDev3Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedDev3Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="devstepfour">
                    <TextProceedService onClick={popdev10Handler}>{t("webquestion_10")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={devQuestion10Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popdev11Handler}>{t("webquestion_11")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={devQuestion11Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popdev12Handler}>{t("webquestion_12")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={devQuestion12Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedDev4Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedDev4Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="modeproceeddev">
                    <TextProceedService>{t("webquestion_13")}</TextProceedService>
                    <TextLoginService><MdOutlineAttachEmail style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput id="emaildev" name="emaildev" value={email} onChange={handleDevChange} ref={devQuestion13Ref} placeholder={t("placemail")}/>
                        {error && <h5 style={{color: 'white'}}>{error}</h5>}
                    </div>
                    <br/>
                    <TextLoginService><AiOutlineWhatsApp style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput type='number' ref={devQuestion14Ref}  placeholder={t("placewhatsapp")}/>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedDev5Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedDev5Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="submitproceeddev">
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedDev6Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={submitProceedDevHandler}>
                                    <MdOutlineCreate style={{fontSize:'30px'}} />
                                   {t("creer")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackProceedDevHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertproceeddev"></div>
                <div id="backdropoutalertproceeddev"></div>
            </LongFormScrollerWidgetSm>
        </div>
    );
}

export default ProceedWebDev;