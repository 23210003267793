import React from 'react';
import { useNavigate } from "react-router-dom";
import '../../components/reactquilleditor/myeditor.css';
//
import { MdOutlineCreate, MdSettingsBackupRestore,} from 'react-icons/md';
import { useRef, useState } from "react";
import Axios from 'axios';
//
import '../../translations/Translation';
//
import { AlertModal, getCurrentDate } from "../../helperfunctions/Helper";
//
import { 
    DivContainerSlide, 
    FormScrollerWidgetSm,
    TextLogin, 
    LargeSelect,
    GlobalLargeInput,
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
} from "../../global/Styles";
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
//
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import katex from 'katex';
import "katex/dist/katex.min.css";
window.katex = katex;
//
const modules = {
    toolbar: [[{header: [1, 2, 3, 4, 5, 6,  false] }],
    [{ color: [] }],
    [{ background: [] }],
    [{ script:  "sub" }, { script:  "super" }],
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
    [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
    ],
    ["link", "image", "video", "formula"],
    ["clean"],
],
};

function CreateContractEditor() {
    const {t} = useTranslation();

    const navigateCreateContractEditor = useNavigate();

    //alert
    const elementOutId = "modaloutalerteditorcontract";
    const elementDropId = "backdropoutalerteditorcontract";
    const buttonHandler = "ouiHandlerAlertEditorContractBtn";

    const [valueContract, setValueContract] = useState("");

    const editorContractTextRef = useRef(null);
    const createSelectTypeContractRef = useRef(null);
    const createTitreContractRef = useRef(null);

    //http://localhost:5000/contract/check

    const submitContractEditorHandler = async (e) => {
        e.preventDefault();

        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const modelContract = document.getElementById("actionpage").innerText;
        const createSelectTypeContract = createSelectTypeContractRef.current.value;
        const createTitreContract = createTitreContractRef.current.value;
        const editorContractText = editorContractTextRef.current.value;
        const createStatusContract = 'online';
        const createDateContract = getCurrentDate();
        //
        var resultatcheck = 0;

        if(!createSelectTypeContract){
            const pTexteSaisir = t("typecontenu");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createTitreContract){
            const pTexteSaisir = "Contract Title";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler); 
        }else if(!editorContractText){
            const pTexteSaisir = t("textcontenu");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler); 
        }else{
            //check if this contract template already exists
            await Axios.post("https://micarionsapi.carions.net/contract/check", {
                choixlangueDiv:choixlangueDiv,
                modelContract:modelContract,
                createSelectTypeContract:createSelectTypeContract,
            }).then((responsecheck) => {
                resultatcheck = responsecheck.data.length;
                if(resultatcheck === 0){
                    Axios.post("https://micarionsapi.carions.net/contract/create", {
                        codeutilisateurDiv:codeutilisateurDiv,
                        choixlangueDiv:choixlangueDiv,
                        modelContract:modelContract,
                        createSelectTypeContract:createSelectTypeContract,
                        createTitreContract:createTitreContract,
                        editorContractText:editorContractText,
                        createStatusContract:createStatusContract,
                        createDateContract:createDateContract,
                    }).then(() => {
                        const pTexteSaisir = "Contract has been saved successfully";
                        AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
                    }); 
                }else{
                    const pTexteSaisir = "This Contract already exists";
                    AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
                }
            });
        }
    }

    const goBackContractEditorHandler = async (e) => {
        e.preventDefault();

        navigateCreateContractEditor("/contractadmin");
    }

    return (
        <DivContainerSlide>
            <FormScrollerWidgetSm>
                <div className="row">
                    <div className="editor">
                        {t("editeur")}
                        <ReactQuill theme="snow" 
                            value={valueContract} 
                            onChange={setValueContract}
                            className="editor-input"
                            modules={modules}
                        />
                    </div>
                    <textarea style={{display:'none'}} ref={editorContractTextRef} defaultValue={valueContract}></textarea>
                    <div style={{display:'none'}} className="preview" 
                        dangerouslySetInnerHTML={{ __html: valueContract }}
                    />
                </div>
                <br/><br/>
                <div>
                    <div id="createtitrecontracteditor">
                        <TextLogin>{t("typecontenu")}<strong style={{color:'red'}}>*</strong></TextLogin>
                        <div style={{display:'block',textAlign:'center'}}>
                            <LargeSelect ref={createSelectTypeContractRef}>
                                <optgroup style={{color:'white', backgroundColor:'green'}} label={t("services")}>
                                    <option value=''>{t("typecontenu")}</option>
                                    <option value='coding'>{t("software_coding")}</option>
                                    <option value='design'>{t("software_design")}</option>
                                    <option value='webdev'>{t("software_development")}</option>
                                    <option value='maintenance'>{t("software_maintenance")}</option>
                                    <option value='management'>{t("software_management")}</option>
                                    <option value='miscellanious'>{t("software_miscellanious")}</option>
                                </optgroup>
                                <optgroup style={{color:'white', backgroundColor:'blue'}} label="Web">
                                    <option value='website'>{t("website")}</option>
                                    <option value='webapp'>{t("webapp")}</option>
                                    <option value='ecommerce'>{t("ecommerce")}</option>
                                </optgroup>
                                <optgroup style={{color:'white', backgroundColor:'gray'}} label="SEO">
                                    <option value='seokeyword'>{t("keyword_research")}</option>
                                    <option value='seooptimization'>{t("on_page")}</option>
                                    <option value='seotechnical'>{t("technical_audit")}</option>
                                    <option value='seomonthly'>{t("monthly_report")}</option>
                                </optgroup>
                                <optgroup style={{color:'white', backgroundColor:'yellow'}} label="Hosting">
                                    <option value='hosting'>Hosting Service</option>
                                </optgroup>
                            </LargeSelect>
                        </div>
                        <br/>
                        <TextLogin>Contract Title<strong style={{color:'red'}}>*</strong></TextLogin>
                        <div style={{display:'block',textAlign:'center'}}>
                            <GlobalLargeInput type='text' ref={createTitreContractRef} placeholder="Contract Title"/>
                        </div>
                        <br/>
                    </div>

                    <HandlerContainerDiv>
                        <div>
                            <ButtonStart onClick={submitContractEditorHandler}>
                                <MdOutlineCreate style={{fontSize:'30px'}} />
                                {t("creer")}
                            </ButtonStart>
                        </div>
                        <div>
                            <BackStart onClick={goBackContractEditorHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                </div>
                <br/><br/>
                <div id="modaloutalerteditorcontract"></div>
                <div id="backdropoutalerteditorcontract"></div>
            </FormScrollerWidgetSm>
        </DivContainerSlide>
    )
}

export default CreateContractEditor