import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../components.css';
//
import imageaccount from '../../carions-icon-192.png';
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,
    ReducedFormScrollerWidgetSm, 
    BigButton,
    AdminButton,
    BackStart,  
} from '../../global/Styles';

function CrudAccueil() {
    const {t} = useTranslation();

    const navigateCrudAccueil = useNavigate();

    const accueilCreateHandler = async (e) => {
        e.preventDefault();

        navigateCrudAccueil('/accueilcreatecontenu');
        navigateCrudAccueil('/myeditor');
    }

    const accueilUpdateHandler = async (e) => {
        e.preventDefault();

        navigateCrudAccueil('/accueileditcontenu');
        navigateCrudAccueil('/editmyeditor');
    }

    const accueilDeleteHandler = async (e) => {
        e.preventDefault();

        navigateCrudAccueil('/accueildeletecontenu');
        navigateCrudAccueil('/deletemyeditor');
    }

    const accueilvisualiserHandler = async (e) => {
        e.preventDefault();

        navigateCrudAccueil('/accueilviewcontenu');
        navigateCrudAccueil('/viewmyeditor');
    }

    const crudAccueilAdminHandler = async (e) => {
        e.preventDefault();

        navigateCrudAccueil('/mainadmin');
    }

    const goBackcrudaccueilHandler = async (e) => {
        e.preventDefault();

        navigateCrudAccueil('/');
    }

    return ( 
        <DivContainerSlide id="crudaccueil">
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t("crudaccueiltitre")}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <img src={imageaccount} alt="" />
            </div>
            <ReducedFormScrollerWidgetSm>
                <div className="crudaccueilstyle">
                    <div>
                        <div className="largebuttonstyle">
                            <BigButton onClick={accueilCreateHandler}>{t("crudcreercontenu")}</BigButton>
                        </div>
                        <div className="largebuttonstyle">
                            <BigButton onClick={accueilUpdateHandler}>{t("crudcontenumodifier")}</BigButton>
                        </div>
                        <div className="largebuttonstyle">
                            <BigButton onClick={accueilDeleteHandler}>{t("crudaccueilsupprimer")}</BigButton>
                        </div>
                        <div className="largebuttonstyle">
                            <BigButton onClick={accueilvisualiserHandler}>{t("crudaccueilvisualiser")}</BigButton>
                        </div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={crudAccueilAdminHandler}>Admin Dashboard</AdminButton>
                        </div>
                    </div>
                    <br/>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                        <div>
                            <BackStart onClick={goBackcrudaccueilHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </div>
                    <br/>
                </div>
            </ReducedFormScrollerWidgetSm>
        </DivContainerSlide>
    );
}

export default CrudAccueil;