import React from "react-dom";
import { useNavigate } from "react-router-dom";
//build tabs tools
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
//
import { AlertModal } from '../../../helperfunctions/Helper';
//
import '../services.css';
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import { HiOutlineClock } from 'react-icons/hi';
import { TbRefresh } from 'react-icons/tb';
//
import {
    SpanGlobalSpan,
    GlobalStrongTitle,
    TabsForm, 
    DivTabRowAround,
    DivTabRowBetween,
    PTab10,
    DivTabColumnAround,
    PTab10Bt0,
    BackStart,  
    HandlerContainerDiv,
} from '../../../global/Styles';

import { useState } from "react";
import Axios from 'axios';

function KeywordTab() {
    const {t} = useTranslation();

    const navigateKeywordTab = useNavigate();

    //alert
    const elementOutId = "modaloutalertkeywordtab";
    const elementDropId = "backdropoutalertkeywordtab";
    const buttonHandler = "ouiHandlerAlertKeywordTabBtn";

    const [keywordTabBasic, getKeywordTabBasic] = useState([0]);

    var resultatappbasic = "";

    //http://localhost:5000
    //https://micarionsapi.carions.net

    const keywordBasicHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'basic';
        //show the tab form paragraphs and panel
        const keywordparagraphDiv = document.getElementById('keywordparagraph');
        const keywordpanelDiv = document.getElementById('keywordpanel');
        //
        await Axios.post("https://micarionsapi.carions.net/seo/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getKeywordTabBasic(responseappbasic.data);
                //
                keywordparagraphDiv.style.display = 'block';
                keywordpanelDiv.style.display = 'block';
            }
        });  
    }

    const keywordStandardHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'standard';
        //
        await Axios.post("https://micarionsapi.carions.net/seo/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getKeywordTabBasic(responseappbasic.data);
            }
        });  
    }

    const keywordPremiumHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'premium';
        //
        await Axios.post("https://micarionsapi.carions.net/seo/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getKeywordTabBasic(responseappbasic.data);
            }
        });  
    }

    //keyword proceed processes
    const keybasicHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'basic';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateKeywordTab('/connectaccount');
            }else{
                navigateKeywordTab('/proceedrecherche');
            }
        }
    }
    //
    const keystandardHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'standard';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateKeywordTab('/connectaccount');
            }else{
                navigateKeywordTab('/proceedrecherche');
            }
        }
    }
    //
    const keypremiumHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'premium';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateKeywordTab('/connectaccount');
            }else{
                navigateKeywordTab('/proceedrecherche');
            }
        }
    }

    //keyword contact
    const keycontactHandler = async (e) => {
        e.preventDefault();

        navigateKeywordTab('/contactme');
    }
    //******************************** */

    const goBackKeywordTabHandler = async (e) => {
        e.preventDefault();

        navigateKeywordTab('/mainseoservices');
    }

    return ( 
        <div id="keywordtab">
            <br/>
            <div style={{marginTop:'-2rem'}}>
                <SpanGlobalSpan style={{textAlign:'center'}}>
                    <GlobalStrongTitle>{t("keyword_research")}</GlobalStrongTitle>
                </SpanGlobalSpan>
            </div>
            <br/>
            <TabsForm>
                {keywordTabBasic.map((valkeywordbasic, indexkeywordbasic) => (
                    <div key={indexkeywordbasic} className="tabstyles">
                        <div id="keywordparagraph">
                            <PTab10>
                                {valkeywordbasic.services_paragraphone}<br/>
                                {valkeywordbasic.services_paragraphtwo}<br/>
                                {valkeywordbasic.services_paragraphthree}<br/>
                                {valkeywordbasic.services_paragraphfour}<br/>
                            </PTab10>
                        </div>
                        <Tabs defaultIndex={0}>
                            <TabList>
                                <Tab onClick={keywordBasicHandler}>{t("basic_plan")}</Tab>
                                <Tab onClick={keywordStandardHandler}>Standard</Tab>
                                <Tab onClick={keywordPremiumHandler}>Premium</Tab>
                            </TabList>
                            <div id="keywordpanel">
                                <TabPanel >
                                    <DivTabRowBetween>
                                        <h3>{valkeywordbasic.services_titre}</h3><h3>{valkeywordbasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valkeywordbasic.services_description} 
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valkeywordbasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valkeywordbasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valkeywordbasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valkeywordbasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valkeywordbasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valkeywordbasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valkeywordbasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={keybasicHandler} className="btnTab btn-proceed">{t("proceedbtn")}</button>
                                        <button onClick={keycontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                                <TabPanel>
                                    <DivTabRowBetween>
                                        <h3>{valkeywordbasic.services_titre}</h3><h3>{valkeywordbasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valkeywordbasic.services_description}
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valkeywordbasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valkeywordbasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valkeywordbasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valkeywordbasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valkeywordbasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valkeywordbasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valkeywordbasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={keystandardHandler} className="btnTab btn-proceed">{t("proceedbtn")}</button>
                                        <button onClick={keycontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                                <TabPanel>
                                    <DivTabRowBetween>
                                        <h3>{valkeywordbasic.services_titre}</h3><h3>{valkeywordbasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valkeywordbasic.services_description}
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valkeywordbasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valkeywordbasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valkeywordbasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valkeywordbasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valkeywordbasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valkeywordbasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valkeywordbasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={keypremiumHandler} className="btnTab btn-proceed">{t("proceedbtn")}</button>
                                        <button onClick={keycontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                ))}
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackKeywordTabHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertkeywordtab"></div>
                <div id="backdropoutalertkeywordtab"></div>
            </TabsForm>
        </div>
    );
}

export default KeywordTab;