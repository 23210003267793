import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../proceedweb.css';
import { useRef, useState } from "react";
//
import { MdOutlineCreate, MdSettingsBackupRestore, MdOutlineArrowForwardIos, MdOutlineArrowBackIos, MdOutlineAttachEmail, } from 'react-icons/md';
import { AiOutlineWhatsApp } from 'react-icons/ai';
//
import { AlertModal, getCurrentDate, PopupModal } from "../../helperfunctions/Helper";
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
import { 
    SpanGlobalSpan,
    GlobalStrongTitle,
    LongFormScrollerWidgetSm,
    TextProceedService, 
    TextLoginService,
    LargeSelect,
    GlobalLargeInput, 
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
    TextArea,
} from "../../global/Styles";

import Axios from 'axios';

function ProceedMiscel() {
    const {t} = useTranslation();

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleMiscelChange = event => {
        if (!isValidEmail(event.target.value)) {
          setError(t('invalidemail'));
        } else {
          setError(null);
        }
    
        setEmail(event.target.value);
    };

    const navigateProceedMiscel = useNavigate();

    //alert
    const elementOutId = "modaloutproceedmiscel";
    const elementDropId = "backdropoutproceedmiscel";
    const buttonHandler = "ouiHandlerProceedMiscelBtn";
    //
    const elementOutAlertId = "modaloutalertproceedmiscel";
    const elementDropAlertId = "backdropoutalertproceedmiscel";
    const buttonAlertHandler = "ouiHandleralertProceedMiscelBtn";

    const miscelQuestion1Ref = useRef(null);
    const miscelQuestion2Ref = useRef(null);
    const miscelQuestion3Ref = useRef(null);
    //
    const miscelQuestion4Ref = useRef(null);
    const miscelQuestion5Ref = useRef(null);
    const miscelQuestion6Ref = useRef(null);
    //
    const miscelQuestion7Ref = useRef(null);
    const miscelQuestion8Ref = useRef(null);
    const miscelQuestion9Ref = useRef(null);
    //
    const miscelQuestion10Ref = useRef(null);
    const miscelQuestion11Ref = useRef(null);
    const miscelQuestion12Ref = useRef(null);
    //
    const miscelQuestion13Ref = useRef(null);
    const miscelQuestion14Ref = useRef(null);

    const popmiscel1Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("miscelquestion_1");
        const pTexteNotAvailable = t("hintmiscelquestion_1");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmiscel2Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("miscelquestion_2");
        const pTexteNotAvailable = t("hintmiscelquestion_2");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmiscel3Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("miscelquestion_3");
        const pTexteNotAvailable = t("hintmiscelquestion_3");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    /** */
    const forwardProceedMiscel1Handler = async (e) => {
        e.preventDefault();

        const miscelsteponeDiv = document.getElementById('miscelstepone');
        const miscelsteptwoDiv = document.getElementById('miscelsteptwo');
        const miscelstepthreeDiv = document.getElementById('miscelstepthree');
        const miscelstepfourDiv = document.getElementById('miscelstepfour');
        const modeproceedmiscelDiv = document.getElementById('modeproceedmiscel');
        const submitproceedmiscelDiv = document.getElementById('submitproceedmiscel');
        //
        miscelsteponeDiv.style.display = 'none';
        miscelsteptwoDiv.style.display = 'block';
        miscelstepthreeDiv.style.display = 'none';
        miscelstepfourDiv.style.display = 'none';
        modeproceedmiscelDiv.style.display = 'none';
        submitproceedmiscelDiv.style.display = 'none';
    }

    const popmiscel4Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("miscelquestion_4");
        const pTexteNotAvailable = t("hintmiscelquestion_4");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmiscel5Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("miscelquestion_5");
        const pTexteNotAvailable = t("hintmiscelquestion_5");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    const popmiscel6Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("miscelquestion_6");
        const pTexteNotAvailable = t("hintmiscelquestion_6");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    /** */
    const goBackProceedMiscel2Handler = async (e) => {
        e.preventDefault();

        const miscelsteponeDiv = document.getElementById('miscelstepone');
        const miscelsteptwoDiv = document.getElementById('miscelsteptwo');
        const miscelstepthreeDiv = document.getElementById('miscelstepthree');
        const miscelstepfourDiv = document.getElementById('miscelstepfour');
        const modeproceedmiscelDiv = document.getElementById('modeproceedmiscel');
        const submitproceedmiscelDiv = document.getElementById('submitproceedmiscel');
        //
        miscelsteponeDiv.style.display = 'block';
        miscelsteptwoDiv.style.display = 'none';
        miscelstepthreeDiv.style.display = 'none';
        miscelstepfourDiv.style.display = 'none';
        modeproceedmiscelDiv.style.display = 'none';
        submitproceedmiscelDiv.style.display = 'none';
    }
    //
    const forwardProceedMiscel2Handler = async (e) => {
        e.preventDefault();

        const miscelsteponeDiv = document.getElementById('miscelstepone');
        const miscelsteptwoDiv = document.getElementById('miscelsteptwo');
        const miscelstepthreeDiv = document.getElementById('miscelstepthree');
        const miscelstepfourDiv = document.getElementById('miscelstepfour');
        const modeproceedmiscelDiv = document.getElementById('modeproceedmiscel');
        const submitproceedmiscelDiv = document.getElementById('submitproceedmiscel');
        //
        miscelsteponeDiv.style.display = 'none';
        miscelsteptwoDiv.style.display = 'none';
        miscelstepthreeDiv.style.display = 'block';
        miscelstepfourDiv.style.display = 'none';
        modeproceedmiscelDiv.style.display = 'none';
        submitproceedmiscelDiv.style.display = 'none';
    }

    const popmiscel7Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("miscelquestion_7");
        const pTexteNotAvailable = t("hintmiscelquestion_7");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmiscel8Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("miscelquestion_8");
        const pTexteNotAvailable = t("hintmiscelquestion_8");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmiscel9Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("miscelquestion_9");
        const pTexteNotAvailable = t("hintmiscelquestion_9");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    /** */
    const goBackProceedMiscel3Handler = async (e) => {
        e.preventDefault();

        const miscelsteponeDiv = document.getElementById('miscelstepone');
        const miscelsteptwoDiv = document.getElementById('miscelsteptwo');
        const miscelstepthreeDiv = document.getElementById('miscelstepthree');
        const miscelstepfourDiv = document.getElementById('miscelstepfour');
        const modeproceedmiscelDiv = document.getElementById('modeproceedmiscel');
        const submitproceedmiscelDiv = document.getElementById('submitproceedmiscel');
        //
        miscelsteponeDiv.style.display = 'none';
        miscelsteptwoDiv.style.display = 'block';
        miscelstepthreeDiv.style.display = 'none';
        miscelstepfourDiv.style.display = 'none';
        modeproceedmiscelDiv.style.display = 'none';
        submitproceedmiscelDiv.style.display = 'none';
    }
    //
    const forwardProceedMiscel3Handler = async (e) => {
        e.preventDefault();

        const miscelsteponeDiv = document.getElementById('miscelstepone');
        const miscelsteptwoDiv = document.getElementById('miscelsteptwo');
        const miscelstepthreeDiv = document.getElementById('miscelstepthree');
        const miscelstepfourDiv = document.getElementById('miscelstepfour');
        const modeproceedmiscelDiv = document.getElementById('modeproceedmiscel');
        const submitproceedmiscelDiv = document.getElementById('submitproceedmiscel');
        //
        miscelsteponeDiv.style.display = 'none';
        miscelsteptwoDiv.style.display = 'none';
        miscelstepthreeDiv.style.display = 'none';
        miscelstepfourDiv.style.display = 'block';
        modeproceedmiscelDiv.style.display = 'none';
        submitproceedmiscelDiv.style.display = 'none';
    }

    const popmiscel10Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("miscelquestion_10");
        const pTexteNotAvailable = t("hintmiscelquestion_10");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmiscel11Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("miscelquestion_11");
        const pTexteNotAvailable = t("hintmiscelquestion_11");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmiscel12Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("miscelquestion_12");
        const pTexteNotAvailable = t("hintmiscelquestion_12");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    /** */
    const goBackProceedMiscel4Handler = async (e) => {
        e.preventDefault();

        const miscelsteponeDiv = document.getElementById('miscelstepone');
        const miscelsteptwoDiv = document.getElementById('miscelsteptwo');
        const miscelstepthreeDiv = document.getElementById('miscelstepthree');
        const miscelstepfourDiv = document.getElementById('miscelstepfour');
        const modeproceedmiscelDiv = document.getElementById('modeproceedmiscel');
        const submitproceedmiscelDiv = document.getElementById('submitproceedmiscel');
        //
        miscelsteponeDiv.style.display = 'none';
        miscelsteptwoDiv.style.display = 'none';
        miscelstepthreeDiv.style.display = 'block';
        miscelstepfourDiv.style.display = 'none';
        modeproceedmiscelDiv.style.display = 'none';
        submitproceedmiscelDiv.style.display = 'none';
    }
    //
    const forwardProceedMiscel4Handler = async (e) => {
        e.preventDefault();

        const miscelsteponeDiv = document.getElementById('miscelstepone');
        const miscelsteptwoDiv = document.getElementById('miscelsteptwo');
        const miscelstepthreeDiv = document.getElementById('miscelstepthree');
        const miscelstepfourDiv = document.getElementById('miscelstepfour');
        const modeproceedmiscelDiv = document.getElementById('modeproceedmiscel');
        const submitproceedmiscelDiv = document.getElementById('submitproceedmiscel');
        //
        miscelsteponeDiv.style.display = 'none';
        miscelsteptwoDiv.style.display = 'none';
        miscelstepthreeDiv.style.display = 'none';
        miscelstepfourDiv.style.display = 'none';
        modeproceedmiscelDiv.style.display = 'block';
        submitproceedmiscelDiv.style.display = 'none';
    }

    const goBackProceedMiscel5Handler = async (e) => {
        e.preventDefault();

        const miscelsteponeDiv = document.getElementById('miscelstepone');
        const miscelsteptwoDiv = document.getElementById('miscelsteptwo');
        const miscelstepthreeDiv = document.getElementById('miscelstepthree');
        const miscelstepfourDiv = document.getElementById('miscelstepfour');
        const modeproceedmiscelDiv = document.getElementById('modeproceedmiscel');
        const submitproceedmiscelDiv = document.getElementById('submitproceedmiscel');
        //
        miscelsteponeDiv.style.display = 'none';
        miscelsteptwoDiv.style.display = 'none';
        miscelstepthreeDiv.style.display = 'none';
        miscelstepfourDiv.style.display = 'block';
        modeproceedmiscelDiv.style.display = 'none';
        submitproceedmiscelDiv.style.display = 'none';
    }
    //
    const forwardProceedMiscel5Handler = async (e) => {
        e.preventDefault();

        const miscelQuestion13 = miscelQuestion13Ref.current.value;
        const miscelQuestion14 = miscelQuestion14Ref.current.value;
        //
        const miscelsteponeDiv = document.getElementById('miscelstepone');
        const miscelsteptwoDiv = document.getElementById('miscelsteptwo');
        const miscelstepthreeDiv = document.getElementById('miscelstepthree');
        const miscelstepfourDiv = document.getElementById('miscelstepfour');
        const modeproceedmiscelDiv = document.getElementById('modeproceedmiscel');
        const submitproceedmiscelDiv = document.getElementById('submitproceedmiscel');
        //
        if(!miscelQuestion13){
            const pTexteSaisir = t("enteremail");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else if(!miscelQuestion14){
            const pTexteSaisir = t("enterphone");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else{
            //
            miscelsteponeDiv.style.display = 'none';
            miscelsteptwoDiv.style.display = 'none';
            miscelstepthreeDiv.style.display = 'none';
            miscelstepfourDiv.style.display = 'none';
            modeproceedmiscelDiv.style.display = 'none';
            submitproceedmiscelDiv.style.display = 'block';
        }
    }

    const goBackProceedMiscel6Handler = async (e) => {
        e.preventDefault();

        const miscelsteponeDiv = document.getElementById('miscelstepone');
        const miscelsteptwoDiv = document.getElementById('miscelsteptwo');
        const miscelstepthreeDiv = document.getElementById('miscelstepthree');
        const miscelstepfourDiv = document.getElementById('miscelstepfour');
        const modeproceedmiscelDiv = document.getElementById('modeproceedmiscel');
        const submitproceedmiscelDiv = document.getElementById('submitproceedmiscel');
        //
        miscelsteponeDiv.style.display = 'none';
        miscelsteptwoDiv.style.display = 'none';
        miscelstepthreeDiv.style.display = 'none';
        miscelstepfourDiv.style.display = 'none';
        modeproceedmiscelDiv.style.display = 'block';
        submitproceedmiscelDiv.style.display = 'none';
    }

    //http://localhost:5000/check/miscelservice

    const submitProceedMiscelHandler = async (e) => {
        e.preventDefault();

        const miscelQuestion1 = miscelQuestion1Ref.current.value;
        const miscelQuestion2 = miscelQuestion2Ref.current.value;
        const miscelQuestion3 = miscelQuestion3Ref.current.value;
        //websteptwo
        const miscelQuestion4 = miscelQuestion4Ref.current.value;
        const miscelQuestion5 = miscelQuestion5Ref.current.value;
        const miscelQuestion6 = miscelQuestion6Ref.current.value;
        //webstepthree
        const miscelQuestion7 = miscelQuestion7Ref.current.value;
        const miscelQuestion8 = miscelQuestion8Ref.current.value;
        const miscelQuestion9 = miscelQuestion9Ref.current.value;
        //webstepfour
        const miscelQuestion10 = miscelQuestion10Ref.current.value;
        const miscelQuestion11 = miscelQuestion11Ref.current.value;
        const miscelQuestion12 = miscelQuestion12Ref.current.value;
        //modeproceedweb
        const miscelQuestion13 = miscelQuestion13Ref.current.value;
        const miscelQuestion14 = miscelQuestion14Ref.current.value;
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const theDate = getCurrentDate();
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = "miscellanious";
        const servicePlan = document.getElementById('typeplan').innerText;
        var createTicketCode = "";
        //
        var resultat1 = "";
        //
        //generate random ticket code
        var randomNumber = Math.floor((Math.random() * 100000) + 1);
        createTicketCode = randomNumber+''+codeutilisateurDiv;
        //
        await Axios.post("https://micarionsapi.carions.net/check/miscelservice", {
            codeutilisateurDiv:codeutilisateurDiv,
            typeService:typeService,
            servicePlan:servicePlan,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                //insert
                Axios.post("https://micarionsapi.carions.net/quest/miscelservice", {
                    choixlangueDiv:choixlangueDiv,
                    usernamedetailDiv:usernamedetailDiv,
                    codeutilisateurDiv:codeutilisateurDiv,
                    //
                    typeService:typeService,
                    servicePlan:servicePlan,
                    //
                    createTicketCode:createTicketCode,
                    //
                    miscelQuestion1:miscelQuestion1,
                    miscelQuestion2:miscelQuestion2,
                    miscelQuestion3:miscelQuestion3,
                    //
                    miscelQuestion4:miscelQuestion4,
                    miscelQuestion5:miscelQuestion5,
                    miscelQuestion6:miscelQuestion6,
                    //
                    miscelQuestion7:miscelQuestion7,
                    miscelQuestion8:miscelQuestion8,
                    miscelQuestion9:miscelQuestion9,
                    //
                    miscelQuestion10:miscelQuestion10,
                    miscelQuestion11:miscelQuestion11,
                    miscelQuestion12:miscelQuestion12,
                    //
                    miscelQuestion13:miscelQuestion13,
                    miscelQuestion14:miscelQuestion14,
                    //
                    theDate:theDate,
                }).then(() => {
                    var answer1 = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                    const hTitleTexte = t("software_miscellanious");
                    const pTexteNotAvailable = answer1;
                    PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
                });
            }else{
                //set up modal to update
                var answer = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                const hTitleTexte = t("software_miscellanious");
                const pTexteNotAvailable = answer;
                PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
            }
        });
    }

    const goBackProceedMiscelHandler = async (e) => {
        e.preventDefault();

        navigateProceedMiscel('/softmiscellaniousservicestab');
    }

    return ( 
        <div id="proceedmiscel">
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t('software_miscellanious')}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <LongFormScrollerWidgetSm>
                <div id="modaloutproceedmiscel"></div>
                <div id="backdropoutproceedmiscel"></div>
                <p className="pservicestyles"><br/>{t("proceedmisceldescription")}</p>
                <div id="miscelstepone">
                    <TextProceedService onClick={popmiscel1Handler}>{t("miscelquestion_1")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <LargeSelect ref={miscelQuestion1Ref}>
                            <option value=''>{t("typetask")}</option>
                            <option value='reacttask'>{t("react_task")}</option>
                            <option value='htmltask'>{t("html_task")}</option>
                            <option value='csstask'>{t("css_task")}</option>
                            <option value='databasetask'>{t("database_task")}</option>
                            <option value='apitask'>{t("api_task")}</option>
                            <option value='functiontask'>{t("function_task")}</option>
                            <option value='algorithmtask'>{t("algorithm_task")}</option>
                            <option value='othertask'>{t("other_task")}</option>
                        </LargeSelect>
                    </div>
                    <TextProceedService onClick={popmiscel2Handler}>{t("miscelquestion_2")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={miscelQuestion2Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmiscel3Handler}>{t("miscelquestion_3")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={miscelQuestion3Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <ButtonStart onClick={forwardProceedMiscel1Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="miscelsteptwo">
                    <TextProceedService onClick={popmiscel4Handler}>{t("miscelquestion_4")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={miscelQuestion4Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmiscel5Handler}>{t("miscelquestion_5")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={miscelQuestion5Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmiscel6Handler}>{t("miscelquestion_6")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={miscelQuestion6Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedMiscel2Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedMiscel2Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="miscelstepthree">
                    <TextProceedService onClick={popmiscel7Handler}>{t("miscelquestion_7")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={miscelQuestion7Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmiscel8Handler}>{t("miscelquestion_8")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={miscelQuestion8Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmiscel9Handler}>{t("miscelquestion_9")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={miscelQuestion9Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedMiscel3Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedMiscel3Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="miscelstepfour">
                    <TextProceedService onClick={popmiscel10Handler}>{t("miscelquestion_10")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={miscelQuestion10Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmiscel11Handler}>{t("miscelquestion_11")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={miscelQuestion11Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmiscel12Handler}>{t("miscelquestion_12")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={miscelQuestion12Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedMiscel4Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedMiscel4Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="modeproceedmiscel">
                    <TextProceedService>{t("miscelquestion_13")}</TextProceedService>
                    <TextLoginService><MdOutlineAttachEmail style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput id="emailmiscel" name="emailmiscel" value={email} onChange={handleMiscelChange} ref={miscelQuestion13Ref} placeholder={t("placemail")}/>
                        {error && <h5 style={{color: 'white'}}>{error}</h5>}
                    </div>
                    <br/>
                    <TextLoginService><AiOutlineWhatsApp style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput type='number' ref={miscelQuestion14Ref}  placeholder={t("placewhatsapp")}/>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedMiscel5Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedMiscel5Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="submitproceedmiscel">
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedMiscel6Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={submitProceedMiscelHandler}>
                                    <MdOutlineCreate style={{fontSize:'30px'}} />
                                   {t("creer")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackProceedMiscelHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertproceedmiscel"></div>
                <div id="backdropoutalertproceedmiscel"></div>
            </LongFormScrollerWidgetSm>
        </div>
    );
}

export default ProceedMiscel;