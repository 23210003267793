import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../proceedweb.css';
import { useRef, useState } from "react";
//
import { MdOutlineCreate, MdSettingsBackupRestore, MdOutlineArrowForwardIos, MdOutlineArrowBackIos, MdOutlineAttachEmail } from 'react-icons/md';
import { AiOutlineWhatsApp } from 'react-icons/ai';
//
import { AlertModal, getCurrentDate, PopupModal } from "../../helperfunctions/Helper";
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
import {  
    SpanGlobalSpan,
    GlobalStrongTitle,
    LongFormScrollerWidgetSm,
    TextProceedService, 
    TextLoginService,
    GlobalLargeInput, 
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
    TextArea,
} from "../../global/Styles";

import Axios from 'axios';

function ProceedMaint() {
    const {t} = useTranslation();

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleMaintChange = event => {
        if (!isValidEmail(event.target.value)) {
          setError(t('invalidemail'));
        } else {
          setError(null);
        }
    
        setEmail(event.target.value);
    };

    const navigateProceedMaint = useNavigate();

    //alert
    const elementOutId = "modaloutproceedmaint";
    const elementDropId = "backdropoutproceedmaint";
    const buttonHandler = "ouiHandlerProceedMaintBtn";
    //
    const elementOutAlertId = "modaloutalertproceedmaint";
    const elementDropAlertId = "backdropoutalertproceedmaint";
    const buttonAlertHandler = "ouiHandleralertProceedMaintBtn";

    const maintQuestion1Ref = useRef(null);
    const maintQuestion2Ref = useRef(null);
    const maintQuestion3Ref = useRef(null);
    //
    const maintQuestion4Ref = useRef(null);
    const maintQuestion5Ref = useRef(null);
    const maintQuestion6Ref = useRef(null);
    //
    const maintQuestion7Ref = useRef(null);
    const maintQuestion8Ref = useRef(null);
    const maintQuestion9Ref = useRef(null);
    //
    const maintQuestion10Ref = useRef(null);
    const maintQuestion11Ref = useRef(null);
    const maintQuestion12Ref = useRef(null);
    //
    const maintQuestion13Ref = useRef(null);
    const maintQuestion14Ref = useRef(null);

    const popmaint1Handler = async (e) => {
        e.preventDefault();
        
        const hTitleTexte = t("maintquestion_1");
        const pTexteNotAvailable = t("hintmaintquestion_1");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmaint2Handler = async (e) => {
        e.preventDefault();
        
        const hTitleTexte = t("maintquestion_2");
        const pTexteNotAvailable = t("hintmaintquestion_2");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmaint3Handler = async (e) => {
        e.preventDefault();
        
        const hTitleTexte = t("maintquestion_3");
        const pTexteNotAvailable = t("hintmaintquestion_3");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    /** */
    const forwardProceedMaint1Handler = async (e) => {
        e.preventDefault();

        const maintsteponeDiv = document.getElementById('maintstepone');
        const maintsteptwoDiv = document.getElementById('maintsteptwo');
        const maintstepthreeDiv = document.getElementById('maintstepthree');
        const maintstepfourDiv = document.getElementById('maintstepfour');
        const modeproceedmaintDiv = document.getElementById('modeproceedmaint');
        const submitproceedmaintDiv = document.getElementById('submitproceedmaint');
        //
        maintsteponeDiv.style.display = 'none';
        maintsteptwoDiv.style.display = 'block';
        maintstepthreeDiv.style.display = 'none';
        maintstepfourDiv.style.display = 'none';
        modeproceedmaintDiv.style.display = 'none';
        submitproceedmaintDiv.style.display = 'none';
    }

    const popmaint4Handler = async (e) => {
        e.preventDefault();
        
        const hTitleTexte = t("maintquestion_4");
        const pTexteNotAvailable = t("hintmaintquestion_4");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmaint5Handler = async (e) => {
        e.preventDefault();
        
        const hTitleTexte = t("maintquestion_5");
        const pTexteNotAvailable = t("hintmaintquestion_5");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmaint6Handler = async (e) => {
        e.preventDefault();
        
        const hTitleTexte = t("maintquestion_6");
        const pTexteNotAvailable = t("hintmaintquestion_6");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    /** */
    const goBackProceedMaint2Handler = async (e) => {
        e.preventDefault();

        const maintsteponeDiv = document.getElementById('maintstepone');
        const maintsteptwoDiv = document.getElementById('maintsteptwo');
        const maintstepthreeDiv = document.getElementById('maintstepthree');
        const maintstepfourDiv = document.getElementById('maintstepfour');
        const modeproceedmaintDiv = document.getElementById('modeproceedmaint');
        const submitproceedmaintDiv = document.getElementById('submitproceedmaint');
        //
        maintsteponeDiv.style.display = 'block';
        maintsteptwoDiv.style.display = 'none';
        maintstepthreeDiv.style.display = 'none';
        maintstepfourDiv.style.display = 'none';
        modeproceedmaintDiv.style.display = 'none';
        submitproceedmaintDiv.style.display = 'none';
    }
    //
    const forwardProceedMaint2Handler = async (e) => {
        e.preventDefault();

        const maintsteponeDiv = document.getElementById('maintstepone');
        const maintsteptwoDiv = document.getElementById('maintsteptwo');
        const maintstepthreeDiv = document.getElementById('maintstepthree');
        const maintstepfourDiv = document.getElementById('maintstepfour');
        const modeproceedmaintDiv = document.getElementById('modeproceedmaint');
        const submitproceedmaintDiv = document.getElementById('submitproceedmaint');
        //
        maintsteponeDiv.style.display = 'none';
        maintsteptwoDiv.style.display = 'none';
        maintstepthreeDiv.style.display = 'block';
        maintstepfourDiv.style.display = 'none';
        modeproceedmaintDiv.style.display = 'none';
        submitproceedmaintDiv.style.display = 'none';
    }

    const popmaint7Handler = async (e) => {
        e.preventDefault();
        
        const hTitleTexte = t("maintquestion_7");
        const pTexteNotAvailable = t("hintmaintquestion_7");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmaint8Handler = async (e) => {
        e.preventDefault();
        
        const hTitleTexte = t("maintquestion_8");
        const pTexteNotAvailable = t("hintmaintquestion_8");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmaint9Handler = async (e) => {
        e.preventDefault();
        
        const hTitleTexte = t("maintquestion_9");
        const pTexteNotAvailable = t("hintmaintquestion_9");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    /** */
    const goBackProceedMaint3Handler = async (e) => {
        e.preventDefault();

        const maintsteponeDiv = document.getElementById('maintstepone');
        const maintsteptwoDiv = document.getElementById('maintsteptwo');
        const maintstepthreeDiv = document.getElementById('maintstepthree');
        const maintstepfourDiv = document.getElementById('maintstepfour');
        const modeproceedmaintDiv = document.getElementById('modeproceedmaint');
        const submitproceedmaintDiv = document.getElementById('submitproceedmaint');
        //
        maintsteponeDiv.style.display = 'none';
        maintsteptwoDiv.style.display = 'block';
        maintstepthreeDiv.style.display = 'none';
        maintstepfourDiv.style.display = 'none';
        modeproceedmaintDiv.style.display = 'none';
        submitproceedmaintDiv.style.display = 'none';
    }
    //
    const forwardProceedMaint3Handler = async (e) => {
        e.preventDefault();

        const maintsteponeDiv = document.getElementById('maintstepone');
        const maintsteptwoDiv = document.getElementById('maintsteptwo');
        const maintstepthreeDiv = document.getElementById('maintstepthree');
        const maintstepfourDiv = document.getElementById('maintstepfour');
        const modeproceedmaintDiv = document.getElementById('modeproceedmaint');
        const submitproceedmaintDiv = document.getElementById('submitproceedmaint');
        //
        maintsteponeDiv.style.display = 'none';
        maintsteptwoDiv.style.display = 'none';
        maintstepthreeDiv.style.display = 'none';
        maintstepfourDiv.style.display = 'block';
        modeproceedmaintDiv.style.display = 'none';
        submitproceedmaintDiv.style.display = 'none';
    }

    const popmaint10Handler = async (e) => {
        e.preventDefault();
        
        const hTitleTexte = t("maintquestion_10");
        const pTexteNotAvailable = t("hintmaintquestion_10");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmaint11Handler = async (e) => {
        e.preventDefault();
        
        const hTitleTexte = t("maintquestion_11");
        const pTexteNotAvailable = t("hintmaintquestion_11");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmaint12Handler = async (e) => {
        e.preventDefault();
        
        const hTitleTexte = t("maintquestion_12");
        const pTexteNotAvailable = t("hintmaintquestion_12");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    /** */
    const goBackProceedMaint4Handler = async (e) => {
        e.preventDefault();

        const maintsteponeDiv = document.getElementById('maintstepone');
        const maintsteptwoDiv = document.getElementById('maintsteptwo');
        const maintstepthreeDiv = document.getElementById('maintstepthree');
        const maintstepfourDiv = document.getElementById('maintstepfour');
        const modeproceedmaintDiv = document.getElementById('modeproceedmaint');
        const submitproceedmaintDiv = document.getElementById('submitproceedmaint');
        //
        maintsteponeDiv.style.display = 'none';
        maintsteptwoDiv.style.display = 'none';
        maintstepthreeDiv.style.display = 'block';
        maintstepfourDiv.style.display = 'none';
        modeproceedmaintDiv.style.display = 'none';
        submitproceedmaintDiv.style.display = 'none';
    }
    //
    const forwardProceedMaint4Handler = async (e) => {
        e.preventDefault();

        const maintsteponeDiv = document.getElementById('maintstepone');
        const maintsteptwoDiv = document.getElementById('maintsteptwo');
        const maintstepthreeDiv = document.getElementById('maintstepthree');
        const maintstepfourDiv = document.getElementById('maintstepfour');
        const modeproceedmaintDiv = document.getElementById('modeproceedmaint');
        const submitproceedmaintDiv = document.getElementById('submitproceedmaint');
        //
        maintsteponeDiv.style.display = 'none';
        maintsteptwoDiv.style.display = 'none';
        maintstepthreeDiv.style.display = 'none';
        maintstepfourDiv.style.display = 'none';
        modeproceedmaintDiv.style.display = 'block';
        submitproceedmaintDiv.style.display = 'none';
    }

    const goBackProceedMaint5Handler = async (e) => {
        e.preventDefault();

        const maintsteponeDiv = document.getElementById('maintstepone');
        const maintsteptwoDiv = document.getElementById('maintsteptwo');
        const maintstepthreeDiv = document.getElementById('maintstepthree');
        const maintstepfourDiv = document.getElementById('maintstepfour');
        const modeproceedmaintDiv = document.getElementById('modeproceedmaint');
        const submitproceedmaintDiv = document.getElementById('submitproceedmaint');
        //
        maintsteponeDiv.style.display = 'none';
        maintsteptwoDiv.style.display = 'none';
        maintstepthreeDiv.style.display = 'none';
        maintstepfourDiv.style.display = 'block';
        modeproceedmaintDiv.style.display = 'none';
        submitproceedmaintDiv.style.display = 'none';
    }
    //
    const forwardProceedMaint5Handler = async (e) => {
        e.preventDefault();

        const maintQuestion13 = maintQuestion13Ref.current.value;
        const maintQuestion14 = maintQuestion14Ref.current.value;
        //
        const maintsteponeDiv = document.getElementById('maintstepone');
        const maintsteptwoDiv = document.getElementById('maintsteptwo');
        const maintstepthreeDiv = document.getElementById('maintstepthree');
        const maintstepfourDiv = document.getElementById('maintstepfour');
        const modeproceedmaintDiv = document.getElementById('modeproceedmaint');
        const submitproceedmaintDiv = document.getElementById('submitproceedmaint');
        //
        if(!maintQuestion13){
            const pTexteSaisir = t("enteremail");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else if(!maintQuestion14){
            const pTexteSaisir = t("enterphone");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else{
            //
            maintsteponeDiv.style.display = 'none';
            maintsteptwoDiv.style.display = 'none';
            maintstepthreeDiv.style.display = 'none';
            maintstepfourDiv.style.display = 'none';
            modeproceedmaintDiv.style.display = 'none';
            submitproceedmaintDiv.style.display = 'block';
        }
    }

    const goBackProceedMaint6Handler = async (e) => {
        e.preventDefault();

        const maintsteponeDiv = document.getElementById('maintstepone');
        const maintsteptwoDiv = document.getElementById('maintsteptwo');
        const maintstepthreeDiv = document.getElementById('maintstepthree');
        const maintstepfourDiv = document.getElementById('maintstepfour');
        const modeproceedmaintDiv = document.getElementById('modeproceedmaint');
        const submitproceedmaintDiv = document.getElementById('submitproceedmaint');
        //
        maintsteponeDiv.style.display = 'none';
        maintsteptwoDiv.style.display = 'none';
        maintstepthreeDiv.style.display = 'none';
        maintstepfourDiv.style.display = 'none';
        modeproceedmaintDiv.style.display = 'block';
        submitproceedmaintDiv.style.display = 'none';
    }

    //http://localhost:5000/check/maintservice

    const submitProceedMaintHandler = async (e) => {
        e.preventDefault();

        const maintQuestion1 = maintQuestion1Ref.current.value;
        const maintQuestion2 = maintQuestion2Ref.current.value;
        const maintQuestion3 = maintQuestion3Ref.current.value;
        //websteptwo
        const maintQuestion4 = maintQuestion4Ref.current.value;
        const maintQuestion5 = maintQuestion5Ref.current.value;
        const maintQuestion6 = maintQuestion6Ref.current.value;
        //webstepthree
        const maintQuestion7 = maintQuestion7Ref.current.value;
        const maintQuestion8 = maintQuestion8Ref.current.value;
        const maintQuestion9 = maintQuestion9Ref.current.value;
        //webstepfour
        const maintQuestion10 = maintQuestion10Ref.current.value;
        const maintQuestion11 = maintQuestion11Ref.current.value;
        const maintQuestion12 = maintQuestion12Ref.current.value;
        //modeproceedweb
        const maintQuestion13 = maintQuestion13Ref.current.value;
        const maintQuestion14 = maintQuestion14Ref.current.value;
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const theDate = getCurrentDate();
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = "maintenance";
        const servicePlan = document.getElementById('typeplan').innerText;
        var createTicketCode = "";
        //
        var resultat1 = "";
        //
        //generate random ticket code
        var randomNumber = Math.floor((Math.random() * 100000) + 1);
        createTicketCode = randomNumber+''+codeutilisateurDiv;
        //
        await Axios.post("https://micarionsapi.carions.net/check/maintservice", {
            codeutilisateurDiv:codeutilisateurDiv,
            typeService:typeService,
            servicePlan:servicePlan,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                //insert
                Axios.post("https://micarionsapi.carions.net/quest/maintservice", {
                    choixlangueDiv:choixlangueDiv,
                    usernamedetailDiv:usernamedetailDiv,
                    codeutilisateurDiv:codeutilisateurDiv,
                    //
                    typeService:typeService,
                    servicePlan:servicePlan,
                    //
                    createTicketCode:createTicketCode,
                    //
                    maintQuestion1:maintQuestion1,
                    maintQuestion2:maintQuestion2,
                    maintQuestion3:maintQuestion3,
                    //
                    maintQuestion4:maintQuestion4,
                    maintQuestion5:maintQuestion5,
                    maintQuestion6:maintQuestion6,
                    //
                    maintQuestion7:maintQuestion7,
                    maintQuestion8:maintQuestion8,
                    maintQuestion9:maintQuestion9,
                    //
                    maintQuestion10:maintQuestion10,
                    maintQuestion11:maintQuestion11,
                    maintQuestion12:maintQuestion12,
                    //
                    maintQuestion13:maintQuestion13,
                    maintQuestion14:maintQuestion14,
                    //
                    theDate:theDate,
                }).then(() => {
                    var answer1 = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                    const hTitleTexte = t("software_maintenance");
                    const pTexteNotAvailable = answer1;
                    PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
                });
            }else{
                //set up modal to update
                var answer = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                const hTitleTexte = t("software_maintenance");
                const pTexteNotAvailable = answer;
                PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
            }
        });
    }

    const goBackProceedMaintHandler = async (e) => {
        e.preventDefault();

        navigateProceedMaint('/softmaintenanceservicestab');
    }

    return ( 
        <div id="proceedmaint">
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t('software_maintenance')}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <LongFormScrollerWidgetSm>
                <div id="modaloutproceedmaint"></div>
                <div id="backdropoutproceedmaint"></div>
                <p className="pservicestyles"><br/>{t("proceedmaintdescription")}</p>
                <div id="maintstepone">
                    <TextProceedService onClick={popmaint1Handler}>{t("maintquestion_1")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={maintQuestion1Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmaint2Handler}>{t("maintquestion_2")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={maintQuestion2Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmaint3Handler}>{t("maintquestion_3")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={maintQuestion3Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <ButtonStart onClick={forwardProceedMaint1Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="maintsteptwo">
                    <TextProceedService onClick={popmaint4Handler}>{t("maintquestion_4")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={maintQuestion4Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmaint5Handler}>{t("maintquestion_5")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={maintQuestion5Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmaint6Handler}>{t("maintquestion_6")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={maintQuestion6Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedMaint2Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedMaint2Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="maintstepthree">
                    <TextProceedService onClick={popmaint7Handler}>{t("maintquestion_7")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={maintQuestion7Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmaint8Handler}>{t("maintquestion_8")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={maintQuestion8Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmaint9Handler}>{t("maintquestion_9")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={maintQuestion9Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedMaint3Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedMaint3Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="maintstepfour">
                    <TextProceedService onClick={popmaint10Handler}>{t("maintquestion_10")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={maintQuestion10Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmaint11Handler}>{t("maintquestion_11")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={maintQuestion11Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmaint12Handler}>{t("maintquestion_12")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={maintQuestion12Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedMaint4Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedMaint4Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="modeproceedmaint">
                    <TextProceedService>{t("maintquestion_13")}</TextProceedService>
                    <TextLoginService><MdOutlineAttachEmail style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput id="emailmaint" name="emailmaint" value={email} onChange={handleMaintChange} ref={maintQuestion13Ref} placeholder={t("placemail")}/>
                        {error && <h5 style={{color: 'white'}}>{error}</h5>}
                    </div>
                    <br/>
                    <TextLoginService><AiOutlineWhatsApp style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput type='number' ref={maintQuestion14Ref}  placeholder={t("placewhatsapp")}/>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedMaint5Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedMaint5Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="submitproceedmaint">
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedMaint6Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={submitProceedMaintHandler}>
                                    <MdOutlineCreate style={{fontSize:'30px'}} />
                                   {t("creer")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackProceedMaintHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertproceedmaint"></div>
                <div id="backdropoutalertproceedmaint"></div>
            </LongFormScrollerWidgetSm>
        </div>
    );
}

export default ProceedMaint;