import React from "react-dom";
import { useNavigate } from "react-router-dom";
import './myeditor.css';
//
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';

import { AlertModal } from "../../helperfunctions/Helper";

import { 
    TextLogin, 
    LargeSelect,
    GlobalLargeInput,
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
} from "../../global/Styles";

import { MdOutlineConfirmationNumber, MdSettingsBackupRestore,} from 'react-icons/md';

import { useRef, useState } from "react";
import Axios from 'axios';
//
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import katex from 'katex';
import "katex/dist/katex.min.css";
window.katex = katex;

const modules = {
    toolbar: [[{header: [1, 2, 3, 4, 5, 6,  false] }],
    [{ color: [] }],
    [{ background: [] }],
    [{ script:  "sub" }, { script:  "super" }],
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
    [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
    ],
    ["link", "image", "video", "formula"],
    ["clean"],
],
};

function ViewMyEditor() {
    const {t} = useTranslation();

    const navigateViewMyEditor = useNavigate();

    //alert
    const elementOutId = "modaloutalerteditorview";
    const elementDropId = "backdropoutalerteditorview";
    const buttonHandler = "ouiHandlerAlertEditorViewBtn";

    const [valueViewType, setValueViewType] = useState([0]);
    const [valueView, setValueView] = useState([0]);
    const [value, setValue] = useState("");

    const viewSelectTitreContenuRef = useRef(null);
    const viewSelectTypeContenuRef = useRef(null);
    const idViewContenuRef = useRef(null);

    //http://localhost:5000/edit/select/titre

    const viewSelectTypeHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const fonctionuserDiv = document.getElementById('fonctionuser').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        //
        const viewSelectTypeContenu = viewSelectTypeContenuRef.current.value;
        var resultattype = "";

        await Axios.post("https://micarionsapi.carions.net/edit/select/titre", {
            choixlangueDiv:choixlangueDiv,
            fonctionuserDiv:fonctionuserDiv,
            codeutilisateurDiv:codeutilisateurDiv,
            editSelectTypeContenu:viewSelectTypeContenu,
        }).then((responsetype) => {
            resultattype = responsetype.data.length;
            if(resultattype === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                setValueViewType(responsetype.data);
            }
        });
    }

    const confirmViewMyEditorHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const fonctionuserDiv = document.getElementById('fonctionuser').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        //
        const viewtitreeditorDiv = document.getElementById('viewtitreeditor');
        const viewviewcontenuDiv = document.getElementById('viewviewcontenu');
        //
        const viewSelectTypeContenu = viewSelectTypeContenuRef.current.value;
        const viewSelectTitreContenu = viewSelectTitreContenuRef.current.value;
        var resultat = "";
        //
        if(!viewSelectTypeContenu){
            const pTexteSaisir = t("typecontenu");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else{
            await Axios.post("https://micarionsapi.carions.net/view/contenu/confirm", {
                choixlangueDiv:choixlangueDiv,
                fonctionuserDiv:fonctionuserDiv,
                codeutilisateurDiv:codeutilisateurDiv,
                viewSelectTitreContenu:viewSelectTitreContenu,
            }).then((response) => {
                resultat = response.data.length;
                if(resultat === 0){
                    const pTexteNotAvailable = t("noteindisponible");
                    AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                    //
                    viewtitreeditorDiv.style.display = 'block';
                    viewviewcontenuDiv.style.display = 'none';
                }else{
                    setValueView(response.data);
                    //
                    viewtitreeditorDiv.style.display = 'none';
                    viewviewcontenuDiv.style.display = 'block';
                }
            });
        }
    }

    const goBackViewMyEditorHandler = async (e) => {
        e.preventDefault();

        navigateViewMyEditor(-2);
    }

    return ( 
        <div id="viewmyeditor">
            <div>
                <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                    <div id="viewtitreeditor">
                        <TextLogin>{t("typecontenu")}<strong style={{color:'red'}}>*</strong></TextLogin>
                        <div style={{display:'block',textAlign:'center'}}>
                            <LargeSelect onChange={viewSelectTypeHandler} ref={viewSelectTypeContenuRef}>
                                <option value=''>{t("typecontenu")}</option>
                                <option value='launch'>{t("landing")}</option>
                                <option value='accueil'>{t("accueil")}</option>
                                <option value='apropos'>{t("apropos")}</option>
                                <option value='portfolio'>{t("portfolio")}</option>
                                <option value='services'>{t("services")}</option>
                                <optgroup style={{color:'white', backgroundColor:'green'}} label={t("aspirant_blog")}>
                                    <option value='introductionblogs'>{t("blogs")} {t("introduction_blog")}</option>
                                    <option value='fundamentalblogs'>{t("blogs")} {t("fundamental_blog")}</option>
                                    <option value='aspirantadvancedblogs'>{t("blogs")} {t("aspirant_blog")} {t("advanced_blog")}</option>
                                    <option value='toolsblogs'>{t("blogs")} {t("tools_blog")}</option>
                                    <option value='technologiesblogs'>{t("blogs")} {t("technologies_blog")}</option>
                                </optgroup>
                                <optgroup style={{color:'white', backgroundColor:'blue'}} label={t("web_blog")}>
                                    <option value='htmlblogs'>{t("blogs")} {t("html_blog")}</option>
                                    <option value='cssblogs'>{t("blogs")} {t("css_blog")}</option>
                                    <option value='javascriptblogs'>{t("blogs")} {t("javascript_blog")}</option>
                                </optgroup>
                                <optgroup style={{color:'white', backgroundColor:'gray'}} label={t("level_blog")}>
                                    <option value='beginnerblogs'>{t("blogs")} {t("beginner_blog")}</option>
                                    <option value='intermediateblogs'>{t("blogs")} {t("intermediate_blog")}</option>
                                    <option value='leveladvancedblogs'>{t("blogs")} {t("level_blog")} {t("advanced_blog")}</option>
                                    <option value='expertblogs'>{t("blogs")} {t("expert_blog")}</option>
                                    <option value='masterblogs'>{t("blogs")} {t("master_blog")}</option>
                                </optgroup>
                                <option value='apprentissage'>{t("apprentissage")}</option>
                                <option value='apps'>{t("apps")}</option>
                                <option value='contact'>{t("contact")}</option>
                            </LargeSelect>
                        </div>
                        <div>
                            <TextLogin>{t("titrecontenu")}<strong style={{color:'red'}}>*</strong></TextLogin>
                            <LargeSelect ref={viewSelectTitreContenuRef}>
                                <option value="⬇️ Select Titre ⬇️"> -- Select Titre -- </option>
                                {valueViewType.map((valtype, indextype) => <option key={indextype} value={valtype.id_contenu}>{valtype.contenu_titre}</option>)}
                            </LargeSelect>
                        </div>
                        <br/>
                        <br/>
                        <HandlerContainerDiv>
                            <div>
                                <ButtonStart onClick={confirmViewMyEditorHandler}>
                                    <MdOutlineConfirmationNumber style={{fontSize:'30px'}} />
                                    {t("confirmer")}
                                </ButtonStart>
                            </div>
                            <div>
                                <BackStart onClick={goBackViewMyEditorHandler}>
                                    <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                   {t("retour")}
                                </BackStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                </div>
                <div style={{overflowY:'scroll'}} id="viewviewcontenu">
                    <br/>
                    {valueView.map((valview, i) => (
                        <div key={i}>
                            <TextLogin>{t("titrecontenu")}<strong style={{color:'red'}}>*</strong></TextLogin>
                            <div >
                                <input style={{display:'none'}} ref={idViewContenuRef} defaultValue={valview.id_contenu} />
                                <div style={{display:'block',textAlign:'center'}}>
                                    <GlobalLargeInput type='text' defaultValue={valview.contenu_titre} />
                                </div>
                            </div>
                            <br/>
                            <div>
                                <TextLogin><strong>{t("originaltexte")}</strong></TextLogin>
                                <div className="editornotestyles" id="launchingnote"
                                    dangerouslySetInnerHTML={{ __html: valview.contenu_texte }}
                                /> 
                            </div>
                        </div>
                    ))}
                    <div style={{display:'none'}}>
                        <div className="row">
                            <div className="editor">
                                {t("nouveautexte")}
                                <ReactQuill theme="snow" 
                                value={value} 
                                onChange={setValue}
                                className="editor-input"
                                modules={modules}
                            />
                            </div>
                            <textarea style={{display:'none'}} defaultValue={value}></textarea>
                            <div style={{display:'none'}} 
                            dangerouslySetInnerHTML={{ __html: value }}
                            />
                        </div>
                    </div>
                    <br/><br/>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackViewMyEditorHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/>
                </div>

                <div id="modaloutalerteditorview"></div>
                <div id="backdropoutalerteditorview"></div>
            </div>
        </div>
    );
}

export default ViewMyEditor;