import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../proceedweb.css';
import { useRef, useState } from "react";
//
import { MdOutlineCreate, MdSettingsBackupRestore, MdOutlineArrowForwardIos, MdOutlineArrowBackIos, MdOutlineAttachEmail, } from 'react-icons/md';
import { AiOutlineWhatsApp } from 'react-icons/ai';
//
import { AlertModal, getCurrentDate, PopupModal } from "../../helperfunctions/Helper";
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
import {  
    SpanGlobalSpan,
    GlobalStrongTitle,
    LongFormScrollerWidgetSm,
    TextProceedService, 
    TextLoginService,
    GlobalLargeInput, 
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
    TextArea,
} from "../../global/Styles";

import Axios from 'axios';

function ProceedManage() {
    const {t} = useTranslation();

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleManageChange = event => {
        if (!isValidEmail(event.target.value)) {
          setError(t('invalidemail'));
        } else {
          setError(null);
        }
    
        setEmail(event.target.value);
    };

    const navigateProceedManage = useNavigate();

    //alert
    const elementOutId = "modaloutproceedmanage";
    const elementDropId = "backdropoutproceedmanage";
    const buttonHandler = "ouiHandlerProceedManageBtn";
    //
    const elementOutAlertId = "modaloutalertproceedmanage";
    const elementDropAlertId = "backdropoutalertproceedmanage";
    const buttonAlertHandler = "ouiHandleralertProceedManageBtn";

    const manageQuestion1Ref = useRef(null);
    const manageQuestion2Ref = useRef(null);
    const manageQuestion3Ref = useRef(null);
    //
    const manageQuestion4Ref = useRef(null);
    const manageQuestion5Ref = useRef(null);
    const manageQuestion6Ref = useRef(null);
    //
    const manageQuestion7Ref = useRef(null);
    const manageQuestion8Ref = useRef(null);
    const manageQuestion9Ref = useRef(null);
    //
    const manageQuestion10Ref = useRef(null);
    const manageQuestion11Ref = useRef(null);
    const manageQuestion12Ref = useRef(null);
    //
    const manageQuestion13Ref = useRef(null);
    const manageQuestion14Ref = useRef(null);

    const popmanage1Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("managequestion_1");
        const pTexteNotAvailable = t("hintmanagequestion_1");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmanage2Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("managequestion_2");
        const pTexteNotAvailable = t("hintmanagequestion_2");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmanage3Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("managequestion_3");
        const pTexteNotAvailable = t("hintmanagequestion_3");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    /** */
    const forwardProceedManage1Handler = async (e) => {
        e.preventDefault();

        const managesteponeDiv = document.getElementById('managestepone');
        const managesteptwoDiv = document.getElementById('managesteptwo');
        const managestepthreeDiv = document.getElementById('managestepthree');
        const managestepfourDiv = document.getElementById('managestepfour');
        const modeproceedmanageDiv = document.getElementById('modeproceedmanage');
        const submitproceedmanageDiv = document.getElementById('submitproceedmanage');
        //
        managesteponeDiv.style.display = 'none';
        managesteptwoDiv.style.display = 'block';
        managestepthreeDiv.style.display = 'none';
        managestepfourDiv.style.display = 'none';
        modeproceedmanageDiv.style.display = 'none';
        submitproceedmanageDiv.style.display = 'none';
    }

    const popmanage4Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("managequestion_4");
        const pTexteNotAvailable = t("hintmanagequestion_4");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmanage5Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("managequestion_5");
        const pTexteNotAvailable = t("hintmanagequestion_5");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmanage6Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("managequestion_6");
        const pTexteNotAvailable = t("hintmanagequestion_6");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    /** */
    const goBackProceedManage2Handler = async (e) => {
        e.preventDefault();

        const managesteponeDiv = document.getElementById('managestepone');
        const managesteptwoDiv = document.getElementById('managesteptwo');
        const managestepthreeDiv = document.getElementById('managestepthree');
        const managestepfourDiv = document.getElementById('managestepfour');
        const modeproceedmanageDiv = document.getElementById('modeproceedmanage');
        const submitproceedmanageDiv = document.getElementById('submitproceedmanage');
        //
        managesteponeDiv.style.display = 'block';
        managesteptwoDiv.style.display = 'none';
        managestepthreeDiv.style.display = 'none';
        managestepfourDiv.style.display = 'none';
        modeproceedmanageDiv.style.display = 'none';
        submitproceedmanageDiv.style.display = 'none';
    }
    //
    const forwardProceedManage2Handler = async (e) => {
        e.preventDefault();

        const managesteponeDiv = document.getElementById('managestepone');
        const managesteptwoDiv = document.getElementById('managesteptwo');
        const managestepthreeDiv = document.getElementById('managestepthree');
        const managestepfourDiv = document.getElementById('managestepfour');
        const modeproceedmanageDiv = document.getElementById('modeproceedmanage');
        const submitproceedmanageDiv = document.getElementById('submitproceedmanage');
        //
        managesteponeDiv.style.display = 'none';
        managesteptwoDiv.style.display = 'none';
        managestepthreeDiv.style.display = 'block';
        managestepfourDiv.style.display = 'none';
        modeproceedmanageDiv.style.display = 'none';
        submitproceedmanageDiv.style.display = 'none';
    }

    const popmanage7Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("managequestion_7");
        const pTexteNotAvailable = t("hintmanagequestion_7");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmanage8Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("managequestion_8");
        const pTexteNotAvailable = t("hintmanagequestion_8");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmanage9Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("managequestion_9");
        const pTexteNotAvailable = t("hintmanagequestion_9");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    /** */
    const goBackProceedManage3Handler = async (e) => {
        e.preventDefault();

        const managesteponeDiv = document.getElementById('managestepone');
        const managesteptwoDiv = document.getElementById('managesteptwo');
        const managestepthreeDiv = document.getElementById('managestepthree');
        const managestepfourDiv = document.getElementById('managestepfour');
        const modeproceedmanageDiv = document.getElementById('modeproceedmanage');
        const submitproceedmanageDiv = document.getElementById('submitproceedmanage');
        //
        managesteponeDiv.style.display = 'none';
        managesteptwoDiv.style.display = 'block';
        managestepthreeDiv.style.display = 'none';
        managestepfourDiv.style.display = 'none';
        modeproceedmanageDiv.style.display = 'none';
        submitproceedmanageDiv.style.display = 'none';
    }
    //
    const forwardProceedManage3Handler = async (e) => {
        e.preventDefault();

        const managesteponeDiv = document.getElementById('managestepone');
        const managesteptwoDiv = document.getElementById('managesteptwo');
        const managestepthreeDiv = document.getElementById('managestepthree');
        const managestepfourDiv = document.getElementById('managestepfour');
        const modeproceedmanageDiv = document.getElementById('modeproceedmanage');
        const submitproceedmanageDiv = document.getElementById('submitproceedmanage');
        //
        managesteponeDiv.style.display = 'none';
        managesteptwoDiv.style.display = 'none';
        managestepthreeDiv.style.display = 'none';
        managestepfourDiv.style.display = 'block';
        modeproceedmanageDiv.style.display = 'none';
        submitproceedmanageDiv.style.display = 'none';
    }

    const popmanage10Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("managequestion_10");
        const pTexteNotAvailable = t("hintmanagequestion_10");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmanage11Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("managequestion_11");
        const pTexteNotAvailable = t("hintmanagequestion_11");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popmanage12Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("managequestion_12");
        const pTexteNotAvailable = t("hintmanagequestion_12");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    /** */
    const goBackProceedManage4Handler = async (e) => {
        e.preventDefault();

        const managesteponeDiv = document.getElementById('managestepone');
        const managesteptwoDiv = document.getElementById('managesteptwo');
        const managestepthreeDiv = document.getElementById('managestepthree');
        const managestepfourDiv = document.getElementById('managestepfour');
        const modeproceedmanageDiv = document.getElementById('modeproceedmanage');
        const submitproceedmanageDiv = document.getElementById('submitproceedmanage');
        //
        managesteponeDiv.style.display = 'none';
        managesteptwoDiv.style.display = 'none';
        managestepthreeDiv.style.display = 'block';
        managestepfourDiv.style.display = 'none';
        modeproceedmanageDiv.style.display = 'none';
        submitproceedmanageDiv.style.display = 'none';
    }
    //
    const forwardProceedManage4Handler = async (e) => {
        e.preventDefault();

        const managesteponeDiv = document.getElementById('managestepone');
        const managesteptwoDiv = document.getElementById('managesteptwo');
        const managestepthreeDiv = document.getElementById('managestepthree');
        const managestepfourDiv = document.getElementById('managestepfour');
        const modeproceedmanageDiv = document.getElementById('modeproceedmanage');
        const submitproceedmanageDiv = document.getElementById('submitproceedmanage');
        //
        managesteponeDiv.style.display = 'none';
        managesteptwoDiv.style.display = 'none';
        managestepthreeDiv.style.display = 'none';
        managestepfourDiv.style.display = 'none';
        modeproceedmanageDiv.style.display = 'block';
        submitproceedmanageDiv.style.display = 'none';
    }

    const goBackProceedManage5Handler = async (e) => {
        e.preventDefault();

        const managesteponeDiv = document.getElementById('managestepone');
        const managesteptwoDiv = document.getElementById('managesteptwo');
        const managestepthreeDiv = document.getElementById('managestepthree');
        const managestepfourDiv = document.getElementById('managestepfour');
        const modeproceedmanageDiv = document.getElementById('modeproceedmanage');
        const submitproceedmanageDiv = document.getElementById('submitproceedmanage');
        //
        managesteponeDiv.style.display = 'none';
        managesteptwoDiv.style.display = 'none';
        managestepthreeDiv.style.display = 'none';
        managestepfourDiv.style.display = 'block';
        modeproceedmanageDiv.style.display = 'none';
        submitproceedmanageDiv.style.display = 'none';
    }
    //
    const forwardProceedManage5Handler = async (e) => {
        e.preventDefault();

        const manageQuestion13 = manageQuestion13Ref.current.value;
        const manageQuestion14 = manageQuestion14Ref.current.value;
        //
        const managesteponeDiv = document.getElementById('managestepone');
        const managesteptwoDiv = document.getElementById('managesteptwo');
        const managestepthreeDiv = document.getElementById('managestepthree');
        const managestepfourDiv = document.getElementById('managestepfour');
        const modeproceedmanageDiv = document.getElementById('modeproceedmanage');
        const submitproceedmanageDiv = document.getElementById('submitproceedmanage');
        //
        if(!manageQuestion13){
            const pTexteSaisir = t("enteremail");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else if(!manageQuestion14){
            const pTexteSaisir = t("enterphone");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else{
            //
            managesteponeDiv.style.display = 'none';
            managesteptwoDiv.style.display = 'none';
            managestepthreeDiv.style.display = 'none';
            managestepfourDiv.style.display = 'none';
            modeproceedmanageDiv.style.display = 'none';
            submitproceedmanageDiv.style.display = 'block';
        }
    }

    const goBackProceedManage6Handler = async (e) => {
        e.preventDefault();

        const managesteponeDiv = document.getElementById('managestepone');
        const managesteptwoDiv = document.getElementById('managesteptwo');
        const managestepthreeDiv = document.getElementById('managestepthree');
        const managestepfourDiv = document.getElementById('managestepfour');
        const modeproceedmanageDiv = document.getElementById('modeproceedmanage');
        const submitproceedmanageDiv = document.getElementById('submitproceedmanage');
        //
        managesteponeDiv.style.display = 'none';
        managesteptwoDiv.style.display = 'none';
        managestepthreeDiv.style.display = 'none';
        managestepfourDiv.style.display = 'none';
        modeproceedmanageDiv.style.display = 'block';
        submitproceedmanageDiv.style.display = 'none';
    }

    //http://localhost:5000/check/manageservice

    const submitProceedManageHandler = async (e) => {
        e.preventDefault();

        const manageQuestion1 = manageQuestion1Ref.current.value;
        const manageQuestion2 = manageQuestion2Ref.current.value;
        const manageQuestion3 = manageQuestion3Ref.current.value;
        //websteptwo
        const manageQuestion4 = manageQuestion4Ref.current.value;
        const manageQuestion5 = manageQuestion5Ref.current.value;
        const manageQuestion6 = manageQuestion6Ref.current.value;
        //webstepthree
        const manageQuestion7 = manageQuestion7Ref.current.value;
        const manageQuestion8 = manageQuestion8Ref.current.value;
        const manageQuestion9 = manageQuestion9Ref.current.value;
        //webstepfour
        const manageQuestion10 = manageQuestion10Ref.current.value;
        const manageQuestion11 = manageQuestion11Ref.current.value;
        const manageQuestion12 = manageQuestion12Ref.current.value;
        //modeproceedweb
        const manageQuestion13 = manageQuestion13Ref.current.value;
        const manageQuestion14 = manageQuestion14Ref.current.value;
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const theDate = getCurrentDate();
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = "management";
        const servicePlan = document.getElementById('typeplan').innerText;
        var createTicketCode = "";
        //
        var resultat1 = "";
        //
        //generate random ticket code
        var randomNumber = Math.floor((Math.random() * 100000) + 1);
        createTicketCode = randomNumber+''+codeutilisateurDiv;
        //
        await Axios.post("https://micarionsapi.carions.net/check/manageservice", {
            codeutilisateurDiv:codeutilisateurDiv,
            typeService:typeService,
            servicePlan:servicePlan,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                //insert
                Axios.post("https://micarionsapi.carions.net/quest/manageservice", {
                    choixlangueDiv:choixlangueDiv,
                    usernamedetailDiv:usernamedetailDiv,
                    codeutilisateurDiv:codeutilisateurDiv,
                    //
                    typeService:typeService,
                    servicePlan:servicePlan,
                    //
                    createTicketCode:createTicketCode,
                    //
                    manageQuestion1:manageQuestion1,
                    manageQuestion2:manageQuestion2,
                    manageQuestion3:manageQuestion3,
                    //
                    manageQuestion4:manageQuestion4,
                    manageQuestion5:manageQuestion5,
                    manageQuestion6:manageQuestion6,
                    //
                    manageQuestion7:manageQuestion7,
                    manageQuestion8:manageQuestion8,
                    manageQuestion9:manageQuestion9,
                    //
                    manageQuestion10:manageQuestion10,
                    manageQuestion11:manageQuestion11,
                    manageQuestion12:manageQuestion12,
                    //
                    manageQuestion13:manageQuestion13,
                    manageQuestion14:manageQuestion14,
                    //
                    theDate:theDate,
                }).then(() => {
                    var answer1 = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                    const hTitleTexte = t("software_management");
                    const pTexteNotAvailable = answer1;
                    PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
                });
            }else{
                //set up modal to update
                var answer = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                const hTitleTexte = t("software_management");
                const pTexteNotAvailable = answer;
                PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
            }
        });
    }

    const goBackProceedManageHandler = async (e) => {
        e.preventDefault();

        navigateProceedManage('/softmanagementservicestab');
    }

    return ( 
        <div id="proceedmanage">
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t('software_management')}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <LongFormScrollerWidgetSm>
                <div id="modaloutproceedmanage"></div>
                <div id="backdropoutproceedmanage"></div>
                <p className="pservicestyles"><br/>{t("proceedmanagedescription")}</p>
                <div id="managestepone">
                    <TextProceedService onClick={popmanage1Handler}>{t("managequestion_1")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={manageQuestion1Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmanage2Handler}>{t("managequestion_2")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={manageQuestion2Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmanage3Handler}>{t("managequestion_3")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={manageQuestion3Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <ButtonStart onClick={forwardProceedManage1Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="managesteptwo">
                    <TextProceedService onClick={popmanage4Handler}>{t("managequestion_4")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={manageQuestion4Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmanage5Handler}>{t("managequestion_5")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={manageQuestion5Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmanage6Handler}>{t("managequestion_6")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={manageQuestion6Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedManage2Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedManage2Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="managestepthree">
                    <TextProceedService onClick={popmanage7Handler}>{t("managequestion_7")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={manageQuestion7Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmanage8Handler}>{t("managequestion_8")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={manageQuestion8Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmanage9Handler}>{t("managequestion_9")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={manageQuestion9Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedManage3Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedManage3Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="managestepfour">
                    <TextProceedService onClick={popmanage10Handler}>{t("managequestion_10")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={manageQuestion10Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmanage11Handler}>{t("managequestion_11")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={manageQuestion11Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popmanage12Handler}>{t("managequestion_12")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={manageQuestion12Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedManage4Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedManage4Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="modeproceedmanage">
                    <TextProceedService>{t("managequestion_13")}</TextProceedService>
                    <TextLoginService><MdOutlineAttachEmail style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput id="emailmanage" name="emailmanage" value={email} onChange={handleManageChange} ref={manageQuestion13Ref} placeholder={t("placemail")}/>
                        {error && <h5 style={{color: 'white'}}>{error}</h5>}
                    </div>
                    <br/>
                    <TextLoginService><AiOutlineWhatsApp style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput type='number' ref={manageQuestion14Ref}  placeholder={t("placewhatsapp")}/>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedManage5Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedManage5Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="submitproceedmanage">
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedManage6Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={submitProceedManageHandler}>
                                    <MdOutlineCreate style={{fontSize:'30px'}} />
                                   {t("creer")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackProceedManageHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertproceedmanage"></div>
                <div id="backdropoutalertproceedmanage"></div>
            </LongFormScrollerWidgetSm>
        </div>
    );
}

export default ProceedManage;