import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../proceedweb.css';
import { useRef, useState } from "react";
//
import { MdOutlineCreate, MdSettingsBackupRestore, MdOutlineArrowForwardIos, MdOutlineArrowBackIos, MdOutlineAttachEmail } from 'react-icons/md';
import { AiOutlineWhatsApp } from 'react-icons/ai';
//
import { AlertModal, getCurrentDate, PopupModal } from "../../helperfunctions/Helper";
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
import {  
    SpanGlobalSpan,
    GlobalStrongTitle,
    LongFormScrollerWidgetSm,
    TextProceedService, 
    TextLoginService,
    GlobalLargeInput, 
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
    TextArea,
} from "../../global/Styles";

import Axios from 'axios';

function ProceedTechnical() {
    const {t} = useTranslation();

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleSeoTechnicChange = event => {
        if (!isValidEmail(event.target.value)) {
          setError(t('invalidemail'));
        } else {
          setError(null);
        }
    
        setEmail(event.target.value);
    };

    const navigateProceedSeoTechnic = useNavigate();

    //alert
    const elementOutId = "modaloutproceedseotechnic";
    const elementDropId = "backdropoutproceedseotechnic";
    const buttonHandler = "ouiHandlerProceedSeoTechnicBtn";
    //
    const elementOutAlertId = "modaloutalertproceedseotechnic";
    const elementDropAlertId = "backdropoutalertproceedseotechnic";
    const buttonAlertHandler = "ouiHandleralertProceedSeoTechnicBtn";

    const technicSeoQuestion1Ref = useRef(null);
    const technicSeoQuestion2Ref = useRef(null);
    const technicSeoQuestion3Ref = useRef(null);
    //
    const technicSeoQuestion4Ref = useRef(null);
    const technicSeoQuestion5Ref = useRef(null);
    const technicSeoQuestion6Ref = useRef(null);
    //
    const technicSeoQuestion7Ref = useRef(null);
    const technicSeoQuestion8Ref = useRef(null);
    const technicSeoQuestion9Ref = useRef(null);
    //
    const technicSeoQuestion10Ref = useRef(null);
    const technicSeoQuestion11Ref = useRef(null);
    const technicSeoQuestion12Ref = useRef(null);
    //
    const technicSeoQuestion13Ref = useRef(null);
    const technicSeoQuestion14Ref = useRef(null);

    const popseotechnic1Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_1");
        const pTexteNotAvailable = t("hintseoquestion_1");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseotechnic2Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_2");
        const pTexteNotAvailable = t("hintseoquestion_2");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseotechnic3Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_3");
        const pTexteNotAvailable = t("hintseoquestion_3");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const popseotechnic4Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_4");
        const pTexteNotAvailable = t("hintseoquestion_4");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseotechnic5Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_5");
        const pTexteNotAvailable = t("hintseoquestion_5");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseotechnic6Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_6");
        const pTexteNotAvailable = t("hintseoquestion_6");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const popseotechnic7Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_7");
        const pTexteNotAvailable = t("hintseoquestion_7");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseotechnic8Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_8");
        const pTexteNotAvailable = t("hintseoquestion_8");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseotechnic9Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_9");
        const pTexteNotAvailable = t("hintseoquestion_9");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const popseotechnic10Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_10");
        const pTexteNotAvailable = t("hintseoquestion_10");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseotechnic11Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_11");
        const pTexteNotAvailable = t("hintseoquestion_11");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseotechnic12Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_12");
        const pTexteNotAvailable = t("hintseoquestion_12");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const forwardProceedSeoTechnic1Handler = async (e) => {
        e.preventDefault();

        const seotechnicsteponeDiv = document.getElementById('seotechnicstepone');
        const seotechnicsteptwoDiv = document.getElementById('seotechnicsteptwo');
        const seotechnicstepthreeDiv = document.getElementById('seotechnicstepthree');
        const seotechnicstepfourDiv = document.getElementById('seotechnicstepfour');
        const modeproceedseotechnicDiv = document.getElementById('modeproceedseotechnic');
        const submitproceedseotechnicDiv = document.getElementById('submitproceedseotechnic');
        //
        seotechnicsteponeDiv.style.display = 'none';
        seotechnicsteptwoDiv.style.display = 'block';
        seotechnicstepthreeDiv.style.display = 'none';
        seotechnicstepfourDiv.style.display = 'none';
        modeproceedseotechnicDiv.style.display = 'none';
        submitproceedseotechnicDiv.style.display = 'none';
    }

    const goBackProceedSeoTechnic2Handler = async (e) => {
        e.preventDefault();

        const seotechnicsteponeDiv = document.getElementById('seotechnicstepone');
        const seotechnicsteptwoDiv = document.getElementById('seotechnicsteptwo');
        const seotechnicstepthreeDiv = document.getElementById('seotechnicstepthree');
        const seotechnicstepfourDiv = document.getElementById('seotechnicstepfour');
        const modeproceedseotechnicDiv = document.getElementById('modeproceedseotechnic');
        const submitproceedseotechnicDiv = document.getElementById('submitproceedseotechnic');
        //
        seotechnicsteponeDiv.style.display = 'block';
        seotechnicsteptwoDiv.style.display = 'none';
        seotechnicstepthreeDiv.style.display = 'none';
        seotechnicstepfourDiv.style.display = 'none';
        modeproceedseotechnicDiv.style.display = 'none';
        submitproceedseotechnicDiv.style.display = 'none';
    }
    //
    const forwardProceedSeoTechnic2Handler = async (e) => {
        e.preventDefault();

        const seotechnicsteponeDiv = document.getElementById('seotechnicstepone');
        const seotechnicsteptwoDiv = document.getElementById('seotechnicsteptwo');
        const seotechnicstepthreeDiv = document.getElementById('seotechnicstepthree');
        const seotechnicstepfourDiv = document.getElementById('seotechnicstepfour');
        const modeproceedseotechnicDiv = document.getElementById('modeproceedseotechnic');
        const submitproceedseotechnicDiv = document.getElementById('submitproceedseotechnic');
        //
        seotechnicsteponeDiv.style.display = 'none';
        seotechnicsteptwoDiv.style.display = 'none';
        seotechnicstepthreeDiv.style.display = 'block';
        seotechnicstepfourDiv.style.display = 'none';
        modeproceedseotechnicDiv.style.display = 'none';
        submitproceedseotechnicDiv.style.display = 'none';
    }

    const goBackProceedSeoTechnic3Handler = async (e) => {
        e.preventDefault();

        const seotechnicsteponeDiv = document.getElementById('seotechnicstepone');
        const seotechnicsteptwoDiv = document.getElementById('seotechnicsteptwo');
        const seotechnicstepthreeDiv = document.getElementById('seotechnicstepthree');
        const seotechnicstepfourDiv = document.getElementById('seotechnicstepfour');
        const modeproceedseotechnicDiv = document.getElementById('modeproceedseotechnic');
        const submitproceedseotechnicDiv = document.getElementById('submitproceedseotechnic');
        //
        seotechnicsteponeDiv.style.display = 'none';
        seotechnicsteptwoDiv.style.display = 'block';
        seotechnicstepthreeDiv.style.display = 'none';
        seotechnicstepfourDiv.style.display = 'none';
        modeproceedseotechnicDiv.style.display = 'none';
        submitproceedseotechnicDiv.style.display = 'none';
    }
    //
    const forwardProceedSeoTechnic3Handler = async (e) => {
        e.preventDefault();

        const seotechnicsteponeDiv = document.getElementById('seotechnicstepone');
        const seotechnicsteptwoDiv = document.getElementById('seotechnicsteptwo');
        const seotechnicstepthreeDiv = document.getElementById('seotechnicstepthree');
        const seotechnicstepfourDiv = document.getElementById('seotechnicstepfour');
        const modeproceedseotechnicDiv = document.getElementById('modeproceedseotechnic');
        const submitproceedseotechnicDiv = document.getElementById('submitproceedseotechnic');
        //
        seotechnicsteponeDiv.style.display = 'none';
        seotechnicsteptwoDiv.style.display = 'none';
        seotechnicstepthreeDiv.style.display = 'none';
        seotechnicstepfourDiv.style.display = 'block';
        modeproceedseotechnicDiv.style.display = 'none';
        submitproceedseotechnicDiv.style.display = 'none';
    }

    const goBackProceedSeoTechnic4Handler = async (e) => {
        e.preventDefault();

        const seotechnicsteponeDiv = document.getElementById('seotechnicstepone');
        const seotechnicsteptwoDiv = document.getElementById('seotechnicsteptwo');
        const seotechnicstepthreeDiv = document.getElementById('seotechnicstepthree');
        const seotechnicstepfourDiv = document.getElementById('seotechnicstepfour');
        const modeproceedseotechnicDiv = document.getElementById('modeproceedseotechnic');
        const submitproceedseotechnicDiv = document.getElementById('submitproceedseotechnic');
        //
        seotechnicsteponeDiv.style.display = 'none';
        seotechnicsteptwoDiv.style.display = 'none';
        seotechnicstepthreeDiv.style.display = 'block';
        seotechnicstepfourDiv.style.display = 'none';
        modeproceedseotechnicDiv.style.display = 'none';
        submitproceedseotechnicDiv.style.display = 'none';
    }
    //
    const forwardProceedSeoTechnic4Handler = async (e) => {
        e.preventDefault();

        const seotechnicsteponeDiv = document.getElementById('seotechnicstepone');
        const seotechnicsteptwoDiv = document.getElementById('seotechnicsteptwo');
        const seotechnicstepthreeDiv = document.getElementById('seotechnicstepthree');
        const seotechnicstepfourDiv = document.getElementById('seotechnicstepfour');
        const modeproceedseotechnicDiv = document.getElementById('modeproceedseotechnic');
        const submitproceedseotechnicDiv = document.getElementById('submitproceedseotechnic');
        //
        seotechnicsteponeDiv.style.display = 'none';
        seotechnicsteptwoDiv.style.display = 'none';
        seotechnicstepthreeDiv.style.display = 'none';
        seotechnicstepfourDiv.style.display = 'none';
        modeproceedseotechnicDiv.style.display = 'block';
        submitproceedseotechnicDiv.style.display = 'none';
    }

    const goBackProceedSeoTechnic5Handler = async (e) => {
        e.preventDefault();

        const seotechnicsteponeDiv = document.getElementById('seotechnicstepone');
        const seotechnicsteptwoDiv = document.getElementById('seotechnicsteptwo');
        const seotechnicstepthreeDiv = document.getElementById('seotechnicstepthree');
        const seotechnicstepfourDiv = document.getElementById('seotechnicstepfour');
        const modeproceedseotechnicDiv = document.getElementById('modeproceedseotechnic');
        const submitproceedseotechnicDiv = document.getElementById('submitproceedseotechnic');
        //
        seotechnicsteponeDiv.style.display = 'none';
        seotechnicsteptwoDiv.style.display = 'none';
        seotechnicstepthreeDiv.style.display = 'none';
        seotechnicstepfourDiv.style.display = 'block';
        modeproceedseotechnicDiv.style.display = 'none';
        submitproceedseotechnicDiv.style.display = 'none';
    }

    const forwardProceedSeoTechnic5Handler = async (e) => {
        e.preventDefault();

        const technicSeoQuestion13 = technicSeoQuestion13Ref.current.value;
        const technicSeoQuestion14 = technicSeoQuestion14Ref.current.value;
        //
        const seotechnicsteponeDiv = document.getElementById('seotechnicstepone');
        const seotechnicsteptwoDiv = document.getElementById('seotechnicsteptwo');
        const seotechnicstepthreeDiv = document.getElementById('seotechnicstepthree');
        const seotechnicstepfourDiv = document.getElementById('seotechnicstepfour');
        const modeproceedseotechnicDiv = document.getElementById('modeproceedseotechnic');
        const submitproceedseotechnicDiv = document.getElementById('submitproceedseotechnic');
        //
        if(!technicSeoQuestion13){
            const pTexteSaisir = t("enteremail");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else if(!technicSeoQuestion14){
            const pTexteSaisir = t("enterphone");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else{
            //
            seotechnicsteponeDiv.style.display = 'none';
            seotechnicsteptwoDiv.style.display = 'none';
            seotechnicstepthreeDiv.style.display = 'none';
            seotechnicstepfourDiv.style.display = 'none';
            modeproceedseotechnicDiv.style.display = 'none';
            submitproceedseotechnicDiv.style.display = 'block';
        }
    }

    const goBackProceedSeoTechnic6Handler = async (e) => {
        e.preventDefault();

        const seotechnicsteponeDiv = document.getElementById('seotechnicstepone');
        const seotechnicsteptwoDiv = document.getElementById('seotechnicsteptwo');
        const seotechnicstepthreeDiv = document.getElementById('seotechnicstepthree');
        const seotechnicstepfourDiv = document.getElementById('seotechnicstepfour');
        const modeproceedseotechnicDiv = document.getElementById('modeproceedseotechnic');
        const submitproceedseotechnicDiv = document.getElementById('submitproceedseotechnic');
        //
        seotechnicsteponeDiv.style.display = 'none';
        seotechnicsteptwoDiv.style.display = 'none';
        seotechnicstepthreeDiv.style.display = 'none';
        seotechnicstepfourDiv.style.display = 'none';
        modeproceedseotechnicDiv.style.display = 'block';
        submitproceedseotechnicDiv.style.display = 'none';
    }

    //http://localhost:5000/check/seotechnicservice

    const submitProceedSeoTechnicHandler = async (e) => {
        e.preventDefault();

        const technicSeoQuestion1 = technicSeoQuestion1Ref.current.value;
        const technicSeoQuestion2 = technicSeoQuestion2Ref.current.value;
        const technicSeoQuestion3 = technicSeoQuestion3Ref.current.value;
        //websteptwo
        const technicSeoQuestion4 = technicSeoQuestion4Ref.current.value;
        const technicSeoQuestion5 = technicSeoQuestion5Ref.current.value;
        const technicSeoQuestion6 = technicSeoQuestion6Ref.current.value;
        //webstepthree
        const technicSeoQuestion7 = technicSeoQuestion7Ref.current.value;
        const technicSeoQuestion8 = technicSeoQuestion8Ref.current.value;
        const technicSeoQuestion9 = technicSeoQuestion9Ref.current.value;
        //webstepfour
        const technicSeoQuestion10 = technicSeoQuestion10Ref.current.value;
        const technicSeoQuestion11 = technicSeoQuestion11Ref.current.value;
        const technicSeoQuestion12 = technicSeoQuestion12Ref.current.value;
        //modeproceedweb
        const technicSeoQuestion13 = technicSeoQuestion13Ref.current.value;
        const technicSeoQuestion14 = technicSeoQuestion14Ref.current.value;
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const theDate = getCurrentDate();
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = "seotechnical";
        const servicePlan = document.getElementById('typeplan').innerText;
        var createTicketCode = "";
        //
        var resultat1 = "";

        //generate random ticket code
        var randomNumber = Math.floor((Math.random() * 100000) + 1);
        createTicketCode = randomNumber+''+codeutilisateurDiv;
        //
        await Axios.post("https://micarionsapi.carions.net/check/seotechnicservice", {
            codeutilisateurDiv:codeutilisateurDiv,
            typeService:typeService,
            servicePlan:servicePlan,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                //insert
                Axios.post("https://micarionsapi.carions.net/quest/seotechnicservice", {
                    choixlangueDiv:choixlangueDiv,
                    usernamedetailDiv:usernamedetailDiv,
                    codeutilisateurDiv:codeutilisateurDiv,
                    //
                    typeService:typeService,
                    servicePlan:servicePlan,
                    //
                    createTicketCode:createTicketCode,
                    //
                    technicSeoQuestion1:technicSeoQuestion1,
                    technicSeoQuestion2:technicSeoQuestion2,
                    technicSeoQuestion3:technicSeoQuestion3,
                    //
                    technicSeoQuestion4:technicSeoQuestion4,
                    technicSeoQuestion5:technicSeoQuestion5,
                    technicSeoQuestion6:technicSeoQuestion6,
                    //
                    technicSeoQuestion7:technicSeoQuestion7,
                    technicSeoQuestion8:technicSeoQuestion8,
                    technicSeoQuestion9:technicSeoQuestion9,
                    //
                    technicSeoQuestion10:technicSeoQuestion10,
                    technicSeoQuestion11:technicSeoQuestion11,
                    technicSeoQuestion12:technicSeoQuestion12,
                    //
                    technicSeoQuestion13:technicSeoQuestion13,
                    technicSeoQuestion14:technicSeoQuestion14,
                    //
                    theDate:theDate,
                }).then(() => {
                    var answer1 = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                    const hTitleTexte = t("proceedwebapptitle");
                    const pTexteNotAvailable = answer1;
                    PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
                });
            }else{
                //set up modal to update
                var answer = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                const hTitleTexte = t("proceedwebapptitle");
                const pTexteNotAvailable = answer;
                PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
            }
        });
    }

    const goBackProceedSeoTechnicHandler = async (e) => {
        e.preventDefault();

        navigateProceedSeoTechnic('/technicaltab');
    }

    return ( 
        <div id="proceedtechnical">
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t('technical_audit')}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <LongFormScrollerWidgetSm>
                <div id="modaloutproceedseotechnic"></div>
                <div id="backdropoutproceedseotechnic"></div>
                <p className="pservicestyles"><br/>{t("proceedseodescription")}</p>
                <div id="seotechnicstepone">
                    <TextProceedService onClick={popseotechnic1Handler}>{t("seoquestion_1")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={technicSeoQuestion1Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseotechnic2Handler}>{t("seoquestion_2")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={technicSeoQuestion2Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseotechnic3Handler}>{t("seoquestion_3")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={technicSeoQuestion3Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <ButtonStart onClick={forwardProceedSeoTechnic1Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="seotechnicsteptwo">
                    <TextProceedService onClick={popseotechnic4Handler}>{t("seoquestion_4")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={technicSeoQuestion4Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseotechnic5Handler}>{t("seoquestion_5")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={technicSeoQuestion5Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseotechnic6Handler}>{t("seoquestion_6")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={technicSeoQuestion6Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedSeoTechnic2Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedSeoTechnic2Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="seotechnicstepthree">
                    <TextProceedService onClick={popseotechnic7Handler}>{t("seoquestion_7")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={technicSeoQuestion7Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseotechnic8Handler}>{t("seoquestion_8")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={technicSeoQuestion8Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseotechnic9Handler}>{t("seoquestion_9")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={technicSeoQuestion9Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedSeoTechnic3Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedSeoTechnic3Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="seotechnicstepfour">
                    <TextProceedService onClick={popseotechnic10Handler}>{t("seoquestion_10")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={technicSeoQuestion10Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseotechnic11Handler}>{t("seoquestion_11")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={technicSeoQuestion11Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseotechnic12Handler}>{t("seoquestion_12")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={technicSeoQuestion12Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedSeoTechnic4Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedSeoTechnic4Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="modeproceedseotechnic">
                    <TextProceedService>{t("webquestion_13")}</TextProceedService>
                    <TextLoginService><MdOutlineAttachEmail style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput id="emailseotechnic" name="emailseotechnic" value={email} onChange={handleSeoTechnicChange} ref={technicSeoQuestion13Ref} placeholder={t("placemail")}/>
                        {error && <h5 style={{color: 'white'}}>{error}</h5>}
                    </div>
                    <br/>
                    <TextLoginService><AiOutlineWhatsApp style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                            <GlobalLargeInput type='number' ref={technicSeoQuestion14Ref}  placeholder={t("placewhatsapp")}/>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedSeoTechnic5Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedSeoTechnic5Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="submitproceedseotechnic">
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedSeoTechnic6Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={submitProceedSeoTechnicHandler}>
                                    <MdOutlineCreate style={{fontSize:'30px'}} />
                                   {t("creer")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackProceedSeoTechnicHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertproceedseotechnic"></div>
                <div id="backdropoutalertproceedseotechnic"></div>
            </LongFormScrollerWidgetSm>
        </div>
    );
}

export default ProceedTechnical;