import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../../components.css';
import imageaccount from '../../../carions-icon-192.png';
//
import { AlertModal } from "../../../helperfunctions/Helper";
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';

import { 
    DivContainerSlide, 
    GlobalStrongTitle,
    SpanGlobalSpan,
    FormScrollerWidgetSm,
    TextLogin, 
    GlobalLargeInput,
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
} from "../../../global/Styles";

import { MdOutlineConnectedTv, MdSettingsBackupRestore} from 'react-icons/md';

import { useRef, } from "react";
import Axios from 'axios';

function ConnectAccount() {
    const {t} = useTranslation();

    const navigateConnectBack = useNavigate();

    //alert
    const elementOutId = "modaloutalertconnectuser";
    const elementDropId = "backdropoutalertconnectuser";
    const buttonHandler = "ouiHandlerAlertConnectUserBtn";

    const connectUsernameRef = useRef(null);
    const connectPasswordRef = useRef(null);

    const creerCompteHandler = async (e) => {
        e.preventDefault();

        navigateConnectBack('/account');
    }

    const modifierCompteHandler = async (e) => {
        e.preventDefault();

        navigateConnectBack('/cruduseraccount');
    }

    //http://localhost:5000
    //https://micarionsapi.carions.net

    const submitConnectHandler = async (e) => {
        e.preventDefault();

        //show username and user code
        const fonctionuserDiv = document.getElementById('fonctionuser');
        const usernamedetailDiv = document.getElementById('usernamedetail');
        const codeutilisateurDiv = document.getElementById('codeutilisateur');
        //
        const connectUsername = connectUsernameRef.current.value;
        const connectPassword = connectPasswordRef.current.value;
        //
        var resultat = 0;
        var checkResultat = [];
        var userName = "";
        var codeUser = "";
        var roleUser = "";

        if(!connectUsername){
            const pTexteSaisir = t("placeusername");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!connectPassword){
            const pTexteSaisir = t("placepassword");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else{
            await Axios.post("https://micarionsapi.carions.net/inscrit/connect", {
                connectUsername:connectUsername,
                connectPassword:connectPassword,
            }).then((response1) => {
                resultat = response1.data.length;
                checkResultat = response1.data;
                if(resultat === 0){
                    const pTexteSaisir = t("erreurdetail");
                    AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
                }else{
                    for(let i=0; i<resultat; i++){
                        userName = checkResultat[i].users_username;
                        codeUser = checkResultat[i].users_code;
                        roleUser = checkResultat[i].users_role;
                    }
                    //
                    fonctionuserDiv.innerText = roleUser;
                    usernamedetailDiv.innerText = userName;
                    codeutilisateurDiv.innerText = codeUser;
                    //
                    navigateConnectBack(-1);
                }
            });
        }
    }

    const goBackConnectHandler = async (e) => {
        e.preventDefault();

        navigateConnectBack('/');
    }

    return ( 
        <DivContainerSlide id="connectaccount">
            <br/>
            <div style={{marginTop:'-2rem'}}>
                <SpanGlobalSpan style={{textAlign:'center'}}>
                    <GlobalStrongTitle>{t("identifier")}</GlobalStrongTitle>
                </SpanGlobalSpan>
            </div>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'-2rem'}}>
                <img src={imageaccount} alt="" />
            </div>
            <FormScrollerWidgetSm>
                <h4>{t("connect_titre")}</h4>
                <p className="pconnectstyles">{t("connect_content")}</p>
                <div className="connectaccountstyle">
                    <br/>
                    <div>
                        <TextLogin>{t("username")}<strong style={{color:'red'}}>*</strong></TextLogin>
                        <div style={{display:'block',textAlign:'center'}}>
                            <GlobalLargeInput type='text' ref={connectUsernameRef}  placeholder={t("placeusername")}/>
                        </div>
                        <br/>
                        <TextLogin>{t("password")}<strong style={{color:'red'}}>*</strong></TextLogin>
                        <div style={{display:'block',textAlign:'center'}}>
                            <GlobalLargeInput type='text' ref={connectPasswordRef} placeholder={t("placepassword")}/>
                        </div>
                        <br/>
                        <p style={{textAlign:'center', color:'#ffffff', fontWeight:'600', cursor:'pointer'}} onClick={creerCompteHandler}>
                            {t("newaccount")}
                        </p>
                        <p style={{textAlign:'center', color:'#ffffff', fontWeight:'600', cursor:'pointer'}} onClick={modifierCompteHandler}>
                            {t("editaccount")}
                        </p>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <ButtonStart onClick={submitConnectHandler}>
                                    <MdOutlineConnectedTv style={{fontSize:'30px'}} />
                                    {t("acceder")}
                                </ButtonStart>
                            </div>
                            <div>
                                <BackStart onClick={goBackConnectHandler}>
                                    <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                    {t("retour")}
                                </BackStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertconnectuser"></div>
                <div id="backdropoutalertconnectuser"></div>
            </FormScrollerWidgetSm>
        </DivContainerSlide>
    );
}

export default ConnectAccount;