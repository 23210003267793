import React from 'react';
import { useNavigate } from "react-router-dom";
import '../components/components.css';
//
import imageaccount from '../carions-icon-192.png';
//
import { useTranslation } from 'react-i18next';
import '../translations/i18n';
import '../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,
    ReducedFormScrollerWidgetSm, 
    AdminButton,
    BackStart,  
} from '../global/Styles';

function ServicesSeoService() {
    const {t} = useTranslation();

    const navigateServiceSeoTab = useNavigate();

    const serviceKeywordHandler = async (e) => {
        e.preventDefault();

        navigateServiceSeoTab('/serviceskeywords');
    }

    const serviceOnPageHandler = async (e) => {
        e.preventDefault();

        navigateServiceSeoTab('/servicesonpage');
    }

    const serviceTechnicalHandler = async (e) => {
        e.preventDefault();

        navigateServiceSeoTab('/servicestechnical');
    }

    const serviceMonthlyHandler = async (e) => {
        e.preventDefault();

        navigateServiceSeoTab('/servicesmonthly');
    }


    const goBackServiceSeoHandler = async (e) => {
        e.preventDefault();

        navigateServiceSeoTab(-1);
    }

    return (
        <DivContainerSlide id='servicesseoservice'>
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t("seoconsulting")}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <img src={imageaccount} alt="" />
            </div>
            <ReducedFormScrollerWidgetSm>
                <div className="adminservicesstyle">
                    <div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={serviceKeywordHandler}>{t("keyword_research")}</AdminButton>
                        </div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={serviceOnPageHandler}>{t("on_page")}</AdminButton>
                        </div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={serviceTechnicalHandler}>{t("technical_audit")}</AdminButton>
                        </div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={serviceMonthlyHandler}>{t("monthly_report")}</AdminButton>
                        </div>
                    </div>
                    <br/>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                        <div>
                            <BackStart onClick={goBackServiceSeoHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </div>
                    <br/>
                </div>
            </ReducedFormScrollerWidgetSm>
        </DivContainerSlide>
    )
}

export default ServicesSeoService