import React from "react-dom";
import { useNavigate } from "react-router-dom";
//build tabs tools
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
//
import { AlertModal } from '../../../helperfunctions/Helper';
//
import '../services.css';
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import { HiOutlineClock } from 'react-icons/hi';
import { TbRefresh } from 'react-icons/tb';
//
import {
    SpanGlobalSpan,
    GlobalStrongTitle,
    TabsForm, 
    DivTabRowAround,
    DivTabRowBetween,
    PTab10,
    DivTabColumnAround,
    PTab10Bt0,
    BackStart,  
    HandlerContainerDiv,
} from '../../../global/Styles';

import { useState } from "react";
import Axios from 'axios';

function SoftDevelopmentServicesTab() {
    const {t} = useTranslation();

    const navigateSoftDevTab = useNavigate();

    //alert
    const elementOutId = "modaloutalertsoftdevelopmenttab";
    const elementDropId = "backdropoutalertsoftdevelopmenttab";
    const buttonHandler = "ouiHandlerAlertSoftDevelopmentTabBtn";

    const [softDevelopmentTabBasic, getSoftDevelopmentTabBasic] = useState([0]);

    var resultatappbasic = "";

    //http://localhost:5000
    //https://micarionsapi.carions.net

    const softDevelopmentBasicHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'basic';
        //show the tab form paragraphs and panel
        const softdevparagraphDiv = document.getElementById('softdevparagraph');
        const softdevpanelDiv = document.getElementById('softdevpanel');
        //
        await Axios.post("https://micarionsapi.carions.net/seo/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getSoftDevelopmentTabBasic(responseappbasic.data);
                //
                softdevparagraphDiv.style.display = 'block';
                softdevpanelDiv.style.display = 'block';
            }
        });
    }

    const softDevelopmentStandardHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'standard';
        //show the tab form paragraphs and panel
        const softdevparagraphDiv = document.getElementById('softdevparagraph');
        const softdevpanelDiv = document.getElementById('softdevpanel');
        //
        await Axios.post("https://micarionsapi.carions.net/seo/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getSoftDevelopmentTabBasic(responseappbasic.data);
                //
                softdevparagraphDiv.style.display = 'block';
                softdevpanelDiv.style.display = 'block';
            }
        });
    }

    const softDevelopmentPremiumHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'premium';
        //show the tab form paragraphs and panel
        const softdevparagraphDiv = document.getElementById('softdevparagraph');
        const softdevpanelDiv = document.getElementById('softdevpanel');
        //
        await Axios.post("https://micarionsapi.carions.net/seo/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getSoftDevelopmentTabBasic(responseappbasic.data);
                //
                softdevparagraphDiv.style.display = 'block';
                softdevpanelDiv.style.display = 'block';
            }
        }); 
    }

    //softdevelopmentservicestab proceed processes
    const devbasicHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'basic';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateSoftDevTab('/connectaccount');
            }else{
                navigateSoftDevTab('/proceedwebdev');
            }
        }
    }
    //
    const devstandardHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'standard';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateSoftDevTab('/connectaccount');
            }else{
                navigateSoftDevTab('/proceedwebdev');
            }
        }
    }
    //
    const devpremiumHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'premium';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateSoftDevTab('/connectaccount');
            }else{
                navigateSoftDevTab('/proceedwebdev');
            }
        }
    }

    //softdevelopmentservicestab contact
    const devcontactHandler = async (e) => {
        e.preventDefault();

        navigateSoftDevTab('/contactme');
    }

    const goBackSoftDevelopmentTabHandler = async (e) => {
        e.preventDefault();

        navigateSoftDevTab('/mainsoftwareservices');
    }

    return ( 
        <div id="softdevelopmentservicestab">
            <br/>
            <div style={{marginTop:'-2rem'}}>
                <SpanGlobalSpan style={{textAlign:'center'}}>
                    <GlobalStrongTitle>{t("software_development")}</GlobalStrongTitle>
                </SpanGlobalSpan>
            </div>
            <br/>
            <TabsForm>
                {softDevelopmentTabBasic.map((valdevbasic, indexdevbasic) => (
                    <div key={indexdevbasic} className="tabstyles">
                        <div id="softdevparagraph">
                            <PTab10>
                                {valdevbasic.services_paragraphone}<br/>
                                {valdevbasic.services_paragraphtwo}<br/>
                                {valdevbasic.services_paragraphthree}<br/>
                                {valdevbasic.services_paragraphfour}<br/>
                            </PTab10>
                        </div>
                        <Tabs defaultIndex={0}>
                            <TabList>
                                <Tab onClick={softDevelopmentBasicHandler}>{t("basic_plan")}</Tab>
                                <Tab onClick={softDevelopmentStandardHandler}>Standard</Tab>
                                <Tab onClick={softDevelopmentPremiumHandler}>Premium</Tab>
                            </TabList>
                            <div id="softdevpanel">
                                <TabPanel >
                                    <DivTabRowBetween>
                                        <h3>{valdevbasic.services_titre}</h3><h3>{valdevbasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valdevbasic.services_description} 
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valdevbasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valdevbasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valdevbasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valdevbasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox"></input>{valdevbasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox"></input>{valdevbasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valdevbasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={devbasicHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={devcontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                                <TabPanel>
                                    <DivTabRowBetween>
                                        <h3>{valdevbasic.services_titre}</h3><h3>{valdevbasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valdevbasic.services_description}
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valdevbasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valdevbasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valdevbasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valdevbasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valdevbasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox"></input>{valdevbasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valdevbasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={devstandardHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={devcontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                                <TabPanel>
                                    <DivTabRowBetween>
                                        <h3>{valdevbasic.services_titre}</h3><h3>{valdevbasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valdevbasic.services_description}
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valdevbasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valdevbasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valdevbasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valdevbasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valdevbasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valdevbasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valdevbasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={devpremiumHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={devcontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                ))}
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackSoftDevelopmentTabHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertsoftdevelopmenttab"></div>
                <div id="backdropoutalertsoftdevelopmenttab"></div>
            </TabsForm>
        </div>
    );
}

export default SoftDevelopmentServicesTab;