import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../proceedweb.css';
import { useRef, useState } from "react";
//
import { MdOutlineCreate, MdSettingsBackupRestore, MdOutlineArrowForwardIos, MdOutlineArrowBackIos, MdOutlineAttachEmail } from 'react-icons/md';
import { AiOutlineWhatsApp } from 'react-icons/ai';
//
import { AlertModal, getCurrentDate, PopupModal } from "../../helperfunctions/Helper";
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
import {  
    SpanGlobalSpan,
    GlobalStrongTitle,
    LongFormScrollerWidgetSm,
    TextProceedService, 
    TextLoginService,
    GlobalLargeInput, 
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
    TextArea,
} from "../../global/Styles";

import Axios from 'axios';

function ProceedWebApp() {
    const {t} = useTranslation();

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleChange = event => {
        if (!isValidEmail(event.target.value)) {
          setError(t('invalidemail'));
        } else {
          setError(null);
        }
    
        setEmail(event.target.value);
    };

    const navigateProceedWebApp = useNavigate();

    //alert
    const elementOutId = "modaloutproceedwebapp";
    const elementDropId = "backdropoutproceedwebapp";
    const buttonHandler = "ouiHandlerProceedWebAppBtn";
    //
    const elementOutAlertId = "modaloutalertproceedwebapp";
    const elementDropAlertId = "backdropoutalertproceedwebapp";
    const buttonAlertHandler = "ouiHandleralertProceedWebAppBtn";

    const webAppSiteQuestion1Ref = useRef(null);
    const webAppSiteQuestion2Ref = useRef(null);
    const webAppSiteQuestion3Ref = useRef(null);
    //
    const webAppSiteQuestion4Ref = useRef(null);
    const webAppSiteQuestion5Ref = useRef(null);
    const webAppSiteQuestion6Ref = useRef(null);
    //
    const webAppSiteQuestion7Ref = useRef(null);
    const webAppSiteQuestion8Ref = useRef(null);
    const webAppSiteQuestion9Ref = useRef(null);
    //
    const webAppSiteQuestion10Ref = useRef(null);
    const webAppSiteQuestion11Ref = useRef(null);
    const webAppSiteQuestion12Ref = useRef(null);
    //
    const webAppSiteQuestion13Ref = useRef(null);
    const webAppSiteQuestion14Ref = useRef(null);

    const popwebapp1Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_1");
        const pTexteNotAvailable = t("hintwebquestion_1");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popwebapp2Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_2");
        const pTexteNotAvailable = t("hintwebquestion_2");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popwebapp3Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_3");
        const pTexteNotAvailable = t("hintwebquestion_3");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const popwebapp4Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_4");
        const pTexteNotAvailable = t("hintwebquestion_4");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popwebapp5Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_5");
        const pTexteNotAvailable = t("hintwebquestion_5");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popwebapp6Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_6");
        const pTexteNotAvailable = t("hintwebquestion_6");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const popwebapp7Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_7");
        const pTexteNotAvailable = t("hintwebquestion_7");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popwebapp8Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_8");
        const pTexteNotAvailable = t("hintwebquestion_8");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popwebapp9Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_9");
        const pTexteNotAvailable = t("hintwebquestion_9");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const popwebapp10Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_10");
        const pTexteNotAvailable = t("hintwebquestion_10");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popwebapp11Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_11");
        const pTexteNotAvailable = t("hintwebquestion_11");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popwebapp12Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("webquestion_12");
        const pTexteNotAvailable = t("hintwebquestion_12");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const forwardProceedWebApp1Handler = async (e) => {
        e.preventDefault();

        const webappsteponeDiv = document.getElementById('webappstepone');
        const webappsteptwoDiv = document.getElementById('webappsteptwo');
        const webappstepthreeDiv = document.getElementById('webappstepthree');
        const webappstepfourDiv = document.getElementById('webappstepfour');
        const modeproceedwebappDiv = document.getElementById('modeproceedwebapp');
        const submitproceedwebappDiv = document.getElementById('submitproceedwebapp');
        //
        webappsteponeDiv.style.display = 'none';
        webappsteptwoDiv.style.display = 'block';
        webappstepthreeDiv.style.display = 'none';
        webappstepfourDiv.style.display = 'none';
        modeproceedwebappDiv.style.display = 'none';
        submitproceedwebappDiv.style.display = 'none';
    }

    const goBackProceedWebApp2Handler = async (e) => {
        e.preventDefault();

        const webappsteponeDiv = document.getElementById('webappstepone');
        const webappsteptwoDiv = document.getElementById('webappsteptwo');
        const webappstepthreeDiv = document.getElementById('webappstepthree');
        const webappstepfourDiv = document.getElementById('webappstepfour');
        const modeproceedwebappDiv = document.getElementById('modeproceedwebapp');
        const submitproceedwebappDiv = document.getElementById('submitproceedwebapp');
        //
        webappsteponeDiv.style.display = 'block';
        webappsteptwoDiv.style.display = 'none';
        webappstepthreeDiv.style.display = 'none';
        webappstepfourDiv.style.display = 'none';
        modeproceedwebappDiv.style.display = 'none';
        submitproceedwebappDiv.style.display = 'none';
    }

    const forwardProceedWebApp2Handler = async (e) => {
        e.preventDefault();

        const webappsteponeDiv = document.getElementById('webappstepone');
        const webappsteptwoDiv = document.getElementById('webappsteptwo');
        const webappstepthreeDiv = document.getElementById('webappstepthree');
        const webappstepfourDiv = document.getElementById('webappstepfour');
        const modeproceedwebappDiv = document.getElementById('modeproceedwebapp');
        const submitproceedwebappDiv = document.getElementById('submitproceedwebapp');
        //
        webappsteponeDiv.style.display = 'none';
        webappsteptwoDiv.style.display = 'none';
        webappstepthreeDiv.style.display = 'block';
        webappstepfourDiv.style.display = 'none';
        modeproceedwebappDiv.style.display = 'none';
        submitproceedwebappDiv.style.display = 'none';
    }

    const goBackProceedWebApp3Handler = async (e) => {
        e.preventDefault();

        const webappsteponeDiv = document.getElementById('webappstepone');
        const webappsteptwoDiv = document.getElementById('webappsteptwo');
        const webappstepthreeDiv = document.getElementById('webappstepthree');
        const webappstepfourDiv = document.getElementById('webappstepfour');
        const modeproceedwebappDiv = document.getElementById('modeproceedwebapp');
        const submitproceedwebappDiv = document.getElementById('submitproceedwebapp');
        //
        webappsteponeDiv.style.display = 'none';
        webappsteptwoDiv.style.display = 'block';
        webappstepthreeDiv.style.display = 'none';
        webappstepfourDiv.style.display = 'none';
        modeproceedwebappDiv.style.display = 'none';
        submitproceedwebappDiv.style.display = 'none';
    }

    const forwardProceedWebApp3Handler = async (e) => {
        e.preventDefault();

        const webappsteponeDiv = document.getElementById('webappstepone');
        const webappsteptwoDiv = document.getElementById('webappsteptwo');
        const webappstepthreeDiv = document.getElementById('webappstepthree');
        const webappstepfourDiv = document.getElementById('webappstepfour');
        const modeproceedwebappDiv = document.getElementById('modeproceedwebapp');
        const submitproceedwebappDiv = document.getElementById('submitproceedwebapp');
        //
        webappsteponeDiv.style.display = 'none';
        webappsteptwoDiv.style.display = 'none';
        webappstepthreeDiv.style.display = 'none';
        webappstepfourDiv.style.display = 'block';
        modeproceedwebappDiv.style.display = 'none';
        submitproceedwebappDiv.style.display = 'none';
    }

    const goBackProceedWebApp4Handler = async (e) => {
        e.preventDefault();

        const webappsteponeDiv = document.getElementById('webappstepone');
        const webappsteptwoDiv = document.getElementById('webappsteptwo');
        const webappstepthreeDiv = document.getElementById('webappstepthree');
        const webappstepfourDiv = document.getElementById('webappstepfour');
        const modeproceedwebappDiv = document.getElementById('modeproceedwebapp');
        const submitproceedwebappDiv = document.getElementById('submitproceedwebapp');
        //
        webappsteponeDiv.style.display = 'none';
        webappsteptwoDiv.style.display = 'none';
        webappstepthreeDiv.style.display = 'block';
        webappstepfourDiv.style.display = 'none';
        modeproceedwebappDiv.style.display = 'none';
        submitproceedwebappDiv.style.display = 'none';
    }

    const forwardProceedWebApp4Handler = async (e) => {
        e.preventDefault();

        const webappsteponeDiv = document.getElementById('webappstepone');
        const webappsteptwoDiv = document.getElementById('webappsteptwo');
        const webappstepthreeDiv = document.getElementById('webappstepthree');
        const webappstepfourDiv = document.getElementById('webappstepfour');
        const modeproceedwebappDiv = document.getElementById('modeproceedwebapp');
        const submitproceedwebappDiv = document.getElementById('submitproceedwebapp');
        //
        webappsteponeDiv.style.display = 'none';
        webappsteptwoDiv.style.display = 'none';
        webappstepthreeDiv.style.display = 'none';
        webappstepfourDiv.style.display = 'none';
        modeproceedwebappDiv.style.display = 'block';
        submitproceedwebappDiv.style.display = 'none';
    }

    const goBackProceedWebApp5Handler = async (e) => {
        e.preventDefault();

        const webappsteponeDiv = document.getElementById('webappstepone');
        const webappsteptwoDiv = document.getElementById('webappsteptwo');
        const webappstepthreeDiv = document.getElementById('webappstepthree');
        const webappstepfourDiv = document.getElementById('webappstepfour');
        const modeproceedwebappDiv = document.getElementById('modeproceedwebapp');
        const submitproceedwebappDiv = document.getElementById('submitproceedwebapp');
        //
        webappsteponeDiv.style.display = 'none';
        webappsteptwoDiv.style.display = 'none';
        webappstepthreeDiv.style.display = 'none';
        webappstepfourDiv.style.display = 'block';
        modeproceedwebappDiv.style.display = 'none';
        submitproceedwebappDiv.style.display = 'none';
    }

    const forwardProceedWebApp5Handler = async (e) => {
        e.preventDefault();

        const webAppSiteQuestion13 = webAppSiteQuestion13Ref.current.value;
        const webAppSiteQuestion14 = webAppSiteQuestion14Ref.current.value;
        //
        const webappsteponeDiv = document.getElementById('webappstepone');
        const webappsteptwoDiv = document.getElementById('webappsteptwo');
        const webappstepthreeDiv = document.getElementById('webappstepthree');
        const webappstepfourDiv = document.getElementById('webappstepfour');
        const modeproceedwebappDiv = document.getElementById('modeproceedwebapp');
        const submitproceedwebappDiv = document.getElementById('submitproceedwebapp');
        //
        if(!webAppSiteQuestion13){
            const pTexteSaisir = t("enteremail");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else if(!webAppSiteQuestion14){
            const pTexteSaisir = t("enterphone");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else{
            //
            webappsteponeDiv.style.display = 'none';
            webappsteptwoDiv.style.display = 'none';
            webappstepthreeDiv.style.display = 'none';
            webappstepfourDiv.style.display = 'none';
            modeproceedwebappDiv.style.display = 'none';
            submitproceedwebappDiv.style.display = 'block';
        }
    }

    const goBackProceedWebApp6Handler = async (e) => {
        e.preventDefault();

        const webappsteponeDiv = document.getElementById('webappstepone');
        const webappsteptwoDiv = document.getElementById('webappsteptwo');
        const webappstepthreeDiv = document.getElementById('webappstepthree');
        const webappstepfourDiv = document.getElementById('webappstepfour');
        const modeproceedwebappDiv = document.getElementById('modeproceedwebapp');
        const submitproceedwebappDiv = document.getElementById('submitproceedwebapp');
        //
        webappsteponeDiv.style.display = 'none';
        webappsteptwoDiv.style.display = 'none';
        webappstepthreeDiv.style.display = 'none';
        webappstepfourDiv.style.display = 'none';
        modeproceedwebappDiv.style.display = 'block';
        submitproceedwebappDiv.style.display = 'none';
    }

    //http://localhost:5000/check/webappservice

    const submitProceedWebAppHandler = async (e) => {
        e.preventDefault();

        const webAppSiteQuestion1 = webAppSiteQuestion1Ref.current.value;
        const webAppSiteQuestion2 = webAppSiteQuestion2Ref.current.value;
        const webAppSiteQuestion3 = webAppSiteQuestion3Ref.current.value;
        //websteptwo
        const webAppSiteQuestion4 = webAppSiteQuestion4Ref.current.value;
        const webAppSiteQuestion5 = webAppSiteQuestion5Ref.current.value;
        const webAppSiteQuestion6 = webAppSiteQuestion6Ref.current.value;
        //webstepthree
        const webAppSiteQuestion7 = webAppSiteQuestion7Ref.current.value;
        const webAppSiteQuestion8 = webAppSiteQuestion8Ref.current.value;
        const webAppSiteQuestion9 = webAppSiteQuestion9Ref.current.value;
        //webstepfour
        const webAppSiteQuestion10 = webAppSiteQuestion10Ref.current.value;
        const webAppSiteQuestion11 = webAppSiteQuestion11Ref.current.value;
        const webAppSiteQuestion12 = webAppSiteQuestion12Ref.current.value;
        //modeproceedweb
        const webAppSiteQuestion13 = webAppSiteQuestion13Ref.current.value;
        const webAppSiteQuestion14 = webAppSiteQuestion14Ref.current.value;
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const theDate = getCurrentDate();
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = "Webapp";
        const servicePlan = document.getElementById('typeplan').innerText;
        var createTicketCode = "";
        //
        var resultat1 = "";
        
        //generate random ticket code
        var randomNumber = Math.floor((Math.random() * 100000) + 1);
        createTicketCode = randomNumber+''+codeutilisateurDiv;
        //
        //console.log(createTicketCode);
        //check if this plan has already been submited
        await Axios.post("https://micarionsapi.carions.net/check/webappservice", {
            codeutilisateurDiv:codeutilisateurDiv,
            typeService:typeService,
            servicePlan:servicePlan,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                //insert
                Axios.post("https://micarionsapi.carions.net/quest/webappservice", {
                    choixlangueDiv:choixlangueDiv,
                    usernamedetailDiv:usernamedetailDiv,
                    codeutilisateurDiv:codeutilisateurDiv,
                    //
                    typeService:typeService,
                    servicePlan:servicePlan,
                    //
                    createTicketCode:createTicketCode,
                    //
                    webAppSiteQuestion1:webAppSiteQuestion1,
                    webAppSiteQuestion2:webAppSiteQuestion2,
                    webAppSiteQuestion3:webAppSiteQuestion3,
                    //
                    webAppSiteQuestion4:webAppSiteQuestion4,
                    webAppSiteQuestion5:webAppSiteQuestion5,
                    webAppSiteQuestion6:webAppSiteQuestion6,
                    //
                    webAppSiteQuestion7:webAppSiteQuestion7,
                    webAppSiteQuestion8:webAppSiteQuestion8,
                    webAppSiteQuestion9:webAppSiteQuestion9,
                    //
                    webAppSiteQuestion10:webAppSiteQuestion10,
                    webAppSiteQuestion11:webAppSiteQuestion11,
                    webAppSiteQuestion12:webAppSiteQuestion12,
                    //
                    webAppSiteQuestion13:webAppSiteQuestion13,
                    webAppSiteQuestion14:webAppSiteQuestion14,
                    //
                    theDate:theDate,
                }).then(() => {
                    var answer1 = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                    const hTitleTexte = t("proceedwebapptitle");
                    const pTexteNotAvailable = answer1;
                    PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
                });
            }else{
                //set up modal to update
                var answer = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                console.log(resultat1);
                const hTitleTexte = t("proceedwebapptitle");
                const pTexteNotAvailable = answer;
                PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
            }
        });//
    }

    const goBackProceedWebAppHandler = async (e) => {
        e.preventDefault();

        navigateProceedWebApp('/webapptab')
    }

    return ( 
        <div id="proceedwebapp">
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t('proceedwebapptitle')}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <LongFormScrollerWidgetSm>
                <div id="modaloutproceedwebapp"></div>
                <div id="backdropoutproceedwebapp"></div>
                <p className="pservicestyles"><br/>{t("proceedwebdescription")}</p>
                <div id="webappstepone">
                    <TextProceedService onClick={popwebapp1Handler}>{t("webquestion_1")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webAppSiteQuestion1Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popwebapp2Handler}>{t("webquestion_2")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webAppSiteQuestion2Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popwebapp3Handler}>{t("webquestion_3")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webAppSiteQuestion3Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <ButtonStart onClick={forwardProceedWebApp1Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="webappsteptwo">
                    <TextProceedService onClick={popwebapp4Handler}>{t("webquestion_4")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webAppSiteQuestion4Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popwebapp5Handler}>{t("webquestion_5")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webAppSiteQuestion5Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popwebapp6Handler}>{t("webquestion_6")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webAppSiteQuestion6Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedWebApp2Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedWebApp2Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="webappstepthree">
                    <TextProceedService onClick={popwebapp7Handler}>{t("webquestion_7")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webAppSiteQuestion7Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popwebapp8Handler}>{t("webquestion_8")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webAppSiteQuestion8Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popwebapp9Handler}>{t("webquestion_9")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webAppSiteQuestion9Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedWebApp3Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedWebApp3Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="webappstepfour">
                    <TextProceedService onClick={popwebapp10Handler}>{t("webquestion_10")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webAppSiteQuestion10Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popwebapp11Handler}>{t("webquestion_11")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webAppSiteQuestion11Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popwebapp12Handler}>{t("webquestion_12")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={webAppSiteQuestion12Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedWebApp4Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedWebApp4Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="modeproceedwebapp">
                    <TextProceedService>{t("webquestion_13")}</TextProceedService>
                    <TextLoginService><MdOutlineAttachEmail style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput id="email" name="email" value={email} onChange={handleChange} ref={webAppSiteQuestion13Ref} placeholder={t("placemail")}/>
                        {error && <h5 style={{color: 'white'}}>{error}</h5>}
                    </div>
                    <br/>
                    <TextLoginService><AiOutlineWhatsApp style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                            <GlobalLargeInput type='number' ref={webAppSiteQuestion14Ref}  placeholder={t("placewhatsapp")}/>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedWebApp5Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedWebApp5Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="submitproceedwebapp">
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedWebApp6Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={submitProceedWebAppHandler}>
                                    <MdOutlineCreate style={{fontSize:'30px'}} />
                                   {t("creer")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackProceedWebAppHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertproceedwebapp"></div>
                <div id="backdropoutalertproceedwebapp"></div>
            </LongFormScrollerWidgetSm>
        </div>
    );
}

export default ProceedWebApp;