import React from "react-dom";
import '../../components.css';
//import myeditor
import EditServicesEditor from "../servicesquilleditor/EditServicesEditor";
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle, 
} from '../../../global/Styles';

function SericeEditContenu() {
    const {t} = useTranslation();

    return ( 
        <DivContainerSlide id="serviceeditcontenu">
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t("crudcontenumodifier")} {t("services")}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <div className="lescontenus">
                <EditServicesEditor />
                <br/>
            </div>
        </DivContainerSlide>
    );
}

export default SericeEditContenu;