import React from 'react';
import { useNavigate } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import Axios from 'axios';
//build tabs tools
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../../components/reactquilleditor/myeditor.css';
//
import services from '../services.json';
//
import { AlertModal, frenchDateFormat, formatDateData, getCurrentDate, PopupModal, addCurrentDate } from '../../helperfunctions/Helper';
//
import '../../components/services/services.css';
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
    MdOutlineCreate,
    MdOutlineEdit,
    MdSaveAlt,
    MdOutlineCancel,
} from 'react-icons/md';
//
import { FiSettings } from 'react-icons/fi';
//
import {
    SpanGlobalSpan,
    GlobalStrongTitle,
    TabsForm, 
    BackStart,  
    HandlerContainerDiv,
    TextLoginService,
    TextAreaView,
    ButtonStart,
    LargeInput,
    TextArea,
    TextProceedService,
    TextLogin,
    GlobalLargeInput,
} from '../../global/Styles';
//
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import katex from 'katex';
import "katex/dist/katex.min.css";
window.katex = katex;

const modules = {
  toolbar: [[{header: [1, 2, 3, 4, 5, 6,  false] }],
  [{ color: [] }],
  [{ background: [] }],
  [{ script:  "sub" }, { script:  "super" }],
  [{ font: [] }],
  [{ size: [] }],
  ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
  [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
  ],
  ["link", "image", "video", "formula"],
  ["clean"],
],
};
//
var codingPlans = "";
var servicePlan = "";
//
function ServicesCoding() {
    const {t} = useTranslation();

    const navigateServiceAdminTabcoding = useNavigate();

    //alert
    const elementOutId = "modaloutalertserviceadmintabcoding";
    const elementDropId = "backdropoutalertserviceadmintabcoding";
    const buttonHandler = "ouiHandlerAlertServiceAdminTabCodingBtn";

    //onbloard coding
    const [serviceAdminCodingTab, getServiceAdminCoding] = useState([0]);
    const [valueServiceAdminRead, setValueServiceAdminRead] = useState([0]);
    //planning coding
    const [servicePlanningCodingTab, getServicePlanningCoding] = useState([0]);
    const [valueServicePlanningRead, setValueServicePlanningRead] = useState([0]);
    //schedule coding
    const [serviceScheduleCodingTab, getServiceScheduleCoding] = useState([0]);
    const [valueServiceScheduleRead, setValueServiceScheduleRead] = useState([0]);
    //schedule communication
    const [serviceCommunicationCodingTab, getServiceCommunicationCoding] = useState([0]);
    const [valueServiceCommunicationRead, setValueServiceCommunicationRead] = useState([0]);
    //onboard actualiser 
    const [serviceActualiserCodingTab, getServiceActualiserCoding] = useState([0]);
    const [viewActualiserService, setViewActualierService] = useState([0]);
    //contract
    const [serviceContractCodingTab, getServiceContractCoding] = useState([0]);
    //budget
    const [serviceBudgetCodingTab, getServiceBudgetCoding] = useState([0]);
    const [valueServiceBudgetRead, setValueServiceBudgetRead] = useState([0]);
    //planning actualiser
    const [viewPlanningActualiserService, setViewPlanningActualierService] = useState([0]);
    //schedule actualiser
    const [viewScheduleActualiserService, setViewScheduleActualierService] = useState([0]);
    //communication actualiser
    const [viewCommunicationActualiserService, setViewCommunicationActualierService] = useState([0]);
    //contract
    const [valueContractService, setValueContractService] = useState([0]);
    const [valueContract, setValueContract] = useState("");
    const [valueEditContract, setValueEditContract] = useState("");
    //contract actualiser
    const [viewContractActualiserService, setViewContractActualierService] = useState([0]);
    //budget actualiser
    const [viewBudgetActualiserService, setViewBudgetActualierService] = useState([0]);
    //

    //instatiate data
    const [data, setData] = useState([]);

    //http://localhost:5000/schedule/list
    //https://micarionsapi.carions.net/schedule/list

    // make the Axios request and update the data state with the response
    useEffect(() => {
        const statusSchedule = "online";
        const fetchData = async () => {
            await Axios.post("https://micarionsapi.carions.net/schedule/list", {
                statusSchedule:statusSchedule,
            })
            .then((responseSchedule) => {
                setData(responseSchedule.data);
            })
            .catch(error => console.log(error));
        }
        fetchData();
    }, []);

    //coding
    const serviceMailRef = useRef(null);
    const serviceWhatsappRef = useRef(null);
    //planning
    const createRequirementsRef = useRef(null);
    const createPlanRef = useRef(null);
    const createMilestonesRef = useRef(null);
    const createTimelinesRef = useRef(null);
    const createResponsibilitiesRef = useRef(null);
    const createContingenciesRef = useRef(null);
    const createAdjustRef = useRef(null);
    //schedule
    const dateScheduleRef = useRef(null);
    const startScheduleRef = useRef(null);
    const endScheduleRef = useRef(null);
    const fullNameScheduleRef = useRef(null);
    const emailScheduleRef = useRef(null);
    const whatsappScheduleRef = useRef(null);
    const notesScheduleRef = useRef(null);
    const serviceMailScheduleRef = useRef(null);
    const serviceWhatsappScheduleRef = useRef(null);
    //Communication
    const dateCommunicationRef = useRef(null);
    const startCommunicationRef = useRef(null);
    const endCommunicationRef = useRef(null);
    const fullNameCommunicationRef = useRef(null);
    const emailCommunicationRef = useRef(null);
    const whatsappCommunicationRef = useRef(null);
    const notesCommunicationRef = useRef(null);
    const serviceMailCommunicationRef = useRef(null);
    const serviceWhatsappCommunicationRef = useRef(null);
    //wequest
    const webQuestion1Ref = useRef(null);
    const webQuestion2Ref = useRef(null);
    const webQuestion3Ref = useRef(null);
    const webQuestion4Ref = useRef(null);
    const webQuestion5Ref = useRef(null);
    const webQuestion6Ref = useRef(null);
    const webQuestion7Ref = useRef(null);
    const webQuestion8Ref = useRef(null);
    const webQuestion9Ref = useRef(null);
    const webQuestion10Ref = useRef(null);
    const webQuestion11Ref = useRef(null);
    const webQuestion12Ref = useRef(null);
    const webQuestion13Ref = useRef(null);
    const webQuestion14Ref = useRef(null);
    //planning
    const editRequirementsRef = useRef(null);
    const editPlanRef = useRef(null);
    const editMilestonesRef = useRef(null);
    const editTimelinesRef = useRef(null);
    const editResponsibilitiesRef = useRef(null);
    const editContingenciesRef = useRef(null);
    const editAdjustRef = useRef(null);
    //schedule
    const editStartScheduleRef = useRef(null);
    const editEndScheduleRef = useRef(null);
    const editFullNameScheduleRef = useRef(null);
    const editEmailScheduleRef = useRef(null);
    const editWhatsappScheduleRef = useRef(null);
    const editNotesScheduleRef = useRef(null);
    //communication
    const editStartCommunicationRef = useRef(null);
    const editEndCommunicationRef = useRef(null);
    const editFullNameCommunicationRef = useRef(null);
    const editEmailCommunicationRef = useRef(null);
    const editWhatsappCommunicationRef = useRef(null);
    const editNotesCommunicationRef = useRef(null);
    //contract
    const idEditContractCodingRef = useRef(null);
    const editTitreContractCodingRef = useRef(null);
    const editcontracreditorTextContractRef = useRef(null);
    const idEditViewContractCodingRef = useRef(null);
    //
    const editcontracreditorViewContractRef = useRef(null);
    //budget
    const serviceBudgetPlanCodingRef = useRef(null);
    const serviceBudgetDescriptionCodingRef = useRef(null);
    const serviceBudgetPriceCodingRef = useRef(null);
    const serviceBudgetDeliveryCodingRef = useRef(null);
    const serviceBudgetRevisionCodingRef = useRef(null);
    const serviceBudgetPageCodingRef = useRef(null);
    const serviceBudgetOhterCostCodingRef = useRef(null);
    const serviceBudgetUpfrontCodingRef = useRef(null);
    const serviceBudgetAddNotesCodingRef = useRef(null);
    //
    const serviceBudgetTotalCodingRef = useRef(null);
    const serviceBudgetResteCodingRef = useRef(null);
    const serviceBudgetDueDateCodingRef = useRef(null);
    //actualiser budget
    const serviceActBudgetPlanCodingRef = useRef(null);
    const serviceActBudgetDescriptionCodingRef = useRef(null);
    const serviceActBudgetPriceCodingRef = useRef(null);
    const serviceActBudgetDeliveryCodingRef = useRef(null);
    const serviceActBudgetRevisionCodingRef = useRef(null);
    const serviceActBudgetPageCodingRef = useRef(null);
    const serviceActBudgetOhterCostCodingRef = useRef(null);
    const serviceActBudgetUpfrontCodingRef = useRef(null);
    const serviceActBudgetAddNotesCodingRef = useRef(null);
    //
    const serviceActBudgetTotalCodingRef = useRef(null);
    const serviceActBudgetResteCodingRef = useRef(null);
    const serviceActBudgetDueDateCodingRef = useRef(null);

    /** admin_onboard */
    const adminOnbaordServiceNew = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const serviceadminpanelcodingnewDiv = document.getElementById('serviceadminpanelcodingnew');
        const serviceadminpanelcodingreadDiv = document.getElementById('serviceadminpanelcodingread');
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const webQuestType = 'coding';
        //
        var resultatservice = 0;

        //http://localhost:5000/admin/new
        //https://micarionsapi.carions.net/admin/new
        await Axios.post("https://micarionsapi.carions.net/admin/new", {
            choixlangueDiv:choixlangueDiv,
            webQuestType:webQuestType,
        }).then((response1) => {
            resultatservice = response1.data;
            if(resultatservice === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                onboardCodingDivContainer.style.display = 'none';
                planningcodingDivContainer.style.display = 'none';
                schedulecodingDivContainer.style.display = 'none';
                communicationcodingDivContainer.style.display = 'none';
                actualisercodingDivContainer.style.display = 'none';
                contractcodingDivContainer.style.display = 'none';
                budgetcodingDivContainer.style.display = 'none';
                //
                serviceadminpanelcodingnewDiv.style.display = 'none';
                serviceadminpanelcodingreadDiv.style.display = 'none';
            }else{
                getServiceAdminCoding(response1.data);
                onboardCodingDivContainer.style.display = 'block';
                planningcodingDivContainer.style.display = 'none';
                schedulecodingDivContainer.style.display = 'none';
                communicationcodingDivContainer.style.display = 'none';
                actualisercodingDivContainer.style.display = 'none';
                contractcodingDivContainer.style.display = 'none';
                budgetcodingDivContainer.style.display = 'none';
                //
                serviceadminpanelcodingnewDiv.style.display = 'block';
                serviceadminpanelcodingreadDiv.style.display = 'none';
            }
        })
    }
    //
    const serviceHeaderHandler = async (indexservice) => {
        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const serviceadminpanelcodingnewDiv = document.getElementById('serviceadminpanelcodingnew');
        const serviceadminpanelcodingreadDiv = document.getElementById('serviceadminpanelcodingread');
        const idService = indexservice;
        //
        //http://localhost:5000/admin/new/read
        //https://micarionsapi.carions.net/admin/new/read
        await Axios.post("https://micarionsapi.carions.net/admin/new/read", {
            idService:idService,
        }).then((response2) => {
            setValueServiceAdminRead(response2.data);
            onboardCodingDivContainer.style.display = 'block';
            planningcodingDivContainer.style.display = 'none';
            schedulecodingDivContainer.style.display = 'none';
            communicationcodingDivContainer.style.display = 'none';
            actualisercodingDivContainer.style.display = 'none';
            contractcodingDivContainer.style.display = 'none';
            budgetcodingDivContainer.style.display = 'none';
            //
            serviceadminpanelcodingnewDiv.style.display = 'none';
            serviceadminpanelcodingreadDiv.style.display = 'block';
        });
    }
    //
    const serviceMailHandler = async (e) => {
        e.preventDefault();

        const receiverEmail = serviceMailRef.current.value;
        window.location.href = `mailto:${receiverEmail}`;
    }
    //
    const serviceWhatsappHandler = async (e) => {
        e.preventDefault();

        const serviceWhatsapp = serviceWhatsappRef.current.value;
        window.location.href = `https://api.whatsapp.com/send?phone=${serviceWhatsapp}`;
    }

    /** admin_planning */
    const adminPlanningService = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const serviceadminpanelcodingnewDiv = document.getElementById('serviceadminpanelcodingnew');
        const serviceadminpanelcodingreadDiv = document.getElementById('serviceadminpanelcodingread');

        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const webQuestType = 'coding';
        //
        var resultatservice = 0;
        //
        //http://localhost:5000/admin/new
        //https://micarionsapi.carions.net/admin/new
        await Axios.post("https://micarionsapi.carions.net/admin/new", {
            choixlangueDiv:choixlangueDiv,
            webQuestType:webQuestType,
        }).then((responsePlanning) => {
            resultatservice = responsePlanning.data.length;
            if(resultatservice === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                onboardCodingDivContainer.style.display = 'none';
                planningcodingDivContainer.style.display = 'none';
                schedulecodingDivContainer.style.display = 'none';
                communicationcodingDivContainer.style.display = 'none';
                actualisercodingDivContainer.style.display = 'none';
                contractcodingDivContainer.style.display = 'none';
                budgetcodingDivContainer.style.display = 'none';
                //
                serviceadminpanelcodingnewDiv.style.display = 'none';
                serviceadminpanelcodingreadDiv.style.display = 'none';
            }else{
                getServicePlanningCoding(responsePlanning.data);
                onboardCodingDivContainer.style.display = 'none';
                planningcodingDivContainer.style.display = 'block';
                schedulecodingDivContainer.style.display = 'none';
                communicationcodingDivContainer.style.display = 'none';
                actualisercodingDivContainer.style.display = 'none';
                contractcodingDivContainer.style.display = 'none';
                budgetcodingDivContainer.style.display = 'none';
                //
                serviceadminpanelcodingnewDiv.style.display = 'block';
                serviceadminpanelcodingreadDiv.style.display = 'none';
            }
        })
    }
    //
    const planningHeaderHandler = async (indexplanning) => {
        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const serviceadminpanelcodingnewDiv = document.getElementById('serviceadminpanelcodingnew');
        const serviceadminpanelcodingreadDiv = document.getElementById('serviceadminpanelcodingread');
        const idService = indexplanning;
        //
        //http://localhost:5000/admin/new/read
        //https://micarionsapi.carions.net/admin/new/read
        await Axios.post("https://micarionsapi.carions.net/admin/new/read", {
            idService:idService,
        }).then((responsereadplanning) => {
            setValueServicePlanningRead(responsereadplanning.data);
            onboardCodingDivContainer.style.display = 'none';
            planningcodingDivContainer.style.display = 'block';
            schedulecodingDivContainer.style.display = 'none';
            communicationcodingDivContainer.style.display = 'none';
            actualisercodingDivContainer.style.display = 'none';
            contractcodingDivContainer.style.display = 'none';
            budgetcodingDivContainer.style.display = 'none';
            //
            serviceadminpanelcodingnewDiv.style.display = 'none';
            serviceadminpanelcodingreadDiv.style.display = 'block';
        });
    }

    const submitCreateServicesPlaningHandler = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        //
        const idWebQuest = document.getElementById('idwebquest').innerText;
        const webQuestCode = document.getElementById('planningcode').innerText;
        const createRequirements = createRequirementsRef.current.value;
        const createPlan = createPlanRef.current.value;
        const createMilestones = createMilestonesRef.current.value;
        const createTimelines = createTimelinesRef.current.value;
        const createResponsibilities = createResponsibilitiesRef.current.value;
        const createContingencies = createContingenciesRef.current.value;
        const createAdjust = createAdjustRef.current.value;
        const createplanningType = 'coding';
        //
        const createPlanningStatut = 'online';
        const createPlanningDate = getCurrentDate();
        //
        var resultatcheck = 0;

        if(!codeutilisateurDiv){
            const pTexteSaisir = "Login before submiting";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createRequirements){
            const pTexteSaisir = t("objective_requirements");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createPlan){
            const pTexteSaisir = t("project_plan");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createMilestones){
            const pTexteSaisir = t("project_milestones");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createTimelines){
            const pTexteSaisir = t("project_timelines");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createResponsibilities){
            const pTexteSaisir = t("task_responsibilities");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createContingencies){
            const pTexteSaisir = t("risks_contingencies");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createAdjust){
            const pTexteSaisir = t("review_adjust");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else{
            //http://localhost:5000
            //https://micarionsapi.carions.net
            //check if idwebquest already exists in planning
            await Axios.post("https://micarionsapi.carions.net/planning/check", {
                idWebQuest:idWebQuest,
            }).then((responsecheck) => {
                resultatcheck = responsecheck.data.length;
                if(resultatcheck === 0){
                    Axios.post("https://micarionsapi.carions.net/planning/create", {
                        idWebQuest:idWebQuest,
                        webQuestCode:webQuestCode,
                        choixlangueDiv:choixlangueDiv,
                        codeutilisateurDiv:codeutilisateurDiv,
                        createRequirements:createRequirements,
                        createPlan:createPlan,
                        createMilestones:createMilestones,
                        createTimelines:createTimelines,
                        createResponsibilities:createResponsibilities,
                        createContingencies:createContingencies,
                        createAdjust:createAdjust,
                        createplanningType:createplanningType,
                        createPlanningStatut:createPlanningStatut,
                        createPlanningDate:createPlanningDate,
                    }).then(() => {
                        const pTexteSaisir = t("planning_succes");
                        AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
                        onboardCodingDivContainer.style.display = 'none';
                        planningcodingDivContainer.style.display = 'none';
                        schedulecodingDivContainer.style.display = 'none';
                        communicationcodingDivContainer.style.display = 'none';
                        actualisercodingDivContainer.style.display = 'none';
                        contractcodingDivContainer.style.display = 'none';
                        budgetcodingDivContainer.style.display = 'none';
                    });
                }else{
                    const pTexteSaisir = t("planning_exists");
                    AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
                    onboardCodingDivContainer.style.display = 'none';
                    planningcodingDivContainer.style.display = 'none';
                    schedulecodingDivContainer.style.display = 'none';
                    communicationcodingDivContainer.style.display = 'none';
                    actualisercodingDivContainer.style.display = 'none';
                    contractcodingDivContainer.style.display = 'none';
                    budgetcodingDivContainer.style.display = 'none';
                }
            });
        }
    }

    /**admin_schedule */
    const adminScheduleService = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const serviceschedulecodingDiv = document.getElementById('serviceschedulecoding');
        const serviceschedulelistcodingDiv = document.getElementById('serviceschedulelistcoding');
        const serviceschedulepanelcodingreadDiv = document.getElementById('serviceschedulepanelcodingread');
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const webQuestType = 'coding';
        //
        var resultatservice = 0;
        //
        //http://localhost:5000
        //https://micarionsapi.carions.net
        await Axios.post("https://micarionsapi.carions.net/admin/new", {
            choixlangueDiv:choixlangueDiv,
            webQuestType:webQuestType,
        }).then((responseSchedule) => {
            resultatservice = responseSchedule.data.length;
            if(resultatservice === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                onboardCodingDivContainer.style.display = 'none';
                planningcodingDivContainer.style.display = 'none';
                schedulecodingDivContainer.style.display = 'none';
                communicationcodingDivContainer.style.display = 'none';
                actualisercodingDivContainer.style.display = 'none';
                contractcodingDivContainer.style.display = 'none';
                budgetcodingDivContainer.style.display = 'none';
                //
                serviceschedulecodingDiv.style.display = 'none';
                serviceschedulelistcodingDiv.style.display = 'none';
                serviceschedulepanelcodingreadDiv.style.display = 'none';
            }else{
                getServiceScheduleCoding(responseSchedule.data);
                onboardCodingDivContainer.style.display = 'none';
                planningcodingDivContainer.style.display = 'none';
                schedulecodingDivContainer.style.display = 'block';
                communicationcodingDivContainer.style.display = 'none';
                actualisercodingDivContainer.style.display = 'none';
                contractcodingDivContainer.style.display = 'none';
                budgetcodingDivContainer.style.display = 'none';
                //
                serviceschedulecodingDiv.style.display = 'block';
                serviceschedulelistcodingDiv.style.display = 'none';
                serviceschedulepanelcodingreadDiv.style.display = 'none';
            }
        });
    }

    //display all schedule list
    const scheduleHandleClick = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const serviceschedulecodingDiv = document.getElementById('serviceschedulecoding');
        const serviceschedulelistcodingDiv = document.getElementById('serviceschedulelistcoding');
        const serviceschedulepanelcodingreadDiv = document.getElementById('serviceschedulepanelcodingread');
        //
        onboardCodingDivContainer.style.display = 'none';
        planningcodingDivContainer.style.display = 'none';
        schedulecodingDivContainer.style.display = 'block';
        communicationcodingDivContainer.style.display = 'none';
        actualisercodingDivContainer.style.display = 'none';
        contractcodingDivContainer.style.display = 'none';
        budgetcodingDivContainer.style.display = 'none';
        //
        serviceschedulecodingDiv.style.display = 'block';
        serviceschedulelistcodingDiv.style.display = 'block';
        serviceschedulepanelcodingreadDiv.style.display = 'none';
    }

    const scheduleHeaderHandler = async (indexschedule) => {
        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const serviceschedulecodingDiv = document.getElementById('serviceschedulecoding');
        const serviceschedulelistcodingDiv = document.getElementById('serviceschedulelistcoding');
        const serviceschedulepanelcodingreadDiv = document.getElementById('serviceschedulepanelcodingread');
        const idService = indexschedule;

        //http://localhost:5000
        //https://micarionsapi.carions.net
        await Axios.post("https://micarionsapi.carions.net/admin/new/read", {
            idService:idService,
        }).then((responsereadschedule) => {
            setValueServiceScheduleRead(responsereadschedule.data);
            onboardCodingDivContainer.style.display = 'none';
            planningcodingDivContainer.style.display = 'none';
            schedulecodingDivContainer.style.display = 'block';
            communicationcodingDivContainer.style.display = 'none';
            actualisercodingDivContainer.style.display = 'none';
            contractcodingDivContainer.style.display = 'none';
            budgetcodingDivContainer.style.display = 'none';
            //
            serviceschedulecodingDiv.style.display = 'block';
            serviceschedulelistcodingDiv.style.display = 'block';
            serviceschedulepanelcodingreadDiv.style.display = 'block';
        });
    }
    //
    const serviceMailScheduleHandler = async (e) => {
        e.preventDefault();

        const receiverScheduleEmail = serviceMailScheduleRef.current.value;
        window.location.href = `mailto:${receiverScheduleEmail}`;
    }
    //
    const serviceWhatsappScheduleHandler = async (e) => {
        e.preventDefault();

        const serviceWhatsappSchedule = serviceWhatsappScheduleRef.current.value;
        window.location.href = `https://api.whatsapp.com/send?phone=${serviceWhatsappSchedule}`;
    }
    //
    const submitCreateServicesScheduleHandler = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        //
        const idWebQuest = document.getElementById('idwebquestschedule').innerText;
        const webQuestCode = document.getElementById('schedulecode').innerText;
        const laDateSchedule = dateScheduleRef.current.value;
        const dateSchedule = formatDateData(laDateSchedule);
        const startSchedule = startScheduleRef.current.value;
        const endSchedule = endScheduleRef.current.value;
        const fullNameSchedule = fullNameScheduleRef.current.value;
        const emailSchedule = emailScheduleRef.current.value;
        const whatsappSchedule = whatsappScheduleRef.current.value;
        const notesSchedule = notesScheduleRef.current.value;
        const schedulecodingType = document.getElementById('schedulecoding').innerText;
        //
        const createScheduleStatut = 'online';
        const createScheduleDateSaved = getCurrentDate();
        //
        var resultatcheck = 0;

        if(!codeutilisateurDiv){
            const pTexteSaisir = "Login before submiting";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!laDateSchedule){
            const pTexteSaisir = "Date";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!startSchedule){
            const pTexteSaisir = "Start Time";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!endSchedule){
            const pTexteSaisir = "End Time";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!fullNameSchedule){
            const pTexteSaisir = "Full Name";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!emailSchedule){
            const pTexteSaisir = "Email";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!whatsappSchedule){
            const pTexteSaisir = "Phone Whatsapp";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!notesSchedule){
            const pTexteSaisir = "Any Important Notes";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else{
            //check if idwebquest already exists in schedule
            //http://localhost:5000
            //https://micarionsapi.carions.net
            await Axios.post("https://micarionsapi.carions.net/schedule/check", {
                idWebQuest:idWebQuest,
            }).then((responseschedulecheck) => {
                resultatcheck = responseschedulecheck.data.length;
                if(resultatcheck === 0){
                    Axios.post("https://micarionsapi.carions.net/schedule/create", {
                        idWebQuest:idWebQuest,
                        webQuestCode:webQuestCode,
                        choixlangueDiv:choixlangueDiv,
                        codeutilisateurDiv:codeutilisateurDiv,
                        dateSchedule:dateSchedule,
                        startSchedule:startSchedule,
                        endSchedule:endSchedule,
                        fullNameSchedule:fullNameSchedule,
                        emailSchedule:emailSchedule,
                        whatsappSchedule:whatsappSchedule,
                        notesSchedule:notesSchedule,
                        createScheduleStatut:createScheduleStatut,
                        schedulecodingType:schedulecodingType,
                        createScheduleDateSaved:createScheduleDateSaved,
                    }).then(() => {
                        const pTexteSaisir = "Schedule has been saved successfully";
                        AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
                        onboardCodingDivContainer.style.display = 'none';
                        planningcodingDivContainer.style.display = 'none';
                        schedulecodingDivContainer.style.display = 'none';
                        communicationcodingDivContainer.style.display = 'none';
                        actualisercodingDivContainer.style.display = 'none';
                        contractcodingDivContainer.style.display = 'none';
                        budgetcodingDivContainer.style.display = 'none';
                    });
                }else{
                    const pTexteSaisir = "This schedule already exists";
                    AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
                    onboardCodingDivContainer.style.display = 'none';
                    planningcodingDivContainer.style.display = 'none';
                    schedulecodingDivContainer.style.display = 'none';
                    communicationcodingDivContainer.style.display = 'none';
                    actualisercodingDivContainer.style.display = 'none';
                    contractcodingDivContainer.style.display = 'none';
                    budgetcodingDivContainer.style.display = 'none';
                }
            });
        }
    }

    /**Communication */
    const adminCommunicationService = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const servicecommunicationcodingDiv = document.getElementById('servicecommunicationcoding');
        const servicecommunicationlistcodingDiv = document.getElementById('servicecommunicationlistcoding');
        const servicecommunicationcodingpanelreadDiv = document.getElementById('servicecommunicationcodingpanelread');
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const webQuestType = 'coding';
        //
        var resultatservice = 0;
        //
        //http://localhost:5000
        //https://micarionsapi.carions.net
        await Axios.post("https://micarionsapi.carions.net/admin/communication/new", {
            choixlangueDiv:choixlangueDiv,
            webQuestType:webQuestType,
        }).then((responseCommunication) => {
            resultatservice = responseCommunication.data.length;
            if(resultatservice === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                onboardCodingDivContainer.style.display = 'none';
                planningcodingDivContainer.style.display = 'none';
                schedulecodingDivContainer.style.display = 'none';
                communicationcodingDivContainer.style.display = 'none';
                actualisercodingDivContainer.style.display = 'none';
                contractcodingDivContainer.style.display = 'none';
                budgetcodingDivContainer.style.display = 'none';
                //
                servicecommunicationcodingDiv.style.display = 'none';
                servicecommunicationlistcodingDiv.style.display = 'none';
                servicecommunicationcodingpanelreadDiv.style.display = 'none';
            }else{
                getServiceCommunicationCoding(responseCommunication.data);
                onboardCodingDivContainer.style.display = 'none';
                planningcodingDivContainer.style.display = 'none';
                schedulecodingDivContainer.style.display = 'none';
                communicationcodingDivContainer.style.display = 'block';
                actualisercodingDivContainer.style.display = 'none';
                contractcodingDivContainer.style.display = 'none';
                budgetcodingDivContainer.style.display = 'none';
                //
                servicecommunicationcodingDiv.style.display = 'block';
                servicecommunicationlistcodingDiv.style.display = 'none';
                servicecommunicationcodingpanelreadDiv.style.display = 'none';
            }
        });
    }

    const communicationHandleClick = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const servicecommunicationcodingDiv = document.getElementById('servicecommunicationcoding');
        const servicecommunicationlistcodingDiv = document.getElementById('servicecommunicationlistcoding');
        const servicecommunicationcodingpanelreadDiv = document.getElementById('servicecommunicationcodingpanelread');
        //
        onboardCodingDivContainer.style.display = 'none';
        planningcodingDivContainer.style.display = 'none';
        schedulecodingDivContainer.style.display = 'none';
        communicationcodingDivContainer.style.display = 'block';
        actualisercodingDivContainer.style.display = 'none';
        contractcodingDivContainer.style.display = 'none';
        budgetcodingDivContainer.style.display = 'none';
        //
        servicecommunicationcodingDiv.style.display = 'block';
        servicecommunicationlistcodingDiv.style.display = 'block';
        servicecommunicationcodingpanelreadDiv.style.display = 'none';
    }

    const communicationHeaderHandler = async (indexcommunication) => {
        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const servicecommunicationcodingDiv = document.getElementById('servicecommunicationcoding');
        const servicecommunicationlistcodingDiv = document.getElementById('servicecommunicationlistcoding');
        const servicecommunicationcodingpanelreadDiv = document.getElementById('servicecommunicationcodingpanelread');
        const idService = indexcommunication;

        //http://localhost:5000
        //https://micarionsapi.carions.net
        await Axios.post("https://micarionsapi.carions.net/admin/new/read", {
            idService:idService,
        }).then((responsereadcommunication) => {
            setValueServiceCommunicationRead(responsereadcommunication.data);
            onboardCodingDivContainer.style.display = 'none';
            planningcodingDivContainer.style.display = 'none';
            schedulecodingDivContainer.style.display = 'none';
            communicationcodingDivContainer.style.display = 'block';
            actualisercodingDivContainer.style.display = 'none';
            contractcodingDivContainer.style.display = 'none';
            budgetcodingDivContainer.style.display = 'none';
            //
            servicecommunicationcodingDiv.style.display = 'block';
            servicecommunicationlistcodingDiv.style.display = 'block';
            servicecommunicationcodingpanelreadDiv.style.display = 'block';
        });
    }

    const serviceWhatsappCommunicationHandler = async (e) => {
        e.preventDefault();

        const serviceWhatsappCommunication = serviceWhatsappCommunicationRef.current.value;
        window.location.href = `https://api.whatsapp.com/send?phone=${serviceWhatsappCommunication}`;
    }

    const serviceMailCommunicationHandler = async (e) => {
        e.preventDefault();

        const receiverCommunicationEmail = serviceMailCommunicationRef.current.value;
        window.location.href = `mailto:${receiverCommunicationEmail}`;
    }

    const submitCreateServicesCommunicationHandler = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        //
        const idWebQuest = document.getElementById('idwebquestcommunication').innerText;
        const idschedulecommunication = document.getElementById('idschedulecommunication').innerText;
        const webQuestCode = document.getElementById('communicationcode').innerText;
        const laDateCommunication = dateCommunicationRef.current.value;
        const dateCommunication = formatDateData(laDateCommunication);
        const startCommunication = startCommunicationRef.current.value;
        const endCommunication = endCommunicationRef.current.value;
        const fullNameCommunication = fullNameCommunicationRef.current.value;
        const emailCommunication = emailCommunicationRef.current.value;
        const whatsappCommunication = whatsappCommunicationRef.current.value;
        const notesCommunication = notesCommunicationRef.current.value;
        const communicationcodingType = document.getElementById('communicationcoding').innerText;
        //
        const createCommunicationStatut = 'ongoing';
        const createCommunicationDateSaved = getCurrentDate();
        //
        var resultatcheck = 0;

        if(!codeutilisateurDiv){
            const pTexteSaisir = "Login before submiting";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!laDateCommunication){
            const pTexteSaisir = "Date";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!startCommunication){
            const pTexteSaisir = "Start Time";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!endCommunication){
            const pTexteSaisir = "End Time";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!fullNameCommunication){
            const pTexteSaisir = "Full Name";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!emailCommunication){
            const pTexteSaisir = "Email";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!whatsappCommunication){
            const pTexteSaisir = "Phone Whatsapp";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!notesCommunication){
            const pTexteSaisir = "Any Important Notes";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else{
            //check if idwebquest already exists in schedule
            //http://localhost:5000
            //https://micarionsapi.carions.net
            await Axios.post("https://micarionsapi.carions.net/communication/check", {
                idWebQuest:idWebQuest,
            }).then((responsecommunicationcheck) => {
                resultatcheck = responsecommunicationcheck.data.length;
                if(resultatcheck === 0){
                    Axios.post("https://micarionsapi.carions.net/communication/create", {
                        idWebQuest:idWebQuest,
                        idschedulecommunication:idschedulecommunication,
                        webQuestCode:webQuestCode,
                        choixlangueDiv:choixlangueDiv,
                        codeutilisateurDiv:codeutilisateurDiv,
                        dateCommunication:dateCommunication,
                        startCommunication:startCommunication,
                        endCommunication:endCommunication,
                        fullNameCommunication:fullNameCommunication,
                        emailCommunication:emailCommunication,
                        whatsappCommunication:whatsappCommunication,
                        notesCommunication:notesCommunication,
                        createCommunicationStatut:createCommunicationStatut,
                        communicationcodingType:communicationcodingType,
                        createCommunicationDateSaved:createCommunicationDateSaved,
                    }).then(() => {
                        const pTexteSaisir = "Communication has been saved successfully";
                        AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
                        onboardCodingDivContainer.style.display = 'none';
                        planningcodingDivContainer.style.display = 'none';
                        schedulecodingDivContainer.style.display = 'none';
                        communicationcodingDivContainer.style.display = 'none';
                        actualisercodingDivContainer.style.display = 'none';
                        contractcodingDivContainer.style.display = 'none';
                        budgetcodingDivContainer.style.display = 'none';
                    });
                }else{
                    const pTexteSaisir = "This Communication already exists";
                    AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
                    onboardCodingDivContainer.style.display = 'none';
                    planningcodingDivContainer.style.display = 'none';
                    schedulecodingDivContainer.style.display = 'none';
                    communicationcodingDivContainer.style.display = 'none';
                    actualisercodingDivContainer.style.display = 'none';
                    contractcodingDivContainer.style.display = 'none';
                    budgetcodingDivContainer.style.display = 'none';
                }
            });
        }
    }

    /**Actualiser */
    const adminActualiserService = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const serviceactualisercodingDiv = document.getElementById('serviceactualisercoding');
        const serviceactualiserlistcodingDiv = document.getElementById('serviceactualiserlistcoding');
        const serviceactualisercodingpanelreadDiv = document.getElementById('serviceactualisercodingpanelread');
        //
        const viewonboardcodingDiv = document.getElementById('viewonboardcoding');
        const viewplanningcodingDiv = document.getElementById('viewplanningcoding');
        const viewschedulecodingDiv = document.getElementById('viewschedulecoding');
        const viewcommunicationcodingDiv = document.getElementById('viewcommunicationcoding');
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const webQuestType = 'coding';
        //
        var resultatservice = 0;
        //
        //http://localhost:5000
        //https://micarionsapi.carions.net
        await Axios.post("https://micarionsapi.carions.net/admin/new", {
            choixlangueDiv:choixlangueDiv,
            webQuestType:webQuestType,
        }).then((responseActualiser) => {
            resultatservice = responseActualiser.data.length;
            if(resultatservice === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                onboardCodingDivContainer.style.display = 'none';
                planningcodingDivContainer.style.display = 'none';
                schedulecodingDivContainer.style.display = 'none';
                communicationcodingDivContainer.style.display = 'none';
                actualisercodingDivContainer.style.display = 'none';
                contractcodingDivContainer.style.display = 'none';
                budgetcodingDivContainer.style.display = 'none';
                //
                serviceactualisercodingDiv.style.display = 'none';
                serviceactualiserlistcodingDiv.style.display = 'none';
                serviceactualisercodingpanelreadDiv.style.display = 'none';
                //
                viewonboardcodingDiv.style.display = 'none';
                viewplanningcodingDiv.style.display = 'none';
                viewschedulecodingDiv.style.display = 'none';
                viewcommunicationcodingDiv.style.display = 'none';
            }else{
                getServiceActualiserCoding(responseActualiser.data);
                onboardCodingDivContainer.style.display = 'none';
                planningcodingDivContainer.style.display = 'none';
                schedulecodingDivContainer.style.display = 'none';
                communicationcodingDivContainer.style.display = 'none';
                actualisercodingDivContainer.style.display = 'block';
                contractcodingDivContainer.style.display = 'none';
                budgetcodingDivContainer.style.display = 'none';
                //
                serviceactualisercodingDiv.style.display = 'block';
                serviceactualiserlistcodingDiv.style.display = 'none';
                serviceactualisercodingpanelreadDiv.style.display = 'none';
                //
                viewonboardcodingDiv.style.display = 'none';
                viewplanningcodingDiv.style.display = 'none';
                viewschedulecodingDiv.style.display = 'none';
                viewcommunicationcodingDiv.style.display = 'none';
            }
        });
    }

    const actualiserHandleClick = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const serviceactualisercodingDiv = document.getElementById('serviceactualisercoding');
        const serviceactualiserlistcodingDiv = document.getElementById('serviceactualiserlistcoding');
        const serviceactualisercodingpanelreadDiv = document.getElementById('serviceactualisercodingpanelread');
        //
        onboardCodingDivContainer.style.display = 'none';
        planningcodingDivContainer.style.display = 'none';
        schedulecodingDivContainer.style.display = 'none';
        communicationcodingDivContainer.style.display = 'none';
        actualisercodingDivContainer.style.display = 'block';
        contractcodingDivContainer.style.display = 'none';
        budgetcodingDivContainer.style.display = 'none';
        //
        serviceactualisercodingDiv.style.display = 'block';
        serviceactualiserlistcodingDiv.style.display = 'block';
        serviceactualisercodingpanelreadDiv.style.display = 'none';
    }

    const actualiserHeaderHandler = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const serviceactualisercodingDiv = document.getElementById('serviceactualisercoding');
        const serviceactualiserlistcodingDiv = document.getElementById('serviceactualiserlistcoding');
        const serviceactualisercodingpanelreadDiv = document.getElementById('serviceactualisercodingpanelread');
        //
        const webquestViewDetailscoding = document.getElementById('webquestviewdetailscoding');
        //

        onboardCodingDivContainer.style.display = 'none';
        planningcodingDivContainer.style.display = 'none';
        schedulecodingDivContainer.style.display = 'none';
        communicationcodingDivContainer.style.display = 'none';
        actualisercodingDivContainer.style.display = 'block';
        contractcodingDivContainer.style.display = 'none';
        budgetcodingDivContainer.style.display = 'none';

        serviceactualisercodingDiv.style.display = 'block';
        serviceactualiserlistcodingDiv.style.display = 'block';
        serviceactualisercodingpanelreadDiv.style.display = 'block';
        //actualiser details views
        webquestViewDetailscoding.style.display = 'none';
    }
    //
    const actualiserOnbaordServiceNew = async (e) => {
        e.preventDefault();

        const idWebQuestActualiserDiv = document.getElementById('idwebquestactualiser').innerText;
        const viewonboardcodingDiv = document.getElementById('viewonboardcoding');
        const viewplanningcodingDiv = document.getElementById('viewplanningcoding');
        const viewschedulecodingDiv = document.getElementById('viewschedulecoding');
        const viewcommunicationcodingDiv = document.getElementById('viewcommunicationcoding');
        const viewcontractcodingDiv = document.getElementById('viewcontractcoding');
        const viewbudgetcodingDiv = document.getElementById('viewbudgetcoding');
        //
        const webquestViewDetailscoding = document.getElementById('webquestviewdetailscoding');
        var resultatview = 0;

        //http://localhost:5000
        //https://micarionsapi.carions.net
        await Axios.post("https://micarionsapi.carions.net/view/actualiser", {
            idRowActualiserDiv:idWebQuestActualiserDiv,
        }).then((responseservice) => {
            resultatview = responseservice.data.length;
            if(resultatview === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                viewonboardcodingDiv.style.display = 'none';
                viewplanningcodingDiv.style.display = 'none';
                viewschedulecodingDiv.style.display = 'none';
                viewcommunicationcodingDiv.style.display = 'none';
                viewcontractcodingDiv.style.display = 'none';
                viewbudgetcodingDiv.style.display = 'none';
                //
                webquestViewDetailscoding.style.display = 'none';
            }else{
                setViewActualierService(responseservice.data);
                viewonboardcodingDiv.style.display = 'block';
                viewplanningcodingDiv.style.display = 'none';
                viewschedulecodingDiv.style.display = 'none';
                viewcommunicationcodingDiv.style.display = 'none';
                viewcontractcodingDiv.style.display = 'none';
                viewbudgetcodingDiv.style.display = 'none';
                //
                webquestViewDetailscoding.style.display = 'block';
            }
        });
    }
    //
    const editWebquestHandler = async (e) => {
        e.preventDefault();

        const idWebQuestActualiser = document.getElementById('idwebquestactualiser').innerText;
        const webQuestion1 = webQuestion1Ref.current.value;
        const webQuestion2 = webQuestion2Ref.current.value;
        const webQuestion3 = webQuestion3Ref.current.value;
        const webQuestion4 = webQuestion4Ref.current.value;
        const webQuestion5 = webQuestion5Ref.current.value;
        const webQuestion6 = webQuestion6Ref.current.value;
        const webQuestion7 = webQuestion7Ref.current.value;
        const webQuestion8 = webQuestion8Ref.current.value;
        const webQuestion9 = webQuestion8Ref.current.value;
        const webQuestion10 = webQuestion10Ref.current.value;
        const webQuestion11 = webQuestion11Ref.current.value;
        const webQuestion12 = webQuestion12Ref.current.value;
        const webQuestion13 = webQuestion13Ref.current.value;
        const webQuestion14 = webQuestion14Ref.current.value;
        //
        if(!webQuestion1){
            const pTexteSaisir = t("codingquestion_1");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!webQuestion2){
            const pTexteSaisir = t("codingquestion_2");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!webQuestion3){
            const pTexteSaisir = t("codingquestion_3");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!webQuestion4){
            const pTexteSaisir = t("codingquestion_4");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!webQuestion5){
            const pTexteSaisir = t("codingquestion_5");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!webQuestion6){
            const pTexteSaisir = t("codingquestion_6");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!webQuestion7){
            const pTexteSaisir = t("codingquestion_7");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!webQuestion8){
            const pTexteSaisir = t("codingquestion_8");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!webQuestion9){
            const pTexteSaisir = t("codingquestion_9");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!webQuestion10){
            const pTexteSaisir = t("codingquestion_10");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!webQuestion11){
            const pTexteSaisir = t("codingquestion_11");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!webQuestion12){
            const pTexteSaisir = t("codingquestion_12");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!webQuestion13){
            const pTexteSaisir = t("codingquestion_13");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!webQuestion14){
            const pTexteSaisir = 'Whatsapp';
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else{
            //http://localhost:5000
            //https://micarionsapi.carions.net
            await Axios.post("https://micarionsapi.carions.net/webquest/actualiser", {
                idWebQuestActualiser:idWebQuestActualiser,
                webQuestion1:webQuestion1,
                webQuestion2:webQuestion2,
                webQuestion3:webQuestion3,
                webQuestion4:webQuestion4,
                webQuestion5:webQuestion5,
                webQuestion6:webQuestion6,
                webQuestion7:webQuestion7,
                webQuestion8:webQuestion8,
                webQuestion9:webQuestion9,
                webQuestion10:webQuestion10,
                webQuestion11:webQuestion11,
                webQuestion12:webQuestion12,
                webQuestion13:webQuestion13,
                webQuestion14:webQuestion14,
            }).then(() =>{
                var answer1 = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                const hTitleTexte = t("proceedcodingtitle");
                const pTexteNotAvailable = answer1;
                PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
            });
        }
    }
    //
    const actualiserPlanningService = async (e) => {
        e.preventDefault();

        const idWebQuestActualiserDiv = document.getElementById('idwebquestactualiser').innerText;
        const viewonboardcodingDiv = document.getElementById('viewonboardcoding');
        const viewplanningcodingDiv = document.getElementById('viewplanningcoding');
        const viewschedulecodingDiv = document.getElementById('viewschedulecoding');
        const viewcommunicationcodingDiv = document.getElementById('viewcommunicationcoding');
        const viewcontractcodingDiv = document.getElementById('viewcontractcoding');
        const viewbudgetcodingDiv = document.getElementById('viewbudgetcoding');
        //
        const planningViewDetailscoding = document.getElementById('planningviewdetailscoding');
        console.log(idWebQuestActualiserDiv);
        var resultatview = 0;

        //http://localhost:5000
        //https://micarionsapi.carions.net
        await Axios.post("https://micarionsapi.carions.net/view/planning/actualiser", {
            idRowActualiserDiv:idWebQuestActualiserDiv,
        }).then((responseservice) => {
            resultatview = responseservice.data.length;
            if(resultatview === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                viewonboardcodingDiv.style.display = 'none';
                viewplanningcodingDiv.style.display = 'none';
                viewschedulecodingDiv.style.display = 'none';
                viewcommunicationcodingDiv.style.display = 'none';
                viewcontractcodingDiv.style.display = 'none';
                viewbudgetcodingDiv.style.display = 'none';
                //
                planningViewDetailscoding.style.display = 'none';
            }else{
                setViewPlanningActualierService(responseservice.data);
                viewonboardcodingDiv.style.display = 'none';
                viewplanningcodingDiv.style.display = 'block';
                viewschedulecodingDiv.style.display = 'none';
                viewcommunicationcodingDiv.style.display = 'none';
                viewcontractcodingDiv.style.display = 'none';
                viewbudgetcodingDiv.style.display = 'none';
                //
                planningViewDetailscoding.style.display = 'block';
            }
        });
    }
    //
    const editPlanningHandler = async (e) => {
        e.preventDefault();

        const idWebQuestActualiser = document.getElementById('idwebquestactualiser').innerText;
        const editRequirements = editRequirementsRef.current.value;
        const editPlan = editPlanRef.current.value;
        const editMilestones = editMilestonesRef.current.value;
        const editTimelines = editTimelinesRef.current.value;
        const editResponsibilities = editResponsibilitiesRef.current.value;
        const editContingencies = editContingenciesRef.current.value;
        const editAdjust = editAdjustRef.current.value;
        //
        if(!editRequirements){
            const pTexteSaisir = t("objective_requirements");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editPlan){
            const pTexteSaisir = t("project_plan");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editMilestones){
            const pTexteSaisir = t("project_milestones");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editTimelines){
            const pTexteSaisir = t("project_timelines");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editResponsibilities){
            const pTexteSaisir = t("task_responsibilities");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editContingencies){
            const pTexteSaisir = t("risks_contingencies");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editAdjust){
            const pTexteSaisir = t("review_adjust");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else{
            //http://localhost:5000
            //https://micarionsapi.carions.net
            await Axios.post("https://micarionsapi.carions.net/planning/actualiser", {
                idWebQuestActualiser:idWebQuestActualiser,
                editRequirements:editRequirements,
                editPlan:editPlan,
                editMilestones:editMilestones,
                editTimelines:editTimelines,
                editResponsibilities:editResponsibilities,
                editContingencies:editContingencies,
                editAdjust:editAdjust,
            }).then(() =>{
                var answer1 = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                const hTitleTexte = t("proceedcodingtitle");
                const pTexteNotAvailable = answer1;
                PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
            });
        }
    }

    //
    const actualiserScheduleService = async (e) => {
        e.preventDefault();

        const idWebQuestActualiserDiv = document.getElementById('idwebquestactualiser').innerText;
        const viewonboardcodingDiv = document.getElementById('viewonboardcoding');
        const viewplanningcodingDiv = document.getElementById('viewplanningcoding');
        const viewschedulecodingDiv = document.getElementById('viewschedulecoding');
        const viewcommunicationcodingDiv = document.getElementById('viewcommunicationcoding');
        const viewcontractcodingDiv = document.getElementById('viewcontractcoding');
        const viewbudgetcodingDiv = document.getElementById('viewbudgetcoding');
        //
        const scheduleViewDetailscoding = document.getElementById('scheduleviewdetailscoding');
        //console.log(idWebQuestActualiserDiv);
        var resultatview = 0;

        //http://localhost:5000
        //https://micarionsapi.carions.net
        await Axios.post("https://micarionsapi.carions.net/view/schedule/actualiser", {
            idRowActualiserDiv:idWebQuestActualiserDiv,
        }).then((responseschedule) => {
            resultatview = responseschedule.data.length;
            if(resultatview === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                viewonboardcodingDiv.style.display = 'none';
                viewplanningcodingDiv.style.display = 'none';
                viewschedulecodingDiv.style.display = 'none';
                viewcommunicationcodingDiv.style.display = 'none';
                viewcontractcodingDiv.style.display = 'none';
                viewbudgetcodingDiv.style.display = 'none';
                //
                scheduleViewDetailscoding.style.display = 'none';
            }else{
                setViewScheduleActualierService(responseschedule.data);
                viewonboardcodingDiv.style.display = 'none';
                viewplanningcodingDiv.style.display = 'none';
                viewschedulecodingDiv.style.display = 'block';
                viewcommunicationcodingDiv.style.display = 'none';
                viewcontractcodingDiv.style.display = 'none';
                viewbudgetcodingDiv.style.display = 'none';
                //
                scheduleViewDetailscoding.style.display = 'block';
            }
        });
    }
    //
    const editScheduleHandler = async (e) => {
        e.preventDefault();

        const idWebQuestActualiser = document.getElementById('idwebquestactualiser').innerText;
        const editStartSchedule = editStartScheduleRef.current.value;
        const editEndSchedule = editEndScheduleRef.current.value;
        const editFullNameSchedule = editFullNameScheduleRef.current.value;
        const editEmailSchedule = editEmailScheduleRef.current.value;
        const editWhatsappSchedule = editWhatsappScheduleRef.current.value;
        const editNotesSchedule = editNotesScheduleRef.current.value;
        //
        if(!editStartSchedule){
            const pTexteSaisir = "Start Time";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editEndSchedule){
            const pTexteSaisir = "End Time";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editFullNameSchedule){
            const pTexteSaisir = "FullName";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editEmailSchedule){
            const pTexteSaisir = "Email Address";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editWhatsappSchedule){
            const pTexteSaisir = "Whatsapp Contact";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editNotesSchedule){
            const pTexteSaisir = "Any Important Notes";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else{
            //http://localhost:5000
            //https://micarionsapi.carions.net
            await Axios.post("https://micarionsapi.carions.net/schedule/actualiser", {
                idWebQuestActualiser:idWebQuestActualiser,
                editStartSchedule:editStartSchedule,
                editEndSchedule:editEndSchedule,
                editFullNameSchedule:editFullNameSchedule,
                editEmailSchedule:editEmailSchedule,
                editWhatsappSchedule:editWhatsappSchedule,
                editNotesSchedule:editNotesSchedule,
            }).then(() =>{
                var answer1 = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                const hTitleTexte = t("proceedcodingtitle");
                const pTexteNotAvailable = answer1;
                PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
            });
        }
    }
    //
    const actualiserCommunicationService = async (e) => {
        e.preventDefault();

        const idWebQuestActualiserDiv = document.getElementById('idwebquestactualiser').innerText;
        const viewonboardcodingDiv = document.getElementById('viewonboardcoding');
        const viewplanningcodingDiv = document.getElementById('viewplanningcoding');
        const viewschedulecodingDiv = document.getElementById('viewschedulecoding');
        const viewcommunicationcodingDiv = document.getElementById('viewcommunicationcoding');
        const viewcontractcodingDiv = document.getElementById('viewcontractcoding');
        const viewbudgetcodingDiv = document.getElementById('viewbudgetcoding');
        //
        const scheduleViewDetailscoding = document.getElementById('scheduleviewdetailscoding');
        //console.log(idWebQuestActualiserDiv);
        var resultatview = 0;

        //http://localhost:5000
        //https://micarionsapi.carions.net
        await Axios.post("https://micarionsapi.carions.net/view/communic/actualiser", {
            idRowActualiserDiv:idWebQuestActualiserDiv,
        }).then((responsecommunic) => {
            resultatview = responsecommunic.data.length;
            if(resultatview === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                viewonboardcodingDiv.style.display = 'none';
                viewplanningcodingDiv.style.display = 'none';
                viewschedulecodingDiv.style.display = 'none';
                viewcommunicationcodingDiv.style.display = 'none';
                viewcontractcodingDiv.style.display = 'none';
                viewbudgetcodingDiv.style.display = 'none';
                //
                scheduleViewDetailscoding.style.display = 'none';
            }else{
                setViewCommunicationActualierService(responsecommunic.data);
                viewonboardcodingDiv.style.display = 'none';
                viewplanningcodingDiv.style.display = 'none';
                viewschedulecodingDiv.style.display = 'none';
                viewcommunicationcodingDiv.style.display = 'block';
                viewcontractcodingDiv.style.display = 'none';
                viewbudgetcodingDiv.style.display = 'none';
                //
                scheduleViewDetailscoding.style.display = 'block';
            }
        });
    }
    //
    const editCommunicationHandler = async (e) => {
        e.preventDefault();

        const idWebQuestActualiser = document.getElementById('idwebquestactualiser').innerText;
        const editStartCommunication = editStartCommunicationRef.current.value;
        const editEndCommunication = editEndCommunicationRef.current.value;
        const editFullNameCommunication = editFullNameCommunicationRef.current.value;
        const editEmailCommunication = editEmailCommunicationRef.current.value;
        const editWhatsappCommunication = editWhatsappCommunicationRef.current.value;
        const editNotesCommunication = editNotesCommunicationRef.current.value;
        //
        if(!editStartCommunication){
            const pTexteSaisir = "Start Time";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editEndCommunication){
            const pTexteSaisir = "End Time";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editFullNameCommunication){
            const pTexteSaisir = "FullName";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editEmailCommunication){
            const pTexteSaisir = "Email Address";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editWhatsappCommunication){
            const pTexteSaisir = "Whatsapp Contact";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editNotesCommunication){
            const pTexteSaisir = "Any Important Notes";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else{
            //http://localhost:5000
            //https://micarionsapi.carions.net
            await Axios.post("https://micarionsapi.carions.net/communic/actualiser", {
                idWebQuestActualiser:idWebQuestActualiser,
                editStartCommunication:editStartCommunication,
                editEndCommunication:editEndCommunication,
                editFullNameCommunication:editFullNameCommunication,
                editEmailCommunication:editEmailCommunication,
                editWhatsappCommunication:editWhatsappCommunication,
                editNotesCommunication:editNotesCommunication,
            }).then(() =>{
                var answer1 = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                const hTitleTexte = t("proceedcodingtitle");
                const pTexteNotAvailable = answer1;
                PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
            });
        }
    }

    // actualiser contract
    const actualiserContractService = async (e) => {
        e.preventDefault();

        const viewonboardcodingDiv = document.getElementById('viewonboardcoding');
        const viewplanningcodingDiv = document.getElementById('viewplanningcoding');
        const viewschedulecodingDiv = document.getElementById('viewschedulecoding');
        const viewcommunicationcodingDiv = document.getElementById('viewcommunicationcoding');
        const viewcontractcodingDiv = document.getElementById('viewcontractcoding');
        const viewbudgetcodingDiv = document.getElementById('viewbudgetcoding');
        //
        const scheduleViewDetailscoding = document.getElementById('scheduleviewdetailscoding');
        //
        const webquestCodeActualisercoding = document.getElementById('webquestCodeActualisercoding').innerText;
        //console.log(webquestCodeActualisercoding);
        var resultatview = 0;

        //http://localhost:5000
        //https://micarionsapi.carions.net
        await Axios.post("https://micarionsapi.carions.net/view/contract/actualiser", {
            webquestCodeActualisercoding:webquestCodeActualisercoding,
        }).then((responsecontractcoding) => {
            resultatview = responsecontractcoding.data.length;
            if(resultatview === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                viewonboardcodingDiv.style.display = 'none';
                viewplanningcodingDiv.style.display = 'none';
                viewschedulecodingDiv.style.display = 'none';
                viewcommunicationcodingDiv.style.display = 'none';
                viewcontractcodingDiv.style.display = 'none';
                viewbudgetcodingDiv.style.display = 'none';
                //
                scheduleViewDetailscoding.style.display = 'none';
            }else{
                setViewContractActualierService(responsecontractcoding.data);
                viewonboardcodingDiv.style.display = 'none';
                viewplanningcodingDiv.style.display = 'none';
                viewschedulecodingDiv.style.display = 'none';
                viewcommunicationcodingDiv.style.display = 'none';
                viewcontractcodingDiv.style.display = 'block';
                viewbudgetcodingDiv.style.display = 'none';
                //
                scheduleViewDetailscoding.style.display = 'block';
            }
        });
    }
    //
    const editContractCodingHandler = async (e) => {
        e.preventDefault();

        const editcontracreditorViewContract = editcontracreditorViewContractRef.current.value;
        const idEditViewContractCoding = idEditViewContractCodingRef.current.value;
        //
        if(!editcontracreditorViewContract){
            const pTexteSaisir = t("textcontenu");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else{
            //http://localhost:5000
            //https://micarionsapi.carions.net
            await Axios.post("https://micarionsapi.carions.net/contract/sign/edit", {
                idContract:idEditViewContractCoding,
                editcontracreditorTextContract:editcontracreditorViewContract,
            }).then(() => {
                const pTexteSaisir = "Edited Successfully";
                AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
            });
        }
    }

    const goBackServiceAdminHeaderHandler = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        onboardCodingDivContainer.style.display = 'none';
        planningcodingDivContainer.style.display = 'none';
        schedulecodingDivContainer.style.display = 'none';
        communicationcodingDivContainer.style.display = 'none';
        actualisercodingDivContainer.style.display = 'none';
        contractcodingDivContainer.style.display = 'none';
        budgetcodingDivContainer.style.display = 'none';
    }

    // admin contract
    const adminContractService = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const servicecontractcodingDiv = document.getElementById('servicecontractcoding');
        const servicecontractlistcodingDiv = document.getElementById('servicecontractlistcoding');
        const servicecontractcodingpanelreadDiv = document.getElementById('servicecontractcodingpanelread');
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const webQuestType = 'coding';

        //
        var resultatservice = 0;
        //
        //http://localhost:5000
        //https://micarionsapi.carions.net
        await Axios.post("https://micarionsapi.carions.net/admin/new", {
            choixlangueDiv:choixlangueDiv,
            webQuestType:webQuestType,
        }).then((responseActualiser) => {
            resultatservice = responseActualiser.data.length;
            if(resultatservice === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                onboardCodingDivContainer.style.display = 'none';
                planningcodingDivContainer.style.display = 'none';
                schedulecodingDivContainer.style.display = 'none';
                communicationcodingDivContainer.style.display = 'none';
                actualisercodingDivContainer.style.display = 'none';
                contractcodingDivContainer.style.display = 'none'
                //
                servicecontractcodingDiv.style.display = 'none';
                servicecontractlistcodingDiv.style.display = 'none';
                servicecontractcodingpanelreadDiv.style.display = 'none';
                //
            }else{
                getServiceContractCoding(responseActualiser.data);
                onboardCodingDivContainer.style.display = 'none';
                planningcodingDivContainer.style.display = 'none';
                schedulecodingDivContainer.style.display = 'none';
                communicationcodingDivContainer.style.display = 'none';
                actualisercodingDivContainer.style.display = 'none';
                contractcodingDivContainer.style.display = 'block';
                budgetcodingDivContainer.style.display = 'none';
                //
                servicecontractcodingDiv.style.display = 'block';
                servicecontractlistcodingDiv.style.display = 'none';
                servicecontractcodingpanelreadDiv.style.display = 'none';
                //
            }
        });
    }
    //
    const contractHandleClick = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const servicecontractcodingDiv = document.getElementById('servicecontractcoding');
        const servicecontractlistcodingDiv = document.getElementById('servicecontractlistcoding');
        const servicecontractcodingpanelreadDiv = document.getElementById('servicecontractcodingpanelread');
        //
        onboardCodingDivContainer.style.display = 'none';
        planningcodingDivContainer.style.display = 'none';
        schedulecodingDivContainer.style.display = 'none';
        communicationcodingDivContainer.style.display = 'none';
        actualisercodingDivContainer.style.display = 'none';
        contractcodingDivContainer.style.display = 'block';
        budgetcodingDivContainer.style.display = 'none';
        //
        servicecontractcodingDiv.style.display = 'block';
        servicecontractlistcodingDiv.style.display = 'block';
        servicecontractcodingpanelreadDiv.style.display = 'none';
    }
    //
    const contractHeaderHandler = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const servicecontractcodingDiv = document.getElementById('servicecontractcoding');
        const servicecontractlistcodingDiv = document.getElementById('servicecontractlistcoding');
        const servicecontractcodingpanelreadDiv = document.getElementById('servicecontractcodingpanelread');

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const webQuestType = 'coding';

        //
        var resultatservice = 0;
        //
        //http://localhost:5000
        //https://micarionsapi.carions.net
        await Axios.post("https://micarionsapi.carions.net/contract/view", {
            choixlangueDiv:choixlangueDiv,
            webQuestType:webQuestType,
        }).then((responseContract) => {
            resultatservice = responseContract.data.length;
            if(resultatservice === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                onboardCodingDivContainer.style.display = 'none';
                planningcodingDivContainer.style.display = 'none';
                schedulecodingDivContainer.style.display = 'none';
                communicationcodingDivContainer.style.display = 'none';
                actualisercodingDivContainer.style.display = 'none';
                contractcodingDivContainer.style.display = 'block';
                budgetcodingDivContainer.style.display = 'none';
                //
                servicecontractcodingDiv.style.display = 'block';
                servicecontractlistcodingDiv.style.display = 'block';
                servicecontractcodingpanelreadDiv.style.display = 'none';
            }else{
                setValueContractService(responseContract.data);
                onboardCodingDivContainer.style.display = 'none';
                planningcodingDivContainer.style.display = 'none';
                schedulecodingDivContainer.style.display = 'none';
                communicationcodingDivContainer.style.display = 'none';
                actualisercodingDivContainer.style.display = 'none';
                contractcodingDivContainer.style.display = 'block';
                budgetcodingDivContainer.style.display = 'none';
                //
                servicecontractcodingDiv.style.display = 'block';
                servicecontractlistcodingDiv.style.display = 'block';
                servicecontractcodingpanelreadDiv.style.display = 'block';
            }
        });
    }
    //
    const saveContractCodingHandler = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const userscodecodingDiv = document.getElementById('userscodecoding').innerText;
        const webquestcodecodingDiv = document.getElementById('webquestcodecoding').innerText;
        const editcontracreditorTextContract = editcontracreditorTextContractRef.current.value;
        const signContractStatus = "online";
        const signContractCodingDate = getCurrentDate();
        //
        var resultatcontract = 0;
        var retrieveContractId = [];
        var idContract = "";
        //
        if(!editcontracreditorTextContract){
            const pTexteSaisir = t("textcontenu");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else{
            //http://localhost:5000
            //https://micarionsapi.carions.net
            await Axios.post("https://micarionsapi.carions.net/contract/check", {
                webquestcodecodingDiv:webquestcodecodingDiv,
            }).then((responsecontractcoding) => {
                resultatcontract = responsecontractcoding.data.length;
                retrieveContractId = responsecontractcoding.data;
                if(resultatcontract === 0){
                    //save contract
                    Axios.post("https://micarionsapi.carions.net/contract/sign", {
                        userscodecodingDiv:userscodecodingDiv,
                        webquestcodecodingDiv:webquestcodecodingDiv,
                        editcontracreditorTextContract:editcontracreditorTextContract,
                        signContractStatus:signContractStatus,
                        signContractCodingDate:signContractCodingDate,
                    }).then(() => {
                        const pTexteSaisir = "Saved Successfully";
                        AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
                        //
                        onboardCodingDivContainer.style.display = 'none';
                        planningcodingDivContainer.style.display = 'none';
                        schedulecodingDivContainer.style.display = 'none';
                        communicationcodingDivContainer.style.display = 'none';
                        actualisercodingDivContainer.style.display = 'none';
                        contractcodingDivContainer.style.display = 'none';
                        budgetcodingDivContainer.style.display = 'none';
                    });
                }else{
                    //retrieve signcontract id
                    for(let i=0; i<resultatcontract; i++){
                        idContract = retrieveContractId[i].id_signcontract;
                    }
                    //edit contract
                    Axios.post("https://micarionsapi.carions.net/contract/sign/edit", {
                        idContract:idContract,
                        editcontracreditorTextContract:editcontracreditorTextContract,
                    }).then(() => {
                        const pTexteSaisir = "Edited Successfully";
                        AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
                        //
                        onboardCodingDivContainer.style.display = 'none';
                        planningcodingDivContainer.style.display = 'none';
                        schedulecodingDivContainer.style.display = 'none';
                        communicationcodingDivContainer.style.display = 'none';
                        actualisercodingDivContainer.style.display = 'none';
                        contractcodingDivContainer.style.display = 'none';
                        budgetcodingDivContainer.style.display = 'none';
                    });
                }
            });
        }
    }
    //
    const cancelContractCodingHandler = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        onboardCodingDivContainer.style.display = 'none';
        planningcodingDivContainer.style.display = 'none';
        schedulecodingDivContainer.style.display = 'none';
        communicationcodingDivContainer.style.display = 'none';
        actualisercodingDivContainer.style.display = 'none';
        contractcodingDivContainer.style.display = 'none';
        budgetcodingDivContainer.style.display = 'none';
    }

    //budget
    const adminBudgetService = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const servicebudgetcodingDiv = document.getElementById('servicebudgetcoding');
        const servicebudgetlistcodingDiv = document.getElementById('servicebudgetlistcoding');
        const servicebudgetpanelcodingreadDiv = document.getElementById('servicebudgetpanelcodingread');
        //
        const servicebudgetcodingcalculateDiv = document.getElementById('servicebudgetcodingcalculate');
        const servicebudgetcodingsaveDiv = document.getElementById('servicebudgetcodingsave');
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const webQuestType = 'coding';
        //
        var resultatservice = 0;
        //http://localhost:5000
        //https://micarionsapi.carions.net
        await Axios.post("https://micarionsapi.carions.net/admin/new", {
            choixlangueDiv:choixlangueDiv,
            webQuestType:webQuestType,
        }).then((responsebudget) => {
            resultatservice = responsebudget.data.length;
            if(resultatservice === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                onboardCodingDivContainer.style.display = 'none';
                planningcodingDivContainer.style.display = 'none';
                schedulecodingDivContainer.style.display = 'none';
                communicationcodingDivContainer.style.display = 'none';
                actualisercodingDivContainer.style.display = 'none';
                contractcodingDivContainer.style.display = 'none';
                budgetcodingDivContainer.style.display = 'none';
                //
                servicebudgetcodingDiv.style.display = 'none';
                servicebudgetlistcodingDiv.style.display = 'none';
                servicebudgetpanelcodingreadDiv.style.display = 'none';
                //
                servicebudgetcodingcalculateDiv.style.display = 'none';
                servicebudgetcodingsaveDiv.style.display = 'none';
            }else{
                getServiceBudgetCoding(responsebudget.data);
                onboardCodingDivContainer.style.display = 'none';
                planningcodingDivContainer.style.display = 'none';
                schedulecodingDivContainer.style.display = 'none';
                communicationcodingDivContainer.style.display = 'none';
                actualisercodingDivContainer.style.display = 'none';
                contractcodingDivContainer.style.display = 'none';
                budgetcodingDivContainer.style.display = 'block';
                //
                servicebudgetcodingDiv.style.display = 'block';
                servicebudgetlistcodingDiv.style.display = 'none';
                servicebudgetpanelcodingreadDiv.style.display = 'none';
                //
                servicebudgetcodingcalculateDiv.style.display = 'none';
                servicebudgetcodingsaveDiv.style.display = 'none';
            }
        });
    }
    //
    const budgetHandleClick = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const servicebudgetcodingDiv = document.getElementById('servicebudgetcoding');
        const servicebudgetlistcodingDiv = document.getElementById('servicebudgetlistcoding');
        const servicebudgetpanelcodingreadDiv = document.getElementById('servicebudgetpanelcodingread');
        //
        const servicebudgetcodingcalculateDiv = document.getElementById('servicebudgetcodingcalculate');
        const servicebudgetcodingsaveDiv = document.getElementById('servicebudgetcodingsave');

        onboardCodingDivContainer.style.display = 'none';
        planningcodingDivContainer.style.display = 'none';
        schedulecodingDivContainer.style.display = 'none';
        communicationcodingDivContainer.style.display = 'none';
        actualisercodingDivContainer.style.display = 'none';
        contractcodingDivContainer.style.display = 'none';
        budgetcodingDivContainer.style.display = 'block';
        //
        servicebudgetcodingDiv.style.display = 'block';
        servicebudgetlistcodingDiv.style.display = 'block';
        servicebudgetpanelcodingreadDiv.style.display = 'none';
        //
        servicebudgetcodingcalculateDiv.style.display = 'none';
        servicebudgetcodingsaveDiv.style.display = 'none';
    }
    //
    const budgetHeaderHandler = async (indexbudget) => {

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const servicebudgetcodingDiv = document.getElementById('servicebudgetcoding');
        const servicebudgetlistcodingDiv = document.getElementById('servicebudgetlistcoding');
        const servicebudgetpanelcodingreadDiv = document.getElementById('servicebudgetpanelcodingread');
        //
        const servicebudgetcodingcalculateDiv = document.getElementById('servicebudgetcodingcalculate');
        const servicebudgetcodingsaveDiv = document.getElementById('servicebudgetcodingsave');
        //
        const idService = indexbudget;
        //set variables to retrieve specific data
        var codingType = "";
        var codingPlan = "";
        var resultat = 0;
        var codingDetails = [];

        //http://localhost:5000
        //https://micarionsapi.carions.net
        await Axios.post("https://micarionsapi.carions.net/admin/new/read", {
            idService:idService,
        }).then((responsereadbudget) => {
            setValueServiceBudgetRead(responsereadbudget.data);
            resultat = responsereadbudget.data.length;
            codingDetails = responsereadbudget.data;
            onboardCodingDivContainer.style.display = 'none';
            planningcodingDivContainer.style.display = 'none';
            schedulecodingDivContainer.style.display = 'none';
            communicationcodingDivContainer.style.display = 'none';
            actualisercodingDivContainer.style.display = 'none';
            contractcodingDivContainer.style.display = 'none';
            budgetcodingDivContainer.style.display = 'block';
            //
            servicebudgetcodingDiv.style.display = 'block';
            servicebudgetlistcodingDiv.style.display = 'block';
            servicebudgetpanelcodingreadDiv.style.display = 'block';
            //
            servicebudgetcodingcalculateDiv.style.display = 'none';
            servicebudgetcodingsaveDiv.style.display = 'none';
            //retrieve data
            for(let i=0; i<resultat; i++){
                codingType = codingDetails[i].webquest_type;
                codingPlan = codingDetails[i].webquest_plan;
            }

            codingPlans = services.services.find(service => service.webquest === codingType);
            //
            if (!codingPlans) {
                // Handle the case when no coding plans are found
                return <div>No coding plans available.</div>;
            }
            //
            const getWebplanDetails = webplan => {
                return codingPlans.webplans.find(plan => plan.webplan === webplan);
            };
            //
            servicePlan = getWebplanDetails(codingPlan);
        });
    }
    //
    const calculateBudgetCodingHandler = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const servicebudgetcodingDiv = document.getElementById('servicebudgetcoding');
        const servicebudgetlistcodingDiv = document.getElementById('servicebudgetlistcoding');
        const servicebudgetpanelcodingreadDiv = document.getElementById('servicebudgetpanelcodingread');
        //
        const servicebudgetcodingcalculateDiv = document.getElementById('servicebudgetcodingcalculate');
        const servicebudgetcodingsaveDiv = document.getElementById('servicebudgetcodingsave');
        //calculate
        const serviceBudgetPriceCoding = serviceBudgetPriceCodingRef.current.value;
        const serviceBudgetOhterCostCoding = serviceBudgetOhterCostCodingRef.current.value;
        const serviceBudgetUpfrontCoding = serviceBudgetUpfrontCodingRef.current.value;
        const serviceBudgetDeliveryCoding = serviceBudgetDeliveryCodingRef.current.value;
        //
        var priceCoding = 0;
        var otherCoding = 0;
        var upfrontCoding = 0;
        var totalBudgetCoding = 0;
        var restBudgetCoding = 0;
        var daysDeliveryCoding = 0;
        var dueDateCoding = 0;
        priceCoding = parseFloat(serviceBudgetPriceCoding);
        otherCoding = parseFloat(serviceBudgetOhterCostCoding);
        upfrontCoding = parseFloat(serviceBudgetUpfrontCoding);
        //due date
        daysDeliveryCoding = parseFloat(serviceBudgetDeliveryCoding);
        dueDateCoding = addCurrentDate(daysDeliveryCoding);
        //
        totalBudgetCoding = (priceCoding + otherCoding);
        restBudgetCoding = ((totalBudgetCoding) - upfrontCoding);
        serviceBudgetTotalCodingRef.current.value = totalBudgetCoding;
        serviceBudgetResteCodingRef.current.value = restBudgetCoding;
        serviceBudgetDueDateCodingRef.current.value = dueDateCoding;
        

        onboardCodingDivContainer.style.display = 'none';
        planningcodingDivContainer.style.display = 'none';
        schedulecodingDivContainer.style.display = 'none';
        communicationcodingDivContainer.style.display = 'none';
        actualisercodingDivContainer.style.display = 'none';
        contractcodingDivContainer.style.display = 'none';
        budgetcodingDivContainer.style.display = 'block';
        //
        servicebudgetcodingDiv.style.display = 'block';
        servicebudgetlistcodingDiv.style.display = 'block';
        servicebudgetpanelcodingreadDiv.style.display = 'block';
        //
        servicebudgetcodingcalculateDiv.style.display = 'block';
        servicebudgetcodingsaveDiv.style.display = 'block';
    }
    //
    const saveBudgetCodingHandler = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const servicebudgetcodingDiv = document.getElementById('servicebudgetcoding');
        const servicebudgetlistcodingDiv = document.getElementById('servicebudgetlistcoding');
        const servicebudgetpanelcodingreadDiv = document.getElementById('servicebudgetpanelcodingread');
        //
        const servicebudgetcodingcalculateDiv = document.getElementById('servicebudgetcodingcalculate');
        const servicebudgetcodingsaveDiv = document.getElementById('servicebudgetcodingsave');
        //
        const usersbudgetcodingDiv = document.getElementById('usersbudgetcoding').innerText;
        const webquestbudgetcodingDiv = document.getElementById('webquestbudgetcoding').innerText;
        const budgetwebquesttypecodingDiv = document.getElementById('budgetwebquesttypecoding').innerText;
        const serviceBudgetDescriptionCoding = serviceBudgetDescriptionCodingRef.current.value;
        const serviceBudgetPlanCoding = serviceBudgetPlanCodingRef.current.value;
        const serviceBudgetPriceCoding = serviceBudgetPriceCodingRef.current.value;
        const serviceBudgetDeliveryCoding = serviceBudgetDeliveryCodingRef.current.value;
        const serviceBudgetRevisionCoding = serviceBudgetRevisionCodingRef.current.value;
        const serviceBudgetPageCoding = serviceBudgetPageCodingRef.current.value;
        const serviceBudgetOhterCostCoding = serviceBudgetOhterCostCodingRef.current.value;
        const serviceBudgetUpfrontCoding = serviceBudgetUpfrontCodingRef.current.value;
        const serviceBudgetAddNotesCoding = serviceBudgetAddNotesCodingRef.current.value;
        const serviceBudgetTotalCoding = serviceBudgetTotalCodingRef.current.value;
        const serviceBudgetResteCoding = serviceBudgetResteCodingRef.current.value;
        const serviceBudgetDueDateCoding = serviceBudgetDueDateCodingRef.current.value;
        const serviceBudgetStatusCoding = 'online';
        const serviceBugetDateCoding = getCurrentDate();
        //
        var resultatcheck = 0;
        //
        if(!serviceBudgetDescriptionCoding){
            const pTexteNotAvailable = "Descriprion is empty";
            AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
        }else if(!serviceBudgetOhterCostCoding){
            const pTexteNotAvailable = "Other-cost is empty";
            AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
        }else if(!serviceBudgetUpfrontCoding){
            const pTexteNotAvailable = "Up-front is empty";
            AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
        }else if(!serviceBudgetAddNotesCoding){
            const pTexteNotAvailable = "Add Notes is empty";
            AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
        }else{
            //http://localhost:5000
            //https://micarionsapi.carions.net
            //check if idwebquest already exists in planning
            await Axios.post("https://micarionsapi.carions.net/budget/check", {
                webquestbudgetcodingDiv:webquestbudgetcodingDiv,
            }).then((responsebudget) => {
                resultatcheck = responsebudget.data.length;
                if(resultatcheck === 0){
                    Axios.post("https://micarionsapi.carions.net/budget/create", {
                        usersbudgetcodingDiv:usersbudgetcodingDiv,
                        webquestbudgetcodingDiv:webquestbudgetcodingDiv,
                        budgetwebquesttypecodingDiv:budgetwebquesttypecodingDiv,
                        serviceBudgetDescriptionCoding:serviceBudgetDescriptionCoding,
                        serviceBudgetPlanCoding:serviceBudgetPlanCoding,
                        serviceBudgetPriceCoding:serviceBudgetPriceCoding,
                        serviceBudgetDeliveryCoding:serviceBudgetDeliveryCoding,
                        serviceBudgetRevisionCoding:serviceBudgetRevisionCoding,
                        serviceBudgetPageCoding:serviceBudgetPageCoding,
                        serviceBudgetOhterCostCoding:serviceBudgetOhterCostCoding,
                        serviceBudgetUpfrontCoding:serviceBudgetUpfrontCoding,
                        serviceBudgetAddNotesCoding:serviceBudgetAddNotesCoding,
                        serviceBudgetTotalCoding:serviceBudgetTotalCoding,
                        serviceBudgetResteCoding:serviceBudgetResteCoding,
                        serviceBudgetDueDateCoding:serviceBudgetDueDateCoding,
                        serviceBudgetStatusCoding:serviceBudgetStatusCoding,
                        serviceBugetDateCoding:serviceBugetDateCoding,
                    }).then(() => {
                        const pTexteSaisir = "Budget "+webquestbudgetcodingDiv+" saved successfully";
                        AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
                        onboardCodingDivContainer.style.display = 'none';
                        planningcodingDivContainer.style.display = 'none';
                        schedulecodingDivContainer.style.display = 'none';
                        communicationcodingDivContainer.style.display = 'none';
                        actualisercodingDivContainer.style.display = 'none';
                        contractcodingDivContainer.style.display = 'none';
                        budgetcodingDivContainer.style.display = 'none';
                        //
                        servicebudgetcodingDiv.style.display = 'none';
                        servicebudgetlistcodingDiv.style.display = 'none';
                        servicebudgetpanelcodingreadDiv.style.display = 'none';
                        //
                        servicebudgetcodingcalculateDiv.style.display = 'none';
                        servicebudgetcodingsaveDiv.style.display = 'none';
                    })
                }else{
                    const pTexteSaisir = "The Budget "+webquestbudgetcodingDiv+" already exists";
                    AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
                    onboardCodingDivContainer.style.display = 'none';
                    planningcodingDivContainer.style.display = 'none';
                    schedulecodingDivContainer.style.display = 'none';
                    communicationcodingDivContainer.style.display = 'none';
                    actualisercodingDivContainer.style.display = 'none';
                    contractcodingDivContainer.style.display = 'none';
                    budgetcodingDivContainer.style.display = 'none';
                    //
                    servicebudgetcodingDiv.style.display = 'none';
                    servicebudgetlistcodingDiv.style.display = 'none';
                    servicebudgetpanelcodingreadDiv.style.display = 'none';
                    //
                    servicebudgetcodingcalculateDiv.style.display = 'none';
                    servicebudgetcodingsaveDiv.style.display = 'none';
                }
            });
        }
    }
    //
    const actualiserBudgetService = async (e) => {
        e.preventDefault();

        const viewonboardcodingDiv = document.getElementById('viewonboardcoding');
        const viewplanningcodingDiv = document.getElementById('viewplanningcoding');
        const viewschedulecodingDiv = document.getElementById('viewschedulecoding');
        const viewcommunicationcodingDiv = document.getElementById('viewcommunicationcoding');
        const viewcontractcodingDiv = document.getElementById('viewcontractcoding');
        const viewbudgetcodingDiv = document.getElementById('viewbudgetcoding');
        //
        const budgetcodingviewdetails = document.getElementById('budgetcodingviewdetails');
        const serviceActbudgetcodingcalculateDiv = document.getElementById('serviceActbudgetcodingcalculate');
        const serviceActbudgetcodingsaveDiv = document.getElementById('serviceActbudgetcodingsave');
        //
        const webquestCodeActualisercoding = document.getElementById('webquestCodeActualisercoding').innerText;
        
        var resultatview = 0;

        //http://localhost:5000
        //https://micarionsapi.carions.net
        await Axios.post("https://micarionsapi.carions.net/view/budget/actualiser", {
            webquestCodeActualisercoding:webquestCodeActualisercoding,
        }).then((responsebudgetcoding) => {
            resultatview = responsebudgetcoding.data.length;
            if(resultatview === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                viewonboardcodingDiv.style.display = 'none';
                viewplanningcodingDiv.style.display = 'none';
                viewschedulecodingDiv.style.display = 'none';
                viewcommunicationcodingDiv.style.display = 'none';
                viewcontractcodingDiv.style.display = 'none';
                viewbudgetcodingDiv.style.display = 'none';
                //
                budgetcodingviewdetails.style.display = 'none';
                serviceActbudgetcodingcalculateDiv.style.display = 'none';
                serviceActbudgetcodingsaveDiv.style.display = 'none';
            }else{
                setViewBudgetActualierService(responsebudgetcoding.data);
                viewonboardcodingDiv.style.display = 'none';
                viewplanningcodingDiv.style.display = 'none';
                viewschedulecodingDiv.style.display = 'none';
                viewcommunicationcodingDiv.style.display = 'none';
                viewcontractcodingDiv.style.display = 'none';
                viewbudgetcodingDiv.style.display = 'block';
                //
                budgetcodingviewdetails.style.display = 'block';
                serviceActbudgetcodingcalculateDiv.style.display = 'none';
                serviceActbudgetcodingsaveDiv.style.display = 'none';
            }
        });
    }
    //
    const calculateActBudgetCodingHandler = async (e) => {
        e.preventDefault();

        const viewonboardcodingDiv = document.getElementById('viewonboardcoding');
        const viewplanningcodingDiv = document.getElementById('viewplanningcoding');
        const viewschedulecodingDiv = document.getElementById('viewschedulecoding');
        const viewcommunicationcodingDiv = document.getElementById('viewcommunicationcoding');
        const viewcontractcodingDiv = document.getElementById('viewcontractcoding');
        const viewbudgetcodingDiv = document.getElementById('viewbudgetcoding');
        //
        const budgetcodingviewdetails = document.getElementById('budgetcodingviewdetails');
        const serviceActbudgetcodingcalculateDiv = document.getElementById('serviceActbudgetcodingcalculate');
        const serviceActbudgetcodingsaveDiv = document.getElementById('serviceActbudgetcodingsave');
        //calculate
        const serviceActBudgetPriceCoding = serviceActBudgetPriceCodingRef.current.value;
        const serviceActBudgetOhterCostCoding = serviceActBudgetOhterCostCodingRef.current.value;
        const serviceActBudgetUpfrontCoding = serviceActBudgetUpfrontCodingRef.current.value;
        const serviceActBudgetDeliveryCoding = serviceActBudgetDeliveryCodingRef.current.value;
        //
        var priceCoding = 0;
        var otherCoding = 0;
        var upfrontCoding = 0;
        var totalBudgetCoding = 0;
        var restBudgetCoding = 0;
        var daysDeliveryCoding = 0;
        var dueDateCoding = 0;
        priceCoding = parseFloat(serviceActBudgetPriceCoding);
        otherCoding = parseFloat(serviceActBudgetOhterCostCoding);
        upfrontCoding = parseFloat(serviceActBudgetUpfrontCoding);
        //due date
        daysDeliveryCoding = parseFloat(serviceActBudgetDeliveryCoding);
        dueDateCoding = addCurrentDate(daysDeliveryCoding);
        //
        totalBudgetCoding = (priceCoding + otherCoding);
        restBudgetCoding = ((totalBudgetCoding) - upfrontCoding);
        serviceActBudgetTotalCodingRef.current.value = totalBudgetCoding;
        serviceActBudgetResteCodingRef.current.value = restBudgetCoding;
        serviceActBudgetDueDateCodingRef.current.value = dueDateCoding;
        //
        viewonboardcodingDiv.style.display = 'none';
        viewplanningcodingDiv.style.display = 'none';
        viewschedulecodingDiv.style.display = 'none';
        viewcommunicationcodingDiv.style.display = 'none';
        viewcontractcodingDiv.style.display = 'none';
        viewbudgetcodingDiv.style.display = 'block';
        //
        budgetcodingviewdetails.style.display = 'block';
        serviceActbudgetcodingcalculateDiv.style.display = 'block';
        serviceActbudgetcodingsaveDiv.style.display = 'block';
    }
    //
    const editBudgetCodingHandler = async (e) => {
        e.preventDefault();

        const onboardCodingDivContainer = document.getElementById('onboardcoding');
        const planningcodingDivContainer = document.getElementById('planningcoding');
        const schedulecodingDivContainer = document.getElementById('schedulecoding');
        const communicationcodingDivContainer = document.getElementById('communicationcoding');
        const actualisercodingDivContainer = document.getElementById('actualisercoding');
        const contractcodingDivContainer = document.getElementById('contractcoding');
        const budgetcodingDivContainer = document.getElementById('budgetcoding');
        //
        const webquestCodeActualisercoding = document.getElementById('webquestCodeActualisercoding').innerText;
        const serviceActIdBudgetCoding = document.getElementById('serviceActIdBudgetCoding').innerText;
        const serviceActBudgetDescriptionCoding = serviceActBudgetDescriptionCodingRef.current.value;
        const serviceActBudgetPlanCoding = serviceActBudgetPlanCodingRef.current.value;
        const serviceActBudgetPriceCoding = serviceActBudgetPriceCodingRef.current.value;
        const serviceActBudgetDeliveryCoding = serviceActBudgetDeliveryCodingRef.current.value;
        const serviceActBudgetRevisionCoding = serviceActBudgetRevisionCodingRef.current.value;
        const serviceActBudgetPageCoding = serviceActBudgetPageCodingRef.current.value;
        const serviceActBudgetOhterCostCoding = serviceActBudgetOhterCostCodingRef.current.value;
        const serviceActBudgetUpfrontCoding = serviceActBudgetUpfrontCodingRef.current.value;
        const serviceActBudgetAddNotesCoding = serviceActBudgetAddNotesCodingRef.current.value;
        const serviceActBudgetTotalCoding = serviceActBudgetTotalCodingRef.current.value;
        const serviceActBudgetResteCoding = serviceActBudgetResteCodingRef.current.value;
        const serviceActBudgetDueDateCoding = serviceActBudgetDueDateCodingRef.current.value;
        const serviceActBugetDateCoding = getCurrentDate();
        //
        if(!serviceActBudgetDescriptionCoding){
            const pTexteNotAvailable = "Descriprion is empty";
            AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
        }else if(!serviceActBudgetOhterCostCoding){
            const pTexteNotAvailable = "Other-cost is empty";
            AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
        }else if(!serviceActBudgetUpfrontCoding){
            const pTexteNotAvailable = "Up-front is empty";
            AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
        }else if(!serviceActBudgetAddNotesCoding){
            const pTexteNotAvailable = "Add Notes is empty";
            AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
        }else{
            //http://localhost:5000
            //https://micarionsapi.carions.net
            await Axios.post("https://micarionsapi.carions.net/budget/actualiser", {
                serviceActIdBudgetCoding:serviceActIdBudgetCoding,
                serviceActBudgetDescriptionCoding:serviceActBudgetDescriptionCoding,
                serviceActBudgetPlanCoding:serviceActBudgetPlanCoding,
                serviceActBudgetPriceCoding:serviceActBudgetPriceCoding,
                serviceActBudgetDeliveryCoding:serviceActBudgetDeliveryCoding,
                serviceActBudgetRevisionCoding:serviceActBudgetRevisionCoding,
                serviceActBudgetPageCoding:serviceActBudgetPageCoding,
                serviceActBudgetOhterCostCoding:serviceActBudgetOhterCostCoding,
                serviceActBudgetUpfrontCoding:serviceActBudgetUpfrontCoding,
                serviceActBudgetAddNotesCoding:serviceActBudgetAddNotesCoding,
                serviceActBudgetTotalCoding:serviceActBudgetTotalCoding,
                serviceActBudgetResteCoding:serviceActBudgetResteCoding,
                serviceActBudgetDueDateCoding:serviceActBudgetDueDateCoding,
                serviceActBugetDateCoding:serviceActBugetDateCoding,
            }).then(() => {
                const pTexteSaisir = "Budget "+webquestCodeActualisercoding+" updated successfully";
                AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
                onboardCodingDivContainer.style.display = 'none';
                planningcodingDivContainer.style.display = 'none';
                schedulecodingDivContainer.style.display = 'none';
                communicationcodingDivContainer.style.display = 'none';
                actualisercodingDivContainer.style.display = 'none';
                contractcodingDivContainer.style.display = 'none';
                budgetcodingDivContainer.style.display = 'none';
            });
        }
    }

    /////////////////////
    const goBackServiceAdminHandler = async (e) => {
        e.preventDefault();

        navigateServiceAdminTabcoding('/mainadmin');
    }

    return (
        <div id='servicescoding'>
            <br/>
            <div style={{marginTop:'-2rem'}}>
                <SpanGlobalSpan style={{textAlign:'center'}}>
                    <GlobalStrongTitle>{t("software_coding")} Dashboard</GlobalStrongTitle>
                </SpanGlobalSpan>
            </div>
            <br/>
            <TabsForm>
                <div className="tabstyles">
                    <Tabs defaultIndex={0}>
                        <TabList>
                            <Tab>{t("software_coding")}</Tab>
                        </TabList>
                        <div id="servicecodingpanel">
                            <TabPanel>
                                <div>
                                    <div style={{textAlign:'center', fontSize:'20px', fontStyle:'italic'}}>
                                        <span>{t("software_coding")}</span>
                                    </div>
                                    <br/>
                                    <button className="adminbtn btn-proceed" onClick={adminOnbaordServiceNew}>{t("admin_onboard")}</button>
                                    {` `}
                                    <button className="adminbtn btn-proceed" onClick={adminPlanningService}>{t("admin_planning")}</button>
                                    {` `}
                                    <button className="adminbtn btn-proceed" onClick={adminScheduleService}>{t("admin_schedule")}</button>
                                    {` `}
                                    <button className="adminbtn btn-proceed" onClick={adminCommunicationService}>{t("admin_communication")}</button>
                                    {` `}
                                    <button className="adminbtn btn-proceed" onClick={adminContractService}>{t("admin_contract")}</button>
                                    {` `}
                                    <button className="adminbtn btn-proceed" onClick={adminBudgetService}>{t("admin_budget")}</button>
                                    {` `}
                                    <button className="adminbtn btn-proceed" onClick={adminActualiserService}>{t("admin_update")}</button>
                                </div>

                                {/**Onboard */}
                                <div id='onboardcoding'>
                                    <div id='serviceadminpanelcodingnew'>
                                        <div style={{fontSize:'20px'}}>{t("admin_onboard")}</div>
                                        {serviceAdminCodingTab.map((valservice, indexservice) => (
                                            <div className="view-admin-header" key={indexservice}>
                                                <div style={{cursor:'pointer'}} onClick={() => {serviceHeaderHandler(valservice.id_webquest)}}>
                                                    <p>{frenchDateFormat(valservice.webquest_date)}: {valservice.webquest_type} {valservice.webquest_code}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div id='serviceadminpanelcodingread'>
                                        {valueServiceAdminRead.map((valread, indexread) => (
                                            <div className="view-admin-reader" key={indexread}>
                                                <div style={{fontSize:'20px'}}>{t("admin_onboard")}</div>
                                                <div style={{fontSize:'20px'}}>{valread.webquest_code}</div>
                                                <div>
                                                    <p><strong style={{backgroundColor:'#00bf8e'}}>{t("codingquestion_1")}</strong><br/>{valread.webquest_one}</p>
                                                    <p><strong style={{backgroundColor:'#00bf8e'}}>{t("codingquestion_2")}</strong><br/>{valread.webquest_two}</p>
                                                    <p><strong style={{backgroundColor:'#00bf8e'}}>{t("codingquestion_3")}</strong><br/>{valread.webquest_three}</p>
                                                    <p><strong style={{backgroundColor:'#00bf8e'}}>{t("codingquestion_4")}</strong><br/>{valread.webquest_four}</p>
                                                    <p><strong style={{backgroundColor:'#00bf8e'}}>{t("codingquestion_5")}</strong><br/>{valread.webquest_five}</p>
                                                    <p><strong style={{backgroundColor:'#00bf8e'}}>{t("codingquestion_6")}</strong><br/>{valread.webquest_six}</p>
                                                    <p><strong style={{backgroundColor:'#00bf8e'}}>{t("codingquestion_7")}</strong><br/>{valread.webquest_seven}</p>
                                                    <p><strong style={{backgroundColor:'#00bf8e'}}>{t("codingquestion_8")}</strong><br/>{valread.webquest_eight}</p>
                                                    <p><strong style={{backgroundColor:'#00bf8e'}}>{t("codingquestion_9")}</strong><br/>{valread.webquest_nine}</p>
                                                    <p><strong style={{backgroundColor:'#00bf8e'}}>{t("codingquestion_10")}</strong><br/>{valread.webquest_ten}</p>
                                                    <p><strong style={{backgroundColor:'#00bf8e'}}>{t("codingquestion_11")}</strong><br/>{valread.webquest_eleven}</p>
                                                    <p><strong style={{backgroundColor:'#00bf8e'}}>{t("codingquestion_12")}</strong><br/>{valread.webquest_twelve}</p>
                                                    <div style={{cursor:'pointer'}} onClick={serviceMailHandler}>
                                                        <input style={{backgroundColor:'#00bf8e',fontSize:'15px'}} type='email' defaultValue={valread.webquest_thirteen} ref={serviceMailRef} />
                                                    </div>
                                                    <div style={{cursor:'pointer'}} onClick={serviceWhatsappHandler}>
                                                        <input style={{backgroundColor:'#00bf8e',fontSize:'15px'}} type='text' defaultValue={valread.webquest_fourteen} ref={serviceWhatsappRef} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <br/>
                                </div>

                                {/**Planning */}
                                <div id='planningcoding'>
                                    <div id='serviceplanningcoding'>
                                        <div style={{fontSize:'20px'}}>{t("admin_planning")}</div>
                                        {servicePlanningCodingTab.map((valplanning, indexplanning) => (
                                            <div className="view-admin-header" key={indexplanning}>
                                                <div style={{cursor:'pointer'}} onClick={() => {planningHeaderHandler(valplanning.id_webquest)}}>
                                                    <p>{frenchDateFormat(valplanning.webquest_date)}: {valplanning.webquest_type} {valplanning.webquest_code}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div id='serviceplanningpanelcodingread'>
                                        {valueServicePlanningRead.map((valreadplanning, indexreadplanning) => (
                                            <div key={indexreadplanning}>
                                                <div style={{fontSize:'20px'}}>{t("admin_planning")}</div>
                                                <div id='planningcode' style={{fontSize:'20px'}}>{valreadplanning.webquest_code}</div>
                                                <div id='idwebquest'>{valreadplanning.id_webquest}</div>
                                            </div>
                                        ))}
                                        <TextLoginService>{t("objective_requirements")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                        <div style={{display:'block',textAlign:'center'}}>
                                            <TextAreaView ref={createRequirementsRef}></TextAreaView>
                                        </div>

                                        <TextLoginService>{t("project_plan")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                        <div style={{display:'block',textAlign:'center'}}>
                                            <TextAreaView ref={createPlanRef}></TextAreaView>
                                        </div>

                                        <TextLoginService>{t("project_milestones")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                        <div style={{display:'block',textAlign:'center'}}>
                                            <TextAreaView ref={createMilestonesRef}></TextAreaView>
                                        </div>

                                        <TextLoginService>{t("project_timelines")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                        <div style={{display:'block',textAlign:'center'}}>
                                            <TextAreaView ref={createTimelinesRef}></TextAreaView>
                                        </div>

                                        <TextLoginService>{t("task_responsibilities")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                        <div style={{display:'block',textAlign:'center'}}>
                                            <TextAreaView ref={createResponsibilitiesRef}></TextAreaView>
                                        </div>

                                        <TextLoginService>{t("risks_contingencies")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                        <div style={{display:'block',textAlign:'center'}}>
                                            <TextAreaView ref={createContingenciesRef}></TextAreaView>
                                        </div>

                                        <TextLoginService>{t("review_adjust")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                        <div style={{display:'block',textAlign:'center'}}>
                                            <TextAreaView ref={createAdjustRef}></TextAreaView>
                                        </div>
                                        <br/>
                                        <HandlerContainerDiv>
                                            <div>
                                                <ButtonStart onClick={submitCreateServicesPlaningHandler}>
                                                    <MdOutlineCreate style={{fontSize:'30px'}} />
                                                    {t("creer")}
                                                </ButtonStart>
                                            </div>
                                        </HandlerContainerDiv>
                                    </div>
                                </div>

                                {/**Calendrier */}
                                <div id='schedulecoding'>
                                    <div id='serviceschedulecoding'>
                                        <div style={{fontSize:'20px'}}>{t("admin_schedule")}</div>
                                        <p>{t("info_schedule")}</p>
                                        <div className="view-schedule-table">
                                            <button className='schedule-btn' onClick={scheduleHandleClick}>Show Appointment schedule</button>
                                        </div>
                                        {serviceScheduleCodingTab.map((valschedule, indexschedule) => (
                                            <div className="view-admin-header" key={indexschedule}>
                                                <div style={{cursor:'pointer'}} onClick={() => {scheduleHeaderHandler(valschedule.id_webquest)}}>
                                                    <p>{frenchDateFormat(valschedule.webquest_date)}: {valschedule.webquest_type} {valschedule.webquest_code}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div id='serviceschedulelistcoding'>
                                        <table className='thin_table_config'>
                                            <thead>
                                                <tr>
                                                    <th>Service</th>
                                                    <th>Date</th>
                                                    <th>Start</th>
                                                    <th>End</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.webquest_type}</td>
                                                        <td>{frenchDateFormat(item.schedule_date)}</td>
                                                        <td>{item.schedule_start}</td>
                                                        <td>{item.schedule_end}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div id='serviceschedulepanelcodingread'>
                                        {valueServiceScheduleRead.map((valreadschedule, indexreadschedule) => (
                                            <div key={indexreadschedule}>
                                                <div id='schedulecoding' style={{fontSize:'20px'}}>{valreadschedule.webquest_type}</div>
                                                <div id='schedulecode' style={{fontSize:'20px'}}>{valreadschedule.webquest_code}</div>
                                                <div id='idwebquestschedule'>{valreadschedule.id_webquest}</div>

                                                <div style={{display:'block',textAlign:'center',cursor:'pointer'}} onClick={serviceWhatsappScheduleHandler}>
                                                    <input style={{backgroundColor:'#00bf8e',fontSize:'15px'}} type='text' defaultValue={valreadschedule.webquest_fourteen} ref={serviceWhatsappScheduleRef} />
                                                </div>

                                                <TextLoginService>Date<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <LargeInput type='date' ref={dateScheduleRef} />
                                                </div>

                                                <TextLoginService>Start Time<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <LargeInput type='time' ref={startScheduleRef} />
                                                </div>

                                                <TextLoginService>End Time<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <LargeInput type='time' ref={endScheduleRef} />
                                                </div>

                                                <TextLoginService>Full Name<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <LargeInput type='text' ref={fullNameScheduleRef} />
                                                </div>

                                                <TextLoginService>Email<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <LargeInput type='text' defaultValue={valreadschedule.webquest_thirteen} ref={emailScheduleRef} />
                                                </div>

                                                <TextLoginService>Whatsapp<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <LargeInput type='text' defaultValue={valreadschedule.webquest_fourteen} ref={whatsappScheduleRef} />
                                                </div>

                                                <TextLoginService>Any Important Notes<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <TextAreaView ref={notesScheduleRef}></TextAreaView>
                                                </div>

                                                <div style={{display:'block',textAlign:'center',cursor:'pointer'}} onClick={serviceMailScheduleHandler}>
                                                    <input style={{backgroundColor:'#00bf8e',fontSize:'15px'}} type='email' defaultValue={valreadschedule.webquest_thirteen} ref={serviceMailScheduleRef} />
                                                </div>
                                                <br/>
                                                <HandlerContainerDiv>
                                                    <div>
                                                        <ButtonStart onClick={submitCreateServicesScheduleHandler}>
                                                            <MdOutlineCreate style={{fontSize:'30px'}} />
                                                            {t("creer")}
                                                        </ButtonStart>
                                                    </div>
                                                </HandlerContainerDiv>
                                            </div>
                                        ))} 
                                    </div>
                                </div>

                                {/**Communication */}
                                <div id='communicationcoding'>
                                    <div id='servicecommunicationcoding'>
                                        <div style={{fontSize:'20px'}}>{t("admin_communication")}</div>
                                        <div className="view-schedule-table">
                                            <button className='schedule-btn' onClick={communicationHandleClick}>Show Appointment schedule</button>
                                        </div>
                                        {serviceCommunicationCodingTab.map((valcommunication, indexcommunication) => (
                                            <div className="view-admin-header" key={indexcommunication}>
                                                <div style={{display:'none'}} id='idschedulecommunication'>{valcommunication.id_schedule}</div>
                                                <div style={{cursor:'pointer'}} onClick={() => {communicationHeaderHandler(valcommunication.id_webquest)}}>
                                                    <p>{frenchDateFormat(valcommunication.schedule_date)}: {valcommunication.webquest_type} {valcommunication.webquest_code}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div id='servicecommunicationlistcoding'>
                                        <table className='thin_table_config'>
                                            <thead>
                                                <tr>
                                                    <th>Service</th>
                                                    <th>Date</th>
                                                    <th>Start</th>
                                                    <th>End</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.webquest_type}</td>
                                                        <td>{frenchDateFormat(item.schedule_date)}</td>
                                                        <td>{item.schedule_start}</td>
                                                        <td>{item.schedule_end}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div id='servicecommunicationcodingpanelread'>
                                        {valueServiceCommunicationRead.map((valreadcommunication, indexreadcommunication) => (
                                            <div key={indexreadcommunication}>
                                                <div id='communicationcoding' style={{fontSize:'20px'}}>{valreadcommunication.webquest_type}</div>
                                                <div id='communicationcode' style={{fontSize:'20px'}}>{valreadcommunication.webquest_code}</div>
                                                <div id='idwebquestcommunication'>{valreadcommunication.id_webquest}</div>

                                                <TextLoginService>Customer Project requirements<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                <div style={{display:'block', marginLeft:'10px'}}>
                                                    <div style={{backgroundColor:'white',color:'black'}}>
                                                        <p><strong style={{fontSize:'15px'}}>{t("codingquestion_1")}</strong><br/>{valreadcommunication.webquest_one}</p>
                                                        <p><strong style={{fontSize:'15px'}}>{t("codingquestion_2")}</strong><br/>{valreadcommunication.webquest_two}</p>
                                                        <p><strong style={{fontSize:'15px'}}>{t("codingquestion_3")}</strong><br/>{valreadcommunication.webquest_three}</p>
                                                        <p><strong style={{fontSize:'15px'}}>{t("codingquestion_4")}</strong><br/>{valreadcommunication.webquest_four}</p>
                                                        <p><strong style={{fontSize:'15px'}}>{t("codingquestion_5")}</strong><br/>{valreadcommunication.webquest_five}</p>
                                                        <p><strong style={{fontSize:'15px'}}>{t("codingquestion_6")}</strong><br/>{valreadcommunication.webquest_six}</p>
                                                        <p><strong style={{fontSize:'15px'}}>{t("codingquestion_7")}</strong><br/>{valreadcommunication.webquest_seven}</p>
                                                        <p><strong style={{fontSize:'15px'}}>{t("codingquestion_8")}</strong><br/>{valreadcommunication.webquest_eight}</p>
                                                        <p><strong style={{fontSize:'15px'}}>{t("codingquestion_9")}</strong><br/>{valreadcommunication.webquest_nine}</p>
                                                        <p><strong style={{fontSize:'15px'}}>{t("codingquestion_10")}</strong><br/>{valreadcommunication.webquest_ten}</p>
                                                        <p><strong style={{fontSize:'15px'}}>{t("codingquestion_11")}</strong><br/>{valreadcommunication.webquest_eleven}</p>
                                                        <p><strong style={{fontSize:'15px'}}>{t("codingquestion_12")}</strong><br/>{valreadcommunication.webquest_twelve}</p>
                                                        <br/>
                                                    </div>
                                                </div>
                                                <hr/><hr/><br/>

                                                <div style={{display:'block',textAlign:'center',cursor:'pointer'}} onClick={serviceWhatsappCommunicationHandler}>
                                                    <input style={{backgroundColor:'#00bf8e',fontSize:'15px'}} type='text' defaultValue={valreadcommunication.webquest_fourteen} ref={serviceWhatsappCommunicationRef} />
                                                </div>

                                                <TextLoginService>Date<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <LargeInput type='date' ref={dateCommunicationRef} />
                                                </div>

                                                <TextLoginService>Start Time<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <LargeInput type='time' ref={startCommunicationRef} />
                                                </div>

                                                <TextLoginService>End Time<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <LargeInput type='time' ref={endCommunicationRef} />
                                                </div>

                                                <TextLoginService>Full Name<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <LargeInput type='text' ref={fullNameCommunicationRef} />
                                                </div>

                                                <TextLoginService>Email<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <LargeInput type='text' defaultValue={valreadcommunication.webquest_thirteen} ref={emailCommunicationRef} />
                                                </div>

                                                <TextLoginService>Whatsapp<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <LargeInput type='text' defaultValue={valreadcommunication.webquest_fourteen} ref={whatsappCommunicationRef} />
                                                </div>

                                                <TextLoginService>Report Communication Notes<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <TextAreaView ref={notesCommunicationRef}></TextAreaView>
                                                </div>

                                                <div style={{display:'block',textAlign:'center',cursor:'pointer'}} onClick={serviceMailCommunicationHandler}>
                                                    <input style={{backgroundColor:'#00bf8e',fontSize:'15px'}} type='email' defaultValue={valreadcommunication.webquest_thirteen} ref={serviceMailCommunicationRef} />
                                                </div>
                                                <br/>
                                                <HandlerContainerDiv>
                                                    <div>
                                                        <ButtonStart onClick={submitCreateServicesCommunicationHandler}>
                                                            <MdOutlineCreate style={{fontSize:'30px'}} />
                                                            {t("creer")}
                                                        </ButtonStart>
                                                    </div>
                                                </HandlerContainerDiv>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/**Contract */}
                                <div id='contractcoding'>
                                    <div id='servicecontractcoding'>
                                        <div style={{fontSize:'20px'}}>{t("admin_contract")}</div>
                                        <div className="view-schedule-table">
                                            <button className='schedule-btn' onClick={contractHandleClick}>Show Appointment schedule</button>
                                        </div>
                                        {serviceContractCodingTab.map((valcoding, indexcoding) => (
                                            <div className="view-admin-header" key={indexcoding}>
                                                <div style={{display:'none'}} id='userscodecoding'>{valcoding.users_code}</div>
                                                <div style={{display:'none'}} id='webquestcodecoding'>{valcoding.webquest_code}</div>
                                                <div style={{cursor:'pointer'}} onClick={contractHeaderHandler}>
                                                    <p>{frenchDateFormat(valcoding.webquest_date)}: {valcoding.webquest_type} {valcoding.webquest_code}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div id='servicecontractlistcoding'>
                                        <table className='thin_table_config'>
                                            <thead>
                                                <tr>
                                                    <th>Service</th>
                                                    <th>Date</th>
                                                    <th>Start</th>
                                                    <th>End</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.webquest_type}</td>
                                                        <td>{frenchDateFormat(item.schedule_date)}</td>
                                                        <td>{item.schedule_start}</td>
                                                        <td>{item.schedule_end}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div id='servicecontractcodingpanelread'>
                                        {valueContractService.map((valcontract, indexcontract) => (
                                            <div key={indexcontract}>
                                                <TextLoginService>{t("titrecontenu")}</TextLoginService>
                                                <div>
                                                    <input style={{display:'none'}} ref={idEditContractCodingRef} defaultValue={valcontract.id_contract} />
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <LargeInput type='text' ref={editTitreContractCodingRef} defaultValue={valcontract.contract_titre} />
                                                    </div>
                                                </div>
                                                <br/>
                                                <div>
                                                    <TextLoginService><strong>{t("originaltexte")}</strong></TextLoginService>
                                                    <div className="editornotestyles" id="launchingnote"
                                                        dangerouslySetInnerHTML={{__html: valcontract.contract_contenu}}
                                                    />
                                                    {/** */}
                                                </div>
                                            </div>
                                        ))}

                                        <div className="editrow">
                                            <div className="editor">
                                                {t("nouveautexte")}
                                                <ReactQuill theme="snow"
                                                    value={valueContract}
                                                    onChange={setValueContract}
                                                    className="editor-input"
                                                    modules={modules}
                                                />
                                            </div>
                                            <textarea style={{display:'none'}} ref={editcontracreditorTextContractRef} defaultValue={valueContract}></textarea>
                                            <div style={{display:'none'}} 
                                                dangerouslySetInnerHTML={{__html: valueContract}}
                                            />
                                        </div>
                                        <br/><br/>
                                        <HandlerContainerDiv>
                                            <div>
                                                <ButtonStart onClick={saveContractCodingHandler}>
                                                    <MdSaveAlt style={{fontSize:'30px'}} />
                                                    Save
                                                </ButtonStart>
                                            </div>
                                            <div>
                                                <BackStart onClick={cancelContractCodingHandler}>
                                                    <MdOutlineCancel style={{fontSize:'30px'}} />
                                                    Cancel
                                                </BackStart>
                                            </div>
                                        </HandlerContainerDiv>
                                    </div>
                                </div>

                                {/**Budget */}
                                <div id='budgetcoding'>
                                    <div id='servicebudgetcoding'>
                                        <div style={{fontSize:'20px'}}>{t("admin_budget")}</div>
                                        <div className="view-schedule-table">
                                            <button className='schedule-btn' onClick={budgetHandleClick}>Show Appointment schedule</button>
                                        </div>
                                        {serviceBudgetCodingTab.map((valbudget, indexbudget) => (
                                            <div className="view-admin-header" key={indexbudget}>
                                                <div style={{display:'none'}} id='usersbudgetcoding'>{valbudget.users_code}</div>
                                                <div style={{display:'none'}} id='webquestbudgetcoding'>{valbudget.webquest_code}</div>
                                                <div style={{cursor:'pointer'}} onClick={() => {budgetHeaderHandler(valbudget.id_webquest)}}>
                                                    <p>{frenchDateFormat(valbudget.webquest_date)}: {valbudget.webquest_type} {valbudget.webquest_code}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div id='servicebudgetlistcoding'>
                                        <table className='thin_table_config'>
                                            <thead>
                                                <tr>
                                                    <th>Service</th>
                                                    <th>Date</th>
                                                    <th>Start</th>
                                                    <th>End</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.webquest_type}</td>
                                                        <td>{frenchDateFormat(item.schedule_date)}</td>
                                                        <td>{item.schedule_start}</td>
                                                        <td>{item.schedule_end}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div id='servicebudgetpanelcodingread'>
                                        {valueServiceBudgetRead.map((valreadbudget, indexreadbudget) => (
                                            <div key={indexreadbudget}>
                                                <div id='webquestplanbudgetcoding' style={{fontSize:'20px'}}>{valreadbudget.webquest_plan}</div>
                                                <div id='budgetwebquesttypecoding' style={{fontSize:'20px'}}>{valreadbudget.webquest_type}</div>
                                                <div id='webquestbudgetcodecoding' style={{fontSize:'20px'}}>{valreadbudget.webquest_code}</div>
                                                <div id='idwebquestbudgetcoding'>{valreadbudget.id_webquest}</div>

                                                {servicePlan && (
                                                    <div>
                                                        <TextLogin>Description</TextLogin>
                                                        <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                            <TextAreaView defaultValue={servicePlan.description} ref={serviceBudgetDescriptionCodingRef} />
                                                        </div>
                                                        <br/>
                                                        <TextLogin>Plan</TextLogin>
                                                        <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                            <GlobalLargeInput type='text' defaultValue={servicePlan.webplan} ref={serviceBudgetPlanCodingRef} readOnly />
                                                        </div>
                                                        <br/>
                                                        <TextLogin>Price</TextLogin>
                                                        <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                            <GlobalLargeInput type='text' defaultValue={servicePlan.webprice} ref={serviceBudgetPriceCodingRef} readOnly />
                                                        </div>
                                                        <br/>
                                                        <TextLogin>Delivery</TextLogin>
                                                        <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                            <GlobalLargeInput type='text' defaultValue={servicePlan.delivery} ref={serviceBudgetDeliveryCodingRef} readOnly />
                                                        </div>
                                                        <br/>
                                                        <TextLogin>Revision</TextLogin>
                                                        <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                            <GlobalLargeInput type='text' defaultValue={servicePlan.revision} ref={serviceBudgetRevisionCodingRef} readOnly />
                                                        </div>
                                                        <br/>
                                                        <TextLogin>Page</TextLogin>
                                                        <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                            <GlobalLargeInput type='text' defaultValue={servicePlan.page} ref={serviceBudgetPageCodingRef} readOnly />
                                                        </div>
                                                        <br/>
                                                        <TextLogin>Other Cost</TextLogin>
                                                        <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                            <GlobalLargeInput type='text' defaultValue={0} ref={serviceBudgetOhterCostCodingRef} />
                                                        </div>
                                                        <br/>
                                                        <TextLogin>Up front Paid</TextLogin>
                                                        <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                            <GlobalLargeInput type='text' defaultValue={0} ref={serviceBudgetUpfrontCodingRef} />
                                                        </div>
                                                        <br/>
                                                        <TextLogin>Add Notes</TextLogin>
                                                        <div style={{display:'block',textAlign:'center'}}>
                                                            <TextAreaView ref={serviceBudgetAddNotesCodingRef}></TextAreaView>
                                                        </div>
                                                    </div>
                                                )}
                                                <br/>
                                                <HandlerContainerDiv>
                                                    <div>
                                                        <ButtonStart onClick={calculateBudgetCodingHandler}>
                                                            <FiSettings style={{fontSize:'30px'}} />
                                                            Budget
                                                        </ButtonStart>
                                                    </div>
                                                </HandlerContainerDiv>
                                                <div id='servicebudgetcodingcalculate'>
                                                    <h4>Project Budget</h4>
                                                    <br/>
                                                    <TextLogin>Total Budget</TextLogin>
                                                    <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                        <GlobalLargeInput type='text' ref={serviceBudgetTotalCodingRef} readOnly />
                                                    </div>
                                                    <br/>
                                                    <TextLogin>Reste à payer</TextLogin>
                                                    <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                        <GlobalLargeInput type='text' ref={serviceBudgetResteCodingRef} readOnly />
                                                    </div>
                                                    <br/>
                                                    <TextLogin>Due date</TextLogin>
                                                    <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                        <GlobalLargeInput type='text' ref={serviceBudgetDueDateCodingRef} readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <br/><br/>
                                        <div id='servicebudgetcodingsave'>
                                            <HandlerContainerDiv>
                                                <div>
                                                    <ButtonStart onClick={saveBudgetCodingHandler}>
                                                        <MdSaveAlt style={{fontSize:'30px'}} />
                                                        Save
                                                    </ButtonStart>
                                                </div>
                                                <div>
                                                    <BackStart onClick={cancelContractCodingHandler}>
                                                        <MdOutlineCancel style={{fontSize:'30px'}} />
                                                        Cancel
                                                    </BackStart>
                                                </div>
                                            </HandlerContainerDiv>
                                        </div>
                                    </div>
                                </div>

                                {/**Actualiser */}
                                <div id='actualisercoding'>
                                    <div id='serviceactualisercoding'>
                                        <div style={{fontSize:'20px'}}>{t("admin_update")}</div>
                                        <div className="view-schedule-table">
                                            <button className='schedule-btn' onClick={actualiserHandleClick}>Show Appointment schedule</button>
                                        </div>
                                        {serviceActualiserCodingTab.map((valactualiser, indexactualiser) => (
                                            <div className="view-admin-header" key={indexactualiser}>
                                                <div style={{display:'none'}} id='idscheduleactualiser'>{valactualiser.id_schedule}</div>
                                                <div style={{display:'none'}} id='idwebquestactualiser'>{valactualiser.id_webquest}</div>
                                                <div style={{display:'none'}} id='webquestCodeActualisercoding'>{valactualiser.webquest_code}</div>
                                                <div style={{cursor:'pointer'}} onClick={actualiserHeaderHandler}>
                                                    <p>{frenchDateFormat(valactualiser.webquest_date)}: {valactualiser.webquest_type} {valactualiser.webquest_code}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div id='serviceactualiserlistcoding'>
                                        <table className='thin_table_config'>
                                            <thead>
                                                <tr>
                                                    <th>Service</th>
                                                    <th>Date</th>
                                                    <th>Start</th>
                                                    <th>End</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.webquest_type}</td>
                                                        <td>{frenchDateFormat(item.schedule_date)}</td>
                                                        <td>{item.schedule_start}</td>
                                                        <td>{item.schedule_end}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <br/>
                                    </div>
                                    <div id='serviceactualisercodingpanelread'>
                                        <div>
                                            <button className="actualiserbtn btn-actualiser" onClick={actualiserOnbaordServiceNew}>{t("admin_onboard")}</button>
                                            {` `}
                                            <button className="actualiserbtn btn-actualiser" onClick={actualiserPlanningService}>{t("admin_planning")}</button>
                                            {` `}
                                            <button className="actualiserbtn btn-actualiser" onClick={actualiserScheduleService}>{t("admin_schedule")}</button>
                                            {` `}
                                            <button className="actualiserbtn btn-actualiser" onClick={actualiserCommunicationService}>{t("admin_communication")}</button>
                                            {` `}
                                            <button className="actualiserbtn btn-actualiser" onClick={actualiserContractService}>{t("admin_contract")}</button>
                                            {` `}
                                            <button className="actualiserbtn btn-actualiser" onClick={actualiserBudgetService}>{t("admin_budget")}</button>
                                        </div>
                                    </div>
                                    <div id='viewonboardcoding'>
                                        <div id='webquestviewdetailscoding'>
                                            {viewActualiserService.map((valactservice, indexactservice) =>(
                                                <div key={indexactservice}>
                                                    <TextProceedService>{t("codingquestion_1")}</TextProceedService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextArea defaultValue={valactservice.webquest_one} ref={webQuestion1Ref} />
                                                    </div>

                                                    <TextProceedService>{t("codingquestion_2")}</TextProceedService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextArea defaultValue={valactservice.webquest_two} ref={webQuestion2Ref} />
                                                    </div>

                                                    <TextProceedService>{t("codingquestion_3")}</TextProceedService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextArea defaultValue={valactservice.webquest_three} ref={webQuestion3Ref} />
                                                    </div>

                                                    <TextProceedService>{t("codingquestion_4")}</TextProceedService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextArea defaultValue={valactservice.webquest_four} ref={webQuestion4Ref} />
                                                    </div>

                                                    <TextProceedService>{t("codingquestion_5")}</TextProceedService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextArea defaultValue={valactservice.webquest_five} ref={webQuestion5Ref} />
                                                    </div>

                                                    <TextProceedService>{t("codingquestion_6")}</TextProceedService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextArea defaultValue={valactservice.webquest_six} ref={webQuestion6Ref} />
                                                    </div>

                                                    <TextProceedService>{t("codingquestion_7")}</TextProceedService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextArea defaultValue={valactservice.webquest_seven} ref={webQuestion7Ref} />
                                                    </div>

                                                    <TextProceedService>{t("codingquestion_8")}</TextProceedService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextArea defaultValue={valactservice.webquest_eight} ref={webQuestion8Ref} />
                                                    </div>

                                                    <TextProceedService>{t("codingquestion_9")}</TextProceedService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextArea defaultValue={valactservice.webquest_nine} ref={webQuestion9Ref} />
                                                    </div>

                                                    <TextProceedService>{t("codingquestion_10")}</TextProceedService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextArea defaultValue={valactservice.webquest_ten} ref={webQuestion10Ref} />
                                                    </div>

                                                    <TextProceedService>{t("codingquestion_11")}</TextProceedService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextArea defaultValue={valactservice.webquest_eleven} ref={webQuestion11Ref} />
                                                    </div>

                                                    <TextProceedService>{t("codingquestion_12")}</TextProceedService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextArea defaultValue={valactservice.webquest_twelve} ref={webQuestion12Ref} />
                                                    </div>

                                                    <TextProceedService>{t("codingquestion_13")}</TextProceedService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextArea defaultValue={valactservice.webquest_thirteen} ref={webQuestion13Ref} />
                                                    </div>

                                                    <TextProceedService>Whatsapp contact</TextProceedService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextArea defaultValue={valactservice.webquest_fourteen} ref={webQuestion14Ref} />
                                                    </div>
                                                    <div>
                                                        <br/>
                                                        <HandlerContainerDiv>
                                                        <div>
                                                            <ButtonStart onClick={editWebquestHandler}>
                                                                <MdOutlineEdit style={{fontSize:'30px'}} />
                                                                {t("modifier")}
                                                            </ButtonStart>
                                                        </div>
                                                    </HandlerContainerDiv>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div id='viewplanningcoding'>
                                        <div id='planningviewdetailscoding'>
                                            {viewPlanningActualiserService.map((valactplan, indexactplan) => (
                                                <div key={indexactplan}>

                                                    <TextProceedService>{t("objective_requirements")}<strong style={{color:'red'}}>*</strong></TextProceedService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextAreaView defaultValue={valactplan.planning_one} ref={editRequirementsRef}></TextAreaView>
                                                    </div>

                                                    <TextProceedService>{t("project_plan")}<strong style={{color:'red'}}>*</strong></TextProceedService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextAreaView defaultValue={valactplan.planning_two} ref={editPlanRef}></TextAreaView>
                                                    </div>

                                                    <TextProceedService>{t("project_milestones")}<strong style={{color:'red'}}>*</strong></TextProceedService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextAreaView defaultValue={valactplan.planning_three} ref={editMilestonesRef}></TextAreaView>
                                                    </div>

                                                    <TextProceedService>{t("project_timelines")}<strong style={{color:'red'}}>*</strong></TextProceedService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextAreaView defaultValue={valactplan.planning_four} ref={editTimelinesRef}></TextAreaView>
                                                    </div>

                                                    <TextProceedService>{t("task_responsibilities")}<strong style={{color:'red'}}>*</strong></TextProceedService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextAreaView defaultValue={valactplan.planning_five} ref={editResponsibilitiesRef}></TextAreaView>
                                                    </div>

                                                    <TextProceedService>{t("risks_contingencies")}<strong style={{color:'red'}}>*</strong></TextProceedService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextAreaView defaultValue={valactplan.planning_six} ref={editContingenciesRef}></TextAreaView>
                                                    </div>

                                                    <TextProceedService>{t("review_adjust")}<strong style={{color:'red'}}>*</strong></TextProceedService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextAreaView defaultValue={valactplan.planning_seven} ref={editAdjustRef}></TextAreaView>
                                                    </div>
                                                    
                                                    <div>
                                                        <br/>
                                                        <HandlerContainerDiv>
                                                            <div>
                                                                <ButtonStart onClick={editPlanningHandler}>
                                                                    <MdOutlineEdit style={{fontSize:'30px'}} />
                                                                    {t("modifier")}
                                                                </ButtonStart>
                                                            </div>
                                                        </HandlerContainerDiv>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div id='viewschedulecoding'>
                                        <div id='scheduleviewdetailscoding'>
                                            {viewScheduleActualiserService.map((valactsched, indexactsched) => (
                                                <div key={indexactsched}>
                                                    <TextLoginService>Date<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <label>{formatDateData(valactsched.schedule_date)}</label>
                                                    </div>

                                                    <TextLoginService>Start Time<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <LargeInput type='time' defaultValue={valactsched.schedule_start} ref={editStartScheduleRef} />
                                                    </div>

                                                    <TextLoginService>End Time<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <LargeInput type='time' defaultValue={valactsched.schedule_end} ref={editEndScheduleRef} />
                                                    </div>

                                                    <TextLoginService>Full Name<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <LargeInput type='text' defaultValue={valactsched.schedule_fullname} ref={editFullNameScheduleRef} />
                                                    </div>

                                                    <TextLoginService>Email<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <LargeInput type='text' defaultValue={valactsched.schedule_email} ref={editEmailScheduleRef} />
                                                    </div>

                                                    <TextLoginService>Whatsapp<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <LargeInput type='text' defaultValue={valactsched.schedule_phone} ref={editWhatsappScheduleRef} />
                                                    </div>

                                                    <TextLoginService>Any Important Notes<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextAreaView defaultValue={valactsched.schedule_note} ref={editNotesScheduleRef}></TextAreaView>
                                                    </div>

                                                    <div>
                                                        <br/>
                                                        <HandlerContainerDiv>
                                                            <div>
                                                                <ButtonStart onClick={editScheduleHandler}>
                                                                    <MdOutlineEdit style={{fontSize:'30px'}} />
                                                                    {t("modifier")}
                                                                </ButtonStart>
                                                            </div>
                                                        </HandlerContainerDiv>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div id='viewcommunicationcoding'>
                                        <div id='communicationviewdetailscoding'>
                                            {viewCommunicationActualiserService.map((valactcommunic, indexvalactcommunic) => (
                                                <div key={indexvalactcommunic}>
                                                    <TextLoginService>Date<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <label>{formatDateData(valactcommunic.communic_date)}</label>
                                                    </div>

                                                    <TextLoginService>Start Time<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <LargeInput type='time' defaultValue={valactcommunic.communic_start} ref={editStartCommunicationRef} />
                                                    </div>

                                                    <TextLoginService>End Time<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <LargeInput type='time' defaultValue={valactcommunic.communic_end} ref={editEndCommunicationRef} />
                                                    </div>

                                                    <TextLoginService>Full Name<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <LargeInput type='text' defaultValue={valactcommunic.communic_fullname} ref={editFullNameCommunicationRef} />
                                                    </div>

                                                    <TextLoginService>Email<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <LargeInput type='text' defaultValue={valactcommunic.communic_email} ref={editEmailCommunicationRef} />
                                                    </div>

                                                    <TextLoginService>Whatsapp<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <LargeInput type='text' defaultValue={valactcommunic.communic_phone} ref={editWhatsappCommunicationRef} />
                                                    </div>

                                                    <TextLoginService>Report Communication Notes<strong style={{color:'red'}}>*</strong></TextLoginService>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextAreaView defaultValue={valactcommunic.communic_note} ref={editNotesCommunicationRef}></TextAreaView>
                                                    </div>

                                                    <div>
                                                        <br/>
                                                        <HandlerContainerDiv>
                                                            <div>
                                                                <ButtonStart onClick={editCommunicationHandler}>
                                                                    <MdOutlineEdit style={{fontSize:'30px'}} />
                                                                    {t("modifier")}
                                                                </ButtonStart>
                                                            </div>
                                                        </HandlerContainerDiv>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    
                                    {/**actualiser contract */}
                                    <div id='viewcontractcoding'>
                                        <div id='contractcodingviewdetails'>
                                            {viewContractActualiserService.map((valactcontract, indexvalactcontract) => (
                                                <div key={indexvalactcontract}>
                                                    <TextLoginService>Edit {t("titrecontenu")}</TextLoginService>
                                                    <div>
                                                        <input style={{display:'none'}} ref={idEditViewContractCodingRef} defaultValue={valactcontract.id_signcontract} />
                                                    </div>
                                                    <br/>
                                                    <div>
                                                        <TextLoginService><strong>{t("originaltexte")}</strong></TextLoginService>
                                                        <div className="editornotestyles" id="launchingnote"
                                                            dangerouslySetInnerHTML={{__html: valactcontract.signcontract_contenu}}
                                                        />
                                                    </div>
                                                </div>
                                            ))}

                                            <div className="editrow">
                                                <div className="editor">
                                                   Edit {t("nouveautexte")}
                                                   <ReactQuill theme="snow"
                                                        value={valueEditContract}
                                                        onChange={setValueEditContract}
                                                        className="editor-input"
                                                        modules={modules}
                                                    />
                                                </div>
                                                <textarea style={{display:'none'}} ref={editcontracreditorViewContractRef} defaultValue={valueEditContract}></textarea>
                                                <div style={{display:'none'}} 
                                                    dangerouslySetInnerHTML={{__html: valueEditContract}}
                                                />
                                            </div>
                                            <br/><br/>
                                            <HandlerContainerDiv>
                                                <div>
                                                    <ButtonStart onClick={editContractCodingHandler}>
                                                        <MdSaveAlt style={{fontSize:'30px'}} />
                                                        Edit
                                                    </ButtonStart>
                                                </div>
                                                <div>
                                                    <BackStart onClick={cancelContractCodingHandler}>
                                                        <MdOutlineCancel style={{fontSize:'30px'}} />
                                                        Cancel
                                                    </BackStart>
                                                </div>
                                            </HandlerContainerDiv>
                                        </div>
                                    </div>

                                    {/**Actuliser Budget */}
                                    <div id='viewbudgetcoding'>
                                        <div id='budgetcodingviewdetails'>
                                            {viewBudgetActualiserService.map((valactbudget, indexvalactbudget) => (
                                                <div key={indexvalactbudget}>
                                                    <label id='serviceActIdBudgetCoding'>{valactbudget.id_budget}</label>
                                                    <TextLogin>Description</TextLogin>
                                                    <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                        <TextAreaView defaultValue={valactbudget.budget_description} ref={serviceActBudgetDescriptionCodingRef} />
                                                    </div>
                                                    <br/>
                                                    <TextLogin>Plan</TextLogin>
                                                    <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                        <GlobalLargeInput type='text' defaultValue={valactbudget.budget_plan} ref={serviceActBudgetPlanCodingRef} readOnly />
                                                    </div>
                                                    <br/>
                                                    <TextLogin>Price</TextLogin>
                                                    <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                        <GlobalLargeInput type='text' defaultValue={valactbudget.budget_price} ref={serviceActBudgetPriceCodingRef} readOnly />
                                                    </div>
                                                    <br/>
                                                    <TextLogin>Delivery</TextLogin>
                                                    <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                        <GlobalLargeInput type='text' defaultValue={valactbudget.budget_delivery} ref={serviceActBudgetDeliveryCodingRef} readOnly />
                                                    </div>
                                                    <br/>
                                                    <TextLogin>Revision</TextLogin>
                                                    <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                        <GlobalLargeInput type='text' defaultValue={valactbudget.budget_revision} ref={serviceActBudgetRevisionCodingRef} readOnly />
                                                    </div>
                                                    <br/>
                                                    <TextLogin>Page</TextLogin>
                                                    <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                        <GlobalLargeInput type='text' defaultValue={valactbudget.budget_page} ref={serviceActBudgetPageCodingRef} readOnly />
                                                    </div>
                                                    <br/>
                                                    <TextLogin>Other Cost</TextLogin>
                                                    <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                        <GlobalLargeInput type='text' defaultValue={valactbudget.budget_othercost} ref={serviceActBudgetOhterCostCodingRef} />
                                                    </div>
                                                    <br/>
                                                    <TextLogin>Up front Paid</TextLogin>
                                                    <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                        <GlobalLargeInput type='text' defaultValue={valactbudget.budget_upfront} ref={serviceActBudgetUpfrontCodingRef} />
                                                    </div>
                                                    <br/>
                                                    <TextLogin>Add Notes</TextLogin>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <TextAreaView defaultValue={valactbudget.budget_notes} ref={serviceActBudgetAddNotesCodingRef}></TextAreaView>
                                                    </div>
                                                </div>
                                            ))}
                                            <br/>
                                            <HandlerContainerDiv>
                                                <div>
                                                    <ButtonStart onClick={calculateActBudgetCodingHandler}>
                                                        <FiSettings style={{fontSize:'30px'}} />
                                                        Budget
                                                    </ButtonStart>
                                                </div>
                                            </HandlerContainerDiv>
                                            <div id='serviceActbudgetcodingcalculate'>
                                                <h4>Actualier Project Budget</h4>
                                                <br/>
                                                <TextLogin>Total Budget</TextLogin>
                                                <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                    <GlobalLargeInput type='text' ref={serviceActBudgetTotalCodingRef} readOnly />
                                                </div>
                                                <br/>
                                                <TextLogin>Reste à payer</TextLogin>
                                                <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                    <GlobalLargeInput type='text' ref={serviceActBudgetResteCodingRef} readOnly />
                                                </div>
                                                <br/>
                                                <TextLogin>Due date</TextLogin>
                                                <div style={{display:'block',textAlign:'center',cursor:'pointer'}}>
                                                    <GlobalLargeInput type='text' ref={serviceActBudgetDueDateCodingRef} readOnly />
                                                </div>
                                            </div>
                                            <br/><br/>
                                            <div id='serviceActbudgetcodingsave'>
                                                <HandlerContainerDiv>
                                                    <div>
                                                        <ButtonStart onClick={editBudgetCodingHandler}>
                                                            <MdSaveAlt style={{fontSize:'30px'}} />
                                                            Edit
                                                        </ButtonStart>
                                                    </div>
                                                    <div>
                                                        <BackStart onClick={cancelContractCodingHandler}>
                                                            <MdOutlineCancel style={{fontSize:'30px'}} />
                                                            Cancel
                                                        </BackStart>
                                                    </div>
                                                </HandlerContainerDiv>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <div>
                                    <HandlerContainerDiv>
                                        <div>
                                            <BackStart onClick={goBackServiceAdminHeaderHandler}>
                                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                                {t("retour")}
                                            </BackStart>
                                        </div>
                                    </HandlerContainerDiv>
                                </div>
                                <br/>
                            </TabPanel>
                        </div>
                    </Tabs>
                </div>
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackServiceAdminHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                </div>
                <br/><br/>
                <div id="modaloutalertserviceadmintabcoding"></div>
                <div id="backdropoutalertserviceadmintabcoding"></div>
            </TabsForm>
        </div>
    )
}

export default ServicesCoding