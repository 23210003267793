import React from 'react';
import { useNavigate } from "react-router-dom";
import '../../components.css';
//
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';

import { AlertModal } from "../../../helperfunctions/Helper";

import { 
    DivContainerSlide, 
    GlobalStrongTitle,
    SpanGlobalSpan,
    FormScrollerWidgetSm,
    TextLogin, 
    GlobalLargeInput,
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
} from "../../../global/Styles";

import { MdOutlineCreate, MdSettingsBackupRestore,} from 'react-icons/md';

import { useRef, useState, useEffect } from "react";
import Axios from 'axios';

function EditAccount() {
    const {t} = useTranslation();

    const navigateBackEditAccount = useNavigate();

    //alert
    const elementOutId = "modaloutalerteditaccount";
    const elementDropId = "backdropoutalerteditaccount";
    const buttonHandler = "ouiHandlerAlertEditAccountBtn";

    //set to retriev all comments
    const [valueEditAccount, setValueEditAccount] = useState([0]);

    //http://localhost:5000
    //https://micarionsapi.carions.net

    useEffect(() => {
      var resultat = '';
      const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;

      const fetchAccount = async () => {
          await Axios.post("https://micarionsapi.carions.net/retrieve/account", {
            codeutilisateurDiv:codeutilisateurDiv,
          }).then((responseaccount) => {
              resultat = responseaccount.data.length;
              if(resultat === 0){
                  //
              }else{
                setValueEditAccount(responseaccount.data);
              }
          });
      }
      fetchAccount();
    }, []);

    const editIdRef = useRef(null);
    const editNameRef = useRef(null);
    const editLastNameRef = useRef(null);
    const editPrefixeRef = useRef(null);
    const editPhoneRef = useRef(null);
    const editUsernameRef = useRef(null);
    const editPasswordRef = useRef(null);
    const editConfirmPasswordRef = useRef(null);

    const submitEditAccountHandler = async (e) => {
        e.preventDefault();

        const editId = editIdRef.current.value;
        const editName = editNameRef.current.value;
        const editLastName = editLastNameRef.current.value;
        const editPrefixe = editPrefixeRef.current.value;
        const editPhone = editPhoneRef.current.value;
        const editUsername = editUsernameRef.current.value;
        const editPassword = editPasswordRef.current.value;
        const editConfirmPassword = editConfirmPasswordRef.current.value;
        //
        if(!editName){
            const pTexteSaisir = t("placenom");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editLastName){
            const pTexteSaisir = t("placeprenoms");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editPrefixe){
            const pTexteSaisir = t("placeprefixe");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editPhone){
            const pTexteSaisir = t("placemobile");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editUsername){
            const pTexteSaisir = t("placeusername");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editPassword){
            const pTexteSaisir = t("placepassword");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!editConfirmPassword){
            const pTexteSaisir = t("placeconfirmpassword");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(editPassword !== editConfirmPassword){
            const pTexteSaisir = t("nomatchpassword");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else{
            //
            await Axios.post("https://micarionsapi.carions.net/edit/account", {
                editName:editName,
                editLastName:editLastName,
                editPrefixe:editPrefixe,
                editPhone:editPhone,
                editUsername:editUsername,
                editPassword:editPassword,
                editId:editId,
            }).then(() => {
                navigateBackEditAccount('/cruduseraccount');
            });
        }
    }

    const goBackEditAccountHandler = async (e) => {
        e.preventDefault();

        navigateBackEditAccount('/cruduseraccount');
    }

    return (
        <DivContainerSlide id='editaccount'>
            <br/>
            <div style={{marginTop:'-2rem'}}>
                <SpanGlobalSpan style={{textAlign:'center'}}>
                    <GlobalStrongTitle>{t("crudusermodifier")}</GlobalStrongTitle>
                </SpanGlobalSpan>
            </div>
            <FormScrollerWidgetSm>
                <div className="connectaccountstyle">
                    <br/><br/>
                    {valueEditAccount.map((valaccount, indexaccount) => (
                        <div key={indexaccount}>
                            <div style={{display:'none',textAlign:'center'}}>
                                <GlobalLargeInput type='text' defaultValue={valaccount.id_users} ref={editIdRef}  placeholder={t("placenom")}/>
                            </div>
                            <TextLogin>{t("nom")}<strong style={{color:'red'}}>*</strong></TextLogin>
                            <div style={{display:'block',textAlign:'center'}}>
                                <GlobalLargeInput type='text' defaultValue={valaccount.users_nom} ref={editNameRef}  placeholder={t("placenom")}/>
                            </div>
                            <br/>
                            <TextLogin>{t("prenoms")}<strong style={{color:'red'}}>*</strong></TextLogin>
                            <div style={{display:'block',textAlign:'center'}}>
                                <GlobalLargeInput type='text' defaultValue={valaccount.users_prenoms} ref={editLastNameRef}  placeholder={t("placeprenoms")}/>
                            </div>
                            <br/>
                            <TextLogin>{t("prefixe")}<strong style={{color:'red'}}>*</strong></TextLogin>
                            <div style={{display:'block',textAlign:'center'}}>
                                <GlobalLargeInput type='number' defaultValue={valaccount.users_prefixe} ref={editPrefixeRef} placeholder={t("placeprefixe")}/>
                            </div>
                            <br/>
                            <TextLogin>{t("mobile")}<strong style={{color:'red'}}>*</strong></TextLogin>
                            <div style={{display:'block',textAlign:'center'}}>
                                <GlobalLargeInput type='number' defaultValue={valaccount.users_phone} ref={editPhoneRef} placeholder={t("placemobile")}/>
                            </div>
                            <br/>
                            <TextLogin>{t("username")}<strong style={{color:'red'}}>*</strong></TextLogin>
                            <div style={{display:'block',textAlign:'center'}}>
                                <GlobalLargeInput type='text' defaultValue={valaccount.users_username} ref={editUsernameRef}  placeholder={t("placeusername")}/>
                            </div>
                            <br/>
                            <TextLogin>{t("password")}<strong style={{color:'red'}}>*</strong></TextLogin>
                            <div style={{display:'block',textAlign:'center'}}>
                                <GlobalLargeInput type='text' defaultValue={valaccount.users_password} ref={editPasswordRef} placeholder={t("placepassword")}/>
                            </div>
                            <br/>
                            <TextLogin>{t("confirmpassword")}<strong style={{color:'red'}}>*</strong></TextLogin>
                            <div style={{display:'block',textAlign:'center'}}>
                                <GlobalLargeInput type='text' ref={editConfirmPasswordRef} placeholder={t("placeconfirmpassword")}/>
                            </div>
                            <br/>
                        </div>
                    ))}
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <ButtonStart onClick={submitEditAccountHandler}>
                                    <MdOutlineCreate style={{fontSize:'30px'}} />
                                    {t("modifier")}
                                </ButtonStart>
                            </div>
                            <div>
                                <BackStart onClick={goBackEditAccountHandler}>
                                    <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                    {t("retour")}
                                </BackStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                    <div id="modaloutalerteditaccount"></div>
                    <div id="backdropoutalerteditaccount"></div>
                </div>
                <br/><br/>
            </FormScrollerWidgetSm>
        </DivContainerSlide>
    )
}

export default EditAccount