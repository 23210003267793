import React from "react-dom";
import { useNavigate } from "react-router-dom";
//build tabs tools
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
//
import { AlertModal } from '../../../helperfunctions/Helper';
//
import '../services.css';
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import { HiOutlineClock } from 'react-icons/hi';
import { TbRefresh } from 'react-icons/tb';
//
import {
    SpanGlobalSpan,
    GlobalStrongTitle,
    TabsForm, 
    DivTabRowAround,
    DivTabRowBetween,
    PTab10,
    DivTabColumnAround,
    PTab10Bt0,
    BackStart,  
    HandlerContainerDiv,
} from '../../../global/Styles';

import { useState } from "react";
import Axios from 'axios';

function SoftCodingServicesTab() {
    const {t} = useTranslation();

    const navigateSoftCodingTab = useNavigate();

    //alert
    const elementOutId = "modaloutalertsoftcodingtab";
    const elementDropId = "backdropoutalertsoftcodingtab";
    const buttonHandler = "ouiHandlerAlertSoftCodingTabBtn";

    const [softCodingTabBasic, getSoftCodingTabBasic] = useState([0]);

    var resultatappbasic = "";

    //http://localhost:5000
    //https://micarionsapi.carions.net

    const softCodingBasicHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'basic';
        //show the tab form paragraphs and panel
        const softcodingparagraphDiv = document.getElementById('softcodingparagraph');
        const softcodingpanelDiv = document.getElementById('softcodingpanel');
        //
        await Axios.post("https://micarionsapi.carions.net/seo/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            resultatappbasic = responseappbasic.data;
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getSoftCodingTabBasic(responseappbasic.data);
                //
                softcodingparagraphDiv.style.display = 'block';
                softcodingpanelDiv.style.display = 'block';
            }
        });
    }

    const softCodingStandardHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'standard';
        //show the tab form paragraphs and panel
        const softcodingparagraphDiv = document.getElementById('softcodingparagraph');
        const softcodingpanelDiv = document.getElementById('softcodingpanel');
        //
        await Axios.post("https://micarionsapi.carions.net/seo/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            resultatappbasic = responseappbasic.data;
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getSoftCodingTabBasic(responseappbasic.data);
                //
                softcodingparagraphDiv.style.display = 'block';
                softcodingpanelDiv.style.display = 'block';
            }
        });
    }

    const softCodingPremiumHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'premium';
        //show the tab form paragraphs and panel
        const softcodingparagraphDiv = document.getElementById('softcodingparagraph');
        const softcodingpanelDiv = document.getElementById('softcodingpanel');
        //
        await Axios.post("https://micarionsapi.carions.net/seo/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            resultatappbasic = responseappbasic.data;
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getSoftCodingTabBasic(responseappbasic.data);
                //
                softcodingparagraphDiv.style.display = 'block';
                softcodingpanelDiv.style.display = 'block';
            }
        }); 
    }

    //softcodingservicestab proceed processes
    const codingbasicHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'basic';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateSoftCodingTab('/connectaccount');
            }else{
                navigateSoftCodingTab('/proceedcoding');
            }
        }
    }
    //
    const codingstandardHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'standard';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateSoftCodingTab('/connectaccount');
            }else{
                navigateSoftCodingTab('/proceedcoding');
            }
        }
    }
    //
    const codingpremiumHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'premium';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateSoftCodingTab('/connectaccount');
            }else{
                navigateSoftCodingTab('/proceedcoding');
            }
        }
    }

    //softcodingservicestab contact
    const codingcontactHandler = async (e) => {
        e.preventDefault();

        navigateSoftCodingTab('/contactme');
    }


    const goBackSoftCodingTabHandler = async (e) => {
        e.preventDefault();

        navigateSoftCodingTab('/mainsoftwareservices');
    }

    return ( 
        <div id="softcodingservicestab">
            <br/>
            <div style={{marginTop:'-2rem'}}>
                <SpanGlobalSpan style={{textAlign:'center'}}>
                    <GlobalStrongTitle>{t("software_coding")}</GlobalStrongTitle>
                </SpanGlobalSpan>
            </div>
            <br/>
            <TabsForm>
                {softCodingTabBasic.map((valcodingbasic, indexcodingbasic) => (
                    <div key={indexcodingbasic} className="tabstyles">
                        <div id="softcodingparagraph">
                            
                            <PTab10>
                                {valcodingbasic.services_paragraphone}<br/>
                                {valcodingbasic.services_paragraphtwo}<br/>
                                {valcodingbasic.services_paragraphthree}<br/>
                                {valcodingbasic.services_paragraphfour}<br/>
                            </PTab10>
                        </div>
                        <Tabs defaultIndex={0}>
                            <TabList>
                                <Tab onClick={softCodingBasicHandler}>{t("basic_plan")}</Tab>
                                <Tab onClick={softCodingStandardHandler}>Standard</Tab>
                                <Tab onClick={softCodingPremiumHandler}>Premium</Tab>
                            </TabList>
                            <div id="softcodingpanel">
                                <TabPanel >
                                    <DivTabRowBetween>
                                        <h3>{valcodingbasic.services_titre}</h3><h3>{valcodingbasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valcodingbasic.services_description} 
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valcodingbasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valcodingbasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valcodingbasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valcodingbasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valcodingbasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valcodingbasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valcodingbasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={codingbasicHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={codingcontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                                <TabPanel>
                                    <DivTabRowBetween>
                                        <h3>{valcodingbasic.services_titre}</h3><h3>{valcodingbasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valcodingbasic.services_description}
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valcodingbasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valcodingbasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valcodingbasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valcodingbasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valcodingbasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valcodingbasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valcodingbasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={codingstandardHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={codingcontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                                <TabPanel>
                                    <DivTabRowBetween>
                                        <h3>{valcodingbasic.services_titre}</h3><h3>{valcodingbasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valcodingbasic.services_description}
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valcodingbasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valcodingbasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valcodingbasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valcodingbasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valcodingbasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valcodingbasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valcodingbasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={codingpremiumHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={codingcontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                ))}
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackSoftCodingTabHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertsoftcodingtab"></div>
                <div id="backdropoutalertsoftcodingtab"></div>
            </TabsForm>
        </div>
    );
}

export default SoftCodingServicesTab;