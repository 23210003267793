import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../services.css';
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,
    DivScrollerWidgetSm, 
    BackStart,
    HandlerContainerDiv,
    BigButton,
} from '../../../global/Styles';

function MainSeoServices() {
    const {t} = useTranslation();

    const navigateMainSeoServices = useNavigate();

    const keywordHandler = async (e) => {
        e.preventDefault();

        //type service
        document.getElementById('typeservice').innerText = 'keywordseo';

        navigateMainSeoServices('/keywordtab');
    }

    const onpageHandler = async (e) => {
        e.preventDefault();

        //type service
        document.getElementById('typeservice').innerText = 'onpageseo';

        navigateMainSeoServices('/onpagetab');    
    }

    const technicalHandler = async (e) => {
        e.preventDefault();

        //type service
        document.getElementById('typeservice').innerText = 'technicalseo';

        navigateMainSeoServices('/technicaltab');
    }

    const monthlyHandler = async (e) => {
        e.preventDefault();

        //type service
        document.getElementById('typeservice').innerText = 'monthlyseo';
        
        navigateMainSeoServices('/monthlytab');
    }

    const goBackSeoServiceHandler = async (e) => {
        e.preventDefault();

        navigateMainSeoServices('/');
    }

    return ( 
        <DivContainerSlide id="mainseoservices">
            <br/>
            <div style={{marginTop:'-2rem'}}>
                <SpanGlobalSpan style={{textAlign:'center'}}>
                    <GlobalStrongTitle>{t("seoconsulting")}</GlobalStrongTitle>
                </SpanGlobalSpan>
            </div>
            <DivScrollerWidgetSm>
                <p className="pservicestyles"><br/><br/>{t("webdescription")}</p>
                <div id="seotabaction" className="connectaccountstyle">
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={keywordHandler}>{t("keyword_research")}</BigButton>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={onpageHandler}>{t("on_page")}</BigButton>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={technicalHandler}>{t("technical_audit")}</BigButton>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={monthlyHandler}>{t("monthly_report")}</BigButton>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackSeoServiceHandler}>
                                    <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                    {t("retour")}
                                </BackStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>
            </DivScrollerWidgetSm>
            <br/><br/>
        </DivContainerSlide>
    );
}

export default MainSeoServices;