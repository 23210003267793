import React from 'react';
import { useNavigate } from "react-router-dom";
import '../../components/reactquilleditor/myeditor.css';
//
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';

import { AlertModal } from "../../helperfunctions/Helper";

import { 
    TextLogin, 
    LargeSelect,
    GlobalLargeInput,
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
} from "../../global/Styles";

import { MdOutlineConfirmationNumber, MdSettingsBackupRestore,} from 'react-icons/md';

import { useRef, useState } from "react";
import Axios from 'axios';
//
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import katex from 'katex';
import "katex/dist/katex.min.css";
window.katex = katex;

const modules = {
  toolbar: [[{header: [1, 2, 3, 4, 5, 6,  false] }],
  [{ color: [] }],
  [{ background: [] }],
  [{ script:  "sub" }, { script:  "super" }],
  [{ font: [] }],
  [{ size: [] }],
  ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
  [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
  ],
  ["link", "image", "video", "formula"],
  ["clean"],
],
};

function ViewContractEditor() {
    const {t} = useTranslation();

    const navigateViewContractEditor = useNavigate();

    //alert
    const elementOutId = "modaloutalertcontracteditorview";
    const elementDropId = "backdropoutalertcontracteditorview";
    const buttonHandler = "ouiHandlerAlertContractEditorViewBtn";

    const [valueContractViewType, setValueContractViewType] = useState([0]);
    const [valueContractView, setValueContractView] = useState([0]);

    const viewSelectTypeContractRef = useRef(null);
    const viewSelectTitreContractRef = useRef(null);
    const idViewContractRef = useRef(null);
    const viewTitreContractRef = useRef(null);

    //http://localhost:5000/edit/select/contract/titre

    const viewSelectTypeContractHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        //
        const viewSelectTypeContract = viewSelectTypeContractRef.current.value;
        var resultattype = "";
        //use the same api code of edit axios post
        await Axios.post("https://micarionsapi.carions.net/edit/select/contract/titre", {
            choixlangueDiv:choixlangueDiv,
            codeutilisateurDiv:codeutilisateurDiv,
            editSelectTypeContract:viewSelectTypeContract,
        }).then((responsecontract) => {
            resultattype = responsecontract.data.length;
            if(resultattype === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                setValueContractViewType(responsecontract.data);
            }
        });
    }

    const confirmViewContractEditorHandler = async (e) => {
        e.preventDefault();

        const viewviewcontractDiv = document.getElementById('viewviewcontract');
        //
        const viewSelectTitreContract = viewSelectTitreContractRef.current.value;
        var resultat = "";
        //
        if(!viewSelectTitreContract){
            const pTexteNotAvailable = "Select Contract Title";
            AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
        }else{
            //Use the axios post as in edit contract
            await Axios.post("https://micarionsapi.carions.net/update/contract/confirm/titre", {
                editSelectTitreContract:viewSelectTitreContract,
            }).then((responseconfirm) => {
                resultat = responseconfirm.data.length;
                if(resultat === 0){
                    const pTexteNotAvailable = t("noteindisponible");
                    AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                    //
                    viewviewcontractDiv.style.display = 'none';
                }else{
                    setValueContractView(responseconfirm.data);
                    //
                    viewviewcontractDiv.style.display = 'block';
                }
            });
        }
    }

    const goBackViewContractEditorHandler = async (e) => {
        e.preventDefault();

        navigateViewContractEditor('/contractadmin');
    }

    return (
        <div id='viewcontracteditor'>
            <div>
                <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                    <div id="viewtitreeditor">
                        <TextLogin>{t("typecontenu")}<strong style={{color:'red'}}>*</strong></TextLogin>
                        <div style={{display:'block',textAlign:'center'}}>
                            <LargeSelect onChange={viewSelectTypeContractHandler} ref={viewSelectTypeContractRef}>
                                <optgroup style={{color:'white', backgroundColor:'green'}} label={t("services")}>
                                    <option value=''>{t("typecontenu")}</option>
                                    <option value='coding'>{t("software_coding")}</option>
                                    <option value='design'>{t("software_design")}</option>
                                    <option value='webdev'>{t("software_development")}</option>
                                    <option value='maintenance'>{t("software_maintenance")}</option>
                                    <option value='management'>{t("software_management")}</option>
                                    <option value='miscellanious'>{t("software_miscellanious")}</option>
                                </optgroup>
                                <optgroup style={{color:'white', backgroundColor:'blue'}} label="Web">
                                    <option value='website'>{t("website")}</option>
                                    <option value='webapp'>{t("webapp")}</option>
                                    <option value='ecommerce'>{t("ecommerce")}</option>
                                </optgroup>
                                <optgroup style={{color:'white', backgroundColor:'gray'}} label="SEO">
                                    <option value='seokeyword'>{t("keyword_research")}</option>
                                    <option value='seooptimization'>{t("on_page")}</option>
                                    <option value='seotechnical'>{t("technical_audit")}</option>
                                    <option value='seomonthly'>{t("monthly_report")}</option>
                                </optgroup>
                                <optgroup style={{color:'white', backgroundColor:'yellow'}} label="Hosting">
                                    <option value='hosting'>Hosting Service</option>
                                </optgroup>
                            </LargeSelect>
                        </div>

                        <div>
                            <TextLogin>{t("titrecontenu")}<strong style={{color:'red'}}>*</strong></TextLogin>
                            <LargeSelect ref={viewSelectTitreContractRef}>
                                <option value="⬇️ Select Titre ⬇️"> -- Select Titre -- </option>
                                {valueContractViewType.map((valtype, indextype) => <option key={indextype} value={valtype.id_contract}>{valtype.contract_titre}</option>)}
                            </LargeSelect>
                        </div>
                        <br/>
                        <HandlerContainerDiv>
                            <div>
                                <ButtonStart onClick={confirmViewContractEditorHandler}>
                                    <MdOutlineConfirmationNumber style={{fontSize:'30px'}} />
                                    {t("confirmer")}
                                </ButtonStart>
                            </div>
                            <div>
                                <BackStart onClick={goBackViewContractEditorHandler}>
                                    <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                    {t("retour")}
                                </BackStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                </div>

                <div id="viewviewcontract">
                {valueContractView.map((valview, i) => (
                        <div key={i}>
                            <TextLogin>{t("titrecontenu")}<strong style={{color:'red'}}>*</strong></TextLogin>
                            <div>
                                <input style={{display:'none'}} ref={idViewContractRef} defaultValue={valview.id_contract} />
                                <div style={{display:'block',textAlign:'center'}}>
                                    <GlobalLargeInput type='text' ref={viewTitreContractRef} defaultValue={valview.contract_titre} />
                                </div>
                            </div>
                            <br/>
                            <div className="editrow">
                                <div className="editor">
                                    {t("nouveautexte")}
                                    <ReactQuill theme="snow"
                                        value={valview.contract_contenu}
                                        className="editor-input"
                                        modules={modules}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}

                    <br/><br/>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackViewContractEditorHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                </div>

                <div id="modaloutalertcontracteditorview"></div>
                <div id="backdropoutalertcontracteditorview"></div>
            </div>
        </div>
    )
}

export default ViewContractEditor