import styled from "styled-components";

export const AccueilButton = styled.button`
    
    color: black;
    display: flex;
    width: 100px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid green;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    background-color: white;;
    cursor: pointer;
    :hover{
      background: transparent;
      color: #00a8ff;
      border-color: var(--color-white);
      transition: var(--transition);
    }
    .active{
      color: #00a8ff;
    }
`;

export const AccueilDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 10vh;
    background: #9ab99b;
    color: white;
`;

export const LargeInput = styled.input`
    width:150px;
    height:20px;
    border:none;
    font-size: 17px;
`;

/* translation container*/
export const TranslateContainer = styled.div`
  width: 100%;
  padding: 0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const LeftSection = styled.div`
  width: 100%;
  
`;

export const ButtonLinks = styled.button`
  color:#d4c0c0;
  fontSize: 0.8rem;
  padding:1rem 1.5rem;
  background-color:#6666ff;
  border: 1px solid transparent;
  border-radius:10px;
  cursor:pointer;
  :hover{
    background: transparent;
    border-color: var(--color-white);
  }
  transtion: var(--transition);
  
  @media screen and (max-width: 640px){
    width:90px;
    padding:10px;
  }
  @media screen and (max-width: 768px){
    width:90px;
    padding:10px;
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  padding: 32px;
  margin: 0 auto;
  max-width: 100%;
  box-sizing: content-box;
  position: relative;
  overflow: hidden;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 768px) {
    padding: 24px 48px 0;
    flex-direction: column;
  }

  @media screen and (max-width: 640px) {
    padding: 16px;
    width: calc(100vw - 32px);
    flex-direction: column;
  }
`