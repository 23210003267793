import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../../reactquilleditor/myeditor.css';
//
import {MdDelete, MdOutlineConfirmationNumber, MdSettingsBackupRestore,} from 'react-icons/md';

import { useRef, useState } from "react";
import Axios from 'axios';
import '../../../translations/Translation';

import { AlertModal } from "../../../helperfunctions/Helper";

import { 
    FormScrollerWidgetSm,
    TextLoginService, 
    LargeSelect,
    GlobalLargeInput,
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
    TextAreaView,
} from "../../../global/Styles";
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';

function DeleteServiceEditor() {
    const {t} = useTranslation();

    const navigateDeleteServiceEditor = useNavigate();

    //alert
    const elementOutId = "modaloutalertserviceseditordelete";
    const elementDropId = "backdropoutalertserviceseditordelete";
    const buttonHandler = "ouiHandlerAlertServicesEditorDeleteBtn";

    const [valueDelete, setValueDelete] = useState([0]);

    const deleteSelectTypeServicesContenuRef = useRef(null);
    const deleteSelectPlanServicesContenuRef = useRef(null);
    //
    const idDeleteServiceContenuRef = useRef(null);

    //http://localhost:5000/delete/service/confirm

    const confirmDeleteServicesEditorHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const fonctionuserDiv = document.getElementById('fonctionuser').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        //
        const viewdeleteservicecontenuDiv = document.getElementById('viewdeleteservicecontenu');

        const deleteSelectTypeServicesContenu = deleteSelectTypeServicesContenuRef.current.value;
        const deleteSelectPlanServicesContenu = deleteSelectPlanServicesContenuRef.current.value;
        var resultat1 = "";

        if(!deleteSelectTypeServicesContenu){
            const pTexteSaisir = t("type_contenu");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
            //
            viewdeleteservicecontenuDiv.style.display = 'none';
        }else if(!deleteSelectPlanServicesContenu){
            const pTexteSaisir = t("titre_plan");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
            //
            viewdeleteservicecontenuDiv.style.display = 'none';
        }else{
            await Axios.post("https://micarionsapi.carions.net/delete/service/confirm", {
                choixlangueDiv:choixlangueDiv,
                fonctionuserDiv:fonctionuserDiv,
                codeutilisateurDiv:codeutilisateurDiv,
                deleteSelectTypeServicesContenu:deleteSelectTypeServicesContenu,
                deleteSelectPlanServicesContenu:deleteSelectPlanServicesContenu,
            }).then((response1) => {
                resultat1 = response1.data.length;
                if(resultat1 === 0){
                    const pTexteNotAvailable = t("noteindisponible");
                    AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                    //
                    viewdeleteservicecontenuDiv.style.display = 'none';
                }else{
                    setValueDelete(response1.data);
                    //
                    viewdeleteservicecontenuDiv.style.display = 'block';
                }
            });
        }
    }

    const submitDeleteServiceEditorHandler = async (e) => {
        e.preventDefault();

        const viewdeleteservicecontenuDiv = document.getElementById('viewdeleteservicecontenu');
        //
        const idDeleteServiceContenu = idDeleteServiceContenuRef.current.value;
        const deleteServiceStatusContenu = 'pause';

        await Axios.post("https://micarionsapi.carions.net/update/service/supprimer" , {
            idDeleteServiceContenu:idDeleteServiceContenu,
            deleteServiceStatusContenu:deleteServiceStatusContenu,
        }).then(() => {
            viewdeleteservicecontenuDiv.style.display = 'none';
        });
    }

    const goBackDeleteServicesEditorHandler = async (e) => {
        e.preventDefault();

        navigateDeleteServiceEditor('/crudservicescompo');
    }

    return ( 
        <div id="deleteserviceseditor">
            <FormScrollerWidgetSm>
                <div style={{overflowY:'scroll'}} id="deleteservicestitreeditor">
                    <br/>
                    <TextLoginService>{t("typecontenu")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <LargeSelect ref={deleteSelectTypeServicesContenuRef}>
                            <option value=''>{t("typecontenu")}</option>
                            <option value='web'>Web Development</option>
                            <option value='app'>App Development</option>
                            <option value='ecom'>E-Commerce Development</option>
                            <option value='keywordseo'>Keyword Research SEO</option>
                            <option value='onpageseo'>On-Page Optimization SEO</option>
                            <option value='technicalseo'>Technical SEO Audit</option>
                            <option value='monthlyseo'>Monthly Reporting SEO</option>
                            <option value='codingsoft'>Software Coding Services</option>
                            <option value='designsoft'>Software Design Services</option>
                            <option value='devsoft'>Software Development Services</option>
                            <option value='maintsoft'>Software Maintenance Services</option>
                            <option value='managesoft'>Software Management Services</option>
                            <option value='miscsoft'>Software Miscellanious Services</option>
                            <option value='hosting'>Hosting</option>
                            <option value='rental'>Rental Apps</option>
                            <option value='purchase'>Purchase Apps</option>
                            <option value='training'>Training</option>
                        </LargeSelect>
                    </div>
                    <TextLoginService>{t("type_plan")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <LargeSelect ref={deleteSelectPlanServicesContenuRef}>
                            <option value=''>{t("select_plan")}</option>
                            <option value='basic'>{t("basic_plan")}</option>
                            <option value='standard'>{t("standard_plan")}</option>
                            <option value='Premium'>{t("premium_plan")}</option>
                        </LargeSelect>
                    </div>
                    <br/>
                    <HandlerContainerDiv>
                        <div>
                            <ButtonStart onClick={confirmDeleteServicesEditorHandler}>
                                <MdOutlineConfirmationNumber style={{fontSize:'30px'}} />
                                {t("confirmer")}
                            </ButtonStart>
                        </div>
                        <div>
                            <BackStart onClick={goBackDeleteServicesEditorHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>

                    <div id="viewdeleteservicecontenu">
                        {valueDelete.map((valview, i) => (
                            <div key={i}>
                                <input style={{display:'block'}} ref={idDeleteServiceContenuRef} defaultValue={valview.id_services} />
                                <TextLoginService>{t("paragraphe_1")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <TextAreaView defaultValue={valview.services_paragraphone}></TextAreaView>
                                </div>

                                <TextLoginService>{t("paragraphe_2")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <TextAreaView defaultValue={valview.services_paragraphtwo}></TextAreaView>
                                </div>

                                <TextLoginService>{t("paragraphe_3")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <TextAreaView defaultValue={valview.services_paragraphthree}></TextAreaView>
                                </div>

                                <TextLoginService>{t("paragraphe_4")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <TextAreaView defaultValue={valview.services_paragraphfour}></TextAreaView>
                                </div>

                                <TextLoginService>{t("service_title")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <GlobalLargeInput type='text' defaultValue={valview.services_titre} />
                                </div>

                                <TextLoginService>{t("service_price")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <GlobalLargeInput type='text' defaultValue={valview.services_price} />
                                </div>

                                <TextLoginService>{t("plan_description")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <TextAreaView defaultValue={valview.services_description}></TextAreaView>
                                </div>

                                <TextLoginService>{t("days_delivery")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <GlobalLargeInput type='text' defaultValue={valview.services_delivery} />
                                </div>

                                <TextLoginService>{t("revision_number")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <GlobalLargeInput type='text' defaultValue={valview.services_revision} />
                                </div>

                                <TextLoginService>{t("page_number")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <GlobalLargeInput type='text' defaultValue={valview.services_page} />
                                </div>

                                <TextLoginService>{t("design_customization")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <GlobalLargeInput type='text' defaultValue={valview.services_design} />
                                </div>

                                <TextLoginService>{t("content_upload")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <GlobalLargeInput type='text' defaultValue={valview.services_content} />
                                </div>

                                <TextLoginService>{t("responsive_design")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <GlobalLargeInput type='text' defaultValue={valview.services_responsive} />
                                </div>

                                <TextLoginService>{t("source_code")}<strong style={{color:'red'}}>*</strong></TextLoginService>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <GlobalLargeInput type='text' defaultValue={valview.services_source}/>
                                </div>

                                <br/><br/>
                                <HandlerContainerDiv>
                                    <div>
                                        <ButtonStart onClick={submitDeleteServiceEditorHandler}>
                                            <MdDelete style={{fontSize:'30px'}} />
                                            {t("supprimer")}
                                        </ButtonStart>
                                    </div>
                                    <div>
                                        <BackStart onClick={goBackDeleteServicesEditorHandler}>
                                            <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                            {t("retour")}
                                        </BackStart>
                                    </div>
                                </HandlerContainerDiv>
                            </div>
                        ))}
                    </div>
                </div>
                <br/><br/>
                <div id="modaloutalertserviceseditordelete"></div>
                <div id="backdropoutalertserviceseditordelete"></div>
            </FormScrollerWidgetSm>
        </div>
    );
}

export default DeleteServiceEditor;