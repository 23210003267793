import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import './accueil.css';
//
import whatsapp from './whatsapp-icon.png';
import facebook from './facebook-icon.png';
import linkedin from './linkedin-icon.png';
//
import { useTranslation } from 'react-i18next';
import '../translations/i18n';
import '../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
    MdShare,
} from 'react-icons/md';
//
import { AiOutlineCloseCircle } from 'react-icons/ai';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,
    BackStart,  
    ButtonStart,
    HandlerContainerDiv,
} from '../global/Styles';

function ShareCarions() {
    const {t} = useTranslation();

    const navigateShareCarions = useNavigate();

    const [showModal, setShowModal] = useState(false);

    const handleShareButtonClick = async (e) => {
        e.preventDefault();

        setShowModal(true);
    }

    const handleSocialShare = async (platform) => {

        // Handle social share logic here based on the selected platform
        switch (platform) {
            case 'whatsapp':
                const appLinkWhatsapp = "https://www.micarions.carions.net";
                // Handle WhatsApp share
                // Open WhatsApp with a pre-filled message or link
                const whatsappMessage = encodeURIComponent(`${t("check_out")} ${appLinkWhatsapp}`);
                window.open(`https://api.whatsapp.com/send?text=${whatsappMessage}`);
                break;
            case 'linkedin':
                const appLinkedin = "https://www.micarions.carions.net";
                // Handle LinkedIn share
                window.open(`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(appLinkedin)}`);
                break;
            case 'facebook':
                const appLinkFacebook = "https://www.micarions.carions.net";
                // Handle Facebook share
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(appLinkFacebook)}`);
                break;
            default:
              break;
        }
        setShowModal(false); // Close the modal after sharing
    }

    const goCloseShareCarionsHandler = async (e) => {
        e.preventDefault();

        setShowModal(false); // Close the modal after sharing
    }

    const goBackShareCarionsHandler = async (e) => {
        e.preventDefault();

        navigateShareCarions("/")
    }

    return (
        <DivContainerSlide id='sharecarions'>
            <br/><br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t("share_title")}</GlobalStrongTitle>
            </SpanGlobalSpan>

            <div>
                <div className='sharecontent'>
                    <p className="pshareservicestyles"><br/>{t("share_description_1")}</p>
                    <p className="pshareservicestyles"><br/>{t("share_description_2")}</p>
                    <p className="pshareservicestyles"><br/>{t("share_description_3")}</p>
                    <p className="pshareservicestyles"><br/>{t("share_description_4")}</p>
                    <p className="pshareservicestyles"><br/>{t("share_description_5")}</p>
                    <p className="pshareservicestyles"><br/>{t("share_description_6")}</p>
                    <p className="pshareservicestyles"><br/>{t("share_description_7")}</p>
                    <p className="pshareservicestyles"><br/>{t("share_description_8")}</p>
                    <p className="pshareservicestyles"><br/>{t("share_description_9")}</p>
                    <p className="pshareservicestyles"><br/>{t("share_description_10")}</p>
                    <p className="pshareservicestyles"><br/>{t("share_description_11")}</p>
                </div>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <ButtonStart onClick={handleShareButtonClick}>
                                <MdShare />
                            </ButtonStart>
                        </div>
                    </HandlerContainerDiv>
                    {showModal && (
                        <div className="sharemodal">
                            <div className="sharemodal-content">
                                <h3>{t("share_with")}</h3>
                                <div className="sharesocial-icons">
                                    <button onClick={() => handleSocialShare('whatsapp')}>
                                        <img src={whatsapp} alt="WhatsApp" />
                                    </button>
                                    <button onClick={() => handleSocialShare('linkedin')}>
                                        <img src={linkedin} alt="LinkedIn" />
                                    </button>
                                    <button onClick={() => handleSocialShare('facebook')}>
                                        <img src={facebook} alt="Facebook" />
                                    </button>
                                </div>
                                <br/>
                                    <HandlerContainerDiv>
                                        <div>
                                            <BackStart onClick={goCloseShareCarionsHandler}>
                                                <AiOutlineCloseCircle style={{fontSize:'30px'}} />
                                            </BackStart>
                                        </div>
                                    </HandlerContainerDiv>
                            </div>
                        </div>
                    )}
                </div>
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackShareCarionsHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                OK
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
            </div>
        </DivContainerSlide>
    )
}

export default ShareCarions