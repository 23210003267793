import React from "react-dom";
import { useNavigate } from "react-router-dom";
//build tabs tools
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
//
import { AlertModal } from '../../../helperfunctions/Helper';
//
import '../services.css';
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import { HiOutlineClock } from 'react-icons/hi';
import { TbRefresh } from 'react-icons/tb';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,
    TabsForm, 
    DivTabRowAround,
    DivTabRowBetween,
    PTab10,
    DivTabColumnAround,
    PTab10Bt0,
    BackStart,  
    HandlerContainerDiv,
} from '../../../global/Styles';

import { useState } from "react";
import Axios from 'axios';

function WebSiteTab() {
    const {t} = useTranslation();

    const navigateWebSiteTab = useNavigate();

    //alert
    const elementOutId = "modaloutalertwebcontenu";
    const elementDropId = "backdropoutalertwebcontenu";
    const buttonHandler = "ouiHandlerAlertWebContenuBtn";

    const [webTabBasic, getWebTabBasic] = useState([0]);

    var resultatwebbasic = "";

    //http://localhost:5000
    //https://micarionsapi.carions.net

    //if basic clicked
    const webBasicHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'basic';
        //show the tab form paragraphs and panel
        const websiteparagraphDiv = document.getElementById('websiteparagraph');
        const websitepanelDiv = document.getElementById('websitepanel');
        //
        await Axios.post("https://micarionsapi.carions.net/website/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responsewebbasic) => {
            if(resultatwebbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getWebTabBasic(responsewebbasic.data);
                //
                websiteparagraphDiv.style.display = 'block';
                websitepanelDiv.style.display = 'block';
            }
        });  
    }

    const webStandardHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'standard';
        //
        await Axios.post("https://micarionsapi.carions.net/website/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responsewebbasic) => {
            if(resultatwebbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getWebTabBasic(responsewebbasic.data);
                //
            }
        });  
    }

    const webPremiumHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'premium';
        //
        await Axios.post("https://micarionsapi.carions.net/website/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responsewebbasic) => {
            if(resultatwebbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getWebTabBasic(responsewebbasic.data);
                //
            }
        });  
    }

    //website proceed processes
    const websitebasicHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'basic';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateWebSiteTab('/connectaccount');
            }else{
                navigateWebSiteTab('/proceedweb');
            }
        }
    }
    //
    const websitestandardHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'standard';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateWebSiteTab('/connectaccount');
            }else{
                navigateWebSiteTab('/proceedweb');
            }
        }
    }
    //
    const websitepremiumHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'premium';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateWebSiteTab('/connectaccount');
            }else{
                navigateWebSiteTab('/proceedweb');
            }
        }
    }

    //website contact
    const websitecontactHandler = async (e) => {
        e.preventDefault();

        navigateWebSiteTab('/contactme');
    }

    const goBackWebSiteTabHandler = async (e) => {
        e.preventDefault();

        navigateWebSiteTab('/mainwebservices');
    }

    return ( 
        <DivContainerSlide id="websitetab">
            <br/>
            <div style={{marginTop:'-2rem'}}>
                <SpanGlobalSpan style={{textAlign:'center'}}>
                    <GlobalStrongTitle>{t("websitetitre")}</GlobalStrongTitle>
                </SpanGlobalSpan>
            </div>
            <br/>
            <TabsForm>
                {webTabBasic.map((valwebbasic, indexwebbasic) => (
                    <div key={indexwebbasic} className="tabstyles">
                        <div id="websiteparagraph">
                            <PTab10>
                                {valwebbasic.services_paragraphone}<br/>
                                {valwebbasic.services_paragraphtwo}<br/>
                                {valwebbasic.services_paragraphthree}<br/>
                                {valwebbasic.services_paragraphfour}<br/>
                            </PTab10>
                        </div>
                        <Tabs defaultIndex={0}>
                            <TabList>
                                <Tab onClick={webBasicHandler}>{t("basic_plan")}</Tab>
                                <Tab onClick={webStandardHandler}>Standard</Tab>
                                <Tab onClick={webPremiumHandler}>Premium</Tab>
                            </TabList>
                            <div id="websitepanel">
                                <TabPanel >
                                    <DivTabRowBetween>
                                        <h3>{valwebbasic.services_titre}</h3><h3>{valwebbasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valwebbasic.services_description} 
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valwebbasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valwebbasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valwebbasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valwebbasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valwebbasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valwebbasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valwebbasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={websitebasicHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={websitecontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                                <TabPanel>
                                    <DivTabRowBetween>
                                        <h3>{valwebbasic.services_titre}</h3><h3>{valwebbasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valwebbasic.services_description}
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valwebbasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valwebbasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valwebbasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valwebbasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valwebbasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valwebbasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valwebbasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={websitestandardHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={websitecontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                                <TabPanel>
                                    <DivTabRowBetween>
                                        <h3>{valwebbasic.services_titre}</h3><h3>{valwebbasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valwebbasic.services_description}
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valwebbasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valwebbasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valwebbasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valwebbasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valwebbasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valwebbasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valwebbasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={websitepremiumHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={websitecontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                ))}
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackWebSiteTabHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertwebcontenu"></div>
                <div id="backdropoutalertwebcontenu"></div>
            </TabsForm>
        </DivContainerSlide>
    );
}

export default WebSiteTab;