import { useEffect, useState } from "react";
import './accueil.css';
import imageaccount from '../carions-icon-192.png';
import DOMPurify from 'dompurify';

import { useTranslation } from 'react-i18next';
import '../translations/i18n';
import '../translations/Translation';

import {MdSettingsBackupRestore} from 'react-icons/md';
//
import {
    BackStart,  
} from '../global/Styles';

import { useNavigate } from 'react-router-dom';
import Axios from 'axios';

function AproposDetail() {
    const {t} = useTranslation();

    const [apropos, setApropos] = useState([0]);
    const navigateapropos = useNavigate();

    //http://localhost:5000
    //https://micarionsapi.carions.net

    useEffect(() => {
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeContenu = "apropos";
        
        const fetchApropos = async () => {
            await Axios.post("https://micarionsapi.carions.net/accueil", {
                choixlangueDiv:choixlangueDiv,
                typeContenu:typeContenu,
            }).then((response) => {
                setApropos(response.data);
                
            });
        }
        fetchApropos();
    }, []);

    const goBackAproposHandler = async (e) => {
        e.preventDefault();

        navigateapropos(-1);
    }

    return ( 
        <div className="accueil__details" id="accueildetails">
            <br/><br/>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'-5rem'}}>
                <img src={imageaccount} alt="" />
            </div>
            <section style={{marginTop:'-8rem'}}>
                <div className="aproposcontent">
                    {apropos.map((valapropos, indexapropos) => (
                        <div key={indexapropos} className="launchingnotestyles"
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(valapropos.contenu_texte)}}
                        /> 
                    ))}
                </div>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                    <div>
                        <BackStart onClick={goBackAproposHandler}>
                            <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                            {t("retour")}
                        </BackStart>
                    </div>
                </div>
                <br/>
            </section>
        </div>
    );
}

export default AproposDetail;