import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../proceedweb.css';
import { useRef, useState } from "react";
//
import { MdOutlineCreate, MdSettingsBackupRestore, MdOutlineArrowForwardIos, MdOutlineArrowBackIos, MdOutlineAttachEmail } from 'react-icons/md';
import { AiOutlineWhatsApp } from 'react-icons/ai';
//
import { AlertModal, getCurrentDate, PopupModal } from "../../helperfunctions/Helper";
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
import {  
    SpanGlobalSpan,
    GlobalStrongTitle,
    LongFormScrollerWidgetSm,
    TextProceedService, 
    TextLoginService,
    GlobalLargeInput, 
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
    TextArea,
} from "../../global/Styles";

import Axios from 'axios';

function ProceedMonthly() {
    const {t} = useTranslation();

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleSeoMonthChange = event => {
        if (!isValidEmail(event.target.value)) {
          setError(t('invalidemail'));
        } else {
          setError(null);
        }
    
        setEmail(event.target.value);
    };

    const navigateProceedSeoMonth = useNavigate();

    //alert
    const elementOutId = "modaloutproceedseomonth";
    const elementDropId = "backdropoutproceedseomonth";
    const buttonHandler = "ouiHandlerProceedSeoMonthBtn";
    //
    const elementOutAlertId = "modaloutalertproceedseomonth";
    const elementDropAlertId = "backdropoutalertproceedseomonth";
    const buttonAlertHandler = "ouiHandleralertProceedSeoMonthBtn";

    const monthSeoQuestion1Ref = useRef(null);
    const monthSeoQuestion2Ref = useRef(null);
    const monthSeoQuestion3Ref = useRef(null);
    //
    const monthSeoQuestion4Ref = useRef(null);
    const monthSeoQuestion5Ref = useRef(null);
    const monthSeoQuestion6Ref = useRef(null);
    //
    const monthSeoQuestion7Ref = useRef(null);
    const monthSeoQuestion8Ref = useRef(null);
    const monthSeoQuestion9Ref = useRef(null);
    //
    const monthSeoQuestion10Ref = useRef(null);
    const monthSeoQuestion11Ref = useRef(null);
    const monthSeoQuestion12Ref = useRef(null);
    //
    const monthSeoQuestion13Ref = useRef(null);
    const monthSeoQuestion14Ref = useRef(null);

    const popseomonth1Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_1");
        const pTexteNotAvailable = t("hintseoquestion_1");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseomonth2Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_2");
        const pTexteNotAvailable = t("hintseoquestion_2");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseomonth3Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_3");
        const pTexteNotAvailable = t("hintseoquestion_3");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const popseomonth4Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_4");
        const pTexteNotAvailable = t("hintseoquestion_4");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseomonth5Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_5");
        const pTexteNotAvailable = t("hintseoquestion_5");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseomonth6Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_6");
        const pTexteNotAvailable = t("hintseoquestion_6");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const popseomonth7Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_7");
        const pTexteNotAvailable = t("hintseoquestion_7");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseomonth8Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_8");
        const pTexteNotAvailable = t("hintseoquestion_8");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseomonth9Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_9");
        const pTexteNotAvailable = t("hintseoquestion_9");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const popseomonth10Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_10");
        const pTexteNotAvailable = t("hintseoquestion_10");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseomonth11Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_11");
        const pTexteNotAvailable = t("hintseoquestion_11");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseomonth12Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_12");
        const pTexteNotAvailable = t("hintseoquestion_12");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const forwardProceedSeoMonth1Handler = async (e) => {
        e.preventDefault();

        const seomonthsteponeDiv = document.getElementById('seomonthstepone');
        const seomonthsteptwoDiv = document.getElementById('seomonthsteptwo');
        const seomonthstepthreeDiv = document.getElementById('seomonthstepthree');
        const seomonthstepfourDiv = document.getElementById('seomonthstepfour');
        const modeproceedseomonthDiv = document.getElementById('modeproceedseomonth');
        const submitproceedseomonthDiv = document.getElementById('submitproceedseomonth');
        //
        seomonthsteponeDiv.style.display = 'none';
        seomonthsteptwoDiv.style.display = 'block';
        seomonthstepthreeDiv.style.display = 'none';
        seomonthstepfourDiv.style.display = 'none';
        modeproceedseomonthDiv.style.display = 'none';
        submitproceedseomonthDiv.style.display = 'none';
    }

    const goBackProceedSeoMonth2Handler = async (e) => {
        e.preventDefault();

        const seomonthsteponeDiv = document.getElementById('seomonthstepone');
        const seomonthsteptwoDiv = document.getElementById('seomonthsteptwo');
        const seomonthstepthreeDiv = document.getElementById('seomonthstepthree');
        const seomonthstepfourDiv = document.getElementById('seomonthstepfour');
        const modeproceedseomonthDiv = document.getElementById('modeproceedseomonth');
        const submitproceedseomonthDiv = document.getElementById('submitproceedseomonth');
        //
        seomonthsteponeDiv.style.display = 'block';
        seomonthsteptwoDiv.style.display = 'none';
        seomonthstepthreeDiv.style.display = 'none';
        seomonthstepfourDiv.style.display = 'none';
        modeproceedseomonthDiv.style.display = 'none';
        submitproceedseomonthDiv.style.display = 'none';
    }
    //
    const forwardProceedSeoMonth2Handler = async (e) => {
        e.preventDefault();

        const seomonthsteponeDiv = document.getElementById('seomonthstepone');
        const seomonthsteptwoDiv = document.getElementById('seomonthsteptwo');
        const seomonthstepthreeDiv = document.getElementById('seomonthstepthree');
        const seomonthstepfourDiv = document.getElementById('seomonthstepfour');
        const modeproceedseomonthDiv = document.getElementById('modeproceedseomonth');
        const submitproceedseomonthDiv = document.getElementById('submitproceedseomonth');
        //
        seomonthsteponeDiv.style.display = 'none';
        seomonthsteptwoDiv.style.display = 'none';
        seomonthstepthreeDiv.style.display = 'block';
        seomonthstepfourDiv.style.display = 'none';
        modeproceedseomonthDiv.style.display = 'none';
        submitproceedseomonthDiv.style.display = 'none';
    }

    const goBackProceedSeoMonth3Handler = async (e) => {
        e.preventDefault();

        const seomonthsteponeDiv = document.getElementById('seomonthstepone');
        const seomonthsteptwoDiv = document.getElementById('seomonthsteptwo');
        const seomonthstepthreeDiv = document.getElementById('seomonthstepthree');
        const seomonthstepfourDiv = document.getElementById('seomonthstepfour');
        const modeproceedseomonthDiv = document.getElementById('modeproceedseomonth');
        const submitproceedseomonthDiv = document.getElementById('submitproceedseomonth');
        //
        seomonthsteponeDiv.style.display = 'none';
        seomonthsteptwoDiv.style.display = 'block';
        seomonthstepthreeDiv.style.display = 'none';
        seomonthstepfourDiv.style.display = 'none';
        modeproceedseomonthDiv.style.display = 'none';
        submitproceedseomonthDiv.style.display = 'none';
    }
    //
    const forwardProceedSeoMonth3Handler = async (e) => {
        e.preventDefault();

        const seomonthsteponeDiv = document.getElementById('seomonthstepone');
        const seomonthsteptwoDiv = document.getElementById('seomonthsteptwo');
        const seomonthstepthreeDiv = document.getElementById('seomonthstepthree');
        const seomonthstepfourDiv = document.getElementById('seomonthstepfour');
        const modeproceedseomonthDiv = document.getElementById('modeproceedseomonth');
        const submitproceedseomonthDiv = document.getElementById('submitproceedseomonth');
        //
        seomonthsteponeDiv.style.display = 'none';
        seomonthsteptwoDiv.style.display = 'none';
        seomonthstepthreeDiv.style.display = 'none';
        seomonthstepfourDiv.style.display = 'block';
        modeproceedseomonthDiv.style.display = 'none';
        submitproceedseomonthDiv.style.display = 'none';
    }

    const goBackProceedSeoMonth4Handler = async (e) => {
        e.preventDefault();

        const seomonthsteponeDiv = document.getElementById('seomonthstepone');
        const seomonthsteptwoDiv = document.getElementById('seomonthsteptwo');
        const seomonthstepthreeDiv = document.getElementById('seomonthstepthree');
        const seomonthstepfourDiv = document.getElementById('seomonthstepfour');
        const modeproceedseomonthDiv = document.getElementById('modeproceedseomonth');
        const submitproceedseomonthDiv = document.getElementById('submitproceedseomonth');
        //
        seomonthsteponeDiv.style.display = 'none';
        seomonthsteptwoDiv.style.display = 'none';
        seomonthstepthreeDiv.style.display = 'block';
        seomonthstepfourDiv.style.display = 'none';
        modeproceedseomonthDiv.style.display = 'none';
        submitproceedseomonthDiv.style.display = 'none';
    }
    //
    const forwardProceedSeoMonth4Handler = async (e) => {
        e.preventDefault();

        const seomonthsteponeDiv = document.getElementById('seomonthstepone');
        const seomonthsteptwoDiv = document.getElementById('seomonthsteptwo');
        const seomonthstepthreeDiv = document.getElementById('seomonthstepthree');
        const seomonthstepfourDiv = document.getElementById('seomonthstepfour');
        const modeproceedseomonthDiv = document.getElementById('modeproceedseomonth');
        const submitproceedseomonthDiv = document.getElementById('submitproceedseomonth');
        //
        seomonthsteponeDiv.style.display = 'none';
        seomonthsteptwoDiv.style.display = 'none';
        seomonthstepthreeDiv.style.display = 'none';
        seomonthstepfourDiv.style.display = 'none';
        modeproceedseomonthDiv.style.display = 'block';
        submitproceedseomonthDiv.style.display = 'none';
    }

    const goBackProceedSeoMonth5Handler = async (e) => {
        e.preventDefault();

        const seomonthsteponeDiv = document.getElementById('seomonthstepone');
        const seomonthsteptwoDiv = document.getElementById('seomonthsteptwo');
        const seomonthstepthreeDiv = document.getElementById('seomonthstepthree');
        const seomonthstepfourDiv = document.getElementById('seomonthstepfour');
        const modeproceedseomonthDiv = document.getElementById('modeproceedseomonth');
        const submitproceedseomonthDiv = document.getElementById('submitproceedseomonth');
        //
        seomonthsteponeDiv.style.display = 'none';
        seomonthsteptwoDiv.style.display = 'none';
        seomonthstepthreeDiv.style.display = 'none';
        seomonthstepfourDiv.style.display = 'block';
        modeproceedseomonthDiv.style.display = 'none';
        submitproceedseomonthDiv.style.display = 'none';
    }
    //
    const forwardProceedSeoMonth5Handler = async (e) => {
        e.preventDefault();

        const monthSeoQuestion13 = monthSeoQuestion13Ref.current.value;
        const monthSeoQuestion14 = monthSeoQuestion14Ref.current.value;
        //
        const seomonthsteponeDiv = document.getElementById('seomonthstepone');
        const seomonthsteptwoDiv = document.getElementById('seomonthsteptwo');
        const seomonthstepthreeDiv = document.getElementById('seomonthstepthree');
        const seomonthstepfourDiv = document.getElementById('seomonthstepfour');
        const modeproceedseomonthDiv = document.getElementById('modeproceedseomonth');
        const submitproceedseomonthDiv = document.getElementById('submitproceedseomonth');
        //
        if(!monthSeoQuestion13){
            const pTexteSaisir = t("enteremail");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else if(!monthSeoQuestion14){
            const pTexteSaisir = t("enterphone");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else{
            //
            seomonthsteponeDiv.style.display = 'none';
            seomonthsteptwoDiv.style.display = 'none';
            seomonthstepthreeDiv.style.display = 'none';
            seomonthstepfourDiv.style.display = 'none';
            modeproceedseomonthDiv.style.display = 'none';
            submitproceedseomonthDiv.style.display = 'block';
        }
    }

    const goBackProceedSeoMonth6Handler = async (e) => {
        e.preventDefault();

        const seomonthsteponeDiv = document.getElementById('seomonthstepone');
        const seomonthsteptwoDiv = document.getElementById('seomonthsteptwo');
        const seomonthstepthreeDiv = document.getElementById('seomonthstepthree');
        const seomonthstepfourDiv = document.getElementById('seomonthstepfour');
        const modeproceedseomonthDiv = document.getElementById('modeproceedseomonth');
        const submitproceedseomonthDiv = document.getElementById('submitproceedseomonth');
        //
        seomonthsteponeDiv.style.display = 'none';
        seomonthsteptwoDiv.style.display = 'none';
        seomonthstepthreeDiv.style.display = 'none';
        seomonthstepfourDiv.style.display = 'none';
        modeproceedseomonthDiv.style.display = 'block';
        submitproceedseomonthDiv.style.display = 'none';
    }

    //http://localhost:5000/check/seomonthlyservice

    const submitProceedSeoMonthHandler = async (e) => {
        e.preventDefault();

        const monthSeoQuestion1 = monthSeoQuestion1Ref.current.value;
        const monthSeoQuestion2 = monthSeoQuestion2Ref.current.value;
        const monthSeoQuestion3 = monthSeoQuestion3Ref.current.value;
        //websteptwo
        const monthSeoQuestion4 = monthSeoQuestion4Ref.current.value;
        const monthSeoQuestion5 = monthSeoQuestion5Ref.current.value;
        const monthSeoQuestion6 = monthSeoQuestion6Ref.current.value;
        //webstepthree
        const monthSeoQuestion7 = monthSeoQuestion7Ref.current.value;
        const monthSeoQuestion8 = monthSeoQuestion8Ref.current.value;
        const monthSeoQuestion9 = monthSeoQuestion9Ref.current.value;
        //webstepfour
        const monthSeoQuestion10 = monthSeoQuestion10Ref.current.value;
        const monthSeoQuestion11 = monthSeoQuestion11Ref.current.value;
        const monthSeoQuestion12 = monthSeoQuestion12Ref.current.value;
        //modeproceedweb
        const monthSeoQuestion13 = monthSeoQuestion13Ref.current.value;
        const monthSeoQuestion14 = monthSeoQuestion14Ref.current.value;
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const theDate = getCurrentDate();
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = "seomonthly";
        const servicePlan = document.getElementById('typeplan').innerText;
        var createTicketCode = "";
        //
        var resultat1 = "";
        
        //generate random ticket code
        var randomNumber = Math.floor((Math.random() * 100000) + 1);
        createTicketCode = randomNumber+''+codeutilisateurDiv;
        //
        await Axios.post("https://micarionsapi.carions.net/check/seomonthlyservice", {
            codeutilisateurDiv:codeutilisateurDiv,
            typeService:typeService,
            servicePlan:servicePlan,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                //insert
                Axios.post("https://micarionsapi.carions.net/quest/seomonthlyservice", {
                    choixlangueDiv:choixlangueDiv,
                    usernamedetailDiv:usernamedetailDiv,
                    codeutilisateurDiv:codeutilisateurDiv,
                    //
                    typeService:typeService,
                    servicePlan:servicePlan,
                    //
                    createTicketCode:createTicketCode,
                    //
                    monthSeoQuestion1:monthSeoQuestion1,
                    monthSeoQuestion2:monthSeoQuestion2,
                    monthSeoQuestion3:monthSeoQuestion3,
                    //
                    monthSeoQuestion4:monthSeoQuestion4,
                    monthSeoQuestion5:monthSeoQuestion5,
                    monthSeoQuestion6:monthSeoQuestion6,
                    //
                    monthSeoQuestion7:monthSeoQuestion7,
                    monthSeoQuestion8:monthSeoQuestion8,
                    monthSeoQuestion9:monthSeoQuestion9,
                    //
                    monthSeoQuestion10:monthSeoQuestion10,
                    monthSeoQuestion11:monthSeoQuestion11,
                    monthSeoQuestion12:monthSeoQuestion12,
                    //
                    monthSeoQuestion13:monthSeoQuestion13,
                    monthSeoQuestion14:monthSeoQuestion14,
                    //
                    theDate:theDate,
                }).then(() => {
                    var answer1 = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                    const hTitleTexte = t("proceedwebapptitle");
                    const pTexteNotAvailable = answer1;
                    PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
                });
            }else{
                //set up modal to update
                var answer = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                const hTitleTexte = t("proceedwebapptitle");
                const pTexteNotAvailable = answer;
                PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
            }
        });
    }

    const goBackProceedSeoMonthHandler = async (e) => {
        e.preventDefault();

        navigateProceedSeoMonth('/monthlytab');
    }

    return ( 
        <div id="proceedmonthly">
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t('monthly_report')}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <LongFormScrollerWidgetSm>
                <div id="modaloutproceedseomonthly"></div>
                <div id="backdropoutproceedseomonthly"></div>
                <p className="pservicestyles"><br/>{t("proceedseodescription")}</p>
                <div id="seomonthstepone">
                    <TextProceedService onClick={popseomonth1Handler}>{t("seoquestion_1")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={monthSeoQuestion1Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseomonth2Handler}>{t("seoquestion_2")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={monthSeoQuestion2Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseomonth3Handler}>{t("seoquestion_3")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={monthSeoQuestion3Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <ButtonStart onClick={forwardProceedSeoMonth1Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="seomonthsteptwo">
                    <TextProceedService onClick={popseomonth4Handler}>{t("seoquestion_4")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={monthSeoQuestion4Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseomonth5Handler}>{t("seoquestion_5")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={monthSeoQuestion5Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseomonth6Handler}>{t("seoquestion_6")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={monthSeoQuestion6Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedSeoMonth2Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedSeoMonth2Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="seomonthstepthree">
                    <TextProceedService onClick={popseomonth7Handler}>{t("seoquestion_7")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={monthSeoQuestion7Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseomonth8Handler}>{t("seoquestion_8")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={monthSeoQuestion8Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseomonth9Handler}>{t("seoquestion_9")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={monthSeoQuestion9Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedSeoMonth3Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedSeoMonth3Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="seomonthstepfour">
                    <TextProceedService onClick={popseomonth10Handler}>{t("seoquestion_10")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={monthSeoQuestion10Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseomonth11Handler}>{t("seoquestion_11")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={monthSeoQuestion11Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseomonth12Handler}>{t("seoquestion_12")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={monthSeoQuestion12Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedSeoMonth4Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedSeoMonth4Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="modeproceedseomonth">
                    <TextProceedService>{t("webquestion_13")}</TextProceedService>
                    <TextLoginService><MdOutlineAttachEmail style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput id="emailseomonth" name="emailseomonth" value={email} onChange={handleSeoMonthChange} ref={monthSeoQuestion13Ref} placeholder={t("placemail")}/>
                        {error && <h5 style={{color: 'white'}}>{error}</h5>}
                    </div>
                    <br/>
                    <TextLoginService><AiOutlineWhatsApp style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                            <GlobalLargeInput type='number' ref={monthSeoQuestion14Ref}  placeholder={t("placewhatsapp")}/>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedSeoMonth5Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedSeoMonth5Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="submitproceedseomonth">
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedSeoMonth6Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={submitProceedSeoMonthHandler}>
                                    <MdOutlineCreate style={{fontSize:'30px'}} />
                                   {t("creer")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackProceedSeoMonthHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertproceedseomonth"></div>
                <div id="backdropoutalertproceedseomonth"></div>
            </LongFormScrollerWidgetSm>
        </div>
    );
}

export default ProceedMonthly;