import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../services.css';
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,
    DivScrollerWidgetSm, 
    BackStart,  
    HandlerContainerDiv,
    BigButton,
} from '../../../global/Styles';

function MainWebServices() {
    const {t} = useTranslation();

    const navigateMainWebServices = useNavigate();

    const websiteHandler = async (e) => {
        e.preventDefault();
        //type service
        document.getElementById('typeservice').innerText = 'web';
        //show the tab
        navigateMainWebServices('/websitetab');
    }

    const webappsHandler = async (e) => {
        e.preventDefault();

        //type service
        document.getElementById('typeservice').innerText = 'app';
        //show the tab
        navigateMainWebServices('/webapptab');
    }

    const ecommerceHandler = async (e) => {
        e.preventDefault();

        //type service
        document.getElementById('typeservice').innerText = 'ecom';
        //show the tab
        navigateMainWebServices('/ecommercetab');
    }

    const goBackWebServiceHandler = async (e) => {
        e.preventDefault();

        navigateMainWebServices('/');
    }

    return ( 
        <DivContainerSlide id="mainwebservices">
            <br/>
            <div style={{marginTop:'-2rem'}}>
                <SpanGlobalSpan style={{textAlign:'center'}}>
                    <GlobalStrongTitle>{t("webdevelopment")}</GlobalStrongTitle>
                </SpanGlobalSpan>
            </div>
            <DivScrollerWidgetSm>
                <p className="pservicestyles"><br/>{t("webdescription")}</p>
                <div id="webtabaction" className="connectaccountstyle">
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={websiteHandler}>{t("website")}</BigButton>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={webappsHandler}>{t("webapp")}</BigButton>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={ecommerceHandler}>{t("ecommerce")}</BigButton>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackWebServiceHandler}>
                                    <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                    {t("retour")}
                                </BackStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertservicescontenu"></div>
                <div id="backdropoutalertservicescontenu"></div>
            </DivScrollerWidgetSm>
        </DivContainerSlide>
    );
}

export default MainWebServices;