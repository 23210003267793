import React from "react-dom";
import { useNavigate } from "react-router-dom";
import './myeditor.css';
//
import { MdOutlineCreate, MdSettingsBackupRestore,} from 'react-icons/md';

import { useRef, useState } from "react";
import Axios from 'axios';
import '../../translations/Translation';

import { AlertModal, getCurrentDate } from "../../helperfunctions/Helper";

import { 
    DivContainerSlide, 
    FormScrollerWidgetSm,
    TextLogin, 
    LargeSelect,
    GlobalLargeInput,
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
} from "../../global/Styles";
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
//
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import katex from 'katex';
import "katex/dist/katex.min.css";
window.katex = katex;

const modules = {
    toolbar: [[{header: [1, 2, 3, 4, 5, 6,  false] }],
    [{ color: [] }],
    [{ background: [] }],
    [{ script:  "sub" }, { script:  "super" }],
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
    [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
    ],
    ["link", "image", "video", "formula"],
    ["clean"],
],
};

function MyEditor() {
    const {t} = useTranslation();

    const navigateMyEditor = useNavigate();

    //alert
    const elementOutId = "modaloutalerteditorcreate";
    const elementDropId = "backdropoutalerteditorcreate";
    const buttonHandler = "ouiHandlerAlertEditorCreateBtn";

    const [value, setValue] = useState("");

    const editorTextRef = useRef(null);
    const createSelectTypeContenuRef = useRef(null);
    const createTitreContenuRef = useRef(null);

    //http://localhost:5000/user/contenu/create

    const submitMyEditorHandler = async (e) => {
        e.preventDefault();
    
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const fonctionuserDiv = document.getElementById('fonctionuser').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        //
        const editorText = editorTextRef.current.value;
        const createSelectTypeContenu = createSelectTypeContenuRef.current.value;
        const createTitreContenu = createTitreContenuRef.current.value;
        const createStatusContenu = 'online';
        const createDateEditor = getCurrentDate();
        //
        if(!editorText){
            const pTexteSaisir = t("textcontenu");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createSelectTypeContenu){
            const pTexteSaisir = t("typecontenu");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createTitreContenu){
            const pTexteSaisir = t("placecontenu");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else{
            await Axios.post("https://micarionsapi.carions.net/user/contenu/create", {
                choixlangueDiv:choixlangueDiv,
                fonctionuserDiv:fonctionuserDiv,
                codeutilisateurDiv:codeutilisateurDiv,
                createSelectTypeContenu:createSelectTypeContenu,
                createTitreContenu:createTitreContenu,
                editorText:editorText,
                createStatusContenu:createStatusContenu,
                createDateEditor:createDateEditor,
            }).then(() => {
                navigateMyEditor(-2);
            });
        }
    };
    
    const goBackMyEditorHandler = async (e) => {
        e.preventDefault();

        navigateMyEditor(-2);
    }

    return ( 
        <DivContainerSlide id="myeditor">
            <FormScrollerWidgetSm>
                <div className="row">
                    <div className="editor">
                        {t("editeur")}
                        <ReactQuill theme="snow" 
                            value={value} 
                            onChange={setValue}
                            className="editor-input"
                            modules={modules}
                        />
                    </div>
                    <textarea style={{display:'none'}} ref={editorTextRef} defaultValue={value}></textarea>
                    <div style={{display:'none'}} className="preview" 
                        dangerouslySetInnerHTML={{ __html: value }}
                    />
                </div>
                <br/><br/>
                <div>
                    <div id="createtitreeditor">
                        <TextLogin>{t("typecontenu")}<strong style={{color:'red'}}>*</strong></TextLogin>
                        <div style={{display:'block',textAlign:'center'}}>
                            <LargeSelect ref={createSelectTypeContenuRef}>
                                <option value=''>{t("typecontenu")}</option>
                                <option value='launch'>{t("landing")}</option>
                                <option value='accueil'>{t("accueil")}</option>
                                <option value='apropos'>{t("apropos")}</option>
                                <option value='portfolio'>{t("portfolio")}</option>
                                <option value='services'>{t("services")}</option>
                                <optgroup style={{color:'white', backgroundColor:'green'}} label={t("aspirant_blog")}>
                                    <option value='introductionblogs'>{t("blogs")} {t("introduction_blog")}</option>
                                    <option value='fundamentalblogs'>{t("blogs")} {t("fundamental_blog")}</option>
                                    <option value='aspirantadvancedblogs'>{t("blogs")} {t("aspirant_blog")} {t("advanced_blog")}</option>
                                    <option value='toolsblogs'>{t("blogs")} {t("tools_blog")}</option>
                                    <option value='technologiesblogs'>{t("blogs")} {t("technologies_blog")}</option>
                                </optgroup>
                                <optgroup style={{color:'white', backgroundColor:'blue'}} label={t("web_blog")}>
                                    <option value='htmlblogs'>{t("blogs")} {t("html_blog")}</option>
                                    <option value='cssblogs'>{t("blogs")} {t("css_blog")}</option>
                                    <option value='javascriptblogs'>{t("blogs")} {t("javascript_blog")}</option>
                                </optgroup>
                                <optgroup style={{color:'white', backgroundColor:'gray'}} label={t("level_blog")}>
                                    <option value='beginnerblogs'>{t("blogs")} {t("beginner_blog")}</option>
                                    <option value='intermediateblogs'>{t("blogs")} {t("intermediate_blog")}</option>
                                    <option value='leveladvancedblogs'>{t("blogs")} {t("level_blog")} {t("advanced_blog")}</option>
                                    <option value='expertblogs'>{t("blogs")} {t("expert_blog")}</option>
                                    <option value='masterblogs'>{t("blogs")} {t("master_blog")}</option>
                                </optgroup>
                                <option value='apprentissage'>{t("apprentissage")}</option>
                                <option value='apps'>{t("apps")}</option>
                                <option value='contact'>{t("contact")}</option>
                            </LargeSelect>
                        </div>
                        <br/>
                        <TextLogin>{t("titrecontenu")}<strong style={{color:'red'}}>*</strong></TextLogin>
                        <div style={{display:'block',textAlign:'center'}}>
                            <GlobalLargeInput type='text' ref={createTitreContenuRef} placeholder={t("placecontenu")}/>
                        </div>
                        <br/>
                    </div>
                
                    <HandlerContainerDiv>
                        <div>
                            <ButtonStart onClick={submitMyEditorHandler}>
                                <MdOutlineCreate style={{fontSize:'30px'}} />
                                {t("creer")}
                            </ButtonStart>
                        </div>
                        <div>
                            <BackStart onClick={goBackMyEditorHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                </div>
                <br/><br/>
                <div id="modaloutalerteditorcreate"></div>
                <div id="backdropoutalerteditorcreate"></div>
            </FormScrollerWidgetSm>
        </DivContainerSlide>
    );
}

export default MyEditor;