import React from 'react';
import '../accueil/accueil.css';
import imageaccount from '../carions-icon-192.png';

import { useTranslation } from 'react-i18next';
import '../translations/i18n';
import '../translations/Translation';

import { 
    DivContainerSlide, 
} from "../global/Styles";

function NoMatch() {
    const {t} = useTranslation();

    return ( 
        < DivContainerSlide>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <img src={imageaccount} alt="" />
            </div>
            <div className="container">
                <div className="error-page">
                    <h3 className="error-code">404</h3>
                    <p className="error-text">{t("nofound")}</p>
                </div>
            </div>
        </DivContainerSlide>
    );
}

export default NoMatch;