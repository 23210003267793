export const TRANSLATIONS_FR = {
    landing: "Lancement",
    accueil: "Accueil",
    apropos: "À propos",
    portfolio: "Portfolio",
    services: "Services",
    blogs: "Blogues",
    apprentissage: "Apprendre",
    tutoriels: "Tutoriels",
    apps: "Apps",
    contact: "Contact",

    francais:"Français",
    anglais:"Anglais",
    portugais:"Portugais",
    italien:"Italien",

    //global translation
    status_contenu: "Type Statut",
    select_status: "Sélectionnez Type Statut",
    retour: "Retour",
    nom: "Nom",
    placenom: "Saisir Nom",
    prenoms: "Prénoms",
    placeprenoms: "Saisir Prénoms",
    prefixe: "Préfixe Inter",
    placeprefixe: "exemple:00225",
    mobile: "Contact Mobile",
    placemobile: "Saisir Numéro Mobile",
    username: "Username",
    placeusername: "Saisir Nom Utilisateur",
    password: "Password",
    placepassword: "Saisir Mot de Passe",
    confirmpassword: "Confirmer Password",
    placeconfirmpassword: "Confirmer Mot de Passe",
    nomatchpassword: "Les 2 passwords ne correspondent pas",
    changepassword: "Changez votre password si vous voulez créer un autre compte sous ce même nom utilisateur",
    creer: "Créer",
    modifier: "Modifier",
    confirmer: "Confirmer",
    identifier: "Identifiez-vous",
    newaccount: "Pas de Compte Utilisateur ?",
    editaccount: "Gérez votre Compte",
    acceder: "Acceder",
    erreurdetail: "Nom utilisateur, ou password incorects",
    soucritabonnement: "Période d'utilisation expiré. Souscrivez à un abonnement",
    crudcreercontenu: "Créer Contenu",
    crudcontenumodifier: "Modifier Contenu",
    crudaccueilsupprimer: "Supprimer Contenu",
    crudaccueilvisualiser: "Visualiser Contenu",

    //slogan section
    slogan: "La Tradition Digitale",
    sous_slogan:"Développement web",

    //Account section
    accounttitre: "Sélectionner Type Compte",
    utilisateur: "Compte Utilisateur",
    abonnement: "Compte Abonnement",

    //crud user account
    crudusertitre: "Gestion Compte",
    crudusergerer: "Comment gérer votre Compte ?",
    crudusercreer: "Créer Compte Gratuit",
    crudusermodifier: "Modifier Votre Compte",
    crudusersupprimer: "Supprimer le Compte",

    //crud user subscribe
    crudsubtitre: "Gestion Abonnement",
    crudsubgerer: "Comment gérer votre Abonnement ?",
    crudsubcreer: "Créer Abonnement",
    crudsubmodifier: "Modifier Abonnement",
    crudsubsupprimer: "Supprimer Abonnement",

    //crud accueil
    crudaccueiltitre: "Gestion Page Accueil",

    //my editor react-quill
    editeur: "Éditeur",
    apercu: "Aperçu",
    typecontenu: "Type Contenu",
    titrecontenu: "Titre Contenu",
    placecontenu: "Saisir Titre Contenu",
    textcontenu: "Saisir Contenu du Texte",
    originaltexte: "Texte Précédent",
    nouveautexte: "Modifier Texte Ici",

    servicetitre: "Services Carion's",
    webdevelopment: "Développement web",
    seoconsulting: "Conseil SEO",
    softdevservice: "Services Dév Logiciels",
    apprentpurchase: "Location/achat App",
    training: "Formation Dév Web/App",
    hosting: "Services Hébergement",
    //
    website: "Sites Web",
    webapp: "Applications Web",
    ecommerce: "Sites E-Commerce",
    webdescription: "Sites Web personnalisés adaptés à vos objectifs commerciaux et à votre marque, utilisant les dernières technologies de développement Web, un site Web convivial, réactif et facile à naviguer. Site e-commerce sécurisé, évolutif et optimisé pour la conversion pour vendre vos produits ou services en ligne. Solution adaptée aux besoins de votre entreprise, que vous ayez besoin d'un système de gestion client, d'un outil de gestion de projet ou de tout autre type d'application Web.",
    //
    accueil_titre: "Construisons votre projet. Débloquez vos solutions Web.",
    accueil_content: "Celui qui veut voyager loin prend soin de sa monture, non seulement parce que la monture est épuisée, mais aussi parce qu'il n'y aura nulle part ailleurs où voyager. Mais attention à ne pas commettre de mauvaise interprétation menant à une impasse !!!",
    accueil_project: "Projets",
    accueil_skills: "Solutions",
    //
    connect_titre: "Créer un compte gratuit. Être connecté.",
    connect_content: "Entrez votre nom d'utilisateur et votre mot de passe pour accéder aux newsletters et plus encore ou créez un nouveau compte gratuitement si ce n'est pas encore fait.",
    //
    crudcontenusupprimer: "Supprimer Contenu",
    //
    crudcontenuVisualiser: "Visualiser Contenu",
    //notification
    noteindisponible: "Aucune liste Notification disponible",
    //gestion tabs services
    crudservicestitre: "Gestion Services",
    //webservices
    webdescription_1: "Que vous ayez besoin d'une application mobile, d'une application Web ou d'un serveur backend, j'ai l'expertise pour les gérer tous. Grâce à mes compétences en React Native, Nodejs et MySQL, je peux créer une application mobile complète qui répond à vos besoins. Si vous préférez une application Web progressive, je peux également en développer une en utilisant React js et MySQL ou CouchDB.",
    webdescription_2: "Si vous avez besoin d'une application Web ou mobile simple mais puissante, je peux vous aider ! En utilisant React js ou React Native, je peux créer une application à la fois conviviale et fonctionnelle. Que vous cherchiez à créer un portail client, une landing page ou un site e-commerce, j'ai les compétences et l'expérience pour livrer un produit qui répond à vos besoins.",
    webdescription_3: "Mon processus de développement commence par une compréhension approfondie de vos besoins, de vos objectifs et de votre public cible. Je travaillerai avec vous pour définir la portée du projet, créer un plan de développement et établir des jalons et des délais clairs.",
    webdescription_4: "Je suis passionné par mon travail et je suis convaincu que vous serez ravis des résultats. Cependant, pour nous assurer que nous sommes sur la même longueur d'onde, je vous recommande fortement de discuter de vos besoins avec moi avant de commencer le processus de développement. Cela m'aidera à mieux comprendre votre vision et à livrer un produit final qui dépasse vos attentes.",
    //
    webIntroductory: "Introduction",
    webbasicprice: "87,22 €",
    webbasicdescription: "En ce qui concerne la création d'applications Web ou mobiles, j'ai une vaste expérience dans une variété de technologies et de cadres. Selon vos besoins, je peux créer une application simple d'une seule page en utilisant React js ou React Native.",
    webbasicdelivery: "Livraison sous 7 jours",
    webbasicrevision: "4 révisions",
    webbasicpage: "1 page",
    webbasicdesign: "Personnalisation de la conception",
    webbasiccontent: "Téléchargement de contenu",
    webbasicresponsive: "Conception réactive",
    webbasicsource: "Code source sur demande",
    //
    proceedbtn: "Procéder",
    contactme: "Contactez moi",
    //
    websitetitre: "Service Développement Web",
    webapptitre: "Service Développement Web App",
    ecomapptitre: "Service Développement E-commerce",
    //global
    type_plan: "Type Plan",
    select_plan: "Sélectionnez le type",
    basic_plan: "Basique",
    standard_plan: "Standard",
    premium_plan: "Premium",
    paragraphe_1: "Paragraphe 1",
    paragraphe_2: "Paragraphe 2",
    paragraphe_3: "Paragraphe 3",
    paragraphe_4: "Paragraphe 4",
    service_title: "Titre du service",
    service_price: "Prix du service",
    plan_description: "Description du plan",
    days_delivery: "Jours de livraison",
    revision_number: "Nombre de révision",
    page_number: "Nombre de page",
    design_customization: "Personnalisation conception",
    content_upload: "Téléchargement contenu",
    responsive_design: "Design réactif",
    source_code: "Source Code",
    titre_service: "Initiation/Intermédiaire/Avancé/",
    delivery: ".. Jours de livraison",
    price: "...€",
    revision: ".. Révisions",
    page: ".. Pages",
    customization: "Conception personnalisée",
    content: "Importation de contenu",
    responsive: "Design réactif",
    source: "Code source sur demande",
    //
    type_contenu: "Sélectionnez un Type de Contenu",
    titre_plan: "Sélectionnez un type de plan",
    type_paragraphe: "Rédiger au moins le premier paragraphe",
    titre_contenu: "Saisir un seul titre de service",
    titre_price: "Saisir le prix du service",
    titre_description: "Saisir la description du service",
    titre_delivery: "Saisir le nombre de jours nécessaires avant livraison",
    titre_revision: "Saisir le nombre de révisions nécessaires",
    titre_page: "Saisir le nombre de pages possible",
    titre_design: "Saisir la Personnalisation conception",
    titre_upload: "Saisir Importation de contenu",
    titre_responsive: "Saisir Design réactif",
    titre_source: "Saisir Code source sur demande",
    //
    tab_success: "Table service enregistrée. Au suivant",
    //seo services
    keyword_research: "Recherche Mots Clés",
    on_page: "Optimisation sur Page",
    technical_audit: "Audit Technique SEO",
    monthly_report: "Reporting Mensuel",
    //software development services
    softservices_description: "Je fournis des services de développement de sites Web, d'applications Web et d'applications mobiles React pour aider les entreprises et les particuliers à créer des solutions logicielles personnalisées qui répondent à leurs besoins uniques. En tant que développeur React expérimenté, je me spécialise dans la mise en œuvre de composants, fonctions, procédures et algorithmes personnalisés en fonction des demandes des clients. Mes services de développement React incluent :",
    softwareservices: "Services Développement individuel",
    software_coding: "Programmation & Codage",
    software_design: "Conception Web et Applications",
    software_development: "Développement Web et Applications",
    software_maintenance: "Maintenance Web et Applications",
    software_management: "Conseil Gestion Projets",
    software_miscellanious: "Services divers",
    //contact me
    contact_me:"Contactez-moi",
    //no page found
    nofound:"Page introuvable",
    //select language
    selectlanguage: "Pour changer de langue, cliquez sur Accueil, puis sélectionnez votre langue préférée.",
    //contact me
    contactintro: "Avez-vous des questions, des commentaires ou des suggestions ? J'aimerais avoir de vos nouvelles ! N'hésitez pas à me contacter en utilisant l'une des méthodes suivantes :",
    contact_1: "Appel direct : Vous pouvez m'appeler directement sur mon numéro de téléphone.",
    contact_2: "Envoyer un e-mail : Si vous préférez communiquer par e-mail, veuillez m'envoyer un message.",
    contact_3: "Appel WhatsApp : si vous utilisez WhatsApp, vous pouvez également m'appeler sur mon numéro WhatsApp.",
    contactconclued_1: "Je suis toujours heureux d'avoir de nouvelles personnes et je ferai de mon mieux pour vous répondre dès que possible.",
    contactconclued_2: "Au plaisir de vous entendre bientôt !",
    //proceed website
    proceedwebtitle: "Développement de Site Web",
    proceedwebdescription: "Voici quelques questions essentielles que je pourrais vous poser avant de démarrer votre projet web. Veuillez remplir gratuitement pour me permettre de mieux comprendre vos besoins, vos préférences et vos limites afin d'adapter la conception si nécessaire pour répondre à ces exigences.",
    webquestion_1: "1. Décrivez votre projet en quelques phrases",
    webquestion_2: "2. Avez-vous actuellement un site Web ? Si oui, qu'est-ce qui fonctionne/ne fonctionne pas pour vous ?",
    webquestion_3: "3. Quels sont vos objectifs pour ce projet ?",
    webquestion_4: "4. Quel est votre public cible ?",
    webquestion_5: "5. Quelles fonctionnalités spécifiques souhaitez-vous sur le site ?",
    webquestion_6: "6. Comment pouvons-nous éviter l'échec ?",
    webquestion_7: "7. Quels sont vos principaux concurrents ?",
    webquestion_8: "8. Qu'est-ce qui vous différencie de vos concurrents ?",
    webquestion_9: "9. Quelle est la portée du projet ?",
    webquestion_10: "10. Qui sera responsable de la maintenance du contenu ?",
    webquestion_11: "11. Quels sont les jalons et les délais ?",
    webquestion_12: "12. Quel est le processus de mise à jour et de modification du site Web après son lancement ?",
    webquestion_13: "Je souhaite être contacté par :",
    //suivant & precedent
    suivant: "Suivant",
    precedent: "Avant",
    //hint webquestion
    hintwebquestion_1: "Dans certains cas, votre entreprise peut apparaître d'une évidence aveuglante, mais vous ne devez jamais rien présumer. Résumez votre entreprise en quelques phrases courtes ; c'est l'essence qui doit être immédiatement évidente lorsque les visiteurs arrivent sur votre nouveau site Web ou votre nouvelle application. Creusez plus profondément aussi. Essayez de découvrir quelles sont vos valeurs clés, votre niveau d'expérience dans l'industrie et votre vision de l'avenir, ainsi que votre taille, votre emplacement et vos antécédents.",
    hintwebquestion_2: "À moins que vous ne partiez de zéro, votre site Web actuel est une mine d'informations. Quel système de gestion de contenu vous souhaitez utiliser, combien de temps vous l'utiliserez et avec quelle facilité vous préférez le mettre à jour. Qu'est-ce que vous aimez et qu'est-ce que vous avez hâte de voir à l'arrière ? En passant du temps à examiner certains sites Web actuels, vous pouvez en savoir plus sur les goûts et les aversions de leurs clients, ainsi que sur les erreurs qu'ils ont commises la dernière fois.",
    hintwebquestion_3: "Quelle est votre définition du succès. Voulez-vous augmenter le nombre de visiteurs, augmenter la taille moyenne des commandes ou augmenter le nombre d'utilisateurs sur leur forum Web ? Peut-être souhaitez-vous encourager un plus grand engagement via votre blog, augmenter la visibilité de votre marque ou encourager les gens à s'inscrire à votre newsletter par e-mail/essai gratuit/livre blanc, etc. En établissant vos points faibles et en déterminant les problèmes que vous essayez de résoudre , vous aurez une meilleure chance de produire une solution efficace.",
    hintwebquestion_4: "Essayez de résonner fortement auprès de vos utilisateurs et de votre public cible, sinon ils ne l'utiliseront tout simplement pas - les publics en ligne sont incroyablement instables aujourd'hui, en raison du marché extrêmement concurrentiel. Plongez dans les données démographiques et psychographiques de vos utilisateurs, telles que leurs habitudes de consommation de médias, leurs mots d'argot et leurs choix de style de vie. Quelles sont leurs habitudes d'achat ? Avez-vous besoin de combler un fossé entre le public actuel et le marché cible ? Plus vous en savez sur les utilisateurs finaux, mieux vous pouvez déterminer l'apparence, la mise en page et la navigation de votre site Web.",
    hintwebquestion_5: "Établissez dès le départ si vous avez des fonctionnalités particulières que vous souhaitez inclure sur votre nouveau site Web. Certaines choses peuvent être évidentes. Si vous gérez un restaurant par exemple, vous aurez besoin de vos heures d'ouverture et de votre menu inclus, alors que si vous gérez un hôtel, vous voudrez peut-être un formulaire de réservation en ligne. D'autres possibilités incluent un blog, un chat sur le site Web, l'intégration des médias sociaux, des galeries de photos, un site mobile ou réactif séparé, l'intégration vidéo ou un formulaire de contact. Précisez toujours dès le départ pour éviter les ajouts tardifs qui pourraient coûter du temps et de l'argent à se marier au site. Rédigez ou fournissez tout matériel de marque ou guide de style qui doit être intégré à la conception du site.",
    hintwebquestion_6: "Connaître vos aversions est tout aussi important. Supprimer quelque chose d'intégré dans un site Web une fois que vous êtes à mi-chemin d'un projet est tout aussi ennuyeux, coûteux et chronophage que d'ajouter quelque chose. Montrez vos sites Web les moins préférés et indiquez les éléments de conception, les fonctionnalités et les styles qui les font frissonner si nécessaire. De cette façon, peut éliminer les échecs potentiels avant d'entrer dans le vif du sujet du projet.",
    hintwebquestion_7: "Découvrir vos concurrents vous fournit une autre source d'information utile. Une fois que vous avez indiqué vos principaux rivaux, vous pouvez recueillir des informations qui vous aideront à orienter votre conception Web. Regardez les éléments qui fonctionnent bien sur leurs sites et voyez s'il y a des choses qui tombent à plat. Votre intention ici n'est pas de copier ce qui a été fait auparavant, mais d'apprendre de leurs succès et de leurs échecs. Soulignez les choses qu'ils font et n'aiment pas sur les sites Web de vos concurrents est un autre indicateur utile de ce que vous aimez et n'aimez pas.",
    hintwebquestion_8: "Connaître votre argument de vente unique vous aidera à concevoir un site qui se démarque de celui de vos concurrents. Dans le monde en ligne occupé, bruyant et concurrentiel, les entreprises doivent être vraiment remarquables pour réussir. Alors, comment pouvez-vous attirer l'attention des gens lorsqu'ils atterrissent sur votre site ? Comment pouvez-vous faire en sorte que cette première impression délicate compte ? Cela n'a pas besoin d'être quelque chose de majeur ; cela peut être aussi simple qu'une consultation gratuite ou un excellent service client. Parmi les autres éléments qui distinguent les entreprises, citons la livraison rapide ou gratuite des produits, le stockage des produits les moins chers ou de la meilleure qualité dans votre région, la mise en place d'une procédure de garantie ou de retour incroyable, ou l'offre de forfaits uniques. En apprenant comment vous vous différenciez de vos concurrents, vous pouvez obtenir un site qui fait de même.",
    hintwebquestion_9: "Enfin, précisez la portée du projet. Vous avez besoin d'une compréhension claire de votre échéance finale ainsi que des étapes clés que vous souhaitez atteindre en cours de route - c'est crucial pour maintenir votre projet sur la bonne voie et vous assurer que vous êtes à la hauteur des attentes. Vous devez également connaître le budget et, plus important encore, déterminer s'il existe une flexibilité dans le calendrier et le budget.",
    hintwebquestion_10: "Si vous êtes responsable de la maintenance du contenu Web, il est important de vous assurer que vous disposez des compétences et des connaissances nécessaires pour effectuer des mises à jour et des modifications sur le site Web. Sinon, il est important de clarifier la portée de ces activités et de vous assurer que vous comprenez ce qui est inclus dans le contrat de maintenance. Cela peut inclure des activités telles que la mise à jour du contenu, la correction de bogues et l'exécution de sauvegardes régulières.",
    hintwebquestion_11: "En établissant vos jalons et vos délais, vous pouvez vous assurer que le projet reste sur la bonne voie et que les attentes sont satisfaites. Il est important de communiquer régulièrement avec moi tout au long du projet pour s'assurer que tout problème ou retard est traité rapidement. Cela inclut : la découverte et la planification, l'approbation de la conception, la livraison de contenu, l'approbation du développement, l'approbation des tests et de l'assurance qualité, le lancement. Sélectionnez celle que vous préférez ou dites-moi que vous les préférez toutes.",
    hintwebquestion_12: "Le processus de mise à jour et de modification du site Web après le lancement peut varier en fonction du site Web spécifique et de l'accord entre nous. Cependant, voici quelques étapes générales qui peuvent être impliquées dans le processus : demande, évaluation, approbation, développement, test, déploiement, lancement. Il est important d'avoir un processus clair en place pour les mises à jour et les modifications du site Web après le lancement afin de s'assurer qu'elles sont mises en œuvre de manière efficace et efficiente. Veuillez donner une réponse claire si vous êtes d'accord et prêt à suivre l'ensemble du processus.",
    //
    placemail: "Adresse e-mail",
    placewhatsapp: "Contact WhatsApp",
    emailphone: "Entrez au moins votre e-mail ou WhatsApp ou les deux.",
    responsequestion_1: "Merci d'avoir soumis votre questionnaire. Nous apprécions votre intérêt à travailler avec nous. Notre équipe examinera vos réponses et vous contactera sous peu pour planifier une discussion en direct afin de mieux comprendre les besoins et les exigences de votre projet. Au cours de cette discussion, nous travaillerons avec vous pour mettre à jour votre questionnaire au besoin afin de nous assurer que nous comprenons parfaitement vos buts et objectifs.",
    responsequestion_2: "Merci encore pour votre attention, et nous nous réjouissons de discuter davantage de votre projet.",
    //proceed webapp
    proceedwebapptitle: "Développement Web App",
    invalidemail: "Entrez votre adresse e-mail valide.",
    enteremail: "Saisissez votre adresse email valide.",
    enterphone: "Entrez votre préfixe international valide suivi du numéro Whatsapp.",
    //proceed ecom
    proceedecomtitle: "Développement E-commerce",
    //Seo processing
    proceedseosearchtitle: "Recherche Mots Clés",
    proceedseooptimtitle: "Optimisation sur Page",
    proceedseodescription: "Voici quelques questions essentielles que je pourrais vous poser avant de démarrer votre projet web. Veuillez remplir gratuitement pour me permettre de mieux comprendre vos besoins, vos préférences et vos limites afin d'adapter la conception si nécessaire pour répondre à ces exigences.",
    seoquestion_1: "1. Quels sont vos buts et objectifs commerciaux ?",
    seoquestion_2: "2. Qui est votre public cible ?",
    seoquestion_3: "3. Quels sont vos arguments de vente uniques (USP) ?",
    seoquestion_4: "4. Quels sont vos principaux concurrents ?",
    seoquestion_5: "5. Quels sont vos efforts et résultats SEO actuels ?",
    seoquestion_6: "6. Quel est le trafic et les performances actuels de votre site Web ?",
    seoquestion_7: "7. Quels sont les principaux mots clés et expressions pour lesquels vous souhaitez vous classer ?",
    seoquestion_8: "8. Quelle est votre stratégie de marketing de contenu ?",
    seoquestion_9: "9. Quelle est votre stratégie sur les réseaux sociaux ?",
    seoquestion_10: "10. Quel est votre budget pour le référencement ?",
    seoquestion_11: "11. Quel est votre calendrier pour atteindre les objectifs de référencement ?",
    seoquestion_12: "12. Comment mesurez-vous le succès ?",
    seoquestion_13: "Je souhaite être contacté par :",
    //hint seoquestion
    hintseoquestion_1: "1. Comprendre vos buts et objectifs commerciaux globaux nous aidera à aligner la stratégie de référencement sur ses besoins commerciaux et à vous assurer que les efforts sont concentrés sur l'obtention des résultats souhaités.",
    hintseoquestion_2: "2. Connaître votre public cible est crucial pour déterminer les mots clés les plus pertinents et optimiser le contenu du site Web pour attirer le public cible.",
    hintseoquestion_3: "3. L'identification des arguments de vente uniques nous aidera à mettre en évidence ces aspects de votre activité dans la stratégie de référencement et à le différencier de vos concurrents.",
    hintseoquestion_4: "4. Comprendre vos principaux concurrents et leur présence en ligne nous aidera à développer une stratégie de référencement plus efficace qui peut vous aider à surclasser ses concurrents.",
    hintseoquestion_5: "5. Connaître vos efforts et résultats actuels en matière de référencement nous aidera à identifier les domaines qui doivent être améliorés et à tirer parti des efforts existants.",
    hintseoquestion_6: "6. Comprendre le trafic et les performances actuels de votre site Web nous aidera à fixer des objectifs réalistes et à mesurer le succès de la stratégie de référencement.",
    hintseoquestion_7: "7. L'identification des mots clés et expressions cibles nous aidera à optimiser le contenu du site Web et à améliorer le classement de ces mots clés.",
    hintseoquestion_8: "8. Connaître votre stratégie de marketing du contenu nous aidera à aligner les efforts de référencement sur la stratégie de contenu et à identifier les opportunités d'optimisation du contenu pour le référencement.",
    hintseoquestion_9: "9. Comprendre votre stratégie de médias sociaux nous aidera à aligner les efforts de référencement sur la stratégie de médias sociaux et à identifier les opportunités d'optimisation des médias sociaux.",
    hintseoquestion_10: "10. Connaître votre budget pour le référencement nous aidera à développer une stratégie plus efficace qui peut fournir les résultats souhaités dans le budget disponible.",
    hintseoquestion_11: "11. Comprendre votre calendrier pour atteindre les objectifs de référencement nous aidera à définir des attentes réalistes et à développer une stratégie capable de fournir les résultats souhaités dans les délais spécifiés.",
    hintseoquestion_12: "12. Connaître vos critères pour mesurer le succès nous aidera à fixer des objectifs réalistes et à développer une stratégie qui peut produire les résultats souhaités en fonction de la définition de votre succès.",
    //individual development service
    proceedcodingdescription: "Voici quelques questions essentielles que je pourrais vous poser avant de commencer la prestation demandée. Veuillez remplir gratuitement pour me permettre de mieux comprendre vos besoins, vos préférences et vos limites afin d'adapter le design si nécessaire pour répondre à ces exigences.",
    proceedcodingtitle: "Programmation & Codage",
    codingquestion_1: "1. Quel est le codage ou la fonctionnalité spécifique dont vous avez besoin pour votre projet de développement logiciel ?",
    codingquestion_2: "2. Quels langages de programmation utilisez-vous actuellement dans votre projet ?",
    codingquestion_3: "3. Avez-vous essayé de résoudre le problème de codage par vous-même ? Si oui, qu'avez-vous essayé jusqu'à présent ?",
    codingquestion_4: "4. Quel est le calendrier de réalisation de ce projet ?",
    codingquestion_5: "5. Quel est votre budget pour ce projet ?",
    codingquestion_6: "6. Qui sont les utilisateurs finaux de votre projet de développement ?",
    codingquestion_7: "7. Quelles caractéristiques et fonctionnalités sont les plus importantes pour vos utilisateurs finaux ?",
    codingquestion_8: "8. Comment le projet de développement s'intégrera-t-il à vos systèmes technologiques existants ?",
    codingquestion_9: "9. Quels sont les risques et les défis potentiels que vous prévoyez avec ce projet ?",
    codingquestion_10: "10. Comment mesurerez-vous le succès de ce projet de développement ?",
    codingquestion_11: "11. À quelle fréquence avez-vous besoin de mises à jour ou de rapports d'avancement sur le travail de codage ?",
    codingquestion_12: "12. De quel niveau de support technique avez-vous besoin pour ce projet de développement ?",
    codingquestion_13: "Je souhaite être contacté par :",
    //hint seoquestion
    hintcodingquestion_1: "1. Fournissez des détails spécifiques sur le codage ou la fonctionnalité requise, y compris les caractéristiques ou fonctions spécifiques que vous souhaitez ajouter ou modifier.",
    hintcodingquestion_2: "2. Fournissez des informations sur les langages de programmation que vous utilisez actuellement dans votre projet. Cela aidera à comprendre la portée du projet et à déterminer si vous avez l'expertise nécessaire pour le mener à bien.",
    hintcodingquestion_3: "3. Avez-vous essayé de résoudre le problème de codage par vous-même. Si oui, veuillez fournir des détails sur ce que vous avez essayé jusqu'à présent. Cela vous aidera à comprendre votre niveau d'expertise technique et à éviter de dupliquer tout travail déjà effectué.",
    hintcodingquestion_4: "4. Votre calendrier spécifique pour la réalisation du projet. Cela aidera à planifier le calendrier des travaux et à déterminer la disponibilité pour terminer le projet dans les délais requis.",
    hintcodingquestion_5: "5. Votre budget pour le projet. Cela nous aidera à comprendre vos contraintes financières et à déterminer si les services sont abordables pour vous.",
    hintcodingquestion_6: "6. Fournissez des informations sur les utilisateurs finaux de votre projet de développement de logiciel ou d'application. Cela aidera à comprendre le public cible et à s'assurer que le travail de codage répond à vos besoins.",
    hintcodingquestion_7: "7. Fournissez des détails sur les caractéristiques et fonctionnalités les plus importantes pour vos utilisateurs finaux. Cela nous aidera à hiérarchiser votre travail de codage et à garantir que les fonctions les plus importantes sont terminées en premier.",
    hintcodingquestion_8: "8. Fournissez des informations sur la manière dont le projet de développement de logiciel ou d'application s'intégrera à vos systèmes technologiques existants. Cela nous aidera à comprendre les exigences techniques du projet et à garantir que le travail de codage est compatible avec vos systèmes technologiques existants.",
    hintcodingquestion_9: "9. Identifiez tous les risques ou défis potentiels que vous prévoyez avec le projet. Cela nous aidera à identifier les obstacles potentiels et à planifier en conséquence pour atténuer les risques ou les défis.",
    hintcodingquestion_10: "10. Fournissez des détails sur la façon dont vous mesurerez le succès du projet de développement de logiciel ou d'application. Cela nous aidera à comprendre vos attentes et à nous assurer que le travail de codage répond à vos exigences.",
    hintcodingquestion_11: "11. À quelle fréquence vous aurez besoin de mises à jour ou de rapports d'avancement sur le travail de codage. Cela nous aidera à planifier votre horaire de travail et à nous assurer que vous êtes tenus informés tout au long du projet.",
    hintcodingquestion_12: "12. De quel niveau de support technique vous aurez besoin pour le projet de développement de logiciel ou d'application. Cela nous aidera à déterminer l'étendue de l'assistance technique requise et à planifier votre horaire de travail en conséquence.",

    //individual development service
    proceeddesigndescription: "Voici quelques questions essentielles que je pourrais vous poser avant de commencer la prestation demandée. Veuillez remplir gratuitement pour me permettre de mieux comprendre vos besoins, vos préférences et vos limites afin d'adapter le design si nécessaire pour répondre à ces exigences.",
    proceeddesigntitle: "Conception Web et Applications",
    designquestion_1: "1. À quoi sert votre site Web ou votre application ?",
    designquestion_2: "2. Quel est votre public cible ?",
    designquestion_3: "3. Quelles caractéristiques et fonctionnalités souhaitez-vous inclure ?",
    designquestion_4: "4. Quel est votre budget pour le projet ?",
    designquestion_5: "5. Avez-vous des éléments de marque ou de design existants ?",
    designquestion_6: "6. Quel est votre calendrier pour le projet ?",
    designquestion_7: "7. Quel est le principal appel à l'action sur votre site Web ou votre application ?",
    designquestion_8: "8. Avez-vous des préférences de conception spécifiques ?",
    designquestion_9: "9. Quelle est votre stratégie de contenu ?",
    designquestion_10: "10. Quelle est votre stratégie SEO ?",
    designquestion_11: "11. Quel est votre plan d'hébergement et de maintenance ?",
    designquestion_12: "12. Quel est le calendrier prévu pour l'achèvement du projet, et y a-t-il des délais spécifiques à respecter ?",
    designquestion_13: "Je souhaite être contacté par :",
    //hint seoquestion
    hintdesignquestion_1: "1. Expliquez ce que vous souhaitez accomplir avec votre site Web ou votre application, comme vendre des produits, fournir des informations ou proposer un service. Cette question nous aidera à comprendre l'objectif principal du site Web ou de l'application, et ce que vous souhaitez en faire.",
    hintdesignquestion_2: "2. Fournissez des informations sur votre public cible, telles que votre tranche d'âge, vos centres d'intérêt et vos données démographiques. Comprendre le public cible nous aidera à créer un design qui vous plaît et répond à vos besoins.",
    hintdesignquestion_3: "3. Fournissez une liste des caractéristiques et fonctionnalités que vous souhaitez inclure, telles qu'un panier, une barre de recherche ou un formulaire de contact. Cette question nous aidera à comprendre ce que vous voulez que le site Web ou l'application fasse, et quelles fonctionnalités il devrait avoir.",
    hintdesignquestion_4: "4. Fournissez une fourchette de budget ou un montant spécifique que vous êtes prêt à consacrer au projet. Comprendre le budget nous aidera à déterminer ce qui est faisable en termes de conception et de développement.",
    hintdesignquestion_5: "5. Fournissez tous les éléments de marque ou de conception existants, tels que les logos, les combinaisons de couleurs ou les polices. Si vous avez des éléments de marque ou de conception existants, nous les intégrerons dans le site Web ou l'application et créerons une apparence cohérente.",
    hintdesignquestion_6: "6. Fournissez un calendrier pour le projet, comme une date de lancement ou une échéance précise. Comprendre le calendrier nous aidera à déterminer si le projet est réalisable et à planifier en conséquence.",
    hintdesignquestion_7: "7. Expliquez l'action que vous souhaitez que les utilisateurs effectuent, comme effectuer un achat, remplir un formulaire ou s'inscrire à une newsletter. Comprendre le principal appel à l'action nous aidera à concevoir le site Web ou l'application de manière à encourager les utilisateurs à prendre l'action souhaitée.",
    hintdesignquestion_8: "8. Fournissez toutes les préférences de conception spécifiques, telles qu'une conception minimaliste ou audacieuse, ou des couleurs spécifiques que vous souhaitez incorporer. Comprendre vos préférences de conception nous aidera à créer un design qui répond à vos préférences et attentes esthétiques.",
    hintdesignquestion_9: "9. Expliquez votre stratégie de contenu, par exemple le type de contenu que vous souhaitez créer et la fréquence à laquelle vous le mettrez à jour. Comprendre votre stratégie de contenu nous aidera à concevoir le site Web ou l'application d'une manière qui réponde à vos objectifs de contenu.",
    hintdesignquestion_10: "10. Expliquez votre stratégie de référencement, par exemple les mots-clés que vous souhaitez cibler et comment vous envisagez d'optimiser votre contenu. Comprendre votre stratégie de référencement nous aidera à concevoir le site Web ou l'application de manière à soutenir vos objectifs d'optimisation des moteurs de recherche.",
    hintdesignquestion_11: "11. Expliquez votre plan d'hébergement et de maintenance, par exemple quel fournisseur d'hébergement vous prévoyez d'utiliser et à quelle fréquence. Comprendre votre plan d'hébergement et de maintenance nous aidera à planifier l'assistance et la maintenance continues du site Web ou de l'application.",
    hintdesignquestion_12: "12. Répondre à cette question nous donnera une meilleure idée de vos attentes concernant le calendrier du projet. Il est important de savoir s'il y a des délais précis à respecter afin que nous puissions planifier vos travaux en conséquence. Cela nous aidera également à établir un calendrier réaliste pour la réalisation du projet et à nous assurer que nous avons suffisamment de temps pour livrer un produit de haute qualité.",

    //individual maintenance service
    proceedmaintdescription: "Voici quelques questions essentielles que je pourrais vous poser avant de commencer la prestation demandée. Veuillez remplir gratuitement pour me permettre de mieux comprendre vos besoins, vos préférences et vos limites afin d'adapter le design si nécessaire pour répondre à ces exigences.",
    maintquestion_1: "1. Quelles parties spécifiques de votre site Web ou de votre application avez-vous besoin que je maintienne ?",
    maintquestion_2: "2. De quel type de mises à jour ou de modifications avez-vous besoin régulièrement ?",
    maintquestion_3: "3. À quelle fréquence avez-vous besoin de mises à jour ou de modifications ?",
    maintquestion_4: "4. Quels sont les problèmes ou problèmes actuels auxquels vous êtes confronté avec votre site Web ou votre application ?",
    maintquestion_5: "5. Quels sont vos objectifs à long terme pour votre site Web ou votre application ?",
    maintquestion_6: "6. Quel est votre budget pour la maintenance du site Web et des applications ?",
    maintquestion_7: "7. Quel est le calendrier prévu pour les travaux d'entretien ?",
    maintquestion_8: "8. Y a-t-il des problèmes de sécurité qui doivent être résolus ?",
    maintquestion_9: "9. Y a-t-il eu des changements récents sur le site Web ou l'application qui pourraient nécessiter une maintenance ?",
    maintquestion_10: "10. Avez-vous un système de sauvegarde en place pour votre site Web ou votre application ?",
    maintquestion_11: "11. À quel type de rapport ou de communication vous attendez-vous pendant le processus de maintenance ?",
    maintquestion_12: "12. Quelle est votre méthode de communication préférée ?",
    maintquestion_13: "Je souhaite être contacté par :",
    //hint seoquestion
    hintmaintquestion_1: "1. La réponse à cette question m'aidera à comprendre quelles parties du site Web ou de l'application vous souhaitez que je maintienne. Cela peut aller de la mise à jour du contenu à la correction de bogues ou de problèmes de sécurité.",
    hintmaintquestion_2: "2. Comprendre les types de mises à jour ou de modifications dont vous avez besoin nous aidera à planifier votre travail et à estimer le temps requis pour chaque tâche.",
    hintmaintquestion_3: "3. Savoir à quelle fréquence vous avez besoin de mises à jour ou de modifications nous aidera à planifier votre horaire de travail et à nous assurer que nous répondons à vos attentes.",
    hintmaintquestion_4: "4. Cette question nous aidera à identifier les problèmes immédiats qui doivent être résolus dans le cadre des travaux de maintenance.",
    hintmaintquestion_5: "5. Comprendre vos objectifs à long terme nous aidera à identifier les domaines qui doivent être améliorés ou mis à jour pour garantir que le site Web ou l'application continue de répondre à vos besoins.",
    hintmaintquestion_6: "6. Connaître votre budget nous aidera à déterminer le niveau de service que nous pouvons fournir et les tâches que nous pouvons entreprendre.",
    hintmaintquestion_7: "7. Répondre à cette question nous donnera une meilleure idée de vos attentes concernant le calendrier des travaux d'entretien. Il est important de savoir s'il y a des délais précis à respecter afin que nous puissions planifier vos travaux en conséquence.",
    hintmaintquestion_8: "8. Savoir s'il existe des problèmes de sécurité nous aidera à hiérarchiser votre travail et à garantir la sécurité du site Web ou de l'application.",
    hintmaintquestion_9: "9. Cette question nous aidera à identifier les modifications récentes susceptibles d'avoir causé des problèmes ou nécessitant des travaux de maintenance supplémentaires.",
    hintmaintquestion_10: "10. Savoir s'il existe un système de sauvegarde en place nous aidera à planifier tout problème imprévu ou problème pouvant survenir.",
    hintmaintquestion_11: "11. Comprendre le niveau de rapport ou de communication que vous attendez nous aidera à planifier comment les tenir informés de l'avancement des travaux de maintenance.",
    hintmaintquestion_12: "12. Connaître votre méthode de communication préférée nous aidera à nous assurer que nous sommes en mesure de communiquer efficacement tout au long du processus de maintenance.",
    //individual management service
    proceedmanagedescription: "Voici quelques questions essentielles que je pourrais vous poser avant de commencer la prestation demandée. Veuillez remplir gratuitement pour me permettre de mieux comprendre vos besoins, vos préférences et vos limites afin d'adapter le design si nécessaire pour répondre à ces exigences.",
    managequestion_1: "1. Quel est l'objectif principal de votre projet et que souhaitez-vous accomplir ?",
    managequestion_2: "2. Quel est votre budget pour ce projet ?",
    managequestion_3: "3. Quel est le calendrier du projet et quand pensez-vous qu'il sera terminé ?",
    managequestion_4: "4. Qui sont les parties prenantes impliquées dans ce projet ?",
    managequestion_5: "5. Quels sont les risques impliqués dans ce projet ?",
    managequestion_6: "6. Quels sont les livrables du projet et comment mesurez-vous le succès ?",
    managequestion_7: "7. Quels sont les indicateurs de performance clés (KPI) du projet ?",
    managequestion_8: "8. Quels sont les canaux et protocoles de communication pour le projet ?",
    managequestion_9: "9. Qui sera responsable de la prise de décision pendant le projet ?",
    managequestion_10: "10. Quels sont les outils et logiciels de gestion de projet que vous utilisez ?",
    managequestion_11: "11. Quelle est la composition et les rôles de l'équipe du projet ?",
    managequestion_12: "12. Quelles sont les exigences d'assistance et de maintenance post-projet ?",
    managequestion_13: "Je souhaite être contacté par :",
    //hint question
    hintmanagequestion_1: "1. Cette question nous aidera à comprendre le but et la portée du projet. Cela nous aidera également à déterminer les ressources nécessaires pour mener à bien le projet.",
    hintmanagequestion_2: "2. Connaître le budget est essentiel pour déterminer la faisabilité du projet et planifier les ressources en conséquence.",
    hintmanagequestion_3: "3. Cette question nous aidera à déterminer l'urgence et l'échéancier du projet. Cela nous aidera également à planifier les étapes du projet et le calendrier de livraison.",
    hintmanagequestion_4: "4. L'identification des parties prenantes est essentielle pour comprendre vos besoins et vos exigences. Cela nous aidera également à gérer vos attentes et à garantir votre adhésion au projet.",
    hintmanagequestion_5: "5. L'identification à l'avance des risques et des défis potentiels nous aidera à les planifier et à les atténuer avant qu'ils n'affectent le projet.",
    hintmanagequestion_6: "6. Cette question nous aidera à comprendre ce que vous attendez du projet et comment vous mesurez le succès.",
    hintmanagequestion_7: "7. Cette question nous aidera à déterminer comment mesurer le succès du projet et s'assurer qu'il correspond à vos objectifs.",
    hintmanagequestion_8: "8. Cette question nous aidera à déterminer vos besoins et vos préférences en matière de communication ainsi que ceux des parties prenantes. Cela nous aidera également à établir des protocoles et des procédures pour assurer une communication efficace tout au long du projet.",
    hintmanagequestion_9: "9. Savoir qui a le pouvoir de décision nous aidera à gérer le projet efficacement et à éviter les retards.",
    hintmanagequestion_10: "10. Cette question nous aidera à déterminer votre familiarité avec les outils de gestion de projet et le niveau d'adoption de la technologie requis pour le projet.",
    hintmanagequestion_11: "11. Cette question nous aidera à comprendre les compétences et l'expertise requises pour le projet et comment constituer une équipe si nécessaire qui répond aux besoins du projet.",
    hintmanagequestion_12: "12. Cette question nous aidera à comprendre vos attentes en matière d'assistance et de maintenance une fois le projet terminé. Cela nous aidera également à planifier des engagements futurs et des relations à long terme avec vous.",
    //individual miscellanious service
    proceedmisceldescription: "Voici quelques questions essentielles que je pourrais vous poser avant de commencer la prestation demandée. Veuillez remplir gratuitement pour me permettre de mieux comprendre vos besoins, vos préférences et vos limites afin d'adapter le design si nécessaire pour répondre à ces exigences.",
    miscelquestion_1: "1. Sélectionnez une tâche à la fois à coder et remplissez les questions ci-dessous.",
    miscelquestion_2: "2. Quelles sont les caractéristiques et fonctionnalités spécifiques que vous souhaitez ajouter à votre tâche ?",
    miscelquestion_3: "3. Avez-vous déjà conçu l'interface utilisateur (IU) pour votre tâche ?",
    miscelquestion_4: "4. Avez-vous un code existant ou une documentation de projet qui peut m'aider à mieux comprendre votre tâche ?",
    miscelquestion_5: "5. Avez-vous des préférences ou des exigences concernant les technologies utilisées dans la tâche ?",
    miscelquestion_6: "6. Votre tâche nécessitera-t-elle une intégration avec une base de données ou des API ?",
    miscelquestion_7: "7. Comment comptez-vous tester et valider la tâche ?",
    miscelquestion_8: "8. À quelle fréquence souhaitez-vous des mises à jour et des rapports d'avancement sur la tâche ?",
    miscelquestion_9: "9. Quels sont votre budget et votre calendrier pour cette tâche ?",
    miscelquestion_10: "10. Comment la tâche sera-t-elle déployée et hébergée ?",
    miscelquestion_11: "11. Quelles sont vos exigences de sécurité pour la tâche ?",
    miscelquestion_12: "12. Comment gérerez-vous la maintenance et le support une fois la tâche terminée ?",
    miscelquestion_13: "Je souhaite être contacté par :",
    //hint question
    hintmiscelquestion_1: "1. Vous devez d'abord sélectionner la fonction principale de votre tâche.",
    hintmiscelquestion_2: "2. Vous devez fournir une liste détaillée des caractéristiques et fonctionnalités que vous souhaitez ajouter et décrire comment vous envisagez leur fonctionnement.",
    hintmiscelquestion_3: "3. Vous devez décrire si vous avez déjà une conception pour l'interface utilisateur ou si vous avez besoin d'aide pour la concevoir.",
    hintmiscelquestion_4: "4. Vous devez fournir tout code existant ou documentation de projet pour nous aider à mieux comprendre la portée de la tâche.",
    hintmiscelquestion_5: "5. Vous devez fournir toutes les technologies spécifiques que vous souhaitez utiliser ou avoir des exigences concernant les technologies à éviter.",
    hintmiscelquestion_6: "6. Vous devez décrire toute intégration de base de données ou d'API qui sera requise pour la tâche.",
    hintmiscelquestion_7: "7. Vous devez décrire comment vous prévoyez de tester et de valider l'application pour vous assurer qu'elle répond à vos exigences.",
    hintmiscelquestion_8: "8. Vous devez décrire la fréquence à laquelle vous souhaitez des mises à jour sur la tâche et le type de rapports d'avancement que vous souhaitez recevoir.",
    hintmiscelquestion_9: "9. Vous devez fournir votre budget pour la tâche et le délai d'exécution souhaité.",
    hintmiscelquestion_10: "10. Vous devez décrire comment la tâche sera déployée et hébergée une fois terminée.",
    hintmiscelquestion_11: "11. Vous devez décrire toutes les exigences de sécurité spécifiques à la tâche.",
    hintmiscelquestion_12: "12. Vous devez décrire comment vous envisagez de gérer la maintenance et le support de la tâche une fois celle-ci terminée.",
    //task
    typetask: "Sélectionnez la tâche",
    react_task: "Composant React",
    html_task: "Tâche HTML",
    css_task: "Tâche CSS",
    database_task: "Intégration Databases",
    api_task: "Développement d'API",
    function_task: "Développement de fonctions",
    algorithm_task: "Codage d'algorithme",
    other_task: "Autres tâches",
    //Blog
    react_blog: "Blogue React",
    aspirant_blog: "Aspirant",
    introduction_blog: "Introduction",
    fundamental_blog: "Fondamentaux",
    advanced_blog: "Avancé",
    tools_blog: "Outils",
    technologies_blog: "Technologies",
    web_blog: "Web",
    html_blog: "HTML",
    css_blog: "CSS",
    javascript_blog: "Javascript",
    level_blog: "Niveau",
    beginner_blog: "Débutant",
    intermediate_blog: "Intermédiaire",
    expert_blog: "Expert",
    master_blog: "Master",
    preambule_blog_1: "Bienvenue dans notre guide étape par étape pour les développeurs Web en herbe, destiné à vous aider à devenir un développeur expérimenté. Dans ce guide, nous couvrirons tout ce que vous devez savoir pour devenir un maître du développement Web, à partir des bases de la programmation jusqu'aux dernières tendances et technologies Web. Que vous soyez débutant ou expert, notre guide vous aidera à améliorer vos compétences et à garder une longueur d'avance.",
    preambule_blog_2: "Notre guide est divisé en trois catégories principales : Aspirant, Débutant et Niveau. Chaque catégorie contient plusieurs sous-catégories composées d'articles et de tutoriels qui couvrent divers sujets en profondeur. Cette série de blogs commencera par la catégorie Aspirant et se terminera par la catégorie Niveau, avec de nouveaux blogs et tutoriels ajoutés régulièrement à chaque sous-catégorie. Nous croyons en l'importance d'avancer étape par étape, c'est pourquoi nous avons conçu notre guide pour vous aider à progresser progressivement.",
    preambule_blog_3: "Que vous préfériez apprendre à travers des vidéos, des livres, des didacticiels interactifs ou des projets pratiques, nous avons ce qu'il vous faut. Notre guide propose une large gamme de ressources d'apprentissage qui répondent à différents styles et préférences d'apprentissage. Pour les débutants, nous proposons également de véritables cours en ligne Learning by Getting Started où vous pouvez acquérir une expérience pratique du développement Web.",
    preambule_blog_4: "Que vous cherchiez à démarrer une carrière dans le développement Web ou que vous soyez un développeur chevronné cherchant à améliorer vos compétences, notre guide étape par étape vous fournira les connaissances et les ressources dont vous avez besoin pour réussir dans le monde du développement logiciel.",
    //
    message_to_wait_dear:"Chers développeurs,",
    message_to_wait_1:"Nous sommes ravis de vous annoncer qu'une nouvelle catégorie de blogs sera bientôt disponible ! Cependant, nous voulons nous assurer que vous avez pleinement absorbé les informations présentées dans la catégorie de blog précédente avant de passer à la suivante.",
    message_to_wait_2:"Nous vous prions de bien vouloir prendre le temps de lire et de bien comprendre chaque blog. Nous comprenons que chacun apprend à son propre rythme, mais nous suggérons d'attendre que la plupart de nos lecteurs aient achevé une lecture approfondie de la catégorie de blogs précédente avant de passer à la suivante.",
    message_to_wait_3:"Nous vous invitons également à continuer à consulter notre site Web pour les mises à jour et les nouveaux articles de blog. Chaque fois que vous vous sentez prêt à passer à la catégorie suivante, cliquez simplement sur l'onglet de la catégorie et vérifiez si de nouveaux blogs sont disponibles.",
    message_to_wait_4:"Merci pour votre dévouement et votre intérêt pour notre blog. Nous espérons que vous apprécierez notre prochaine catégorie tout autant que la précédente.",
    message_to_wait_best:"Cordialement,",
    message_to_wait_carions:"Carion's La Tradition Digitale",
    //Aspirant blogs advice
    aspirant_blog_1:"Lorsque vous débutez votre parcours de programmeur, il est important de ne pas avancer trop rapidement. Il est tentant de vouloir plonger directement dans des projets complexes ou des sujets avancés, mais prendre le temps de construire une base solide est essentiel pour un succès à long terme.",
    aspirant_blog_2:"En suivant une approche étape par étape, vous pouvez progressivement développer vos connaissances et vos compétences, gagner en confiance et en expertise en cours de route. Chaque étape s'appuie sur la précédente, il est donc important de maîtriser chacune avant de passer à la suivante.",
    aspirant_blog_3:"Par exemple, si vous débutez avec JavaScript, n'essayez pas d'aborder tout de suite des concepts avancés comme les fermetures ou la récursivité. Au lieu de cela, concentrez-vous sur la compréhension des variables, des types de données et du flux de contrôle de base. Une fois que vous les avez compris, vous pouvez passer à des sujets plus avancés.",
    aspirant_blog_4:"De même, si vous apprenez un nouveau framework comme React, n'essayez pas de créer une application complexe tout de suite. Commencez par des composants simples et progressez vers des composants plus complexes, en développant progressivement vos compétences et vos connaissances au fur et à mesure.",
    aspirant_blog_5:"N'oubliez pas que l'objectif n'est pas de se précipiter dans le processus et d'atteindre votre destination le plus rapidement possible. Il s'agit de profiter du voyage et d'apprendre autant que possible en cours de route. Alors, prenez votre temps, soyez patient et faites confiance au processus.",
    aspirant_blog_6:"Au fur et à mesure que vous progressez dans votre parcours de programmation, vous rencontrerez sans aucun doute des problèmes complexes qui nécessiteront une base solide dans les bases. Sauter des étapes peut sembler être un raccourci, mais cela finira par entraver votre progression et limiter votre capacité à vous attaquer à des tâches plus difficiles.",
    aspirant_blog_7:"En plus de construire une base solide, suivre une approche structurée de l'apprentissage vous aidera à rester motivé et concentré. Il est facile de se laisser submerger face à une quantité apparemment infinie d'informations à apprendre. En décomposant votre apprentissage en étapes plus petites et plus gérables, vous pouvez éviter l'épuisement professionnel et rester sur la bonne voie.",
    aspirant_blog_8:"N'oubliez pas que la programmation est un voyage qui dure toute la vie et qu'il est important d'apprécier le processus. Ne soyez pas trop pris par la recherche de la dernière technologie ou du dernier cadre. Au lieu de cela, concentrez-vous sur la construction d'une base solide et sur le perfectionnement de vos compétences en résolution de problèmes. Avec du temps, du dévouement et de la patience, vous deviendrez un programmeur compétent et confiant.",
    aspirant_blog_9:"Donc, mon conseil pour vous en tant qu'aspirant développeur est de prendre votre temps, de suivre chaque étape du processus d'apprentissage et de profiter du voyage. Avec persévérance et dévouement, vous atteindrez vos objectifs et deviendrez un programmeur à succès.",
    //poser une question
    question_select:"Question",
    question_comment:"Commentaire",
    question_blog:"Ajouter une question",
    cancel_question:"Annuler",
    send_question:"Envoyez votre question",
    write_question_blog:"Veuillez écrire votre question ou commentaire",
    question_public:"Publique",
    question_private:"Privée",
    //web blogs advice
    web_blog_1:"En tant qu'aspirant développeur Web, il est important d'aborder votre parcours avec intention et un plan de progression. Voici quelques conseils pour démarrer et progresser dans votre carrière de développeur Web :",
    web_blog_2:"1. Commencez par les bases : tout comme pour la programmation, il est important de commencer par les bases du développement Web. Cela inclut l'apprentissage de HTML, CSS et JavaScript. Prenez le temps de comprendre les fondamentaux de chaque langage et entraînez-vous à écrire du code.",
    web_blog_3:"2. Concentrez-vous sur l'expérience utilisateur : en tant que développeur Web, vous serez responsable de la création de sites Web à la fois visuellement attrayants et faciles à utiliser. Il est important de garder à l'esprit l'expérience utilisateur lors de la conception et de la création de sites Web.",
    web_blog_4:"3. Apprenez un framework CSS : les frameworks CSS, tels que Bootstrap et Foundation, peuvent vous faire gagner du temps et vous aider à créer des conceptions cohérentes sur votre site Web. Apprenez à utiliser au moins un framework CSS pour améliorer vos compétences en développement.",
    web_blog_5:"4. Comprendre l'importance de la réactivité : Avec autant de personnes accédant à Internet à partir de leurs appareils mobiles, il est crucial que les sites Web soient réactifs et puissent s'adapter à différentes tailles d'écran. Apprenez à créer des conceptions réactives qui auront fière allure sur n'importe quel appareil.",
    web_blog_6:"5. Développez vos compétences : une fois que vous avez une base solide en développement Web, il est important de continuer à apprendre et à développer vos compétences. Envisagez d'apprendre un langage back-end, tel que PHP ou Ruby, ou un framework JavaScript, tel que React ou Angular.",
    web_blog_7:"6. Pratiquez, pratiquez, pratiquez : Plus vous pratiquez l'écriture de code, meilleur vous deviendrez. Cherchez des occasions de mettre en pratique vos compétences en développement Web, que ce soit en créant des projets personnels ou en contribuant à des projets open source.",
    web_blog_8:"7. Restez à jour avec les tendances de l'industrie : Le monde du développement Web est en constante évolution, avec de nouvelles technologies et tendances qui émergent tout le temps. Restez au courant des dernières nouvelles et tendances de l'industrie pour vous assurer que vos compétences restent pertinentes et en demande.",
    web_blog_9:"N'oubliez pas que le développement Web est un voyage et qu'il faut du temps et des efforts pour le maîtriser. Ne vous laissez pas décourager par les revers et continuez à vous pousser à apprendre et à grandir en tant que développeur. Bonne chance dans votre parcours de développement Web !",
    //level blogs advice
    level_blog_1:"Si vous êtes un développeur Web en herbe et que vous souhaitez améliorer vos compétences, vous êtes au bon endroit. Les didacticiels et outils de développement Web que nous proposons dans les catégories Aspirant et Web sont parfaits pour démarrer et se familiariser avec les langages de programmation et les outils de développement Web. Cependant, pour vraiment faire progresser vos compétences et devenir un développeur compétitif dans l'industrie, la catégorie Niveau est la voie à suivre.",
    level_blog_2:"Considérez-le comme une salle de classe virtuelle en ligne, où chaque sous-catégorie correspond à une année de formation. À partir de la classe débutant, vous progresserez jusqu'à la classe intermédiaire, avancée et enfin la classe de maître, où vous pourrez développer de vrais projets logiciels et concourir pour les meilleurs emplois dans le domaine.",
    level_blog_3:"La catégorie Niveau est parfaite pour ceux qui souhaitent suivre de vrais cours de programmation de manière autodidacte, approfondir leurs connaissances, ou combler des lacunes dans leur compréhension. C'est comme avoir un tuteur personnel qui vous guide à chaque étape.",
    level_blog_4:"Pour accéder aux différentes sous-catégories, vous devez remplir quelques conditions. Premièrement, vous devez être au moins au quatrième degré du secondaire. Deuxièmement, vous aurez besoin d'un ordinateur portable ou d'un ordinateur de bureau pour les cours pratiques, ainsi que d'une connexion Internet fiable. De plus, vous aurez besoin d'un téléphone mobile à écran tactile avec un compte WhatsApp pour communiquer avec vos instructeurs et camarades de classe. Enfin, vous devrez créer un compte gratuit sur notre site Web et soumettre une candidature pour accéder à l'une des sous-catégories.",
    level_blog_5:"Une fois votre candidature approuvée, vous passerez un test simple pour évaluer vos connaissances et vos compétences. Ne vous inquiétez pas, il n'y a ni réussite ni échec. Nous utilisons vos résultats pour vous guider et vous offrir la meilleure expérience d'apprentissage possible.",
    level_blog_6:"Donc, si vous souhaitez vraiment devenir un développeur Web compétitif, ne sautez aucune étape. La catégorie Niveau est la voie à suivre.",
    level_apply:"Postuler",
    connect_first:"Connectez-vous à votre compte puis revenez sur la page.",
    connect_cours:"Accéder aux cours",
    connect_cours_content:"Entrez votre code étudiant et votre mot de passe pour accéder aux cours et plus encore ou créez un nouveau compte gratuitement si ce n'est pas encore fait.",
    code_inscrit:"Code étudiant",
    placecodeinscrit:"Entrer code étudiant",
    newaccount_incrit:"Pas de code étudiant ?",
    editaccount_inscrit:"Modifier détails de étudiant",
    student_creer:"Créez votre compte étudiant",

    //individual hosting service
    proceedhostingdescription: "Voici quelques questions essentielles que je pourrais vous poser avant de commencer la prestation demandée. Veuillez remplir gratuitement pour me permettre de mieux comprendre vos besoins, vos préférences et vos limites afin d'adapter le design si nécessaire pour répondre à ces exigences.",
    proceedhostingtitle: "Hébergement",
    hostingquestion_1:"1. Quel type de site Web devez-vous héberger ?",
    hostingquestion_2:"2. Quel est le trafic attendu sur votre site Web ?",
    hostingquestion_3:"3. Quel est votre budget pour les services d'hébergement ?",
    hostingquestion_4:"4. Quelle est votre exigence de disponibilité prévue ?",
    hostingquestion_5:"5. Avez-vous besoin d'une adresse IP dédiée ?",
    hostingquestion_6:"6. Quelle est la vitesse de chargement de votre site Web prévue ?",
    hostingquestion_7:"7. Avez-vous besoin d'un logiciel ou d'un langage de programmation spécifique ?",
    hostingquestion_8:"8. Quelle est votre exigence de stockage de données prévue ?",
    hostingquestion_9:"9. De quel niveau de support technique avez-vous besoin ?",
    hostingquestion_10:"10. Quelle est votre exigence d'évolutivité prévue ?",
    hostingquestion_11:"11. Quel est votre niveau d'exigence de sécurité attendu ?",
    hostingquestion_12:"12. Quel est votre niveau de contrôle attendu sur l'environnement d'hébergement ?",
    hostingquestion_13:"Je souhaite être contacté par :",
    //hint hostingquestion
    hinthostingquestion_1: "1. Comprendre le type de site Web que vous devez héberger nous aidera à déterminer la solution d'hébergement appropriée à vos besoins.",
    hinthostingquestion_2: "2. Connaître le trafic prévu sur votre site Web nous aidera à déterminer le plan d'hébergement approprié et les ressources de serveur nécessaires pour gérer le trafic.",
    hinthostingquestion_3: "3. Connaître votre budget pour les services d'hébergement nous aidera à recommander des solutions d'hébergement abordables et respectant votre budget.",
    hinthostingquestion_4: "4. Comprendre vos besoins de disponibilité prévus nous aidera à recommander des solutions d'hébergement offrant une disponibilité et une disponibilité élevées.",
    hinthostingquestion_5: "5. Savoir si vous avez besoin d'une adresse IP dédiée nous aidera à vous recommander des solutions d'hébergement offrant cette fonctionnalité.",
    hinthostingquestion_6: "6. Comprendre la vitesse de chargement de votre site Web prévue nous aidera à recommander des solutions d'hébergement qui offrent des temps de chargement de page rapides.",
    hinthostingquestion_7: "7. Savoir si vous avez besoin d'un logiciel ou d'un langage de programmation spécifique nous aidera à recommander des solutions d'hébergement qui prennent en charge ces exigences.",
    hinthostingquestion_8: "8. Comprendre vos besoins de stockage de données attendus nous aidera à recommander des solutions d'hébergement qui offrent une capacité de stockage suffisante.",
    hinthostingquestion_9: "9. Connaître vos besoins en matière de support technique nous aidera à recommander des solutions d'hébergement offrant le niveau de support approprié.",
    hinthostingquestion_10: "10. Comprendre vos besoins d'évolutivité attendus nous aidera à recommander des solutions d'hébergement qui peuvent s'adapter à la croissance et à l'expansion futures.",
    hinthostingquestion_11: "11. Connaître votre niveau d'exigence de sécurité attendu nous aidera à recommander des solutions d'hébergement qui fournissent des fonctionnalités et des protocoles de sécurité appropriés.",
    hinthostingquestion_12: "12. Comprendre votre niveau de contrôle attendu sur l'environnement d'hébergement nous aidera à recommander des solutions d'hébergement qui offrent le niveau approprié de contrôle et d'options de personnalisation.",
    //admin dashboard 
    //Onboard
    service_admin_title:"Tableau de bord Services",
    admin_onboard:"À bord",
    admin_planning:"Planification",
    admin_schedule:"Calendrier",
    admin_communication:"Communication",
    admin_update:"Actualiser",
    admin_contract:"Contrat",
    admin_budget:"Budget",
    admin_timeline:"Chronologie",
    admin_metrics:"Métrique",
    admin_view:"Visualiser",
    admin_management:"Gestion",
    admin_new:"Nouveau",
    admin_delivery:"Livraison",
    admin_deployment:"Déploiement",
    //planning
    objective_requirements:"1. Définir les objectifs et les exigences du projet : il s'agit d'identifier les besoins, les objectifs et les exigences du client pour le projet.",
    project_plan:"2. Création d'un plan de projet : Cela implique l'élaboration d'un plan de projet qui décrit la portée, le calendrier, le budget et les ressources nécessaires au projet.",
    project_milestones:"3. Identifier les jalons du projet : il s'agit de décomposer le projet en tâches ou jalons plus petits et gérables qui peuvent être suivis et mesurés.",
    project_timelines:"4. Établir des échéanciers de projet : Cela implique la création d'un échéancier qui décrit les dates de début et de fin de chaque tâche ou jalon.",
    task_responsibilities:"5. Attribuer des tâches et des responsabilités : il s'agit d'identifier les membres de l'équipe ou les sous-traitants responsables de chaque tâche ou étape, et d'attribuer des délais et des priorités.",
    risks_contingencies:"6. Identifier les risques et les imprévus du projet : Cela implique d'identifier les risques ou les défis potentiels qui pourraient avoir un impact sur le projet et d'élaborer des plans d'urgence pour atténuer ces risques.",
    review_adjust:"7. Examiner et ajuster le plan du projet : Cela implique d'examiner régulièrement le plan et le calendrier du projet pour s'assurer que le projet est sur la bonne voie et d'effectuer les ajustements nécessaires.",
    planning_succes:"Ce planning a été enregistré avec succès",
    planning_exists:"Ce planning a déjà été enregistré",
    //schedule
    info_schedule:"Établir une connexion et discuter avec le client lors de la prise de rendez-vous puis valider.",
    //share carions contents
    share_title:"📣 Aidez-nous à passer le mot ! Partagez notre application Web avec vos amis et collègues ! 🚀",
    share_description_1:"Chers visiteurs/utilisateurs de Carion,",
    share_description_2:"Nous sommes ravis de vous compter parmi les utilisateurs de Carion ! Nous pensons que les grandes choses doivent être partagées et nous apprécierions grandement votre soutien pour faire connaître ce que nous proposons.",
    share_description_3:"Si vous avez trouvé de la valeur à utiliser Carion's, nous vous invitons à le partager avec vos amis et collègues en cliquant simplement sur l'icône de partage sur votre téléphone mobile. Ce faisant, vous aiderez les autres à découvrir un outil puissant qui peut rendre leur vie plus facile et plus productive.",
    share_description_4:"Voici comment vous pouvez aider :",
    share_description_5:"1️⃣ Ouvrez notre application Web sur votre téléphone mobile.",
    share_description_6:"2️⃣ Recherchez l'icône de partage (généralement représentée par trois points reliés par des lignes ou une flèche pointant vers le haut).",
    share_description_7:"3️⃣ Appuyez sur l'icône de partage et choisissez votre plate-forme de médias sociaux ou votre application de messagerie préférée pour partager le lien de l'application Web avec vos contacts.",
    share_description_8:"Votre soutien signifie le monde pour nous, et ensemble, nous pouvons créer une communauté dynamique et prospère autour de notre application Web. Donnons à plus d'individus les moyens d'atteindre leurs objectifs et de libérer leur plein potentiel.",
    share_description_9:"Merci de faire partie de notre voyage!",
    share_description_10:"Cordialement,",
    share_description_11:"Carion's Ltd",
    //comments
    comment_title:"Commentaires",
    comment_dear:"Chers utilisateurs",
    comment_description:"Carion's vous invite à la soutenir en partageant vos suggestions et propositions qui pourraient nous permettre d'améliorer votre expérience utilisateur et d'améliorer cette application pour mieux vous servir.",
    comment_select:"Evaluation",
    select_star:"Sélectionnez votre évaluation",
    comment_why:"Saisissez vos remarques et suggestions",
    creer_comment:"Soumettre",
    comment_name:"Ajoutez votre nom s'il vous plaît.",
    comment_place:"Votre Nom",
    view_all_comment:"Voir tous les commentaires",
    //crud account
    info_titre:"🔐 Gérez votre compte en toute simplicité ! Créez, modifiez et supprimez votre compte sans tracas ! 🔄",
    info_dear:"Chers utilisateurs,",
    info_intro:"Nous voulons rendre votre expérience avec nos services d'applications Web aussi transparente que possible, c'est pourquoi nous avons mis en place une fonctionnalité de gestion de compte conviviale. Avec cette fonctionnalité, vous avez un contrôle total sur votre compte, vous permettant de le créer, de le modifier et de le supprimer à tout moment, sans aucune complication.",
    info_create:"1️⃣ Créez un compte :",
    info_create_detail:"Lorsque vous accédez pour la première fois à notre application Web, cliquez sur le bouton 'S'inscrire' ou 'Créer un compte'. Remplissez les informations requises, définissez vos préférences et le tour est joué ! Votre compte sera créé en quelques étapes simples.",
    info_edit:"2️⃣ Modifiez votre compte :",
    info_edit_detail:"Besoin de mettre à jour vos informations personnelles, de changer votre mot de passe ou de modifier les paramètres de votre compte ? Aucun problème! Accédez simplement à la section 'Gestion du compte et cliquez sur Modifier votre compte', où vous trouverez des options pour modifier vos informations. Apportez les modifications nécessaires, enregistrez-les et votre compte sera mis à jour instantanément.",
    info_delete:"3️⃣Supprimer votre compte :",
    info_delete_detail:"Si jamais vous décidez de supprimer votre compte, nous avons simplifié le processus. Accédez à la section 'Gestion du compte' et cliquez sur l'option 'Supprimer votre compte', puis suivez les instructions. Veuillez noter que la suppression de votre compte supprimera définitivement toutes les données associées, alors procédez avec prudence.",
    info_conclu_1:"En offrant cette fonctionnalité de gestion de compte sans tracas, nous visons à vous offrir flexibilité et contrôle sur votre expérience d'application Web. Nous comprenons que les circonstances changent et vous devriez avoir la liberté de gérer votre compte comme bon vous semble.",
    info_conclu_2:"Si vous avez des questions ou avez besoin d'aide concernant la gestion de votre compte, notre équipe d'assistance est toujours là pour vous aider. Contactez-nous simplement via le formulaire de contact.",
    info_conclu_3:"Merci d'être un utilisateur apprécié de notre application Web !",
    info_conclu_4:"Cordialement,",
    info_conclu_5:"Carion's Ltd",
    //
    login_before:"Connectez-vous avant de gérer votre compte",
    //delete account
    delete_titre:"⚠️ Avis important : suppression et récupération de compte ⚠️",
    delete_texte:"La suppression de votre compte supprime définitivement toutes les données et informations qui y sont associées. Une fois supprimé, il ne peut pas être récupéré. Considérez cela attentivement car vous perdrez l'accès à votre compte et à son contenu. Pour revenir, vous devez créer un nouveau compte. Nous vous recommandons de sauvegarder toutes les données importantes avant de continuer. Notre équipe d'assistance est là pour vous aider si vous avez des préoccupations ou des questions. Suivez le processus de suppression de compte dans la section 'Gestion du compte'. Merci d'être un membre apprécié de Carion's Ltd!",
    delete_conclu_1:"Cordialement,",
    delete_conclu_2:"Carion's Ltd",
    delete:"Supprimer",
    //
    share_with:"Partager avec",
    check_out:"Découvrez les Solutions Web de Carion's. Partagez le lien pour aider les autres à bénéficier de nos services !",
};