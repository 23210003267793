import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../proceedweb.css';
import { useRef, useState } from "react";
//
import { MdOutlineCreate, MdSettingsBackupRestore, MdOutlineArrowForwardIos, MdOutlineArrowBackIos, MdOutlineAttachEmail } from 'react-icons/md';
import { AiOutlineWhatsApp } from 'react-icons/ai';
//
import { AlertModal, getCurrentDate, PopupModal } from "../../helperfunctions/Helper";
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
import {  
    SpanGlobalSpan,
    GlobalStrongTitle,
    LongFormScrollerWidgetSm,
    TextProceedService, 
    TextLoginService,
    GlobalLargeInput, 
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
    TextArea,
} from "../../global/Styles";

import Axios from 'axios';

function ProceedRecherche() {
    const {t} = useTranslation();

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleSeoSearchChange = event => {
        if (!isValidEmail(event.target.value)) {
          setError(t('invalidemail'));
        } else {
          setError(null);
        }
    
        setEmail(event.target.value);
    };

    const navigateProceedSeoSearch = useNavigate();

    //alert
    const elementOutId = "modaloutproceedseosearch";
    const elementDropId = "backdropoutproceedseosearch";
    const buttonHandler = "ouiHandlerProceedSeoSearchBtn";
    //
    const elementOutAlertId = "modaloutalertproceedseosearch";
    const elementDropAlertId = "backdropoutalertproceedseosearch";
    const buttonAlertHandler = "ouiHandleralertProceedSeoSearchBtn";

    const searchSeoQuestion1Ref = useRef(null);
    const searchSeoQuestion2Ref = useRef(null);
    const searchSeoQuestion3Ref = useRef(null);
    //
    const searchSeoQuestion4Ref = useRef(null);
    const searchSeoQuestion5Ref = useRef(null);
    const searchSeoQuestion6Ref = useRef(null);
    //
    const searchSeoQuestion7Ref = useRef(null);
    const searchSeoQuestion8Ref = useRef(null);
    const searchSeoQuestion9Ref = useRef(null);
    //
    const searchSeoQuestion10Ref = useRef(null);
    const searchSeoQuestion11Ref = useRef(null);
    const searchSeoQuestion12Ref = useRef(null);
    //
    const searchSeoQuestion13Ref = useRef(null);
    const searchSeoQuestion14Ref = useRef(null);

    const popseosearch1Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_1");
        const pTexteNotAvailable = t("hintseoquestion_1");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseosearch2Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_2");
        const pTexteNotAvailable = t("hintseoquestion_2");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseosearch3Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_3");
        const pTexteNotAvailable = t("hintseoquestion_3");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const popseosearch4Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_4");
        const pTexteNotAvailable = t("hintseoquestion_4");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseosearch5Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_5");
        const pTexteNotAvailable = t("hintseoquestion_5");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseosearch6Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_6");
        const pTexteNotAvailable = t("hintseoquestion_6");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const popseosearch7Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_7");
        const pTexteNotAvailable = t("hintseoquestion_7");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseosearch8Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_8");
        const pTexteNotAvailable = t("hintseoquestion_8");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseosearch9Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_9");
        const pTexteNotAvailable = t("hintseoquestion_9");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const popseosearch10Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_10");
        const pTexteNotAvailable = t("hintseoquestion_10");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseosearch11Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_11");
        const pTexteNotAvailable = t("hintseoquestion_11");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popseosearch12Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("seoquestion_12");
        const pTexteNotAvailable = t("hintseoquestion_12");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const forwardProceedSeoSearch1Handler = async (e) => {
        e.preventDefault();

        const seosearchsteponeDiv = document.getElementById('seosearchstepone');
        const seosearchsteptwoDiv = document.getElementById('seosearchsteptwo');
        const seosearchstepthreeDiv = document.getElementById('seosearchstepthree');
        const seosearchstepfourDiv = document.getElementById('seosearchstepfour');
        const modeproceedseosearchDiv = document.getElementById('modeproceedseosearch');
        const submitproceedseosearchDiv = document.getElementById('submitproceedseosearch');
        //
        seosearchsteponeDiv.style.display = 'none';
        seosearchsteptwoDiv.style.display = 'block';
        seosearchstepthreeDiv.style.display = 'none';
        seosearchstepfourDiv.style.display = 'none';
        modeproceedseosearchDiv.style.display = 'none';
        submitproceedseosearchDiv.style.display = 'none';
    }

    const goBackProceedSeoSearch2Handler = async (e) => {
        e.preventDefault();

        const seosearchsteponeDiv = document.getElementById('seosearchstepone');
        const seosearchsteptwoDiv = document.getElementById('seosearchsteptwo');
        const seosearchstepthreeDiv = document.getElementById('seosearchstepthree');
        const seosearchstepfourDiv = document.getElementById('seosearchstepfour');
        const modeproceedseosearchDiv = document.getElementById('modeproceedseosearch');
        const submitproceedseosearchDiv = document.getElementById('submitproceedseosearch');
        //
        seosearchsteponeDiv.style.display = 'block';
        seosearchsteptwoDiv.style.display = 'none';
        seosearchstepthreeDiv.style.display = 'none';
        seosearchstepfourDiv.style.display = 'none';
        modeproceedseosearchDiv.style.display = 'none';
        submitproceedseosearchDiv.style.display = 'none';
    }

    const forwardProceedSeoSearch2Handler = async (e) => {
        e.preventDefault();

        const seosearchsteponeDiv = document.getElementById('seosearchstepone');
        const seosearchsteptwoDiv = document.getElementById('seosearchsteptwo');
        const seosearchstepthreeDiv = document.getElementById('seosearchstepthree');
        const seosearchstepfourDiv = document.getElementById('seosearchstepfour');
        const modeproceedseosearchDiv = document.getElementById('modeproceedseosearch');
        const submitproceedseosearchDiv = document.getElementById('submitproceedseosearch');
        //
        seosearchsteponeDiv.style.display = 'none';
        seosearchsteptwoDiv.style.display = 'none';
        seosearchstepthreeDiv.style.display = 'block';
        seosearchstepfourDiv.style.display = 'none';
        modeproceedseosearchDiv.style.display = 'none';
        submitproceedseosearchDiv.style.display = 'none';
    }

    const goBackProceedSeoSearch3Handler = async (e) => {
        e.preventDefault();

        const seosearchsteponeDiv = document.getElementById('seosearchstepone');
        const seosearchsteptwoDiv = document.getElementById('seosearchsteptwo');
        const seosearchstepthreeDiv = document.getElementById('seosearchstepthree');
        const seosearchstepfourDiv = document.getElementById('seosearchstepfour');
        const modeproceedseosearchDiv = document.getElementById('modeproceedseosearch');
        const submitproceedseosearchDiv = document.getElementById('submitproceedseosearch');
        //
        seosearchsteponeDiv.style.display = 'none';
        seosearchsteptwoDiv.style.display = 'block';
        seosearchstepthreeDiv.style.display = 'none';
        seosearchstepfourDiv.style.display = 'none';
        modeproceedseosearchDiv.style.display = 'none';
        submitproceedseosearchDiv.style.display = 'none';
    }

    const forwardProceedSeoSearch3Handler = async (e) => {
        e.preventDefault();

        const seosearchsteponeDiv = document.getElementById('seosearchstepone');
        const seosearchsteptwoDiv = document.getElementById('seosearchsteptwo');
        const seosearchstepthreeDiv = document.getElementById('seosearchstepthree');
        const seosearchstepfourDiv = document.getElementById('seosearchstepfour');
        const modeproceedseosearchDiv = document.getElementById('modeproceedseosearch');
        const submitproceedseosearchDiv = document.getElementById('submitproceedseosearch');
        //
        seosearchsteponeDiv.style.display = 'none';
        seosearchsteptwoDiv.style.display = 'none';
        seosearchstepthreeDiv.style.display = 'none';
        seosearchstepfourDiv.style.display = 'block';
        modeproceedseosearchDiv.style.display = 'none';
        submitproceedseosearchDiv.style.display = 'none';
    }

    const goBackProceedSeoSearch4Handler = async (e) => {
        e.preventDefault();

        const seosearchsteponeDiv = document.getElementById('seosearchstepone');
        const seosearchsteptwoDiv = document.getElementById('seosearchsteptwo');
        const seosearchstepthreeDiv = document.getElementById('seosearchstepthree');
        const seosearchstepfourDiv = document.getElementById('seosearchstepfour');
        const modeproceedseosearchDiv = document.getElementById('modeproceedseosearch');
        const submitproceedseosearchDiv = document.getElementById('submitproceedseosearch');
        //
        seosearchsteponeDiv.style.display = 'none';
        seosearchsteptwoDiv.style.display = 'none';
        seosearchstepthreeDiv.style.display = 'block';
        seosearchstepfourDiv.style.display = 'none';
        modeproceedseosearchDiv.style.display = 'none';
        submitproceedseosearchDiv.style.display = 'none';
    }

    const forwardProceedSeoSearch4Handler = async (e) => {
        e.preventDefault();

        const seosearchsteponeDiv = document.getElementById('seosearchstepone');
        const seosearchsteptwoDiv = document.getElementById('seosearchsteptwo');
        const seosearchstepthreeDiv = document.getElementById('seosearchstepthree');
        const seosearchstepfourDiv = document.getElementById('seosearchstepfour');
        const modeproceedseosearchDiv = document.getElementById('modeproceedseosearch');
        const submitproceedseosearchDiv = document.getElementById('submitproceedseosearch');
        //
        seosearchsteponeDiv.style.display = 'none';
        seosearchsteptwoDiv.style.display = 'none';
        seosearchstepthreeDiv.style.display = 'none';
        seosearchstepfourDiv.style.display = 'none';
        modeproceedseosearchDiv.style.display = 'block';
        submitproceedseosearchDiv.style.display = 'none';
    }

    const goBackProceedSeoSearch5Handler = async (e) => {
        e.preventDefault();

        const seosearchsteponeDiv = document.getElementById('seosearchstepone');
        const seosearchsteptwoDiv = document.getElementById('seosearchsteptwo');
        const seosearchstepthreeDiv = document.getElementById('seosearchstepthree');
        const seosearchstepfourDiv = document.getElementById('seosearchstepfour');
        const modeproceedseosearchDiv = document.getElementById('modeproceedseosearch');
        const submitproceedseosearchDiv = document.getElementById('submitproceedseosearch');
        //
        seosearchsteponeDiv.style.display = 'none';
        seosearchsteptwoDiv.style.display = 'none';
        seosearchstepthreeDiv.style.display = 'none';
        seosearchstepfourDiv.style.display = 'block';
        modeproceedseosearchDiv.style.display = 'none';
        submitproceedseosearchDiv.style.display = 'none';
    }

    const forwardProceedSeoSearch5Handler = async (e) => {
        e.preventDefault();

        const searchSeoQuestion13 = searchSeoQuestion13Ref.current.value;
        const searchSeoQuestion14 = searchSeoQuestion14Ref.current.value;
        //
        const seosearchsteponeDiv = document.getElementById('seosearchstepone');
        const seosearchsteptwoDiv = document.getElementById('seosearchsteptwo');
        const seosearchstepthreeDiv = document.getElementById('seosearchstepthree');
        const seosearchstepfourDiv = document.getElementById('seosearchstepfour');
        const modeproceedseosearchDiv = document.getElementById('modeproceedseosearch');
        const submitproceedseosearchDiv = document.getElementById('submitproceedseosearch');
        //
        if(!searchSeoQuestion13){
            const pTexteSaisir = t("enteremail");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else if(!searchSeoQuestion14){
            const pTexteSaisir = t("enterphone");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else{
            //
            seosearchsteponeDiv.style.display = 'none';
            seosearchsteptwoDiv.style.display = 'none';
            seosearchstepthreeDiv.style.display = 'none';
            seosearchstepfourDiv.style.display = 'none';
            modeproceedseosearchDiv.style.display = 'none';
            submitproceedseosearchDiv.style.display = 'block';
        }
    }

    const goBackProceedSeoSearch6Handler = async (e) => {
        e.preventDefault();

        const seosearchsteponeDiv = document.getElementById('seosearchstepone');
        const seosearchsteptwoDiv = document.getElementById('seosearchsteptwo');
        const seosearchstepthreeDiv = document.getElementById('seosearchstepthree');
        const seosearchstepfourDiv = document.getElementById('seosearchstepfour');
        const modeproceedseosearchDiv = document.getElementById('modeproceedseosearch');
        const submitproceedseosearchDiv = document.getElementById('submitproceedseosearch');
        //
        seosearchsteponeDiv.style.display = 'none';
        seosearchsteptwoDiv.style.display = 'none';
        seosearchstepthreeDiv.style.display = 'none';
        seosearchstepfourDiv.style.display = 'none';
        modeproceedseosearchDiv.style.display = 'block';
        submitproceedseosearchDiv.style.display = 'none';
    }

    //http://localhost:5000/check/seosearchservice

    const submitProceedSeoSearchHandler = async (e) => {
        e.preventDefault();

        const searchSeoQuestion1 = searchSeoQuestion1Ref.current.value;
        const searchSeoQuestion2 = searchSeoQuestion2Ref.current.value;
        const searchSeoQuestion3 = searchSeoQuestion3Ref.current.value;
        //websteptwo
        const searchSeoQuestion4 = searchSeoQuestion4Ref.current.value;
        const searchSeoQuestion5 = searchSeoQuestion5Ref.current.value;
        const searchSeoQuestion6 = searchSeoQuestion6Ref.current.value;
        //webstepthree
        const searchSeoQuestion7 = searchSeoQuestion7Ref.current.value;
        const searchSeoQuestion8 = searchSeoQuestion8Ref.current.value;
        const searchSeoQuestion9 = searchSeoQuestion9Ref.current.value;
        //webstepfour
        const searchSeoQuestion10 = searchSeoQuestion10Ref.current.value;
        const searchSeoQuestion11 = searchSeoQuestion11Ref.current.value;
        const searchSeoQuestion12 = searchSeoQuestion12Ref.current.value;
        //modeproceedweb
        const searchSeoQuestion13 = searchSeoQuestion13Ref.current.value;
        const searchSeoQuestion14 = searchSeoQuestion14Ref.current.value;
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const theDate = getCurrentDate();
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = "seokeyword";
        const servicePlan = document.getElementById('typeplan').innerText;
        var createTicketCode = "";
        //
        var resultat1 = "";
        //generate random ticket code
        var randomNumber = Math.floor((Math.random() * 100000) + 1);
        createTicketCode = randomNumber+''+codeutilisateurDiv;
        //
        await Axios.post("https://micarionsapi.carions.net/check/seosearchservice", {
            codeutilisateurDiv:codeutilisateurDiv,
            typeService:typeService,
            servicePlan:servicePlan,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                //insert
                Axios.post("https://micarionsapi.carions.net/quest/seosearchservice", {
                    choixlangueDiv:choixlangueDiv,
                    usernamedetailDiv:usernamedetailDiv,
                    codeutilisateurDiv:codeutilisateurDiv,
                    //
                    typeService:typeService,
                    servicePlan:servicePlan,
                    //
                    createTicketCode:createTicketCode,
                    //
                    searchSeoQuestion1:searchSeoQuestion1,
                    searchSeoQuestion2:searchSeoQuestion2,
                    searchSeoQuestion3:searchSeoQuestion3,
                    //
                    searchSeoQuestion4:searchSeoQuestion4,
                    searchSeoQuestion5:searchSeoQuestion5,
                    searchSeoQuestion6:searchSeoQuestion6,
                    //
                    searchSeoQuestion7:searchSeoQuestion7,
                    searchSeoQuestion8:searchSeoQuestion8,
                    searchSeoQuestion9:searchSeoQuestion9,
                    //
                    searchSeoQuestion10:searchSeoQuestion10,
                    searchSeoQuestion11:searchSeoQuestion11,
                    searchSeoQuestion12:searchSeoQuestion12,
                    //
                    searchSeoQuestion13:searchSeoQuestion13,
                    searchSeoQuestion14:searchSeoQuestion14,
                    //
                    theDate:theDate,
                }).then(() => {
                    var answer1 = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                    const hTitleTexte = t("proceedwebapptitle");
                    const pTexteNotAvailable = answer1;
                    PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
                });
            }else{
                //set up modal to update
                var answer = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                console.log(resultat1);
                const hTitleTexte = t("proceedwebapptitle");
                const pTexteNotAvailable = answer;
                PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
            }
        });
    }

    const goBackProceedSeoSearchHandler = async (e) => {
        e.preventDefault();

        navigateProceedSeoSearch('/keywordtab');
    }

    return ( 
        <div id="proceedrecherche">
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t('proceedseosearchtitle')}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <LongFormScrollerWidgetSm>
                <div id="modaloutproceedseosearch"></div>
                <div id="backdropoutproceedseosearch"></div>
                <p className="pservicestyles"><br/>{t("proceedseodescription")}</p>
                <div id="seosearchstepone">
                    <TextProceedService onClick={popseosearch1Handler}>{t("seoquestion_1")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={searchSeoQuestion1Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseosearch2Handler}>{t("seoquestion_2")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={searchSeoQuestion2Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseosearch3Handler}>{t("seoquestion_3")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={searchSeoQuestion3Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <ButtonStart onClick={forwardProceedSeoSearch1Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="seosearchsteptwo">
                    <TextProceedService onClick={popseosearch4Handler}>{t("seoquestion_4")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={searchSeoQuestion4Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseosearch5Handler}>{t("seoquestion_5")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={searchSeoQuestion5Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseosearch6Handler}>{t("seoquestion_6")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={searchSeoQuestion6Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedSeoSearch2Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedSeoSearch2Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="seosearchstepthree">
                    <TextProceedService onClick={popseosearch7Handler}>{t("seoquestion_7")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={searchSeoQuestion7Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseosearch8Handler}>{t("seoquestion_8")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={searchSeoQuestion8Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseosearch9Handler}>{t("seoquestion_9")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={searchSeoQuestion9Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedSeoSearch3Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedSeoSearch3Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="seosearchstepfour">
                    <TextProceedService onClick={popseosearch10Handler}>{t("seoquestion_10")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={searchSeoQuestion10Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseosearch11Handler}>{t("seoquestion_11")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={searchSeoQuestion11Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popseosearch12Handler}>{t("seoquestion_12")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={searchSeoQuestion12Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedSeoSearch4Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedSeoSearch4Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="modeproceedseosearch">
                    <TextProceedService>{t("webquestion_13")}</TextProceedService>
                    <TextLoginService><MdOutlineAttachEmail style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput id="emailseosearch" name="emailseosearch" value={email} onChange={handleSeoSearchChange} ref={searchSeoQuestion13Ref} placeholder={t("placemail")}/>
                        {error && <h5 style={{color: 'white'}}>{error}</h5>}
                    </div>
                    <br/>
                    <TextLoginService><AiOutlineWhatsApp style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                            <GlobalLargeInput type='number' ref={searchSeoQuestion14Ref}  placeholder={t("placewhatsapp")}/>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedSeoSearch5Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedSeoSearch5Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="submitproceedseosearch">
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedSeoSearch6Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={submitProceedSeoSearchHandler}>
                                    <MdOutlineCreate style={{fontSize:'30px'}} />
                                   {t("creer")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackProceedSeoSearchHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertproceedseosearch"></div>
                <div id="backdropoutalertproceedseosearch"></div>
            </LongFormScrollerWidgetSm>
        </div>
    );
}

export default ProceedRecherche;