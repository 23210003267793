import React from "react-dom";
import '../../components.css';
//import myeditor
import ViewServiceEditor from "../servicesquilleditor/ViewServiceEditor";
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle, 
} from '../../../global/Styles';

function ServiceViewContenu() {
    const {t} = useTranslation();

    return ( 
        <DivContainerSlide id="serviceviewcontenu">
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t("crudcontenuVisualiser")} {t("services")}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <div className="lescontenus">
                <ViewServiceEditor />
                <br/>
            </div>
        </DivContainerSlide>
     );
}

export default ServiceViewContenu;