import React from "react-dom";
import { NavLink } from 'react-router-dom';
import './accueil.css';
import image from '../carions.png';
//
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import '../translations/i18n';
import '../translations/Translation';
//
import {BiUserCircle} from 'react-icons/bi';
import {BiLike} from 'react-icons/bi';
import {MdShare} from 'react-icons/md';
//
import { 
    Section, 
    TranslateContainer, 
    LeftSection, 
    ButtonLinks, 
    AccueilButton,
} from './AccueilStyles';
//
import {
    DivContainerSlide,  
} from '../global/Styles';
//

import { useNavigate } from "react-router-dom";

function Accueil() {
    const {t} = useTranslation();

    const navigateAccueil = useNavigate();
    
    const accueilHandler = async (e) => {
        e.preventDefault();

        document.getElementById("actionpage").innerText = t("accueil");
        //
        //navigateAccueil('/accueilintro');
        navigateAccueil('/');
    }

    const carionsHandler = async (e) => {
        e.preventDefault();

        document.getElementById("actionpage").innerText = t("accueil");
        document.getElementById("resultId").innerText = "accueilbtn";
        //
        const fonctionuserDiv = document.getElementById('fonctionuser').innerText;
        //
        if(fonctionuserDiv === 'redacteur'){
            navigateAccueil('/crudaccueil')
        }else{
            navigateAccueil('/accueildetail');
        }
    }

    const aproposHandler = async (e) => {
        e.preventDefault();
        
        document.getElementById("actionpage").innerText = t("apropos");
        document.getElementById("resultId").innerText = "aproposbtn";
        //
        const fonctionuserDiv = document.getElementById('fonctionuser').innerText;
        //
        if(fonctionuserDiv === 'redacteur'){
            navigateAccueil('/crudaccueil')
        }else{
            navigateAccueil('/aproposdetail');
        }
    };

    const portfolioHandler = async (e) => {
        e.preventDefault();

        window.location.href = 'https://www.carions.net';
    };

    const servicesHandler = async (e) => {
        e.preventDefault();

        document.getElementById("actionpage").innerText = t("services");
        document.getElementById("resultId").innerText = "servicesbtn";
        //
        const fonctionuserDiv = document.getElementById('fonctionuser').innerText;
        //
        if(fonctionuserDiv === 'redacteur'){
            navigateAccueil('/crudservicescompo');
        }else{
            navigateAccueil('/mainsoftwareservices');
        }
    }

    const webHandler = async (e) => {
        e.preventDefault();

        document.getElementById("actionpage").innerText = "web";
        document.getElementById("resultId").innerText = "blogsbtn";
        //
        const fonctionuserDiv = document.getElementById('fonctionuser').innerText;
        //
        if(fonctionuserDiv === 'redacteur'){
            navigateAccueil('/crudaccueil');
        }else{
            navigateAccueil('/mainwebservices');
        }
    }

    const seoHandler = async (e) => {
        e.preventDefault();

        document.getElementById("actionpage").innerText = "seo";
        document.getElementById("resultId").innerText = "seobtn";
        //
        const fonctionuserDiv = document.getElementById('fonctionuser').innerText;
        //
        if(fonctionuserDiv === 'redacteur'){
            navigateAccueil('/crudaccueil');
        }else{
            navigateAccueil('/mainseoservices');
        }
    }

    const hostingHandler = async (e) => {
        e.preventDefault();

        document.getElementById("actionpage").innerText = "hosting";
        document.getElementById("resultId").innerText = "hostingbtn";
        //
        const fonctionuserDiv = document.getElementById('fonctionuser').innerText;
        //
        if(fonctionuserDiv === 'redacteur'){
            navigateAccueil('/crudaccueil');
        }else{
            navigateAccueil('/proceedhosting');
        }
    }

    const commentHandler = async (e) => {
        e.preventDefault();

        navigateAccueil('/commentcarions');
    }

    const shareHandler = async (e) => {
        e.preventDefault();

        navigateAccueil("/sharecarions");
    }

    //select language
    const FrancaisHandler = async (e) => {
        e.preventDefault();

        var choixFrancais = 'fr';
        var langueStr = document.getElementById('choixlangue');
        langueStr.innerText = choixFrancais;
        i18next.changeLanguage(choixFrancais);
        //display main page
        const accueilheaderDiv = document.getElementById('accueilheader');
        //
        accueilheaderDiv.style.display = 'block';
        //
        navigateAccueil('/accueilintro');
    }

    const AnglaisHandler = async (e) => {
        e.preventDefault();

        var choixAnglais = 'en';
        var langueStr = document.getElementById('choixlangue');
        langueStr.innerText = choixAnglais;
        i18next.changeLanguage(choixAnglais);
        //display main page
        const accueilheaderDiv = document.getElementById('accueilheader');
        //
        accueilheaderDiv.style.display = 'block';
        //
        navigateAccueil('/accueilintro');
    }

    const ItalienHandler = async (e) => {
        e.preventDefault();

        var choixItalien = 'it';
        var langueStr = document.getElementById('choixlangue');
        langueStr.innerText = choixItalien;
        i18next.changeLanguage(choixItalien);
        //display main page
        const accueilheaderDiv = document.getElementById('accueilheader');
        //
        accueilheaderDiv.style.display = 'block';
        //
        navigateAccueil('/accueilintro');
    }
    //end select language

    return ( 
        <DivContainerSlide id="pageprincipal">
            <br/><br/>
            <div style={{display:"none"}}>
                <label id="choixlangue"></label>
                <label id="fonctionuser"></label>
                <label id="resultId"></label>
                <label id="typeservice"></label>
                <label id="typeplan"></label>
            </div>
            <div className="accueil">
                <img className="image_logo" src={image} alt='logo' />
                <div className="slogancontainer">
                    <div style={{fontSize:'18px',marginRight:'15px'}}>{t("slogan")}</div>
                </div>
                <NavLink className="navlink" to="/connectaccount">
                    <BiUserCircle style={{fontSize:'25px', cursor:'pointer'}} />
                </NavLink>
                <BiLike style={{fontSize:'20px', cursor:'pointer'}} onClick={commentHandler} />
                <MdShare style={{fontSize:'20px', cursor:'pointer'}} onClick={shareHandler} />
            </div>
            <div id="accueilheader" className="accueilmenu">
                <div className="scrollermenu">
                    <div style={{marginRight:'15px'}}>
                        <AccueilButton onClick={accueilHandler}>{t("accueil")}</AccueilButton>
                    </div>
                    <div style={{marginRight:'15px'}}>
                        <AccueilButton onClick={carionsHandler}>Carion's ?</AccueilButton>
                    </div>
                    <div style={{marginRight:'15px'}}>
                        <AccueilButton onClick={aproposHandler}>{t("apropos")}</AccueilButton>
                    </div>
                    <div style={{marginRight:'15px'}}>
                        <AccueilButton onClick={portfolioHandler}>{t("portfolio")}</AccueilButton>
                    </div>
                    <div style={{marginRight:'15px'}}>
                        <AccueilButton onClick={servicesHandler}>{t("services")}</AccueilButton>
                    </div>
                    <div style={{marginRight:'15px'}}>
                        <AccueilButton onClick={webHandler}>Web</AccueilButton>
                    </div>
                    <div style={{marginRight:'15px'}}>
                        <AccueilButton onClick={seoHandler}>SEO</AccueilButton>
                    </div>
                    <div style={{marginRight:'15px'}}>
                        <AccueilButton onClick={hostingHandler}>Hosting</AccueilButton>
                    </div>
                    <div style={{marginRight:'15px'}}>
                        <AccueilButton>
                            <NavLink className="navlink" to="/contactme">
                                {t("contact")}
                            </NavLink>
                        </AccueilButton>
                    </div>
                </div>
            </div>
            <label style={{display:"none", color:"#6666ff", marginLeft:'16px'}} id="actionpage"></label>
            <div className="userdetails" style={{width:'40%', display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                <div style={{color:"#ffffff"}} id="usernamedetail"></div>
                <div style={{color:"#ffffff"}} id="codeutilisateur"></div>
            </div>
            <Section>
                <LeftSection>
                    <TranslateContainer>
                        <ButtonLinks id='fr' onClick={FrancaisHandler}>{t("francais")}</ButtonLinks>
                        <ButtonLinks id='en' onClick={AnglaisHandler}>{t("anglais")}</ButtonLinks>
                        <ButtonLinks id='it' onClick={ItalienHandler}>{t("italien")}</ButtonLinks>
                    </TranslateContainer>
                </LeftSection>
            </Section>
            <div style={{width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                <h4 style={{marginLeft:'15px'}}>{t("selectlanguage")}</h4>
            </div>
        </DivContainerSlide>
    );
}

export default Accueil;