import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../../components.css';

import { AlertModal } from "../../../helperfunctions/Helper";
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,
    ReducedFormScrollerWidgetSm, 
    BigButton,
    BackStart,  
} from '../../../global/Styles';

function CrudUserAccount() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertcruduser";
    const elementDropId = "backdropoutalertcruduser";
    const buttonHandler = "ouiHandlerAlertCrudUserBtn";

    const navigateCrudUserAccount = useNavigate();
    const navigateBackCrudUserAccount = useNavigate();

    const inscriptionInfoHandler = async (e) => {
        e.preventDefault();

        navigateBackCrudUserAccount('/infoaccount');
    }

    const inscriptionCreateHandler = async (e) => {
        e.preventDefault();

        navigateCrudUserAccount('/createaccount');
    }

    const inscriptionUpdateHandler = async (e) => {
        e.preventDefault();

        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(!codeutilisateurDiv){
            const pTexteSaisir = t("login_before");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else{
            navigateCrudUserAccount('/editaccount');
        }
    }

    const inscriptionDeleteHandler = async (e) => {
        e.preventDefault();

        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(!codeutilisateurDiv){
            const pTexteSaisir = t("login_before");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else{
            navigateCrudUserAccount('/deleteaccount');
        }
    }

    const goBackInscriptionHandler = async (e) => {
        e.preventDefault();

        navigateBackCrudUserAccount('/connectaccount');
    }

    return ( 
        <DivContainerSlide id="cruduseraccount">
            <br/>
            <div style={{marginTop:'-3rem'}}>
                <SpanGlobalSpan style={{textAlign:'center'}}>
                    <GlobalStrongTitle>{t("crudusertitre")}</GlobalStrongTitle>
                </SpanGlobalSpan>
            </div>
            <ReducedFormScrollerWidgetSm>
                <div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={inscriptionInfoHandler}>{t("crudusergerer")}</BigButton>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={inscriptionCreateHandler}>{t("crudusercreer")}</BigButton>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={inscriptionUpdateHandler}>{t("crudusermodifier")}</BigButton>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={inscriptionDeleteHandler}>{t("crudusersupprimer")}</BigButton>
                    </div>
                </div>
                <br/>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                    <div>
                        <BackStart onClick={goBackInscriptionHandler}>
                            <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                            {t("retour")}
                        </BackStart>
                    </div>
                </div>
                <br/><br/>
                <div id="modaloutalertcruduser"></div>
                <div id="backdropoutalertcruduser"></div>
                <br/><br/>
            </ReducedFormScrollerWidgetSm>
        </DivContainerSlide>
    );
}

export default CrudUserAccount;