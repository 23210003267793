import React from 'react';
import '../../components/components.css';
//
import '../../components/reactquilleditor/myeditor.css';
//import edit contract editor
import ViewContractEditor from './ViewContractEditor';
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,  
} from '../../global/Styles';

function ViewContract() {
    const {t} = useTranslation();

    return (
        <DivContainerSlide>
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t("crudcontenuVisualiser")}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <div className="lescontenus">
                <ViewContractEditor />
                <br/>
            </div>
        </DivContainerSlide>
    )
}

export default ViewContract