import { /*lazy,*/ Suspense } from "react";
import { Layout } from "../layout/Layout";
import { Route, Routes } from 'react-router-dom';
//
import Accueil from "../accueil/Accueil";
//image centrale
import ImageCentrale from "../accueil/ImageCentrale";
import AccueilIntro from "../accueil/AccueilIntro";
import AccueilDetail from "../accueil/AccueilDetail";
import AproposDetail from "../accueil/AproposDetail";
//sharecarions
import ShareCarions from "../accueil/ShareCarions";
//commentcarions
import CommentCarions from "../accueil/CommentCarions";
//1 connectaccount
import ConnectAccount from "../components/account/useraccount/ConnectAccount";
//account folder
import Account from "../components/account/Account";
import CrudUserAccount from "../components/account/useraccount/CrudUserAccount";
import CreateAccount from "../components/account/useraccount/CreateAccount";
//infoaccount
import InfoAccount from "../components/account/useraccount/InfoAccount";
//editaccount
import EditAccount from "../components/account/useraccount/EditAccount";
//deleteaccount
import DeleteAccount from "../components/account/useraccount/DeleteAccount";
//import MainAccount from "../components/account/MainAccount";
//creation contenu
import CrudAccueil from "../components/accueilcompo/CrudAccueil";
import AccueilCreateContenu from "../components/accueilcompo/AccueilCreateContenu";
import MyEditor from "../components/reactquilleditor/MyEditor";
//edit contenu
import AccueilEditContenu from "../components/accueilcompo/AccueilEditContenu";
import EditMyEditor from "../components/reactquilleditor/EditMyEditor";
//delete contenu
import AccueilDeleteContenu from "../components/accueilcompo/AccueilDeleteContenu";
import DeleteMyEditor from "../components/reactquilleditor/DeleteMyEditor";
//view contenu
import AccueilViewContenu from "../components/accueilcompo/AccueilViewContenu";
import ViewMyEditor from "../components/reactquilleditor/ViewMyEditor";
//main accueil
//import MainAccueil from "../components/accueilcompo/MainAccueil";
//service
import CrudServicesCompo from "../components/services/servicescompo/CrudServicesCompo";
//createservice contenu
import ServiceCreateContenu from "../components/services/servicescompo/ServiceCreateContenu";
import CreateServicesEditor from "../components/services/servicesquilleditor/CreateServicesEditor";
//
import ServiceEditContenu from '../components/services/servicescompo/ServiceEditContenu';
import EditServicesEditor from "../components/services/servicesquilleditor/EditServicesEditor";
//
import ServiceDeleteContenu from "../components/services/servicescompo/ServiceDeleteContenu";
import DeleteServiceEditor from "../components/services/servicesquilleditor/DeleteServiceEditor";
//
import ServiceViewContenu from "../components/services/servicescompo/ServiceViewContenu";
import ViewServiceEditor from "../components/services/servicesquilleditor/ViewServiceEditor";
//mainservices
import MainServices from "../components/services/MainServices";
//mainwebservices
import MainWebServices from "../components/services/webservices/MainWebServices";
//websitetab
import WebSiteTab from "../components/services/webservices/WebSiteTab";
//webapptab
import WebAppTab from "../components/services/webservices/WebAppTab";
//ecommercetab
import EcommerceTab from "../components/services/webservices/EcommerceTab";
//mainseoservices
import MainSeoServices from "../components/services/seoservices/MainSeoServices";
//keywordtab
import KeywordTab from "../components/services/seoservices/KeywordTab";
//onpagetab
import OnpageTab from "../components/services/seoservices/OnpageTab";
//technicaltab
import TechnicalTab from "../components/services/seoservices/TechnicalTab";
//monthlytab
import MonthlyTab from "../components/services/seoservices/MonthlyTab";
//serviceshosting
import ServicesHosting from "../admindashboard/services/ServicesHosting";
//mainsoftwareservices
import MainSoftwareServices from "../components/services/softwareservices/MainSoftwareServices";
//softcodingservicestab
import SoftCodingServicesTab from "../components/services/softwareservices/SoftCodingServicesTab";
//softdesignservicestab
import SoftDesignServicesTab from "../components/services/softwareservices/SoftDesignServicesTab";
//softdevelopmentservicestab
import SoftDevelopmentServicesTab from "../components/services/softwareservices/SoftDevelopmentServicesTab";
//softmaintenanceservicestab
import SoftMaintenanceServicesTab from "../components/services/softwareservices/SoftMaintenanceServicesTab";
//softmanagementservicestab
import SoftManagementServicesTab from "../components/services/softwareservices/SoftManagementServicesTab";
//softmiscellaniousservicestab
import SoftMiscellaniousServicesTab from "../components/services/softwareservices/SoftMiscellaniousServicesTab";
//contactme
//proceedweb
import ProceedWeb from "../proceedcompo/peoceedweb/ProceedWeb";
//proceedwebapp
import ProceedWebApp from "../proceedcompo/peoceedweb/ProceedWebApp";
//proceedecom
import ProceedEcom from "../proceedcompo/peoceedweb/ProceedEcom";
//proceedrecherche
import ProceedRecherche from "../proceedcompo/proceedseo/ProceedRecherche";
//proceedoptimization
import ProceedOptimization from "../proceedcompo/proceedseo/ProceedOptimization";
//proceedtechnical
import ProceedTechnical from "../proceedcompo/proceedseo/ProceedTechnical";
//proceedmonthly
import ProceedMonthly from "../proceedcompo/proceedseo/ProceedMonthly";
//proceedcoding
import ProceedCoding from "../proceedcompo/proceedcoding/ProceedCoding";
//proceeddesign
import ProceedDesign from "../proceedcompo/proceedcoding/ProceedDesign";
//proceedwebdev
import ProceedWebDev from "../proceedcompo/proceedcoding/ProceedWebDev";
//proceedmaint
import ProceedMaint from "../proceedcompo/proceedcoding/ProceedMaint";
//proceedmanage
import ProceedManage from "../proceedcompo/proceedcoding/ProceedManage";
//proceedmiscel
import ProceedMiscel from "../proceedcompo/proceedcoding/ProceedMiscel";
//proceedhosting
import ProceedHosting from "../proceedcompo/proceedhosting/ProceedHosting";

//mainadmin
import MainAdmin from "../admindashboard/MainAdmin";
//serviceadmin
import ServiceAdmin from "../admindashboard/ServiceAdmin";
//servicescoding
import ServicesCoding from "../admindashboard/services/ServicesCoding";
//servicesdesign
import ServicesDesign from "../admindashboard/services/ServicesDesign";
//servicesdevelopment
import ServicesDevelopment from "../admindashboard/services/ServicesDevelopment";
//servicesmaintenance
import ServicesMaintenance from "../admindashboard/services/ServicesMaintenance";
//servicesmanagement
import ServicesManagement from "../admindashboard/services/ServicesManagement";
//servicesmiscellanious
import ServicesMiscellanious from "../admindashboard/services/ServicesMiscellanious";
//servicewebdevelpment
import ServicesWebDevelopment from "../admindashboard/ServicesWebDevelopment";
//servicewebsite
import ServicesWebSite from "../admindashboard/services/ServicesWebSite";
//servicesapp
import ServicesApp from "../admindashboard/services/ServicesApp";
//servicesecommerce
import ServicesEcommerce from "../admindashboard/services/ServicesEcommerce";
//servicesseoservice
import ServicesSeoService from "../admindashboard/ServicesSeoService";
//serviceskeywords
import ServicesKeywords from "../admindashboard/services/ServicesKeywords";
//servicesonpage
import ServicesOnPage from "../admindashboard/services/ServicesOnPage";
//servicestechnical
import ServicesTechnical from "../admindashboard/services/ServicesTechnical";
//servicesmonthly
import ServicesMonthly from "../admindashboard/services/ServicesMonthly";
//contractadmin
import ContractAdmin from "../admindashboard/contract/ContractAdmin";
//createcontract
import CreateContract from "../admindashboard/contract/CreateContract";
//editcontract
import EditContract from "../admindashboard/contract/EditContract";
//deletecontract
import DeleteContract from "../admindashboard/contract/DeleteContract";
//viewcontract
import ViewContract from "../admindashboard/contract/ViewContract";

import ContactMe from "../components/contactme/ContactMe";
//Footer
import Footer from "../footer/Footer";
import NoMatch from "../components/NoMatch";

function Home(){
    return(
        <>
            <Layout>
                <Accueil />
                <Suspense>
                    <Routes>
                        <Route path="/" element={<ImageCentrale/>} />
                        <Route path="/accueilintro" element={<AccueilIntro />} />
                        <Route path="/sharecarions" element={<ShareCarions />} />
                        <Route path="/commentcarions" element={<CommentCarions />} />
                        <Route path="/accueildetail" element={<AccueilDetail />} />
                        <Route path="/aproposdetail" element={<AproposDetail />} />
                        <Route path="/connectaccount" element={<ConnectAccount />} />
                        <Route path="/account" element={<Account />}></Route>
                        <Route path="/cruduseraccount" element={<CrudUserAccount />} />
                        <Route path="/createaccount" element={<CreateAccount />} />
                        <Route path="/infoaccount" element={<InfoAccount />} />
                        <Route path="/editaccount" element={<EditAccount />} />
                        <Route path="/deleteaccount" element={<DeleteAccount />} />
                        <Route path="/crudaccueil" element={<CrudAccueil />} />
                        <Route path="/accueilcreatecontenu" element={<AccueilCreateContenu />} />
                        <Route path="/myeditor" element={<MyEditor />} />
                        <Route path="accueileditcontenu" element={<AccueilEditContenu />} />
                        <Route path="/editmyeditor" element={<EditMyEditor />} />
                        <Route path="/accueildeletecontenu" element={<AccueilDeleteContenu />} />
                        <Route path="/deletemyeditor" element={<DeleteMyEditor />} />
                        <Route path="/accueilviewcontenu" element={<AccueilViewContenu />} />
                        <Route path="/viewmyeditor" element={<ViewMyEditor />} />
                        <Route path="/crudservicescompo" element={<CrudServicesCompo />} />
                        <Route path="/servicecreatecontenu" element={<ServiceCreateContenu />} />
                        <Route path="/createserviceseditor" element={<CreateServicesEditor />} />
                        <Route path="/serviceeditcontenu" element={<ServiceEditContenu />} />
                        <Route path="/editserviceseditor" element={<EditServicesEditor />} />
                        <Route path="/servicedeletecontenu" element={<ServiceDeleteContenu />} />
                        <Route path="/servicedeleteeditor" element={<DeleteServiceEditor />} />
                        <Route path="/serviceviewcontenu" element={<ServiceViewContenu />} />
                        <Route path="/viewserviceseditor" element={<ViewServiceEditor />} />
                        
                        <Route path="/mainservices" element={<MainServices />} />
                        <Route path="/mainwebservices" element={<MainWebServices />} />
                        <Route path="/websitetab" element={<WebSiteTab />} />
                        <Route path="/webapptab" element={<WebAppTab />} />
                        <Route path="/ecommercetab" element={<EcommerceTab />} />

                        <Route path="/mainseoservices" element={<MainSeoServices />} />
                        <Route path="/keywordtab" element={<KeywordTab />} />
                        <Route path="/onpagetab" element={<OnpageTab />} />
                        <Route path="/technicaltab" element={<TechnicalTab />} />
                        <Route path="/monthlytab" element={<MonthlyTab />} />

                        <Route path="/mainsoftwareservices" element={<MainSoftwareServices />} />
                        <Route path="/softcodingservicestab" element={<SoftCodingServicesTab />} />
                        <Route path="/softdesignservicestab" element={<SoftDesignServicesTab />} />
                        <Route path="/softdevelopmentservicestab" element={<SoftDevelopmentServicesTab />} />
                        <Route path="/softmaintenanceservicestab" element={<SoftMaintenanceServicesTab />} />
                        <Route path="/softmanagementservicestab" element={<SoftManagementServicesTab />} />
                        <Route path="/softmiscellaniousservicestab" element={<SoftMiscellaniousServicesTab />} />
                        <Route path="/servicesmiscellanious" element={<ServicesMiscellanious />} />

                        <Route path="/servicewebdevelpment" element={<ServicesWebDevelopment />} />
                        <Route path="/servicewebsite" element={<ServicesWebSite />} />
                        <Route path="/servicesapp" element={<ServicesApp />} />
                        <Route path="/servicesecommerce" element={<ServicesEcommerce />} />

                        <Route path="/servicesseoservice" element={<ServicesSeoService />} />
                        <Route path="/serviceskeywords" element={<ServicesKeywords />} />
                        <Route path="/servicesonpage" element={<ServicesOnPage />} />
                        <Route path="/servicestechnical" element={<ServicesTechnical />} />
                        <Route path="/servicesmonthly" element={<ServicesMonthly />} />

                        <Route path="/proceedweb" element={<ProceedWeb />} />
                        <Route path="/proceedwebapp" element={<ProceedWebApp />} />
                        <Route path="/proceedecom" element={<ProceedEcom />} />

                        <Route path="/proceedrecherche" element={<ProceedRecherche />} />
                        <Route path="/proceedoptimization" element={<ProceedOptimization />} />
                        <Route path="/proceedtechnical" element={<ProceedTechnical />} />
                        <Route path="/proceedmonthly" element={<ProceedMonthly />} />

                        <Route path="/serviceshosting" element={<ServicesHosting />} />

                        <Route path="/proceedcoding" element={<ProceedCoding />} />
                        <Route path="/proceeddesign" element={<ProceedDesign />} />
                        <Route path="/proceedwebdev" element={<ProceedWebDev />} />
                        <Route path="/proceedmaint" element={<ProceedMaint />} />
                        <Route path="/proceedmanage" element={<ProceedManage />} />
                        <Route path="/proceedmiscel" element={<ProceedMiscel />} />
                        <Route path="/proceedhosting" element={<ProceedHosting />} />

                        <Route path="/mainadmin" element={<MainAdmin />} />
                        <Route path="/serviceadmin" element={<ServiceAdmin />} />
                        <Route path="/servicescoding" element={<ServicesCoding />} />
                        <Route path="/servicesdesign" element={<ServicesDesign />} />
                        <Route path="/servicesdevelopment" element={<ServicesDevelopment />} />
                        <Route path="/servicesmaintenance" element={<ServicesMaintenance />} />
                        <Route path="/servicesmanagement" element={<ServicesManagement />} />

                        <Route path="/contractadmin" element={<ContractAdmin />} />
                        <Route path="/createcontract" element={<CreateContract />} />
                        <Route path="/editcontract" element={<EditContract />} />
                        <Route path="/deletecontract" element={<DeleteContract />} />
                        <Route path="/viewcontract" element={<ViewContract />} />

                        <Route path="/contactme" element={<ContactMe />} />
                        
                        <Route path="*" element={<NoMatch />} />
                    </Routes>
                </Suspense>
                <Footer />
            </Layout> 
        </>
    )
}

export default Home;