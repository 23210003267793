import React from "react";
import { useNavigate } from "react-router-dom";
import './accueil.css';
import leftRightImage from '../carions-icon-192.png';
//CommentCard component;
import CommentCard from "../components/cardcomponents/CommentCard";
//
import { useTranslation } from 'react-i18next';
import '../translations/i18n';
import '../translations/Translation';

function AccueilIntro() {
    const {t} = useTranslation();

    const navigateAccueilIntro = useNavigate();

    const webProjectsHandler = async (e) => {
        e.preventDefault();

        navigateAccueilIntro('/mainservices');
    }

    const webSkillsHandler = async (e) => {
        e.preventDefault();

        navigateAccueilIntro('/mainsoftwareservices');
    }

    return ( 
        <div style={{marginTop:'-6rem'}} id="accueilintro">
            <header id="theheader">
                <div className="container header__container">
                    <div className="header__left">
                        <h2>{t("accueil_titre")}</h2>
                        <p>{t("accueil_content")}</p>
                        <div style={{display:'flex', flexDirection:'row',  gap:'1rem'}}>
                            <button className="btn btn-primary" onClick={webProjectsHandler}>{t("accueil_project")}</button>
                            <button className="btn btn-primary" onClick={webSkillsHandler}>{t("accueil_skills")}</button>
                        </div>
                    </div>
                    <div className="header__right">
                        <div className="header__right-image">
                            <img src={leftRightImage} alt="" />
                        </div>
                    </div>
                </div>
            </header>
            <CommentCard />
        </div>
    );
}

export default AccueilIntro;