import React from "react-dom";
import { useNavigate } from "react-router-dom";
import './services.css';
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,
    FormScrollerWidgetSm, 
    BackStart,  
    HandlerContainerDiv,
    BigButton,
} from '../../global/Styles';

function MainServices() {
    const {t} = useTranslation();

    const navigateMainServices = useNavigate();

    const webDevelopmentHandler = async (e) => {
        e.preventDefault();

        navigateMainServices('/mainwebservices');
    }

    const seoConsultingHandler = async (e) => {
        e.preventDefault();

        navigateMainServices('/mainseoservices');
    }

    const softDevServiceHandler = async (e) => {
        e.preventDefault();

        navigateMainServices('/mainsoftwareservices');
    }

    const hostingHandler = async (e) => {
        e.preventDefault();

        navigateMainServices('/proceedhosting');
    }

    const goBackServiceHandler = async (e) => {
        e.preventDefault();

        navigateMainServices('/');
    }

    return ( 
        <DivContainerSlide id="mainservices">
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t("servicetitre")}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <FormScrollerWidgetSm>
                <div className="connectaccountstyle">
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={webDevelopmentHandler}>{t("webdevelopment")}</BigButton>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={seoConsultingHandler}>{t("seoconsulting")}</BigButton>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={softDevServiceHandler}>{t("softdevservice")}</BigButton>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <BigButton onClick={hostingHandler}>{t("hosting")}</BigButton>
                    </div>

                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackServiceHandler}>
                                    <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                    {t("retour")}
                                </BackStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>
            </FormScrollerWidgetSm>
            <br/><br/>
        </DivContainerSlide>
    );
}

export default MainServices;