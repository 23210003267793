import React from "react-dom";
import { useNavigate } from "react-router-dom";
import '../proceedweb.css';
import { useRef, useState } from "react";
//
import { MdOutlineCreate, MdSettingsBackupRestore, MdOutlineArrowForwardIos, MdOutlineArrowBackIos, MdOutlineAttachEmail } from 'react-icons/md';
import { AiOutlineWhatsApp } from 'react-icons/ai';
//
import { AlertModal, getCurrentDate, PopupModal } from "../../helperfunctions/Helper";
//
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import '../../translations/Translation';
import { 
    SpanGlobalSpan,
    GlobalStrongTitle,
    LongFormScrollerWidgetSm,
    TextProceedService, 
    TextLoginService,
    GlobalLargeInput, 
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
    TextArea,
} from "../../global/Styles";

import Axios from 'axios';

function ProceedCoding() {
    const {t} = useTranslation();

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleCodingChange = event => {
        if (!isValidEmail(event.target.value)) {
          setError(t('invalidemail'));
        } else {
          setError(null);
        }
    
        setEmail(event.target.value);
    };

    const navigateProceedCoding = useNavigate();

    //alert
    const elementOutId = "modaloutproceedcoding";
    const elementDropId = "backdropoutproceedcoding";
    const buttonHandler = "ouiHandlerProceedCodingBtn";
    //
    const elementOutAlertId = "modaloutalertproceedcoding";
    const elementDropAlertId = "backdropoutalertproceedcoding";
    const buttonAlertHandler = "ouiHandleralertProceedCodingBtn";

    const codingQuestion1Ref = useRef(null);
    const codingQuestion2Ref = useRef(null);
    const codingQuestion3Ref = useRef(null);
    //
    const codingQuestion4Ref = useRef(null);
    const codingQuestion5Ref = useRef(null);
    const codingQuestion6Ref = useRef(null);
    //
    const codingQuestion7Ref = useRef(null);
    const codingQuestion8Ref = useRef(null);
    const codingQuestion9Ref = useRef(null);
    //
    const codingQuestion10Ref = useRef(null);
    const codingQuestion11Ref = useRef(null);
    const codingQuestion12Ref = useRef(null);
    //
    const codingQuestion13Ref = useRef(null);
    const codingQuestion14Ref = useRef(null);

    const popcoding1Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("codingquestion_1");
        const pTexteNotAvailable = t("hintcodingquestion_1");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popcoding2Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("codingquestion_2");
        const pTexteNotAvailable = t("hintcodingquestion_2");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popcoding3Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("codingquestion_3");
        const pTexteNotAvailable = t("hintcodingquestion_3");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const popcoding4Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("codingquestion_4");
        const pTexteNotAvailable = t("hintcodingquestion_4");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popcoding5Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("codingquestion_5");
        const pTexteNotAvailable = t("hintcodingquestion_5");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popcoding6Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("codingquestion_6");
        const pTexteNotAvailable = t("hintcodingquestion_6");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const popcoding7Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("codingquestion_7");
        const pTexteNotAvailable = t("hintcodingquestion_7");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popcoding8Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("codingquestion_8");
        const pTexteNotAvailable = t("hintcodingquestion_8");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popcoding9Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("codingquestion_9");
        const pTexteNotAvailable = t("hintcodingquestion_9");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const popcoding10Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("codingquestion_10");
        const pTexteNotAvailable = t("hintcodingquestion_10");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popcoding11Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("codingquestion_11");
        const pTexteNotAvailable = t("hintcodingquestion_11");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }
    //
    const popcoding12Handler = async (e) => {
        e.preventDefault();

        const hTitleTexte = t("codingquestion_12");
        const pTexteNotAvailable = t("hintcodingquestion_12");
        PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
    }

    const forwardProceedCoding1Handler = async (e) => {
        e.preventDefault();

        const codingsteponeDiv = document.getElementById('codingstepone');
        const codingsteptwoDiv = document.getElementById('codingsteptwo');
        const codingstepthreeDiv = document.getElementById('codingstepthree');
        const codingstepfourDiv = document.getElementById('codingstepfour');
        const modeproceedcodingDiv = document.getElementById('modeproceedcoding');
        const submitproceedcodingDiv = document.getElementById('submitproceedcoding');
        //
        codingsteponeDiv.style.display = 'none';
        codingsteptwoDiv.style.display = 'block';
        codingstepthreeDiv.style.display = 'none';
        codingstepfourDiv.style.display = 'none';
        modeproceedcodingDiv.style.display = 'none';
        submitproceedcodingDiv.style.display = 'none';
    }

    const goBackProceedCoding2Handler = async (e) => {
        e.preventDefault();

        const codingsteponeDiv = document.getElementById('codingstepone');
        const codingsteptwoDiv = document.getElementById('codingsteptwo');
        const codingstepthreeDiv = document.getElementById('codingstepthree');
        const codingstepfourDiv = document.getElementById('codingstepfour');
        const modeproceedcodingDiv = document.getElementById('modeproceedcoding');
        const submitproceedcodingDiv = document.getElementById('submitproceedcoding');
        //
        codingsteponeDiv.style.display = 'block';
        codingsteptwoDiv.style.display = 'none';
        codingstepthreeDiv.style.display = 'none';
        codingstepfourDiv.style.display = 'none';
        modeproceedcodingDiv.style.display = 'none';
        submitproceedcodingDiv.style.display = 'none';
    }
    //
    const forwardProceedCoding2Handler = async (e) => {
        e.preventDefault();

        const codingsteponeDiv = document.getElementById('codingstepone');
        const codingsteptwoDiv = document.getElementById('codingsteptwo');
        const codingstepthreeDiv = document.getElementById('codingstepthree');
        const codingstepfourDiv = document.getElementById('codingstepfour');
        const modeproceedcodingDiv = document.getElementById('modeproceedcoding');
        const submitproceedcodingDiv = document.getElementById('submitproceedcoding');
        //
        codingsteponeDiv.style.display = 'none';
        codingsteptwoDiv.style.display = 'none';
        codingstepthreeDiv.style.display = 'block';
        codingstepfourDiv.style.display = 'none';
        modeproceedcodingDiv.style.display = 'none';
        submitproceedcodingDiv.style.display = 'none';
    }

    const goBackProceedCoding3Handler = async (e) => {
        e.preventDefault();

        const codingsteponeDiv = document.getElementById('codingstepone');
        const codingsteptwoDiv = document.getElementById('codingsteptwo');
        const codingstepthreeDiv = document.getElementById('codingstepthree');
        const codingstepfourDiv = document.getElementById('codingstepfour');
        const modeproceedcodingDiv = document.getElementById('modeproceedcoding');
        const submitproceedcodingDiv = document.getElementById('submitproceedcoding');
        //
        codingsteponeDiv.style.display = 'none';
        codingsteptwoDiv.style.display = 'block';
        codingstepthreeDiv.style.display = 'none';
        codingstepfourDiv.style.display = 'none';
        modeproceedcodingDiv.style.display = 'none';
        submitproceedcodingDiv.style.display = 'none';
    }
    //
    const forwardProceedCoding3Handler = async (e) => {
        e.preventDefault();

        const codingsteponeDiv = document.getElementById('codingstepone');
        const codingsteptwoDiv = document.getElementById('codingsteptwo');
        const codingstepthreeDiv = document.getElementById('codingstepthree');
        const codingstepfourDiv = document.getElementById('codingstepfour');
        const modeproceedcodingDiv = document.getElementById('modeproceedcoding');
        const submitproceedcodingDiv = document.getElementById('submitproceedcoding');
        //
        codingsteponeDiv.style.display = 'none';
        codingsteptwoDiv.style.display = 'none';
        codingstepthreeDiv.style.display = 'none';
        codingstepfourDiv.style.display = 'block';
        modeproceedcodingDiv.style.display = 'none';
        submitproceedcodingDiv.style.display = 'none';
    }

    const goBackProceedCoding4Handler = async (e) => {
        e.preventDefault();

        const codingsteponeDiv = document.getElementById('codingstepone');
        const codingsteptwoDiv = document.getElementById('codingsteptwo');
        const codingstepthreeDiv = document.getElementById('codingstepthree');
        const codingstepfourDiv = document.getElementById('codingstepfour');
        const modeproceedcodingDiv = document.getElementById('modeproceedcoding');
        const submitproceedcodingDiv = document.getElementById('submitproceedcoding');
        //
        codingsteponeDiv.style.display = 'none';
        codingsteptwoDiv.style.display = 'none';
        codingstepthreeDiv.style.display = 'block';
        codingstepfourDiv.style.display = 'none';
        modeproceedcodingDiv.style.display = 'none';
        submitproceedcodingDiv.style.display = 'none';
    }
    //
    const forwardProceedCoding4Handler = async (e) => {
        e.preventDefault();

        const codingsteponeDiv = document.getElementById('codingstepone');
        const codingsteptwoDiv = document.getElementById('codingsteptwo');
        const codingstepthreeDiv = document.getElementById('codingstepthree');
        const codingstepfourDiv = document.getElementById('codingstepfour');
        const modeproceedcodingDiv = document.getElementById('modeproceedcoding');
        const submitproceedcodingDiv = document.getElementById('submitproceedcoding');
        //
        codingsteponeDiv.style.display = 'none';
        codingsteptwoDiv.style.display = 'none';
        codingstepthreeDiv.style.display = 'none';
        codingstepfourDiv.style.display = 'none';
        modeproceedcodingDiv.style.display = 'block';
        submitproceedcodingDiv.style.display = 'none';
    }

    const goBackProceedCoding5Handler = async (e) => {
        e.preventDefault();

        const codingsteponeDiv = document.getElementById('codingstepone');
        const codingsteptwoDiv = document.getElementById('codingsteptwo');
        const codingstepthreeDiv = document.getElementById('codingstepthree');
        const codingstepfourDiv = document.getElementById('codingstepfour');
        const modeproceedcodingDiv = document.getElementById('modeproceedcoding');
        const submitproceedcodingDiv = document.getElementById('submitproceedcoding');
        //
        codingsteponeDiv.style.display = 'none';
        codingsteptwoDiv.style.display = 'none';
        codingstepthreeDiv.style.display = 'none';
        codingstepfourDiv.style.display = 'block';
        modeproceedcodingDiv.style.display = 'none';
        submitproceedcodingDiv.style.display = 'none';
    }
    //
    const forwardProceedCoding5Handler = async (e) => {
        e.preventDefault();

        const codingQuestion13 = codingQuestion13Ref.current.value;
        const codingQuestion14 = codingQuestion14Ref.current.value;
        //
        const codingsteponeDiv = document.getElementById('codingstepone');
        const codingsteptwoDiv = document.getElementById('codingsteptwo');
        const codingstepthreeDiv = document.getElementById('codingstepthree');
        const codingstepfourDiv = document.getElementById('codingstepfour');
        const modeproceedcodingDiv = document.getElementById('modeproceedcoding');
        const submitproceedcodingDiv = document.getElementById('submitproceedcoding');
        //
        if(!codingQuestion13){
            const pTexteSaisir = t("enteremail");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else if(!codingQuestion14){
            const pTexteSaisir = t("enterphone");
            AlertModal(elementOutAlertId,elementDropAlertId,pTexteSaisir,buttonAlertHandler);
        }else{
            //
            codingsteponeDiv.style.display = 'none';
            codingsteptwoDiv.style.display = 'none';
            codingstepthreeDiv.style.display = 'none';
            codingstepfourDiv.style.display = 'none';
            modeproceedcodingDiv.style.display = 'none';
            submitproceedcodingDiv.style.display = 'block';
        }
    }

    const goBackProceedCoding6Handler = async (e) => {
        e.preventDefault();

        const codingsteponeDiv = document.getElementById('codingstepone');
        const codingsteptwoDiv = document.getElementById('codingsteptwo');
        const codingstepthreeDiv = document.getElementById('codingstepthree');
        const codingstepfourDiv = document.getElementById('codingstepfour');
        const modeproceedcodingDiv = document.getElementById('modeproceedcoding');
        const submitproceedcodingDiv = document.getElementById('submitproceedcoding');
        //
        codingsteponeDiv.style.display = 'none';
        codingsteptwoDiv.style.display = 'none';
        codingstepthreeDiv.style.display = 'none';
        codingstepfourDiv.style.display = 'none';
        modeproceedcodingDiv.style.display = 'block';
        submitproceedcodingDiv.style.display = 'none';
    }

    //http://localhost:5000/check/codingservice

    const submitProceedCodingHandler = async (e) => {
        e.preventDefault();

        const codingQuestion1 = codingQuestion1Ref.current.value;
        const codingQuestion2 = codingQuestion2Ref.current.value;
        const codingQuestion3 = codingQuestion3Ref.current.value;
        //websteptwo
        const codingQuestion4 = codingQuestion4Ref.current.value;
        const codingQuestion5 = codingQuestion5Ref.current.value;
        const codingQuestion6 = codingQuestion6Ref.current.value;
        //webstepthree
        const codingQuestion7 = codingQuestion7Ref.current.value;
        const codingQuestion8 = codingQuestion8Ref.current.value;
        const codingQuestion9 = codingQuestion9Ref.current.value;
        //webstepfour
        const codingQuestion10 = codingQuestion10Ref.current.value;
        const codingQuestion11 = codingQuestion11Ref.current.value;
        const codingQuestion12 = codingQuestion12Ref.current.value;
        //modeproceedweb
        const codingQuestion13 = codingQuestion13Ref.current.value;
        const codingQuestion14 = codingQuestion14Ref.current.value;
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const theDate = getCurrentDate();
        //
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = "coding";
        const servicePlan = document.getElementById('typeplan').innerText;
        var createTicketCode = "";
        //
        var resultat1 = "";
        //
        //generate random ticket code
        var randomNumber = Math.floor((Math.random() * 100000) + 1);
        createTicketCode = randomNumber+''+codeutilisateurDiv;
        //
        await Axios.post("https://micarionsapi.carions.net/check/codingservice", {
            codeutilisateurDiv:codeutilisateurDiv,
            typeService:typeService,
            servicePlan:servicePlan,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                //insert
                Axios.post("https://micarionsapi.carions.net/quest/codingservice", {
                    choixlangueDiv:choixlangueDiv,
                    usernamedetailDiv:usernamedetailDiv,
                    codeutilisateurDiv:codeutilisateurDiv,
                    //
                    typeService:typeService,
                    servicePlan:servicePlan,
                    //
                    createTicketCode:createTicketCode,
                    //
                    codingQuestion1:codingQuestion1,
                    codingQuestion2:codingQuestion2,
                    codingQuestion3:codingQuestion3,
                    //
                    codingQuestion4:codingQuestion4,
                    codingQuestion5:codingQuestion5,
                    codingQuestion6:codingQuestion6,
                    //
                    codingQuestion7:codingQuestion7,
                    codingQuestion8:codingQuestion8,
                    codingQuestion9:codingQuestion9,
                    //
                    codingQuestion10:codingQuestion10,
                    codingQuestion11:codingQuestion11,
                    codingQuestion12:codingQuestion12,
                    //
                    codingQuestion13:codingQuestion13,
                    codingQuestion14:codingQuestion14,
                    //
                    theDate:theDate,
                }).then(() => {
                    var answer1 = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                    const hTitleTexte = t("proceedcodingtitle");
                    const pTexteNotAvailable = answer1;
                    PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
                });
            }else{
                //set up modal to update
                var answer = `${t("responsequestion_1")}<br/><br/>${t("responsequestion_2")}`;
                const hTitleTexte = t("proceedcodingtitle");
                const pTexteNotAvailable = answer;
                PopupModal(elementOutId, elementDropId, hTitleTexte, pTexteNotAvailable,buttonHandler);
            }
        });
    }

    const goBackProceedCodingHandler = async (e) => {
        e.preventDefault();

        navigateProceedCoding('/softcodingservicestab');
    }

    return ( 
        <div id="proceedcoding">
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t('software_coding')}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <LongFormScrollerWidgetSm>
                <div id="modaloutproceedcoding"></div>
                <div id="backdropoutproceedcoding"></div>
                <p className="pservicestyles"><br/>{t("proceedcodingdescription")}</p>
                <div id="codingstepone">
                    <TextProceedService onClick={popcoding1Handler}>{t("codingquestion_1")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={codingQuestion1Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popcoding2Handler}>{t("codingquestion_2")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={codingQuestion2Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popcoding3Handler}>{t("codingquestion_3")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={codingQuestion3Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <ButtonStart onClick={forwardProceedCoding1Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="codingsteptwo">
                    <TextProceedService onClick={popcoding4Handler}>{t("codingquestion_4")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={codingQuestion4Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popcoding5Handler}>{t("codingquestion_5")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={codingQuestion5Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popcoding6Handler}>{t("codingquestion_6")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={codingQuestion6Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedCoding2Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedCoding2Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="codingstepthree">
                    <TextProceedService onClick={popcoding7Handler}>{t("codingquestion_7")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={codingQuestion7Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popcoding8Handler}>{t("codingquestion_8")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={codingQuestion8Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popcoding9Handler}>{t("codingquestion_9")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={codingQuestion9Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedCoding3Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedCoding3Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="codingstepfour">
                    <TextProceedService onClick={popcoding10Handler}>{t("codingquestion_10")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={codingQuestion10Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popcoding11Handler}>{t("codingquestion_11")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={codingQuestion11Ref}></TextArea>
                    </div>
                    <TextProceedService onClick={popcoding12Handler}>{t("codingquestion_12")}</TextProceedService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <TextArea ref={codingQuestion12Ref}></TextArea>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedCoding4Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedCoding4Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="modeproceedcoding">
                    <TextProceedService>{t("webquestion_13")}</TextProceedService>
                    <TextLoginService><MdOutlineAttachEmail style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput id="emailcodin" name="emailcodin" value={email} onChange={handleCodingChange} ref={codingQuestion13Ref} placeholder={t("placemail")}/>
                        {error && <h5 style={{color: 'white'}}>{error}</h5>}
                    </div>
                    <br/>
                    <TextLoginService><AiOutlineWhatsApp style={{fontSize:'30px', color:'white'}} /></TextLoginService>
                    <div style={{display:'block',textAlign:'center'}}>
                            <GlobalLargeInput type='number' ref={codingQuestion14Ref}  placeholder={t("placewhatsapp")}/>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedCoding5Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={forwardProceedCoding5Handler}>
                                    <MdOutlineArrowForwardIos style={{fontSize:'30px'}} />
                                    {t("suivant")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>

                <div id="submitproceedcoding">
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <BackStart onClick={goBackProceedCoding6Handler}>
                                    <MdOutlineArrowBackIos style={{fontSize:'30px'}} />
                                    {t("precedent")}
                                </BackStart>
                            </div>
                            <div>
                                <ButtonStart onClick={submitProceedCodingHandler}>
                                    <MdOutlineCreate style={{fontSize:'30px'}} />
                                   {t("creer")}
                                </ButtonStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                    <br/><br/>
                </div>
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackProceedCodingHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertproceedcoding"></div>
                <div id="backdropoutalertproceedcoding"></div>
            </LongFormScrollerWidgetSm>
        </div>
    );
}

export default ProceedCoding;