import React from "react-dom";
import { useNavigate } from "react-router-dom";
//build tabs tools
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { AlertModal } from "../../../helperfunctions/Helper";
//
import '../services.css';
//
import {MdOutlineConfirmationNumber, MdSettingsBackupRestore,} from 'react-icons/md';
//
import { HiOutlineClock } from 'react-icons/hi';
import { TbRefresh } from 'react-icons/tb';

import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';

import { 
    TextLoginService, 
    LargeSelect,
    TabsForm, 
    DivTabRowAround,
    DivTabRowBetween,
    PTab10,
    DivTabColumnAround,
    PTab10Bt0,
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
} from "../../../global/Styles";
//
import { useRef, useState } from "react";
import Axios from 'axios';

function ViewServiceEditor() {
    const {t} = useTranslation();

    const navigateViewServiceEditor = useNavigate();

    //alert
    const elementOutId = "modaloutalertserviceseditorview";
    const elementDropId = "backdropoutalertserviceseditorview";
    const buttonHandler = "ouiHandlerAlertServicesEditorViewBtn";

    const [valueView, setValueView] = useState([0]);

    const viewSelectTypeServicesContenuRef = useRef(null);
    const viewSelectPlanServicesContenuRef = useRef(null);
    const viewSelectStatusServicesContenuRef = useRef(null);

    //http://localhost:5000/view/service/confirm

    const confirmViewServicesEditorHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const fonctionuserDiv = document.getElementById('fonctionuser').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        //
        const viewviewservicecontenuDiv = document.getElementById('viewviewservicecontenu');
        const viewparagraphDiv = document.getElementById('viewparagraph');
        const viewpanelDiv = document.getElementById('viewpanel');

        const viewSelectTypeServicesContenu = viewSelectTypeServicesContenuRef.current.value;
        const viewSelectPlanServicesContenu = viewSelectPlanServicesContenuRef.current.value;
        const viewSelectStatusServicesContenu = viewSelectStatusServicesContenuRef.current.value;
        var resultat1 = "";

        if(!viewSelectTypeServicesContenu){
            const pTexteSaisir = t("type_contenu");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
            //
            viewviewservicecontenuDiv.style.display = 'none';
        }else if(!viewSelectPlanServicesContenu){
            const pTexteSaisir = t("titre_plan");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
            //
            viewviewservicecontenuDiv.style.display = 'none';
        }else if(!viewSelectStatusServicesContenu){
            const pTexteSaisir = t("select_status");
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
            //
            viewviewservicecontenuDiv.style.display = 'none';
        }else{
            viewviewservicecontenuDiv.style.display = 'block';
            await Axios.post("https://micarionsapi.carions.net/view/service/confirm", {
                choixlangueDiv:choixlangueDiv,
                fonctionuserDiv:fonctionuserDiv,
                codeutilisateurDiv:codeutilisateurDiv,
                viewSelectTypeServicesContenu:viewSelectTypeServicesContenu,
                viewSelectPlanServicesContenu:viewSelectPlanServicesContenu,
                viewSelectStatusServicesContenu:viewSelectStatusServicesContenu,
            }).then((response1) => {
                resultat1 = response1.data.length;
                if(resultat1 === 0){
                    const pTexteNotAvailable = t("noteindisponible");
                    AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                    //
                    viewviewservicecontenuDiv.style.display = 'none';
                }else{
                    setValueView(response1.data);
                    //
                    viewviewservicecontenuDiv.style.display = 'block';
                    viewparagraphDiv.style.display = 'block';
                    viewpanelDiv.style.display = 'block';
                }
            });
        }
    }

    const goBackViewServicesEditorHandler = async (e) => {
        e.preventDefault();

        navigateViewServiceEditor('/crudservicescompo');
    }

    return ( 
        <div id="viewserviceseditor">
            <div style={{overflowY:'scroll'}} id="viewservicestitreeditor">
                <br/>
                <TextLoginService style={{color:'white'}}>{t("typecontenu")}<strong style={{color:'white'}}>*</strong></TextLoginService>
                <div style={{display:'block',textAlign:'center'}}>
                    <LargeSelect ref={viewSelectTypeServicesContenuRef}>
                        <option value=''>{t("typecontenu")}</option>
                        <option value='web'>Web Development</option>
                        <option value='app'>App Development</option>
                        <option value='ecom'>E-Commerce Development</option>
                        <option value='keywordseo'>Keyword Research SEO</option>
                        <option value='onpageseo'>On-Page Optimization SEO</option>
                        <option value='technicalseo'>Technical SEO Audit</option>
                        <option value='monthlyseo'>Monthly Reporting SEO</option>
                        <option value='codingsoft'>Software Coding Services</option>
                        <option value='designsoft'>Software Design Services</option>
                        <option value='devsoft'>Software Development Services</option>
                        <option value='maintsoft'>Software Maintenance Services</option>
                        <option value='managesoft'>Software Management Services</option>
                        <option value='miscsoft'>Software Miscellanious Services</option>
                        <option value='hosting'>Hosting</option>
                        <option value='rental'>Rental Apps</option>
                        <option value='purchase'>Purchase Apps</option>
                        <option value='training'>Training</option>
                    </LargeSelect>
                </div>
                <TextLoginService style={{color:'white'}}>{t("type_plan")}<strong style={{color:'white'}}>*</strong></TextLoginService>
                <div style={{display:'block',textAlign:'center'}}>
                    <LargeSelect ref={viewSelectPlanServicesContenuRef}>
                        <option value=''>{t("select_plan")}</option>
                        <option value='basic'>{t("basic_plan")}</option>
                        <option value='standard'>{t("standard_plan")}</option>
                        <option value='Premium'>{t("premium_plan")}</option>
                    </LargeSelect>
                </div>
                <TextLoginService style={{color:'white'}}>{t("status_contenu")}<strong style={{color:'white'}}>*</strong></TextLoginService>
                <div style={{display:'block',textAlign:'center'}}>
                    <LargeSelect ref={viewSelectStatusServicesContenuRef}>
                        <option value=''>{t("select_status")}</option>
                        <option value='online'>Online</option>
                        <option value='pause'>Pause</option>
                    </LargeSelect>
                </div>
                <br/>
                <HandlerContainerDiv>
                    <div>
                        <ButtonStart onClick={confirmViewServicesEditorHandler}>
                            <MdOutlineConfirmationNumber style={{fontSize:'30px'}} />
                            {t("confirmer")}
                        </ButtonStart>
                    </div>
                    <div>
                        <BackStart onClick={goBackViewServicesEditorHandler}>
                            <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                            {t("retour")}
                        </BackStart>
                    </div>
                </HandlerContainerDiv>
                <br/>
                <div id="viewviewservicecontenu">
                    <TabsForm>
                        {valueView.map((valview, indexview) => (
                            <div key={indexview} className="tabstyles">
                                <div id="viewparagraph">
                                    <PTab10>
                                        {valview.services_paragraphone}<br/>
                                        {valview.services_paragraphtwo}<br/>
                                        {valview.services_paragraphthree}<br/>
                                        {valview.services_paragraphfour}<br/>
                                    </PTab10>
                                </div>
                                <Tabs defaultIndex={0}>
                                    <TabList style={{display:'block'}}>
                                        <Tab>{valview.services_subgroup}</Tab>
                                    </TabList>
                                    <div id="viewpanel">
                                        <TabPanel >
                                            <DivTabRowBetween>
                                                <h3>{valview.services_titre}</h3><h3>{valview.services_price}</h3>
                                            </DivTabRowBetween>
                                            <PTab10>
                                                {valview.services_description} 
                                            </PTab10>
                                            <DivTabRowAround>
                                                <PTab10><HiOutlineClock />{valview.services_delivery}</PTab10>
                                                <p><TbRefresh />{valview.services_revision}</p>
                                            </DivTabRowAround>
                                            <DivTabColumnAround>
                                                <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valview.services_page}</PTab10Bt0>
                                                <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valview.services_design}</PTab10Bt0>
                                                <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valview.services_content}</PTab10Bt0>
                                                <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valview.services_responsive}</PTab10Bt0>
                                                <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valview.services_source}</PTab10Bt0>
                                            </DivTabColumnAround>
                                            <br/>
                                        </TabPanel>
                                    </div>
                                </Tabs>
                            </div>
                        ))}
                        <br/>
                        <div>
                            <HandlerContainerDiv>
                                <div>
                                    <BackStart onClick={goBackViewServicesEditorHandler}>
                                        <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                        {t("retour")}
                                    </BackStart>
                                </div>
                            </HandlerContainerDiv>
                        </div>
                    </TabsForm>
            </div>
            <br/><br/>
                <div id="modaloutalertserviceseditorview"></div>
                <div id="backdropoutalertserviceseditorview"></div>
            </div>
        </div>
    );
}

export default ViewServiceEditor;