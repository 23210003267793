import React from 'react';
import { useNavigate } from "react-router-dom";
import '../components/components.css';
//
import imageaccount from '../carions-icon-192.png';
//
import { useTranslation } from 'react-i18next';
import '../translations/i18n';
import '../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,
    ReducedFormScrollerWidgetSm, 
    AdminButton,
    BackStart,  
} from '../global/Styles';

function ServiceAdmin() {

    const {t} = useTranslation();

    const navigateServiceAdminTab = useNavigate();

    const serviceCodingHandler = async (e) => {
        e.preventDefault();

        navigateServiceAdminTab('/servicescoding');
    }

    const serviceDesignHandler = async (e) => {
        e.preventDefault();

        navigateServiceAdminTab('/servicesdesign');
    }

    const serviceDevelopmentHandler = async (e) => {
        e.preventDefault();

        navigateServiceAdminTab('/servicesdevelopment');
    }

    const serviceMaintenanceHandler = async (e) => {
        e.preventDefault();

        navigateServiceAdminTab('/servicesmaintenance');
    }

    const serviceManagementHandler = async (e) => {
        e.preventDefault();

        navigateServiceAdminTab('/servicesmanagement');
    }

    const serviceMiscellaniousHandler = async (e) => {
        e.preventDefault();

        navigateServiceAdminTab('/servicesmiscellanious');
    }

    const goBackServiceAdminHandler = async (e) => {
        e.preventDefault();

        navigateServiceAdminTab(-1);
    }

    return (
        <DivContainerSlide id='serviceadmin'>
            <br/>
            <SpanGlobalSpan style={{textAlign:'center'}}>
                <GlobalStrongTitle>{t("service_admin_title")}</GlobalStrongTitle>
            </SpanGlobalSpan>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <img src={imageaccount} alt="" />
            </div>
            <ReducedFormScrollerWidgetSm>
                <div className="adminservicesstyle">
                    <div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={serviceCodingHandler}>{t("software_coding")}</AdminButton>
                        </div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={serviceDesignHandler}>{t("software_design")}</AdminButton>
                        </div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={serviceDevelopmentHandler}>{t("software_development")}</AdminButton>
                        </div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={serviceMaintenanceHandler}>{t("software_maintenance")}</AdminButton>
                        </div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={serviceManagementHandler}>{t("software_management")}</AdminButton>
                        </div>
                        <div className="largebuttonstyle">
                            <AdminButton onClick={serviceMiscellaniousHandler}>{t("software_miscellanious")}</AdminButton>
                        </div>
                    </div>
                    <br/>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                        <div>
                            <BackStart onClick={goBackServiceAdminHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </div>
                    <br/>
                </div>
            </ReducedFormScrollerWidgetSm>
        </DivContainerSlide>
    )
}

export default ServiceAdmin