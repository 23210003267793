import React from "react-dom";
import { useNavigate } from "react-router-dom";
//build tabs tools
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
//
import { AlertModal } from '../../../helperfunctions/Helper';
//
import '../services.css';
//
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import '../../../translations/Translation';
//
import { 
    MdSettingsBackupRestore,
} from 'react-icons/md';
//
import { HiOutlineClock } from 'react-icons/hi';
import { TbRefresh } from 'react-icons/tb';
//
import {
    DivContainerSlide,
    SpanGlobalSpan,
    GlobalStrongTitle,
    TabsForm, 
    DivTabRowAround,
    DivTabRowBetween,
    PTab10,
    DivTabColumnAround,
    PTab10Bt0,
    BackStart,  
    HandlerContainerDiv,
} from '../../../global/Styles';

import { useState } from "react";
import Axios from 'axios';

function WebAppTab() {
    const {t} = useTranslation();

    const navigateWebAppTab = useNavigate();

    //alert
    const elementOutId = "modaloutalertappcontenu";
    const elementDropId = "backdropoutalertappcontenu";
    const buttonHandler = "ouiHandlerAlertAppContenuBtn";

    const [appTabBasic, getAppTabBasic] = useState([0]);

    var resultatappbasic = "";

    //http://localhost:5000
    //https://micarionsapi.carions.net

    const appBasicHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'basic';
        //show the tab form paragraphs and panel
        const webappparagraphDiv = document.getElementById('webappparagraph');
        const webapppanelDiv = document.getElementById('webapppanel');
        //
        await Axios.post("https://micarionsapi.carions.net/website/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getAppTabBasic(responseappbasic.data);
                //
                webappparagraphDiv.style.display = 'block';
                webapppanelDiv.style.display = 'block';
            }
        });  
    }

    const appStandardHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'standard';
        //
        await Axios.post("https://micarionsapi.carions.net/website/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getAppTabBasic(responseappbasic.data);
                //
            }
        });  
    }

    const appPremiumHandler = async (e) => {
        e.preventDefault();

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const typeService = document.getElementById('typeservice').innerText;
        const typePlan = 'premium';
        //
        await Axios.post("https://micarionsapi.carions.net/website/tab/other", {
            choixlangueDiv:choixlangueDiv,
            typeService:typeService,
            typePlan:typePlan,
        }).then((responseappbasic) => {
            if(resultatappbasic === 0){
                const pTexteNotAvailable = t("noteindisponible");
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            }else{
                getAppTabBasic(responseappbasic.data);
                //
            }
        });  
    }

    //webapp proceed processes
    const webappbasicHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'basic';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateWebAppTab('/connectaccount');
            }else{
                navigateWebAppTab('/proceedwebapp');
            }
        }
    }
    //
    const webappbstandardHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'standard';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateWebAppTab('/connectaccount');
            }else{
                navigateWebAppTab('/proceedwebapp');
            }
        }
    }
    //
    const webappbpremiumHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeplan').innerText = 'premium';
        //
        const usernamedetailDiv = document.getElementById('usernamedetail').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(usernamedetailDiv !== 'redacteur'){
            if(!codeutilisateurDiv){
                navigateWebAppTab('/connectaccount');
            }else{
                navigateWebAppTab('/proceedwebapp');
            }
        }
    }

    //webapp contact
    const webappcontactHandler = async (e) => {
        e.preventDefault();

        navigateWebAppTab('/contactme');
    }

    const goBackAppSiteTabHandler = async (e) => {
        e.preventDefault();

        navigateWebAppTab('/mainwebservices')
    }

    return ( 
        <DivContainerSlide id="webapptab">
            <br/>
            <div style={{marginTop:'-2rem'}}>
                <SpanGlobalSpan style={{textAlign:'center'}}>
                    <GlobalStrongTitle>{t("webapptitre")}</GlobalStrongTitle>
                </SpanGlobalSpan>
            </div>
            <br/>
            <TabsForm>
                {appTabBasic.map((valappbasic, indexappbasic) => (
                    <div key={indexappbasic} className="tabstyles">
                        <div id="webappparagraph">
                            <PTab10>
                                {valappbasic.services_paragraphone}<br/>
                                {valappbasic.services_paragraphtwo}<br/>
                                {valappbasic.services_paragraphthree}<br/>
                                {valappbasic.services_paragraphfour}<br/>
                            </PTab10>
                        </div>
                        <Tabs defaultIndex={0}>
                            <TabList>
                                <Tab onClick={appBasicHandler}>{t("basic_plan")}</Tab>
                                <Tab onClick={appStandardHandler}>Standard</Tab>
                                <Tab onClick={appPremiumHandler}>Premium</Tab>
                            </TabList>
                            <div id="webapppanel">
                                <TabPanel >
                                    <DivTabRowBetween>
                                        <h3>{valappbasic.services_titre}</h3><h3>{valappbasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valappbasic.services_description} 
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valappbasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valappbasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valappbasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valappbasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valappbasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valappbasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valappbasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={webappbasicHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={webappcontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                                <TabPanel>
                                    <DivTabRowBetween>
                                        <h3>{valappbasic.services_titre}</h3><h3>{valappbasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valappbasic.services_description}
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valappbasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valappbasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valappbasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valappbasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valappbasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valappbasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valappbasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={webappbstandardHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={webappcontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                                <TabPanel>
                                    <DivTabRowBetween>
                                        <h3>{valappbasic.services_titre}</h3><h3>{valappbasic.services_price}</h3>
                                    </DivTabRowBetween>
                                    <PTab10>
                                        {valappbasic.services_description}
                                    </PTab10>
                                    <DivTabRowAround>
                                        <PTab10><HiOutlineClock />{valappbasic.services_delivery}</PTab10>
                                        <p><TbRefresh />{valappbasic.services_revision}</p>
                                    </DivTabRowAround>
                                    <DivTabColumnAround>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valappbasic.services_page}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valappbasic.services_design}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valappbasic.services_content}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valappbasic.services_responsive}</PTab10Bt0>
                                        <PTab10Bt0><input type="checkbox" defaultChecked="checked"></input>{valappbasic.services_source}</PTab10Bt0>
                                    </DivTabColumnAround>
                                    <br/>
                                    <DivTabRowAround>
                                        <button onClick={webappbpremiumHandler} className="btnTab btn-proceed" >{t("proceedbtn")}</button>
                                        <button onClick={webappcontactHandler} className="btnTab btn-contact">{t("contactme")}</button>
                                    </DivTabRowAround>
                                    <br/>
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                ))}
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <BackStart onClick={goBackAppSiteTabHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                {t("retour")}
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                    <br/><br/>
                </div>
                <br/><br/>
                <div id="modaloutalertappcontenu"></div>
                <div id="backdropoutalertappcontenu"></div>
            </TabsForm>
        </DivContainerSlide>
    );
}

export default WebAppTab;