export const TRANSLATIONS_IT = {
    landing: "Lancio",
    accueil: "Home",
    apropos: "A proposito",
    portfolio: "Portafoglio",
    services: "Servizi",
    blogs: "Blog",
    apprentissage: "Imparare",
    tutoriels: "Tutorial",
    apps: "App",
    contact: "Contatto",

    francais:"Francese",
    anglais:"Inglese",
    portugais:"Portoghese",
    italien:"Italiano",

    //global translation
    status_contenu: "tipo Stato",
    select_status: "Seleziona Tipo Stato",
    retour: "Indietro",
    nom: "Nome",
    placenom: "Inserisci il Nome",
    prenoms: "Cognome",
    placeprenoms: "Inserisci Cognome",
    prefixe: "Prefisso inter",
    placeprefixe: "esempio:00225",
    mobile: "Contatto Mobile",
    placemobile: "Inserisci Contatto Mobile",
    username: "Username",
    placeusername: "Inserisci Username",
    password: "Password",
    placepassword: "Inserisci Password",
    confirmpassword: "Conferma Password",
    placeconfirmpassword: "Conferma Password",
    nomatchpassword: "Le 2 password non coincidono",
    changepassword: "Cambia la tua password se vuoi creare un altro account con lo stesso nome utente",
    creer: "Creare",
    modifier: "Modifica",
    confirmer: "Conferma",
    identifier: "Identificati",
    newaccount: "Nessun Conto Utente?",
    editaccount: "Gestisci il tuo conto",
    acceder: "Accedere",
    erreurdetail: "Nome utente o password errati",
    soucritabonnement: "Periodo di utilizzo scaduto. Iscriviti per un abbonamento",
    crudcreercontenu: "Crea Contenuto",
    crudcontenumodifier: "Modifica Contenuto",
    crudaccueilsupprimer: "Cancella Contenuto",
    crudaccueilvisualiser: "Visualizza Contenuto",

    //slogan section
    slogan: "La Tradizione Digitale",
    sous_slogan:"Sviluppo web",

    //Account section
    accounttitre: "Seleziona Tipo Conto",
    utilisateur: "Conto Utente",
    abonnement: "Conto Abbonamento",

    //crud user account
    crudusertitre: "Gestione Conto",
    crudusergerer: "Come gestire il tuo Conto?",
    crudusercreer: "Crea un Conto Gratuito",
    crudusermodifier: "Modifica il tuo conto",
    crudusersupprimer: "Cancella Conto",

    //crud accueil
    crudaccueiltitre: "Gestione Home Page",

    //my editor react-quill
    editeur: "Editore",
    apercu: "Anteprima",
    typecontenu: "Tipo Contenuto",
    titrecontenu: "Titolo Contenuto",
    placecontenu: "Inserisci Titolo Contenuto",
    textcontenu: "Inserisci il contenuto del testo",
    originaltexte: "Testo precedente",
    nouveautexte: "Modifica il testo qui",

    servicetitre: "Servizi Carion's",
    webdevelopment: "Sviluppo Web",
    seoconsulting: "Consulenza SEO",
    softdevservice: "Servizi Sviluppo Software",
    apprentpurchase: "Noleggio/Acquisto App",
    training: "Formazione Sviluppo App",
    hosting: "Servizi Hosting",
    //
    website: "Siti Web",
    webapp: "Applicazioni Web",
    ecommerce: "Siti E-Commerce",
    webdescription: "Siti Web personalizzati su misura per i tuoi obiettivi aziendali e il tuo marchio, utilizzando le più recenti tecnologie di sviluppo Web, sito Web intuitivo e reattivo e facile da navigare. Sito web di e-commerce sicuro, scalabile e ottimizzato per la conversione per vendere i tuoi prodotti o servizi online. Soluzione su misura per le tue esigenze aziendali, sia che tu abbia bisogno di un sistema di gestione dei clienti, di uno strumento di gestione dei progetti o di qualsiasi altro tipo di applicazione web.",
    //
    accueil_titre: "Costruiamo il tuo progetto. Sblocca le tue soluzioni web",
    accueil_content: "Chi vuole viaggiare lontano si prende cura della sua cavalcatura, non solo perché la cavalcatura è esausta, ma anche perché non ci sarà altro posto dove viaggiare. Ma fai attenzione a non commettere a interpretazione errata che porta a un vicolo cieco !!!",
    accueil_project: "Progetti",
    accueil_skills: "Soluzioni",
    //
    connect_titre: "Crea un conto gratis. Essere connessi.",
    connect_content: "Inserisci il tuo nome utente e password per accedere alle newsletter e altro o crea un nuovo conto gratuitamente se non l'hai ancora fatto.",
    //
    //
    crudcontenusupprimer: "Cancella Contenuto",
    //
    crudcontenuVisualiser: "Visualizza Contenuto",

    //notification
    noteindisponible: "Nessun elenco di notifiche disponibile",
    //gestion tabs services
    crudservicestitre: "Gestione Servizi",
    //webservices
    webdescription_1: "Che tu abbia bisogno di un'app mobile, di un'app Web o di un server back-end, ho l'esperienza per gestirli tutti. Con le mie competenze in React Native, Nodejs e MySQL, posso creare un'app mobile completa che soddisfi le tue esigenze. Se preferisci un'app Web progressiva, posso anche svilupparne una utilizzando React js e MySQL o CouchDB.",
    webdescription_2: "Se hai bisogno di un'app web o mobile a pagina singola semplice ma potente, posso aiutarti! Utilizzando React js o React Native, posso creare un'app che sia sia user-friendly che funzionale. Che tu stia cercando di creare un portale clienti, una landing page o un sito di e-commerce, ho le competenze e l'esperienza per fornire un prodotto che soddisfi le tue esigenze.",
    webdescription_3: "Il mio processo di sviluppo inizia con una profonda comprensione delle tue esigenze, obiettivi e target di riferimento. Lavorerò con te per definire l'ambito del progetto, creare un piano di sviluppo e stabilire obiettivi e scadenze chiari.",
    webdescription_4: "Sono appassionato del mio lavoro e sono fiducioso che sarai felice dei risultati. Tuttavia, per assicurarci di essere sulla stessa lunghezza d'onda, consiglio vivamente di discutere con me le vostre esigenze prima di iniziare il processo di sviluppo. Questo mi aiuterà a comprendere meglio la tua visione e a fornire un prodotto finale che superi le tue aspettative.",
    //
    webIntroductory: "Introduttivo",
    webbasicprice: "€ 87,22",
    webbasicdescription: "Quando si tratta di creare app Web o mobili, ho una vasta esperienza in una varietà di tecnologie e framework. A seconda delle tue esigenze, posso creare una semplice app a singola pagina utilizzando React js o React Native.",
    webbasicdelivery: "Consegna in 7 giorni",
    webbasicrevision: "4 revisioni",
    webbasicpage: "1 pagina",
    webbasicdesign: "Personalizzazione del design",
    webbasiccontent: "Caricamento dei contenuti",
    webbasicresponsive: "Design reattivo",
    webbasicsource: "Codice sorgente su richiesta",
    //
    proceedbtn: "Procedere",
    contactme: "Contattami",
    //
    websitetitre: "Servizio sviluppo web",
    webapptitre: "Servizio sviluppo Web App",
    ecomapptitre: "Servizio sviluppo e-commerce",
    //global
    type_plan: "Tipo di piano",
    select_plan: "Seleziona il tipo",
    basic_plan: "Basic",
    standard_plan: "Standard",
    premium_plan: "Premium",
    paragraphe_1: "Paragrafo 1",
    paragraphe_2: "Paragrafo 2",
    paragraphe_3: "Paragrafo 3",
    paragraphe_4: "Paragrafo 4",
    service_title: "Titolo servizio",
    service_price: "Prezzo del servizio",
    plan_description: "Descrizione Piano",
    days_delivery: "Tempi Consegna",
    revision_number: "Numero di revisione",
    page_number: "Numero di pagina",
    design_customization: "Personalizzazione del design",
    content_upload: "Caricamento dei contenuti",
    responsive_design: "Design reattivo",
    source_code: "Codice sorgente",
    titre_service: "Introduttivo/Intermedio/Avanzato/",
    delivery: ".. Gioni di consegna",
    price: "...€",
    revision: ".. Revisioni",
    page: ".. pagine",
    customization: "Personalizzazione del design",
    content: "Caricamento dei contenuti",
    responsive: "Design reattivo",
    source: "Codice sorgente su richiesta",
    //
    type_contenu: "Seleziona un tipo di contenuto",
    titre_plan: "Seleziona il tipo di piano",
    type_paragraphe: "Scrivi almeno il primo paragrafo",
    titre_contenu: "Inserisci un singolo titolo di servizio",
    titre_price: "Inserisci il prezzo del servizio",
    titre_description: "Inserisci la descrizione del servizio",
    titre_delivery: "Inserisci il numero di giorni necessari prima della consegna",
    titre_revision: "Immettere il numero di revisioni necessarie",
    titre_page: "Immettere il numero di pagine possibili",
    titre_design: "Inserisci il design di personalizzazione",
    titre_upload: "Immettere Importazione contenuto",
    titre_responsive: "Inserisci il design reattivo",
    titre_source: "Inserisci il codice sorgente su richiesta",
    //
    tab_success: "Tabella servizi registrato. Prossimo",
    //seo services
    keyword_research: "Ricerca Parole Chiave",
    on_page: "Ottimizzazione in Pagina",
    technical_audit: "Audit Tecnico SEO",
    monthly_report: "Reportistica Mensile",
    //software development services
    softservices_description: "Fornisco servizi di sviluppo di siti Web, app Web e app mobili React per aiutare le aziende e gli individui a creare soluzioni software personalizzate che soddisfino le loro esigenze specifiche. In qualità di sviluppatore esperto di React, sono specializzato nell'implementazione di componenti, funzioni, procedure e algoritmi personalizzati in base alle richieste dei clienti. I miei servizi di sviluppo React includono:",
    softwareservices: "Servizi Sviluppo Individuale",
    software_coding: "Programmazione & Codifica",
    software_design: "Progettazione Web e App",
    software_development: "Sviluppo Web e App",
    software_maintenance: "Manutenzione Web e App",
    software_management: "Consulenza Gestione Progetti",
    software_miscellanious: "Servizi vari",
    //contact me
    contact_me:"Contattami",
    //no page found
    nofound:"Pagina non trovata",
    //select language
    selectlanguage: "Per cambiare lingua, clicca su Home e seleziona la tua lingua preferita.",
    //contact me
    contactintro: "Hai domande, commenti o suggerimenti? Mi piacerebbe sentirti! Non esitare a contattarmi utilizzando uno dei seguenti metodi:",
    contact_1: "Chiamata diretta: puoi chiamarmi direttamente al mio numero di telefono.",
    contact_2: "Invia e-mail: Se preferisci comunicare via e-mail, inviami un messaggio.",
    contact_3: "Chiamata WhatsApp: se usi WhatsApp, puoi anche chiamarmi al mio numero WhatsApp all'indirizzo.",
    contactconclued_1: "Sono sempre felice di sentire nuove persone e farò del mio meglio per ricontattarti il prima possibile.",
    contactconclued_2: "Non vedo l'ora di sentirti presto!",
    //proceed website
    proceedwebtitle: "Sviluppo del sito web",
    proceedwebdescription: "Ecco alcune domande essenziali che potrei farti prima di iniziare il tuo progetto web. Si prega di compilare gratuitamente per consentirmi di comprendere meglio le vostre esigenze, preferenze e limitazioni per personalizzare il design, se necessario, per soddisfare tali requisiti.",
    webquestion_1: "1. Descrivi il tuo progetto in poche frasi",
    webquestion_2: "2. Attualmente hai un sito web? In tal caso, cosa funziona/non funziona per te?",
    webquestion_3: "3. Quali sono i tuoi obiettivi per questo progetto?",
    webquestion_4: "4. Chi è il tuo target di riferimento?",
    webquestion_5: "5. Quali funzionalità specifiche desideri sul sito?",
    webquestion_6: "6. Come possiamo evitare il fallimento?",
    webquestion_7: "7. Chi sono i tuoi principali concorrenti?",
    webquestion_8: "8. Cosa ti rende diverso dai tuoi concorrenti?",
    webquestion_9: "9. Qual è lo scopo del progetto?",
    webquestion_10: "10. Chi sarà responsabile della manutenzione dei contenuti?",
    webquestion_11: "11. Quali sono le pietre miliari e le scadenze?",
    webquestion_12: "12. Qual è la procedura per gli aggiornamenti e le modifiche al sito Web dopo il lancio?",
    webquestion_13: "Desidero essere contattato via:",
    //suivant & precedent
    suivant: "Prossimo",
    precedent: "Prima",
    //hint webquestion
    hintwebquestion_1: "In alcuni casi, la tua attività può sembrare incredibilmente ovvia, ma non dovresti mai dare per scontato nulla. Riassumi la tua attività in poche brevi frasi; questa è l'essenza che deve essere immediatamente evidente quando i visitatori arrivano sul tuo nuovo sito web o app. Scava anche più a fondo. Prova a scoprire quali sono i tuoi valori chiave, il tuo livello di esperienza nel settore e la tua visione del futuro, nonché le tue dimensioni, posizione e background.",
    hintwebquestion_2: "A meno che tu non stia partendo da zero, il tuo attuale sito web è una miniera di informazioni. Quale sistema di gestione dei contenuti desideri utilizzare, per quanto tempo lo utilizzeresti e con quale facilità preferisci aggiornarlo. Cosa ti piace e di cosa non vedi l'ora di vedere il retro? Passando un po' di tempo a rivedere alcuni siti Web attuali, puoi saperne di più sulle simpatie e antipatie dei loro clienti, nonché imparare da eventuali errori che hanno commesso l'ultima volta.",
    hintwebquestion_3: "Qual è la tua definizione di successo. Vuoi aumentare la quantità di visitatori, aumentare la dimensione media degli ordini o aumentare gli utenti sul loro forum web? Forse vuoi incoraggiare un maggiore coinvolgimento tramite il tuo blog, aumentare la visibilità del tuo marchio o incoraggiare le persone a iscriversi alla tua newsletter via e-mail/prova gratuita/white paper, ecc. Stabilendo i tuoi punti deboli e determinando i problemi che stai cercando di risolvere , avrai maggiori possibilità di produrre una soluzione efficiente.",
    hintwebquestion_4: "Cerca di entrare in risonanza con i tuoi utenti e il pubblico di destinazione, altrimenti semplicemente non lo useranno: il pubblico online è incredibilmente volubile oggi, a causa del mercato estremamente competitivo. Approfondisci i dati demografici e psicografici dei tuoi utenti, come le abitudini di consumo dei media, le parole gergali e le scelte di stile di vita. Quali sono le loro abitudini di acquisto? Hai bisogno di colmare un divario tra il pubblico attuale e il mercato di riferimento? Più conosci gli utenti finali, meglio puoi determinare l'aspetto, il layout e la navigazione del tuo sito web.",
    hintwebquestion_5: "Stabilisci fin dall'inizio se hai funzionalità particolari che desideri includere nel tuo nuovo sito web. Alcune cose possono essere ovvie. Se gestisci un ristorante, ad esempio, avrai bisogno degli orari di apertura e del menu inclusi, mentre se gestisci un hotel, potresti volere un modulo di prenotazione online. Altre possibilità includono un blog, una chat sul sito Web, l'integrazione dei social media, gallerie fotografiche, un sito mobile o reattivo separato, l'integrazione video o un modulo di contatto. Chiarisci sempre all'inizio per evitare aggiunte tardive che potrebbero costare tempo e denaro per sposarsi nel sito. Scrivi o fornisci materiali di branding o guide di stile che devono essere incorporati nel design del sito.",
    hintwebquestion_6: "Conoscere le tue antipatie è altrettanto importante. Rimuovere qualcosa di integrato in un sito Web una volta che sei a metà di un progetto è altrettanto fastidioso, costoso e dispendioso in termini di tempo quanto aggiungere qualcosa. Mostra i tuoi siti Web meno preferiti e sottolinea gli elementi di design, le caratteristiche e gli stili che li fanno rabbrividire se necessario. In questo modo, puoi eliminare potenziali fallimenti prima di entrare nel nocciolo del progetto.",
    hintwebquestion_7: "Scoprire i tuoi concorrenti ti fornisce un'altra utile fonte di informazioni. Dopo aver indicato i tuoi principali rivali, puoi raccogliere informazioni che ti aiuteranno a guidare il tuo web design. Guarda gli elementi che funzionano bene sui loro siti e vedi se ci sono cose che non vanno bene. La tua intenzione qui non è copiare ciò che è stato fatto prima, ma imparare dai loro successi e fallimenti. Sottolinea le cose che fanno e non gli piacciono sui siti Web dei tuoi concorrenti è un altro utile indicatore di ciò che ti piace e non ti piace.",
    hintwebquestion_8: "Conoscere il tuo unico punto di forza ti aiuterà a progettare un sito che si distingue dai tuoi concorrenti. Nel frenetico, rumoroso e competitivo mondo online, le aziende devono essere veramente straordinarie per avere successo. Quindi, come puoi attirare l'attenzione delle persone quando atterrano sul tuo sito? Come puoi far valere quella prima impressione complicata? Questo non deve essere niente di importante; può essere semplice come una consulenza gratuita o un fantastico servizio clienti. Altre cose che fanno risaltare le aziende includono la consegna rapida o gratuita dei prodotti, l'immagazzinamento dei prodotti più economici o della migliore qualità nella tua zona, una garanzia straordinaria o una procedura di restituzione o l'offerta di pacchetti unici. Imparando come differisci dai tuoi concorrenti, puoi ottenere un sito che fa lo stesso.",
    hintwebquestion_9: "Infine, chiarire l'ambito del progetto. Hai bisogno di una chiara comprensione della tua scadenza finale e di eventuali traguardi chiave che desideri raggiungere lungo il percorso: questo è fondamentale per mantenere il tuo progetto sulla buona strada e assicurarti di essere all'altezza delle aspettative. Devi anche conoscere il budget e, cosa più importante, stabilire se c'è flessibilità nella tempistica e nel budget.",
    hintwebquestion_10: "Se sei responsabile della manutenzione dei contenuti web, è importante assicurarti di avere le competenze e le conoscenze necessarie per apportare aggiornamenti e modifiche al sito web. Altrimenti, è importante chiarire l'ambito di queste attività e assicurarsi di aver compreso cosa è incluso nel contratto di manutenzione. Ciò può includere attività come l'aggiornamento del contenuto, la correzione di bug e l'esecuzione di backup regolari.",
    hintwebquestion_11: "Stabilendo le pietre miliari e le scadenze, puoi assicurarti che il progetto rimanga sulla buona strada e che le aspettative siano soddisfatte. È importante comunicare regolarmente con me durante tutto il progetto per garantire che eventuali problemi o ritardi vengano risolti tempestivamente. Ciò include: scoperta e pianificazione, approvazione del progetto, consegna dei contenuti, approvazione dello sviluppo, approvazione dei test e della garanzia della qualità, lancio. Seleziona quale preferisci o dimmi che li preferisci tutti.",
    hintwebquestion_12: "Il processo per gli aggiornamenti e le modifiche al sito Web dopo il lancio può variare a seconda del sito Web specifico e dell'accordo tra di noi. Tuttavia, ecco alcuni passaggi generali che possono essere coinvolti nel processo: richiesta, valutazione, approvazione, sviluppo, test, distribuzione, lancio. È importante disporre di un processo chiaro per gli aggiornamenti e le modifiche al sito Web dopo il lancio per garantire che vengano implementati in modo efficiente ed efficace. Si prega di dare una risposta chiara se si è d'accordo e pronti a seguire l'intero processo.",
    //
    placemail: "Indirizzo e-mail",
    placewhatsapp: "Contatto WhatsApp",
    emailphone: "Inserisci almeno la tua Email o whatsapp o entrambi.",
    responsequestion_1: "Grazie per aver inviato il questionario. Apprezziamo il tuo interesse a lavorare con noi. Il nostro team esaminerà le tue risposte e ti contatterà a breve per programmare una discussione dal vivo per comprendere meglio le esigenze e i requisiti del tuo progetto. Durante questa discussione, lavoreremo con te per aggiornare il tuo questionario secondo necessità per assicurarci di comprendere appieno i tuoi obiettivi e obiettivi.",
    responsequestion_2: "Grazie ancora per la vostra attenzione e non vediamo l'ora di discutere ulteriormente del vostro progetto.",
    //proceed website
    proceedwebaatitle: "Sviluppo Web App",
    invalidemail: "Inserisci il tuo indirizzo e-mail valido.",
    enteremail: "Inserisci il tuo indirizzo e-mail valido.",
    enterphone: "Inserisci il tuo prefisso internazionale valido seguito dal numero Whatsapp.",
    //proceed ecom
    proceedecomtitle: "Sviluppo E-commerce",
    //Seo processing
    proceedseosearchtitle: "Ricerca Parole Chiave",
    proceedseooptimtitle: "Ottimizzazione in Pagina",
    proceedseodescription: "Ecco alcune domande essenziali che potrei farti prima di iniziare il tuo progetto web. Si prega di compilare gratuitamente per consentirmi di comprendere meglio le vostre esigenze, preferenze e limitazioni per personalizzare il design, se necessario, per soddisfare tali requisiti.",
    seoquestion_1: "1. Quali sono i tuoi obiettivi e obiettivi aziendali?",
    seoquestion_2: "2. Chi è il tuo pubblico di riferimento?",
    seoquestion_3: "3. Quali sono i tuoi punti di vendita unici (USP)?",
    seoquestion_4: "4. Chi sono i tuoi principali concorrenti?",
    seoquestion_5: "5. Quali sono i tuoi attuali sforzi e risultati SEO?",
    seoquestion_6: "6. Qual è il traffico e le prestazioni attuali del tuo sito web?",
    seoquestion_7: "7. Quali sono le parole chiave e le frasi principali per le quali desideri classificarti?",
    seoquestion_8: "8. Qual è la tua strategia di marketing dei contenuti?",
    seoquestion_9: "9. Qual è la tua strategia sui social media?",
    seoquestion_10: "10. Qual è il tuo budget per la SEO?",
    seoquestion_11: "11. Qual è la tua tempistica per raggiungere gli obiettivi SEO?",
    seoquestion_12: "12. How do you measure success?",
    seoquestion_13: "Desidero essere contattato via:",
    //hint seoquestion
    hintseoquestion_1: "1. Comprendere gli obiettivi e gli obiettivi aziendali generali ci aiuterà ad allineare la strategia SEO con le vostre esigenze aziendali e ad assicurare che gli sforzi siano focalizzati sul raggiungimento dei risultati desiderati.",
    hintseoquestion_2: "2. Conoscere il pubblico di destinazione è fondamentale per determinare le parole chiave più pertinenti e ottimizzare il contenuto del sito Web per attrarre il pubblico di destinazione.",
    hintseoquestion_3: "3. Identificare i punti di forza unici ci aiuterà a mettere in evidenza quegli aspetti della tua attività nella strategia SEO ed a differenziarlo dalla concorrenza.",
    hintseoquestion_4: "4. Comprendere i principali concorrenti e la loro presenza online ci aiuterà a sviluppare una strategia SEO più efficace che può aiutare a superare i tuoi propri concorrenti.",
    hintseoquestion_5: "5. Conoscere gli attuali sforzi e risultati SEO ci aiuterà a identificare le aree che necessitano di miglioramenti e a sviluppare gli sforzi esistenti.",
    hintseoquestion_6: "6. Comprendere il traffico e le prestazioni del sito Web attuale ci aiuterà a fissare obiettivi realistici e misurare il successo della strategia SEO.",
    hintseoquestion_7: "7. Identificare le parole chiave e le frasi target ci aiuterà a ottimizzare il contenuto del sito Web e migliorare il posizionamento per tali parole chiave.",
    hintseoquestion_8: "8. Conoscere la strategia di marketing dei contenuti ci aiuterà ad allineare gli sforzi SEO con la strategia dei contenuti e identificare le opportunità per ottimizzare i contenuti per la SEO.",
    hintseoquestion_9: "9. Comprendere la strategia sui social media ci aiuterà ad allineare gli sforzi SEO con la strategia sui social media e identificare le opportunità per l'ottimizzazione dei social media.",
    hintseoquestion_10: "10. Conoscere il budget per la SEO ci aiuterà a sviluppare una strategia più efficace in grado di fornire i risultati desiderati entro il budget disponibile.",
    hintseoquestion_11: "11. Comprendere la tempistica per il raggiungimento degli obiettivi SEO ci aiuterà a stabilire aspettative realistiche e sviluppare una strategia in grado di fornire i risultati desiderati entro il periodo di tempo specificato.",
    hintseoquestion_12: "12. Conoscere i criteri per misurare il successo ci aiuterà a fissare obiettivi realistici e sviluppare una strategia che possa fornire i risultati desiderati sulla base della definizione del vostro successo.",
    //individual development service
    proceedcodingdescription: "Ecco alcune domande essenziali che potrei farti prima di iniziare il servizio richiesto. Si prega di compilare gratuitamente per consentirmi di comprendere meglio le vostre esigenze, preferenze e limitazioni al fine di adattare il design, se necessario, per soddisfare questi requisiti.",
    proceedcodingtitle: "Programmazione & Codifica",
    codingquestion_1: "1. Qual è la codifica o la funzionalità specifica di cui hai bisogno per il tuo progetto di sviluppo software?",
    codingquestion_2: "2. Quali linguaggi di programmazione utilizzi attualmente nel tuo progetto?",
    codingquestion_3: "3. Hai provato a risolvere il problema di codifica da solo? Se sì, cosa hai provato finora?",
    codingquestion_4: "4. Qual è la tempistica per il completamento di questo progetto?",
    codingquestion_5: "5. Qual è il tuo budget per questo progetto?",
    codingquestion_6: "6. Chi sono gli utenti finali del tuo progetto di sviluppo?",
    codingquestion_7: "7. Quali caratteristiche e funzionalità sono più importanti per i tuoi utenti finali?",
    codingquestion_8: "8. Come si integrerà il progetto di sviluppo con i sistemi tecnologici esistenti?",
    codingquestion_9: "9. Quali sono i potenziali rischi e le sfide che prevedi con questo progetto?",
    codingquestion_10: "10. Come misurerai il successo di questo progetto di sviluppo?",
    codingquestion_11: "11. Con quale frequenza richiedi aggiornamenti o rapporti sullo stato di avanzamento del lavoro di codifica?",
    codingquestion_12: "12. Di quale livello di supporto tecnico hai bisogno per questo progetto di sviluppo?",
    codingquestion_13: "Desidero essere contattato via:",
    //hint seoquestion
    hintcodingquestion_1: "1. Fornire dettagli specifici sulla codifica o sulla funzionalità richiesta, comprese eventuali caratteristiche o funzioni specifiche che si desidera aggiungere o modificare.",
    hintcodingquestion_2: "2. Fornisci informazioni sui linguaggi di programmazione che stai attualmente utilizzando nel tuo progetto. Ciò contribuirà a comprendere l'ambito del progetto e determinare se si dispone delle competenze necessarie per completarlo.",
    hintcodingquestion_3: "3. Hai provato a risolvere il problema di codifica da solo. In caso affermativo, fornisci i dettagli su ciò che hai provato finora. Questo ti aiuterà a capire il tuo livello di competenza tecnica ed eviterà di duplicare qualsiasi lavoro che è già stato fatto.",
    hintcodingquestion_4: "4. La tua tempistica specifica per il completamento del progetto. Ciò contribuirà a pianificare il programma di lavoro e determinare la disponibilità per completare il progetto entro il periodo di tempo richiesto.",
    hintcodingquestion_5: "5. Il tuo budget per il progetto. Questo ci aiuterà a capire i tuoi vincoli finanziari e determinare se i servizi sono convenienti per te.",
    hintcodingquestion_6: "6. Fornisci informazioni sugli utenti finali del tuo progetto di sviluppo software o app. Ciò aiuterà a comprendere il pubblico di destinazione e assicurerà che il lavoro di codifica soddisfi le tue esigenze.",
    hintcodingquestion_7: "7. Fornisci dettagli sulle caratteristiche e funzionalità più importanti per i tuoi utenti finali. Questo ci aiuterà a stabilire le priorità del tuo lavoro di codifica e a garantire che le funzioni più importanti vengano completate per prime.",
    hintcodingquestion_8: "8. Fornire informazioni su come il progetto di sviluppo del software o dell'app si integrerà con i sistemi tecnologici esistenti. Questo ci aiuterà a comprendere i requisiti tecnici del progetto e a garantire che il lavoro di codifica sia compatibile con i sistemi tecnologici esistenti.",
    hintcodingquestion_9: "9. Identifica eventuali rischi o sfide potenziali che prevedi con il progetto. Questo ci aiuterà a identificare potenziali ostacoli e pianificare di conseguenza per mitigare eventuali rischi o sfide.",
    hintcodingquestion_10: "10. Fornisci dettagli su come misurerai il successo del progetto di sviluppo del software o dell'app. Questo ci aiuterà a comprendere le vostre aspettative e a garantire che il lavoro di codifica soddisfi le vostre esigenze.",
    hintcodingquestion_11: "11. Con quale frequenza richiederai aggiornamenti o rapporti sullo stato di avanzamento del lavoro di codifica. Questo ci aiuterà a pianificare il tuo programma di lavoro e a garantire che tu sia tenuto informato durante tutto il progetto.",
    hintcodingquestion_12: "12. Quale livello di supporto tecnico sarà necessario per il progetto di sviluppo del software o dell'app. Questo ci aiuterà a determinare l'ambito del supporto tecnico richiesto e a pianificare il tuo programma di lavoro di conseguenza.",

    //individual development service
    proceeddesigndescription: "Ecco alcune domande essenziali che potrei farti prima di iniziare il tuo progetto web. Si prega di compilare gratuitamente per consentirmi di comprendere meglio le vostre esigenze, preferenze e limitazioni per personalizzare il design, se necessario, per soddisfare tali requisiti.",
    proceeddesigntitle: "Progettazione Web e App",
    designquestion_1: "1. Qual è lo scopo del tuo sito web o della tua app?",
    designquestion_2: "2. Chi è il tuo target di riferimento?",
    designquestion_3: "3. Quali caratteristiche e funzionalità vuoi includere?",
    designquestion_4: "4. Qual è il tuo budget per il progetto?",
    designquestion_5: "5. Hai elementi di branding o design esistenti?",
    designquestion_6: "6. Qual è la tua tempistica per il progetto?",
    designquestion_7: "7. Qual è il principale invito all'azione sul tuo sito web o sulla tua app?",
    designquestion_8: "8. Hai preferenze di design specifiche?",
    designquestion_9: "9. Qual è la tua strategia per i contenuti?",
    designquestion_10: "10. Qual è la tua strategia SEO?",
    designquestion_11: "11. Qual è il tuo piano di hosting e manutenzione?",
    designquestion_12: "12. Qual è la tempistica prevista per il completamento del progetto e ci sono scadenze specifiche che devono essere rispettate?",
    designquestion_13: "Desidero essere contattato via:",
    //hint seoquestion
    hintdesignquestion_1: "1. Spiega cosa vuoi ottenere con il tuo sito web o la tua app, come vendere prodotti, fornire informazioni o offrire un servizio. Questa domanda ci aiuterà a capire l'obiettivo principale del sito web o dell'app e cosa vuoi ottenere con esso.",
    hintdesignquestion_2: "2. Fornisci informazioni sul tuo pubblico di destinazione, come fascia d'età, interessi e dati demografici. Comprendere il pubblico di destinazione ci aiuterà a creare un design che ti piaccia e soddisfi le tue esigenze.",
    hintdesignquestion_3: "3. Fornisci un elenco di caratteristiche e funzionalità che desideri includere, come un carrello della spesa, una barra di ricerca o un modulo di contatto. Questa domanda ci aiuterà a capire cosa vuoi che faccia il sito web o l'app e quali caratteristiche dovrebbe avere.",
    hintdesignquestion_4: "4. Fornisci una fascia di budget o un importo specifico che sei disposto a spendere per il progetto. Comprendere il budget ci aiuterà a determinare ciò che è fattibile in termini di progettazione e sviluppo.",
    hintdesignquestion_5: "5. Fornire eventuali elementi di branding o design esistenti, come loghi, combinazioni di colori o caratteri. Se disponi di elementi di branding o design esistenti, li incorporeremo nel sito Web o nell'app e creeremo un aspetto coerente.",
    hintdesignquestion_6: "6. Fornisci una tempistica per il progetto, come una data di lancio o una scadenza specifica. Comprendere la tempistica ci aiuterà a determinare se il progetto è fattibile e pianificare di conseguenza.",
    hintdesignquestion_7: "7. Spiega quale azione vuoi che gli utenti intraprendano, come effettuare un acquisto, compilare un modulo o iscriversi a una newsletter. Comprendere l'invito all'azione principale ci aiuterà a progettare il sito Web o l'app in modo da incoraggiare gli utenti a intraprendere l'azione desiderata.",
    hintdesignquestion_8: "8. Fornisci eventuali preferenze di design specifiche, come un design minimalista o audace o colori specifici che desideri incorporare. Comprendere le tue preferenze di design ci aiuterà a creare un design che soddisfi le tue preferenze e aspettative estetiche.",
    hintdesignquestion_9: "9. Spiega la tua strategia di contenuto, ad esempio che tipo di contenuto vuoi creare e con quale frequenza lo aggiornerai. Comprendere la tua strategia per i contenuti ci aiuterà a progettare il sito web o l'app in modo da supportare i tuoi obiettivi di contenuto.",
    hintdesignquestion_10: "10. Spiega la tua strategia SEO, ad esempio quali parole chiave vuoi scegliere come target e come intendi ottimizzare i tuoi contenuti. Comprendere la tua strategia SEO ci aiuterà a progettare il sito Web o l'app in modo da supportare i tuoi obiettivi di ottimizzazione dei motori di ricerca.",
    hintdesignquestion_11: "11. Spiega il tuo piano di hosting e manutenzione, ad esempio quale provider di hosting intendi utilizzare e con quale frequenza. Comprendere il tuo piano di hosting e manutenzione ci aiuterà a pianificare il supporto e la manutenzione continui del sito Web o dell'app.",
    hintdesignquestion_12: "12. Rispondere a questa domanda ci darà un'idea migliore delle vostre aspettative in merito alla tempistica del progetto. È importante sapere se ci sono scadenze specifiche che devono essere rispettate in modo da poter pianificare il tuo lavoro di conseguenza. Ci aiuterà anche a stabilire una tempistica realistica per il completamento del progetto e ad assicurarci di avere abbastanza tempo per consegnare un prodotto di alta qualità.",

    //individual maintenance service
    proceedmaintdescription: "Ecco alcune domande essenziali che potrei farti prima di iniziare il tuo progetto web. Si prega di compilare gratuitamente per consentirmi di comprendere meglio le vostre esigenze, preferenze e limitazioni per personalizzare il design, se necessario, per soddisfare tali requisiti.",
    maintquestion_1: "1. Quali parti specifiche del tuo sito Web o della tua app devi mantenere?",
    maintquestion_2: "2. Di che tipo di aggiornamenti o modifiche hai bisogno regolarmente?",
    maintquestion_3: "3. Con quale frequenza sono necessari aggiornamenti o modifiche da apportare?",
    maintquestion_4: "4. Quali sono gli attuali problemi o problemi che stai affrontando con il tuo sito web o la tua app?",
    maintquestion_5: "5. Quali sono i tuoi obiettivi a lungo termine per il tuo sito web o la tua app?",
    maintquestion_6: "6. Qual è il tuo budget per la manutenzione del web e delle app?",
    maintquestion_7: "7. Qual è la tempistica prevista per i lavori di manutenzione?",
    maintquestion_8: "8. Ci sono problemi di sicurezza che devono essere affrontati?",
    maintquestion_9: "9. Ci sono state modifiche recenti al sito Web o all'app che potrebbero richiedere manutenzione?",
    maintquestion_10: "10. Disponi di un sistema di backup per il tuo sito web o la tua app?",
    maintquestion_11: "11. Che tipo di segnalazione o comunicazione ti aspetti durante il processo di manutenzione?",
    maintquestion_12: "12. Qual è il tuo metodo di comunicazione preferito?",
    maintquestion_13: "Desidero essere contattato via:",
    //hint seoquestion
    hintmaintquestion_1: "1. La risposta a questa domanda mi aiuterà a capire quali parti del sito web o dell'app vuoi che mantenga. Potrebbe trattarsi di qualsiasi cosa, dall'aggiornamento dei contenuti alla correzione di bug o problemi di sicurezza.",
    hintmaintquestion_2: "2. Comprendere i tipi di aggiornamenti o modifiche di cui hai bisogno ci aiuterà a pianificare il tuo lavoro e stimare il tempo necessario per ciascuna attività.",
    hintmaintquestion_3: "3. Sapere quanto spesso hai bisogno di aggiornamenti o modifiche ci aiuterà a pianificare il tuo programma di lavoro e ad assicurarci di soddisfare le tue aspettative.",
    hintmaintquestion_4: "4. Questa domanda ci aiuterà a identificare eventuali problemi immediati che devono essere affrontati come parte del lavoro di manutenzione.",
    hintmaintquestion_5: "5. Comprendere i tuoi obiettivi a lungo termine ci aiuterà a identificare le aree che devono essere migliorate o aggiornate per garantire che il sito Web o l'app continuino a soddisfare le tue esigenze.",
    hintmaintquestion_6: "6. Conoscere il tuo budget ci aiuterà a determinare il livello di servizio che possiamo fornire e quali attività possiamo assumerci.",
    hintmaintquestion_7: "7. Rispondere a questa domanda ci darà un'idea migliore delle vostre aspettative in merito alla tempistica dei lavori di manutenzione. È importante sapere se ci sono scadenze specifiche che devono essere rispettate in modo da poter pianificare il tuo lavoro di conseguenza.",
    hintmaintquestion_8: "8. Sapere se ci sono problemi di sicurezza ci aiuterà a dare la priorità al tuo lavoro e a garantire che il sito Web o l'app rimangano sicuri.",
    hintmaintquestion_9: "9. Questa domanda ci aiuterà a identificare eventuali modifiche recenti che potrebbero aver causato problemi o richiedere ulteriori lavori di manutenzione.",
    hintmaintquestion_10: "10. Sapere se esiste un sistema di backup in atto ci aiuterà a pianificare eventuali problemi o problemi imprevisti che potrebbero sorgere.",
    hintmaintquestion_11: "11. Comprendere il livello di segnalazione o comunicazione che ti aspetti ci aiuterà a pianificare come tenerli aggiornati sullo stato di avanzamento dei lavori di manutenzione.",
    hintmaintquestion_12: "12. Conoscere il tuo metodo di comunicazione preferito ci aiuterà a garantire che siamo in grado di comunicare in modo efficace durante tutto il processo di manutenzione.",
    //individual management service
    proceedmanagedescription: "Ecco alcune domande essenziali che potrei farti prima di iniziare il tuo progetto web. Si prega di compilare gratuitamente per consentirmi di comprendere meglio le vostre esigenze, preferenze e limitazioni per personalizzare il design, se necessario, per soddisfare tali requisiti.",
    managequestion_1: "1. Qual è l'obiettivo principale del tuo progetto e cosa vuoi ottenere?",
    managequestion_2: "2. Qual è il tuo budget per questo progetto?",
    managequestion_3: "3. Qual è la tempistica del progetto e quando prevedete che venga completato?",
    managequestion_4: "4. Chi sono le parti interessate coinvolte in questo progetto?",
    managequestion_5: "5. Quali sono i rischi connessi a questo progetto?",
    managequestion_6: "6. Quali sono i risultati del progetto e come si misura il successo?",
    managequestion_7: "7. Quali sono gli indicatori chiave di prestazione (KPI) per il progetto?",
    managequestion_8: "8. Quali sono i canali di comunicazione ei protocolli del progetto?",
    managequestion_9: "9. Chi sarà responsabile del processo decisionale durante il progetto?",
    managequestion_10: "10. Quali sono gli strumenti e il software di gestione dei progetti che utilizzi?",
    managequestion_11: "11. Qual è la composizione e i ruoli del team di progetto?",
    managequestion_12: "12. Quali sono i requisiti di supporto e manutenzione post-progetto?",
    managequestion_13: "Desidero essere contattato via:",
    //hint question
    hintmanagequestion_1: "1. Questa domanda ci aiuterà a capire lo scopo e la portata del progetto. Ci aiuterà anche a determinare le risorse necessarie per completare il progetto.",
    hintmanagequestion_2: "2. Conoscere il budget è essenziale per determinare la fattibilità del progetto e pianificare le risorse di conseguenza.",
    hintmanagequestion_3: "3. Questa domanda ci aiuterà a determinare l'urgenza e la scadenza del progetto. Ci aiuterà anche a pianificare le pietre miliari del progetto e il programma di consegna.",
    hintmanagequestion_4: "4. Identificare le parti interessate è essenziale per comprendere le vostre esigenze e requisiti. Ci aiuterà anche a gestire le tue aspettative e a garantire il tuo buy-in per il progetto.",
    hintmanagequestion_5: "5. Identificare in anticipo potenziali rischi e sfide ci aiuterà a pianificarli e mitigarli prima che abbiano un impatto sul progetto.",
    hintmanagequestion_6: "6. Questa domanda ci aiuterà a capire cosa ti aspetti di ricevere dal progetto e come misuri il successo.",
    hintmanagequestion_7: "7. Questa domanda ci aiuterà a determinare come misurare il successo del progetto e assicurarci che sia in linea con i tuoi obiettivi.",
    hintmanagequestion_8: "8. Questa domanda ci aiuterà a determinare le esigenze e le preferenze di comunicazione di te e delle parti interessate. Ci aiuterà anche a stabilire protocolli e procedure per garantire una comunicazione efficace durante tutto il progetto.",
    hintmanagequestion_9: "9. Sapere chi ha autorità decisionale ci aiuterà a gestire il progetto in modo efficiente ed evitare ritardi.",
    hintmanagequestion_10: "10. Questa domanda ci aiuterà a determinare la tua familiarità con gli strumenti di gestione del progetto e il livello di adozione della tecnologia richiesta per il progetto.",
    hintmanagequestion_11: "11. Questa domanda ci aiuterà a capire le capacità e le competenze richieste per il progetto e come formare un team, se necessario, che soddisfi le esigenze del progetto.",
    hintmanagequestion_12: "12. Questa domanda ci aiuterà a capire le tue aspettative per il supporto e la manutenzione dopo il completamento del progetto. Ci aiuterà anche a pianificare impegni futuri e relazioni a lungo termine con te.",
    //individual miscellanious service
    proceedmisceldescription: "Ecco alcune domande essenziali che potrei farti prima di iniziare il tuo progetto web. Si prega di compilare gratuitamente per consentirmi di comprendere meglio le vostre esigenze, preferenze e limitazioni per personalizzare il design, se necessario, per soddisfare tali requisiti.",
    miscelquestion_1: "1. Selezionare un'attività alla volta da codificare e compilare gratuitamente le seguenti domande.",
    miscelquestion_2: "2. Quali sono le caratteristiche e le funzionalità specifiche che desideri aggiungere alla tua attività?",
    miscelquestion_3: "3. Hai già progettato l'interfaccia utente (UI) per la tua attività?",
    miscelquestion_4: "4. Disponi di un codice esistente o di una documentazione di progetto che possa aiutarmi a comprendere meglio il tuo compito?",
    miscelquestion_5: "5. Hai preferenze o requisiti per le tecnologie utilizzate nell'attività?",
    miscelquestion_6: "6. La tua attività richiederà l'integrazione con un database o API?",
    miscelquestion_7: "7. Come pensi di testare e convalidare l'attività?",
    miscelquestion_8: "8. Con quale frequenza desideri aggiornamenti e rapporti sullo stato di avanzamento dell'attività?",
    miscelquestion_9: "9. Qual è il budget e la tempistica per questa attività?",
    miscelquestion_10: "10. In che modo l'attività verrà distribuita e ospitata?",
    miscelquestion_11: "11. Quali sono i requisiti di sicurezza per l'attività?",
    miscelquestion_12: "12. Come gestirai la manutenzione e il supporto una volta completata l'attività?",
    miscelquestion_13: "Desidero essere contattato via:",
    //hint question
    hintmiscelquestion_1: "1. Dovresti prima selezionare la funzione principale della tua attività.",
    hintmiscelquestion_2: "2. Dovresti fornire un elenco dettagliato delle caratteristiche e delle funzionalità che desideri aggiungere e descrivere come pensi che funzionino.",
    hintmiscelquestion_3: "3. Dovresti descrivere se hai già un design per l'interfaccia utente o se hai bisogno di aiuto per progettarlo.",
    hintmiscelquestion_4: "4. È necessario fornire qualsiasi codice esistente o documentazione del progetto per aiutarci a comprendere meglio l'ambito dell'attività.",
    hintmiscelquestion_5: "5. È necessario fornire tutte le tecnologie specifiche che si desidera utilizzare o avere requisiti per le tecnologie da evitare.",
    hintmiscelquestion_6: "6. È necessario descrivere eventuali integrazioni di database o API che saranno necessarie per l'attività.",
    hintmiscelquestion_7: "7. Dovresti descrivere come intendi testare e convalidare l'applicazione per assicurarti che soddisfi i tuoi requisiti.",
    hintmiscelquestion_8: "8. Dovresti descrivere la frequenza con cui desideri ricevere aggiornamenti sull'attività e che tipo di rapporti sullo stato di avanzamento desideri ricevere.",
    hintmiscelquestion_9: "9. È necessario fornire il budget per l'attività e la tempistica desiderata per il completamento.",
    hintmiscelquestion_10: "10. Dovresti descrivere come l'attività verrà distribuita e ospitata una volta completata.",
    hintmiscelquestion_11: "11. È necessario descrivere eventuali requisiti di sicurezza specifici per l'attività.",
    hintmiscelquestion_12: "12. Dovresti descrivere come intendi gestire la manutenzione e il supporto per l'attività dopo che è stata completata.",
    //task
    typetask: "Seleziona attività",
    react_task: "Componente di reazione",
    html_task: "Attività HTML",
    css_task: "Compito CSS",
    database_task: "Integrazione database",
    api_task: "Sviluppo dell'API",
    function_task: "Sviluppo delle funzioni",
    algorithm_task: "Codifica algoritmica",
    other_task: "Altri compiti",
    //Blog
    react_blog: "React Blog",
    aspirant_blog: "Aspirante",
    introduction_blog: "Introduzione",
    fundamental_blog: "Fondamentali",
    advanced_blog: "Avanzate",
    tools_blog: "Strumenti",
    technologies_blog: "Tecnologie",
    web_blog: "Web",
    html_blog: "HTML",
    css_blog: "CSS",
    javascript_blog: "Javascript",
    level_blog: "Livello",
    beginner_blog: "Principiante",
    intermediate_blog: "Intermedio",
    expert_blog: "Esperto",
    master_blog: "Maestro",
    preambule_blog_1: "Benvenuto nella nostra guida passo-passo per sviluppatori web in erba, volta ad aiutarti a diventare uno sviluppatore esperto. In questa guida, tratteremo tutto ciò che devi sapere per diventare un maestro dello sviluppo web, dalle basi della programmazione a le ultime tendenze e tecnologie del Web. Che tu sia un principiante o un esperto, la nostra guida ti aiuterà a migliorare le tue capacità e a stare al passo con il gioco.",
    preambule_blog_2: "La nostra guida è divisa in tre categorie principali: Aspirante, Principiante e Livello. Ogni categoria contiene diverse sottocategorie costituite da articoli ed esercitazioni che trattano in modo approfondito vari argomenti. Questa serie di blog inizierà con la categoria Aspirante e terminerà con la categoria Livello, con nuovi blog e tutorial aggiunti regolarmente a ciascuna sottocategoria. Crediamo nell'importanza di fare le cose passo dopo passo, quindi abbiamo progettato la nostra guida per aiutarti a progredire gradualmente.",
    preambule_blog_3: "Sia che tu preferisca imparare attraverso video, libri, tutorial interattivi o progetti pratici, abbiamo quello che fa per te. La nostra guida offre una vasta gamma di risorse di apprendimento che soddisfano diversi stili e preferenze di apprendimento. Per i principianti, offriamo anche veri e propri corsi online Learning by Getting Started in cui è possibile acquisire esperienza pratica con lo sviluppo web.",
    preambule_blog_4: "Sia che tu stia cercando di iniziare una carriera nello sviluppo web o che tu sia uno sviluppatore esperto che cerca di migliorare le tue capacità, la nostra guida passo dopo passo ti fornirà le conoscenze e le risorse necessarie per avere successo nel mondo dello sviluppo software .",
    //
    message_to_wait_dear:"Cari sviluppatori,",
    message_to_wait_1:"Siamo entusiasti di annunciare che presto avremo una nuova categoria di blog! Tuttavia, vogliamo assicurarci che tu abbia assorbito completamente le informazioni presentate nella precedente categoria del blog prima di passare a quella successiva.",
    message_to_wait_2:"Ti chiediamo gentilmente di dedicare il tuo tempo a leggere e comprendere a fondo ciascun blog. Comprendiamo che ognuno impara al proprio ritmo, ma suggeriamo di attendere che la maggior parte dei nostri lettori abbia raggiunto una lettura approfondita della precedente categoria di blog prima di passare a quella successiva.",
    message_to_wait_3:"Ti invitiamo inoltre a continuare a controllare il nostro sito Web per aggiornamenti e nuovi post sul blog. Ogni volta che ti senti pronto per passare alla categoria successiva, fai semplicemente clic sulla scheda della categoria e controlla se sono disponibili nuovi blog.",
    message_to_wait_4:"Grazie per la tua dedizione e interesse per il nostro blog. Ci auguriamo che la nostra prossima categoria ti piaccia tanto quanto la precedente.",
    message_to_wait_best:"Distinti saluti,",
    message_to_wait_carions:"Carion's La Tradizione Digitale",
    //Aspirant blogs advice
    aspirant_blog_1:"Quando inizi per la prima volta il tuo viaggio di programmazione, è importante non saltare troppo in fretta. È allettante volersi tuffare direttamente in progetti complessi o argomenti avanzati, ma prendersi il tempo per costruire una solida base è essenziale per il successo a lungo termine.",
    aspirant_blog_2:"Seguendo un approccio graduale, puoi sviluppare gradualmente le tue conoscenze e abilità, acquisendo sicurezza e competenza lungo il percorso. Ogni passaggio si basa su quello precedente, quindi è importante padroneggiarli prima di passare a quello successivo.",
    aspirant_blog_3:"Ad esempio, se stai appena iniziando con JavaScript, non provare ad affrontare concetti avanzati come chiusure o ricorsione subito. Concentrati invece sulla comprensione delle variabili, dei tipi di dati e del flusso di controllo di base. Una volta che li hai abbattuti, puoi passare ad argomenti più avanzati.",
    aspirant_blog_4:"Allo stesso modo, se stai imparando un nuovo framework come React, non provare a creare subito un'app complessa. Inizia con componenti semplici e fatti strada fino a quelli più complessi, sviluppando gradualmente le tue abilità e conoscenze mentre procedi.",
    aspirant_blog_5:"Ricorda, l'obiettivo non è affrettare il processo e raggiungere la tua destinazione il più rapidamente possibile. Si tratta di godersi il viaggio e imparare il più possibile lungo la strada. Quindi prenditi il tuo tempo, sii paziente e fidati del processo.",
    aspirant_blog_6:"Man mano che avanzi nel tuo viaggio di programmazione, incontrerai senza dubbio problemi complessi che richiederanno una solida base nelle basi. Saltare i passaggi può sembrare una scorciatoia, ma alla fine ostacolerà i tuoi progressi e limiterà la tua capacità di affrontare compiti più impegnativi.",
    aspirant_blog_7:"Oltre a costruire solide fondamenta, seguire un approccio strutturato all'apprendimento ti aiuterà a rimanere motivato e concentrato. È facile essere sopraffatti di fronte a una quantità apparentemente infinita di informazioni da apprendere. Suddividendo il tuo apprendimento in passaggi più piccoli e più gestibili, puoi evitare il burnout e rimanere in pista.",
    aspirant_blog_8:"Ricorda che la programmazione è un viaggio che dura tutta la vita ed è importante godersi il processo. Non lasciarti coinvolgere troppo dalla ricerca della tecnologia o del framework più recenti. Invece, concentrati sulla costruzione di solide basi e sull'affinamento delle tue capacità di risoluzione dei problemi. Con tempo, dedizione e pazienza, diventerai un programmatore esperto e fiducioso.",
    aspirant_blog_9:"Quindi, il mio consiglio per te come aspirante sviluppatore è di prenderti il tuo tempo, seguire ogni fase del processo di apprendimento e goderti il viaggio. Con perseveranza e dedizione, raggiungerai i tuoi obiettivi e diventerai un programmatore di successo.",
    //poser une question
    question_select:"Domanda",
    question_comment:"Commenti",
    question_blog:"Aggiungi una domanda",
    cancel_question:"Annulla",
    send_question:"Invia la tua domanda",
    write_question_blog:"Per favore scrivi la tua domanda o commenti",
    question_public:"Pubblica",
    question_private:"Privata",
    //web blogs advice
    web_blog_1:"Come aspirante sviluppatore web, è importante affrontare il tuo viaggio con intenzione e un piano per la progressione. Ecco alcuni suggerimenti per iniziare e progredire nella tua carriera di sviluppatore web:",
    web_blog_2:"1. Inizia con le basi: proprio come con la programmazione, è importante iniziare con le basi dello sviluppo web. Ciò include l'apprendimento di HTML, CSS e JavaScript. Prenditi il tempo per comprendere i fondamenti di ogni lingua ed esercitati a scrivere codice.",
    web_blog_3:"2. Concentrati sull'esperienza dell'utente: in qualità di sviluppatore web, sarai responsabile della creazione di siti Web visivamente accattivanti e facili da usare. È importante tenere presente l'esperienza dell'utente durante la progettazione e la creazione di siti Web.",
    web_blog_4:"3. Impara un framework CSS: i framework CSS, come Bootstrap e Foundation, possono farti risparmiare tempo e aiutarti a creare design coerenti sul tuo sito web. Scopri come utilizzare almeno un framework CSS per migliorare le tue capacità di sviluppo.",
    web_blog_5:"4. Comprendi l'importanza della reattività: con così tante persone che accedono a Internet dai propri dispositivi mobili, è fondamentale che i siti Web siano reattivi e possano adattarsi a schermi di dimensioni diverse. Scopri come creare design reattivi che staranno benissimo su qualsiasi dispositivo.",
    web_blog_6:"5. Espandi il tuo set di competenze: una volta che hai una solida base nello sviluppo web, è importante continuare ad apprendere ed espandere il tuo set di competenze. Prendi in considerazione l'idea di imparare un linguaggio back-end, come PHP o Ruby, o un framework JavaScript, come React o Angular.",
    web_blog_7:"6. Pratica, pratica, pratica: più ti eserciti a scrivere codice, meglio diventerai. Cerca opportunità per mettere in pratica le tue capacità di sviluppo web, sia attraverso la creazione di progetti personali o contribuendo a progetti open source.",
    web_blog_8:"7. Rimani aggiornato con le tendenze del settore: il mondo dello sviluppo web è in continua evoluzione, con nuove tecnologie e tendenze che emergono continuamente. Rimani aggiornato con le ultime novità e tendenze del settore per assicurarti che le tue competenze rimangano pertinenti e richieste.",
    web_blog_9:"Ricorda, lo sviluppo web è un viaggio e ci vuole tempo e impegno per diventarne esperto. Non lasciarti scoraggiare dalle battute d'arresto e continua a spingerti per imparare e crescere come sviluppatore. Buona fortuna per il tuo viaggio nello sviluppo web!",
    //level blogs advice
    level_blog_1:"Se sei un aspirante sviluppatore web che cerca di migliorare le tue capacità, sei nel posto giusto. I tutorial e gli strumenti di sviluppo web che abbiamo fornito nelle categorie Aspirant e Web sono ottimi per iniziare e familiarizzare con i linguaggi di programmazione e gli strumenti per lo sviluppo web. Tuttavia, per migliorare veramente le tue capacità e diventare uno sviluppatore competitivo nel settore, la categoria Livello è la strada da percorrere.",
    level_blog_2:"Pensala come un'aula virtuale online, in cui ogni sottocategoria corrisponde a un anno di formazione. Partendo dalla classe per principianti, ti farai strada fino alla classe intermedia, avanzata e infine alla master class, dove sarai in grado di sviluppare progetti software reali e competere per i migliori lavori nel campo.",
    level_blog_3:"La categoria Level è perfetta per chi vuole seguire veri e propri corsi di programmazione in modo autodidatta, approfondire le proprie conoscenze o colmare lacune nella propria comprensione. È come avere un tutor personale che ti guida in ogni fase del percorso.",
    level_blog_4:"Per accedere alle diverse sottocategorie, è necessario soddisfare alcuni requisiti. Innanzitutto, dovresti essere almeno al quarto grado della scuola secondaria. In secondo luogo, avrai bisogno di un computer portatile o desktop per le lezioni pratiche, insieme a una connessione Internet affidabile. Inoltre, avrai bisogno di un telefono cellulare touchscreen con un account WhatsApp per comunicare con i tuoi istruttori e compagni di classe. Infine, dovrai creare un account gratuito sul nostro sito Web e inviare una domanda per accedere a una delle sottocategorie.",
    level_blog_5:"Una volta approvata la tua domanda, farai un semplice test per valutare le tue conoscenze e abilità. Non preoccuparti, non c'è passaggio o fallimento. Utilizziamo i tuoi risultati per guidarti e offrirti la migliore esperienza di apprendimento possibile.",
    level_blog_6:"Quindi, se sei seriamente intenzionato a diventare uno sviluppatore web competitivo, non saltare nessun passaggio. La categoria Livello è la strada da percorrere.",
    level_apply:"Applicare",
    connect_first:"Accedi al tuo account e poi torna alla pagina.",
    connect_cours:"Accedi ai corsi",
    connect_cours_content:"Inserisci il tuo codice studente e la password per accedere ai corsi e altro o crea un nuovo account gratuitamente se non l'hai ancora fatto.",
    code_inscrit:"Codice studente",
    placecodeinscrit:"Inserisci codice studente",
    newaccount_incrit:"Non Codice Studente?",
    editaccount_inscrit:"Modifica dettagli studente",
    student_creer:"Crea il tuo account studente",

    //individual hosting service
    proceedhostingdescription: "Ecco alcune domande essenziali che potrei farti prima di iniziare il tuo progetto web. Si prega di compilare gratuitamente per consentirmi di comprendere meglio le vostre esigenze, preferenze e limitazioni per personalizzare il design, se necessario, per soddisfare tali requisiti.",
    proceedhostingtitle: "Hosting",
    hostingquestion_1:"1. Che tipo di sito web devi ospitare?",
    hostingquestion_2:"2. Qual è il traffico previsto per il tuo sito web?",
    hostingquestion_3:"3. Qual è il tuo budget per i servizi di hosting?",
    hostingquestion_4:"4. Qual è il tuo tempo di attività previsto?",
    hostingquestion_5:"5. Hai bisogno di un indirizzo IP dedicato?",
    hostingquestion_6:"6. Qual è la velocità di caricamento del tuo sito web prevista?",
    hostingquestion_7:"7. Hai bisogno di software o linguaggi di programmazione specifici?",
    hostingquestion_8:"8. Qual è il requisito previsto per l'archiviazione dei dati?",
    hostingquestion_9:"9. Di quale livello di supporto tecnico hai bisogno?",
    hostingquestion_10:"10. Qual è il requisito di scalabilità previsto?",
    hostingquestion_11:"11. Qual è il livello di sicurezza previsto?",
    hostingquestion_12:"12. Qual è il tuo livello di controllo previsto sull'ambiente di hosting?",
    hostingquestion_13:"Desidero essere contattato via:",
    //hint hostingquestion
    hinthostingquestion_1: "1. Comprendere il tipo di sito Web che devi ospitare ci aiuterà a determinare la soluzione di hosting appropriata per le tue esigenze.",
    hinthostingquestion_2: "2. Conoscere il traffico del tuo sito Web previsto ci aiuterà a determinare il piano di hosting appropriato e le risorse del server necessarie per gestire il traffico.",
    hinthostingquestion_3: "3. Conoscere il tuo budget per i servizi di hosting ci aiuterà a consigliarti soluzioni di hosting convenienti e all'interno del tuo budget.",
    hinthostingquestion_4: "4. Comprendere i requisiti di uptime previsti ci aiuterà a consigliare soluzioni di hosting che forniscano uptime e disponibilità elevati.",
    hinthostingquestion_5: "5. Sapere se hai bisogno di un indirizzo IP dedicato ci aiuterà a consigliarti soluzioni di hosting che forniscono questa funzionalità.",
    hinthostingquestion_6: "6. Comprendere la velocità di caricamento del tuo sito Web prevista ci aiuterà a consigliare soluzioni di hosting che forniscano tempi di caricamento delle pagine rapidi.",
    hinthostingquestion_7: "7. Sapere se hai bisogno di software o linguaggi di programmazione specifici ci aiuterà a consigliarti soluzioni di hosting che supportino tali requisiti.",
    hinthostingquestion_8: "8. Comprendere i requisiti di archiviazione dei dati previsti ci aiuterà a consigliare soluzioni di hosting che forniscano una capacità di archiviazione sufficiente.",
    hinthostingquestion_9: "9. Conoscere le vostre esigenze di supporto tecnico ci aiuterà a consigliare soluzioni di hosting che forniscano il livello di supporto appropriato.",
    hinthostingquestion_10: "10. Comprendere i requisiti di scalabilità previsti ci aiuterà a consigliare soluzioni di hosting in grado di soddisfare la crescita e l'espansione future.",
    hinthostingquestion_11: "11. Conoscere il livello previsto di requisiti di sicurezza ci aiuterà a consigliare soluzioni di hosting che forniscano funzionalità e protocolli di sicurezza appropriati.",
    hinthostingquestion_12: "12. Comprendere il livello di controllo previsto sull'ambiente di hosting ci aiuterà a consigliare soluzioni di hosting che forniscano il livello appropriato di opzioni di controllo e personalizzazione.",
    //admin dashboard
    service_admin_title:"Pannello di Servizio",
    admin_onboard:"A bordo",
    admin_planning:"Pianificazione",
    admin_schedule:"Orario",
    admin_communication:"Comunicazione",
    admin_update:"Aggiornamento",
    admin_contract:"Contratto",
    admin_budget:"Budget",
    admin_timeline:"Cronologia",
    admin_metrics:"Metrica",
    admin_view:"Visualizza",
    admin_management:"Gestione",
    admin_new:"Nuovo",
    admin_delivery:"Consegna",
    admin_deployment:"Distribuzione",
    //planning
    objective_requirements:"1. Definizione degli obiettivi e dei requisiti del progetto: comporta l'identificazione delle esigenze, degli obiettivi e dei requisiti del cliente per il progetto.",
    project_plan:"2. Creazione di un piano di progetto: comporta lo sviluppo di un piano di progetto che delinei l'ambito, la tempistica, il budget e le risorse necessarie per il progetto.",
    project_milestones:"3. Identificazione delle pietre miliari del progetto: comporta la suddivisione del progetto in attività o pietre miliari più piccole e gestibili che possono essere monitorate e misurate.",
    project_timelines:"4. Stabilire le tempistiche del progetto: ciò comporta la creazione di una sequenza temporale che delinei le date di inizio e di fine per ogni attività o traguardo.",
    task_responsibilities:"5. Assegnazione di compiti e responsabilità: comporta l'identificazione dei membri del team o degli appaltatori responsabili di ogni attività o traguardo e l'assegnazione di scadenze e priorità.",
    risks_contingencies:"6. Identificazione dei rischi e delle contingenze del progetto: comporta l'identificazione di potenziali rischi o sfide che potrebbero avere un impatto sul progetto e lo sviluppo di piani di emergenza per mitigare tali rischi.",
    review_adjust:"7. Revisione e adeguamento del piano del progetto: ciò comporta la revisione regolare del piano e della tempistica del progetto per garantire che il progetto sia sulla buona strada e apportare le modifiche necessarie.",
    planning_succes:"Questa pianificazione è stata salvata correttamente",
    planning_exists:"Questa pianificazione è già stata registrata",
    //schedule
    info_schedule:"Stabilire una connessione e discutere con il cliente quando si fissa un appuntamento e quindi convalidare.",
    //share carions contents
    share_title:"📣 Aiutaci a diffondere il verbo! Condividi la nostra web app con i tuoi amici e colleghi! 🚀",
    share_description_1:"Gentili Visitatori/Utenti di Carion,",
    share_description_2:"Siamo entusiasti di averti tra gli utenti di Carion! Crediamo che le grandi cose debbano essere condivise e apprezzeremmo molto il tuo supporto nel spargere la voce su ciò che offriamo.",
    share_description_3:"Se hai trovato valore nell'utilizzo di Carion, ti invitiamo a condividerlo con i tuoi amici e colleghi semplicemente facendo clic sull'icona di condivisione sul tuo cellulare. In questo modo, aiuterai gli altri a scoprire uno strumento potente che può rendere la loro vita più facile e più produttiva.",
    share_description_4:"Ecco come puoi aiutare:",
    share_description_5:"1️⃣ Apri la nostra web app sul tuo cellulare.",
    share_description_6:"2️⃣ Cerca l'icona di condivisione (di solito rappresentata da tre punti collegati da linee o da una freccia rivolta verso l'alto).",
    share_description_7:"3️⃣ Tocca l'icona di condivisione e scegli la piattaforma di social media o l'app di messaggistica preferita per condividere il collegamento dell'app Web con i tuoi contatti.",
    share_description_8:"Il tuo supporto significa molto per noi e, insieme, possiamo creare una comunità vivace e prospera attorno alla nostra app web. Consentiamo a più persone di raggiungere i propri obiettivi e sbloccare il loro pieno potenziale.",
    share_description_9:"Grazie per aver fatto parte del nostro viaggio!",
    share_description_10:"Distinti saluti,",
    share_description_11:"Carion's Ltd",
    //comments
    comment_title:"Commenti",
    comment_dear:"Cari utenti",
    comment_description:"Carion's ti invita a supportarlo condividendo i tuoi suggerimenti e proposte che potrebbero permetterci di migliorare la tua esperienza utente e migliorare questa applicazione per servirti meglio.",
    comment_select:"Valutazione",
    select_star:"Seleziona la tua valutazione",
    comment_why:"Inserisci i tuoi commenti e suggerimenti",
    creer_comment:"Invia",
    comment_name:"Aggiungi il vostro nome per favore.",
    comment_place:"Vostro Nome",
    view_all_comment:"Visualizza tutti i commenti",
    //crud account
    info_titre:"🔐 Gestisci il tuo account con facilità! Crea, modifica ed elimina il tuo account senza problemi! 🔄",
    info_dear:"Cari utenti,",
    info_intro:"Vogliamo rendere la tua esperienza con la nostra app Web dei servizi il più semplice possibile, motivo per cui abbiamo implementato una funzione di gestione dell'account intuitiva. Con questa funzione, hai il pieno controllo del tuo account, permettendoti di crearlo, modificarlo ed eliminarlo quando necessario, senza complicazioni.",
    info_create:"1️⃣ Crea un account:",
    info_create_detail:"Quando accedi per la prima volta alla nostra app Web, fai clic sul pulsante 'Registrati' o 'Crea un account'. Inserisci le informazioni richieste, imposta le tue preferenze e voilà! Il tuo account verrà creato in pochi semplici passaggi.",
    info_edit:"2️⃣ Modifica il tuo account:",
    info_edit_detail:"Hai bisogno di aggiornare i tuoi dati personali, cambiare la tua password o modificare le impostazioni del tuo account? Nessun problema! Vai semplicemente alla sezione 'Gestione account e fai clic su Modifica il tuo account', dove troverai le opzioni per modificare le tue informazioni. Apporta le modifiche necessarie, salvale e il tuo account verrà aggiornato all'istante.",
    info_delete:"3️⃣ Elimina il tuo account:",
    info_delete_detail:"Se dovessi mai decidere di eliminare il tuo account, abbiamo semplificato la procedura. Vai alla sezione 'Gestione account' e fai clic sull'opzione Elimina il tuo account e segui le istruzioni. Tieni presente che l'eliminazione del tuo account rimuoverà definitivamente tutti i dati associati, quindi procedi con cautela.",
    info_conclu_1:"Offrendo questa funzione di gestione dell'account senza problemi, miriamo a fornirti flessibilità e controllo sulla tua esperienza con l'app web. Comprendiamo che le circostanze cambiano e dovresti avere la libertà di gestire il tuo account come meglio credi.",
    info_conclu_2:"Se hai domande o hai bisogno di assistenza per quanto riguarda la gestione dell'account, il nostro team di supporto è sempre qui per aiutarti. Basta contattarci tramite il modulo di contatto.",
    info_conclu_3:"Grazie per essere un apprezzato utente della nostra web app!",
    info_conclu_4:"Distinti saluti,",
    info_conclu_5:"Carion Ltd",
    //
    login_before:"Accedi prima di gestire il tuo account",
    //delete account
    delete_titre:"⚠️ Avviso importante: cancellazione e recupero dell'account ⚠️",
    delete_texte:"La cancelazione definitiva del tuo account rimuove tutti i dati e le informazioni ad esso associati. Una volta eliminato, non può essere recuperato. Consideralo attentamente poiché perderai l'accesso al tuo account e ai suoi contenuti. Per tornare, devi creare un nuovo account. Si consiglia di eseguire il backup di tutti i dati importanti prima di procedere. Il nostro team di supporto è qui per aiutarti in caso di dubbi o domande. Segui il processo di cancellazione dell'account nella sezione 'Gestione account'. Grazie per essere un prezioso membro di Carion's Ltd!",
    delete_conclu_1:"Distinti saluti,",
    delete_conclu_2:"Carion's Ltd",
    delete:"Cancelare",
    //
    share_with:"Condividere con",
    check_out:"Scopri le Soluzioni Web di Carion's. Condividi il link per aiutare gli altri a beneficiare dei nostri servizi!",
};